import { FC } from 'react';
import { Menu, MenuItem, MenuProps } from './menu';
import { Anchor } from '../anchor';

export type AnchoredMenuProps = MenuProps & {
  anchor: HTMLElement;
  anchorOrigin?: { horizontal: 'left' | 'right' };
  onClose: VoidFunction;
  title?: string;
};

export const AnchoredMenu: FC<AnchoredMenuProps> = ({ anchor, onClose, ...props }) => {
  function onItemClick(item: MenuItem) {
    if (!item.onClick) return;
    return function onClick() {
      item.onClick!();
      onClose();
    };
  }

  const items = props.items.map(item => ({
    ...item,
    onClick: onItemClick(item),
  }));

  return (
    <Anchor anchor={anchor} onClose={onClose}>
      <Menu {...props} items={items} />
    </Anchor>
  );
};
