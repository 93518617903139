// TODO: figure out how to not duplicate these values
export enum Color {
  Blue300 = '#c4e3f4',
  Blue400 = '#89c8e8',
  Blue500 = '#4da9da',
  Blue700 = '#007cc2',
  Blue800 = '#005fa3',

  Orange300 = '#fee7c4',
  Orange400 = '#fdce8a',
  Orange600 = '#fc9e15',
  Orange700 = '#d47e00',
  Orange800 = '#9e6100',

  Green300 = '#cce5b2',
  Green400 = '#9c6',
  Green600 = '#62a30f',
  Green700 = '#028000',

  Grey200 = '#f3f4f5',
  Grey300 = '#dbdde1',
  Grey500 = '#8c929e',
  Grey600 = '#6c707a',
  Grey700 = '#4d5059',

  Red500 = '#ff9e9c',
  Red600 = '#ff8381',
  Red700 = '#db3734',

  White = '#fff',
  Black = '#000',
}

export enum ChartColor {
  BlueDark = '#007cc2',
  YellowDark = '#fc9e15',
  GreenDark = '#028000',
  RedLight = '#ff8381',
  Lavender = '#8291D3',
  GreenLight = '#9c6',
  Brown = '#9e6100',
  YellowLight = '#FFCF44',
  Pink = '#ff9e9c',
  BlueLight = '#89c8e8',
  LimedSpruce = '#4d5059',
  Grey = '#8c929e',
}
