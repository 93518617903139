import * as React from 'react';
import { Props } from './reset-default-dashboard-modal.types';
import './reset-default-dashboard-modal.css';
import { ConfirmationModal } from '../../../components/confirmation-modal';
import { translate } from '../../../helpers/utils';

export const ResetDefaultDashboardModal: React.FunctionComponent<Props> = ({
  onApply,
  onClose,
}) => {
  return (
    <ConfirmationModal
      title={translate('reset_default_dashboard_modal_title')}
      details={[translate('reset_default_dashboard_modal_body')]}
      onClose={onClose}
      primaryButton={{
        title: translate('common_apply'),
        actionFn: () => {
          onApply();
          onClose();
        },
      }}
      secondaryButton={{
        title: translate('common_cancel'),
        actionFn: onClose,
      }}
    />
  );
};
