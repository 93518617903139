import { createContext, useContext } from 'react';
import { useCompareDateRangePeriods } from '../../components/date-picker/date-picker.state';

export type AbsoluteOrRelative = 'absolute' | 'relative';

type AbsoluteOrRelativeComparison = {
  activeTrend: AbsoluteOrRelative;
  setActiveTrend: (value: AbsoluteOrRelative) => void;
};

export function useAbsoluteOrRelativeComparison() {
  const [comparePeriods] = useCompareDateRangePeriods();
  const context = useContext(AbsoluteOrRelativeComparisonContext);

  if (!context) {
    throw new Error(
      'useAbsoluteOrRelativeComparison must be used within a AbsoluteOrRelativeComparisonProvider',
    );
  }

  return { ...context, disabled: comparePeriods.length !== 1 };
}

export const AbsoluteOrRelativeComparisonContext =
  createContext<AbsoluteOrRelativeComparison | null>(null);
