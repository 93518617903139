import React, { FC } from 'react';
import { MemberAdministration } from '../../../models';
import { MemberInformation } from '../admin-members/member-information';
import { MemberPartners } from '../admin-members/member-partners';
import styles from './members.module.css';
import { Button } from '../../../core-ui/components/button/button';

export const MemberDetails: FC<{ member: MemberAdministration; onBack: VoidFunction }> = ({
  member,
  onBack,
}) => {
  return (
    <div className={styles.members}>
      <Button onClick={onBack} text="Go back" variant="tertiary" />
      <MemberInformation member={member} />
      <MemberPartners member={member} />
    </div>
  );
};
