import * as React from 'react';
import { Redirect, Switch } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {
  UNAUTHORIZED_ACCOUNT_ROUTE,
  LOGIN_ROUTE,
  OAUTH_CHECK_ROUTE,
  DASHBOARD_ROUTE,
} from '../../constants/routes';
import { App } from '../../global/app';
import Authentication from '../../components/authentication/authentication';
import { Unauthorized } from '../unauthorized';

const authenticationURL = () => {
  const redirectUri = process.env.REACT_APP_LOCAL!;
  window.location.href = getLoginUri() + redirectUri;
  return null;
};

function getLoginUri(): string {
  return process.env.REACT_APP_LOGIN!;
}

export const Login = () => {
  const searchParams = new URLSearchParams(window.location ? window.location.search : '');
  const returnTo = searchParams.get('return_to');

  if (returnTo) {
    if (returnTo.includes('resourcecenter')) {
      localStorage.setItem('return_to', returnTo);
    } else {
      window.location.href = 'https://support.trivago.com';
      return null;
    }
  }

  return (
    <Router>
      <Switch>
        <Route exact={true} path={LOGIN_ROUTE} render={authenticationURL} />
        <Route exact={true} path={UNAUTHORIZED_ACCOUNT_ROUTE} component={Unauthorized} />
        <Route exact={true} path={OAUTH_CHECK_ROUTE} component={Authentication} />
        <Redirect from="/" exact={true} to={DASHBOARD_ROUTE} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
  );
};
