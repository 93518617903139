import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { ReportsApi } from '../../helpers/api/reports/reports-api';

interface UseSftpConfigHookResult {
  folderName: string | null;
  isLoading: boolean;
  refetch: () => void;
  error?: string;
}

export function useSftpConfig(partnerId: number): UseSftpConfigHookResult {
  const [folderName, setFolderName] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  async function fetchFolderName(): Promise<string | null> {
    try {
      const config = await ReportsApi.fetchSftpConfig(partnerId);
      return config.folderName;
    } catch (e) {
      if (!(e instanceof AxiosError)) {
        throw e;
      }
      console.error(e);
      if (
        e?.response?.status === 404 &&
        e?.response?.data?.message === `Partner \`${partnerId}\` has no sftp folder set up`
      ) {
        return null;
      }
      throw new Error('Unable to fetch folder name');
    }
  }

  async function fetchSftpConfig() {
    try {
      setLoading(true);
      setError(undefined);
      setFolderName(await fetchFolderName());
    } catch (e) {
      console.error(e);
      setError((e as any).message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchSftpConfig();
  }, [partnerId]);

  return {
    folderName,
    isLoading,
    refetch: fetchSftpConfig,
    error,
  };
}
