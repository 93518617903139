import React from 'react';
import { Column, ColumnType } from '../../../components/grid';
import { PartnerLevels } from '../../../constants/admin';
import { TranslationKey, translate } from '../../../helpers/utils';
import { useSetPartnerLevel } from './use-set-partner-level';
import { HyperlinkCell } from '../../../components/hyperlink-cell';
import { MemberAdministration } from '../../../models';
import { LEVEL_TRIVAGO_EMPLOYEE } from '../../../constants';

const partnerLevelTitle = (partnerLevel: number) =>
  `${partnerLevel} - ` +
  translate(
    `member_partner_level_selector_${PartnerLevels[partnerLevel]?.toLowerCase()}` as TranslationKey,
  );

const partnerLevelOptions = Object.keys(PartnerLevels)
  .filter(level => !isNaN(Number(level)))
  .map(level => Number(level))
  .map(level => ({ label: partnerLevelTitle(level), value: level }));

export function useMembersColumns(onMemberClick: (member: MemberAdministration) => void): Column[] {
  const setPartnerLevel = useSetPartnerLevel();

  return [
    {
      key: 'memberId',
      name: 'ID',
      type: ColumnType.TEXT,
    },
    {
      key: 'email',
      name: 'Email',
      type: ColumnType.CUSTOM,
      children(_, row) {
        return (
          <HyperlinkCell
            displayValue={row.email}
            hasValue
            onClick={e => {
              e.preventDefault();
              onMemberClick(row as any);
            }}
          />
        );
      },
    },
    {
      key: 'firstName',
      name: 'First name',
      type: ColumnType.TEXT,
    },
    {
      key: 'lastName',
      name: 'Last name',
      type: ColumnType.TEXT,
    },
    {
      key: 'isTrivagoEmployee',
      name: 'Trivago employee',
      type: ColumnType.CUSTOM,
      children(_, row) {
        return <>{row.level === LEVEL_TRIVAGO_EMPLOYEE ? 'Yes' : 'No'}</>;
      },
    },
    {
      key: 'partnerLevel',
      name: 'Partner level',
      type: ColumnType.CUSTOM,
      children(_, row) {
        return (
          <select
            onChange={e => setPartnerLevel(row as any, Number(e.target.value))}
            style={{ margin: 0, height: 'unset' }}
          >
            {partnerLevelOptions.map(option => (
              <option
                key={option.value}
                value={option.value}
                selected={option.value === row.partnerLevel}
              >
                {option.label}
              </option>
            ))}
          </select>
        );
      },
    },
  ];
}
