import { ComponentProps, FC } from 'react';
import styles from './loading.module.css';
import cn from 'classnames';

type LoadingProps = {} & ComponentProps<'span'>;

export const Loading: FC<LoadingProps> = ({ className, ...props }) => {
  return (
    <span {...props} className={cn(styles.loading, className)}>
      <span className={cn(styles.dot, styles.red)}></span>
      <span className={cn(styles.dot, styles.orange)}></span>
      <span className={cn(styles.dot, styles.blue)}></span>
    </span>
  );
};
