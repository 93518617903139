import { EventType } from '../../../models';
import { useImportSchedules } from './use-import-schedules';
import { useRemainingPushes } from './use-remaining-pushes';

export interface PushesAndSchedules {
  type: EventType;
  pushesRemaining?: number;
  schedules: string[];
}

interface UsePushesAndSchedulesResult {
  isLoading: boolean;
  error: Error | null;
  data: PushesAndSchedules[];
}

export function usePushesAndSchedules(partnerId: number): UsePushesAndSchedulesResult {
  const {
    isLoading: isLoadingPushes,
    error: errorPushes,
    data: pushes = {},
  } = useRemainingPushes(partnerId);
  const {
    isLoading: isLoadingSchedules,
    error: errorSchedules,
    data: schedules = {},
  } = useImportSchedules(partnerId);

  const data: PushesAndSchedules[] = allTypes(pushes, schedules).map(type => ({
    type,
    pushesRemaining: pushes[type],
    schedules: schedules[type] || [],
  }));

  const isLoading = isLoadingPushes || isLoadingSchedules;
  const error = errorPushes || errorSchedules;

  return {
    isLoading,
    error,
    data: isLoading || error ? [] : data,
  };
}

const allTypes = (pushes: Record<number, number>, schedules: Record<number, string[]>) =>
  Array.from(new Set([...numberKeys(pushes), ...numberKeys(schedules)]));

const numberKeys = (object: Record<number, any>) => Object.keys(object).map(el => Number(el));
