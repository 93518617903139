import * as React from 'react';
import HistoryLineDarkIcon from 'tcp-react-icons/lib/HistoryLineDarkIcon';
import TrackerService from '../../services/tracker/tracker-service';
import { ENTERED_RESOURCE_CENTER_NAME, EnteredTab } from '../../services/tracker/customer-actions';
import { Props } from './resource-center.types';
import './resource-center.css';
import { TooltipWrapper, TooltipPosition } from '../tooltip-wrapper';
import { translate } from '../../helpers/utils';

export const goToResourceCenter = () => {
  TrackerService.track(ENTERED_RESOURCE_CENTER_NAME, {
    previous_tab: window.location.pathname,
  } as EnteredTab);

  window.open(process.env.REACT_APP_RESOURCE_CENTER, '_blank');
};

const ResourceCenter: React.FunctionComponent<Props> = () => {
  return (
    <TooltipWrapper
      message={translate('menu_link_name_resource_center_tooltip')}
      position={TooltipPosition.BOTTOM_CENTER}
      className="c-panel-control__faq-circle-icon"
      noTinyPaddingTop={true}
    >
      <div
        className="c-resource-center u-display--flex u-align-items--center u-user-select--none u-padding--small u-cursor--pointer u-margin-right--gutter"
        onClick={goToResourceCenter}
        data-qa="resource-center"
      >
        <HistoryLineDarkIcon className="u-margin-right--small" />
        {translate('menu_link_name_resource_center')}
      </div>
    </TooltipWrapper>
  );
};

export default ResourceCenter;
