import { KEYBOARD_EVENT_ENTER } from '../../constants';

// @see https://30secondsofcode.org/
export const chunk = (arr: any[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
    arr.slice(i * size, i * size + size),
  );

export function compareTo(o1: number, o2: number) {
  return o1 < o2 ? -1 : o1 > o2 ? 1 : 0;
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export function areArraysEqual<T>(
  array1: T[],
  array2: T[],
  comparableEqual: (a1: T, a2: T) => boolean,
): boolean {
  return (
    array1.length === array2.length &&
    array1.every(item1Tmp => array2.some(item2Tmp => comparableEqual(item1Tmp, item2Tmp)))
  );
}

export const calculatePercentage = (value1: number, value2: number): number =>
  ((value1 - value2) / value2) * 100;

export const isUndefinedOrNull = (value: any) => value === undefined || value === null;

export const pressKeyEnter = (key: string, action: () => void) => {
  if (key === KEYBOARD_EVENT_ENTER) {
    action();
  }
};

export const insertDecimal = (num: number, numOfDecimals: number): string =>
  num.toFixed(numOfDecimals);

// Add or increase widget number: Widget 1 -> Widget 2 -> Widget 3
export const generateNewWidgetName = (name: string, allWidgets: any[]): string => {
  if (!isNaN(Number(name))) {
    const numericalNames = allWidgets
      .reduce((acc, widget) => {
        !isNaN(Number(widget.props.name)) && acc.push(widget.props.name);
        return acc;
      }, [])
      .sort((a: number, b: number) => a - b);
    return (+numericalNames[numericalNames.length - 1] + 1).toString();
  }
  const nameArray = name.split(' ');
  let idx = Number(nameArray[nameArray.length - 1]);
  if (!isNaN(idx)) {
    idx += 1;
    nameArray.pop();
  } else {
    idx = 2;
  }

  const newName = `${nameArray.join(' ')} ${idx}`;
  const alreadyExists = allWidgets.some(widget => widget.props.name === newName);

  if (alreadyExists) return generateNewWidgetName(newName, allWidgets);

  return newName;
};

export const capitalizeFirstLetter = (myString: string) => {
  return myString[0].toUpperCase() + myString.substring(1);
};
export const isTextOverflowing = (ref: HTMLSpanElement | HTMLElement | null) =>
  !ref ? false : ref?.scrollWidth > ref?.clientWidth;

export function comparing<T, K>(
  prop: (obj: T) => K,
  compareFn: (a: K, b: K) => number = defaultCompare,
): (a: T, b: T) => number {
  return (a, b) => {
    const propA = prop(a);
    const propB = prop(b);
    return compareFn(propA, propB);
  };
}

function defaultCompare<T>(propA: T, propB: T) {
  if (propA < propB) {
    return -1;
  }
  if (propA > propB) {
    return 1;
  }
  return 0;
}

export const isNumber = (
  v: string | boolean | undefined | Record<string, string | number> | number,
) => !Number.isNaN(Number(v)) && v !== '';
