import React, { useState, useRef } from 'react';
import { Props, MenuOptionType } from './multi-level-menu.types';
import cn from 'classnames';
import './multi-level-menu.css';
import ArrowIcon from 'tcp-react-icons/lib/ArrowIcon';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';

export const MultiLevelMenu: React.FunctionComponent<Props> = ({
  items,
  className,
  isComponent = false,
  isDatePickerModalOpen = false,
}) => {
  const [openMenuItemKey, setOpenMenuItemKey] = useState<React.Key | null>(null);

  const handleMenuOpen = (key: string, state: boolean) => {
    setOpenMenuItemKey(state ? key : null);
  };
  const menuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(menuRef, event => {
    event.stopPropagation();
    if (isComponent || isDatePickerModalOpen) return;
    setOpenMenuItemKey(null);
  });

  return (
    <div
      className={cn(
        {
          'multi-level-menu u-padding--medium u-border-radius u-display--flex u-background--white':
            !isComponent,
        },
        className,
      )}
      ref={menuRef}
      data-testid="multi-level-menu"
    >
      {items.map(item => (
        <MenuOption
          key={item.key}
          item={item}
          isOpen={item.key === openMenuItemKey}
          onMenuOpen={handleMenuOpen}
        />
      ))}
    </div>
  );
};

const MenuOption: React.FunctionComponent<MenuOptionType> = ({ item, isOpen, onMenuOpen }) => {
  const hasSubMenu = item.subMenu && item.subMenu.length > 0;
  const [menuPosition, setMenuPosition] = useState({ left: 18 });

  const handleMenuPosition = (event: React.MouseEvent<HTMLDivElement>) => {
    const menuItem = (event.target as HTMLElement).closest(
      '.multi-level-menu--item',
    ) as HTMLDivElement;

    if (!menuItem) return;

    const menuItemRect = menuItem.getBoundingClientRect();
    const menuParent = menuItem.parentElement?.parentElement?.getBoundingClientRect();
    const menuParentWidth = menuParent?.width || 0;
    const isSubMenu = menuItem.parentElement?.parentElement?.classList?.contains(
      'multi-level-menu--submenu',
    );
    const left = isSubMenu ? menuParentWidth : menuItemRect.width + 24;
    setMenuPosition({ left });
  };

  const hasContentSubMenu = item.subMenu && React.isValidElement(item.subMenu[0]?.label);

  return (
    <>
      <div
        onMouseEnter={event => {
          onMenuOpen(item.key, true);
          handleMenuPosition(event);
        }}
        className={cn(
          'multi-level-menu--item u-display--flex u-font-size--h-tiny u-background--white',
          {
            'multi-level-menu--item__active': isOpen,
          },
        )}
        key={item.key}
      >
        <span>{item.label}</span>
        {hasSubMenu && (
          <span className={cn('multi-level-menu--item__icon')}>
            <ArrowIcon />
          </span>
        )}
      </div>
      {hasSubMenu && isOpen && item.subMenu && (
        <div
          className={cn('multi-level-menu--submenu', {
            'content-submenu': hasContentSubMenu,
          })}
          style={{
            position: 'absolute',
            left: menuPosition.left,
            top: 0,
          }}
        >
          <MultiLevelMenu items={item.subMenu} isComponent={hasContentSubMenu} />
        </div>
      )}
    </>
  );
};
