import * as React from 'react';
import { Redirect } from 'react-router';
import { Profile } from '../../models';
import { Loading } from '../loading/loading';
import './guard.css';
import { Props, State } from './guard.types';
import ApiAuthHelper from '../../helpers/api/api-auth-helper';
import {
  deleteProfileFromLocalStorage,
  saveProfileToLocalStorage,
} from '../../helpers/local-storage-helper';
import { getProfileFromLocalStorage } from '../../helpers/local-storage-helper';
import { isTokenExpired } from '../../services/profile-service';
import { showNotification } from '../../services/notification-service';
import { NotificationLevel } from '../../constants';
import { translate } from '../../helpers/utils';

export const Guard: React.FunctionComponent<Props> = ({ onRetrieveUser, children }: any) => {
  const [state, setState] = React.useState<State>({
    isLoading: true,
    isInvalid: false,
  });

  React.useEffect(() => {
    const onExpiredUser = async (profile: Profile) => {
      try {
        const refreshedProfile = await ApiAuthHelper.refresh(profile.token.accessToken);
        saveProfileToLocalStorage({
          user: {
            ...refreshedProfile.user,
            enabledFeatures: profile.user.enabledFeatures,
          },
          token: refreshedProfile.token,
        });

        setState({
          isInvalid: false,
          isLoading: false,
        });

        onRetrieveUser(refreshedProfile);
      } catch (e) {
        showNotification({
          level: NotificationLevel.ERROR,
          message: translate((e as any).message),
        });
        deleteProfileFromLocalStorage();
        setState({ isInvalid: true, isLoading: false });
      }
    };

    const profile = getProfileFromLocalStorage();

    if (profile === undefined) {
      setState({
        isInvalid: true,
        isLoading: false,
      });
      return;
    }

    const isExpired = isTokenExpired(profile);
    if (isExpired) {
      // Token is expired
      onExpiredUser(profile);
      return;
    }

    // Token is okay
    setState({
      isInvalid: false,
      isLoading: false,
    });
  }, [onRetrieveUser]);

  if (state.isLoading) {
    return <Loading message="Checking user..." />;
  }

  if (state.isInvalid) {
    return <Redirect to="/login" />;
  }

  return children;
};
