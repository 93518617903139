import React, { FunctionComponent } from 'react';
import { Props } from './property-name-dimension.types';
import { useSelectedSubPartners } from '../../hooks/use-selected-partner';
import { DimensionValueDropdown } from '../dimension-value-dropdown';
import { ReactQueryKeys } from '../../constants';
import ApiAnalyticsHelper from '../../helpers/api/analytics/api-analytics-helper';
import { usePropertiesData } from '../../views/custom-dashboard/hooks/use-properties-data';
import { DEFAULT_OPTIONS_LIMIT } from '../checkbox-select';
import { PropertyPartnerName } from '../../helpers/api/analytics/types';
import { TranslationKey, translate } from '../../helpers/utils';
import { dimensionTitleTranslationKey } from '../../views/custom-dashboard/bid-modifier-dimension-menu/bm-dimension-breakouts';
import { SelectionRow } from '../dimension-value-dropdown';

export const PropertyNameDimension: FunctionComponent<Props> = ({
  applyFilter,
  selectedPropertyIds,
}) => {
  const subPartners = useSelectedSubPartners();
  const partnerIds = subPartners.map(({ partnerId }) => partnerId);

  return (
    <DimensionValueDropdown
      title={translate(dimensionTitleTranslationKey('propertyName') as TranslationKey)}
      selectionLimit={DEFAULT_OPTIONS_LIMIT}
      selectedValues={selectedPropertyIds}
      cacheKey={ReactQueryKeys.PROPERTIES}
      defaultOptions={null}
      loadOptions={{
        byId: usePropertiesAsOptions,
        byName: inputValue =>
          ApiAnalyticsHelper.fetchPropertiesByNameOrRef(partnerIds, inputValue).then(response =>
            response.map(property2Option),
          ),
      }}
      applyFilter={selectedOptions =>
        applyFilter(
          selectedOptions.map(({ value, label, subLabel }) => ({
            itemId: Number(value),
            name: label,
            partnerRef: subLabel!,
          })),
        )
      }
    />
  );
};

const property2Option = (item: PropertyPartnerName): SelectionRow => ({
  value: item.itemId,
  label: item.name,
  subLabel: item.partnerRef,
});

const usePropertiesAsOptions = (propertyIds: string[]) =>
  usePropertiesData(propertyIds, property2Option);
