import { Props } from './history-event-list.types';
import './history-event-list.css';
import cn from 'classnames';
import { EventStatus, HistoryEvent, getPushTypeForImportType } from '../../../models';
import { TranslationKey, formatDateAndTime, translate } from '../../../helpers/utils';
import { getEventTypeKey } from '../../../services/history-service';
import { HistoryEventFiles } from './event-files';
import { useRemainingPushes } from '../history-toolbar/use-remaining-pushes';
import { useSelectedPartner } from '../../../hooks/use-selected-partner';
import { Loading } from '../../../components/loading';
import { FC } from 'react';

export const HistoryEventList: FC<Props> = ({ events, nextHour }) => (
  <section className="u-border-radius u-padding-w--large u-padding-h--gutter c-history-list">
    {events.map(event => {
      return <HistoryEventListItem key={event.id} event={event} nextHour={nextHour} />;
    })}
  </section>
);

const HistoryEventListItem: FC<{ event: HistoryEvent; nextHour: () => string }> = ({
  event,
  nextHour,
}) => {
  const partnerId = useSelectedPartner().partnerId;
  const { data: remainingPushes = {}, isLoading } = useRemainingPushes(partnerId);

  const shouldShowExpectedPushTime = () =>
    event.isImportEvent() && [EventStatus.PENDING, EventStatus.IN_PROGRESS].includes(event.status);

  function expectedPushTime(): string | React.ReactElement {
    if (isLoading) return <Loading />;

    const relatedPushType = getPushTypeForImportType(event.type);
    if (remainingPushes[relatedPushType] > 0)
      return `Bids will be pushed today at ${nextHour()} UTC`;
    return 'Daily push limit is reached. Bids will be canceled.';
  }

  const getEventDetails = () => {
    if (shouldShowExpectedPushTime()) return expectedPushTime();
    if (event.status === EventStatus.PARTIAL_SUCCESS) return 'partial_success_history_details';
    return getEventTypeKey(event.type) + getEventStatusKey(event.status);
  };

  return (
    <article
      key={event.id}
      className="u-display--flex u-color--juri u-flex-direction--row u-padding-left--large u-padding-right--large u-border--juri-very-light u-text-align--left u-margin-bottom--medium u-radius-2px"
    >
      <div className="c-card__column--huge u-padding-top--gutter u-padding-bottom--gutter u-padding-right--extra-huge">
        <p className="u-color--juri-lighter">
          <span>{formatDateAndTime(new Date(event.createdAt))}</span>
        </p>
        <span className="u-font-weight--bold u-overflow-wrap--break-word">
          {translate(getEventTypeKey(event.type).slice(0, -1) as TranslationKey)}
        </span>
        <p>
          {translate('history_column_event_id')}: {event.id}
        </p>
      </div>
      <div className="c-card__column--medium u-padding-top--gutter u-padding-bottom--gutter u-padding-right--extra-huge">
        <span className="u-color--juri-lighter">{translate('history_column_status')}</span>
        <p
          className={cn('u-font-weight--bold', {
            'u-color--juri-lighter':
              event.status === EventStatus.IN_PROGRESS || event.status === EventStatus.CANCELED,
            'u-color--green': event.status === EventStatus.SUCCESS,
            'u-color--red': event.status === EventStatus.ERROR,
            'u-color--orange-light': event.status === EventStatus.PARTIAL_SUCCESS,
          })}
        >
          {translate(getEventStatusKey(event.status) as TranslationKey)}
        </p>
      </div>
      <div className="c-card__column--large u-padding-top--gutter u-padding-bottom--gutter u-padding-right--large">
        <span className="u-color--juri-lighter">{translate('history_column_details')}</span>
        <p>{translate(getEventDetails() as TranslationKey)}</p>
      </div>
      <HistoryEventFiles event={event} />
    </article>
  );
};

const getEventStatusKey = (status: EventStatus) => STATUS_TRANSLATION_MAP[status] || 'unknown';

const STATUS_TRANSLATION_MAP: Record<EventStatus, string> = {
  [EventStatus.IN_PROGRESS]: 'inprogress_history_key',
  [EventStatus.ERROR]: 'error_history_key',
  [EventStatus.SUCCESS]: 'success_history_key',
  [EventStatus.PARTIAL_SUCCESS]: 'partial_success_history_key',
  [EventStatus.PENDING]: 'pending_history_key',
  [EventStatus.CANCELED]: 'canceled_history_key',
  [EventStatus.WAITING]: 'waiting_history_key',
};
