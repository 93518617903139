import { ComponentProps, FC } from 'react';
import { Icon, IconProps } from '../icon';
import styles from './button-icon.module.css';
import cn from 'classnames';

export type ButtonIconProps = {
  icon: IconProps;
  isElevated?: boolean;
} & ComponentProps<'button'>;

export const ButtonIcon: FC<ButtonIconProps> = ({ icon, isElevated, ...props }) => {
  return (
    <button className={cn(styles.button, { [styles.elevated]: isElevated })} {...props}>
      <Icon {...icon} />
    </button>
  );
};
