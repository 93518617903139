import React from 'react';
import {
  getActiveFilterSetFromLocalStorage,
  saveActiveFilterSetToLocalStorage,
} from '../../../../../helpers/local-storage-helper';
import { Observable } from '../../../../../helpers/utils/observable';
import { FilterStorageKey } from '../../../../../constants';

const selectedNameStore = new Observable('');

export const useSelectFilterSet = (
  name: string,
  filterStorageKey?: FilterStorageKey,
): {
  isSelected: boolean;
  setSelected: (name: string) => void;
  storeSelected: (name: string) => void;
  selectedName: string;
} => {
  const [selectedName, selectedNameSubscriber] = React.useState<string>(
    getActiveFilterSetFromLocalStorage(filterStorageKey),
  );

  React.useEffect(() => selectedNameStore.subscribe(selectedNameSubscriber), []);

  const isSelected = name === selectedName;
  const setSelected = React.useCallback((name: string) => selectedNameStore.set(name), []);
  const storeSelected = React.useCallback(
    (name: string) => {
      saveActiveFilterSetToLocalStorage(name, filterStorageKey);
      setSelected(name);
    },
    [setSelected],
  );

  return { isSelected, setSelected, storeSelected, selectedName };
};
