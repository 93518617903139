import * as React from 'react';
import './input-icon.css';
import { Props } from './input-icon.types';

// @see https://medium.com/@martin_hotell/react-refs-with-typescript-a32d56c4d315
export const InputIcon = React.forwardRef<HTMLInputElement, Props>(
  ({ icon, qaId, onClickIcon, ...rest }, ref) => {
    const Icon = icon;
    return (
      <div className="u-position--relative">
        <input {...rest} ref={ref} data-qa={`${qaId}-input`} />
        {Icon && (
          <Icon
            className="c-inputIcon u-cursor--pointer"
            onClick={onClickIcon}
            data-qa={`${qaId}-icon`}
          />
        )}
      </div>
    );
  },
);

InputIcon.displayName = 'InputIcon';
