import { useEffect, useState } from 'react';
import { DashBoard } from '../../models';
import { getSelectedDashboardId, selectDashboard } from '../../services/dashboard-service';

export function useSelectedDashboard(
  allDashboards: DashBoard[],
): [DashBoard | undefined, (d: DashBoard | undefined) => void] {
  const [dashboard, _setDashboard] = useState<DashBoard>();

  useEffect(() => {
    if (!allDashboards) return;
    const selectedDashboardId = getSelectedDashboardId();
    const selectedDashboard = allDashboards.find(d => d.id === selectedDashboardId);
    setDashboard(selectedDashboard || allDashboards[0]);
  }, [allDashboards]);

  function setDashboard(d: DashBoard | undefined) {
    _setDashboard(d);
    if (d) selectDashboard(d.id);
  }

  return [dashboard, setDashboard];
}
