import * as React from 'react';
import { Props } from './button-loading.types';
import './button-loading.css';
import cn from 'classnames';
import { Loading } from '../loading/loading';

export const ButtonLoading: React.FunctionComponent<Props> = ({ className = '' }) => {
  return (
    <button
      className={cn('e-button e-button--small e-button--tertiary c-button-loading', className)}
      data-qa="button-loading"
    >
      <Loading className="" />
    </button>
  );
};
