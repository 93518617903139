import * as React from 'react';
import { Props } from './widget-show-status.types';
import './widget-show-status.css';

export const WidgetShowStatus: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="c-widget-show-status u-color--juri-lighter position-center center">
      {children}
    </div>
  );
};
