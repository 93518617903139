import React, { FC } from 'react';
import { Props } from './notification.types';
import cn from 'classnames';
import './notification.css';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import { NotificationLevel } from '../../constants';
import { translateHtml } from '../../helpers/utils';

export const Notification: FC<Props> = ({ notification: { message, level }, onClose }) => {
  return (
    <article
      className={cn('c-notification__v2 u-padding--gutter u-border-radius u-display--flex', {
        'u-background--green-very-light u-border-bottom--green-lighter':
          level === NotificationLevel.SUCCESS,
        'u-background--red-very-light u-border-bottom--red-lighter':
          level === NotificationLevel.ERROR,
        'u-background--orange-very-light u-border-bottom--orange-lighter':
          level === NotificationLevel.WARNING,
        'u-background--blue-very-light u-border-bottom--blue-lighter':
          level === NotificationLevel.INFO,
      })}
    >
      <div>
        {Array.isArray(message) ? <ArrayMessage messages={message} /> : translateHtml(message)}
      </div>
      {onClose && <CloseIcon className="u-margin-left--auto u-cursor--pointer" onClick={onClose} />}
    </article>
  );
};

const ArrayMessage: FC<{ messages: string[] }> = ({ messages }) => {
  return (
    <>
      {messages.map((message, index) => (
        <p key={index}>{translateHtml(message)}</p>
      ))}
    </>
  );
};
