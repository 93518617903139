import { FC, useContext, useState } from 'react';
import { EventType } from '../../models';
import styles from './history.module.css';
import { AppContext } from '../../global/context/app-context';
import { HeaderTab } from '../../components/header-tab';
import { Loading } from '../../components/loading';
import { TeapotAnimation } from '../../components/teapot-animation';
import { nextHourUTC, translate } from '../../helpers/utils';
import { HistoryEventList } from './event-list';
import { HistoryToolbar } from './history-toolbar';
import { Notification } from '../../components/notification';
import { useHistoryEvents } from './use-history-events';
import { getPartner } from '../../services/app-service';
import { EVENT_TYPE_TABS } from './history-toolbar/event-type-tabs';
import { SchedulesAndAvailability } from './history-toolbar/schedules-and-availability';
import { Tabs } from '../../components/tabs';
import { useQueryParam } from '../../hooks/use-query-param';
import cn from 'classnames';
import { QUERY_PARAM_ACTIVE_TAB } from '../../constants';
import { Button } from '../../core-ui/components/button/button';

export const History: FC = () => {
  const {
    partner,
    subPartners,
    historyRange: { from, to },
  } = useContext(AppContext);
  const partnerId = getPartner(partner, subPartners).partnerId;

  const [notifications, setNotifications] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState<EventType>(EventType.UNDEFINED);
  const [searchText, setSearchText] = useState('');

  const closeNotification = (index: number) =>
    setNotifications(prev => prev.filter((_, i) => i !== index));

  const [activeTabKey, setActiveTabKey] = useQueryParam(QUERY_PARAM_ACTIVE_TAB);

  const activeTab = EVENT_TYPE_TABS.find(tab => tab.label === activeTabKey) || EVENT_TYPE_TABS[0];

  const { filteredEvents, isLoading, refetch } = useHistoryEvents({
    partnerId,
    from,
    to,
    types: selectedEventType !== EventType.UNDEFINED ? [selectedEventType] : activeTab.jobTypes,
    searchText: searchText,
  });

  function selectableTypes() {
    const types = [EventType.UNDEFINED, ...activeTab.jobTypes];

    return types;
  }

  function content() {
    return (
      <div className="u-padding-top--gutter--small">
        <HistoryToolbar
          selectableTypes={selectableTypes()}
          onChangeFilter={type => setSelectedEventType(type || EventType.UNDEFINED)}
          defaultDates={{ from, to }}
        />
        <SchedulesAndAvailability now={() => new Date()} />
        {eventsList()}
      </div>
    );
  }

  function eventsList() {
    if (isLoading) {
      return (
        <div className={styles.eventList}>
          <Loading
            className={styles.loading}
            isWhite
            message={translate('common_loading_message')}
          />
        </div>
      );
    }

    if (filteredEvents.length === 0) {
      return (
        <div className={styles.eventList}>
          <TeapotAnimation message={translate('bidding_table_no_data_result_message')} />
          <Button
            variant="tertiary"
            text="Retry"
            onClick={refetch}
            className={cn(styles.retryButton)}
          />
        </div>
      );
    }

    return <HistoryEventList events={filteredEvents} nextHour={() => nextHourUTC(new Date())} />;
  }

  return (
    <main className={styles.main}>
      {notifications &&
        notifications.map((notification, index) => (
          <Notification
            key={index}
            notification={notification}
            onClose={() => closeNotification(index)}
          />
        ))}
      <section className={styles.eventSection}>
        <div className={styles.headerTabWrapper}>
          <HeaderTab
            title={translate('history_header_title')}
            searchText={searchText}
            searchPlaceholder={translate('history_toolbar_search_placeholder')}
            onChangeSearch={setSearchText}
          />
        </div>
        <Tabs
          activeTabKey={activeTab.label}
          onTabChange={tab => setActiveTabKey(tab.label)}
          tabs={EVENT_TYPE_TABS.map(tab => ({
            key: tab.label,
            label: tab.label,
            content: content(),
          }))}
        />
      </section>
    </main>
  );
};
