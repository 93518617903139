import React, { FC, useContext } from 'react';
import { Checkbox } from '../../../components/checkbox';
import { AppContext } from '../../../global/context/app-context';

export const CombinedPicker: FC<{
  combinedValue: boolean;
  nonCombinedValue: boolean;
  onCombinedChange: (newValue: boolean) => void;
  onNonCombinedChange: (newValue: boolean) => void;
}> = ({ combinedValue, nonCombinedValue, onCombinedChange, onNonCombinedChange }) => {
  const { partner } = useContext(AppContext);
  const hasSubPartners = partner.subpartners.length > 0;

  if (!hasSubPartners) return null;

  return (
    <>
      <div className="u-padding-vertical--t-medium">
        <Checkbox
          title="Non-combined"
          onChange={() => onNonCombinedChange(!nonCombinedValue)}
          isChecked={nonCombinedValue}
        />
        <Checkbox
          title="Combined"
          onChange={() => onCombinedChange(!combinedValue)}
          isChecked={combinedValue}
        />
      </div>
      <hr />
    </>
  );
};
