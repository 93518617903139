import ApiHistoryHelper from '../helpers/api/history/api-history-helper';
import { EventType, HistoryEvent, Partner } from '../models';
import { getPartner } from './app-service';

export async function fetchEvents(
  partner: Partner,
  subbrands: ReadonlyArray<Partner>,
  from: Date,
  to: Date,
  types: EventType[],
): Promise<HistoryEvent[]> {
  const partnerId: number = getPartner(partner, subbrands).partnerId;
  const events: HistoryEvent[] = await ApiHistoryHelper.fetchEvents(partnerId, from, to, types);
  return events.filter(isValidType);
}

export const filterEvents = (events: HistoryEvent[], search: string): HistoryEvent[] => {
  const lowerCaseSearch: string = search.toLocaleLowerCase();

  return events.filter((jobTmp: HistoryEvent) => {
    const jobId = jobTmp.id + '';
    return jobId.includes(lowerCaseSearch);
  });
};

export const cancelFetchEvents = () => {
  ApiHistoryHelper.cancelAllRequests();
};

const isValidType = (event: HistoryEvent) => {
  return isShownType(event.type);
};

export const isShownType = (type: EventType) => {
  return (
    type !== EventType.CAMPAIGN_REFRESH &&
    type !== EventType.CANCEL_PENDING_FILES &&
    type !== EventType.CAMPAIGN_TO_MANUAL_RESET &&
    type !== EventType.REPORTS
  );
};

export const getEventTypeKey = (type: EventType): string => {
  switch (type) {
    case EventType.EDIT_CPC_UI:
      return 'history_edit_cpc_ui_';
    case EventType.EDIT_BM_UI:
      return 'history_edit_bm_ui_';
    case EventType.REVERT_PENDING_CHANGES:
      return 'history_revert_ui_';
    case EventType.REVERT_BM:
      return 'history_revert_bm_ui_';
    case EventType.FILE_BIDDING:
      return 'history_file_bidding_';
    case EventType.PUSH:
      return 'history_push_';
    case EventType.PUSH_BM:
      return 'history_push_bm_';
    case EventType.FILE_PROPERTY_GROUP_ID:
      return 'history_file_property_group_id_';
    case EventType.FILE_CAMPAIGN_ALLOCATION:
      return 'history_file_campaign_allocation_';
    case EventType.TYPE_CONTENT_HUB_TASK:
      return 'history_dpi_push_';
    case EventType.FILE_BIDDING_MODIFIERS:
      return 'history_file_bid_modifier_';
    case EventType.CONTENT_HUB_BID_MODIFIER_IMPORT:
      return 'history_dpi_bm_import_';
    case EventType.PUSH_PROPERTY_GROUPS:
      return 'history_push_property_group_id_';
    case EventType.FILE_CPA_VALUE:
      return 'history_file_cpa_value_';
    case EventType.PUSH_CAMPAIGN_ALLOCATIONS:
      return 'history_property_allocation_push_';
    case EventType.PUSH_CPA_VALUE:
      return 'history_cpa_value_push_';
    case EventType.EDIT_CPA_VALUE_UI:
      return 'history_edit_cpa_value_ui_';
    case EventType.REVERT_PROPERTY_GROUPS:
      return 'history_revert_property_groups_ui_';
    case EventType.REVERT_PENDING_CAMPAIGN_ALLOCATION:
      return 'history_revert_pending_campaign_allocation_ui_';
    case EventType.REVERT_PENDING_CPA_VALUE:
      return 'history_revert_pending_cpa_value_ui_';
    case EventType.CONTENT_HUB_CPA_ALLOC_IMPORT:
      return 'history_dpi_cpa_alloc_';
    case EventType.CONTENT_HUB_CPA_VALUE_IMPORT:
      return 'history_dpi_cpa_value_';
    case EventType.TYPE_CONTENT_HUB_TASK_MANUAL:
      return 'history_dpi_push_manual_';
    case EventType.CONTENT_HUB_BID_MODIFIER_IMPORT_MANUAL:
      return 'history_dpi_bm_import_manual_';
    case EventType.CONTENT_HUB_CPA_ALLOC_IMPORT_MANUAL:
      return 'history_dpi_cpa_alloc_manual_';
    case EventType.CONTENT_HUB_CPA_VALUE_IMPORT_MANUAL:
      return 'history_dpi_cpa_value_manual_';
    case EventType.CONTENT_HUB_PROPERTY_GROUP_IMPORT_MANUAL:
      return 'history_dpi_property_groups_manual_';
    case EventType.CONTENT_HUB_SL_IMPORT_MANUAL:
      return 'history_dpi_sl_bids_manual_';
    case EventType.CONTENT_HUB_SL_BM_IMPORT_MANUAL:
      return 'history_dpi_sl_bid_modifiers_manual_';
    case EventType.CONTENT_HUB_SL_BUDGET_CAP_MANUAL:
      return 'history_dpi_sl_budget_cap_manual_';
    case EventType.INVENTORY_IMPORT_MANUAL:
      return 'history_dpi_inventory_import_manual_';
    case EventType.FILE_SL_BM:
      return 'history_file_sl_bm_';
    case EventType.FILE_SL_BID:
      return 'history_file_sl_bid_';
    case EventType.PUSH_SL_BID:
      return 'history_push_sl_bid_';
    case EventType.FILE_SL_BUDGET_CAP:
      return 'history_file_sl_budget_cap_';
    case EventType.PUSH_SL_BUDGET_CAP:
      return 'history_push_sl_budget_cap_';
    case EventType.REVERT_SL_BIDS:
      return 'history_revert_pending_sl_bid_';
    case EventType.REVERT_SL_BUDGET_CAP:
      return 'history_revert_pending_sl_budget_cap_';
    case EventType.REVERT_SL_MODIFIERS:
      return 'history_revert_pending_sl_bid_modifiers_';
    case EventType.CONTENT_HUB_SL_BUDGET_CAP:
      return 'history_dpi_sl_budget_cap_';
    case EventType.UNDEFINED:
      return 'history_toolbar_filter_show_all_';
    case EventType.EDIT_SL_BID_UI:
      return 'history_edit_sl_bid_ui_';
    case EventType.CONTENT_HUB_PROPERTY_GROUP_IMPORT:
      return 'history_dpi_property_groups_';
    case EventType.CONTENT_HUB_SL_IMPORT:
      return 'history_dpi_sl_bids_';
    case EventType.CONTENT_HUB_SL_BM_IMPORT:
      return 'history_dpi_sl_bid_modifiers_';
    case EventType.INVENTORY_IMPORT:
      return 'history_dpi_inventory_import_';
    case EventType.COMBINED_BIDDING:
      return 'history_dpi_combined_bidding_';
    case EventType.PUSH_SL_BM:
      return 'history_push_sl_bm_';
    case EventType.INVENTORY_IMPORT_TI:
      return 'history_inventory_import_';
    case EventType.FILE_CPA_BM:
      return 'history_file_cpa_bm_';
    case EventType.PUSH_CPA_BM:
      return 'history_push_cpa_bm_';
    case EventType.CONTENT_HUB_CPA_BM_IMPORT_MANUAL:
      return 'history_dpi_cpa_bid_modifiers_manual_';
    case EventType.CONTENT_HUB_CPA_BM_IMPORT_SCHEDULED:
      return 'history_dpi_cpa_bid_modifiers_';
    case EventType.CPA_BM_UI_EDIT:
      return 'history_cpa_edit_bm_ui_';
    default:
      return 'history_unknown_';
  }
};
