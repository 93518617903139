import { getAccessToken } from '../profile-helper';
import { sleep } from '../utils';

const baseURL = process.env.REACT_APP_URL?.replace('http', 'ws');

export abstract class WebSocketHelper {
  private ws!: WebSocket;
  private readonly url: string;
  private retryTime = 1000;

  protected constructor(url: string) {
    this.url = url;
    this.connect();

    this.ws.onclose = this.closeTmp.bind(this);
  }

  private connect() {
    const token = getAccessToken();
    if (!token) throw Error('Unauthorized user');
    const previousWS = this.ws;

    this.ws = new WebSocket(`${baseURL}/${this.url}?auth=${token}`);
    if (previousWS) {
      this.ws.onopen = previousWS.onopen;
      this.ws.onclose = previousWS.onclose;
      this.ws.onerror = previousWS.onerror;
      this.ws.onmessage = previousWS.onmessage;
    }
  }

  private async closeTmp() {
    await sleep(this.retryTime);
    this.retryTime = this.retryTime * 2;
    this.reconnect();
  }

  public reconnect() {
    this.connect();
  }

  public onOpen(fn: (e: Event) => void): void {
    this.ws.onopen = event => fn(event);
  }

  public onMessage(fn: (e: MessageEvent) => void): void {
    this.ws.onmessage = event => fn(event);
  }

  public onError(fn: (e: Event) => void): void {
    this.ws.onerror = event => fn(event);
  }

  // closeTmp always needs to be called for retries, cannot be overwritten by the handler
  public onClose(fn: (e: CloseEvent) => void): void {
    this.ws.onclose = event => {
      this.closeTmp();
      fn(event);
    };
  }

  // TODO just for testing purposes (remove)
  public send(value: string) {
    this.ws.send(value);
  }

  // TODO just for testing purposes (remove)
  public close() {
    this.ws.close();
  }
}
