import { createRef, FC, useState } from 'react';
import { Menu } from '../../../components/menu';
import { CHART_BUILDER_CREATE_START, FEATURE_FLAGS } from '../../../constants';
import { translate } from '../../../helpers/utils';
import { useOnClickOutside } from '../../../hooks/use-on-click-outside';
import { WidgetType } from '../../../models';
import TrackerService from '../../../services/tracker/tracker-service';
import './add-widget-dropdown.css';
import { AddWidgetDropdownProps } from './add-widget-dropdown.types';
import { AddWidgetItemMenu } from './add-widget-item-menu';
import { Button } from '../../../core-ui/components/button/button';

export const AddWidgetDropdown: FC<AddWidgetDropdownProps> = ({ onSelectWidget }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = createRef<HTMLDivElement>();
  useOnClickOutside(ref, () => setIsOpen(false));
  const menuWidgets = [
    WidgetType.CHART_BUILDER,
    WidgetType.TIMELINE,
    WidgetType.YESTERDAY_OVERVIEW,
    WidgetType.POS_OVERVIEW,
    WidgetType.MBLU,
  ];

  if (FEATURE_FLAGS.enableGlobalInventoryWidget) {
    menuWidgets.splice(3, 0, WidgetType.GLOBAL_INVENTORY);
  }

  const onSelect = (index: number) => {
    onSelectWidget(menuWidgets[index]);
    setIsOpen(false);
    if (menuWidgets[index] === WidgetType.CHART_BUILDER)
      TrackerService.track(CHART_BUILDER_CREATE_START);
  };

  const generateMenuItems = () =>
    menuWidgets.map((item, index) => <AddWidgetItemMenu key={index} type={item} />);

  return (
    <div ref={ref}>
      <Button
        text={translate('add_dashboard_dropdown__title')}
        onClick={() => setIsOpen(!isOpen)}
        icon={{ name: 'plus' }}
      />

      <div className="c-add-widget-dropdown u-position--relative">
        {isOpen && <Menu body={{ items: generateMenuItems() }} onSelectItem={onSelect} />}
      </div>
    </div>
  );
};
