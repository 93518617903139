import React, { useState } from 'react';
import { YAxis, XAxis, ResponsiveContainer, Tooltip, BarChart, Bar, Legend } from 'recharts';
import { Color } from '../../constants';
import { Props } from './chart.types';
import './chart.css';
import cs from 'classnames';
import { WidgetTooltip } from '../widget-tooltip';

const ChartBuilderBarChart: React.FunctionComponent<Props> = ({
  isFullScreen,
  columns,
  data,
  columnsColor,
  xAxisDataKey = 'date',
  tickFormatterX,
  tickFormatterY,
  toolTipTitle,
  domain,
  stackID,
  tickLineX = false,
  tickLineY = false,
  highlightBar = false,
  xAxisLabel,
  leftYAxisLabel,
}) => {
  const [isBarVisible, setBarVisibility] = useState<{ [key: string]: boolean }>(
    data.reduce((acc, { key }) => ({ ...acc, [key as string]: false }), {}),
  );
  const renderColorfulLegendText = (value: string, entry: any) => (
    <span style={{ color: entry.inactive ? '#9ba2a6' : 'var(--grey-800)' }}>
      {columns.find(columnTmp => columnTmp.key === value)!.name}
    </span>
  );

  return (
    <ResponsiveContainer
      width="100%"
      height={isFullScreen ? 620 : 270}
      className={cs('chart-builder--chart')}
    >
      <BarChart data={data} margin={{ left: 30, top: 16, right: 16, bottom: 16 }}>
        {[...columns].reverse().map(c => (
          <Bar
            key={c.key}
            dataKey={c.key}
            stackId={stackID}
            fill={columnsColor![c.key]}
            hide={isBarVisible[c.key]}
          />
        ))}

        <XAxis
          dataKey={xAxisDataKey}
          tickFormatter={tickFormatterX}
          axisLine={{ stroke: Color.Grey200 }}
          stroke={Color.Grey700}
          tickLine={tickLineX}
          tickMargin={6}
          label={{
            value: xAxisLabel,
            position: 'bottom',
            fill: Color.Grey700,
          }}
        />
        <YAxis
          domain={domain}
          tickFormatter={tickFormatterY}
          stroke={Color.Grey600}
          tickLine={tickLineY}
          tickMargin={8}
          label={{
            value: leftYAxisLabel,
            angle: -90,
            position: 'left',
            offset: 15,
            fill: Color.Grey500,
          }}
          axisLine={false}
        />
        <Tooltip
          cursor={{ fill: highlightBar ? 'var(--blue-300)' : 'none' }}
          content={({ label, payload }: { label: string; payload: any[] }) => {
            if (!payload) {
              return;
            }
            return (
              <WidgetTooltip
                title={toolTipTitle(label)}
                metrics={payload.reverse().map((payloadTmp, index) => {
                  return {
                    name: columns.find(metricTmp => metricTmp.key === payloadTmp.dataKey)!.name,
                    wrapper: columns[index].wrapper,
                    color: columnsColor![payloadTmp.dataKey],
                    value: payloadTmp.value,
                  };
                })}
              />
            );
          }}
        />
        <Legend
          verticalAlign="top"
          align="center"
          height={36}
          iconType="circle"
          onClick={e =>
            setBarVisibility({ ...isBarVisible, [e.dataKey]: !isBarVisible[e.dataKey] })
          }
          formatter={renderColorfulLegendText}
          iconSize={8}
          wrapperStyle={{ height: 30, top: 0, width: '100%' }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartBuilderBarChart;
