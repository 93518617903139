import React, { FC } from 'react';
import { Props } from './grid-footer.types';
import './grid-footer.css';
import { useStickyColumn } from '../hooks/use-sticky-column';
import { useStickyFooter } from '../hooks/use-sticky-footer';
import cs from 'classnames';
import { GridCell } from '../grid-cell';
import { Column, ColumnType, Row, TextColumn } from '..';
import { isColumnAlignedToTheLeft } from '../utils/grid-utils';

export const GridFooter: FC<Props> = ({
  columns,
  rows,
  numStickyColumns,
  numColumnsLeftAlignment,
  configuration,
}) => {
  const { getCustomRowStyle, refFooter } = useStickyFooter();
  const { getCustomColumnStyle, isColumnSticky } = useStickyColumn(
    columns,
    numStickyColumns,
    refFooter,
  );
  const excludedCellTypes = [ColumnType.CHECKBOX];

  const buildStyle = (index: number, footerElementPosition: number, columnsLength: number) => {
    return {
      ...(isColumnSticky(index)
        ? getCustomColumnStyle(index, index >= numStickyColumns ? 1 : 0, columnsLength)
        : undefined),
      ...getCustomRowStyle(footerElementPosition),
    };
  };

  const getValue = (column: Column, row: Row): string | boolean =>
    column.type !== ColumnType.CHECKBOX ? row[column.key] : '';

  const convertCustomColumnToTextColumn = (column: Column): Column => {
    if (column.type !== ColumnType.CUSTOM) return column;
    return {
      ...column,
      type: ColumnType.TEXT,
    } as TextColumn;
  };

  return (
    <tfoot className="c-grid-footer">
      {rows.map((rowTmp, index) => (
        <tr key={index} className="c-grid-footer" ref={refFooter}>
          {columns.map(convertCustomColumnToTextColumn).map((columnTmp, indexTmp) => (
            <td
              key={indexTmp}
              style={buildStyle(indexTmp, rows.length - index - 1, columns.length)}
              className={cs('u-border u-padding-left--gutter u-padding-right--gutter', {
                'c-grid-footer__sticky': isColumnSticky(indexTmp),
                'c-grid-footer__border-sticky': indexTmp === numStickyColumns - 1,
                'c-grid-footer__column-aligned-right': !isColumnAlignedToTheLeft(
                  columns,
                  numStickyColumns,
                  numColumnsLeftAlignment,
                  indexTmp,
                ),
              })}
            >
              {!excludedCellTypes.includes(columnTmp.type) && (
                <span>
                  <GridCell
                    configuration={configuration}
                    value={getValue(columnTmp, rowTmp)}
                    column={columnTmp}
                    row={rowTmp}
                    rowIndex={index}
                  />
                </span>
              )}
            </td>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};
