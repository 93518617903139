import { FC } from 'react';
import { CheckboxProps } from './checkbox.types';
import styles from './checkbox.module.css';
import cn from 'classnames';

export const Checkbox: FC<CheckboxProps> = ({
  isChecked,
  title,
  onChange,
  children,
  classNames,
  disabled,
}) => {
  return (
    <label className={cn(styles.checkbox, classNames)}>
      <input type="checkbox" checked={isChecked} disabled={disabled} onChange={onChange} />
      <p className="u-margin-left--medium u-width--100">
        {title}
        {children}
      </p>
    </label>
  );
};
