import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../global/context/app-context';
import styles from './members.module.css';
import { Checkbox } from '../../../components/checkbox';
import { useMembers } from './use-members';
import { useMembersColumns } from './use-members-columns';
import { useErrorNotificationEffect } from '../../../hooks/use-error-notification-effect';
import { PartnerSelector } from '../../../global/app/header/partner-selector';
import { MemberAdministration, Partner } from '../../../models';
import { MemberDetails } from './member-details';
import { Grid } from '../../../components/grid';
import { GRID_VIEW_CONFIGURATION } from '../../../constants';
import { useQueryParamPagination } from './use-query-param-pagination';
import { useMembersFilters } from './use-members-filters';
import { useDebounce } from '../../../hooks/use-debounce';

export const Members: FC = () => {
  const [member, setMember] = useState<MemberAdministration | null>(null);
  if (member) return <MemberDetails member={member} onBack={() => setMember(null)} />;
  return <MembersList onMemberClicked={setMember} />;
};

const MembersList: FC<{
  onMemberClicked: (member: MemberAdministration) => void;
}> = props => {
  const { user } = useContext(AppContext);
  const filters = useMembersFilters();

  const columns = useMembersColumns(props.onMemberClicked);
  const {
    data = [],
    isLoading,
    error,
  } = useMembers({
    includeTrivagoEmployees: filters.includeTrivagoEmployees,
    partnerId: filters.partnerId,
    email: useDebounce(filters.email, 300),
  });
  const pagination = useQueryParamPagination(data);
  const isMounted = useRef(false);

  useErrorNotificationEffect(error?.message);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    pagination.setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.includeTrivagoEmployees, filters.email, filters.partnerId]);

  const flatPartners = user.partners.flatMap(p => (p.subpartners.length > 0 ? p.subpartners : [p]));
  const selectablePartners = [allPartnersOption, ...flatPartners];

  return (
    <div className={styles.members}>
      <div className={styles.filters}>
        <input
          value={filters.email}
          onChange={e => filters.setEmail(e.target.value)}
          className={styles.searchInput}
          type="email"
          placeholder="Search by email"
        />
        <PartnerSelector
          onChange={id => filters.setPartnerId(id)}
          selectedValue={filters.partnerId}
          partners={selectablePartners}
        />
        <Checkbox
          isChecked={filters.includeTrivagoEmployees}
          onChange={() => filters.setIncludeTrivagoEmployees(!filters.includeTrivagoEmployees)}
          title="Include trivago employees"
        />
      </div>
      <Grid
        columns={columns}
        rows={pagination.paginatedData}
        numColumnsLeftAlignment={6}
        isLoading={isLoading}
        pagination={pagination}
        configuration={GRID_VIEW_CONFIGURATION}
      />
    </div>
  );
};

const allPartnersOption: Partner = {
  partnerId: 0,
  // The leading space is a hack to make the "All partners" option appear first in the list
  name: ' All partners',
  subpartners: [],
  isCpaEligible: false,
  isTrackingWorking: false,
  locales: [],
  type: 0,
};
