import { RequestHelper } from './request-helper';
import { Profile } from '../../models';
import { AxiosError } from 'axios';

const BASE_URL = '/partner/auth';
const LOGOUT_URL = `${BASE_URL}/logout`;
const REFRESH_URL = `${BASE_URL}/refresh`;

const JLOO_LOGOUT_URL = `${process.env.REACT_APP_LOGOUT_BASE}?continue=${document.location.origin}`;

export function getLogoutUrl(): string {
  return JLOO_LOGOUT_URL;
}

class ApiAuthHelper extends RequestHelper {
  private static instance: ApiAuthHelper;

  public static getInstance() {
    if (!ApiAuthHelper.instance) {
      ApiAuthHelper.instance = new ApiAuthHelper();
    }

    return ApiAuthHelper.instance;
  }

  public async login(code: string): Promise<Profile> {
    try {
      const response = await this.getRequest(`${BASE_URL}/${code}`);
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'auth_login_error');
      return {} as Profile;
    }
  }

  public async refresh(code: string): Promise<Profile> {
    try {
      const response = await this.getRequest(`${REFRESH_URL}/${code}`);
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'auth_refresh_error');
      return {} as Profile;
    }
  }

  public async logout(token: string) {
    try {
      const { status } = await this.postRequest(`${LOGOUT_URL}/${token}`, {});
      if (status !== 200) {
        throw Error('logout_error_message');
      }
    } catch (e) {
      const error = e as AxiosError;
      this.handleError(error, error.message);
    }
  }
}

export default ApiAuthHelper.getInstance();
