import { CellRas } from '../components/cell-ras';
import { ReactText } from 'react';
import { HyperlinkCell } from '../components/hyperlink-cell/hyperlink-cell';
import { Row } from '../components/grid';
import { ClickableCellProps } from './grid-formatter';
import { translate } from './utils';
import { ReportDownloadCell } from '../components/report-download-cell';
import { FileFormat } from '../models';

export const formatColumnRas = () => (ras: string | number) => <CellRas ras={ras} />;

export const formatClickableColumn =
  (clickableCellProps: ClickableCellProps, columnName: string) =>
  (cellValue: ReactText, row: Row) => {
    const displayValue =
      clickableCellProps?.displayValue?.(row) ?? cellValue ?? translate('common_not_available');
    const hasValue = !!cellValue;

    if (clickableCellProps.fileDownloadProps) {
      return (
        <ReportDownloadCell
          displayValue={displayValue}
          onClick={async fileFormat => clickableCellProps?.action?.(row, fileFormat)}
          hasValue={hasValue}
          fileFormats={clickableCellProps.fileDownloadProps.fileFormats ?? [FileFormat.CSV]}
          columnName={columnName}
        />
      );
    }
    return (
      <HyperlinkCell
        displayValue={displayValue}
        onClick={() => clickableCellProps?.action?.(row)}
        hasValue={hasValue}
      />
    );
  };
