import { Partner, Notification, BaseNotification } from '../models';
import ApiNotificationsHelper from '../helpers/api/notifications/api-notifications-helper';
import {
  EVENT_DRAWER_NOTIFICATION,
  EVENT_NOTIFICATION,
  EVENT_SNACKBAR_NOTIFICATION,
  MAX_NOTIFICATIONS,
} from '../constants';
import { data2DrawerNotification } from '../helpers/transformers';
import { removeProfile } from './profile-service';
import { Event } from './event';

export const fetchNotifications = (
  partner: Partner,
  subpartners: Partner[],
  pagination = {
    offset: 0,
    limit: MAX_NOTIFICATIONS,
  },
): Promise<Notification[]> => {
  const partnersId = new Set<number>();
  if (subpartners.length === 0) {
    partnersId.add(partner.partnerId);
  } else {
    subpartners.forEach(({ partnerId }) => partnersId.add(partnerId));
  }

  return ApiNotificationsHelper.fetchNotifications(
    Array.from(partnersId),
    pagination,
    partner,
    subpartners,
  );
};

export const showNotification = (notification: BaseNotification) => {
  Event.emit(EVENT_NOTIFICATION, notification);
};

export const showSnackbarNotification = (notification: Notification) => {
  Event.emit(EVENT_SNACKBAR_NOTIFICATION, notification);
};

export const showDrawerNotification = (notification: Notification) => {
  Event.emit(EVENT_DRAWER_NOTIFICATION, notification);
};

export const onOpenConnection = (_: Event) => ({});

export const onConnectionError = (_: Event) => ({});

export const onConnectionClosed = (e: CloseEvent) => {
  if (e.code === 1008) {
    removeProfile();
    document.location.reload();
  }
};

export const onReceiveMessage = (e: MessageEvent, partner: Partner, subpartners: Partner[]) => {
  const message = JSON.parse(e.data);

  const messagePartnerId = message.metadata['PARTNER_ID'];

  const subPartner = subpartners.find(p => p.partnerId === messagePartnerId);

  const isSameSubbrand =
    subPartner !== undefined && subPartner.partnerId === message.metadata.PARTNER_ID;

  if (partner.partnerId === message.metadata.PARTNER_ID || isSameSubbrand) {
    const notification = data2DrawerNotification(message, subPartner ?? partner);
    showSnackbarNotification(notification);
    showDrawerNotification(notification);
  }
};
