import React, { FC, useContext, useState } from 'react';
import { ScrollableModal } from '../../../components/scrollable-modal';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';
import { WrapperErrorHint } from '../../../components/wrapper-error-hint';
import { NotificationLevel } from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { ReportsApi } from '../../../helpers/api/reports/reports-api';
import { translate } from '../../../helpers/utils';
import { showNotification } from '../../../services/notification-service';
import cs from 'classnames';
import { getErrorMessage } from '../util';
import { getPartner } from '../../../services/app-service';

interface SftpFolderSetupModalProps {
  onClose: (saved: boolean) => void;
  initialFolderName?: string | null;
}

export const SftpFolderSetupModal: FC<SftpFolderSetupModalProps> = props => {
  const [sftpFolderPath, setSftpFolderPath] = useState(props.initialFolderName || '');
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setLoading] = useState(false);
  const { partner, subPartners } = useContext(AppContext);
  const partnerId = getPartner(partner, subPartners).partnerId;

  async function save() {
    try {
      setLoading(true);
      await ReportsApi.updateSftpFolderPath(partnerId, sftpFolderPath);
      props.onClose(true);
      showNotification({
        level: NotificationLevel.SUCCESS,
        message: 'SFTP folder path saved!',
      });
    } catch (e) {
      console.error(e);
      setError(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }

  function closeModal() {
    if (isLoading) return;
    props.onClose(false);
  }

  return (
    <ScrollableModal
      title="Reports settings"
      onClose={closeModal}
      bodyStyle={{ overflow: 'initial' }}
      primaryButton={{
        title: isLoading ? 'Saving...' : 'Save',
        actionFn: save,
        isDisabled: isLoading || !sftpFolderPath,
      }}
      secondaryButton={{ title: translate('common_cancel'), actionFn: closeModal }}
      footer={{ isTopBorderHidden: true }}
    >
      <div className="u-display--flex u-flex-direction--column u-padding-bottom--large u-padding-w--large">
        <div className="u-display--flex u-cursor--default">
          <span className="u-font-weight--bold">{translate('reports_enable_modal_folder')}</span>
          <div className="u-margin-left--auto">
            <TooltipWrapper
              message={translate('reports_modal_tooltip_folder')}
              position={TooltipPosition.LEADING_CENTER}
              noTinyPaddingTop={true}
              isAddingPadding={true}
            >
              <span className="u-margin-left--auto u-color--juri-lighter u-font-weight--bold">
                (?)
              </span>
            </TooltipWrapper>
          </div>
        </div>
        <WrapperErrorHint
          message={error || ''}
          isShown={!!error}
          extraClasses="c-reports-type-enable-modal__error-hint"
        >
          <input
            type="text"
            value={sftpFolderPath}
            onChange={e => setSftpFolderPath(e.target.value)}
            className={cs('c-reports-type-enable-modal__input', {
              'c-reports-type-enable-modal__error': !!error,
            })}
            placeholder={translate('reports_enable_modal_folder_placeholder')}
          />
        </WrapperErrorHint>
      </div>
    </ScrollableModal>
  );
};
