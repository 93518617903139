import * as React from 'react';
import { Props } from './cell-ras.types';
import './cell-ras.css';
import cn from 'classnames';
import { translate } from '../../helpers/utils';

export const CellRas: React.FunctionComponent<Props> = ({ ras }) => {
  const hasRateAccuracyScore = !(ras === 0 || ras === '-' || ras === null);

  return (
    <div>
      {hasRateAccuracyScore ? (
        <>
          <span
            className={cn({
              'u-color--red': ras === 'Poor' || ras === 'Failed',
              'u-color--green': ras === 'Good' || ras === 'Excellent',
              'u-color--orange-dark': ras === 'Average' || ras === 'At Risk',
            })}
          >
            {ras}{' '}
          </span>
        </>
      ) : (
        <span> {translate('analytics_score_na')} </span>
      )}
    </div>
  );
};
