import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { ModalProvider } from 'react-modal-hook';
import { Redirect, useLocation } from 'react-router-dom';
import { Guard } from '../../components/guard/guard';
import { LOGIN_ROUTE } from '../../constants';
import { AppContext } from '../context/app-context';
import { FilterContextProvider } from '../context/filter-context';
import AppRoute from './app-route/app-route';
import { AppWebSocket } from './app-web-socket';
import './app.css';
import { Props } from './app.types';
import { SnackbarContext } from './snackbar/snackbar-context';
import { SnackbarMessage } from './snackbar/snackbar-message';
import { useApp } from './use-app';

export const queryClient = new QueryClient();

export const App: React.FunctionComponent<Props> = () => {
  const state = useApp();
  const [visibleSnackbarMessages, setVisibleSnackbarMessages] = React.useState<SnackbarMessage[]>(
    [],
  );
  const initAppContext = () => {
    return {
      user: state.profile!.user,
      onUpdateUser: state.onUpdateUser,
      partner: state.selectedPartner!,
      onUpdatePartner: state.onUpdatePartner,
      subPartners: state.subbrands,
      onUpdateSubpartners: state.onUpdateSubpartners,
      locales: state.locales,
      selectedLocales: state.POSlocales,
      onUpdateLocales: state.onUpdateLocales,
      dateRangePeriod: state.dateRangePeriod,
      onUpdatePeriod: state.onUpdatePeriod,
      compareDatePeriods: state.compareDatePeriods,
      onUpdateCompareDatePeriods: state.onUpdateCompareDatePeriods,
      historyRange: state.historyRange,
      onUpdateHistoryRange: state.onUpdateHistoryRange,
      regions: state.regions,
    };
  };
  const location = useLocation();

  React.useEffect(() => {
    localStorage.setItem('last_location', JSON.stringify(location));
  }, [location]);

  if (state.isLoadingProfile && state.profile === undefined) {
    return <></>;
  }

  if (state.profile === undefined) {
    return <Redirect to={LOGIN_ROUTE} />;
  }

  return (
    <Guard onRetrieveUser={state.onRetrieveUser}>
      <AppContext.Provider value={initAppContext()}>
        <QueryClientProvider client={queryClient}>
          <FilterContextProvider>
            <AppWebSocket>
              <SnackbarContext.Provider
                value={{ visibleSnackbarMessages, setVisibleSnackbarMessages }}
              >
                <ModalProvider>
                  <AppRoute />
                </ModalProvider>
              </SnackbarContext.Provider>
            </AppWebSocket>
          </FilterContextProvider>
        </QueryClientProvider>
      </AppContext.Provider>
    </Guard>
  );
};
