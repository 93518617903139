import { subWeeks } from 'date-fns';
import { FC, useState } from 'react';
import { TooltipWrapper } from '../../../../components/tooltip-wrapper';
import { NotificationLevel } from '../../../../constants';
import { ButtonAnchoredMenu } from '../../../../core-ui/components/menu/button-anchored-menu';
import { MenuItem } from '../../../../core-ui/components/menu/menu';
import { translate } from '../../../../helpers/utils';
import { EventStatus, EventType, HistoryEvent } from '../../../../models';
import { showNotification } from '../../../../services/notification-service';
import styles from './history-event-files.module.css';
import { useDownloadHistoryEventFile } from './use-download-history-event-file';

export const HistoryEventFiles: FC<{ event: HistoryEvent }> = ({ event }) => {
  if (event.type === EventType.INVENTORY_IMPORT_TI) return <InventoryFiles event={event} />;

  return (
    <div className={styles.historyEventFiles}>
      <DownloadButton
        event={event}
        options={[
          { id: 'source', label: 'Source file', disabled: !event.hasSourceFile() },
          { id: 'summary', label: 'Summary file', disabled: !event.hasSummaryFile() },
        ]}
      />
    </div>
  );
};

const InventoryFiles: FC<{ event: HistoryEvent }> = ({ event }) => {
  const options: any[] = [
    { id: 'source', label: 'Source file' },
    { id: 'errors', label: 'Errors file' },
    { id: 'warnings', label: 'Warnings file' },
    { id: 'deactivated', label: 'Deactivated properties file' },
    { id: 'mismatch', label: 'Mismatch data' },
    { id: 'new_references', label: 'New references file' },
  ];
  const isEventOlderThanAWeek = new Date(event.createdAt) < subWeeks(new Date(), 1);
  const eventHasNonFinalStatus = [
    EventStatus.IN_PROGRESS,
    EventStatus.PENDING,
    EventStatus.WAITING,
  ].includes(event.status);
  const isButtonDisabled = isEventOlderThanAWeek || eventHasNonFinalStatus;
  const disabledMessage = (): string | null => {
    if (eventHasNonFinalStatus) return 'Inventory files are available only for completed events';
    if (isEventOlderThanAWeek) return 'Inventory files are available only for a week';
    return null;
  };

  return (
    <div className={styles.historyEventFiles}>
      <TooltipWrapper message={disabledMessage()} hideTooltip={!isButtonDisabled}>
        <DownloadButton event={event} options={options} disabled={isButtonDisabled} />
      </TooltipWrapper>
    </div>
  );
};

const DownloadButton: FC<{
  options: Pick<MenuItem, 'id' | 'label' | 'disabled'>[];
  event: HistoryEvent;
  disabled?: boolean;
}> = ({ options, event, disabled }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadHistoryEventFile = useDownloadHistoryEventFile();

  async function downloadFile(fileType: string) {
    try {
      setIsDownloading(true);
      await downloadHistoryEventFile(event, fileType);
    } catch (error) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((error as any).message),
      });
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <ButtonAnchoredMenu
      button={{ text: 'Download', isLoading: isDownloading, disabled, variant: 'tertiary' }}
      menu={{
        anchorOrigin: { horizontal: 'right' },
        className: styles.downloadMenu,
        items: options.map(o => ({
          ...o,
          icon: { name: 'download' },
          onClick: () => downloadFile(String(o.id)),
        })),
      }}
    />
  );
};
