// @see: https://github.com/vbrdnk/typescript-publisher-subscriber/blob/master/dist/pub-sub.ts

type callbackFunc<T> = (data: T) => void;
type EventType<T> = { [key: string]: Array<callbackFunc<T>> };

export class Event {
  public static subscribe<T>(eventName: string, func: callbackFunc<T>) {
    Event.events[eventName] = [func];
  }

  public static unsubscribe<T>(eventName: string, func: callbackFunc<T>) {
    if (Event.events[eventName]) {
      for (let i = 0; i < Event.events[eventName].length; i++) {
        if (Event.events[eventName][i] === func) {
          Event.events[eventName].splice(i, 1);
          break;
        }
      }
    }
  }

  public static emit(eventName: string, data: any) {
    if (Event.events[eventName]) {
      Event.events[eventName].forEach((fn: (data: any) => any) => fn(data));
    }
  }
  private static events: EventType<any> = {};
}
