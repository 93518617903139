import React, { useRef, useState } from 'react';
import { ArrowIcon } from 'tcp-react-icons';
import iconUpload from '../../../global/app/selector-bar/file-uploader/upload-arrow.svg';
import { EventType, Partner } from '../../../models';
import './schedules-and-availability.css';

import cs from 'classnames';
import { nextHourUTC } from '../../../helpers/utils';
import { useOnClickOutside } from '../../../hooks/use-on-click-outside';
import { useSelectedPartner } from '../../../hooks/use-selected-partner';
import { RecurrenceIcon } from './recurrence-icon';
import { PushesAndSchedules, usePushesAndSchedules } from './use-pushes-and-schedules';

export const SchedulesAndAvailability = ({ now }: { now: () => Date }) => {
  const partner = useSelectedPartner();
  const [isRemainingPushesOpen, setIsRemainingPushesOpen] = useState(false);
  const remainingPushesRef = useRef(null);

  useOnClickOutside(remainingPushesRef, () => setIsRemainingPushesOpen(false));

  return (
    <section className="schedules-and-availability">
      <div className="snackbar">
        <section>
          <img src={iconUpload} alt="Icon showing push" />
        </section>
        <section style={{ flex: 2 }}>
          <h4>Next available push at {nextHourUTC(now())} UTC</h4>
          <p>
            If you have already used all your pushes for today, the next push will be scheduled for
            tomorrow at 00:00 UTC.
          </p>
        </section>
        <section
          ref={remainingPushesRef}
          onClick={() => setIsRemainingPushesOpen(prev => !prev)}
          className="remaining-pushes-button"
        >
          My pushes & schedules{' '}
          <ArrowIcon className={cs('arrow-icon', { inverted: isRemainingPushesOpen })} />
          {isRemainingPushesOpen && <RemainingPushesAndSchedules partner={partner} />}
        </section>
      </div>
    </section>
  );
};

const RemainingPushesAndSchedules = React.forwardRef<any, { partner: Partner }>(
  ({ partner }, ref) => {
    const { isLoading, error, data } = usePushesAndSchedules(partner.partnerId);
    let pushesAndSchedules = data || [];
    if (!partner.isCpaEligible) {
      pushesAndSchedules = pushesAndSchedules.filter(
        el => el.type !== EventType.PUSH_CAMPAIGN_ALLOCATIONS && el.type !== EventType.PUSH_CPA_BM,
      );
    }

    return (
      <ul
        ref={ref}
        data-testid="remaining-pushes-list"
        className="remaining-pushes-list"
        onClick={e => e.stopPropagation()}
      >
        <li>
          <p style={{ fontSize: 12 }}>
            The automatic pushes you have defined outside of tI are included in the daily remaining
            push count.
          </p>
        </li>
        {isLoading && (
          <li>
            <h4>Loading...</h4>
          </li>
        )}
        {error && (
          <li>
            <h4>Error: {error?.message}</h4>
          </li>
        )}
        {pushesAndSchedules.map(el => (
          <PushesAndSchedulesListItem key={el.type} pushesAndSchedules={el} />
        ))}
      </ul>
    );
  },
);

RemainingPushesAndSchedules.displayName = 'RemainingPushesAndSchedules';

function PushesAndSchedulesListItem(props: { pushesAndSchedules: PushesAndSchedules }) {
  const { type, pushesRemaining, schedules } = props.pushesAndSchedules;
  return (
    <li>
      <h4>{labels[type] || type}</h4>
      {pushesRemaining !== undefined && (
        <p className="remaining-pushes">{pushesRemaining} pushes remaining today</p>
      )}
      {schedules.map((schedule, index) => (
        <p key={index} className="import-schedule">
          <RecurrenceIcon /> {schedule}
        </p>
      ))}
    </li>
  );
}

const labels: Record<number, string> = {
  [EventType.PUSH]: 'CPC base bid',
  [EventType.PUSH_BM]: 'CPC bid modifier',
  [EventType.PUSH_PROPERTY_GROUPS]: 'Property groups',
  [EventType.PUSH_SL_BID]: 'SL base bids',
  [EventType.PUSH_SL_BM]: 'SL bid modifier',
  [EventType.PUSH_SL_BUDGET_CAP]: 'SL budget cap',
  [EventType.PUSH_CAMPAIGN_ALLOCATIONS]: 'Campaign allocation',
  [EventType.PUSH_CPA_VALUE]: 'CPA value',
  [EventType.INVENTORY_IMPORT]: 'Inventory',
  [EventType.COMBINED_BIDDING]: 'Combined bids',
  [EventType.PUSH_CPA_BM]: 'CPA bid modifier',
  [EventType.INVENTORY_IMPORT_TI]: 'Inventory',
};
