import * as React from 'react';
import { API_WIDGET_TYPE_2_WIDGET_TYPE, Props } from './add-widget-item-menu.types';
import './add-widget-item-menu.css';
import { TranslationKey, translate } from '../../../../helpers/utils';
import { BetaIcon } from '../../../../components/icon/beta-icon';

export const AddWidgetItemMenu: React.FunctionComponent<Props> = ({ type }) => {
  const { title, description, icon, isBetaFeature } = API_WIDGET_TYPE_2_WIDGET_TYPE[type];

  return (
    <div
      className="
        c-add-widget-item-menu
        u-display--flex
        u-margin-left--gutter--small
        u-margin-right--gutter--small
        u-border-bottom
        u-border--juri-lightest
        u-display--flex--row
        u-align-items--center
        "
    >
      <img
        src={icon}
        className="u-margin-right--gutter"
        alt="Icon showing the widget to be added"
      />
      <span className="u-padding-top--gutter u-padding-bottom--gutter u-padding-right--gutter">
        <p className="u-font-size--h-tiny u-line-height--small u-padding-bottom--tiny u-display--flex u-align-items--center">
          {translate(title as TranslationKey)}
          {isBetaFeature && (
            <span className="u-margin-left--small">
              <BetaIcon />
            </span>
          )}
        </p>
        <p className="u-font-size--h-tiny u-color--juri-lighter u-line-height--large2">
          {translate(description as TranslationKey)}
        </p>
      </span>
    </div>
  );
};
