export type Props = {
  totalSelectedProperties: number;
  totalProperties: number;
  onShortcut: (shortcut: ShortCutType) => void;
};

export enum ShortCutType {
  SELECT_ALL,
  UNSELECT,
}
