import {
  PROPERTY_LIST_FILE_NAME_SUFFIX,
  PROPERTY_LIST_VIEW,
  PropertyListType,
} from '../../views/bidding/campaigns';
import { CampaignTypeCode, CPC_CAMPAIGN_CODE, FileFormat, Locale, Row, View } from '../../models';
import {
  COLUMN_CAMPAIGNS_TYPE,
  DATE_FORMAT_ISO_8601,
  FILE_EXTENSION,
  NotificationLevel,
} from '../../constants';
import { getPartner } from '../../services/app-service';
import { useContext } from 'react';
import { AppContext } from '../../global/context/app-context';
import ReportService from '../../services/report-service';
import { biddingTypes2Filter } from '../../services/analytics-service';
import { format } from 'date-fns';
import { showNotification } from '../../services/notification-service';
import { BiddingModel } from './use-export-properties-list.types';

export const useExportPropertiesList = (
  campaignTypeGetter: (row: Row) => CampaignTypeCode,
  localeGetter: (row: Row) => Locale,
) => {
  const { partner, subPartners } = useContext(AppContext);
  const exportPropertiesList = async (
    row: Row,
    propertyListType: PropertyListType,
    fileFormat: FileFormat,
  ) => {
    const campaignTypeCode: CampaignTypeCode = campaignTypeGetter(row);
    const biddingModel =
      campaignTypeCode === CPC_CAMPAIGN_CODE ? BiddingModel.CPC : BiddingModel.CPA;
    const view: View | null = PROPERTY_LIST_VIEW[propertyListType][biddingModel];
    if (!view) return;
    const localeCode: string = localeGetter(row).localeCode;
    const effectivePartnerId = getPartner(partner, subPartners).partnerId;
    try {
      return await ReportService.export(
        view,
        [],
        fileFormat,
        {
          partnersId: [effectivePartnerId],
          locales: [localeCode],
          filters: [biddingTypes2Filter([campaignTypeCode], COLUMN_CAMPAIGNS_TYPE)],
        },
        propertyListFilename(
          effectivePartnerId,
          localeCode,
          campaignTypeCode,
          propertyListType,
          fileFormat,
        ),
      );
    } catch (e) {
      const error = e as Error;
      showNotification({
        message: error.message,
        level: NotificationLevel.ERROR,
      });
    }
  };

  const propertyListFilename = (
    partnerId: number,
    localeCode: string,
    campaignType: string,
    propertyListType: PropertyListType,
    fileFormat: FileFormat,
  ) => {
    const dateCreated = format(new Date(), DATE_FORMAT_ISO_8601);
    const propertyListSuffix = PROPERTY_LIST_FILE_NAME_SUFFIX[propertyListType];
    const fileExtension = FILE_EXTENSION[fileFormat];
    return `${dateCreated}_${partnerId}_${localeCode}_${campaignType}_${propertyListSuffix}${fileExtension}`;
  };

  return {
    exportPropertiesList,
  };
};
