import { isBefore } from 'date-fns';
import { CURRENT_PARTNER_KEY, EMAIL_KEY, PROFILE_KEY, PROFILE_SETTINGS } from '../constants';
import {
  deleteItemFromLocalStorage,
  getItem,
  saveItemToLocalStorage,
} from '../helpers/local-storage-helper';
import { Partner, Profile, User } from '../models';
import TrackerService from './tracker/tracker-service';

export const removeProfile = () => {
  deleteItemFromLocalStorage(PROFILE_KEY);
  deleteItemFromLocalStorage(EMAIL_KEY);
};

export const removeProfileSettings = () => {
  deleteItemFromLocalStorage(PROFILE_SETTINGS);
};

export const removeCurrentPartner = () => {
  deleteItemFromLocalStorage(CURRENT_PARTNER_KEY);
};

export const setSelectedPartner = (data: { partnerId: number; subbrands: number[] }) => {
  saveItemToLocalStorage(CURRENT_PARTNER_KEY, data);
};

export const clearProfile = () => {
  removeProfile();
  removeProfileSettings();
  removeCurrentPartner();
};

export const getSelectedPartners = () => {
  try {
    return getItem<{
      partnerId: number;
      subbrands: number[];
    }>(CURRENT_PARTNER_KEY);
  } catch {
    return undefined;
  }
};

export const setProfileSettings = (data: any) => {
  saveItemToLocalStorage(PROFILE_SETTINGS, data);
};

export const getEmailFromLocalStorage = () => getItem<string>(EMAIL_KEY);

export const getProfileSettings = () => {
  const defaultProfileSettings = {
    preferedLocale: [''],
  };
  const tmp = localStorage.getItem(PROFILE_SETTINGS);
  if (tmp) {
    try {
      return JSON.parse(tmp) as typeof defaultProfileSettings;
    } catch {
      return defaultProfileSettings;
    }
  } else {
    return defaultProfileSettings;
  }
};

export const isTokenExpired = (profile: Profile) => {
  const tokenDateInMilliseconds = parseInt(profile.token.expirationTime, 10) * 1000;
  const tokenDate = new Date(tokenDateInMilliseconds);
  const today = new Date();
  return isBefore(tokenDate, today);
};

export const setTrackingProfile = (
  user: User,
  { partnerId, type }: Partner,
  selectedSubpartners: Partner[],
) => {
  const { memberId, level } = user;
  TrackerService.baseAttributes = {
    member_id: memberId,
    level,
    partner_id: partnerId,
    adv_type: type,
    sub_partner_id: selectedSubpartners.map(subpartner => subpartner.partnerId),
    session_id: user.sessionId,
  };
};
