import { csvOf } from './data-to-csv';
import { csvToDataUrl } from './csv-to-data-url';
import { downloadFile } from '../download';

type DownloadCsvRequest = {
  data: Record<string, any>[];
  fileName: string;
};

export async function downloadCsv(request: DownloadCsvRequest): Promise<void> {
  const fileName = request.fileName.endsWith('.csv') ? request.fileName : `${request.fileName}.csv`;
  if (!('showSaveFilePicker' in window)) {
    // fallback for browsers that don't support showSaveFilePicker
    downloadFile(csvToDataUrl(csvOf(request.data)), fileName);
    return Promise.resolve();
  }
  const handle = await window.showSaveFilePicker({
    suggestedName: fileName,
    types: [
      {
        description: 'CSV file',
        accept: {
          'text/csv': ['.csv'],
        },
      },
    ],
  });
  const writable = await handle.createWritable();
  await writable.write(csvOf(request.data));
  await writable.close();
}
