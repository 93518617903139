import { RequestHelper } from '../request-helper';
import { Banner, BannerResponse } from './types';
import { AxiosError } from 'axios';

const URL_BANNER = '/admin/banner';

class ApiBannerSwitchHelper extends RequestHelper {
  public enableBanner = async (banner: Banner): Promise<BannerResponse> => {
    try {
      const data = await this.postRequest(URL_BANNER, banner);
      return data.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'banner_enable_error');
      throw e;
    }
  };

  public disableBanner = async (): Promise<BannerResponse> => {
    try {
      const data = await this.deleteRequest(URL_BANNER, {});
      return data.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'banner_disable_error');
      throw e;
    }
  };

  public getBanner = async (): Promise<Banner | null> => {
    try {
      const data = await this.getRequest(URL_BANNER);
      return data.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 404) return null;
      this.handleError(error, 'banner_get_error');
      throw error;
    }
  };
}

export const apiBannerSwitchHelper = new ApiBannerSwitchHelper();
