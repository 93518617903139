/* eslint-disable @typescript-eslint/naming-convention */
import { FC, Suspense, lazy, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Modal } from '../modal';
import { Loading } from '../loading';

export type OldFormatBaseBid = {
  [partnerReferenceOrLocaleCode: string]: string;
};

export type NewFormatBaseBid = {
  partner_reference: string;
  locale: string;
  bid: string;
};

// This is imported lazily to avoid running a worker in tests (import.meta.url is not supported in Jest)
// There is probably a better way to solve this
const ConvertBaseBidFileModal = lazy(() =>
  import('./convert-base-bid-file-modal/convert-base-bid-file-modal').then(m => ({
    default: m.ConvertBaseBidFileModal,
  })),
);

const LoadingModal: FC = () => (
  <Modal backdropStatic onClose={console.log}>
    <Loading />
  </Modal>
);

export function useConvertBaseBidFileModal() {
  const [file, setFile] = useState<File | null>(null);
  const [showModal, hideModal] = useModal(
    () => (
      <Suspense fallback={<LoadingModal />}>
        <ConvertBaseBidFileModal onClose={hideModal} file={file!} />
      </Suspense>
    ),
    [file],
  );

  return (f: File) => {
    setFile(f);
    showModal();
  };
}
