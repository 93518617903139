import * as React from 'react';
import { Props } from './grid-pagination.types';
import './grid-pagination.css';
import ArrowIcon from 'tcp-react-icons/lib/ArrowIcon';

export const GridPagination: React.FunctionComponent<Props> = ({
  page: { numPage, numItemsPerPage },
  totalItems,
  onPreviousPage,
  onNextPage,
  onUpdateRowsPerPage,
}) => {
  const firstRow = (numPage - 1) * numItemsPerPage + 1;
  const lastRow = numPage * numItemsPerPage < totalItems ? numPage * numItemsPerPage : totalItems;
  const enabledArrowClasses = 'u-cursor--pointer u-color--juri';
  return (
    <div className="u-display--flex u-align-items--center">
      <div className="u-margin-left--auto u-display--flex u-align-items--center u-margin-right--small">
        <label htmlFor="numPages" className="u-margin-right--small">
          Show{' '}
          {/* // TODO Add key/translation. We cannot use translation(), it should be passed to the grid as a property */}
        </label>
        <select
          value={numItemsPerPage}
          name="numPages"
          className="u-margin--zero u-height--36px"
          onChange={({ target: { value } }) => {
            onUpdateRowsPerPage(+value);
          }}
          data-qa="grid-pagination-select"
        >
          <option data-qa="grid-pagination-select-option-0" value="50">
            50
          </option>
          <option data-qa="grid-pagination-select-option-1" value="100">
            100
          </option>
          <option data-qa="grid-pagination-select-option-2" value="500">
            500
          </option>
          <option data-qa="grid-pagination-select-option-3" value="1000">
            1000
          </option>
        </select>
      </div>
      <span className="u-margin-right--small">
        {firstRow}-{lastRow} of{' '}
        {/* // TODO Add key/translation. We cannot use translation(), it should be passed to the grid as a property */}
      </span>
      <b>{totalItems}</b>
      <ArrowIcon
        onClick={onPreviousPage}
        className={`u-transform--rotate-180deg u-color--juri-very-light ${
          firstRow > 1 && enabledArrowClasses
        }`}
        data-qa="grid-pagination-previous-page"
      />
      <ArrowIcon
        onClick={onNextPage}
        className={`u-color--juri-very-light ${lastRow < totalItems && enabledArrowClasses}`}
        data-qa="grid-pagination-next-page"
      />
    </div>
  );
};
