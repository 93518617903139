import React, { FC, useContext } from 'react';
import { Props } from './analytics-router.types';
import './analytics-router.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Analytics } from '../analytics';
import { DASHBOARD_ROUTE, HOTEL_DETAILS_PARTIAL_ROUTE } from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { HotelDetailView } from '../hotel-detail-view';
import { RouteComponentProps } from 'react-router';
import { useDateRangePeriod } from '../../../components/date-picker/date-picker.state';

export const AnalyticsRouter: FC<Props> = ({ routeProps }) => {
  const { selectedLocales, partner, subPartners } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();
  const baseRoute = routeProps.match.path;

  const renderAnalytics = () => (
    <Analytics
      selectedPartner={partner}
      POSlocales={Array.from(selectedLocales)}
      subbrands={Array.from(subPartners)}
      from={from}
      to={to}
    />
  );

  const renderHotelDetail = (routePropsParam: RouteComponentProps<any>) => {
    return <HotelDetailView partnerRef={routePropsParam.match.params.partnerRef} />;
  };

  return (
    <Switch>
      <Route exact={true} path={baseRoute} render={renderAnalytics} />
      <Route
        exact={true}
        path={`${baseRoute}/${HOTEL_DETAILS_PARTIAL_ROUTE}`}
        render={renderHotelDetail}
      />
      <Redirect to={DASHBOARD_ROUTE} />
    </Switch>
  );
};
