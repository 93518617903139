import React from 'react';
import styles from './circle-icon.module.css';
import cs from 'classnames';

export type Props = {
  size?: number;
  iconStyle?: string;
  color?: string;
};

export const CircleIcon: React.FunctionComponent<Props> = ({ size = 20, iconStyle, color }) => (
  <div
    className={cs(styles.circleIcon, iconStyle)}
    style={{ width: size, height: size, borderColor: color }}
  />
);
