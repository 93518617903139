import * as React from 'react';
import { Props } from './vertical-divider.types';
import styles from './vertical-divider.module.css';
import cn from 'classnames';
import { FC } from 'react';
import { Color } from '../../constants';

export const VerticalDivider: FC<Props> = ({ height = 'large', colour = Color.Grey300 }) => {
  return (
    <span
      className={cn(styles.verticalDivider, styles[height])}
      style={{ backgroundColor: colour }}
    />
  );
};
