import { FC, ReactNode } from 'react';
import cn from 'classnames';
import styles from './selected-range.module.css';

type SelectedRangeProps = {
  label: ReactNode;
  details: ReactNode;
  actionLabel: ReactNode;
  onClick?: VoidFunction;
  onActionClick?: VoidFunction;
};

export const SelectedRange: FC<SelectedRangeProps> = ({
  label,
  details,
  actionLabel,
  onClick,
  onActionClick,
}: SelectedRangeProps) => {
  const handleActionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onActionClick?.();
  };

  return (
    <div className={cn(styles.box, 'option-item__custom-header-active')} onClick={onClick}>
      <div className={styles.labelBox}>
        <p>{label}</p>
        <p className={styles.details}>{details}</p>
      </div>
      <div className={styles.changeButton} onClick={handleActionClick}>
        {actionLabel}
      </div>
    </div>
  );
};
