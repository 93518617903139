import { FC } from 'react';
import { PERIOD_PICKER_OPTIONS, TimeOption } from '../../constants';
import { SegmentedButtons } from '../../core-ui/components/segmented-buttons/segmented-buttons';
import { translate } from '../../helpers/utils';

export type PeriodPickerProps = {
  timeOptionSelected?: TimeOption;
  onSelectTimeOption(option: TimeOption): void;
  disabled?: boolean;
};

export const PeriodPicker: FC<PeriodPickerProps> = ({
  onSelectTimeOption,
  timeOptionSelected = TimeOption.DAY,
  disabled = false,
}) => {
  const selectTimeOption = (option: TimeOption): void => {
    if (disabled) return;
    onSelectTimeOption(option);
  };

  return (
    <SegmentedButtons
      value={timeOptionSelected}
      disabled={disabled}
      onChange={selectTimeOption}
      options={PERIOD_PICKER_OPTIONS.map(({ option, translation }) => ({
        value: option,
        text: translate(translation),
      }))}
    />
  );
};
