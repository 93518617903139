import cn from 'classnames';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Column, Row } from '.';
import { NO_OF_NARROW_TABLE_COLUMNS } from '../../constants';
import { GridBody } from './grid-body';
import { GridFooter } from './grid-footer';
import { GridHeader } from './grid-header';
import { GridLoading } from './grid-loading';
import { GridPagination } from './grid-pagination';
import styles from './grid.module.css';
import { GridProps } from './grid.types';
import { ColumnType, Sort, SortDirection } from './models/grid-model';
import { swapSortDirection as getNextSortDirection } from './utils/grid-utils';

export const Grid: FC<GridProps> = ({
  columns,
  rows,
  footerRows,
  numStickyColumns = 0,
  numColumnsLeftAlignment = 0,
  isLoading,
  selectableRow: { conditionFn = (_: Row) => true, onSelectPage, onSelectRow } = {},
  initialData: { initialSelectedRowsId, initialSelectedPages } = {
    initialSelectedRowsId: undefined,
    initialSelectedPages: undefined,
  },
  onSort,
  pagination,
  configuration,
  rowStyles,
  resultsNotFound,
  columnOnboardingTooltips = [],
  isAccordionTable = false,
  onExpandRow,
}) => {
  const [selectedRowsId, setSelectedRowsId] = useState(new Set<number>());
  const selectedPages = useRef(new Set<number>());
  const [activeSort, setActiveSort] = useState<Sort | undefined>();

  const isPageSelected =
    selectedPages.current &&
    pagination !== undefined &&
    selectedPages.current.has(pagination.page.numPage);

  useEffect(() => {
    if (initialSelectedRowsId !== undefined) {
      setSelectedRowsId(initialSelectedRowsId);
    }
    if (initialSelectedPages !== undefined && selectedPages.current !== undefined) {
      selectedPages.current = initialSelectedPages;
    }
  }, [initialSelectedRowsId]);

  const clickHeaderCell = (column: Column): void => {
    if (column.type === ColumnType.CHECKBOX) {
      clickCheckboxAction();
    } else if (onSort) {
      const nextSort: Sort = {
        columnKey: column.key,
        direction: SortDirection.ASC,
      };
      if (activeSort !== undefined && activeSort.columnKey === column.key) {
        nextSort.direction = getNextSortDirection(activeSort.direction);
      }

      onSort(column.key, nextSort.direction);
      setActiveSort(nextSort);
    }
  };

  const clickCheckboxAction = () => {
    if (!selectedPages.current || !pagination) {
      return;
    }

    const nextSet = selectedRowsId;
    const isSelectingPage = !isPageSelected;

    if (isSelectingPage) {
      selectedPages.current.add(pagination.page.numPage);
      rows.filter(rowTmp => conditionFn(rowTmp)).forEach(rowTmp => nextSet.add(rowTmp.id));
    } else {
      selectedPages.current.delete(pagination.page.numPage);
      rows.forEach(rowTmp => nextSet.delete(rowTmp.id));
    }

    if (onSelectRow) {
      onSelectRow(nextSet);
    }
    if (onSelectPage) {
      onSelectPage(selectedPages.current);
    }
  };

  const selectRow = (row: Row) => {
    if (selectedRowsId.has(row.id)) {
      selectedRowsId.delete(row.id);
    } else {
      selectedRowsId.add(row.id);
    }

    setSelectedRowsId(new Set(selectedRowsId));

    if (onSelectRow) {
      onSelectRow(selectedRowsId);
    }
  };

  const isHorizontalScrollDisabled = isLoading || rows.length === 0;

  const isPaginationVisible = () =>
    !isLoading &&
    rows.length > 0 &&
    pagination !== undefined &&
    !pagination.isHidden &&
    pagination.onNextPage !== undefined &&
    pagination.onPreviousPage !== undefined &&
    pagination.onUpdateRowsPerPage !== undefined &&
    pagination.totalItems !== undefined;

  return (
    <Fragment>
      <div
        className={cn(styles.grid, styles.scrollbar, {
          [styles.hidden]: isHorizontalScrollDisabled,
          [styles.noFooter]: !footerRows?.length,
          [styles.flexibleHeight]: isAccordionTable,
        })}
      >
        <table
          className={cn(styles.table, {
            [styles.fixedLayout]: columns.length < NO_OF_NARROW_TABLE_COLUMNS,
          })}
        >
          {/* When we receive the rows, we need to recalculate the left property of the sticky columns,
          the easiest and simplest way to do it is to unmount the component and to mount it again because these
          calculations are done when the component is mounted */}
          {!isLoading ? (
            <GridHeader
              key={1}
              columns={columns}
              numStickyColumns={numStickyColumns}
              numColumnsLeftAlignment={numColumnsLeftAlignment}
              isPageSelected={isPageSelected}
              onClickCell={onSort ? clickHeaderCell : undefined}
              currentSort={activeSort}
              isShowingTooltips={true}
              isHorizontalScrollDisabled={isHorizontalScrollDisabled}
              columnOnboardingTooltips={columnOnboardingTooltips}
            />
          ) : (
            <GridHeader
              key={2}
              columns={columns}
              numStickyColumns={numStickyColumns}
              numColumnsLeftAlignment={numColumnsLeftAlignment}
              isPageSelected={isPageSelected}
              onClickCell={onSort ? clickHeaderCell : undefined}
              currentSort={activeSort}
              isShowingTooltips={false}
              isHorizontalScrollDisabled={isHorizontalScrollDisabled}
              columnOnboardingTooltips={columnOnboardingTooltips}
            />
          )}
          {!isLoading && (
            <GridBody
              columns={columns}
              rows={rows}
              numStickyColumns={numStickyColumns}
              numColumnsLeftAlignment={numColumnsLeftAlignment}
              selectedRows={selectedRowsId}
              isSelectableFn={conditionFn}
              configuration={configuration}
              onSelectRow={onSelectRow && selectRow}
              rowStyles={rowStyles}
              isAccordionTable={isAccordionTable}
              onExpandRow={onExpandRow}
            />
          )}
          {!isLoading && rows.length > 0 && (
            <GridFooter
              configuration={configuration}
              columns={columns}
              rows={footerRows !== undefined ? footerRows : []}
              numStickyColumns={numStickyColumns}
              numColumnsLeftAlignment={numColumnsLeftAlignment}
            />
          )}
        </table>
      </div>
      {isLoading && <GridLoading />}
      {!isLoading && rows.length === 0 && <div>{resultsNotFound}</div>}
      {isPaginationVisible() && (
        <div className="u-margin-top--large">
          <GridPagination
            page={pagination!.page}
            totalItems={pagination!.totalItems!}
            onPreviousPage={pagination!.onPreviousPage!}
            onNextPage={pagination!.onNextPage!}
            onUpdateRowsPerPage={pagination!.onUpdateRowsPerPage!}
          />
        </div>
      )}
    </Fragment>
  );
};
