import { translate } from '../../../../helpers/utils';
import { usePropertyGroups } from '../../../../hooks/use-property-groups';
import { Filter } from './filter';
import { FC } from 'react';

type PropertyGroupsFilterProps = {
  selectedIds: number[];
  onApply: (ids: number[]) => void;
  disabledMessage?: string;
};

export const PropertyGroupsFilter: FC<PropertyGroupsFilterProps> = ({
  selectedIds,
  onApply,
  disabledMessage,
}) => {
  const propertyGroups = usePropertyGroups();
  const pgMap = new Map(propertyGroups.map(pg => [pg.id, pg]));
  return (
    <Filter
      defaultLabel={translate('property_groups')}
      allIds={propertyGroups.map(({ id }) => id)}
      selectedIds={selectedIds}
      label={id => pgMap.get(id)!.name}
      subLabel={id => pgMap.get(id)!.alias}
      onApply={onApply}
      disabledMessage={disabledMessage}
      allowEmptySelection={false}
    />
  );
};
