import { FC, useContext, useState } from 'react';
import { useDateRangePeriod } from '../../../../../components/date-picker/date-picker.state';
import { Grid, SortDirection } from '../../../../../components/grid';
import { PeriodPicker } from '../../../../../components/period-picker';
import { ShownMetricsSelector } from '../../../../../components/shown-metrics-selector';
import { Toolbar } from '../../../../../components/toolbar';
import { ViewExporter } from '../../../../../components/view-exporter';
import {
  COLUMN_KEY_DATE,
  COLUMN_KEY_PARTNER_REFERENCE_SL,
  GRID_VIEW_CONFIGURATION,
  TimeOption,
  VIEW_NAME,
} from '../../../../../constants';
import { AppContext } from '../../../../../global/context/app-context';
import { formatColumnsAndRows } from '../../../../../helpers/grid-formatter';
import { translate } from '../../../../../helpers/utils';
import { useShownMetricsSelector } from '../../../../../hooks/use-shown-metrics-selector';
import { useViewTable } from '../../../../../hooks/use-view-table';
import { Aggregation, FileFormat, fromTo, View } from '../../../../../models';
import { partnerRef2Filter } from '../../../../../services/analytics-service';
import { withSortTracking } from '../../../../analytics/with-sort-tracking';
import styles from '../../common-view-styles.module.css';

export const SponsoredHotelDetail: FC<{ partnerRef: string }> = ({ partnerRef }) => {
  const { selectedLocales } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();

  const [timeOptionSelected, setTimeOptionSelected] = useState(TimeOption.DAY);

  const view = View.SPONSORED_HOTEL_DETAIL;
  const numOfStickyColumns = 1;

  const {
    table: { columns, data, total },
    isLoading,
    pagination,
    filters: { allActiveFilters },
    sort: { sort, setSort },
  } = useViewTable(
    view,
    TIME_OPTION_TO_AGGREGATION[timeOptionSelected],
    {
      locales: selectedLocales,
      from,
      to,
      filters: [partnerRef2Filter(partnerRef, COLUMN_KEY_PARTNER_REFERENCE_SL)],
    },
    {
      ignoreGlobalFilters: true,
      avoidFetchingSubtotal: true,
    },
  );

  const { formattedColumns, formattedRows, formattedFooterRows } = formatColumnsAndRows({
    columns,
    rows: data.rows,
    total,
    id: COLUMN_KEY_DATE,
  });

  const { shownMetrics, setShownMetrics, availableMetrics, visibleFormattedColumns } =
    useShownMetricsSelector(view, numOfStickyColumns, columns, formattedColumns);

  return (
    <div className={styles.toolbarWrapper}>
      <Toolbar
        filters={[
          <PeriodPicker
            key="key-period-picker"
            onSelectTimeOption={setTimeOptionSelected}
            timeOptionSelected={timeOptionSelected}
          />,
        ]}
        actions={[
          <ShownMetricsSelector
            key="key-metrics-selector"
            options={availableMetrics}
            selectedByDefaultOptions={shownMetrics}
            view={view}
            onApply={setShownMetrics}
          />,
          <ViewExporter
            key="key-view-exporter"
            title={translate('sponsored_home_export_report')}
            exportOptions={[
              {
                text: translate('sponsored_toolbar_export_performance_grid'),
                fileFormat: FileFormat.CSV,
              },
              {
                text: translate('sponsored_toolbar_export_performance_grid_excel'),
                fileFormat: FileFormat.EXCEL,
              },
            ]}
            view={view}
            columns={columns}
            aggregation={TIME_OPTION_TO_AGGREGATION[timeOptionSelected]}
            filters={allActiveFilters}
            sort={sort}
          />,
        ]}
      />
      <div className={styles.gridWrapper}>
        <Grid
          numStickyColumns={numOfStickyColumns}
          columns={visibleFormattedColumns}
          rows={formattedRows}
          footerRows={formattedFooterRows}
          isLoading={isLoading}
          configuration={GRID_VIEW_CONFIGURATION}
          onSort={withSortTracking((columnName: string, direction: SortDirection) => {
            setSort({ columnName, direction });
          }, VIEW_NAME[view])}
          pagination={{
            page: pagination.pagination,
            totalItems: data.count,
            onPreviousPage: pagination.setPrevPage,
            onNextPage: pagination.setNextPage,
            onUpdateRowsPerPage: pagination.setNumItemsPerPage,
          }}
        />
      </div>
    </div>
  );
};

const TIME_OPTION_TO_AGGREGATION: fromTo<TimeOption, Aggregation> = {
  [TimeOption.DAY]: Aggregation.SPONSORED_HOTEL_DETAIL_DAY,
  [TimeOption.WEEK]: Aggregation.SPONSORED_HOTEL_DETAIL_WEEK,
  [TimeOption.MONTH]: Aggregation.SPONSORED_HOTEL_DETAIL_MONTH,
  [TimeOption.QUARTER]: Aggregation.TIME_QUARTER,
};
