import React from 'react';

export const PencilIcon: React.FunctionComponent<{ fill?: string }> = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18" fill="none">
    <mask id="mask0_1930_3402" maskUnits="userSpaceOnUse" x="2" y="2" width="14" height="14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 2.25H15.7482V15.75H2.25V2.25Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1930_3402)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9436 6.0615L8.55585 12.4808L8.5296 12.504L7.2771 11.2515L7.2786 11.25L13.8508 4.677L14.9428 5.76525L14.9556 5.787L14.9691 5.80875C15.0126 5.87325 15.0126 5.991 14.9436 6.0615ZM3.32985 14.9722C3.27135 14.9948 3.2286 15 3.2181 15C3.15435 15 3.0936 14.976 3.05835 14.9423C3.0021 14.8853 2.98335 14.7847 3.0156 14.7098L4.9836 10.1085L5.01585 10.0402L7.9686 12.993L7.9266 13.0125L3.32985 14.9722ZM11.9203 3.06825C11.9728 3.018 12.0373 3 12.0756 3C12.1333 3 12.1911 3.0255 12.2278 3.05775L13.3251 4.15275L6.7536 10.725L6.7521 10.7265L5.50335 9.47775L11.9203 3.06825ZM15.5931 5.3925C15.5631 5.34 15.5256 5.295 15.4881 5.2575L14.3796 4.149L14.3811 4.1475L13.8553 3.6225L13.8546 3.624L12.7581 2.5275C12.7506 2.52 12.7506 2.52 12.7431 2.5125C12.5481 2.34 12.3156 2.25 12.0756 2.25C11.8356 2.25 11.5881 2.3475 11.4006 2.5275L4.9731 8.9475L4.8906 9.03L4.5756 9.3375C4.5306 9.3825 4.4931 9.435 4.4556 9.48C4.4256 9.5325 4.3956 9.585 4.3731 9.645L4.3056 9.7875L2.3256 14.415C2.1756 14.7675 2.2506 15.195 2.5281 15.4725C2.7156 15.6525 2.9631 15.75 3.2181 15.75C3.3381 15.75 3.4731 15.72 3.5931 15.675L8.2206 13.7025L8.3631 13.635C8.4231 13.6125 8.4756 13.5825 8.5206 13.545C8.5806 13.515 8.6256 13.4775 8.6706 13.4325L8.9781 13.11L9.0606 13.035L15.4731 6.5925C15.7956 6.2625 15.8331 5.7525 15.5931 5.3925Z"
        fill={fill || 'var(--grey-800)'}
      />
    </g>
  </svg>
);
