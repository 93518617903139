import { useEffect, RefObject } from 'react';
import { EVENT_MOUSEDOWN } from '../../constants';

export const useOnClickOutside = (
  ref: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  handler: (e: MouseEvent) => void,
) => {
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (Array.isArray(ref)) {
        if (ref.some(r => r.current?.contains(e.target as Node))) {
          return;
        }
      } else {
        if (!ref.current || ref.current.contains(e.target as Node)) {
          return;
        }
      }
      handler(e);
      e.stopPropagation();
    };

    document.addEventListener(EVENT_MOUSEDOWN, listener);
    return () => document.removeEventListener(EVENT_MOUSEDOWN, listener);
  }, [ref, handler]);
};
