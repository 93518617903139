import * as React from 'react';
import cs from 'classnames';
import { Props } from './card.types';
import './card.css';

export const Card: React.FunctionComponent<Props> = ({ children, className = '' }) => {
  return (
    <div className={cs('u-background--white u-box-shadow u-border-radius', className)}>
      {children}
    </div>
  );
};
