import { FC } from 'react';
import { WidgetTooltipProps } from './widget-tooltip.types';
import styles from './widget-tooltip.module.css';
import { Circle } from '../circle';
import { formatNumber } from '../../helpers/formater';
import { translate } from '../../helpers/utils';
import cn from 'classnames';

export const WidgetTooltip: FC<WidgetTooltipProps> = ({ title, metrics }) => {
  return (
    <div
      className={cn(
        styles.widgetTooltip,
        'u-padding--gutter u-padding-bottom--small u-display--flex u-flex-direction--column u-background--white',
      )}
    >
      {title && <div className={cn('u-margin-bottom--medium u-font-size--tiny')}>{title}</div>}
      {metrics.map(metricTmp => (
        <div
          key={metricTmp.name}
          className={cn(
            'u-display--flex u-align-items--center u-margin-bottom--small u-font-size--tiny',
          )}
        >
          <Circle radius={5} color={metricTmp.color} />
          <span className={cn('u-margin-left--gutter--small')}>{metricTmp.name}</span>
          <span
            className={cn(
              'u-margin-left--gutter--small u-margin-left--auto u-padding-left--medium',
            )}
          >
            {isNaN(metricTmp.value as number)
              ? translate('common_not_available')
              : formatNumber(metricTmp.value, metricTmp.wrapper)}
          </span>
        </div>
      ))}
    </div>
  );
};
