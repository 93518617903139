import { ReactComponent as arrowDown } from './icons/arrow-down.svg';
import { ReactComponent as close } from './icons/close.svg';
import { ReactComponent as download } from './icons/download.svg';
import { ReactComponent as equals } from './icons/equals.svg';
import { ReactComponent as hash } from './icons/hash.svg';
import { ReactComponent as info } from './icons/info.svg';
import { ReactComponent as pointingArrowDown } from './icons/pointing-arrow-down.svg';
import { ReactComponent as pointingArrowUp } from './icons/pointing-arrow-up.svg';
import { ReactComponent as percentage } from './icons/percent.svg';
import { ReactComponent as arrowRight } from './icons/arrow-right.svg';
import { ReactComponent as kebabMenu } from './icons/kebab-menu-icon.svg';
import { ReactComponent as infoFilled } from './icons/info-filled.svg';
import { ReactComponent as plus } from './icons/plus.svg';
import { ReactComponent as directionRight } from './icons/direction.svg';
import { ReactComponent as search } from './icons/search.svg';

export const ICONS = {
  equals,
  arrowDown,
  download,
  close,
  info,
  pointingArrowDown,
  pointingArrowUp,
  hash,
  percentage,
  arrowRight,
  kebabMenu,
  infoFilled,
  plus,
  directionRight,
  search,
} as const;
