import { FC } from 'react';
import { GridRow } from '../grid-row';
import { Props } from './grid-body.types';

export const GridBody: FC<Props> = ({
  columns,
  rows,
  numStickyColumns,
  numColumnsLeftAlignment,
  selectedRows,
  isSelectableFn,
  configuration,
  onSelectRow,
  rowStyles,
  isAccordionTable,
  onExpandRow,
}) => {
  return (
    <tbody>
      {rows.map((row, index) => (
        <GridRow
          key={row.id ?? index}
          row={{ ...row, rowIndex: index }}
          columns={columns}
          isSelected={selectedRows.has(row.id)}
          isSelectable={isSelectableFn(row)}
          numStickyColumns={numStickyColumns}
          numColumnsLeftAlignment={numColumnsLeftAlignment}
          configuration={configuration}
          onSelectRow={() => onSelectRow && onSelectRow(row)}
          rowStyles={rowStyles}
          qaId={`grid-row-${index}`}
          isAccordionTable={isAccordionTable}
          onExpand={onExpandRow}
        />
      ))}
    </tbody>
  );
};
