import { WidgetType, fromTo } from '../../../../models';
import globalInventoryIcon from './icons/global-inventory-health-icon.svg';
import mbluIcon from './icons/mblu-icon.svg';
import posOverviewIcon from './icons/pos-overview-icon.svg';
import timelineIcon from './icons/timeline-icon.svg';
import chartBuilderIcon from './icons/chart-builder-icon.svg';
import yesterdaysOverviewIcon from './icons/yesterday-overview-icon.svg';

export type Props = {
  type: WidgetType;
};

export type WidgetContent = {
  title: string;
  description: string;
  icon: string;
  isBetaFeature?: boolean;
};

export const API_WIDGET_TYPE_2_WIDGET_TYPE: fromTo<WidgetType, WidgetContent> = {
  [WidgetType.TIMELINE]: {
    title: 'add_widget_item_menu__timeline-title',
    description: 'add_widget_item_menu__timeline-description',
    icon: timelineIcon,
  },
  [WidgetType.YESTERDAY_OVERVIEW]: {
    title: 'add_widget_item_menu__yesterday-overview-title',
    description: 'add_widget_item_menu__yesterday-overview-description',
    icon: yesterdaysOverviewIcon,
  },
  [WidgetType.POS_OVERVIEW]: {
    title: 'add_widget_item_menu__pos-overview-title',
    description: 'add_widget_item_menu__pos-overview-description',
    icon: posOverviewIcon,
  },
  [WidgetType.GLOBAL_INVENTORY]: {
    title: 'add_widget_item_menu__global-inventory-title',
    description: 'add_widget_item_menu__global-inventory-description',
    icon: globalInventoryIcon,
  },
  [WidgetType.MBLU]: {
    title: 'add_widget_item_menu__mblu-title',
    description: 'add_widget_item_menu__mblu-description',
    icon: mbluIcon,
  },
  [WidgetType.UNKNOWN]: {
    title: '',
    description: '',
    icon: '',
  },
  [WidgetType.CHART_BUILDER]: {
    title: 'Chart builder',
    description: 'Get more insights of your data in a timeline chart',
    icon: chartBuilderIcon,
    isBetaFeature: true,
  },
};
