import { useContext } from 'react';
import uuid from 'uuid';
import { SnackbarContext } from './snackbar-context';
import { SnackbarMessage } from './snackbar-message';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type SnackbarMessageWithOptionalId = PartialBy<SnackbarMessage, 'id'>;

export interface UseSnackbarResult {
  visibleSnackbarMessages: SnackbarMessage[];
  showSnackbarMessage(message: SnackbarMessageWithOptionalId): void;
  remove(messageId: string): void;
}

export function useSnackbar(): UseSnackbarResult {
  const { visibleSnackbarMessages, setVisibleSnackbarMessages } = useContext(SnackbarContext);

  function addToVisible(message: SnackbarMessageWithOptionalId): SnackbarMessage {
    if (!message.id) message.id = uuid();
    const messageWithId = message as SnackbarMessage;
    setVisibleSnackbarMessages(prev => [...prev, messageWithId]);
    return messageWithId;
  }

  return {
    visibleSnackbarMessages,
    showSnackbarMessage(message) {
      addToVisible(message);
    },
    remove(messageId) {
      setVisibleSnackbarMessages(prev => prev.filter(el => el.id !== messageId));
    },
  };
}
