import * as React from 'react';
import { Props } from './metrics-selector.types';
import { MetricColumn } from '../../models';
import { CheckboxSelect } from '../checkbox-select/checkbox-select';
import { CheckboxSelectOption } from '../checkbox-select/use-checkbox-options';

export const MetricsSelector: React.FunctionComponent<Props> = ({
  metricColumns,
  onApply,
  maxMetricsAmount = 2,
  previouslySelectedMetrics,
}) => {
  function handleSelection(selectedOptions: CheckboxSelectOption<MetricColumn>[]) {
    const selectedMetrics = selectedOptions
      .filter(option => option.checked)
      .map(item => item.value);
    onApply(selectedMetrics);
  }

  const columnToOption = (column: MetricColumn): CheckboxSelectOption<MetricColumn> => ({
    id: column.key,
    label: column.name,
    checked: previouslySelectedMetrics?.some(item => item.key === column.key) || false,
    value: column,
  });

  return (
    <CheckboxSelect
      title="Metrics"
      options={metricColumns.map(columnToOption)}
      selectedOptionsLimit={maxMetricsAmount}
      searchEnabled={true}
      searchPlaceholder="Search metrics"
      onApply={handleSelection}
    />
  );
};
