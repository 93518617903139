import * as React from 'react';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import SearchLineDarkIcon from 'tcp-react-icons/lib/SearchLineDarkIcon';
import { Props } from './header-tab.types';
import { InputIcon } from '../input-icon';
import './header-tab.css';

export const HeaderTab: React.FunctionComponent<Props> = props => {
  const onCleanText = () => (props.onChangeSearch !== undefined ? props.onChangeSearch('') : null);
  const onChangeTextSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.onChangeSearch !== undefined ? props.onChangeSearch(e.target.value) : null;
  const isEmptyText = props.searchText !== undefined ? props.searchText.trim() === '' : null;

  return (
    <div className="u-display--flex">
      <h2>{props.title}</h2>
      <div className="c-header-tab__search">
        <InputIcon
          icon={isEmptyText ? SearchLineDarkIcon : CloseIcon}
          className="c-input u-border-radius u-width--100 u-font-family-arial"
          placeholder={props.searchPlaceholder}
          onChange={onChangeTextSearch}
          onClickIcon={onCleanText}
          value={props.searchText}
        />
      </div>
    </div>
  );
};
