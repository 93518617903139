import * as React from 'react';
import cs from 'classnames';
import { Props } from './sponsored-hotel-detail-information-column.types';
import './sponsored-hotel-detail-information-column.css';

export const SponsoredHotelDetailInformationColumn: React.FunctionComponent<Props> = ({
  fields,
  extraClassesKeys = '',
  extraClassesValues = '',
}) => {
  return (
    <React.Fragment>
      <div
        className={cs(
          'u-display--flex u-flex-direction--column u-margin-right--medium',
          extraClassesKeys,
        )}
      >
        {fields.map((fieldTmp, idx) => (
          <span
            key={idx}
            className="sl-hotel-detail-information-column__item u-color--juri-lighter"
          >
            {fieldTmp.key}:
          </span>
        ))}
      </div>
      <div className={cs('u-display--flex u-flex-direction--column', extraClassesValues)}>
        {fields.map((fieldTmp, idx) => (
          <span
            key={idx}
            className="sl-hotel-detail-information-column__item u-white-space--no-wrap"
          >
            {fieldTmp.value !== '' ? fieldTmp.value : '-'}
          </span>
        ))}
      </div>
    </React.Fragment>
  );
};
