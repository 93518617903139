import { useContext } from 'react';
import { AppContext } from '../../global/context/app-context';
import { areMultiplePOSorGlobalSelected } from '../../services/app-service';

export function useSelectedLocales() {
  return useContext(AppContext).selectedLocales;
}

/**
 *
 * @returns The single selected locale or null if multiple locales are selected
 */
export function useSingleSelectedLocale(): string | null {
  const selectedLocales = useSelectedLocales();
  if (areMultiplePOSorGlobalSelected(selectedLocales)) return null;
  return selectedLocales[0];
}
