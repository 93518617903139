import React from 'react';
import styles from './inventory-file-upload.module.css';
import { translate, translateHtml } from '../../../helpers/utils/translations';
import { UploadInventoryModal } from '../../../components/upload-inventory-modal';

type Props = { closeUploadModal: () => void; initiateFileUpload: () => void };

export const InventoryFileUpload: React.FunctionComponent<Props> = ({
  closeUploadModal,
  initiateFileUpload,
}) => {
  return (
    <UploadInventoryModal
      title={translate('inventory_file_upload_modal_title')}
      onCloseModal={closeUploadModal}
      onClickCTA={initiateFileUpload}
      ctaButtonTitle="Upload"
    >
      <p className={styles.inventoryFileUploadModalBody}>
        {translateHtml('inventory_file_upload')}
      </p>
    </UploadInventoryModal>
  );
};
