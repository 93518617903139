import { RequestHelper } from '../request-helper';
import {
  AllPartnersResponse,
  MemberByEmailRequestBody,
  MemberByEmailResponse,
  MemberPartnerLevelResponse,
  MemberRelatedPartnersResponse,
} from './types';
import { MemberAdministration, PartnerAdministration } from '../../../models';
import { PartnerLevels } from '../../../constants/admin';
import { AxiosError } from 'axios';
import { UseMembersOptions } from '../../../views/admin/members/use-members';

const URL_BASE = '/admin';
const URL_BASE_MEMBERS = `${URL_BASE}/members`;
const URL_GET_ALL_PARTNERS = `${URL_BASE}/partners`;
const URL_MEMBER_SEARCH = `${URL_BASE_MEMBERS}/search`;
const URL_MEMBER_GET_PARTNERS = `${URL_BASE_MEMBERS}/{memberId}/partners`;
const URL_MEMBER_SET_PARTNER_LEVEL = `${URL_BASE_MEMBERS}/{memberId}/partner-level/{partnerLevel}`;
const URL_MEMBER_ASSIGN_PARTNERS = `${URL_BASE_MEMBERS}/{memberId}/partners`;
const URL_MEMBER_UN_ASSIGN_PARTNERS = `${URL_BASE_MEMBERS}/{memberId}/partners/{partnerId}`;

class ApiAdminHelper extends RequestHelper {
  private static instance: ApiAdminHelper;

  public static getInstance() {
    if (!ApiAdminHelper.instance) {
      ApiAdminHelper.instance = new ApiAdminHelper();
    }

    return ApiAdminHelper.instance;
  }

  public async getMembers(options: UseMembersOptions): Promise<MemberAdministration[]> {
    try {
      const { includeTrivagoEmployees, partnerId, email } = options;
      const params: Record<string, any> = {};
      if (partnerId) params.partnerId = partnerId;
      if (includeTrivagoEmployees) params.includeTrivagoEmployees = '1';
      if (email) params.email = email;

      const response = await this.getRequest(URL_BASE_MEMBERS, { params });
      return response.data.members as MemberAdministration[];
    } catch (e) {
      this.handleError(e as AxiosError, 'admin_members_error_get_members');
      return [];
    }
  }

  public async searchMemberByEmail(email: string): Promise<MemberAdministration> {
    const body: MemberByEmailRequestBody = { email };
    const response = await this.postRequest(URL_MEMBER_SEARCH, body);
    return ApiAdminHelper.searchMemberResponseConverter(response.data);
  }

  public async getMemberRelatedPartners(memberId: number): Promise<PartnerAdministration[]> {
    try {
      const url = ApiAdminHelper.replaceUrlParams(URL_MEMBER_GET_PARTNERS, [
        { key: 'memberId', value: memberId },
      ]);
      const response = await this.getRequest(url);
      return ApiAdminHelper.getMemberRelatedPartnersConverter(response.data);
    } catch (e) {
      this.handleError(e as AxiosError, 'admin_members_error_get_member_partners');
      return [];
    }
  }

  public async setMemberPartnerLevel(
    memberId: number,
    partnerLevelId: number,
  ): Promise<MemberAdministration> {
    try {
      const url = ApiAdminHelper.replaceUrlParams(URL_MEMBER_SET_PARTNER_LEVEL, [
        { key: 'memberId', value: memberId },
        { key: 'partnerLevel', value: PartnerLevels[partnerLevelId] },
      ]);
      const response = await this.putRequest(url, {});
      return ApiAdminHelper.setMemberPartnerLevelConverter(response.data);
    } catch (e) {
      this.handleError(e as AxiosError, 'admin_members_error_set_partner_level');
      return {} as MemberAdministration;
    }
  }

  public async assignMemberPartners(memberId: number, partnerIds: number[]): Promise<void> {
    try {
      const url = ApiAdminHelper.replaceUrlParams(URL_MEMBER_ASSIGN_PARTNERS, [
        { key: 'memberId', value: memberId },
      ]);
      await this.postRequest(url, { partnerIds });
    } catch (e) {
      this.handleError(e as AxiosError, 'admin_members_error_assign_partners');
    }
  }

  public async unAssignMemberPartner(memberId: number, partnerId: number): Promise<void> {
    try {
      const url = ApiAdminHelper.replaceUrlParams(URL_MEMBER_UN_ASSIGN_PARTNERS, [
        { key: 'memberId', value: memberId },
        { key: 'partnerId', value: partnerId },
      ]);
      await this.deleteRequest(url, {});
    } catch (e) {
      this.handleError(e as AxiosError, 'admin_members_error_un_assign_partners');
    }
  }

  public async getAllPartner(): Promise<PartnerAdministration[]> {
    try {
      const response = await this.getRequest(URL_GET_ALL_PARTNERS);
      return ApiAdminHelper.getAllPartnersConverter(response.data);
    } catch (e) {
      this.handleError(e as AxiosError, 'admin_members_error_get_partners');
      return [];
    }
  }

  private static searchMemberResponseConverter(
    response: MemberByEmailResponse,
  ): MemberAdministration {
    return response.member as MemberAdministration;
  }

  private static getMemberRelatedPartnersConverter(
    response: MemberRelatedPartnersResponse,
  ): PartnerAdministration[] {
    return response.partners
      .map(p => (p.subPartners.length > 0 ? p.subPartners : [p]))
      .flat() as PartnerAdministration[];
  }

  private static setMemberPartnerLevelConverter(
    response: MemberPartnerLevelResponse,
  ): MemberAdministration {
    return response.member as MemberAdministration;
  }

  private static getAllPartnersConverter(response: AllPartnersResponse): PartnerAdministration[] {
    return response.partners
      .map(p => (p.subPartners.length > 0 ? p.subPartners : [p]))
      .flat() as PartnerAdministration[];
  }
}

export default ApiAdminHelper.getInstance();
