import { useState } from 'react';
import { BiddingTypeSelectorProps } from './bidding-type-selector';
import { CheckboxSelectOption } from '../checkbox-select/use-checkbox-options';
import { CampaignType, getAllCampaignTypes } from '../../models';

export interface BiddingTypeSelectorState {
  isSlActive: boolean;
  toggleIsSlActive: () => void;
  meta: {
    isActive: boolean;
    toggleIsActive: (value: boolean) => void;
    options: CheckboxSelectOption<number>[];
    toggleOption: (option: CheckboxSelectOption<number>) => void;
    selectedBiddingTypes: number[];
    selectAll: () => void;
    deselectAll: () => void;
  };
}

export function useBiddingTypeSelector(props: BiddingTypeSelectorProps): BiddingTypeSelectorState {
  const [isMetaActive, setIsMetaActive] = useState(props.selectedBiddingTypes.length > 0);
  const [isSlActive, setIsSlActive] = useState(props.isSlActive);
  const allCampaignTypes = getAllCampaignTypes();
  const [metaOptions, setMetaOptions] = useState(
    metaBiddingTypesOptions(allCampaignTypes, props.selectedBiddingTypes),
  );
  const selectedBiddingTypes = metaOptions.filter(o => o.checked).map(o => o.value);

  function toggleOption(option: CheckboxSelectOption<number>) {
    option.checked = !option.checked;
    setMetaOptions(prev => [...prev]);
  }

  const toggleIsMetaActive = (value: boolean) => {
    if (!value) markAllAsChecked(false);
    setIsMetaActive(value);
  };

  const markAllAsChecked = (checked: boolean) =>
    setMetaOptions(prev => prev.map(o => ({ ...o, checked })));

  return {
    isSlActive,
    toggleIsSlActive: () => setIsSlActive(prev => !prev),
    meta: {
      isActive: isMetaActive,
      toggleIsActive: toggleIsMetaActive,
      options: metaOptions,
      toggleOption,
      selectedBiddingTypes,
      selectAll: () => markAllAsChecked(true),
      deselectAll: () => markAllAsChecked(false),
    },
  };
}

const metaBiddingTypesOptions = (
  allCampaignTypes: readonly CampaignType[],
  selectedBiddingTypes: number[] = [],
): CheckboxSelectOption<number>[] =>
  allCampaignTypes.map(type => ({
    id: type.value,
    checked: selectedBiddingTypes.includes(type.value),
    label: type.code,
    value: type.value,
  }));
