import { useRef, useState } from 'react';
import { Row } from '../../models';

export const useRowsSelection = () => {
  const selectedPages = useRef(new Set<number>());
  const [selectedRowsById, setSelectedRowsById] = useState(new Map<number, Row>());

  return {
    selectedPages,
    selectedRowsById,
    setSelectedRowsById,
  };
};
