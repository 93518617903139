import { useCPCBidCapsByLocale } from '../../../hooks/use-max-CPC-cap';
import { BidFileType, MAX_BID_MODIFIER, MIN_BID_MODIFIER, fromTo } from '../../../models';
import { BM_BREAKOUTS } from '../../../views/custom-dashboard/bid-modifier-dimension-menu/bm-dimension-breakouts';
import { HeaderValidationRule, RowValidationRule } from './row-validation-rule';
import {
  MaxCpcValueWarningRule,
  MaxCpcValueWarningRuleOptions,
} from './rules/max-cpc-value-warning-rule';
import { OneOfValuesRule } from './rules/one-of-values-rule';
import { RequiredFieldRule } from './rules/required-field-rule';
import { RequiredHeaderRule } from './rules/required-header-rule';
import { ValidBidModifierBreakoutRule } from './rules/valid-bid-modifier-breakout-rule';
import { ValidFloatNumberRule } from './rules/valid-float-number-rule';
import { ValidIntegerRule } from './rules/valid-integer-rule';
import { ValidLocaleCodeRule } from './rules/valid-locale-code-rule';

const PARTNER_REFERENCE = 'partner_reference';
const LOCALE = 'locale';
const BID = 'bid';
const SL_BID = 'sl bid';
const PROPERTY_GROUP_ID = 'property_group_id';
const DIMENSION = 'dimension';
const BREAKOUT = 'breakout';
const MODIFIER = 'modifier';
const DAILY_BUDGET_EUROCENT = 'daily_budget_eurocent';
const CAMPAIGN = 'campaign';
const CPA_VALUE = 'cpa_value';
const NAME = 'name';
const ADDRESS = 'address';
const POSTCODE = 'postcode';
const CITY = 'city';
const COUNTRY = 'country';
const LONGITUDE = 'longitude';
const LATITUDE = 'latitude';

const MIN_PROPERTY_GROUP_ID = 0;
const MAX_PROPERTY_GROUP_ID = 49;
const DEFAULT_MAX_CPC_VALUE = 600;

type Rules = { headerRules: HeaderValidationRule[]; rowRules: RowValidationRule[] };

type BiddingFilesValidationRules = fromTo<BidFileType, Rules>;

export function useBiddingFilesValidationRules(): BiddingFilesValidationRules {
  const { bidCaps: bidCapsByLocale } = useCPCBidCapsByLocale();

  return {
    [BidFileType.SL_BASE_BID]: baseBidRules({
      fieldName: SL_BID,
      bidCapsByLocale: {},
      defaultMax: DEFAULT_MAX_CPC_VALUE,
    }),
    [BidFileType.SL_BID_MODIFIER]: BID_MODIFIER_RULES,
    [BidFileType.SL_BUDGET_CAP]: SL_BUDGET_CAP_RULES,
    [BidFileType.BASE_BID]: baseBidRules({
      fieldName: BID,
      bidCapsByLocale,
      defaultMax: DEFAULT_MAX_CPC_VALUE,
    }),
    [BidFileType.BID_MODIFIER]: BID_MODIFIER_RULES,
    [BidFileType.PROPERTY_GROUPS]: PROPERTY_GROUPS_RULES,
    [BidFileType.CAMPAIGN_ALLOCATION]: CAMPAIGN_ALLOCATION_RULES,
    [BidFileType.CPA]: CPA_RULES,
    [BidFileType.INVENTORY]: INVENTORY_RULES,
    [BidFileType.CPA_BID_MODIFIER]: {
      headerRules: requiredHeaders([LOCALE, DIMENSION, BREAKOUT, MODIFIER]),
      rowRules: [
        new ValidLocaleCodeRule(LOCALE),
        new OneOfValuesRule({ fieldName: DIMENSION, values: ['TTT'] }),
        new ValidBidModifierBreakoutRule({
          breakoutFieldName: BREAKOUT,
          dimensionFieldName: DIMENSION,
        }),
        new ValidFloatNumberRule({
          fieldName: MODIFIER,
          min: MIN_BID_MODIFIER,
          max: MAX_BID_MODIFIER,
        }),
      ],
    },
  };
}

const requiredHeaders = (headers: string[]): RequiredHeaderRule[] =>
  headers.map(header => new RequiredHeaderRule({ header, ignoreCase: true }));

const SL_BUDGET_CAP_RULES: Rules = {
  headerRules: requiredHeaders([PARTNER_REFERENCE, LOCALE, DAILY_BUDGET_EUROCENT]),
  rowRules: [
    new RequiredFieldRule(PARTNER_REFERENCE),
    new ValidLocaleCodeRule(LOCALE),
    new ValidIntegerRule({ fieldName: DAILY_BUDGET_EUROCENT, min: 0 }),
  ],
};

const baseBidRules = (options: Omit<MaxCpcValueWarningRuleOptions, 'localeFieldName'>): Rules => ({
  headerRules: requiredHeaders([PARTNER_REFERENCE, LOCALE, options.fieldName]),
  rowRules: [
    new RequiredFieldRule(PARTNER_REFERENCE),
    new ValidLocaleCodeRule(LOCALE),
    new ValidIntegerRule({ fieldName: options.fieldName, min: 0 }),
    new MaxCpcValueWarningRule({ ...options, localeFieldName: LOCALE }),
  ],
});

const BID_MODIFIER_RULES: Rules = {
  headerRules: requiredHeaders([LOCALE, PROPERTY_GROUP_ID, DIMENSION, BREAKOUT, MODIFIER]),
  rowRules: [
    new ValidLocaleCodeRule(LOCALE),
    new ValidIntegerRule({
      fieldName: PROPERTY_GROUP_ID,
      min: MIN_PROPERTY_GROUP_ID,
      max: MAX_PROPERTY_GROUP_ID,
    }),
    new OneOfValuesRule({
      fieldName: DIMENSION,
      values: Object.keys(BM_BREAKOUTS).map(key => key.toUpperCase()),
    }),
    new ValidBidModifierBreakoutRule({
      breakoutFieldName: BREAKOUT,
      dimensionFieldName: DIMENSION,
    }),
    new ValidFloatNumberRule({ fieldName: MODIFIER, min: MIN_BID_MODIFIER, max: MAX_BID_MODIFIER }),
  ],
};

const PROPERTY_GROUPS_RULES: Rules = {
  headerRules: requiredHeaders([LOCALE, PARTNER_REFERENCE, PROPERTY_GROUP_ID]),
  rowRules: [
    new ValidLocaleCodeRule(LOCALE),
    new RequiredFieldRule(PARTNER_REFERENCE),
    new ValidIntegerRule({
      fieldName: PROPERTY_GROUP_ID,
      min: MIN_PROPERTY_GROUP_ID,
      max: MAX_PROPERTY_GROUP_ID,
    }),
  ],
};

const CAMPAIGN_ALLOCATION_RULES: Rules = {
  headerRules: requiredHeaders([PARTNER_REFERENCE, LOCALE, CAMPAIGN]),
  rowRules: [
    new RequiredFieldRule(PARTNER_REFERENCE),
    new ValidLocaleCodeRule(LOCALE),
    new ValidIntegerRule({ fieldName: CAMPAIGN, min: 0, max: 12 }),
  ],
};

const CPA_RULES: Rules = {
  headerRules: requiredHeaders([LOCALE, CAMPAIGN, CPA_VALUE]),
  rowRules: [
    new ValidLocaleCodeRule(LOCALE),
    new ValidIntegerRule({ fieldName: CAMPAIGN, min: 1, max: 12 }),
    new ValidFloatNumberRule({ fieldName: CPA_VALUE, min: 0 }),
  ],
};

const INVENTORY_REQUIRED_HEADERS = [
  PARTNER_REFERENCE,
  NAME,
  ADDRESS,
  POSTCODE,
  CITY,
  COUNTRY,
  LONGITUDE,
  LATITUDE,
];

const INVENTORY_RULES: Rules = {
  headerRules: requiredHeaders(INVENTORY_REQUIRED_HEADERS),
  rowRules: [
    ...INVENTORY_REQUIRED_HEADERS.map(field => new RequiredFieldRule(field)),
    new ValidFloatNumberRule({ fieldName: LONGITUDE, min: -180, max: 180 }),
    new ValidFloatNumberRule({ fieldName: LATITUDE, min: -90, max: 90 }),
  ],
};
