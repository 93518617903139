import * as React from 'react';
import { Props } from './wrapper-error-hint.types';
import './wrapper-error-hint.css';
import cs from 'classnames';

export const WrapperErrorHint: React.FunctionComponent<Props> = ({
  message,
  isShown,
  extraClasses = '',
  children,
}) => {
  return (
    <div className="u-position--relative">
      {children}
      {isShown && <span className={cs('c-wrapper-error-hint', extraClasses)}>{message}</span>}
    </div>
  );
};
