import { ReactNode, useEffect } from 'react';
import './checkbox-select.css';
import cs from 'classnames';
import { CheckboxSelectFooter } from './checkbox-select-footer';
import { CheckboxSelectHeader } from './checkbox-select-header';
import { CheckboxSelectOption, useCheckboxOptions } from './use-checkbox-options';

export type CheckboxSelectProps<T> = {
  title?: string;
  options: CheckboxSelectOption<T>[];
  selectedOptionsLimit?: number;
  searchEnabled?: boolean;
  searchPlaceholder?: string;
  onApply: (options: CheckboxSelectOption<T>[]) => void;
  allowEmptySelection?: boolean;
  customHeader?: ReactNode;
  customOptions?: ReactNode;
  customSelectedDate?: Date;
};

export const CheckboxSelect = <T,>(props: CheckboxSelectProps<T>) => {
  const {
    options,
    visibleOptions,
    selectAll,
    clearSelection,
    filterVisible,
    toggleAvailability,
    toggle,
    isMaxReached,
  } = useCheckboxOptions(props.options, props.selectedOptionsLimit);

  useEffect(() => {
    const isCustomDateSelected = Boolean(props.customSelectedDate);
    const enableOptions = !isCustomDateSelected;
    toggleAvailability(enableOptions);
    if (!isCustomDateSelected) return;

    clearSelection();
  }, [props.customSelectedDate]);

  const checkedOptionsCount = options.filter(o => o.checked).length;
  const { customHeader, customOptions } = props;

  const canSelectAll =
    !hasRadio(options) &&
    (props.selectedOptionsLimit === undefined || props.selectedOptionsLimit > options.length);

  const hasSubLabels = options.some(o => !!o.subLabel);
  return (
    <div className="checkbox-select u-background--white">
      {customHeader ?? (
        <CheckboxSelectHeader
          title={props.title}
          onSelectAll={canSelectAll ? selectAll : undefined}
          onClearSelection={clearSelection}
          onSearchTextChange={props.searchEnabled ? filterVisible : undefined}
          searchPlaceholder={props.searchPlaceholder}
        />
      )}
      <ul className={cs('options', { 'has-sub-labels': hasSubLabels })}>
        {visibleOptions.length === 0 && <p className="has-sub-labels">No options available</p>}
        {visibleOptions.map(option => (
          <Option
            key={option.id}
            option={option}
            isDisabled={option.isDisabled || (isMaxReached && !option.checked)}
            toggle={toggle}
          />
        ))}
      </ul>
      {customOptions}
      <CheckboxSelectFooter
        onApply={() => props.onApply(options)}
        numberOfSelectedOptions={checkedOptionsCount}
        selectedOptionsLimit={props.selectedOptionsLimit}
        allowEmptySelection={props.allowEmptySelection}
      />
    </div>
  );
};

function hasRadio<T>(options: CheckboxSelectOption<T>[]) {
  return options.filter(o => o.isRadio).length > 0;
}

type OptionProps<T> = {
  option: CheckboxSelectOption<T>;
  isDisabled: boolean;
  toggle: (option: CheckboxSelectOption<T>) => void;
};

const Option = <T,>({ option, isDisabled, toggle }: OptionProps<T>) => {
  return (
    <li title={option.tooltip}>
      <label
        className={cs('option-item', {
          'option-item--selected': option.checked,
          'option-item--disabled': isDisabled,
        })}
      >
        <input
          type={option.isRadio ? 'radio' : 'checkbox'}
          checked={option.checked}
          disabled={isDisabled}
          onChange={() => toggle(option)}
        />
        <div className="labels">
          <p>{option.label}</p>
          <p title={option.subLabel} className="sub-label">
            {option.subLabel}
          </p>
        </div>
      </label>
    </li>
  );
};
