import * as React from 'react';
import EditLineDarkIcon from 'tcp-react-icons/lib/EditLineDarkIcon';
import DeleteLineDarkIcon from 'tcp-react-icons/lib/DeleteLineDarkIcon';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import TickThinDarkIcon from 'tcp-react-icons/lib/TickThinDarkIcon';
import { InputProps, Props } from './filter-set-option.types';
import { useOnClickOutside } from '../../../../hooks/use-on-click-outside';
import { useSelectFilterSet } from '../hooks/use-select-filter-set';
import { useEditFilterSetName } from '../hooks/use-edit-filter-set-name';
import { useFilterSetDeleteModal } from '../hooks/use-filter-set-delete-modal';
import { useModal } from 'react-modal-hook';
import { useVerifiedFilterSetName } from './use-verified-filter-set-name';
import { ConfirmationModal } from '../../../confirmation-modal';
import { pressKeyEnter, translate } from '../../../../helpers/utils';
import './filter-set-option.css';
import { getDefaultFilterSetName } from '../helpers/default-filter-set-name';
import ApiAnalyticsHelper from '../../../../helpers/api/analytics/api-analytics-helper';
import { AppContext } from '../../../../global/context/app-context';
import { getPartner } from '../../../../services/app-service';
import { FilterSet } from '../../../../models';
import TrackerService from '../../../../services/tracker/tracker-service';
import { FILTERS_DELETE_FILTER_SET, NotificationLevel } from '../../../../constants';
import { showNotification } from '../../../../services/notification-service';
import { useFilters } from '../../../../global/context/filter-context';

export const FilterSetOption: React.FunctionComponent<Props> = ({
  filterSet,
  onSaveName,
  onStopEditing,
  isDefault = false,
  fromName,
}) => {
  const DUPLICATE_FILTER_NAME_SUFFIX = translate('analytics_filters_copy');
  const DEFAULT_FILTER_SET_NAME = getDefaultFilterSetName();
  const { getVerifiedName } = useVerifiedFilterSetName();
  const { name: filterSetName, filters } = filterSet;
  const isEmptyName = !filterSetName;
  const name = isDefault ? DEFAULT_FILTER_SET_NAME : filterSetName;

  const { isSelected, setSelected: setIsSelected, storeSelected } = useSelectFilterSet(name);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_editingFilterSetName, setEditingFilterSetName, isEditingCurrentFilterSet] =
    useEditFilterSetName(name);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isFilterSetDeleteModalOpen, setIsFilterSetDeleteModalOpen] = useFilterSetDeleteModal();
  const { storedFilterSets, setStoredFilterSets } = useFilters();

  const didSaveNameRef = React.useRef(false);

  const { partner, subPartners } = React.useContext(AppContext);
  const { partnerId } = getPartner(partner, subPartners);

  const selectSet = () => {
    setIsSelected(name);
  };
  const startEditing = () => {
    setEditingFilterSetName(name);
    setIsSelected(name);
  };
  const stopEditing = (didCancel: boolean) => {
    setEditingFilterSetName('');
    const isNew = filterSetName !== fromName;
    const didSaveName =
      (isNew && didSaveNameRef.current) || (!didCancel && (!isEmptyName || didSaveNameRef.current));
    onStopEditing(didSaveName);
  };

  React.useEffect(() => {
    if (isEmptyName) startEditing();
  }, [isEmptyName]);

  const saveOrUpdateFilterSet = async (
    isNewFilterSet: boolean,
    partnerId: number,
    newFilterSet: FilterSet,
  ) => {
    try {
      if (isNewFilterSet) {
        await ApiAnalyticsHelper.saveFilterSet(partnerId, newFilterSet);
      } else {
        await ApiAnalyticsHelper.deleteFilterSet(partnerId, filterSet);
        await ApiAnalyticsHelper.saveFilterSet(partnerId, newFilterSet);
      }
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  const saveName = async (nextName: string) => {
    didSaveNameRef.current = true;

    const verifiedName = getVerifiedName({
      filterSetName,
      nextName,
      fromName,
      defaultName: DEFAULT_FILTER_SET_NAME,
      storedFilterSets,
      duplicateSuffix: DUPLICATE_FILTER_NAME_SUFFIX,
    });
    const isNewFilterSet =
      storedFilterSets.findIndex(filterSet => filterSet.name === filterSetName) === -1;
    const newFilterSet = { name: verifiedName, filters };
    const withNewFilterSet = [...storedFilterSets, newFilterSet];
    const editedFilterSets = storedFilterSets.map(filterSet =>
      filterSet.name === filterSetName ? newFilterSet : filterSet,
    );
    const filterSetsToStore = isNewFilterSet ? withNewFilterSet : editedFilterSets;
    saveOrUpdateFilterSet(isNewFilterSet, partnerId, newFilterSet);
    setStoredFilterSets(filterSetsToStore);
    setIsSelected(verifiedName);
    setEditingFilterSetName('');
    onSaveName(verifiedName);

    const timeoutId = setTimeout(() => (didSaveNameRef.current = false), 300);
    return () => clearTimeout(timeoutId);
  };

  const deleteFilterSet = async () => {
    try {
      const withRemovedFilterSets = storedFilterSets.filter(
        filterSet => filterSet.name !== filterSetName,
      );
      await ApiAnalyticsHelper.deleteFilterSet(partnerId, filterSet);
      setStoredFilterSets(withRemovedFilterSets);
      storeSelected(DEFAULT_FILTER_SET_NAME);
      TrackerService.track(FILTERS_DELETE_FILTER_SET);
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  const [showModal, closeModal] = useModal(() => {
    const handleCloseModal = () => {
      closeModal();
      setIsFilterSetDeleteModalOpen(false);
    };
    return (
      <ConfirmationModal
        title={translate('analytics_filters_delete_saved_filter_title')}
        details={[
          <span>{translate('analytics_filters_delete_saved_filter_details', filterSetName)}</span>,
        ]}
        onClose={handleCloseModal}
        primaryButton={{
          title: translate('analytics_filters_delete_saved_filter_confirm'),
          actionFn: () => {
            handleCloseModal();
            deleteFilterSet();
          },
          style: 'e-button--danger',
        }}
        secondaryButton={{
          title: translate('common_cancel'),
          actionFn: handleCloseModal,
        }}
      />
    );
  }, [storedFilterSets]);

  const handleDelete = () => {
    showModal();
    setIsFilterSetDeleteModalOpen(true);
  };

  if (isEditingCurrentFilterSet) {
    return <InputNameFilterOption name={name} onSave={saveName} onClose={stopEditing} />;
  }

  return (
    <article
      className={`c-filter-set-option ${
        isSelected && !isEditingCurrentFilterSet ? 'selected' : ''
      }`}
      onClick={selectSet}
    >
      <div className="c-filter-set-option__label">{filterSetName}</div>
      {!isDefault && (
        <div className="c-filter-set-option__actions">
          <EditLineDarkIcon onClick={startEditing} className="u-margin-left--medium" />
          <DeleteLineDarkIcon
            onClick={handleDelete}
            className="u-margin-left--medium u-margin-right--tiny"
          />
        </div>
      )}
    </article>
  );
};

const InputNameFilterOption: React.FunctionComponent<InputProps> = ({ name, onSave, onClose }) => {
  const [nextName, setNextName] = React.useState<string>(name);
  const inputRef = React.useRef(null);

  const changeName = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNextName(event.target.value);
  const confirm = () => {
    onSave(nextName);
    onClose(!nextName);
  };
  const cancel = () => {
    onClose(true);
  };

  useOnClickOutside(inputRef, cancel);

  return (
    <article className="c-filter-set-option__input selected" ref={inputRef}>
      <input
        autoFocus
        value={nextName}
        placeholder={translate('analytics_filter_sets_name_placeholder')}
        onChange={changeName}
        onKeyDown={({ key }) => pressKeyEnter(key, confirm)}
        className="u-padding--small"
      />
      <CloseIcon width={26} height={26} onClick={cancel} className="u-cursor--pointer" />
      <div className="c-filter-set-option__input-confirm">
        <TickThinDarkIcon width={30} height={30} onClick={confirm} className="u-cursor--pointer" />
      </div>
    </article>
  );
};
