import { Props } from './menu-item-remove.types';
import DeleteLineDarkIcon from 'tcp-react-icons/lib/DeleteLineDarkIcon';

export const MenuItemRemove: React.FunctionComponent<Props> = ({ children, onClick }) => {
  return (
    <div onClick={onClick} className="c-menu__item-remove-button">
      <DeleteLineDarkIcon className="u-color--red u-margin-right--t-small" />
      {children}
    </div>
  );
};
