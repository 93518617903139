import * as React from 'react';
import { Props } from './admin-partner-row.types';
import './admin-partner-row.css';
import { ButtonIcon } from '../../../../core-ui/components/button-icon/button-icon';

export const AdminPartnerRow: React.FunctionComponent<Props> = ({
  partner: { partnerId, name },
  onUnAssignPartner,
}) => {
  return (
    <>
      <td className="u-font-weight-bold u-font-size--h-tiny">{partnerId}</td>
      <td className="u-font-size--h-tiny">{name}</td>
      <td>
        <ButtonIcon
          icon={{ name: 'close' }}
          onClick={() => onUnAssignPartner(partnerId)}
          style={{ verticalAlign: 'middle' }}
        />
      </td>
    </>
  );
};
