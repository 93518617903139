import { parse } from 'papaparse';

/**
 * Extract headers from CSV file
 * @param file
 * @return Promise<string[]> list of headers
 */
export function extractFileHeaders(file: File): Promise<{ data: [string]; delimiter: string }> {
  return new Promise((resolve, reject) => {
    parse<any, File>(file, {
      preview: 1,
      skipEmptyLines: true,
      complete: results => {
        const { errors, data, meta } = results;
        if (errors.length > 0) {
          reject(errors);
        } else {
          resolve({ data: data[0], delimiter: meta.delimiter });
        }
      },
    });
  });
}

/**
 * Read CSV file
 * @param file
 * @return Promise<string[][]> array of file lines expressed as object[]
 */
export function readFile(file: File): Promise<{ data: string[][]; delimiter: string }> {
  return new Promise((resolve, reject) => {
    parse<any, File>(file, {
      skipEmptyLines: true,
      chunk: results => {
        const { errors, data, meta } = results;
        if (errors.length > 0) {
          reject(errors);
        } else {
          resolve({ data, delimiter: meta.delimiter });
        }
      },
      complete: console.log,
    });
  });
}
