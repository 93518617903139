import * as React from 'react';
import { Props } from './teapot-animation.types';
import logo from './150x150px-teapot-animation.png';

export const TeapotAnimation: React.FunctionComponent<Props> = props => {
  return (
    <React.Fragment>
      <img
        className="u-margin-auto u-display--flex"
        src={logo}
        width="150"
        alt="Teapot showing no results for current filter selection"
      />
      <p className="u-color--juri-light u-font-size--h-tiny u-justify-content--center u-margin-auto u-display--flex">
        {props.message}
      </p>
    </React.Fragment>
  );
};
