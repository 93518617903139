import React from 'react';
import { Props, VariantComponentMap } from './chart.types';
import { LineChartComponent } from './line-chart';
import { BarChartComponent } from './bar-chart';

const variantComponents = {
  singleAxisLine: LineChartComponent,
  doubleAxisLine: LineChartComponent,
  bar: BarChartComponent,
};

const withVariant =
  (variantComponentMap: VariantComponentMap) =>
  ({ variant, ...props }: Props) => {
    const Component = variantComponentMap[variant];
    if (!Component) {
      throw new Error(`Invalid variant "${variant}"`);
    }
    return <Component {...props} variant={variant} />;
  };

export const Chart: React.FunctionComponent<Props> = ({ variant, ...props }) => {
  const ComponentWithVariantRendering = withVariant(variantComponents);
  return <ComponentWithVariantRendering variant={variant} {...props} />;
};
