import * as React from 'react';
import { Props, ShortCutType } from './selection-shortcut.types';
import { translate } from '../../helpers/utils';

export const SelectionShortcut: React.FunctionComponent<Props> = props => {
  return (
    <div className="u-margin-left--large u-margin-left--large u-margin-top--small">
      <span>
        {props.totalSelectedProperties} {translate('analytics_control_panel_properties_selected')}
      </span>
      <a
        className="u-margin-left--large u-cursor--pointer"
        onClick={() => props.onShortcut(ShortCutType.SELECT_ALL)}
      >
        {translate('analytics_shortcut_select_all')} {props.totalProperties}
      </a>
      <a
        className="u-margin-left--large u-cursor--pointer"
        onClick={() => props.onShortcut(ShortCutType.UNSELECT)}
      >
        {translate('analytics_shortcut_unselect')}
      </a>
    </div>
  );
};
