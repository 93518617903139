import { ChartColor, Color, TimeOption } from '../constants';
import {
  Dimension,
  SelectedDimension,
} from '../views/custom-dashboard/multi-folder-select/multi-folder-select.types';
import { View } from './view';

type Widget = {
  uuid: string;
  name?: string;
  position: WidgetPosition;
  size: {
    height: number; //  min is 1, max is maxHeight value
    maxHeight: number; // default = 1
    width: number; // min is 1, max is 3
  };
  isDraggable: boolean; // default: true
  isResizable: boolean; // default is true
  type: WidgetType;
};

export type DashBoard = {
  id: number;
  name: string;
  widgets: DashboardWidget[];
};

export type DashboardWidget =
  | TimelineWidget
  | YesterdayOverviewWidget
  | PosOverviewWidget
  | GlobalInventoryWidget
  | MBLUWidget;

export type Metric = {
  id: string;
  color?: Color | ChartColor;
};

export type SelectedDimensionApi = {
  name: Dimension;
  values: string[];
};

export type DimensionValue = {
  value: string | number;
};

export type DimensionValueLabels = DimensionValue & {
  label: string;
};

export type TimelineWidgetProps = {
  name?: string;
  metrics: Metric[];
  dimension?: SelectedDimensionApi;
};
export type ExtendedTimelineWidgetProps = {
  uuid?: string;
  name?: string;
  metrics: Metric[];
  dimension?: SelectedDimension;
  aggregationPeriod: TimeOption;
};
export type YesterdayOverviewWidgetProps = {};
export type PosOverviewWidgetProps = {};
export type GlobalInventoryWidgetProps = {};
export type MBLUWidgetProps = {};

export type DashboardWidgetProps =
  | TimelineWidgetProps
  | YesterdayOverviewWidgetProps
  | PosOverviewWidgetProps
  | GlobalInventoryWidgetProps
  | MBLUWidgetProps;

export type TimelineWidget = {
  props: TimelineWidgetProps;
} & Widget;

export type YesterdayOverviewWidget = {
  props: YesterdayOverviewWidgetProps;
} & Widget;

export type PosOverviewWidget = {
  props: PosOverviewWidgetProps;
} & Widget;

export type GlobalInventoryWidget = {
  props: GlobalInventoryWidgetProps;
} & Widget;

export type MBLUWidget = {
  props: MBLUWidgetProps;
} & Widget;

export type WidgetPosition = {
  x: number;
  y: number;
};

export enum WidgetType {
  TIMELINE = 'timeline-widget',
  YESTERDAY_OVERVIEW = 'yesterday-overview-widget',
  POS_OVERVIEW = 'pos-overview-widget',
  GLOBAL_INVENTORY = 'global-inventory-widget',
  MBLU = 'mblu-widget',
  CHART_BUILDER = 'chart-builder',
  UNKNOWN = 'unknown-widget',
}

export enum DashBoardType {
  DEFAULT = 0,
  EMPTY = 1,
}

export type MetricColumn = {
  key: string;
  name: string;
  type: string;
  wrapper: string;
  tooltip: string;
  view?: View;
  isComparable?: boolean;
  isHidden?: boolean;
};
