import * as React from 'react';
import { Props } from './option.types';
import { FlagIcon } from '../../../../../components/flag-icon';
import cs from 'classnames';

export const Option: React.FunctionComponent<Props> = ({
  option,
  onChange,
  isSelected,
  className,
  isMultiple = false,
}) => {
  return (
    <li
      className={cs('c-option u-padding--small', className, {
        'c-option__checked': isSelected,
      })}
    >
      <label
        className="u-display--flex u-align-items--center u-cursor--pointer"
        data-qa={`option-${option.id}`}
      >
        <input
          type={isMultiple ? 'checkbox' : 'radio'}
          className="u-display--inline-block u-margin-right--small"
          checked={isSelected}
          onChange={() => onChange(option)}
        />
        {option.flag && <FlagIcon flag={option.flag} className="u-margin-right--small" />}
        <span className="u-font-weight--normal u-margin-right--small">{option.label}</span>
        {option.subLabel && (
          <div className="has-tooltip">
            <span className="u-color--juri-lighter u-margin-right--medium">{option.subLabel}</span>
            <div className="tooltip-wrapper tooltip-wrapper--trailing-center">
              <div className="tooltip" tabIndex={0} role="tooltip">
                <p className="u-color--white u-line-height--small u-font-size--tiny u-font-weight--normal">
                  {option.value}
                </p>
              </div>
            </div>
          </div>
        )}
      </label>
    </li>
  );
};
