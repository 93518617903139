import { FC } from 'react';
import { GridTrendCellProps } from './grid-trend-cell.types';
import cn from 'classnames';
import styles from './grid-trend-cell.module.css';
import { Icon } from '../../../core-ui/components/icon';
import { formatNumber, formatNumericalValues, invertedTrendColumns } from '../utils/grid-utils';

export const GridTrendCell: FC<GridTrendCellProps> = ({ value, column, configuration }) => {
  const { isPositiveTrend, comparisonValue, columnType } = value;
  const isValueUnchanged = comparisonValue === 0;

  const isInvertedColumn = invertedTrendColumns.includes(column.key);

  const cellClassName = cn(styles.comparisonCell, {
    [styles.positiveTrendCell]: isPositiveTrend,
    [styles.negativeTrendCell]: !isPositiveTrend,
    [styles.unchangedCell]: isValueUnchanged,
  });

  if (comparisonValue === '-') {
    let icon: 'pointingArrowUp' | 'pointingArrowDown' = isPositiveTrend
      ? 'pointingArrowUp'
      : 'pointingArrowDown';
    if (isInvertedColumn) icon = isPositiveTrend ? 'pointingArrowDown' : 'pointingArrowUp';

    return (
      <span className={cellClassName} data-testid="grid-trend-cell">
        -
        <Icon name={icon} />
      </span>
    );
  }

  const changeIndicatorIcon = isValueUnchanged ? null : (
    <Icon name={comparisonValue > 0 ? 'pointingArrowUp' : 'pointingArrowDown'} />
  );

  const changeValue =
    columnType === 'relative'
      ? formatNumber(configuration, comparisonValue, 'percent', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      : formatNumericalValues(Math.abs(comparisonValue), column, configuration, columnType);

  return (
    <span className={cellClassName} data-testid="grid-trend-cell">
      {changeValue}
      {changeIndicatorIcon}
    </span>
  );
};
