import format from 'date-fns/format';
import {
  EventType,
  HistoryEvent,
  ImportSchedule,
  Properties,
  RemainingPushes,
} from '../../../models';
import { DATE_FORMAT_STANDARD } from '../../../constants';
import { RequestHelper } from '../request-helper';
import { AxiosError } from 'axios';

const URL_HISTORY = 'bidding/job/{partnerId}/{fromDate}/{endDate}';
const URL_REMAINING_PUSHES = 'bidding/remaining-pushes/{partnerId}';
const URL_IMPORT_SCHEDULES = 'bidding/import-schedules/{partnerId}';

class ApiHistoryHelper extends RequestHelper {
  private static instance: ApiHistoryHelper;

  public static getInstance() {
    if (!ApiHistoryHelper.instance) {
      ApiHistoryHelper.instance = new ApiHistoryHelper();
    }

    return ApiHistoryHelper.instance;
  }

  public async fetchEvents(
    partnerId: number,
    from: Date,
    to: Date,
    types: EventType[],
  ): Promise<HistoryEvent[]> {
    try {
      const pathParams = [
        { key: 'partnerId', value: partnerId },
        { key: 'fromDate', value: format(from, DATE_FORMAT_STANDARD) },
        { key: 'endDate', value: format(to, DATE_FORMAT_STANDARD) },
      ];
      const url = RequestHelper.replaceUrlParams(URL_HISTORY, pathParams);
      const queryParams = { type: types };

      const data = await this.getRequest(url, {
        params: queryParams,
        paramsSerializer: params => (params.type as number[]).map(t => `type=${t}`).join('&'),
      });
      return (data.data as Job[]).map(jobTmp => {
        const eventProperties: Properties = this.parseJsonToEventProperties(jobTmp.properties);
        return new HistoryEvent(
          jobTmp.id,
          jobTmp.status,
          jobTmp.createdAt,
          jobTmp.type,
          eventProperties,
        );
      });
    } catch (e) {
      this.handleError(e as AxiosError);
      return [];
    }
  }

  public async fetchRemainingPushes(partnerId: number): Promise<RemainingPushes[]> {
    const url = RequestHelper.replaceUrlParams(URL_REMAINING_PUSHES, [
      { key: 'partnerId', value: partnerId },
    ]);
    const response = await this.getRequest(url);
    return response.data;
  }

  public async fetchImportSchedules(partnerId: number): Promise<ImportSchedule[]> {
    const url = RequestHelper.replaceUrlParams(URL_IMPORT_SCHEDULES, [
      { key: 'partnerId', value: partnerId },
    ]);
    const response = await this.getRequest(url);
    return response.data;
  }

  private parseJsonToEventProperties = (serializedProperties: string): Properties => {
    const properties = {
      pos: '',
      targetValue: 0,
      previousTargetValue: 0,
      budgetValue: 0,
      previousBudgetValue: 0,
      filePath: '',
      sourceFilePath: '',
      sourceFileAWSPath: '',
      error: '',
    };
    if (serializedProperties !== '') {
      const jsonData = JSON.parse(serializedProperties);

      if (jsonData.properties) {
        const subProperties = jsonData.properties[0];
        properties.pos = subProperties.pos;
        properties.targetValue = subProperties.target.value;
        properties.previousTargetValue = subProperties.target.previousValue;
        properties.budgetValue = subProperties.budget.value;
        properties.previousBudgetValue = subProperties.budget.previousValue;
      }
      properties.filePath = jsonData.filePath;
      properties.sourceFilePath = jsonData.sourceFilePath;
      properties.sourceFileAWSPath = jsonData.sourceFileAWSPath;
      properties.error = jsonData.error;
    }

    return properties;
  };
}

type Job = {
  createdAt: string;
  type: number;
  executionResult: string;
  id: number;
  status: number;
  properties: string;
};

export default ApiHistoryHelper.getInstance();
