import { FC, ReactNode, useContext, useState } from 'react';
import { PROPERTY_LIST_TYPE_COLUMN_NAME, PropertyListType } from './campaigns.types';
import styles from './campaigns.module.css';
import { AppContext } from '../../../global/context/app-context';
import {
  Aggregation,
  BaseNotification,
  BiddingCampaign,
  CampaignTypeCode,
  CPC_CAMPAIGN_CODE,
  FileFormat,
  getAllCampaignTypes,
  Row,
  View,
} from '../../../models';
import { Toolbar } from '../../../components/toolbar';
import { filterListWithPosBySearchText, translate, translateHtml } from '../../../helpers/utils';
import { ControlPanel } from '../control-panel';
import { useSimpleViewTable } from '../../../hooks/use-simple-view-table';
import { ColumnBase, ColumnOnboardingTooltip, Grid, StyleRow } from '../../../components/grid';
import { Notification } from '../../../components/notification';
import { ClickableCellProps, formatColumnsAndRows } from '../../../helpers/grid-formatter';
import {
  CAMPAIGNS_BIDDING_CPA_VALUE_APPLIED,
  CAMPAIGNS_VIEW_ACTIVE_ONLY_TOGGLE,
  COLUMN_CAMPAIGNS_CPA_VALUE,
  COLUMN_CAMPAIGNS_ID,
  COLUMN_CAMPAIGNS_LOCALE_CODE,
  COLUMN_CAMPAIGNS_MIN_CPA_VALUE,
  COLUMN_CAMPAIGNS_PROPERTIES,
  COLUMN_CAMPAIGNS_TYPE,
  COLUMN_KEY_PROPERTIES_ALL,
  COLUMN_KEY_PROPERTIES_INACTIVE,
  COLUMN_KEY_PROPERTIES_INVISIBLE,
  COLUMN_KEY_PROPERTIES_NO_BID,
  COLUMN_POS,
  DATE_FORMAT_ISO_8601,
  GRID_VIEW_CONFIGURATION,
  INVENTORY_IMPORT_ONBOARDING_KEY,
  MULTI_SELECT_CAMPAIGN_PREFIX,
  MULTI_SELECT_DROPDOWN_APPLY,
  MULTI_SELECT_DROPDOWN_OPEN,
  NotificationLevel,
  POS_GLOBAL_VALUE,
  TIMEOUT_NOTIFICATION,
} from '../../../constants';
import { ResultsNotFound } from '../../../components/results-not-found';
import { LocaleColumn } from '../../../components/locale-column';
import { getPartner, hasUserBiddingRights } from '../../../services/app-service';
import { CloseIcon, ExclamationCircleFillDarkIcon, SearchLineDarkIcon } from 'tcp-react-icons';
import { InputIcon } from '../../../components/input-icon';
import { RevertBidChanges } from './revert-bid-changes';
import { roundPercentage } from '../../../helpers/formater';
import { ExportOption, ViewExporter } from '../../../components/view-exporter';
import {
  ButtonDropdownFileUploader,
  FileUploaderOption,
} from '../../../components/button-dropdown-file-uploader';
import { IsVisibleToggle } from '../../../components/is-visible-toggle';
import TrackerService from '../../../services/tracker/tracker-service';
import {
  getCampaignFiltersFromLocalStorage,
  saveCampaignFiltersToLocalStorage,
} from '../../../helpers/local-storage-helper';
import { useBidFileUploaderOptions } from './use-bid-file-uploader-options';
import { BiddingResponse } from '../../../helpers/api/bidding/types';
import { useExportPropertiesList } from '../../../hooks/use-export-properties-list';
import { format } from 'date-fns';
import { PopoverDirection } from '../../../components/popover';
import cn from 'classnames';
import { OnboardingTooltip } from '../../../components/onboarding-tooltip';
import { Button } from '../../../core-ui/components/button/button';
import { TooltipWrapper, TooltipPosition } from '../../../components/tooltip-wrapper';
import { CheckboxSelect, CheckboxSelectOption } from '../../../components/checkbox-select';
import { Anchor } from '../../../core-ui/components/anchor';

export const Campaigns: FC = () => {
  const { partner, subPartners, user, locales } = useContext(AppContext);
  const [search, setSearch] = useState('');
  const [notification, setNotification] = useState<BaseNotification | undefined>();

  const [isActiveOnly, setIsActiveOnly] = useState(true);
  const hiddenCampaigns: CampaignTypeCode[] = ['CPA 99_beta'];
  const availableCampaigns = getAllCampaignTypes().filter(
    ({ code }) => !hiddenCampaigns.includes(code),
  );
  const selectedCampaignCodes: CampaignTypeCode[] =
    getCampaignFiltersFromLocalStorage(availableCampaigns);

  const dropdownOptions: CheckboxSelectOption<string>[] = availableCampaigns.map(({ code }) => ({
    id: code,
    key: code,
    value: code,
    label: code,
    checked: true,
  }));

  const [selectedOptions, setSelectedOptions] = useState<CheckboxSelectOption<string>[]>(
    selectedCampaignCodes.map(code => ({
      id: code,
      key: code,
      value: code,
      label: code,
      checked: selectedCampaignCodes.includes(code),
    })),
  );

  const clickApply = (options: CheckboxSelectOption<string>[]) => {
    setSelectedOptions(options);
    saveCampaignFiltersToLocalStorage(options.map(({ value }) => value));
  };

  const {
    table: {
      columns,
      data: { rows },
    },
    pagination: { pagination },
    resetData,
    isLoading,
    retry,
    selection: {
      selectedPages,
      selectedRowsById,
      selectRow,
      setSelectedPages,
      setSelectedRowsById,
    },
  } = useSimpleViewTable(
    View.CAMPAIGNS,
    {
      aggregation: Aggregation.CAMPAIGNS,
      localeCodes: [POS_GLOBAL_VALUE],
    },
    {
      columnId: COLUMN_CAMPAIGNS_ID,
      isUsingPagination: false,
    },
  );

  const { exportPropertiesList } = useExportPropertiesList(
    row => row[COLUMN_CAMPAIGNS_TYPE],
    row => row[COLUMN_CAMPAIGNS_LOCALE_CODE],
  );

  const propertyNumClickableCellProps = (
    propertyListType: PropertyListType,
  ): ClickableCellProps => ({
    action: (row: Row, fileFormat: FileFormat) =>
      exportPropertiesList(row, propertyListType, fileFormat),
    displayValue: (row: Row) => row[PROPERTY_LIST_TYPE_COLUMN_NAME[propertyListType]] || '-',
    fileDownloadProps: { fileFormats: [FileFormat.CSV, FileFormat.EXCEL] },
  });

  const row2Campaign = (): BiddingCampaign[] => {
    return Array.from(selectedRowsById.keys()).map<BiddingCampaign>(id => {
      const row = selectedRowsById.get(id)!;
      return {
        cpaValue:
          row[COLUMN_CAMPAIGNS_CPA_VALUE] !== ''
            ? roundPercentage(row[COLUMN_CAMPAIGNS_CPA_VALUE])
            : undefined,
        minCpaValue: roundPercentage(row[COLUMN_CAMPAIGNS_MIN_CPA_VALUE]),
        type: availableCampaigns.find(type => type.code === row[COLUMN_CAMPAIGNS_TYPE])!,
        pos: locales.find(l => l.localeCode === row[COLUMN_CAMPAIGNS_LOCALE_CODE])!,
      };
    });
  };

  let { formattedColumns, formattedRows } = formatColumnsAndRows({
    columns,
    rows,
    total: undefined,
    id: COLUMN_CAMPAIGNS_ID,
    isShowingCheckbox: true,
    customColumns: {
      posLocale: {
        key: COLUMN_CAMPAIGNS_LOCALE_CODE,
        children: (data: { localeCode: string; name: string }) => {
          return (
            <LocaleColumn
              key={data.localeCode}
              pos={{
                localeCode: data.localeCode,
                name: data.name,
              }}
              styles={{
                font: 'u-color--juri u-font-size--tiny',
              }}
            />
          );
        },
      },
      columnCellClickActions: {
        [COLUMN_KEY_PROPERTIES_ALL]: propertyNumClickableCellProps(PropertyListType.ALL),
        [COLUMN_KEY_PROPERTIES_INACTIVE]: propertyNumClickableCellProps(PropertyListType.INACTIVE),
        [COLUMN_KEY_PROPERTIES_INVISIBLE]: propertyNumClickableCellProps(
          PropertyListType.INVISIBLE,
        ),
        [COLUMN_KEY_PROPERTIES_NO_BID]: propertyNumClickableCellProps(PropertyListType.NO_BID),
      },
    },
  });

  formattedColumns = formattedColumns.map(columnTmp => {
    (columnTmp as ColumnBase).isSortable = false;
    return columnTmp;
  });
  formattedRows = formattedRows.map(rowTmp => {
    const locale = locales.find(
      ({ localeCode }) => localeCode === rowTmp[COLUMN_CAMPAIGNS_LOCALE_CODE],
    );
    const localeCode = rowTmp[COLUMN_CAMPAIGNS_LOCALE_CODE];
    rowTmp[COLUMN_POS] = {
      localeCode: localeCode,
      name: locale !== undefined ? locale.name : localeCode,
    };
    rowTmp[COLUMN_CAMPAIGNS_LOCALE_CODE] = {
      localeCode: localeCode,
      name: locale !== undefined ? locale.name : localeCode,
    };
    return rowTmp;
  });

  const filterBySelectedTypes =
    <T extends Row>() =>
    (row: T) => {
      return selectedOptions.map(option => option.value).includes(row[COLUMN_CAMPAIGNS_TYPE]);
    };

  const filterByEmptyInventory =
    <T extends Row>() =>
    (row: T) => {
      return isActiveOnly ? +row[COLUMN_CAMPAIGNS_PROPERTIES] > 0 : true;
    };

  const filteredRows = formattedRows
    .filter(filterByEmptyInventory())
    .filter(filterBySelectedTypes())
    .filter(row => filterListWithPosBySearchText(search)(row as any));
  const shouldShowCheckbox = (row: Row) => row[COLUMN_CAMPAIGNS_TYPE] !== CPC_CAMPAIGN_CODE;

  const onActiveOnlyToggle = () => {
    TrackerService.track(CAMPAIGNS_VIEW_ACTIVE_ONLY_TOGGLE(!isActiveOnly));
    setIsActiveOnly(!isActiveOnly);
  };

  const createExportOption = (optionText: string, fileFormat: FileFormat): ExportOption => {
    const dateCreated = format(new Date(), DATE_FORMAT_ISO_8601);
    const partnerId = getPartner(partner, subPartners).partnerId;
    const fileNameWithoutExtension = `${dateCreated}_${partnerId}_Campaigns`;
    return {
      text: optionText,
      fileFormat: fileFormat,
      payload: {
        filters: [
          {
            columnName: COLUMN_CAMPAIGNS_TYPE,
            columnLabel: 'Campaign Type',
            type: 'IN',
            value: selectedOptions.map(o => o.value).join(),
          },
        ],
        locales: [POS_GLOBAL_VALUE],
      },
      fileName: fileNameWithoutExtension,
    };
  };

  const bidFileUploaderOptions = useBidFileUploaderOptions();

  const columnOnboardingTooltips: ColumnOnboardingTooltip[] = [
    {
      text: translate('campaigns_invisible_properties_onboarding_tooltip'),
      column: formattedColumns.find(column => column.key === 'invisible_properties')!,
      position: PopoverDirection.BOTTOM_TRAILING,
    },
  ];

  return (
    <>
      <div className="u-padding-top--gutter u-padding-left--gutter u-padding-right--gutter">
        <h2 className="u-padding-left--gutter u-padding-right--gutter u-padding-bottom--gutter">
          {translate('bidding_campaigns__title')}
        </h2>
        <Toolbar
          filters={[
            <InputIcon
              key="key-campaign-input-icon"
              icon={search.length === 0 ? SearchLineDarkIcon : CloseIcon}
              className={cn('c-input', styles.searchField)}
              placeholder={translate('campaign_dashboard__search_placeholder')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onClickIcon={() => {
                if (search.length !== 0) {
                  setSearch('');
                }
              }}
              value={search}
            />,
            <IsVisibleToggle
              key="key-campaign-only-actives-filter"
              defaults={{
                isVisible: false,
                tooltip: { text: translate('campaign_only_actives_filter_tooltip') },
              }}
              onToggle={onActiveOnlyToggle}
            />,
            <CampaignsDropdownSelector
              options={dropdownOptions}
              clickApply={clickApply}
              selectedOptions={selectedOptions}
            />,
          ]}
          actions={[
            <ViewExporter
              key="key-view-exporter"
              title={translate('analytics_home_export_report')}
              exportOptions={[
                createExportOption(translate('campaigns_toolbar_export'), FileFormat.CSV),
                createExportOption(translate('campaigns_toolbar_export_excel'), FileFormat.EXCEL),
              ]}
              view={View.CAMPAIGNS}
              columns={columns}
              aggregation={Aggregation.CAMPAIGNS}
              filters={[]}
            />,
            <RevertBidChanges key="revert-bid-changes" />,
            hasUserBiddingRights(user) && renderFileUploaderDropdown(bidFileUploaderOptions),
          ]}
        />
      </div>

      {selectedRowsById.size > 0 && (
        <ControlPanel
          campaigns={row2Campaign()}
          onClose={() => {
            setSelectedRowsById(new Map());
            setSelectedPages(new Set());
          }}
          onApply={(newCpaValue, result) => {
            TrackerService.track(CAMPAIGNS_BIDDING_CPA_VALUE_APPLIED, { cpaValue: newCpaValue });
            resetData();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setNotification(buildNotification(result));
            setTimeout(() => setNotification(undefined), TIMEOUT_NOTIFICATION);
          }}
        />
      )}
      {notification && (
        <Notification notification={notification} onClose={() => setNotification(undefined)} />
      )}
      <div className={styles.gridWrapper}>
        <Grid
          columns={formattedColumns}
          numColumnsLeftAlignment={3}
          rows={filteredRows}
          initialData={{
            initialSelectedRowsId: new Set(selectedRowsById.keys()),
            initialSelectedPages: selectedPages,
          }}
          selectableRow={{
            onSelectPage: selectedPagesParam => setSelectedPages(selectedPagesParam),
            onSelectRow: selectRow,
            conditionFn: shouldShowCheckbox,
          }}
          pagination={{
            page: pagination,
            isHidden: true,
          }}
          isLoading={isLoading}
          configuration={GRID_VIEW_CONFIGURATION}
          resultsNotFound={<ResultsNotFound retry={retry} />}
          rowStyles={[
            {
              style: StyleRow.LARGE,
            },
          ]}
          columnOnboardingTooltips={columnOnboardingTooltips}
        />
      </div>
    </>
  );
};

function buildNotification(biddingResult: BiddingResponse): BaseNotification {
  const { totalBids, updatedBids } = biddingResult;
  const notification: BaseNotification = {
    level: NotificationLevel.ERROR,
    message: 'analytics_perform_bids_error',
  };
  if (updatedBids === 0) {
    notification.level = NotificationLevel.ERROR;
    notification.message = `${translate('common_fail')} ${translate(
      'analytics_perform_bids_error',
    )}`;
  } else if (updatedBids === totalBids) {
    notification.level = NotificationLevel.SUCCESS;
    const keyCampaign = totalBids === 1 ? 'common_campaign' : 'common_campaigns';
    notification.message = `${translate('common_success')} ${translate(
      'analytics_perform_bids_success',
    )} ${totalBids} ${translate(keyCampaign)}. ${translate(
      'analytics_perform_bids_success_extra_info',
    )}`;
  } else {
    notification.level = NotificationLevel.WARNING;
    notification.message = `${translate('notification_partial_success')} ${translate(
      'analytics_perform_bids_partial_success',
    )} ${updatedBids} ${translate('common_of_the')} ${totalBids} ${translate(
      'common_selected_campaigns',
    )} ${translate('analytics_check_status')}`;
  }
  return notification;
}

const renderFileUploaderDropdown = (bidFileUploaderOptions: FileUploaderOption[]) =>
  renderOnboardingTooltip(
    <ButtonDropdownFileUploader key="upload-bids-key" options={bidFileUploaderOptions} />,
  );

const renderOnboardingTooltip = (children: ReactNode) => (
  <div style={{ position: 'relative' }}>
    {children}
    <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
      <OnboardingTooltip
        keyStorage={INVENTORY_IMPORT_ONBOARDING_KEY}
        direction={PopoverDirection.BOTTOM_TRAILING}
      >
        {translateHtml('campaigns_inventory_upload_onboarding_tooltip')}
      </OnboardingTooltip>
    </div>
  </div>
);

const CampaignsDropdownSelector = ({
  options,
  clickApply,
  selectedOptions,
}: {
  options: CheckboxSelectOption<string>[];
  clickApply: (options: CheckboxSelectOption<string>[]) => void;
  selectedOptions: CheckboxSelectOption<string>[];
}) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const biddingTitle =
    selectedOptions.length === 1
      ? selectedOptions[0].value
      : `${selectedOptions.length} ${translate('campaign_groups_dropdown_multiple_selected')}`;

  const handleApply = (checkedOptions: CheckboxSelectOption<string>[]) => {
    const filteredOptions = checkedOptions.filter(({ checked }) => checked);
    clickApply(filteredOptions);
    setAnchor(null);
    TrackerService.trackWithPrefix(MULTI_SELECT_CAMPAIGN_PREFIX, MULTI_SELECT_DROPDOWN_APPLY, {
      selectedOptions: filteredOptions.map(option => option.value),
    });
  };

  return (
    <>
      <Button
        variant="tertiary"
        text={biddingTitle}
        onClick={event => {
          setAnchor(event.currentTarget as HTMLElement);
          TrackerService.trackWithPrefix(MULTI_SELECT_CAMPAIGN_PREFIX, MULTI_SELECT_DROPDOWN_OPEN);
        }}
        icon={{ name: 'arrowDown', rotation: anchor ? 180 : 0 }}
      />
      <TooltipWrapper
        position={TooltipPosition.TOP_CENTER}
        noTinyPaddingTop={true}
        message={translate('analytics_bidding_type_dropdown_information_tooltip')}
      >
        <ExclamationCircleFillDarkIcon
          className={styles.biddingTypeTooltip}
          width={14}
          height={14}
        />
      </TooltipWrapper>
      {anchor && (
        <Anchor anchor={anchor} onClose={() => setAnchor(null)}>
          <CheckboxSelect
            options={options.map(option => ({
              ...option,
              checked: selectedOptions.some(({ value }) => value === option.value),
            }))}
            onApply={handleApply}
          />
        </Anchor>
      )}
    </>
  );
};
