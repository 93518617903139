import { ErrorRowValidationRule } from '../row-validation-rule';

type Options = {
  fieldName: string;
  min?: number;
  max?: number;
};

export class ValidFloatNumberRule extends ErrorRowValidationRule {
  constructor(private readonly options: Options) {
    super();
  }

  getError(row: Record<string, string>): string | null {
    const { fieldName, min, max } = this.options;
    const value = row[fieldName];
    if (isNaN(Number(value))) return `Field ${fieldName} must be a number`;
    const parsed = parseFloat(value);
    if (min !== undefined && parsed < min) return `Field ${fieldName} must be at least ${min}`;
    if (max !== undefined && parsed > max) return `Field ${fieldName} must be at most ${max}`;
    return null;
  }
}
