import * as React from 'react';
import {
  TranslationKey,
  getDateTooltip,
  labelDateFormatter,
  translate,
} from '../../../helpers/utils';
import { parse } from 'date-fns';
import { Props } from './time-line-chart.types';
import { Chart } from '../../../components/chart';
import isEqual from 'lodash.isequal';

const _TimeLineChart: React.FunctionComponent<Props> = ({
  widgetProps,
  data,
  columns,
  isFetchingData,
  isError,
  isFullScreen,
  leftYAxisLabel = '',
  rightYAxisLabel,
  widgetType,
  chartHeight,
  dateRangePeriod,
}) => {
  if (isFetchingData || isError) return null;
  let widgetPropsExtended = widgetProps;
  if (widgetProps.dimension?.name === 'timePeriod') {
    widgetPropsExtended = {
      ...widgetProps,
      dimension: {
        ...widgetProps.dimension,
        values: [
          {
            label: translate(dateRangePeriod.period as TranslationKey),
            value: translate(dateRangePeriod.period as TranslationKey),
          },
          ...(widgetProps.dimension?.values || []).map(({ label, value }) => ({
            label: translate(label as unknown as TranslationKey),
            value: translate(value as unknown as TranslationKey),
          })),
        ],
      },
    };
  }
  return (
    <Chart
      data={data}
      columns={columns}
      chartProps={widgetPropsExtended}
      isFullScreen={isFullScreen}
      variant={widgetProps.metrics.length > 1 ? 'doubleAxisLine' : 'singleAxisLine'}
      tickFormatterX={value => labelDateFormatter(parse(String(value)))}
      toolTipTitle={value =>
        getDateTooltip(data, value, widgetProps.aggregationPeriod, dateRangePeriod)
      }
      leftYAxisLabel={leftYAxisLabel}
      rightYAxisLabel={rightYAxisLabel}
      widgetType={widgetType}
      chartHeight={chartHeight}
    />
  );
};
export const TimeLineChart = React.memo(_TimeLineChart, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
