import { Props } from './radio-button.types';
import cn from 'classnames';
import React from 'react';

export const RadioButton: React.FunctionComponent<Props> = ({
  isChecked,
  title,
  isDisabled,
  onChange,
  classNames,
  children,
}) => {
  let className = 'u-display--flex u-font-weight-normal u-align-items--center';
  if (classNames) className += ` ${classNames}`;
  return (
    <label className={className}>
      <input type="radio" checked={isChecked} onChange={onChange} disabled={isDisabled} />
      <p
        className={cn('u-margin-left--medium u-width--100', {
          'u-color--juri-lighter': isDisabled,
        })}
      >
        {title}
        {children}
      </p>
    </label>
  );
};
