import { UseSimpleViewTableRtn } from './use-simple-view-table.types';
import { Aggregation, ColumnDef, FilterColumn, Row, Sort, TableData, View } from '../../models';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../global/context/app-context';
import { usePagination } from '../use-pagination';
import { useFilterTypes } from '../use-filter-types';
import { getPartnersId } from '../../services/app-service';
import ApiViewHelper from '../../helpers/api/views/api-view-helper';
import { RequestHelper } from '../../helpers/api/request-helper';
import { useRowsSelection } from '../use-rows-selection';
import { ViewPayload } from '../../helpers/api/views/types';
import { showNotification } from '../../services/notification-service';
import { NotificationLevel } from '../../constants';
import { translate } from '../../helpers/utils';

export const useSimpleViewTable = (
  view: View,
  payload: {
    aggregation?: Aggregation;
    defaultFilters?: FilterColumn[][];
    localeCodes?: string[];
  },
  options: { columnId?: string; isUsingPagination?: boolean; avoidFetchingCount?: boolean } = {
    avoidFetchingCount: false,
  },
): UseSimpleViewTableRtn => {
  const { partner, subPartners } = useContext(AppContext);

  const pagination = usePagination();
  const [columns, setColumns] = useState<ColumnDef[]>([]);
  const [data, setTableData] = useState<TableData>({ rows: [], count: 0 });
  const [isLoading, setLoading] = useState(false);

  const [filters, setFilters] = useState<FilterColumn[][]>(payload.defaultFilters ?? [[]]);
  const [selectedFilter, setSelectedFilter] = useState<FilterColumn | undefined>();
  const [filterTypes] = useFilterTypes();
  const [sort, setSort] = useState<Sort | undefined>();

  const { selectedPages, selectedRowsById, setSelectedRowsById } = useRowsSelection();
  const [isAllSelected, setISAllSelected] = useState(false);
  const [shouldFetchCount, setShouldFetchCount] = useState(false);

  const columnId =
    options !== undefined && options.columnId !== undefined ? options.columnId : 'id';

  useEffect(() => {
    initViewTable();
    return () => {
      ApiViewHelper.cancelAllRequests();
    };
  }, []);

  useEffect(() => {
    resetData();
  }, [filters, sort, partner, subPartners]);

  // NOTE: pagination is used as the trigger of refreshing data,
  // which is reset in the previous hook (resetData function)
  useEffect(() => {
    if (columns.length === 0) return;
    updateRows();
  }, [columns.length, pagination.pagination]);

  const initViewTable = async () => {
    const effectivePartnersId: number[] = getPartnersId(partner, subPartners);
    try {
      const payloadTmp: ViewPayload = {
        pagination: options?.isUsingPagination ? pagination.pagination : undefined,
        partnersId: effectivePartnersId,
        sort,
        filters: filters.flat(),
        localeCodes: payload.localeCodes,
      };
      setColumns(await ApiViewHelper.fetchColumns(view, effectivePartnersId));
      setTableData(await fetchTableData(view, payload.aggregation, payloadTmp, true));
      setShouldFetchCount(false);
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  const updateRows = async () => {
    ApiViewHelper.cancelAllRequests();
    setLoading(true);
    setTableData(prevState => ({ rows: [], count: prevState.count }));
    try {
      const payloadTmp: ViewPayload = {
        pagination: options?.isUsingPagination ? pagination.pagination : undefined,
        partnersId: getPartnersId(partner, subPartners),
        sort,
        filters: filters.flat(),
        localeCodes: payload.localeCodes,
      };
      setTableData(await fetchTableData(view, payload.aggregation, payloadTmp, shouldFetchCount));
      setLoading(false);
      setShouldFetchCount(false);
    } catch (e) {
      const error = e as Error;
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
      if (error.message !== RequestHelper.cancelMessage) {
        setLoading(false);
      }
    }
  };

  const fetchTableData = async (
    viewParam: View,
    aggregationParam: Aggregation | undefined,
    payloadTmp: ViewPayload,
    isFullFetch: boolean,
  ) => {
    const rowsPromise = ApiViewHelper.fetchRows(viewParam, aggregationParam, payloadTmp);
    const countPromise =
      isFullFetch && !options.avoidFetchingCount
        ? ApiViewHelper.fetchCount(viewParam, aggregationParam!, payloadTmp)
        : new Promise<number>(resolve => resolve(data.count));
    const promises: [Promise<Row[]>, Promise<number>] = [rowsPromise, countPromise];
    const responses = await Promise.all(promises);

    return {
      rows: responses[0],
      count: responses[1],
    };
  };

  const selectRow = (selectedRows: Set<number>) => {
    const next = new Map<number, Row>();
    const findRow = (id: number) => data.rows.find(rowTmp => rowTmp[columnId!] === id)!;
    Array.from(selectedRows).forEach(rowIdTmp => {
      next.set(rowIdTmp, findRow(rowIdTmp));
    });
    setSelectedRowsById(next);
  };

  const resetData = () => {
    setShouldFetchCount(true);
    selectedPages.current = new Set();
    setSelectedRowsById(new Map());
    // NOTE: This will be the trigger for fetching rows, please don't remove it from here.
    pagination.resetPagination();
  };

  const getFilters = (index: number | undefined = undefined) =>
    index === undefined ? filters.flat() : index < filters.length ? filters[index] : [];

  const setFiltersByIndex = (index: number, filtersTmp: FilterColumn[]) => {
    const nextFilters = filters.slice();
    nextFilters[index] = filtersTmp;
    setFilters(nextFilters);
  };

  return {
    table: { data, columns },
    updateRows,
    resetData,
    isLoading,
    retry: initViewTable,
    sort: {
      sort,
      setSort: (sortParam: Sort) => setSort(sortParam),
    },
    pagination: {
      ...pagination,
      setNextPage: () => {
        if (data.count > pagination.pagination.numItemsPerPage) {
          pagination.setNextPage();
        }
      },
    },
    selection: {
      selectedPages: selectedPages.current,
      selectRow,
      setSelectedPages: (pages: Set<number>) => (selectedPages.current = pages),
      selectedRowsById,
      setSelectedRowsById: (newSelection: Map<number, Row>) => setSelectedRowsById(newSelection),
      isAllSelected,
      setIsAllSelected: (isSelected: boolean) => setISAllSelected(isSelected),
    },
    filters: {
      getFilters,
      setFilters: setFiltersByIndex,
      filterTypes,
      selectedFilter,
      setSelectedFilter: (filter: FilterColumn) => setSelectedFilter(filter),
    },
  };
};
