import React, { CSSProperties } from 'react';

export const MoveIcon: React.FunctionComponent<{
  fill?: string;
  style?: CSSProperties;
  className?: string;
}> = ({ fill, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="none"
    style={style}
    className={className}
  >
    <path d="M7.5 5.25L11.25 9" stroke={fill || 'var(--grey-800)'} strokeLinecap="round" />
    <path d="M7.5 12.75L11.25 9" stroke={fill || 'var(--grey-800)'} strokeLinecap="round" />
    <path d="M2.25 9L11.25 9" stroke={fill || 'var(--grey-800)'} strokeLinecap="round" />
    <path d="M14.25 4.5L14.25 13.5" stroke={fill || 'var(--grey-800)'} strokeLinecap="round" />
  </svg>
);
