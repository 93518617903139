import { useSelectedLocales } from '../use-selected-locales/use-selected-locales';
import { View } from '../../models';
import { getHotelView } from '../../services/analytics-service';

export const useHotelViews = (): { hotelView: View; hotelViewWithoutImpressions: View } => {
  const selectedLocales = useSelectedLocales();
  return {
    hotelView: getHotelView(selectedLocales),
    hotelViewWithoutImpressions: getHotelView(selectedLocales, false),
  };
};
