import { MouseEventHandler, useState } from 'react';

export function useMenuAnchor() {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const showMenu: MouseEventHandler = e => setAnchor(e.currentTarget as HTMLElement);
  const hideMenu = () => setAnchor(null);

  return { anchor, showMenu, hideMenu };
}
