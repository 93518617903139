import * as React from 'react';
import { Props } from './delete-last-dashboard-modal.types';
import './delete-last-dashboard-modal.css';
import { ConfirmationModal } from '../../../../components/confirmation-modal';
import { translate, translateHtml } from '../../../../helpers/utils';

export const DeleteLastDashboardModal: React.FunctionComponent<Props> = ({ onClose }) => {
  return (
    <ConfirmationModal
      title={translate('dashboard_header_modal_last_dashboard_title')}
      details={[translateHtml('dashboard_header_modal_last_dashboard_body')]}
      onClose={onClose}
      primaryButton={{
        title: translate('common_close'),
        actionFn: onClose,
      }}
    />
  );
};
