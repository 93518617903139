import { FileUploaderOption } from '../../../components/button-dropdown-file-uploader';
import { useSelectedPartner } from '../../../hooks/use-selected-partner';
import { EventType } from '../../../models';
import { useRemainingPushes } from '../../history/history-toolbar/use-remaining-pushes';
import {
  SlBaseBidUploaderOption,
  SlBidModifierUploaderOption,
  SlBudgetCapUploaderOption,
} from './uploader-options';

export function useSlBidFileUploaderOptions(): FileUploaderOption[] {
  const partner = useSelectedPartner();
  const { data: remainingPushes = {} } = useRemainingPushes(partner.partnerId);

  return [
    new SlBaseBidUploaderOption(remainingPushes[EventType.PUSH_SL_BID]),
    new SlBidModifierUploaderOption(remainingPushes[EventType.PUSH_SL_BM]),
    new SlBudgetCapUploaderOption(remainingPushes[EventType.PUSH_SL_BUDGET_CAP]),
  ];
}
