import { fromTo } from '../../models';

export type TooltipType = 'column' | 'onboarding' | 'notificationDrawer' | 'userDrawer';

type VerticalDirection = 'top' | 'bottom';

type HorizontalDirection = 'center' | 'trailing' | 'leading';

export enum PopoverDirection {
  BOTTOM_CENTER,
  BOTTOM_TRAILING,
  BOTTOM_LEADING,
  TRAILING_TOP,
}

export type PopoverDirectionProps = {
  vertical: VerticalDirection;
  horizontal: HorizontalDirection;
};

export const POPOVER_DIRECTION: fromTo<PopoverDirection, PopoverDirectionProps> = {
  [PopoverDirection.BOTTOM_CENTER]: { vertical: 'bottom', horizontal: 'center' },
  [PopoverDirection.BOTTOM_TRAILING]: { vertical: 'bottom', horizontal: 'trailing' },
  [PopoverDirection.BOTTOM_LEADING]: { vertical: 'bottom', horizontal: 'leading' },
  [PopoverDirection.TRAILING_TOP]: { vertical: 'top', horizontal: 'trailing' },
};
