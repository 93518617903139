import * as React from 'react';
import { Props } from './dashboard-dropdown.types';
import cs from 'classnames';
import './dashboard-dropdown.css';
import ArrowIcon from 'tcp-react-icons/lib/ArrowIcon';
import { Menu, MenuDirection } from '../../../../components/menu';
import { InputIcon } from '../../../../components/input-icon/input-icon';
import SearchLineDarkIcon from 'tcp-react-icons/lib/SearchLineDarkIcon';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import { useOnClickOutside } from '../../../../hooks/use-on-click-outside';
import { createRef } from 'react';
import { translate } from '../../../../helpers/utils';
import iconTick from './icn-tick.svg';
import iconCreate from './icn-create.svg';
import { DASHBOARD_MINIMUM_NAME_LENGTH } from '../../../../constants/dashboard';
import { OnboardingTooltip } from '../../../../components/onboarding-tooltip';
import { DASHBOARD_V2_DROPDOWN } from '../../../../constants/constants';

export const DashboardDropdown: React.FunctionComponent<Props> = ({
  selectedDashboardId,
  dashboards,
  onSelectDashboard,
  onCreateDashboard,
}) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const ref = createRef<HTMLDivElement>();
  useOnClickOutside(ref, () => setIsOpened(false));
  const [filteredDashboards, setFilteredDashboards] = React.useState(dashboards);
  const [searchText, setSearchText] = React.useState('');

  React.useEffect(() => {
    setFilteredDashboards(dashboards);
  }, [dashboards]);

  const selectItem = (position: number) => {
    onSelectDashboard(filteredDashboards[position]);
    setIsOpened(false);
  };

  const onChangeSearch = (text: string) => {
    setSearchText(text);
    if (text.length < DASHBOARD_MINIMUM_NAME_LENGTH) {
      setFilteredDashboards(dashboards);
    } else {
      const textLocalLowercase = text.toLocaleLowerCase();
      setFilteredDashboards(
        dashboards.filter(dashboardTmp =>
          dashboardTmp.name.toLocaleLowerCase().includes(textLocalLowercase),
        ),
      );
    }
  };

  return (
    <div ref={ref}>
      <div onClick={() => setIsOpened(!isOpened)}>
        <ArrowIcon
          className={cs(
            'u-transition--quick u-cursor--pointer u-position--relative u-margin-left--tiny c-dashboard-dropdown',
            {
              'u-transform--rotate-90deg': !isOpened,
              'u-transform--rotate-minus-90deg': isOpened,
            },
          )}
        />
      </div>
      <div className="c-dashboard-dropdown__on-boarding u-position--relative">
        <OnboardingTooltip keyStorage={DASHBOARD_V2_DROPDOWN}>
          <p>{translate('dashboard_dropdown_on_boarding')}</p>
        </OnboardingTooltip>
      </div>
      <div className="c-dashboard-dropdown__popover u-position--relative u-margin-left--tiny">
        {isOpened && (
          <Menu
            header={{
              html: (
                <InputIcon
                  icon={searchText.length === 0 ? SearchLineDarkIcon : CloseIcon}
                  value={searchText}
                  autoFocus={true}
                  placeholder={translate('dashboard_dropdown_search_dashboard')}
                  className="c-input c-dashboard-dropdown__input u-margin-bottom--medium"
                  onChange={event => onChangeSearch(event.target.value)}
                  onClickIcon={() => onChangeSearch('')}
                />
              ),
              extraClasses: 'c-dashboard-dropdown__menu-header',
            }}
            direction={MenuDirection.BottomLeading}
            body={{
              items: filteredDashboards.map(tmp => {
                return (
                  <div key={tmp.id} className="u-display--flex u-width--100">
                    {tmp.name}
                    {tmp.id === selectedDashboardId && (
                      <div className="u-margin-left--auto u-color--juri-light">
                        <img
                          src={iconTick}
                          className="u-margin-right--tiny"
                          alt="Selected Dashboard"
                        />
                        {translate('dashboard_dropdown_selected_dashboard')}
                      </div>
                    )}
                  </div>
                );
              }),
              extraClasses: 'c-dashboard-dropdown__menu',
            }}
            defaultEmpty={
              <div className="u-text-align--center u-color--juri-lighter">
                {translate('dashboard_dropdown_no_results')}
              </div>
            }
            onSelectItem={selectItem}
            footer={{
              html: (
                <span className="u-color--blue" onClick={onCreateDashboard}>
                  <img
                    src={iconCreate}
                    className="u-margin-right--tiny"
                    alt="Create new dashboard"
                  />
                  {translate('dashboard_dropdown_create_dashboard')}
                </span>
              ),
              extraClasses: 'c-dashboard-dropdown__menu-footer',
            }}
          />
        )}
      </div>
    </div>
  );
};
