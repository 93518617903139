import {
  UseVerifiedFilterSetNameReturn,
  GetVerifiedName,
  GetVerifiedNextName,
  FindUniqueNameFromSet,
} from './use-verified-filter-set-name.types';

export function findUniqueNameFromSet({
  storedFilterSetNames,
  verifiedNextName,
  fromName,
  isEdit,
  duplicateSuffix,
}: FindUniqueNameFromSet): string {
  let uniqueName = verifiedNextName;
  let nextPossibleName = verifiedNextName;
  const condition = false;
  while (!condition) {
    if (isEdit && nextPossibleName === fromName) break;
    const isDuplicateName = storedFilterSetNames.find(
      storedSetName => storedSetName === nextPossibleName,
    );
    if (isDuplicateName) {
      nextPossibleName = `${nextPossibleName} ${duplicateSuffix}`;
      uniqueName = nextPossibleName;
      continue;
    }
    break;
  }

  return uniqueName;
}

export function getVerifiedNextName({
  filterSetName,
  nextName,
  copiedFromFilterName,
  isEdit,
  isNoChange,
  isDefaultName,
  duplicateSuffix,
}: GetVerifiedNextName): string {
  let verifiedNextName = nextName;
  if (isEdit && isNoChange) verifiedNextName = filterSetName;
  if ((!(isEdit && isNoChange) && !nextName) || isDefaultName) {
    verifiedNextName = `${copiedFromFilterName} ${duplicateSuffix}`;
  }
  return verifiedNextName;
}

export function getVerifiedName({
  filterSetName,
  nextName,
  fromName,
  defaultName,
  storedFilterSets,
  duplicateSuffix,
}: GetVerifiedName): string {
  const isEdit = filterSetName === fromName;
  const isNoChange = filterSetName === nextName || !nextName;
  const isDefaultName = nextName === defaultName;

  const copiedFromFilterName = fromName || defaultName;
  const storedFilterSetNames = storedFilterSets.map(filterSet => filterSet.name);
  const verifiedNextName = getVerifiedNextName({
    filterSetName,
    nextName,
    copiedFromFilterName,
    isEdit,
    isNoChange,
    isDefaultName,
    duplicateSuffix,
  });

  if ((isEdit && isNoChange) || isDefaultName) return verifiedNextName;
  return findUniqueNameFromSet({
    storedFilterSetNames,
    verifiedNextName,
    fromName,
    isEdit,
    duplicateSuffix,
  });
}

export function useVerifiedFilterSetName(): UseVerifiedFilterSetNameReturn {
  return { getVerifiedName };
}
