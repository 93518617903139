import React, { FunctionComponent } from 'react';
import { MenuItemsProps } from './multi-folder-select.types';
import { MetricsSelector } from '../../../components/metrics-selector/metrics-selector';
import { MetricColumn } from '../../../models';

export const MetricMenuItems: FunctionComponent<MenuItemsProps> = ({
  applyFilters,
  selectedMetrics,
  selectedDimension,
  columns,
}) => {
  return (
    <MetricsSelector
      onApply={(metrics: MetricColumn[]) => applyFilters(metrics)}
      metricColumns={columns as MetricColumn[]}
      previouslySelectedMetrics={selectedMetrics}
      maxMetricsAmount={selectedDimension ? 1 : 2}
    />
  );
};
