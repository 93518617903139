import { ComponentProps, FC } from 'react';
import cn from 'classnames';
import styles from './icon.module.css';
import { ICONS } from './icons';

export type IconProps = {
  name: keyof typeof ICONS;
  rotation?: 0 | 90 | 180 | 270;
} & ComponentProps<'svg'>;

export const Icon: FC<IconProps> = ({ name, rotation, className, ...props }) => {
  const Svg = ICONS[name];
  return (
    <Svg
      {...props}
      className={cn(styles.icon, styles[`rotation-${rotation}`], className)}
      title=""
    />
  );
};
