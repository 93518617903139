import { fromTo } from '../../../../models';
import { RevertType } from '../../../../constants/analytics';
import { RevertOption } from '../../../../components/revert';

export type Props = {};

export const REVERT_BID_OPTIONS: fromTo<RevertType, RevertOption<RevertType>> = {
  [RevertType.BIDS]: {
    type: RevertType.BIDS,
    option: {
      title: 'revert_bid_changes_bids_title',
      subtitle: 'revert_bid_changes_bids_subtitle',
    },
    modal: {
      title: 'analytics_revert_modal_title_info_bids',
      subtitle: 'analytics_revert_modal_info_bids',
    },
  },
  [RevertType.MODIFIERS]: {
    type: RevertType.MODIFIERS,
    option: {
      title: 'revert_bid_changes_modifiers_title',
      subtitle: 'revert_bid_changes_modifiers_subtitle',
    },
    modal: {
      title: 'analytics_revert_modal_title_info_modifiers',
      subtitle: 'analytics_revert_modal_info_modifiers',
    },
  },
  [RevertType.PROPERTY_GROUPS]: {
    type: RevertType.PROPERTY_GROUPS,
    option: {
      title: 'revert_bid_changes_property_groups_title',
      subtitle: 'revert_bid_changes_property_groups_subtitle',
    },
    modal: {
      title: 'analytics_revert_modal_title_info_property_groups',
      subtitle: 'analytics_revert_modal_info_property_groups',
    },
  },
  [RevertType.CPA_VALUE]: {
    type: RevertType.CPA_VALUE,
    option: {
      title: 'revert_bid_changes_cpa_value_title',
      subtitle: 'revert_bid_changes_cpa_value_subtitle',
    },
    modal: {
      title: 'analytics_revert_modal_title_info_cpa_value',
      subtitle: 'analytics_revert_modal_info_cpa_value',
    },
  },
  [RevertType.CAMPAIGN_ALLOCATION]: {
    type: RevertType.CAMPAIGN_ALLOCATION,
    option: {
      title: 'revert_bid_changes_campaign_allocation_title',
      subtitle: 'revert_bid_changes_campaign_allocation_subtitle',
    },
    modal: {
      title: 'analytics_revert_modal_title_info_campaign_allocations',
      subtitle: 'analytics_revert_modal_info_campaign_allocations',
    },
  },
};
