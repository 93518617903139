import cn from 'classnames';
import { FC } from 'react';
import styles from './tooltip.module.css';

export type TooltipPosition =
  | 'topCenter'
  | 'topStart'
  | 'topEnd'
  | 'bottomCenter'
  | 'bottomStart'
  | 'bottomEnd'
  | 'leftCenter'
  | 'leftStart'
  | 'leftEnd'
  | 'rightCenter'
  | 'rightStart'
  | 'rightEnd';

export type TooltipProps = {
  title: string;
  position: TooltipPosition;
  className?: string;
  isSticky?: boolean;
  isHidden?: boolean;
  isDelayed?: boolean;
};

export const Tooltip: FC<TooltipProps> = ({
  title,
  position,
  className,
  children,
  isSticky,
  isHidden,
  isDelayed,
}) => {
  const [primaryPosition, secondaryPosition] = position
    .match(/([A-Z]?[^A-Z]*)/g)!
    .filter(Boolean)
    .map(s => s.toLowerCase());
  return (
    <div className={cn(styles.tooltipContainer, { [styles.stickyTooltip]: isSticky })}>
      {children}
      <div
        className={cn(
          styles.tooltipText,
          styles[primaryPosition],
          styles[secondaryPosition],
          {
            [styles.hideTooltip]: isHidden,
            [styles.delayedTooltip]: isDelayed,
          },

          className,
        )}
      >
        {title}
      </div>
    </div>
  );
};
