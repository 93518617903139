import { parse } from 'papaparse';
import { HeaderValidationRule } from './row-validation-rule';

export const validateCsvHeaders = (file: File, rules: HeaderValidationRule[]): Promise<string[]> =>
  new Promise(resolve => {
    const errors: string[] = [];
    parse<Record<string, string>, File>(file, {
      skipEmptyLines: true,
      header: true,
      chunk: (results, parser) => {
        const headers = results.meta.fields ?? [];
        for (const rule of rules) {
          const error = rule.validate(headers);
          if (error) errors.push(error);
        }
        parser.abort();
      },
      complete: () => resolve(errors),
    });
  });
