import { Tab } from '../../components/tabs';
import { TAB_CLICKED } from '../../constants';

export type BaseAttributes = {
  member_id?: number;
  partner_id?: number | string;
  sub_partner_id?: Array<number | string>;
  adv_type?: number;
  level?: number;
  session_id?: string;
};

declare const analytics: SegmentAnalytics.AnalyticsJS;

/* tslint:disable:variable-name */
class TrackerService {
  private _baseAttributes: BaseAttributes;
  private static instance: TrackerService;

  constructor() {
    this._baseAttributes = {};
  }

  public static getInstance() {
    if (!TrackerService.instance) {
      TrackerService.instance = new TrackerService();
    }

    return TrackerService.instance;
  }

  set baseAttributes(attrs: BaseAttributes) {
    Object.keys(attrs).forEach(key => {
      (this._baseAttributes as any)[key] =
        key === 'partner_id' ? 'pid_' + (attrs as any)[key] : (attrs as any)[key];
    });
  }

  public track(eventName: string, properties: object = {}) {
    analytics.track(eventName, { ...this._baseAttributes, ...properties });
  }

  public trackTabChange(tab: Tab, groupName: string) {
    this.track(TAB_CLICKED(groupName, tab.label), {
      tabTitle: tab.label,
      key: tab.key,
    });
  }

  public trackWithPrefix(eventPrefix: string, eventBase: string, properties: object = {}) {
    this.track(`${eventPrefix} ${eventBase}`, properties);
  }
}

export function getCurrentPath() {
  return window.location.pathname.slice(1);
}

export default TrackerService.getInstance();
