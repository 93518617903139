import { apiFileHelper } from '../../../../helpers/api/api-file-helper';
import { useSelectedPartner } from '../../../../hooks/use-selected-partner';
import { EventType, HistoryEvent } from '../../../../models';
import TrackerService from '../../../../services/tracker/tracker-service';

export function useDownloadHistoryEventFile() {
  const { partnerId } = useSelectedPartner();

  function downloadHistoryEventFile(event: HistoryEvent, fileType: string): Promise<void> {
    if (event.type === EventType.INVENTORY_IMPORT_TI) {
      return apiFileHelper.downloadInventoryRelatedFile(event.id, fileType);
    }
    if (fileType === 'source') {
      return apiFileHelper.downloadSourceFile(partnerId, event.id);
    }
    return apiFileHelper.downloadSummaryFile(partnerId, event.id);
  }

  return (event: HistoryEvent, fileType: string) =>
    downloadHistoryEventFile(event, fileType).then(() => trackFileDownloadSuccess(event, fileType));
}

const trackFileDownloadSuccess = (event: HistoryEvent, fileType: string) =>
  TrackerService.track('History file download', {
    eventId: event.id,
    fileType,
  });
