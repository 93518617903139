import cn from 'classnames';
import { FC } from 'react';
import { Icon } from '../icon';
import { Loading } from '../loading/loading';
import styles from './button.module.css';
import { ButtonProps } from './button.types';

export const Button: FC<ButtonProps> = props => {
  const {
    variant = 'primary',
    size = 'regular',
    text,
    icon,
    disabled,
    isLoading,
    className: classesFromProps,
    ...rest
  } = props;
  return (
    <button
      type="button"
      {...rest}
      disabled={isLoading || disabled}
      className={cn(styles.button, styles[variant], styles[size], classesFromProps)}
    >
      {text && <span className={styles.text}>{text}</span>}
      {icon ? <Icon className={styles.icon} {...icon} /> : null}
      {isLoading ? <Loading className={styles.loading} /> : null}
    </button>
  );
};
