import { ComponentProps, FC, MouseEventHandler, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './modal-dialog.module.css';
import { ButtonIcon } from '../button-icon/button-icon';
import { ButtonProps } from '../button/button.types';
import { Button } from '../button/button';

export type ModalDialogProps = {
  open: boolean;
  title?: string;
  hasCloseButton?: boolean;
  closeOnBackdropClick?: boolean;
  onClose?: VoidFunction;
  hasNoContentPadding?: boolean;
  buttons?: {
    start?: ButtonProps[];
    end?: ButtonProps[];
  };
} & ComponentProps<'dialog'>;

export const ModalDialog: FC<ModalDialogProps> = ({
  open,
  title,
  hasCloseButton,
  closeOnBackdropClick,
  onClose,
  buttons,
  className,
  hasNoContentPadding = false,
  ...props
}) => {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = ref.current;
    if (!dialog) return;
    const close = () => onClose?.();
    dialog.addEventListener('close', close);

    if (open) dialog.showModal();
    else dialog.close();

    return () => {
      if (dialog) dialog.removeEventListener('close', close);
    };
  }, [ref, open, onClose]);

  const closeDialogOnBackdropClick: MouseEventHandler = event => {
    const dialog = ref.current;
    if (event.target !== dialog) return;
    dialog.close();
  };

  return (
    <dialog
      ref={ref}
      {...props}
      onClick={closeOnBackdropClick ? closeDialogOnBackdropClick : undefined}
      className={cn(styles.modalDialog, className)}
    >
      <ModalDialogHeader title={title} hasCloseButton={hasCloseButton} onClose={onClose} />
      <section className={cn(styles.content, { [styles.noPadding]: hasNoContentPadding })}>
        {props.children}
      </section>
      <ModalDialogFooter buttons={buttons} />
    </dialog>
  );
};

const ModalDialogHeader: FC<
  Pick<ModalDialogProps, 'title' | 'hasCloseButton' | 'onClose'>
> = props => {
  const { title, hasCloseButton, onClose } = props;

  if (!title && !hasCloseButton) return null;

  return (
    <header className={styles.header}>
      <h2>{title}</h2>
      {hasCloseButton ? <ButtonIcon onClick={onClose} icon={{ name: 'close' }} /> : null}
    </header>
  );
};

const ModalDialogFooter: FC<Pick<ModalDialogProps, 'buttons'>> = ({ buttons }) => {
  const shouldDisplayFooter =
    (buttons?.start && buttons.start.length > 0) || (buttons?.end && buttons.end.length > 0);

  if (!shouldDisplayFooter) return null;

  return (
    <footer className={styles.footer}>
      {buttons?.start ? (
        <section>
          {buttons.start?.map((button, index) => <Button key={index} {...button} />)}
        </section>
      ) : null}
      {buttons?.end ? (
        <section className={styles.footerEnd}>
          {buttons.end?.map((button, index) => <Button key={index} {...button} />)}
        </section>
      ) : null}
    </footer>
  );
};
