import { OnboardingTooltip } from './onboarding-tooltip';
import styles from './onboarding-tooltip-container.module.css';
import React, { ReactNode } from 'react';
import { POPOVER_DIRECTION, PopoverDirection } from '../popover';
import cn from 'classnames';

type OnboardingTooltipContainerProps = {
  onboardingKey: string | undefined;
  onboardingText: ReactNode | undefined;
  location?: OnboardingTooltipLocation;
  direction?: PopoverDirection;
};

type OnboardingTooltipLocation = 'navigationTab' | 'gridHeader';

export const OnboardingTooltipContainer: React.FunctionComponent<
  OnboardingTooltipContainerProps
> = ({
  onboardingKey,
  onboardingText,
  location = 'navigationTab',
  direction = PopoverDirection.BOTTOM_LEADING,
}) => {
  if (!onboardingKey || !onboardingText) return null;

  const { horizontal, vertical } = POPOVER_DIRECTION[direction];
  return (
    <div className={cn(styles[location], styles[horizontal], styles[vertical])}>
      <OnboardingTooltip keyStorage={onboardingKey} direction={direction}>
        <p>{onboardingText}</p>
      </OnboardingTooltip>
    </div>
  );
};
