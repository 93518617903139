import { FC } from 'react';
import { Props, TooltipPosition } from './tooltip-wrapper.types';
import cn from 'classnames';
import './tooltip-wrapper.css';

export const TooltipWrapper: FC<Props> = ({
  children,
  noTinyPaddingTop,
  message,
  className = '',
  classNameParent = '',
  position = TooltipPosition.TOP_CENTER,
  hideTooltip = false,
  isAddingPadding = false,
}) => {
  const shouldShowTooltip = message !== undefined && message !== '' && !hideTooltip;
  return shouldShowTooltip ? (
    <div
      className={cn('has-tooltip u-display--inline-block', classNameParent, {
        'u-padding-top--tiny': !noTinyPaddingTop,
        'u-padding-bottom--gutter--small':
          isAddingPadding &&
          (position === TooltipPosition.BOTTOM_CENTER ||
            position === TooltipPosition.BOTTOM_LEADING),
        'u-padding-top--gutter--small':
          !noTinyPaddingTop &&
          isAddingPadding &&
          (position === TooltipPosition.TOP_CENTER ||
            position === TooltipPosition.TOP_LEADING ||
            position === TooltipPosition.TOP_TRAILING),
        'u-padding-left--gutter--small':
          isAddingPadding && position === TooltipPosition.LEADING_CENTER,
        'u-padding-right--gutter--small':
          isAddingPadding && position === TooltipPosition.TRAILING_TOP,
      })}
    >
      {children}
      <div
        className={cn('tooltip-wrapper u-white-space--pre-wrap', className, {
          'tooltip-wrapper--top-center': position === TooltipPosition.TOP_CENTER,
          'tooltip-wrapper--top-leading': position === TooltipPosition.TOP_LEADING,
          'tooltip-wrapper--top-trailing': position === TooltipPosition.TOP_TRAILING,
          'tooltip-wrapper--bottom-center c-tooltip-wrapper__bottom-leading c-tooltip-wrapper__bottom-center':
            position === TooltipPosition.BOTTOM_CENTER,
          'tooltip-wrapper--bottom-leading': position === TooltipPosition.BOTTOM_LEADING,
          'tooltip-wrapper--bottom-trailing': position === TooltipPosition.BOTTOM_TRAILING,
          'tooltip-wrapper--trailing-top': position === TooltipPosition.TRAILING_TOP, // TODO
          'tooltip-wrapper--leading-center': position === TooltipPosition.LEADING_CENTER,
          'tooltip-wrapper--leading-top': position === TooltipPosition.TRAILING_TOP,
          'tooltip-wrapper--trailing-center': position === TooltipPosition.TRAILING_CENTER,
        })}
      >
        <p className="tooltip u-color--white u-font-size--tiny c-tooltip-wrapper__message">
          {message ? message : ''}
        </p>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};
