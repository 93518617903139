import { FC } from 'react';
import { PERIOD_PICKER_OPTIONS, TimeOption } from '../../../constants';
import { TranslationKey, translate } from '../../../helpers/utils';
import { PeriodPickerProps } from './chart-builder.types';
import './chart-builder.css';
import cn from 'classnames';

export const ChartBuilderPeriodPicker: FC<PeriodPickerProps> = ({
  timeOption,
  setTimeOption,
  dimensionName,
}) => {
  const isTimePeriodDimensionSelected = dimensionName === 'timePeriod';
  return (
    <div
      className={cn('u-margin-right--large', {
        chartBuilderPeriodPickerDisabled: isTimePeriodDimensionSelected,
      })}
    >
      <select
        className={'u-height--36px'}
        onChange={event => setTimeOption(event.target.value as TimeOption)}
        value={timeOption}
        disabled={isTimePeriodDimensionSelected}
      >
        {PERIOD_PICKER_OPTIONS.map(option => (
          <option key={option.option} value={option.option}>
            {translate(option.translation as TranslationKey)}
          </option>
        ))}
      </select>
    </div>
  );
};
