import { useEffect, useState } from 'react';
import { FetchReportArtifactsListParams, ReportsApi } from '../../helpers/api/reports/reports-api';
import { ReportArtifact } from '../../helpers/api/reports/types';

interface UseReportArtifactsResult {
  artifacts: ReportArtifact[];
  isLoading: boolean;
  error?: string;
}

export function useReportArtifacts(
  params: FetchReportArtifactsListParams,
): UseReportArtifactsResult {
  const [artifacts, setArtifacts] = useState<ReportArtifact[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    async function fetchArtifacts() {
      try {
        setLoading(true);
        setError(undefined);
        setArtifacts(await ReportsApi.fetchReportArtifactsList(params));
      } catch (e) {
        console.error(e);
        setError((e as any).message);
      } finally {
        setLoading(false);
      }
    }
    fetchArtifacts();
  }, [
    params.partnerId,
    params.reportName,
    params.fromDate,
    params.toDate,
    params.isCombined,
    params.reportDefinitionId,
  ]);

  return { artifacts, isLoading, error };
}
