import * as React from 'react';
import { Props } from './mblu-widget.types';
import './mblu-widget.css';
import {
  CHART_DATA_AGGREGATION_TIME_OPTION,
  CHART_DATA_DATE_COLUMN_TIME_OPTION,
  Widget,
} from '../widget';
import { MbluTitleWidget } from './mblu-title-widget';
import { translate } from '../../../helpers/utils';
import { WidgetRemoveMenuItem } from '../widget/widget-remove-menu-item';
import { View, fromTo } from '../../../models';
import { getPartnersId } from '../../../services/app-service';
import { AppContext } from '../../../global/context/app-context';
import {
  COLUMN_BEAT,
  COLUMN_LOSE,
  COLUMN_MEET,
  COLUMN_UNAVAILABILITY,
  Color,
} from '../../../constants';
import { WidgetNoData } from '../widget/widget-no-data';
import { ButtonRetry } from '../../../components/button-retry';
import { useWidgetData } from '../../../hooks/use-widget-data';
import { MbluChart } from './mblu-chart';
import { useDateRangePeriod } from '../../../components/date-picker/date-picker.state';

export const MbluWidget: React.FunctionComponent<Props> = ({
  onRemoveWidget,
  timeOption,
  onFullScreen,
  isFullScreen,
}) => {
  const { selectedLocales, partner, subPartners } = React.useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();

  const shownColumns = [
    CHART_DATA_DATE_COLUMN_TIME_OPTION[timeOption],
    COLUMN_BEAT,
    COLUMN_MEET,
    COLUMN_LOSE,
    COLUMN_UNAVAILABILITY,
  ];

  const { columns, data, isFetchingColumns, isFetchingData, refetch, isError } = useWidgetData(
    View.BMLU,
    {
      aggregations: [CHART_DATA_AGGREGATION_TIME_OPTION[timeOption]],
      partnersId: getPartnersId(partner, subPartners),
      localeCodes: [...selectedLocales],
      from,
      to,
      timeOption,
      shownColumns,
    },
    [selectedLocales, subPartners, from, to, timeOption],
  );

  const COLUMNS_COLOUR: fromTo<string, Color> = {
    [COLUMN_BEAT]: Color.Green600,
    [COLUMN_MEET]: Color.Orange600,
    [COLUMN_LOSE]: Color.Red600,
    [COLUMN_UNAVAILABILITY]: Color.Grey300,
  };

  const shouldUseFullScreen = localStorage.getItem('useDashboardFullScreen') === 'true';

  return (
    <Widget
      menuItems={
        shouldUseFullScreen
          ? [
              <WidgetRemoveMenuItem key="1" />,
              <div className="u-display--flex u-align-items--center" key="2">
                {isFullScreen ? 'Close full screen' : 'Open in full screen'}
              </div>,
            ]
          : [<WidgetRemoveMenuItem key="1" />]
      }
      onSelectMenuItem={index => (index === 0 ? onRemoveWidget() : onFullScreen())}
      isLoading={isFetchingData || isFetchingColumns}
      title={columns.map((c, index) => (
        <MbluTitleWidget
          extraClasses={index !== 0 ? 'u-margin-left--large u-align-items--center' : ''}
          key={c.key}
          text={c.name}
          color={COLUMNS_COLOUR[c.key]}
        />
      ))}
    >
      {isError ? (
        <ButtonRetry message={translate('dashboard_widget_error')} onClick={refetch} />
      ) : columns.length === 0 || data.length === 0 ? (
        <WidgetNoData />
      ) : (
        <MbluChart
          {...{
            isFullScreen,
            columns,
            data,
            columnsColor: COLUMNS_COLOUR,
            aggregationPeriod: timeOption,
          }}
        />
      )}
    </Widget>
  );
};
