import React, { FC, ReactNode, useState } from 'react';
import {
  ListItemsProps,
  DimensionValueDropdownProps,
  SelectionRow,
} from './dimension-value-dropdown.types';
import './dimension-value-dropdown.css';
import { LoadOptions, SelectDropdown, SelectOption } from '../../core-ui/select-dropdown';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import { Button } from '../button';
import cn from 'classnames';
import { escapeRegExp } from 'lodash';
import SortedSet from 'collections/sorted-set';
import { comparing } from '../../helpers/utils';
import { Loading } from '../loading';
import { queryClient } from '../../global/app';

export const DimensionValueDropdown: FC<DimensionValueDropdownProps> = props => {
  const {
    title,
    selectionLimit,
    applyFilter,
    selectedValues,
    defaultOptions,
    loadOptions,
    cacheKey,
  } = props;
  const { byId: loadOptionsById, byName: loadOptionsByName } = loadOptions;
  const { data: initialOptions, isLoading } = loadOptionsById(selectedValues);
  const [selectedOptions, setSelectedOptions] = useState(createSortedSet(initialOptions ?? []));
  const setSortedSelectedOptions = (options: SelectionRow[]) =>
    setSelectedOptions(createSortedSet(options));

  const handleLoadOptions: LoadOptions<SelectionRow> = (inputValue: string) =>
    loadOptionsByName(inputValue).then(selectionRows =>
      createOptionItems(selectionRows, inputValue),
    );

  const handleChange = (option: SelectOption<SelectionRow>) => {
    setSortedSelectedOptions([...selectedOptions, option?.value]);
  };

  const handleRemoveOption = (optionToRemove: SelectionRow) =>
    setSortedSelectedOptions(selectedOptions.filter(option => option !== optionToRemove));

  const renderApplyButton = () => (
    <Button
      message="Apply"
      onClick={() => {
        applyFilter(selectedOptions);
        if (cacheKey) cacheData(selectedOptions, cacheKey);
      }}
      disabled={selectedOptions.length === 0}
    />
  );

  return (
    <div
      className="c-dimension-value-dropdown"
      data-testid="dimension-value-dropdown-dimension-selector"
    >
      <div className="u-padding-top--large u-padding-left--large u-padding-right--large">
        <h1>{title}</h1>
        <SelectDropdown
          isAsync
          options={defaultOptions ? createOptionItems(defaultOptions) : defaultOptions}
          loadOptions={handleLoadOptions}
          onChange={handleChange}
          placeholder="Search"
          disabled={selectedOptions.length === selectionLimit}
        />
        {selectedOptions.length > 0 && renderClearButton(() => setSortedSelectedOptions([]))}
      </div>
      {isLoading && <Loading />}
      {selectedOptions.length > 0 && (
        <ListItems options={selectedOptions} removeOption={handleRemoveOption} />
      )}
      <div
        className={cn(
          'c-dimension-value-dropdown-cta',
          selectedOptions.length > 0 ? 'u-margin-top--medium' : 'u-margin-top--large',
        )}
      >
        <p>
          {selectedOptions.length} selected (max {selectionLimit})
        </p>
        {renderApplyButton()}
      </div>
    </div>
  );
};

const ListItems: FC<ListItemsProps> = ({ options, removeOption }) => {
  return (
    <ul className="c-dimension-value-dropdown-list">
      {options.map(item => (
        <li key={item.value}>
          <div>
            <span className=" u-color--juri">{item.label}</span>
            <span className=" u-color--juri-light">{item.subLabel}</span>
          </div>

          <CloseIcon onClick={() => removeOption(item)} />
        </li>
      ))}
    </ul>
  );
};

const createSortedSet = (values: SelectionRow[]) => {
  return new SortedSet(
    values,
    (a, b) => a.value === b.value,
    comparing(({ value }) => value),
  ).slice();
};

const renderClearButton = (onClick: () => void) => (
  <button className="c-dimension-value-dropdown-clear-btn" onClick={onClick}>
    Clear Selection
  </button>
);

const createOptionItems = (
  selectionRows: SelectionRow[],
  inputValue: string = '',
): SelectOption<SelectionRow>[] =>
  selectionRows.map(item => ({
    value: item,
    label: (
      <>
        <div className="u-color--juri">{highlightMatchingText(item.label, inputValue)}</div>
        {renderOptionSubLabel(item.subLabel, inputValue)}
      </>
    ),
  }));

const renderOptionSubLabel = (subLabel: string | undefined, inputValue: string) => {
  if (!subLabel) return;
  return <div className="u-color--juri-light">{highlightMatchingText(subLabel, inputValue)}</div>;
};

const highlightMatchingText = (originalString: string, matchString: string): ReactNode => {
  const regex = RegExp(`(${escapeRegExp(matchString)})`, 'gi');
  const highlightedString = originalString.replace(regex, match => `<b>${match}</b>`);
  return <span dangerouslySetInnerHTML={{ __html: highlightedString }} />;
};

const cacheData = (selectedOptions: SelectionRow[], cacheKey: string) => {
  queryClient.setQueryData<SelectionRow[]>(
    [cacheKey, selectedOptions.map(option => option.value).join()],
    () => selectedOptions,
  );
};
