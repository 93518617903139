import React, { FC, useState } from 'react';
import { CheckboxSelect } from '../checkbox-select';
import { CheckboxSelectOption, DEFAULT_OPTIONS_LIMIT } from '../checkbox-select';
import { PropertyGroupFilterOption, Props } from './property-groups-dimension-selector.types';
import { usePropertyGroups } from '../../hooks/use-property-groups';

export const PropertyGroupsDimension: FC<Props> = ({
  applyPropertyGroupsFilter,
  previouslySelectedPropertyGroups,
}) => {
  const selectedPropertyGroups =
    previouslySelectedPropertyGroups && previouslySelectedPropertyGroups?.selectedOptions;
  const propertyGroups = usePropertyGroups();

  const propertyGroupsSelection: CheckboxSelectOption<number>[] = propertyGroups.map(pg => ({
    id: pg.id,
    label: pg.name,
    subLabel: pg.alias,
    checked: selectedPropertyGroups?.some(option => option.value === pg.id) ?? false,
    value: pg.id,
  }));
  const [selectedPropertyGroupOptions, setSelectedPropertyGroupOptions] =
    useState<CheckboxSelectOption<number>[]>(propertyGroupsSelection);

  const handleApply = (options: CheckboxSelectOption<number>[]) => {
    const selectedOptions = options?.filter(option => option.checked);
    if (!selectedOptions) return;

    setSelectedPropertyGroupOptions(selectedOptions);
    const mappedSelectedOptions: PropertyGroupFilterOption[] = selectedOptions.map(option => ({
      value: option.value,
      propertyGroupName: option.subLabel ? `${option.label} (${option.subLabel})` : option.label,
    }));
    applyPropertyGroupsFilter({
      name: 'propertyGroups',
      selectedOptions: mappedSelectedOptions,
    });
  };

  return (
    <div>
      <CheckboxSelect
        title="Property Groups"
        searchEnabled={true}
        searchPlaceholder="Search property groups"
        options={selectedPropertyGroupOptions}
        onApply={options => handleApply(options)}
        selectedOptionsLimit={
          selectedPropertyGroupOptions.length > DEFAULT_OPTIONS_LIMIT
            ? DEFAULT_OPTIONS_LIMIT
            : undefined
        }
      />
    </div>
  );
};
