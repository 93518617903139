import * as React from 'react';
import { Props } from './switch.types';
import './switch.css';
import cs from 'classnames';

export const Switch: React.FunctionComponent<Props> = ({
  isActive,
  isDisabled = false,
  onChange,
  variant = 'box',
  size,
}) => {
  return (
    <label className={cs('c-switch', { [`c-switch--${size}`]: size })}>
      <input
        type="checkbox"
        disabled={isDisabled}
        checked={isActive}
        onChange={() => onChange(!isActive)}
      />
      <span
        className={cs(
          'c-switch__slider',
          { round: variant === 'circle' },
          { [`c-switch__slider--${size}`]: size },
        )}
      ></span>
    </label>
  );
};
