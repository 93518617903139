import React from 'react';

export const RecurrenceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" fill="white" />
    <path
      d="M14 16L11 18.5L14 21M11 8L14 5.5L11 3"
      stroke="var(--grey-600)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5.5C14 5.22386 13.7761 5 13.5 5H9C5.68629 5 3 7.68629 3 11V13C3 15.7643 4.86937 18.092 7.41291 18.7879C7.71635 18.8709 8 18.6304 8 18.3158C8 18.0739 7.82859 17.8681 7.59632 17.8003C5.51819 17.1936 4 15.2741 4 13V11C4 8.23858 6.23858 6 9 6H13.5C13.7761 6 14 5.77614 14 5.5ZM17 6.07608C17 6.28258 17.1235 6.46731 17.3067 6.56271C18.9071 7.39639 20 9.07057 20 11V13C20 15.7614 17.7614 18 15 18H11.5C11.2239 18 11 18.2239 11 18.5C11 18.7761 11.2239 19 11.5 19H15C18.3137 19 21 16.3137 21 13V11C21 8.64787 19.6465 6.61186 17.6759 5.62829C17.3551 5.46817 17 5.71752 17 6.07608Z"
      fill="var(--grey-600)"
    />
  </svg>
);
