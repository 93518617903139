import { GridProps } from '../../components/grid';
import trackerService from '../../services/tracker/tracker-service';

export function withSortTracking(callback: GridProps['onSort'], view: string): GridProps['onSort'] {
  if (!callback) return;
  return (columnName, direction) => {
    trackerService.track(`Sort by ${columnName} in ${view} view`);
    callback(columnName, direction);
  };
}
