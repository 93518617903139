import * as React from 'react';
import { Props } from './snackbar-notifications.types';
import './snackbar-notifications.css';
import { SnackbarNotification } from './snackbar-notification';
import { EVENT_SNACKBAR_NOTIFICATION } from '../../../constants';
import { Event } from '../../../services/event';
import { Notification } from '../../../models';
import { v4 } from 'uuid';
import { CloseIcon } from 'tcp-react-icons';
import cn from 'classnames';
import { sleep, translate } from '../../../helpers/utils';

const NUM_NOTIFICATIONS_TO_SHOW_CLEAR_ALL = 3;

enum AnimationPhase {
  NOTHING,
  MOVE_DOWN,
  MOVE_UP,
  MOVE_LEFT,
  DONE,
  SHOW_CLEAR_ALL_HIDDEN,
  SHOW_CLEAR_ALL_VISIBLE,
}

export const SnackbarNotifications: React.FunctionComponent<Props> = () => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const [animationStatus, setAnimationStatus] = React.useState(AnimationPhase.NOTHING);
  const [isShowingClearAll, setIsShowingClearAll] = React.useState(false);
  const [idNotificationToClose, setIdNotificationToClose] = React.useState<string>('');

  const addNotification = async (notification: Notification) => {
    if (notification.title.length > 30) {
      notification.title =
        notification.title.substring(0, 15) +
        '...' +
        notification.title.substring(notification.title.length - 15);
    }

    const localNotification: Notification = {
      ...notification,
      id: v4(),
    };
    notifications.splice(0, 0, localNotification);
    setAnimationStatus(AnimationPhase.NOTHING);

    setNotifications(notifications.slice());
    await sleep(20);
    if (notifications.length === 1) {
      setAnimationStatus(AnimationPhase.MOVE_LEFT);
      await sleep(300);
      setAnimationStatus(AnimationPhase.DONE);
    } else {
      setAnimationStatus(AnimationPhase.MOVE_DOWN);
      await sleep(100);
      setAnimationStatus(AnimationPhase.MOVE_LEFT);
      await sleep(300);
      setAnimationStatus(AnimationPhase.DONE);
      await sleep(100);
      if (notifications.length >= NUM_NOTIFICATIONS_TO_SHOW_CLEAR_ALL) {
        setAnimationStatus(AnimationPhase.SHOW_CLEAR_ALL_HIDDEN);
        setIsShowingClearAll(true);
        await sleep(300);
        setAnimationStatus(AnimationPhase.SHOW_CLEAR_ALL_VISIBLE);
      }
    }
  };

  Event.subscribe(EVENT_SNACKBAR_NOTIFICATION, addNotification);

  const closeNotification = async (id: string) => {
    setIsShowingClearAll(notifications.length - 1 >= NUM_NOTIFICATIONS_TO_SHOW_CLEAR_ALL);
    setAnimationStatus(AnimationPhase.MOVE_UP);
    setIdNotificationToClose(id);
    const nextNotifications = notifications.filter(notification => notification.id !== id);
    await sleep(500);
    setAnimationStatus(AnimationPhase.DONE);
    setIdNotificationToClose('');
    setNotifications(nextNotifications);
  };

  const clearNotification = () => {
    setNotifications([]);
    setIsShowingClearAll(false);
  };

  const isKeepingInPlace = (index: number, notification: Notification) =>
    (index > 0 ||
      animationStatus === AnimationPhase.DONE ||
      animationStatus === AnimationPhase.MOVE_UP ||
      animationStatus === AnimationPhase.SHOW_CLEAR_ALL_HIDDEN ||
      animationStatus === AnimationPhase.SHOW_CLEAR_ALL_VISIBLE) &&
    notification.id !== idNotificationToClose;

  const isHidden = (index: number) => index === 0 && animationStatus === AnimationPhase.NOTHING;

  const isVisible = (index: number) => index === 0 && animationStatus === AnimationPhase.MOVE_DOWN;

  const isAppearing = (index: number) =>
    index === 0 && animationStatus === AnimationPhase.MOVE_LEFT;

  const isMovingUp = (notification: Notification) =>
    notification.id === idNotificationToClose && animationStatus === AnimationPhase.MOVE_UP;

  return (
    <div className="c-snackbar-notifications">
      {isShowingClearAll && (
        <div className="u-margin-top--small u-display--flex u-margin-left--auto u-padding-top--tiny u-padding-bottom--tiny u-padding-left--huge u-padding-right--medium c-snackbar-notifications__close">
          <span
            className="u-cursor--pointer u-color--blue u-margin-right--gutter u-justify-content--center"
            onClick={clearNotification}
          >
            {translate('notifications_clear_all')}
          </span>
          <div className="u-margin-left--auto">
            <CloseIcon
              className=" u-cursor--pointer c-snackbar-notifications__close-icon u-position--relative u-color--blue "
              onClick={clearNotification}
            />
          </div>
        </div>
      )}
      <ul
        className={cn({
          'c-snackbar-notifications__padding': !isShowingClearAll,
        })}
      >
        {notifications.map((notificationTmp, i) => (
          <li
            key={notificationTmp.id}
            className={cn({
              'c-snackbar-notifications__hidden': isHidden(i),
              'c-snackbar-notifications__visible': isVisible(i),
              'c-snackbar-notifications__visible c-snackbar-notifications__animate': isAppearing(i),
              'c-snackbar-notifications__visible c-snackbar-notifications__keep-place':
                isKeepingInPlace(i, notificationTmp),
              'c-snackbar-notifications__hidden-delete': isMovingUp(notificationTmp),
            })}
          >
            <SnackbarNotification
              notification={notificationTmp}
              onClose={() => closeNotification(notificationTmp.id)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
