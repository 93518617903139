import { FC } from 'react';
import { Props } from './bid-modifier-dimension-menu.types';
import { TranslationKey, translate } from '../../../helpers/utils';
import { CheckboxSelect } from '../../../components/checkbox-select/checkbox-select';
import {
  CheckboxSelectOption,
  DEFAULT_OPTIONS_LIMIT,
} from '../../../components/checkbox-select/use-checkbox-options';
import { DimensionBreakout } from '../../../models/dimension-breakout';
import { isEqual } from 'lodash';
import { dimensionTranslationKeyPrefix } from './bm-dimension-breakouts';

export const BidModifierDimensionMenu: FC<Props> = ({
  dimension,
  availableBreakouts,
  previouslySelectedBreakouts,
  onApply,
}) => {
  const dimensionTitle = `${translate('analytics_chart_builder_dimension_bm_title')} · ${translate(
    `${dimensionTranslationKeyPrefix(dimension)}_title` as TranslationKey,
  )}`;

  const isOptionChecked = ({ dimension, breakout }: DimensionBreakout): boolean =>
    previouslySelectedBreakouts
      ? previouslySelectedBreakouts?.selectedOptions?.some(selectedOption =>
          isEqual(selectedOption, { dimension, breakout }),
        )
      : false;

  const breakoutToOption = ({
    dimension,
    breakout,
  }: DimensionBreakout): CheckboxSelectOption<DimensionBreakout> => ({
    id: breakout.value,
    label: breakout.label,
    checked: isOptionChecked({ dimension, breakout }),
    value: { dimension, breakout },
  });

  const handleApply = (options: CheckboxSelectOption<DimensionBreakout>[]) => {
    const selectedOptions = options
      .filter(option => option.checked)
      .map(option => option.value.breakout);

    onApply({
      label: dimension,
      selectedOptions: selectedOptions.map(option => ({
        dimension,
        breakout: option,
      })),
    });
  };
  const availableOptions = availableBreakouts.map(breakoutToOption);
  return (
    <CheckboxSelect
      title={dimensionTitle}
      options={availableOptions}
      searchEnabled={true}
      searchPlaceholder={translate(
        `${dimensionTranslationKeyPrefix(dimension)}_search_placeholder` as TranslationKey,
      )}
      onApply={selectedOptions => handleApply(selectedOptions)}
      selectedOptionsLimit={
        availableOptions.length > DEFAULT_OPTIONS_LIMIT ? DEFAULT_OPTIONS_LIMIT : undefined
      }
    />
  );
};
