import { downloadCsv } from '../../../helpers/csv/download';
import { MetricColumn, ViewData } from '../../../models';
import {
  DIMENSION_AGGREGATION,
  SelectedDimension,
} from '../multi-folder-select/multi-folder-select.types';
import { groupBy } from 'lodash';

type ExportToCsvRequest = {
  data: ViewData[];
  metrics: MetricColumn[];
  fileName: string;
  dimension?: SelectedDimension;
};

export const exportToCsv = (request: ExportToCsvRequest) =>
  downloadCsv({ data: toRows(request), fileName: request.fileName });

function toRows(request: ExportToCsvRequest): Record<string, string>[] {
  const { data, metrics, dimension } = request;
  if (data.length === 0) throw new Error('Data is empty');
  if (dimension) return toRowsWithDimension(data, dimension, metrics[0]!);
  return data.map(row => new CsvRow(row.date).addMetricColumns(row, metrics).toRecord());
}

function toRowsWithDimension(
  data: ViewData[],
  dimension: SelectedDimension,
  metric: MetricColumn,
): Record<string, string>[] {
  const dataGroupedByDate = Object.entries(groupBy(data, 'date'));
  const columnName = DIMENSION_AGGREGATION[dimension.name].columnName;
  return dataGroupedByDate.map(([date, rows]) => {
    const csvRow = new CsvRow(date);
    for (const { label, value } of dimension.values) {
      const metricValue = rows.find(row => String(row[columnName]) === String(value))?.[metric.key];
      csvRow.addColumn(label, metricValue);
    }
    return csvRow.toRecord();
  });
}

class CsvRow {
  private readonly row: Record<string, string> = {};

  constructor(date: number | string) {
    this.row.Date = String(date);
  }

  addColumn(columnName: string, value: string | number | undefined): CsvRow {
    this.row[columnName] = value !== undefined ? String(value) : '';
    return this;
  }

  addMetricColumns(data: ViewData, visibleColumns: MetricColumn[]): CsvRow {
    for (const column of visibleColumns) {
      this.addColumn(column.name, data[column.key]);
    }
    return this;
  }

  toRecord(): Record<string, string> {
    return this.row;
  }
}
