import { useRef } from 'react';

export function useOnce(callback: () => void) {
  const hasBeenCalledRef = useRef(false);

  return () => {
    const hasBeenCalled = hasBeenCalledRef.current;
    if (hasBeenCalled) return;

    hasBeenCalledRef.current = true;
    callback();
  };
}
