import * as React from 'react';
import { Props } from './multi-selection-info.types';
import './multi-selection-info.css';
import IconClose from './icn-close.svg';
import { translate } from '../../../../helpers/utils';
import GlobalLineDarkIcon from 'tcp-react-icons/lib/GlobalLineDarkIcon';

export const MultiSelectionInfo: React.FunctionComponent<Props> = ({
  onClose,
  numberOfCampaigns,
}) => {
  return (
    <span className="u-display--flex u-align-items--center">
      <img
        alt={''}
        src={IconClose}
        className="c-multi-selection__icon u-cursor--pointer u-margin-right--medium"
        onClick={onClose}
      />
      <GlobalLineDarkIcon className="u-margin-right--gutter--small" />
      <div className="u-font-weight-bold">
        <span className="u-margin-right--tiny">{numberOfCampaigns}</span>
        <span>{translate('bidding_control_panel_multiple_selection')}</span>
      </div>
    </span>
  );
};
