import { AxiosError } from 'axios';
import { RevertSponsored } from '../../../constants/sponsored';
import { RequestHelper } from '../request-helper';

const URL_REVERT = 'bidding/revert/partner';

class ApiSponsoredHelper extends RequestHelper {
  private static instance: ApiSponsoredHelper;

  public static getInstance() {
    if (!ApiSponsoredHelper.instance) {
      ApiSponsoredHelper.instance = new ApiSponsoredHelper();
    }

    return ApiSponsoredHelper.instance;
  }

  public async applyRevert(partnerId: number, type: RevertSponsored): Promise<unknown> {
    try {
      return await this.deleteRequest(`${URL_REVERT}/${partnerId}/${type}`, {});
    } catch (e) {
      this.handleError(e as AxiosError);
      return;
    }
  }
}

export default ApiSponsoredHelper.getInstance();
