import React, { ReactNode, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { ConfirmationModal } from '../../components/confirmation-modal';

interface ConfirmationModalHookResult {
  confirm: () => Promise<boolean>;
}

interface UseConfirmationModalArgs {
  title?: string;
  details?: ReactNode[];
  confirmTitle?: string;
  cancelTitle?: string;
}

export function useConfirmationModal(
  args: UseConfirmationModalArgs = {},
): ConfirmationModalHookResult {
  const { title = 'Are you sure?', confirmTitle = 'Yes', cancelTitle = 'No', details = [] } = args;
  const [resolverFn, setResolverFn] = useState<(x: boolean) => void>(() => (x: boolean) => x);
  const [showModal, hideModal] = useModal(
    () => (
      <ConfirmationModal
        onClose={() => {
          hideModal();
          resolverFn(false);
        }}
        details={details}
        title={title}
        primaryButton={{
          title: confirmTitle,
          actionFn: () => {
            hideModal();
            resolverFn(true);
          },
        }}
        secondaryButton={{
          title: cancelTitle,
          actionFn: () => {
            hideModal();
            resolverFn(false);
          },
        }}
      />
    ),
    [resolverFn],
  );

  function confirm(): Promise<boolean> {
    return new Promise(resolve => {
      setResolverFn(() => resolve);
      showModal();
    });
  }

  return { confirm };
}
