import React from 'react';
import { Modifier, RangeModifier } from 'react-day-picker';
import { DateRangePeriod } from '../../models';

export function useDatePickerStyle({
  isComparing,
  compareDateRangePeriods,
  compareModifiers,
  selectedDatePeriodIndex,
}: {
  isComparing: boolean;
  compareDateRangePeriods: Array<DateRangePeriod>;
  compareModifiers: {
    start: Date | undefined;
    end: Date | undefined;
    selectedDatePeriod: Modifier;
    otherDatePeriods: Modifier[];
  };
  selectedDatePeriodIndex: number | null;
}) {
  const setOtherDatePeriodsStyle = () => {
    if (isComparing) {
      compareModifiers.otherDatePeriods.forEach((datePeriod: RangeModifier) => {
        const otherFromElement = document.querySelector(
          `[aria-label="${datePeriod.from?.toDateString()}"]:not(.DayPicker-Day--outside)`,
        );
        const otherToElement = document.querySelector(
          `[aria-label="${datePeriod.to?.toDateString()}"]:not(.DayPicker-Day--outside)`,
        );

        otherFromElement?.classList.add('u-left-round-border');
        otherToElement?.classList.add('u-right-round-border');
      });
    }
  };

  React.useEffect(() => {
    setOtherDatePeriodsStyle();
  }, [compareDateRangePeriods, selectedDatePeriodIndex]);

  return { setOtherDatePeriodsStyle };
}
