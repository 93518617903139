import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { TeapotAnimation } from '../teapot-animation';
import {
  CURRENT_PARTNER_KEY,
  ERROR_RETRY_KEY,
  PROFILE_KEY,
  PROFILE_SETTINGS,
  TI_TRANSLATIONS_BK,
} from '../../constants';

export function withErrorBoundary(component: JSX.Element): React.ReactElement {
  return (
    <ErrorBoundary fallback={<ErrorBoundaryDisplay />} onError={onError}>
      {component}
    </ErrorBoundary>
  );
}

function onError(error: any) {
  try {
    console.error(error);
    const retry = localStorage.getItem(ERROR_RETRY_KEY);
    if (retry !== 'disabled') {
      localStorage.setItem(ERROR_RETRY_KEY, 'disabled');
      localStorage.removeItem(PROFILE_KEY);
      localStorage.removeItem(PROFILE_SETTINGS);
      localStorage.removeItem(CURRENT_PARTNER_KEY);
      localStorage.removeItem(TI_TRANSLATIONS_BK);
      window.location.reload();
    }
  } catch (e) {
    console.error(e);
  }
}

const ErrorBoundaryDisplay = () => {
  return (
    <div
      style={{ paddingTop: '10vh' }}
      className="u-display--flex u-flex-direction--column u-align-items--center u-justify-content--center"
    >
      <TeapotAnimation message="Something went wrong" />
      <h1>Sorry for the inconvenience. Please try refreshing the page</h1>
    </div>
  );
};
