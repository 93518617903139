import * as React from 'react';
import cs from 'classnames';
import './loading.css';
import { Props, LoadingPositionMessage } from './loading.types';

export const Loading: React.FC<Props> = ({
  message,
  className = 'u-padding--huge',
  isDark = false,
  isWhite = false,
  positionMessage = LoadingPositionMessage.BOTTOM,
  variant = 'dots',
}) => (
  <>
    {variant === 'dots' && (
      <div className={`${className} c-loading`}>
        <div
          className={cs('', {
            'c-loading--dark': isDark,
            'c-loading--white': isWhite,
            'c-loading--wrapper': positionMessage === LoadingPositionMessage.BOTTOM,
            'c-loading__full u-display--flex': positionMessage === LoadingPositionMessage.RIGHT,
          })}
        >
          <div
            className={cs({
              'c-loading__dot-size': message && positionMessage === LoadingPositionMessage.RIGHT,
            })}
          >
            <div className={cs('c-loading--dot', { 'u-background--red': !isDark && !isWhite })} />
            <div
              className={cs('c-loading--dot', { 'u-background--orange': !isDark && !isWhite })}
            />
            <div className={cs('c-loading--dot', { 'u-background--blue': !isDark && !isWhite })} />
          </div>
          {message && positionMessage === LoadingPositionMessage.RIGHT && (
            <span className="u-font-weight--bold u-margin-left--medium c-loading__right-message">
              {message}
            </span>
          )}
        </div>
        {message && positionMessage === LoadingPositionMessage.BOTTOM && (
          <div className="u-display--block u-text-align--center u-font-size--h-tiny u-padding-top--small">
            {message}
          </div>
        )}
      </div>
    )}
    {variant === 'ellipsis' && <div className={cs('c-loader-ellipsis')}>{message}</div>}
  </>
);
