import * as React from 'react';
import { Props } from './member-information.types';
import './member-information.css';
import { TranslationKey, translate } from '../../../../helpers/utils';
import { PartnerLevels } from '../../../../constants/admin';

export const MemberInformation: React.FunctionComponent<Props> = ({ member }) => {
  return (
    <div className="u-display--block">
      <h3 className="u-padding-bottom--gutter">
        {translate('admin_members_basic_information_title')}
      </h3>
      <div className="u-display--flex u-padding-bottom--gutter">
        <span className="u-margin-right--gutter"> {translate('admin_members_full_name')} </span>
        <span className="u-font-weight-bold"> {`${member.firstName} ${member.lastName}`} </span>
      </div>
      <div className="u-display--flex u-padding-bottom--gutter">
        <span className="u-margin-right--gutter"> {translate('admin_members_member_id')}</span>
        <span className="u-font-weight-bold"> {member.memberId}</span>
      </div>
      <div className="u-display--flex u-padding-bottom--gutter">
        <span className="u-margin-right--gutter">{translate('admin_members_partner_level')}</span>
        <span className="u-font-weight-bold">
          {partnerLevelDisplayField(member.partnerLevel)} ({member.partnerLevel})
        </span>
      </div>
      <div className="u-display--flex u-padding-bottom--gutter">
        <span className="u-margin-right--gutter"> {translate('admin_members_level')} </span>
        <span className="u-font-weight-bold"> {member.level} </span>
      </div>

      <div className="u-display--flex u-padding-bottom--gutter">
        <span className="u-margin-right--gutter"> {translate('admin_members_email')} </span>
        <span className="u-font-weight-bold"> {member.email} </span>
      </div>
    </div>
  );
};

const partnerLevelDisplayField = (partnerLevel: number) => {
  return translate(
    `member_partner_level_selector_${PartnerLevels[partnerLevel].toLowerCase()}` as TranslationKey,
  );
};
