import * as React from 'react';
import { Props } from './widget.types';
import './widget.css';
import { Card } from '../../../components/card';
import { WidgetMenu } from './widget-menu';
import { Loading } from '../../../components/loading/loading';
import cs from 'classnames';
import filtersCrossedIcon from './filters-crossed.svg';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';

export const Widget: React.FunctionComponent<Props> = ({
  title,
  legend,
  isLoading,
  menuItems,
  children,
  onSelectMenuItem,
  className,
  showMenu = true,
  partialFiltersAppliedMessage = '',
}) => {
  const [isGrabbing, setIsGrabbing] = React.useState(false);
  return (
    <Card
      className={cs('c-widget__shadow c-widget__hover u-padding--gutter u-height--100', className)}
    >
      <div
        className={cs('c-widget u-display--flex u-flex-direction--column u-cursor--grab', {
          'u-cursor--grabbing': isGrabbing,
        })}
        onMouseDown={() => setIsGrabbing(true)}
        onMouseUp={() => setIsGrabbing(false)}
      >
        <div className="c-widget-header u-display--flex u-margin-bottom--large">
          {title}
          {showMenu && (
            <div className="u-margin-left--auto u-display--flex">
              <PartiallyAppliedFiltersIndicator message={partialFiltersAppliedMessage} />
              <WidgetMenu menuItems={menuItems} onSelectMenuItem={onSelectMenuItem} />
            </div>
          )}
        </div>
        <div className="c-widget-legend">{legend}</div>
        {isLoading ? (
          <div className="c-widget-loading">
            <Loading
              isWhite={false}
              isDark={true}
              className="c-widget__loading u-margin-top--huge"
            />
          </div>
        ) : (
          children
        )}
      </div>
    </Card>
  );
};

const PartiallyAppliedFiltersIndicator: React.FC<{ message?: string }> = ({ message }) => {
  if (!message) return null;
  return (
    <TooltipWrapper
      message={message}
      classNameParent="filters-crossed-icon"
      position={TooltipPosition.BOTTOM_TRAILING}
    >
      <img src={filtersCrossedIcon} alt="Filters not applicable icon" />
    </TooltipWrapper>
  );
};
