import * as React from 'react';
import './locale-column.css';
import { Props } from './locale-column.types';
import { FlagIcon } from '../flag-icon/flag-icon';
import cs from 'classnames';

export const LocaleColumn: React.FunctionComponent<Props> = ({
  pos: { localeCode, name },
  styles,
}) => {
  const fontStyles = styles ? styles.font : 'u-color--juri u-font-weight--bold u-font-size--h-tiny';
  return (
    <>
      <FlagIcon className="c-local-column__flag__icon" flag={localeCode} />
      <span className={cs('u-padding-left--small', fontStyles)}>{name}</span>
    </>
  );
};
