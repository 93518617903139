import * as React from 'react';
import cs from 'classnames';
import styles from './popover.module.css';
import { PopoverDirection, POPOVER_DIRECTION, TooltipType } from './popover.types';

export const Popover: React.FC<{
  tooltipType?: TooltipType;
  direction?: PopoverDirection;
}> = ({ children, tooltipType = 'column', direction = PopoverDirection.TRAILING_TOP }) => {
  const { horizontal, vertical } = POPOVER_DIRECTION[direction];
  return (
    <div className={cs(styles.popoverWrapper, styles[horizontal], styles[vertical])}>
      <div className={cs(styles.popover, styles[tooltipType])} role="tooltip">
        <div className={styles.popoverBody}>{children}</div>
      </div>
    </div>
  );
};
