import { FC, MouseEventHandler, useState } from 'react';
import { Button } from '../button/button';
import { ButtonProps } from '../button/button.types';
import { AnchoredMenu, AnchoredMenuProps } from './anchored-menu';

export type ButtonAnchoredMenuProps = {
  button: ButtonProps;
  menu: Omit<AnchoredMenuProps, 'anchor' | 'onClose'>;
};

export const ButtonAnchoredMenu: FC<ButtonAnchoredMenuProps> = ({ button, menu }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const showMenu: MouseEventHandler = e => {
    setAnchor(e.currentTarget as HTMLElement);
  };
  const hideMenu = () => setAnchor(null);

  return (
    <>
      <Button
        {...button}
        icon={{ name: 'arrowDown', rotation: anchor ? 180 : 0 }}
        onClick={showMenu}
      />
      {anchor && <AnchoredMenu {...menu} anchor={anchor} onClose={hideMenu} />}
    </>
  );
};
