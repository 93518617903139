import { useContext } from 'react';
import { AppContext } from '../../global/context/app-context';
import { areMultipleBrandsSelected, getPartner } from '../../services/app-service';
import { Partner } from '../../models';

export function useSelectedPartner() {
  const { partner, subPartners } = useContext(AppContext);
  return getPartner(partner, subPartners);
}

export function useSelectedSubPartners() {
  const { partner, subPartners } = useContext(AppContext);
  if (subPartners.length) return subPartners;
  return [partner];
}

/**
 *
 * @returns The single selected partner or null if multiple partners are selected
 */
export function useSingleSelectedPartner(): Partner | null {
  const selectedPartners = useSelectedSubPartners();
  if (areMultipleBrandsSelected(selectedPartners)) return null;
  return selectedPartners[0];
}
