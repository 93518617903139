import React, { FC } from 'react';
import styles from './linear-progress-bar.module.css';
import cn from 'classnames';
import { TickCirlceThinFillIcon } from 'tcp-react-icons/lib/';
import { CircleIcon } from '../circle-icon';

export type LinearProgressBarProps = {
  startMilestoneLabel: string;
  endMilestoneLabel: string;
  progressBarStyle?: string;
};

export const LinearProgressBar: FC<LinearProgressBarProps> = props => {
  const { startMilestoneLabel, endMilestoneLabel, progressBarStyle } = props;
  return (
    <div className={cn(styles.stepperWrapper, progressBarStyle)}>
      <div className={styles.stepperItem}>
        <TickCirlceThinFillIcon className={styles.icon} />
        <div className={styles.stepName}>{startMilestoneLabel}</div>
      </div>
      <div className={styles.stepperItem}>
        <CircleIcon size={20} />
        <div className={styles.stepName}>{endMilestoneLabel}</div>
      </div>
    </div>
  );
};
