import { useQuery } from '@tanstack/react-query';
import { getDashboards } from '../../services/dashboard-service';
import { AppContext } from '../../global/context/app-context';
import { useContext } from 'react';

export function useDashboards() {
  const {
    partner: { partnerId },
  } = useContext(AppContext);
  return useQuery(['dashboards', partnerId], () => getDashboards(partnerId));
}
