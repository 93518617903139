import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { DatePickerWithModal } from '../../components/date-picker/date-picker-with-modal';

export const useDatePickerModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDatePicker, hideDatePicker] = useModal(() => {
    const onClose = () => {
      setIsModalOpen(false);
      hideDatePicker();
    };
    return <DatePickerWithModal onClose={onClose} />;
  });
  const showDatePickerWithState = () => {
    setIsModalOpen(true);
    showDatePicker();
  };
  return { showDatePicker: showDatePickerWithState, isModalOpen };
};
