import * as React from 'react';
import { Props } from './button.types';
import './button.css';
import cn from 'classnames';
import { Loading } from '../loading/loading';

export const Button: React.FunctionComponent<Props> = ({
  message,
  className = '',
  isLoading = false,
  loadingMessage,
  loaderWidth = 85,
  onClick,
  disabled = false,
  testId,
}) => {
  return (
    <button
      data-testid={testId}
      className={cn(
        `e-button e-button--small e-button--primary u-display--flex ${
          isLoading ? 'c-button--disabled' : ''
        }`,
        className,
      )}
      data-qa="button"
      style={{ width: isLoading ? loaderWidth : 'auto' }}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      {!isLoading && message}
      {isLoading && <Loading message={loadingMessage} variant="ellipsis" />}
    </button>
  );
};
