import React, { FC, useState } from 'react';
import './report-definition-picker.css';
import { ButtonDropdown } from '../../../components/button-dropdown';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';
import { useReportDefinitions } from '../../../hooks/use-report-definitions';
import { getDisplayName } from '../util';
import { useErrorNotificationEffect } from '../../../hooks/use-error-notification-effect';
import { LoadingOverlay } from '../../../components/loading';
import { ReportDefinition } from '../report.types';

export const ReportDefinitionPicker: FC<{
  reportName: string;
  onDefinitionPick: (definition?: ReportDefinition) => void;
}> = ({ reportName, onDefinitionPick }) => {
  const { definitions, isLoading, error } = useReportDefinitions(reportName);
  const [title, setTitle] = useState('Choose report definition');

  useErrorNotificationEffect(error);

  function onSelect(id: number) {
    const definition = definitions.find(el => el.id === id);
    if (!definition) throw new Error('Definition not found');
    setTitle(getDisplayName(definition));
    onDefinitionPick(definition);
  }

  return (
    <div style={{ display: 'flex' }}>
      <LoadingOverlay isLoading={isLoading} style={{ width: '100%', marginRight: 5 }}>
        <ButtonDropdown
          className="report-definition-picker"
          title={title}
          options={definitions.map(definition => ({
            value: definition.id,
            text: getDisplayName(definition),
          }))}
          onSelectOption={onSelect}
        />
      </LoadingOverlay>
      <TooltipWrapper
        message="Report definition is the version of report you want to subscribe to"
        position={TooltipPosition.BOTTOM_TRAILING}
        noTinyPaddingTop={false}
        isAddingPadding={true}
      >
        <span className="u-margin-left--auto u-color--juri-lighter u-font-weight--bold">(?)</span>
      </TooltipWrapper>
    </div>
  );
};
