import { FC, HTMLAttributes, useState } from 'react';
import { ButtonIcon } from '../button-icon/button-icon';
import { AnchoredMenu } from '../menu/anchored-menu';
import { AnchorObject, MenuItem } from '../menu/menu';
import sharedStyles from './shared.module.css';
import styles from './grid-cell-context-menu.module.css';
import { Tooltip, TooltipPosition } from '../tooltip/tooltip';
import cn from 'classnames';
import { GridContextMenu, View } from '../../../models';
import { GridValue } from '../../../components/grid/grid-cell/grid-cell.types';
import trackerService from '../../../services/tracker/tracker-service';
import { QUERY_PARAM_ACTIVE_TAB } from '../../../constants';
import { useQueryParam } from '../../../hooks/use-query-param';

type GridCellContextMenuProps = {
  gridCell: JSX.Element | string | null;
  menuItems: GridContextMenu[];
  rowId: string | number;
  tooltipTitle?: string;
  menuTitle?: string;
  value: GridValue;
  applyMenuValue: (value: GridValue, menuId: number | undefined) => void;
  tooltipPosition?: TooltipPosition;
} & HTMLAttributes<HTMLDivElement>;

export const GridCellContextMenu: FC<GridCellContextMenuProps> = ({
  gridCell,
  menuItems,
  rowId,
  tooltipTitle,
  menuTitle,
  value,
  applyMenuValue,
  tooltipPosition,
}) => {
  const [anchor, setAnchor] = useState<AnchorObject>({
    anchor: null,
    id: null,
  });
  const [activeTab] = useQueryParam(QUERY_PARAM_ACTIVE_TAB);
  const currentTab: string = View[activeTab as unknown as View] ?? 'hotel';

  if (!gridCell && gridCell !== '') return null;

  const anchorMenuItems: MenuItem[] = menuItems.map((item, index) => ({
    id: index,
    label: item.menu,
    onClick: () => {
      trackerService.track(`Examined by : ${item.menu} clicked`, { from_view: currentTab });
      applyMenuValue(value, item.id);
    },
    nestedMenu: item.nestedMenu
      ? item.nestedMenu.map((nestedItem, nestedIndex) => ({
          id: nestedIndex,
          label: nestedItem.menu,
          onClick: () => {
            trackerService.track(`Examined by : ${nestedItem.menu} clicked`, {
              from_view: currentTab,
            });
            applyMenuValue(value, nestedItem?.id);
          },
        }))
      : undefined,
  }));

  return (
    <>
      <span className={cn(styles.contextMenuGridCell)}>
        {gridCell}
        <span
          onClick={e => setAnchor({ anchor: e.currentTarget as HTMLElement, id: rowId })}
          className={cn(sharedStyles.showOnHover, {
            [sharedStyles.activeShowOnHover]: anchor.id === rowId,
          })}
        >
          <div style={{ position: 'relative' }}>
            <Tooltip
              position={tooltipPosition ?? 'rightCenter'}
              title={tooltipTitle ?? ''}
              isDelayed
            >
              <ButtonIcon
                icon={{ name: 'kebabMenu', width: 18, height: 18 }}
                onClick={e => setAnchor({ anchor: e.currentTarget as HTMLElement, id: rowId })}
                title=""
                className={cn(styles.contextMenuButton, {
                  [styles.contextMenuButtonActive]: anchor.id === rowId,
                })}
              />
            </Tooltip>
          </div>
        </span>
      </span>
      {anchor && anchor.id === rowId && (
        <AnchoredMenu
          anchor={anchor.anchor as HTMLElement}
          onClose={() => setAnchor({ anchor: null, id: null })}
          items={anchorMenuItems}
          title={menuTitle ?? ''}
          style={{ width: 200 }}
        />
      )}
    </>
  );
};
