import { Partner, User, Locale, DateRangePeriod, DateRange, Region } from '../../models';
import React from 'react';

type Context = {
  user: User;
  partner: Partner;
  subPartners: ReadonlyArray<Partner>;
  locales: ReadonlyArray<Locale>;
  selectedLocales: ReadonlyArray<string>;
  dateRangePeriod: DateRangePeriod;
  compareDatePeriods: DateRangePeriod[];
  historyRange: DateRange;
  regions: Region[];
  onUpdatePartner: (partner: Partner) => void;
  onUpdateSubpartners: (subpartners: Partner[]) => void;
  onUpdateLocales: (locales: string[]) => void;
  onUpdatePeriod: (dateRangePeriod: DateRangePeriod) => void;
  onUpdateCompareDatePeriods: (dateRangePeriods: DateRangePeriod[]) => void;
  onUpdateHistoryRange: (range: DateRange) => void;
  onUpdateUser: (user: User) => void;
};

// we need to force it to be empty because here we do not have all the data/functions and
// it will be overwritten by the consumer in the App component
export const AppContext = React.createContext({} as Context);
