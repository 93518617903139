export enum ReactQueryKeys {
  WIDGET_DATA = 'WIDGET_DATA',
  WIDGET_COLUMNS = 'WIDGET_COLUMNS',
  YESTERDAY_OVERVIEW_DATA = 'YESTERDAY_OVERVIEW_DATA',
  YESTERDAY_OVERVIEW_COLUMNS = 'YESTERDAY_OVERVIEW_COLUMNS',
  FILTER_TYPES = 'FILTER_TYPES',
  METRICS_COLUMNS = 'METRICS_COLUMNS',
  INVENTORY_STATS = 'INVENTORY_STATS',
  PROPERTIES = 'PROPERTIES',
  DESTINATION = 'DESTINATION',
}
