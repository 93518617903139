import { fromTo } from '../../../models';
import { NotificationLevel, NotificationType } from '../../../constants';

export type ApiSettings = {
  id: number;
  sendEmail: boolean;
  notificationType: number;
  notificationLevel: number;
};

export type FetchNotificationsBody = {
  pagination: {
    offset: number;
    limit: number;
  };
  partnerId: number[];
};

export type NotificationLevelApi = {
  SUCCESS: 0;
  WARNING: 1;
  ERROR: 2;
  INFO: 3;
};

export type NotificationTypeApi = {
  FILE_BASE_BIDS_UPLOAD: 0;
  FILE_BID_MODIFIERS_UPLOAD: 1;
  FILE_PROPERTY_GROUPS_UPLOAD: 2;
};

export const NUMBER_TO_NOTIFICATION_LEVEL: fromTo<number, NotificationLevel> = {
  0: NotificationLevel.SUCCESS,
  1: NotificationLevel.WARNING,
  2: NotificationLevel.ERROR,
};

export const NUMBER_TO_NOTIFICATION_TYPE: fromTo<number, NotificationType> = {
  0: NotificationType.FILE_BASE_BIDS_UPLOAD,
  1: NotificationType.FILE_BID_MODIFIERS_UPLOAD,
  2: NotificationType.FILE_PROPERTY_GROUPS_UPLOAD,
  5: NotificationType.FILE_CAMPAIGN_ALLOCATIONS_UPLOAD,
  6: NotificationType.FILE_CPA_VALUES_UPLOAD,
  7: NotificationType.FILE_SL_BM_UPLOAD,
  8: NotificationType.FILE_SL_BID_UPLOAD,
  9: NotificationType.FILE_SL_BUDGET_CAP_UPLOAD,
};

export const NOTIFICATION_LEVEL_TO_NUMBER: fromTo<NotificationLevel, number> = {
  [NotificationLevel.SUCCESS]: 0,
  [NotificationLevel.WARNING]: 1,
  [NotificationLevel.ERROR]: 2,
  [NotificationLevel.INFO]: 3,
};

export const NOTIFICATION_TYPE_TO_NUMBER: fromTo<NotificationType, number> = {
  [NotificationType.FILE_BASE_BIDS_UPLOAD]: 0,
  [NotificationType.FILE_BID_MODIFIERS_UPLOAD]: 1,
  [NotificationType.FILE_PROPERTY_GROUPS_UPLOAD]: 2,
  [NotificationType.FILE_CAMPAIGN_ALLOCATIONS_UPLOAD]: 5,
  [NotificationType.FILE_CPA_VALUES_UPLOAD]: 6,
  [NotificationType.FILE_SL_BM_UPLOAD]: 7,
  [NotificationType.FILE_SL_BID_UPLOAD]: 8,
  [NotificationType.FILE_SL_BUDGET_CAP_UPLOAD]: 9,
};
