import React, { FC, useContext } from 'react';
import { ArrowIcon } from 'tcp-react-icons';
import { FREQUENCY_TO_TRANSLATION } from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { TranslationKey, isTrivagoEmployee, translate } from '../../../helpers/utils';
import { ReportFrequency } from '../../../models';
import './report-row.css';
import { Button } from '../../../core-ui/components/button/button';

interface ReportRowProps {
  displayName: string;
  documentationUri?: string | null;
  isActive: boolean;
  frequency: ReportFrequency;
  onEnableDisableReport: (newValue: boolean) => void;
  onClick?: () => void;
}

export const ReportRow: FC<ReportRowProps> = props => {
  const { displayName, documentationUri, isActive, frequency, onClick, onEnableDisableReport } =
    props;
  const {
    user: { level },
  } = useContext(AppContext);

  return (
    <tr onClick={onClick} className="c-reports-type-list-row">
      <td>
        <span className="c-reports-type-list-row__name u-font-weight--bold">{displayName}</span>
        {documentationUri && (
          <span className="u-margin-left--gutter">
            <a
              onClick={e => e.stopPropagation()}
              href={documentationUri}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate('reports_documentation_link')}
            </a>
          </span>
        )}
      </td>
      {isTrivagoEmployee(level) && (
        <>
          <td>
            <span className="u-display--flex u-justify-content--flex-end">
              <p style={{ fontSize: 14, opacity: isActive ? 1 : 0 }}>
                {translate(FREQUENCY_TO_TRANSLATION[frequency] as TranslationKey)}
              </p>
            </span>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <div className="u-display--flex u-justify-content--flex-end">
              <div
                className="u-display--flex u-align-items--center"
                onClick={e => e.stopPropagation()}
              >
                {isActive ? (
                  <Button
                    text="Unsubscribe"
                    variant="delete"
                    onClick={() => onEnableDisableReport(false)}
                    style={{ width: 115 }}
                  />
                ) : (
                  <Button
                    text="Subscribe"
                    onClick={() => onEnableDisableReport(true)}
                    style={{ width: 115 }}
                  />
                )}
              </div>
            </div>
          </td>
        </>
      )}
      <td>
        <span className="u-cursor--pointer u-color--blue">
          <span className="c-reports-type-list-row__open u-margin-right--gutter">
            {translate('reports_list')}
          </span>
          <ArrowIcon className="c-reports-type-list-row__open-icon u-color--blue u-position--relative" />
        </span>
      </td>
    </tr>
  );
};
