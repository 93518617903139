export type RowValidationRule = {
  validate: (row: Record<string, string>) => RowValidationResult;
};

export abstract class ErrorRowValidationRule implements RowValidationRule {
  validate(row: Record<string, string>): RowValidationResult {
    const error = this.getError(row);
    return error ? { type: 'error', message: error } : null;
  }

  abstract getError(row: Record<string, string>): string | null;
}

export abstract class WarningRowValidationRule implements RowValidationRule {
  validate(row: Record<string, string>): RowValidationResult {
    const warning = this.getWarning(row);
    return warning ? { type: 'warning', message: warning } : null;
  }

  abstract getWarning(row: Record<string, string>): string | null;
}

export type RowValidationResult = RowValidationIssue | null;

export type RowValidationIssue = {
  type: 'error' | 'warning';
  message: string;
};

export type HeaderValidationRule = {
  validate: (headers: string[]) => string | null;
};
