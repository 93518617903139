import ApiAnalyticsHelper from '../helpers/api/analytics/api-analytics-helper';
import {
  View,
  Hotel,
  FilterColumn,
  ColumnDef,
  CampaignTypeCode,
  Partner,
  Option,
  getAllCampaignTypes,
} from '../models';
import { BRANDS_SUBGROUP, COLUMN_PROPERTY_GROUP_ID, POS_GLOBAL_VALUE } from '../constants';
import { getPartners } from './app-service';

export const getHotelView = (
  localesParam: ReadonlyArray<string>,
  impressionsOnly: boolean = true,
): View => {
  if (
    localesParam.length > 1 ||
    (localesParam.length === 1 && localesParam[0] === POS_GLOBAL_VALUE)
  ) {
    return impressionsOnly ? View.HOTEL_MULTI_LOCALE : View.HOTEL_WITHOUT_IMPRESSIONS_MULTI_LOCALE;
  }
  return impressionsOnly ? View.HOTEL : View.HOTEL_WITHOUT_IMPRESSIONS;
};

export const getHotel = (
  partnersId: number[],
  partnerRef: string,
  locales: string[],
): Promise<Hotel | undefined> => ApiAnalyticsHelper.fetchHotel(partnersId, partnerRef, locales);

// TODO: columnLabel seems to be fixed here, add it
export const propertyGroups2Filter = (propertyGroups: number[]): FilterColumn => {
  return {
    value: propertyGroups.join(','),
    type: 'IN',
    columnName: COLUMN_PROPERTY_GROUP_ID,
    columnLabel: 'Property Group',
  };
};

// TODO: columnLabel seems to be fixed here, add it
export const biddingTypes2Filter = (
  biddingTypes: CampaignTypeCode[],
  columnName: string,
): FilterColumn => {
  const indexes: number[] = [];

  biddingTypes.forEach((it: CampaignTypeCode) => {
    const campaignType = getAllCampaignTypes().find(type => type.code === it);
    if (campaignType) {
      indexes.push(campaignType.value);
    }
  });
  return {
    value: indexes.join(','),
    type: 'IN',
    columnName,
    columnLabel: 'Bidding Type',
  };
};

export const partnerRef2Filter = (partnerRef: string, columnName: string): FilterColumn => {
  return {
    columnName,
    columnLabel: 'PartnerRef',
    type: 'EQUALS',
    value: partnerRef,
  };
};

export const getColumnKeys = (columns: ColumnDef[]) => columns.map(column => column.key);

export const getFiltersMatchingColumns = (filters: FilterColumn[], columnKeys: string[]) =>
  filters.filter(filter => columnKeys.includes(filter.columnName));

export const getFiltersNotMatchingColumns = (filters: FilterColumn[], columnKeys: string[]) =>
  filters.filter(filter => !columnKeys.includes(filter.columnName));

// TODO: Remove this because of storeDisplayedFilters
export const getUpdatedFiltersToBeStored = (
  filtersStored: FilterColumn[],
  filtersToAddOrEdit: FilterColumn[],
  filtersToRemove: FilterColumn[],
) => {
  // Remove all filters that match this view's columns
  // Left are filters from other dimensions
  // Concat filtersCreated and filters from other dimensions

  // Take filtersCreated and filtersFromOtherDimensions. That's all

  const columnNamesToAddOrEdit = filtersToAddOrEdit.map(filterToStore => filterToStore.columnLabel);
  const columnNamesToRemove = filtersToRemove.map(filterToRemove => filterToRemove.columnLabel);
  // eslint-disable-next-line prefer-const
  let columnNamesEdited: string[] = [];

  const updatedStoredFilters = filtersStored.map(filterStored => {
    const indexOfFilter = columnNamesToAddOrEdit.indexOf(filterStored.columnLabel);
    if (indexOfFilter !== -1) {
      columnNamesEdited.push(filterStored.columnLabel);
      return filtersToAddOrEdit[indexOfFilter];
    }
    return filterStored;
  });

  const withRemovedStoredFilters = updatedStoredFilters
    .filter(filterStored => !columnNamesToRemove.includes(filterStored.columnLabel))
    .filter(filterStored => !!filterStored.columnLabel);
  const filtersToAdd = filtersToAddOrEdit
    .filter(filter => !columnNamesEdited.includes(filter.columnLabel))
    .filter(filterStored => !!filterStored.columnLabel);
  const nextStoredFilters = [...withRemovedStoredFilters, ...filtersToAdd];
  return nextStoredFilters;
};

export const partnerToOption = (partnerTmp: Partner): Option => ({
  label: partnerTmp.name,
  value: partnerTmp.partnerId.toString(),
  group: BRANDS_SUBGROUP,
  id: partnerTmp.partnerId.toString(),
});

export const getSelectedBrands = (partner: Partner, subPartners: Partner[]) =>
  getPartners(partner, subPartners).map(partnerToOption);
