import cn from 'classnames';
import { FC, useRef, useState } from 'react';
import { CloseIcon, PlusThinDarkIcon } from 'tcp-react-icons';
import { OnboardingTooltip } from '../../../components/onboarding-tooltip';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';
import { CHART_BUILDER_TIME_PERIOD_ONBOARDING_KEY } from '../../../constants';
import { translate } from '../../../helpers/utils';
import { DimensionValueLabels, MetricColumn } from '../../../models';
import { dimensionTitleTranslationKey } from '../bid-modifier-dimension-menu/bm-dimension-breakouts';
import { DimensionMenuItems } from './dimension-menu-items';
import { MetricMenuItems } from './metric-menu-items';
import './multi-folder-select.css';
import {
  Dimension,
  Props,
  SelectedDimension,
  SelectedItemsListProps,
} from './multi-folder-select.types';
import { useOnClickOutside } from '../../../hooks/use-on-click-outside';

export const MultiFolderSelect: FC<Props> = ({
  title,
  applySelection,
  dimension,
  metrics,
  columns,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const menuRef = useRef(null);
  const multiLevelMenuRef = useRef<HTMLDivElement>(null);
  const showDatePickerRef = useRef<boolean>(false);

  useOnClickOutside(menuRef, event => {
    const isClickOutsideMenu = !multiLevelMenuRef.current?.contains(event.target as HTMLElement);
    !showDatePickerRef?.current && (!menuRef?.current || isClickOutsideMenu) && setOpenMenu(false);
  });

  const applyMetricsFilters = (filters: MetricColumn[]) => {
    setOpenMenu(false);
    const sortedFilters = filters.sort((a, b) => a.name.localeCompare(b.name));
    applySelection(sortedFilters);
  };

  const removeMetric = (metric: MetricColumn) => {
    const filteredMetrics = metrics?.filter(item => item.key !== metric.key);
    if (filteredMetrics && filteredMetrics?.length > 0) {
      applySelection(filteredMetrics);
    } else {
      applySelection(undefined);
    }
  };
  const applyFilters = (filters: SelectedDimension) => {
    setOpenMenu(false);
    applySelection(filters);
  };

  const maxAllowedMetrics = dimension ? 1 : 2;
  const noOfSelectedMetrics = metrics?.length ?? 0;
  const isDimensionMissing = !dimension && noOfSelectedMetrics < 2;
  const areMetricsMissing = !metrics || metrics?.length < maxAllowedMetrics;
  const isMissingDimensionOrMetrics = isDimensionMissing || areMetricsMissing;
  const showTooltip = noOfSelectedMetrics === 2 && title === 'Dimension';
  const isDimensionSelected = dimension && title === 'Dimension';
  const shouldEnableMenus = isMissingDimensionOrMetrics && !isDimensionSelected;

  const dimensionNameLabelMapper: { [key: string]: string[] } = {
    'Bid Modifier': ['ttt', 'los', 'dd', 'gs'],
    'Point of Sale': ['posCountry', 'posRegion'],
  };

  const dimensionLabel = Object.keys(dimensionNameLabelMapper).find(
    key => dimension && dimensionNameLabelMapper[key].includes(dimension.name),
  );

  return (
    <>
      <div
        className={cn(
          'multi-folder-select u-display--flex u-flex-direction--column u-padding-w--medium u-margin-top--large u-position--relative',
          {
            'multi-folder-select-item': shouldEnableMenus,
            'multi-folder-select-active': openMenu,
          },
        )}
        ref={menuRef}
      >
        <TooltipWrapper
          message={translate('analytics_chart_builder_dimension_menu_tooltip')}
          position={TooltipPosition.TRAILING_CENTER}
          hideTooltip={!showTooltip}
          className="multi-folder-select--tooltip"
        >
          <div
            data-testid="multifolder-select-title"
            className={cn(
              'u-display--flex u-justify-content--space-between u-align-items--center u-padding-left--medium u-padding-right--medium u-color--juri-light u-height--36px',
              { 'disable-menu': showTooltip },
            )}
            onClick={() => shouldEnableMenus && setOpenMenu(!openMenu)}
          >
            <h4 className={cn({ 'u-color--juri-lighter': showTooltip })}>{title}</h4>

            {shouldEnableMenus && <PlusThinDarkIcon className="u-color--juri-light" />}
          </div>
        </TooltipWrapper>
      </div>
      {openMenu && title === 'Dimension' && (
        <div
          className="multi-folder-select-menu"
          ref={multiLevelMenuRef}
          data-testid="dimension-folder-select-dimension-name"
        >
          <DimensionMenuItems
            applyFilters={applyFilters}
            selectedDimension={dimension as SelectedDimension}
            showDatePickerRef={showDatePickerRef}
          />
        </div>
      )}
      {title === 'Dimension' && (
        <div className="time-period-onboarding-tooltip">
          <OnboardingTooltip keyStorage={CHART_BUILDER_TIME_PERIOD_ONBOARDING_KEY}>
            <p>{translate('dashboard_chart_builder_time_period_onboarding')}</p>
          </OnboardingTooltip>
        </div>
      )}
      {title === 'Dimension' && dimension && (
        <SelectedItemsList<DimensionValueLabels>
          items={[dimension]}
          onOpenMenu={() => setOpenMenu(true)}
          onRemoveItem={() => {
            applySelection(undefined);
          }}
        >
          {item => (
            <>
              <h4 data-testid="multi-folder-dimension-title">
                {dimensionLabel ? `${dimensionLabel} · ` : ''}
                {translate(dimensionTitleTranslationKey(item.name as Dimension))}
              </h4>
              <p data-testid="multi-folder-dimension-subtitle">
                {(item.values?.length || 0) + (dimension.name === 'timePeriod' ? 1 : 0)} selected
              </p>
            </>
          )}
        </SelectedItemsList>
      )}
      {openMenu && title === 'Metrics' && (
        <div className="multi-folder-select-menu" ref={multiLevelMenuRef}>
          <MetricMenuItems
            columns={columns.sort((a, b) => a.name.localeCompare(b.name))}
            applyFilters={applyMetricsFilters}
            selectedMetrics={metrics}
            selectedDimension={dimension}
          />
        </div>
      )}
      {title === 'Metrics' && !!metrics && (
        <SelectedItemsList<MetricColumn>
          items={metrics}
          onOpenMenu={() => setOpenMenu(true)}
          onRemoveItem={removeMetric}
          className="multi-folder-select--metric"
        >
          {item => <h4>{item.name}</h4>}
        </SelectedItemsList>
      )}
    </>
  );
};

const SelectedItemsList = <T,>({
  items,
  onOpenMenu,
  onRemoveItem,
  className,
  children,
}: SelectedItemsListProps<T>) => {
  return (
    <ul
      className="multi-folder-select--list u-margin-top--small"
      data-testid="multi-folder-select-items"
    >
      {items.map(item => {
        return (
          <li
            className={cn(
              'u-display--flex u-flex-direction--row u-margin-bottom--minuscule u-border--blue-very-light u-padding-left--medium u-padding-right--medium u-background--blue-lightest u-color--juri u-font-size--h-tiny u-justify-content--space-between u-border-radius',
              className,
            )}
            key={item.name}
          >
            <div className="u-flex-grow--1" onClick={onOpenMenu}>
              {children(item)}
            </div>
            <CloseIcon onClick={() => onRemoveItem(item)} />
          </li>
        );
      })}
    </ul>
  );
};
