import { useEffect } from 'react';
import { NotificationLevel } from '../../constants';
import { showNotification } from '../../services/notification-service';

export function useErrorNotificationEffect(error?: string) {
  useEffect(() => {
    if (!error) return;
    showNotification({
      level: NotificationLevel.ERROR,
      message: error,
    });
  }, [error]);
}
