import { QueryKey, useQuery } from '@tanstack/react-query';
import { useSelectedPartner } from '../use-selected-partner';
import axios from 'axios';

export const propertyGroupAliasesQueryKey = (partnerId: number): QueryKey => [
  'property-group-aliases',
  partnerId,
];

export function usePropertyGroupAliases() {
  const partnerId = useSelectedPartner().partnerId;
  return useQuery(propertyGroupAliasesQueryKey(partnerId), () =>
    fetchPropertyGroupAliases(partnerId),
  );
}

export const URL_PROPERTY_GROUP_ALIASES = '/property-group-aliases';

type PGAlias = {
  propertyGroupId: number;
  alias: string;
  localeCode: string;
};

/**
 * A map of property group aliases by property group id and locale code.
 */
export type PropertyGroupAliases = Record<number, Record<string, string>>;

async function fetchPropertyGroupAliases(partnerId: number): Promise<PropertyGroupAliases> {
  const response = await axios.get<{ propertyGroupAliases: PGAlias[] }>(
    `${URL_PROPERTY_GROUP_ALIASES}/${partnerId}`,
    {
      baseURL: process.env.REACT_APP_URL,
    },
  );
  const result: PropertyGroupAliases = {};
  for (const { propertyGroupId, localeCode, alias } of response.data.propertyGroupAliases) {
    if (!result[propertyGroupId]) result[propertyGroupId] = {};
    result[propertyGroupId][localeCode] = alias;
  }
  return result;
}
