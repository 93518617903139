/* eslint-disable react/display-name */
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { ManagementRightsGuard } from '../../../components/management-rights-guard';
import {
  DASHBOARD_ROUTE,
  CAMPAIGNS_ROUTE,
  HISTORY_ROUTE,
  ANALYTICS_ROUTE,
  REPORTS_ROUTE,
  SPONSORED_ANALYTICS_ROUTE,
  SPONSORED_CAMPAIGNS_ROUTE,
  ADMIN_ROUTE,
  REPORTS_HISTORY_ROUTE,
} from '../../../constants';
import { Bidding } from '../../../views/bidding';
import { History } from '../../../views/history';
import { AnalyticsRouter } from '../../../views/analytics/analytics-router';
import { AppContext } from '../../context/app-context';
import { HeaderPage } from '../header/header-page';
import { PageFooter } from '../../../components/page-footer';
import { findItemsMenuOptions } from '../../../services/app-service';
import { SponsoredCampaigns } from '../../../views/sponsored/sponsored-campaigns';
import { Admin } from '../../../views/admin';
import { SponsoredAnalyticsRouter } from '../../../views/sponsored/sponsored-analytics/sponsored-analytics-router';
import { ReportHistory } from '../../../views/reports/report-history/report-history';
import { NewReportsTab } from '../../../views/reports/new-reports-tab';
import { SnackbarDisplayContainer } from '../../../components/snackbar/snackbar-display-container';
import { withErrorBoundary } from '../../../components/error/error-boundary';
import { CustomDashboard } from '../../../views/custom-dashboard/custom-dashboard';

const AppRoute: React.FunctionComponent<RouteComponentProps> = ({ location }) => {
  const { user, selectedLocales, partner, subPartners, onUpdateSubpartners } =
    React.useContext(AppContext);

  const updateSubPartnersSelectedForCurrentPage = React.useCallback(() => {
    const menuOption = findItemsMenuOptions(location.pathname);

    if (!menuOption) return;

    if (!menuOption.selectorBar.allowMultipleBrandSelection && subPartners.length > 1) {
      const brand = subPartners.find(({ partnerId }) => partnerId === partner!.partnerId);
      onUpdateSubpartners([brand ?? subPartners[0]]);
    }
  }, [location.pathname, onUpdateSubpartners, partner, subPartners]);

  React.useEffect(() => {
    updateSubPartnersSelectedForCurrentPage();
  }, [updateSubPartnersSelectedForCurrentPage]);

  React.useEffect(() => {
    updateSubPartnersSelectedForCurrentPage();
  }, [location, partner, updateSubPartnersSelectedForCurrentPage]);

  const renderCustomDashboard = () => {
    const hasLocalesLoaded = selectedLocales.length > 0;
    return <>{hasLocalesLoaded && <CustomDashboard />}</>;
  };

  const renderAnalytics = (routeProps: RouteComponentProps) => {
    return <>{selectedLocales.length > 0 && <AnalyticsRouter routeProps={routeProps} />}</>;
  };

  const renderSponsoredAnalytics = (routeProps: RouteComponentProps) => {
    return (
      <>{selectedLocales.length > 0 && <SponsoredAnalyticsRouter routeProps={routeProps} />}</>
    );
  };

  const { right } = findItemsMenuOptions(location.pathname);

  const exactRoutes = {
    [DASHBOARD_ROUTE]: renderCustomDashboard,
    [CAMPAIGNS_ROUTE]: () => <Bidding />,
    [HISTORY_ROUTE]: () => <History />,
    [REPORTS_ROUTE]: () => <NewReportsTab />,
    [REPORTS_HISTORY_ROUTE]: () => <ReportHistory />,
    [SPONSORED_CAMPAIGNS_ROUTE]: () => <SponsoredCampaigns />,
  };

  const nonExactRoutes = {
    [ANALYTICS_ROUTE]: renderAnalytics,
    [SPONSORED_ANALYTICS_ROUTE]: renderSponsoredAnalytics,
  };

  const toRoute = ([path, renderComponent]: any, index: number, exact: boolean = false) => (
    <Route
      key={index}
      path={path}
      exact={exact}
      render={props => withErrorBoundary(renderComponent(props))}
    />
  );

  const toExactRoute = (entry: any, index: number) => toRoute(entry, index, true);

  return (
    <>
      <HeaderPage />
      <div style={{ minHeight: 'calc(100vh - 362px)' }}>
        <Switch>
          <ManagementRightsGuard user={user} rights={right}>
            {Object.entries(exactRoutes).map(toExactRoute)}
            {Object.entries(nonExactRoutes).map((entry, index) => toRoute(entry, index))}
            {user.isAdmin && (
              <Route exact={true} path={ADMIN_ROUTE} render={() => withErrorBoundary(<Admin />)} />
            )}
          </ManagementRightsGuard>

          <Redirect to={DASHBOARD_ROUTE} />
        </Switch>
      </div>
      <PageFooter />
      <SnackbarDisplayContainer />
    </>
  );
};

export default withRouter(AppRoute);
