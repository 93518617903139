import { fromTo } from '../../models';

export type FilterType = 'string' | 'numeric';

//TODO: To be added in phase 2 of filter redesign
// export type StringQualifier = 'IS' | 'IS_NOT' | 'CONTAINS' | 'NOT_CONTAINS';
// export type NumericQualifier =
//   | 'EQUALS'
//   | 'NOT_EQUALS'
//   | 'GREATER'
//   | 'LESS'
//   | 'BETWEEN'
//   | 'NOT_BETWEEN';

export type StringQualifier = 'EQUALS' | 'NOT_EQUALS' | 'CONTAINS' | 'NOT_CONTAINS';
export type NumericQualifier = 'EQUALS' | 'NOT_EQUALS' | 'GREATER' | 'LESS';

export type FilterQualifier = StringQualifier | NumericQualifier;

export type NumericRange = { from?: number; to?: number };

type BaseFilter = {
  id: string;
  name: string;
  decoration?: string;
};

export type StringFilter = BaseFilter & {
  type: 'string';
  qualifier: StringQualifier;
  value?: string;
};

type NumericFilter = BaseFilter & {
  type: 'numeric';
  qualifier: NumericQualifier;
  value?: number | string | readonly string[] | undefined;
};

export type NumericRangeFilter = BaseFilter & {
  type: 'numeric';
  qualifier: NumericQualifier;
  value?: NumericRange;
};

export type Filter = StringFilter | NumericFilter | NumericRangeFilter;

export type OldFilter = StringFilter | NumericFilter;

export const QUALIFIERS_BY_FILTER_TYPE: fromTo<FilterType, readonly FilterQualifier[]> = {
  string: ['EQUALS', 'NOT_EQUALS', 'CONTAINS', 'NOT_CONTAINS'] as const,
  numeric: ['EQUALS', 'NOT_EQUALS', 'GREATER', 'LESS'] as const,
} as const;
