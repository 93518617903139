import * as React from 'react';
import { Props } from './delete-dashboard-modal.types';
import './delete-dashboard-modal.css';
import { ConfirmationModal } from '../../../../components/confirmation-modal';
import { translate } from '../../../../helpers/utils';

export const DeleteDashboardModal: React.FunctionComponent<Props> = ({
  name,
  onClose,
  onApply,
}) => {
  return (
    <ConfirmationModal
      title={translate('dashboard_header_modal_delete_title')}
      details={[translate('dashboard_header_modal_delete_body', name)]}
      onClose={onClose}
      primaryButton={{
        title: translate('common_delete'),
        actionFn: () => {
          onClose();
          onApply();
        },
        style: 'e-button--danger',
      }}
      secondaryButton={{
        title: translate('common_cancel'),
        actionFn: onClose,
      }}
    />
  );
};
