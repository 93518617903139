import { useContext } from 'react';
import { isNumericRangeFilter } from '../is-numeric-range-filter';
import { OldFilter, FilterQualifier, NumericRange } from '../types';
import { FilterFormFieldContext } from './filter-form-field-context';

export function useFilterFormField() {
  const context = useContext(FilterFormFieldContext);

  if (!context) {
    throw new Error('useFilterFormField must be used within a FilterFormFieldProvider');
  }
  const { filter, setFilter } = context;

  return {
    filter,
    setValue: (value: string | number | NumericRange) =>
      setFilter({ ...filter, value } as OldFilter),
    changeQualifier: (qualifier: FilterQualifier) => {
      const newFilter = { ...filter, qualifier } as OldFilter;
      // If the filter changes between numeric range and numeric, reset the value
      if (isNumericRangeFilter(filter) !== isNumericRangeFilter(newFilter)) {
        return setFilter({ ...newFilter, value: undefined });
      }
      return setFilter(newFilter);
    },
  };
}
