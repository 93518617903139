import * as React from 'react';
import './grid-loading.css';

export const GridLoading: React.FunctionComponent = () => {
  return (
    <div className="u-padding--huge">
      <div className="c-grid-loading__wrapper">
        <div className="c-grid-loading__dot u-background--red" />
        <div className="c-grid-loading__dot u-background--orange" />
        <div className="c-grid-loading__dot u-background--blue" />
      </div>

      <p className="u-display--block u-text-align--center u-font-size--h-tiny u-padding-top--small">
        Updating your results{' '}
        {/* // TODO Add key/translation. We cannot use translation(), it should be passed to the grid as a property */}
      </p>
    </div>
  );
};
