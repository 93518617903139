import * as React from 'react';
import { Notification } from '../../../../components/notification';
import { BaseNotification } from '../../../../models';
import { useBanner } from '../../../../hooks/use-banner';

export const Banner: React.FC = () => {
  const { banner } = useBanner();
  if (!banner) return null;

  const notification: BaseNotification = {
    message: [banner.header, banner.body],
    level: banner.type,
  };

  return <Notification notification={notification} />;
};
