import React from 'react';
import { FilterSet } from '../../../../../models';
import ApiAnalyticsHelper from '../../../../../helpers/api/analytics/api-analytics-helper';
import { AppContext } from '../../../../../global/context/app-context';
import { getPartner } from '../../../../../services/app-service';
import { showNotification } from '../../../../../services/notification-service';
import { NotificationLevel } from '../../../../../constants';
import { translate } from '../../../../../helpers/utils';

export const useStoredFilterSets = (): [FilterSet[], (filterSets: FilterSet[]) => void] => {
  const [storedFilterSets, setStoredFilterSets] = React.useState<FilterSet[]>([]);
  const { partner, subPartners } = React.useContext(AppContext);
  const { partnerId } = getPartner(partner, subPartners);

  const fetchFilterSets = React.useCallback(
    async (partnerId: number) => {
      try {
        return await ApiAnalyticsHelper.fetchFilterSets(partnerId);
      } catch (e) {
        showNotification({
          level: NotificationLevel.ERROR,
          message: translate((e as any).message),
        });
        return [];
      }
    },
    [partnerId],
  );

  React.useEffect(() => {
    (async () => {
      const filterSets: FilterSet[] = await fetchFilterSets(partnerId);
      setStoredFilterSets(filterSets);
    })();
  }, [fetchFilterSets, partnerId]);

  return [storedFilterSets, setStoredFilterSets];
};
