import { translate } from './utils';
import { ColumnDef } from '../models';
import { Row } from '../components/grid';

export const formatTotalRow = (data: Row[], columns: ColumnDef[]) => {
  const key = columns.find(value => !value.isHidden)!.key;
  if (data.length === 1) {
    data[0][key] = translate('analytics_table_total_rows');
  }
  if (data.length === 2) {
    data[0][key] = translate('analytics_table_subtotal_rows');
    data[1][key] = translate('analytics_table_total_rows');
  }

  return data.map(row => {
    const copy = { ...row };
    columns.forEach(column => {
      if (!copy[column.key] && column.key !== 'id') {
        copy[column.key] = '-';
      }
    });

    copy.checkbox = '';

    return copy;
  });
};
