export enum BidFileType {
  BASE_BID = 'BASE_BID',
  BID_MODIFIER = 'BID_MODIFIER',
  PROPERTY_GROUPS = 'PROPERTY_GROUPS',
  CAMPAIGN_ALLOCATION = 'CAMPAIGN_ALLOCATION',
  CPA = 'CPA',
  SL_BASE_BID = 'SL_BASE_BID',
  SL_BID_MODIFIER = 'SL_BID_MODIFIER',
  SL_BUDGET_CAP = 'SL_BUDGET_CAP',
  INVENTORY = 'INVENTORY',
  CPA_BID_MODIFIER = 'CPA_BID_MODIFIER',
}
