import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import './chart-builder.css';
import { ChartBuilderProps } from './chart-builder.types';
import { ArrowIcon, CloseIcon, ExclamationCircleFillDarkIcon } from 'tcp-react-icons';
import { WidgetMenu, WidgetMenuItem } from '../widget/widget-menu';
import { TimeLineChart } from '../time-line-widget/time-line-chart';
import { Color } from '../../../constants';
import {
  DashBoard,
  DashboardWidget,
  DateRange,
  ExtendedTimelineWidgetProps,
  Metric,
  MetricColumn,
  SelectedDimensionApi,
  TimelineWidget,
  TimelineWidgetProps,
  WidgetType,
} from '../../../models';
import { MultiFolderSelect } from '../multi-folder-select';
import { Button } from '../../../components/button';
import {
  BID_MODIFIER_DIMENSIONS,
  Dimension,
  isBidModifierDimension,
  SelectedDimension,
} from '../multi-folder-select/multi-folder-select.types';
import {
  CHART_BUILDER_CREATE_STAY,
  CHART_BUILDER_CREATE_SUCCESS,
  CHART_BUILDER_DELETE,
  CHART_BUILDER_EDIT_SUCCESS,
  DATE_FORMAT_LONG_COMA,
  TimeOption,
} from '../../../constants';
import { useTimelineData } from '../../../hooks/use-timeline-data';
import { Loading } from '../../../components/loading';
import { iconTypes } from '../../../components/icon';
import { useSnackbar } from '../../../global/app/snackbar/use-snackbar';
import {
  areArraysEqual,
  capitalizeFirstLetter,
  generateNewWidgetName,
  isTextOverflowing,
  translate,
  translateHtml,
} from '../../../helpers/utils';
import { ButtonRetry } from '../../../components/button-retry';
import { WidgetNoData } from '../widget/widget-no-data';
import { AppContext } from '../../../global/context/app-context';
import DeleteChartModal from './delete-chart';
import PreventNavigationPrompt from '../../../components/prevent-navigation-prompt';
import TrackerService from '../../../services/tracker/tracker-service';
import { UnsavedChangesModal } from './unsaved-changes-modal';
import { VerticalDivider } from '../../../components/vertical-divider';
import { isEqual } from 'lodash';
import cs from 'classnames';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';
import { ChartBuilderPeriodPicker } from './chart-builder-period-picker';
import { usePropertiesData } from '../hooks/use-properties-data';
import { useMoveChartModal } from './move-chart-modal';
import ApiWidgetsHelper from '../../../helpers/api/dashboard/api-widgets-helper';
import { useSelectedPartner } from '../../../hooks/use-selected-partner';
import { calculateNewWidgetPosition } from '../../../services/dashboard-service';
import { useDashboards } from '../use-dashboards';
import { WidgetTitleEdit } from '../time-line-widget/widget-title-edit';
import { useDateRangePeriod } from '../../../components/date-picker/date-picker.state';
import { exportToCsv } from './export-to-csv';
import { useExtendDimensionInfo } from '../../../hooks/use-extend-dimension-info';
import { SaveAsModal } from './chart-builder-save-as-modal';
import { format } from 'date-fns';

const CHART_TYPE = WidgetType.TIMELINE;

type MenuItem = {
  title: string;
  disabled?: boolean;
  icon: keyof typeof iconTypes;
  action?: () => void;
};

export const ChartBuilder: FC<ChartBuilderProps> = ({
  currentDashboard,
  currentWidget,
  setDashboard,
  onClose,
  save,
  deleteChartWidget,
  edit,
}) => {
  const { showSnackbarMessage } = useSnackbar();
  const [dimension, setDimension] = useState<SelectedDimension>();
  const [metrics, setMetrics] = useState<MetricColumn[]>([]);
  const [timeOption, setTimeOption] = useState<TimeOption>(TimeOption.DAY);
  const [viewColumns, setViewColumns] = useState<
    ReadonlyMap<Dimension | undefined, MetricColumn[]>
  >(new Map());
  const [isSavingNewChart, setIsSavingNewChart] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(!!currentWidget);
  const [isRemovingChart, setIsRemovingChart] = useState<boolean>(false);
  const [saveAsOptions, setSaveAsOptions] = useState({
    name:
      (currentWidget as TimelineWidget)?.props.name ||
      translate('analytics_chart_builder_untitled'),
    dashboard: currentDashboard,
  });
  const { locales, partner, dateRangePeriod } = useContext(AppContext);
  const [globalDateRangePeriod] = useDateRangePeriod();
  const [startedEditing, setStartedEditing] = useState<boolean>(false);
  const [isUnsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState<boolean>(false);
  const [saveButtonTitle, setSaveButtonTitle] = useState<string>(
    translate('analytics_chart_builder_unsaved_changes_modal_save'),
  );
  const [chartHeight, setChartHeight] = useState<number>(0);
  const [previousChartNameAndDashboard, setPreviousChartNameAndDashboard] = useState<{
    name: string;
    dashboard: DashBoard | undefined;
  }>({ name: 'Untitled', dashboard: currentDashboard });
  const [createStayTimeElapsed, setCreateStayTimeElapsed] = useState<boolean>(false);
  const titleRef = useRef<HTMLSpanElement | null>(null);

  const missingItems = [];
  if (!dimension) missingItems.push(translate('analytics_chart_builder_dimension'));
  if (!metrics.length) missingItems.push(translate('analytics_chart_builder_metric'));

  const { data: allDashboards = [], refetch: refetchDashboards } = useDashboards();

  const partnerId = useSelectedPartner().partnerId;
  const moveChartModal = useMoveChartModal(moveWidgetTo, {
    chartName: saveAsOptions.name,
    dashboards: allDashboards.filter(d => d.id !== currentDashboard?.id),
    widgetUuid: currentWidget?.uuid,
  });

  async function moveWidgetTo(dashboard: DashBoard) {
    await ApiWidgetsHelper.moveWidget({
      partnerId,
      destinationDashboardId: dashboard.id,
      widgetUuid: currentWidget!.uuid,
      sourceDashboardId: currentDashboard!.id,
      newPosition: calculateNewWidgetPosition(dashboard.widgets),
    });
    const { data = [] } = await refetchDashboards();
    const dashboardWithNewWidget = data.find(dash => dash.id === dashboard.id);
    setSaveAsOptions(prevState => ({ ...prevState, dashboard: dashboardWithNewWidget }));
    setDashboard(dashboardWithNewWidget);
  }

  const [isRenaming, setIsRenaming] = useState(false);

  const isDimensionChanged = (oldDimension?: SelectedDimensionApi) =>
    dimension?.name !== (currentWidget?.props as TimelineWidgetProps).dimension?.name ||
    !isEqual(
      dimension?.values.map(item => String(item.value)),
      oldDimension?.values.map(value => String(value)),
    );

  const isMetricChanged = (oldMetrics?: Metric[]) =>
    !isEqual(
      metrics.map(item => item.key),
      oldMetrics?.map(item => item.id),
    );

  const hasUnsavedChanges = (): boolean => {
    if (!currentWidget) return false;
    const props = currentWidget.props as TimelineWidgetProps;
    return isDimensionChanged(props.dimension) || isMetricChanged(props.metrics);
  };

  useEffect(() => {
    if (createStayTimeElapsed) {
      TrackerService.track(CHART_BUILDER_CREATE_STAY, {
        chartType: CHART_TYPE,
        dimension,
        metrics,
        aggregation: timeOption,
        dateRangePeriod: {
          ...dateRangePeriod,
          from: format(dateRangePeriod.from, DATE_FORMAT_LONG_COMA),
          to: format(dateRangePeriod.to, DATE_FORMAT_LONG_COMA),
        },
      });
    }
  }, [createStayTimeElapsed, dimension, metrics, timeOption, dateRangePeriod]);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    const timeout = setTimeout(() => setCreateStayTimeElapsed(true), 1000 * 15);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (currentDashboard?.id !== saveAsOptions.dashboard?.id) setDashboard(saveAsOptions.dashboard);
  }, [currentDashboard, saveAsOptions.dashboard, setDashboard]);

  const widgetProps = (): ExtendedTimelineWidgetProps => {
    const dimensionInfo: SelectedDimensionApi = {
      name: dimension?.name as Dimension,
      values: dimension?.values.map(value => String(value.value)) as string[],
    };
    return {
      metrics: metrics.map(metric => ({
        id: metric.key,
      })),
      dimension:
        dimension?.name === 'propertyGroups'
          ? extendDimensionInfo(dimensionInfo, locales, [], propertiesData)
          : dimension,
      aggregationPeriod: timeOption,
    };
  };

  const selectedMetricKeys: string[] = metrics.map(metric => metric.key) ?? [];
  const selectedDimensionApi: SelectedDimensionApi | undefined = dimension
    ? {
        name: dimension.name,
        values: dimension.values.map(dim => {
          return dim.value !== undefined ? dim.value.toString() : (dim as any);
        }),
      }
    : undefined;

  const currentWidgetProps = currentWidget?.props as TimelineWidgetProps;
  const itemIds =
    currentWidgetProps?.dimension && currentWidgetProps.dimension.name === 'propertyName'
      ? currentWidgetProps.dimension.values
      : [];
  const {
    data: propertiesData,
    isError: isPropertiesError,
    isLoading: isPropertiesLoading,
  } = usePropertiesData(itemIds);

  const { columns, data, isLoading, isError, refetch } = useTimelineData(
    timeOption,
    selectedMetricKeys,
    selectedDimensionApi,
    WidgetType.CHART_BUILDER,
    [dimension, metrics],
  );

  const extendDimensionInfo = useExtendDimensionInfo();

  useEffect(() => {
    const subBrands = partner.subpartners?.length ? partner.subpartners : [partner];

    if (currentWidget && columns.length && !startedEditing) {
      const currentWidgetProps = currentWidget.props as TimelineWidgetProps;
      if (currentWidgetProps.dimension && propertiesData) {
        setDimension(
          extendDimensionInfo(currentWidgetProps.dimension, locales, subBrands, propertiesData),
        );
      }
      if (currentWidgetProps.metrics) {
        setMetrics(
          (currentWidgetProps.metrics as any).map(
            (metric: Metric) => columns?.find(col => col.key === metric.id) || {},
          ),
        );
      }
    }
  }, [currentWidget, columns, locales, startedEditing, partner, propertiesData]);

  useEffect(() => {
    const calculateHeight = () => {
      const chartContainerHeight = window.innerHeight - 400;
      setChartHeight(chartContainerHeight);
    };
    calculateHeight();
    window.addEventListener('resize', calculateHeight);
    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);

  const getMetricColumns = (colMetrics: MetricColumn[]) =>
    columns.filter(column => colMetrics?.find(metric => metric.name === column.name));

  if (columns.length) {
    if (!viewColumns.has(dimension?.name)) {
      setViewColumns(extendReadonlyMap(viewColumns, [dimension?.name, columns]));
    }
    const newMetrics = getMetricColumns(metrics);
    if (!areArraysEqual(metrics, newMetrics, (a, b) => a.key === b.key)) {
      setMetrics(newMetrics);
    }
  }

  function extendReadonlyMap<K, V>(
    oldMap: ReadonlyMap<K, V>,
    pairToAdd: [K, V],
  ): ReadonlyMap<K, V> {
    return new Map(Array.from(oldMap.entries()).concat([pairToAdd]));
  }

  const handleWidgetMenuItemClick = (index: number) => menuItems[index]?.action?.();

  const saveAsWidget = async () => {
    setPreviousChartNameAndDashboard({
      name: saveAsOptions.name,
      dashboard: saveAsOptions.dashboard,
    });
    let widgetName = saveAsOptions.name;

    const alreadyExists = currentDashboard?.widgets.some((widget: DashboardWidget) => {
      return (widget as TimelineWidget).props.name === saveAsOptions.name;
    });
    if (alreadyExists) {
      widgetName = generateNewWidgetName(widgetName, currentDashboard?.widgets || []);
      setSaveAsOptions(prev => ({ ...prev, name: widgetName }));
    }

    setIsSavingNewChart(false);
    setUnsavedChangesModalOpen(false);
    const isSuccessful = await save({
      name: widgetName,
      metrics: selectedMetricKeys.map(metricKey => ({ id: metricKey })),
      dimension: selectedDimensionApi,
    });
    if (isSuccessful) {
      setIsSaved(true);
      TrackerService.track(CHART_BUILDER_CREATE_SUCCESS, {
        chartType: CHART_TYPE,
        dimension,
        metrics,
        aggregation: timeOption,
      });
      showSnackbarMessage({
        id: 'save-as-success',
        message: `${widgetName} saved in ${saveAsOptions.dashboard?.name}`,
        type: 'success',
      });
    }
  };

  const saveWidget = () => {
    if (currentWidget && metrics.length) {
      setIsSaving(true);
      edit({
        metrics: metrics.map((metric, index) => ({
          id: metric.key,
          color: index ? Color.Green300 : Color.Blue400,
        })),
        dimension: selectedDimensionApi,
      })
        .then(() => {
          TrackerService.track(CHART_BUILDER_EDIT_SUCCESS, {
            chartType: CHART_TYPE,
            dimension,
            metrics,
            aggregation: timeOption,
          });
          setSaveButtonTitle(translate('analytics_chart_builder_unsaved_changes_modal_saved'));
          showSnackbarMessage({
            id: 'save-success',
            message: `${saveAsOptions.name} saved in ${saveAsOptions.dashboard?.name}`,
            type: 'success',
          });
        })
        .finally(() => setIsSaving(false));
    } else {
      setIsSavingNewChart(true);
    }
  };

  const renameChart = async (newName: string) => {
    await edit({
      name: newName,
      metrics: metrics.map((metric, index) => ({
        id: metric.key,
        color: index ? Color.Green300 : Color.Blue400,
      })),
    });
    setSaveAsOptions({
      name: newName,
      dashboard: saveAsOptions.dashboard,
    });
    setIsRenaming(false);
  };

  const deleteWidget = () => {
    deleteChartWidget(saveAsOptions.name);
    TrackerService.track(CHART_BUILDER_DELETE, {
      chartType: CHART_TYPE,
      dimension,
      metrics,
      aggregation: timeOption,
      deletedFrom: 'Chart builder',
    });
  };

  const exitChartBuilder = () => {
    if (metrics.length && (!isSaved || hasUnsavedChanges()))
      return setUnsavedChangesModalOpen(true);
    onClose();
  };

  const restorePreviousChartName = () => {
    let chartState = previousChartNameAndDashboard;
    if (isSaved && currentWidget && 'name' in currentWidget.props) {
      chartState = {
        name: currentWidget?.props.name as string,
        dashboard: previousChartNameAndDashboard.dashboard,
      };
    }
    setSaveAsOptions(prevState => ({
      ...prevState,
      ...chartState,
    }));
    setIsSavingNewChart(false);
  };
  const isBMorPGDimensionSelected =
    dimension && (isBidModifierDimension(dimension?.name) || dimension?.name === 'propertyGroups');

  const bidModifierToolTipValue = translate('dashboard_chart_builder_bid_modifier_tooltip');

  const dimensionToolTip: { [key: string]: string | ReactNode } = {
    propertyGroups: translateHtml('dashboard_chart_builder_property_group_tooltip'),
  };
  BID_MODIFIER_DIMENSIONS.forEach(key => {
    dimensionToolTip[key] = bidModifierToolTipValue;
  });

  const menuItems: MenuItem[] = [
    {
      title: translate('analytics_chart_builder_menu_rename'),
      icon: 'PencilIcon',
      disabled: !isSaved,
      action: () => isSaved && setIsRenaming(true),
    },
    {
      title: translate('analytics_chart_builder_unsaved_changes_modal_save_as') + '...',
      icon: 'SaveAsIcon',
      disabled: !metrics.length,
      action: () => metrics.length && setIsSavingNewChart(true),
    },
    {
      title: translate('analytics_chart_builder_menu_move'),
      icon: 'MoveIcon',
      disabled: !isSaved || allDashboards.length === 1,
      action: () => isSaved && moveChartModal.open(),
    },
    {
      title: translate('analytics_chart_builder_menu_delete'),
      icon: 'DeleteLineDarkIcon',
      disabled: !isSaved,
      action: () => isSaved && setIsRemovingChart(true),
    },
    {
      title: 'Export chart (.csv)',
      icon: 'ExportResource',
      disabled: metrics.length === 0 || isLoading || isError,
      action: () =>
        exportToCsv({ data, metrics, dimension, fileName: saveAsOptions.name }).catch(error => {
          console.error(error);
          showSnackbarMessage({
            id: 'export-failed',
            message: translate('analytics_chart_builder_failed_to_export_chart'),
            type: 'error',
          });
        }),
    },
  ];

  return (
    <>
      {isUnsavedChangesModalOpen && (
        <UnsavedChangesModal
          onDiscard={onClose}
          onContinue={() => setUnsavedChangesModalOpen(false)}
          onSaveAs={() => {
            setIsSavingNewChart(true);
            setUnsavedChangesModalOpen(false);
          }}
          onSave={() => {
            saveWidget();
            setUnsavedChangesModalOpen(false);
          }}
          isSaved={isSaved}
        />
      )}
      <PreventNavigationPrompt />
      {isSavingNewChart && (
        <SaveAsModal
          isSavingNewChart={isSavingNewChart}
          saveAsOptions={saveAsOptions}
          allDashboards={allDashboards}
          setSaveAsOptions={setSaveAsOptions}
          restorePreviousChartName={restorePreviousChartName}
          saveAsWidget={saveAsWidget}
          translate={translate}
        />
      )}
      {isRemovingChart && (
        <DeleteChartModal
          chartName={saveAsOptions.name}
          openDeleteChart={setIsRemovingChart}
          confirmDeleteChart={deleteWidget}
        />
      )}
      <div className="u-padding-w--gutter u-padding-top--gutter u-margin-bottom--gutter">
        <div
          className={cs(
            'c-chart-builder u-display--flex u-flex-direction--column u-box-shadow u-background--white u-border-radius',
          )}
        >
          <div className="u-display--flex u-justify-content--space-between u-padding--small u-padding-horizontal--s-large u-border-bottom u-border--juri-lightest u-align-items--center">
            <div className="u-display--flex u-align-items--center c-chart-builder--title">
              <div className="u-display--flex">
                <ArrowIcon
                  className="u-transform--rotate-180deg u-margin-left--medium close-icon"
                  onClick={exitChartBuilder}
                />
              </div>
              <TooltipWrapper
                message={saveAsOptions.name}
                position={TooltipPosition.TOP_TRAILING}
                hideTooltip={!isTextOverflowing(titleRef.current)}
                classNameParent="hide-long-text--tooltip"
                className="hide-long-text--tooltip__position"
              >
                <h3
                  className="u-margin-left--medium u-display--inline-flex"
                  style={{ width: '97%' }}
                >
                  <span className="u-display--flex u-align-items--center u-color--juri-light u-font-weight--normal">
                    {currentDashboard?.name} / &nbsp;
                  </span>
                  <span className="hide-long-text" ref={titleRef}>
                    {isRenaming ? (
                      <WidgetTitleEdit
                        initialValue={saveAsOptions.name}
                        onCancel={() => setIsRenaming(false)}
                        onSave={renameChart}
                      />
                    ) : (
                      saveAsOptions.name
                    )}
                  </span>
                </h3>
              </TooltipWrapper>
            </div>
            <div className="u-display--flex">
              {isSaved ? (
                <Button
                  className="u-margin-right--gutter"
                  message={
                    hasUnsavedChanges()
                      ? translate('analytics_chart_builder_unsaved_changes_modal_save')
                      : saveButtonTitle
                  }
                  loadingMessage={translate('analytics_chart_builder_unsaved_changes_modal_saving')}
                  isLoading={isSaving}
                  onClick={saveWidget}
                  disabled={!metrics.length || !hasUnsavedChanges()}
                />
              ) : (
                <Button
                  className="u-margin-right--gutter"
                  message={translate('analytics_chart_builder_unsaved_changes_modal_save_as')}
                  loadingMessage={translate('analytics_chart_builder_unsaved_changes_modal_saving')}
                  isLoading={isSaving}
                  onClick={() => {
                    setIsSavingNewChart(true);
                    setPreviousChartNameAndDashboard({
                      name: saveAsOptions.name,
                      dashboard: saveAsOptions.dashboard,
                    });
                  }}
                  disabled={!metrics.length}
                />
              )}
              <WidgetMenu
                menuItems={menuItems.map((item, index) => (
                  <WidgetMenuItem
                    title={item.title}
                    icon={item.icon}
                    disabled={item.disabled}
                    key={`${item.title}-${index}`}
                  />
                ))}
                onSelectMenuItem={handleWidgetMenuItemClick}
                className="u-display--flex u-align-items--center u-margin-right--gutter"
              />
              <div className="u-display--flex u-margin-right--gutter">
                <VerticalDivider />
              </div>
              <div className="u-display--flex u-justify-content--center u-align-items--center">
                <CloseIcon onClick={exitChartBuilder} className="u-cursor--pointer" />
              </div>
            </div>
          </div>
          <div className="u-display--flex u-height--100" style={{ flex: 1 }}>
            <div
              className="u-display--flex u-flex-direction--column u-padding--large u-border-right u-border--juri-lightest"
              style={{ flex: 1 }}
            >
              <div className="u-display--flex u-flex-direction--column u-border-bottom u-border--juri-lightest">
                <h4>{translate('analytics_chart_builder_chart_type')}</h4>
                <select className="u-margin-bottom--large u-height--36px">
                  <option>{translate('add_widget_item_menu__timeline-title')}</option>
                </select>
              </div>
              <MultiFolderSelect
                title={capitalizeFirstLetter(translate('analytics_chart_builder_dimension'))}
                applySelection={(dim: SelectedDimension) => {
                  setStartedEditing(true);
                  setDimension(dim);
                }}
                dimension={dimension}
                metrics={metrics}
                columns={columns}
              />
              <MultiFolderSelect
                title={capitalizeFirstLetter(translate('analytics_chart_builder_metric'))}
                applySelection={(selectedMetrics: MetricColumn[]) => {
                  setStartedEditing(true);
                  setMetrics(getMetricColumns(selectedMetrics));
                }}
                dimension={dimension}
                metrics={metrics}
                columns={columns}
              />
            </div>
            <div
              className="u-display--flex u-flex-direction--column u-padding--gutter"
              style={{ flex: 4 }}
            >
              <div className="u-display--flex u-padding--large">
                <div className="u-margin-right--large">
                  <select className="u-height--36px">
                    <option>{translate('analytics_chart_builder_chart_variant_line')}</option>
                  </select>
                </div>
                <ChartBuilderPeriodPicker
                  timeOption={timeOption}
                  setTimeOption={setTimeOption}
                  dimensionName={dimension?.name}
                />
                {isBMorPGDimensionSelected && (
                  <div>
                    <span style={{ verticalAlign: 'text-bottom' }}>Important Data Details</span>
                    <TooltipWrapper
                      position={TooltipPosition.TOP_LEADING}
                      noTinyPaddingTop={true}
                      message={dimensionToolTip[dimension?.name as string]}
                    >
                      <ExclamationCircleFillDarkIcon
                        className="u-color--juri-very-light u-margin-left--small u-margin-top--medium"
                        width={14}
                        height={14}
                      />
                    </TooltipWrapper>
                  </div>
                )}
              </div>

              <div
                className={cs(
                  'u-display--flex u-justify-content--center u-align-items--center u-flex-wrap--wrap u-width--100',
                )}
                style={{ flex: 1 }}
              >
                {!metrics || !metrics.length ? (
                  <GetStartedMessage missingItems={missingItems} />
                ) : isLoading || isPropertiesLoading || !viewColumns.has(dimension?.name) ? (
                  <div className="c-widget-loading">
                    <Loading
                      isWhite={false}
                      isDark={true}
                      className="c-widget__loading u-margin-top--huge"
                    />
                  </div>
                ) : isError || isPropertiesError ? (
                  <ButtonRetry message={translate('dashboard_widget_error')} onClick={refetch} />
                ) : (
                  viewColumns.get(dimension?.name)!!.length === 0 ||
                  (data.length === 0 && (
                    <div className="u-position--relative chart-builder--no-widget">
                      <WidgetNoData />
                    </div>
                  ))
                )}
                {metrics && metrics.length > 0 && !isLoading && (
                  <div
                    className="u-display--grid u-align-content--stretch"
                    style={{ margin: 'auto 48px 24px 48px', width: '100%', height: '99%' }}
                  >
                    <TimeLineChart
                      isFullScreen={true}
                      widgetProps={widgetProps()}
                      data={data}
                      columns={viewColumns.get(dimension?.name)!!}
                      isFetchingData={false}
                      isError={false}
                      widgetType={WidgetType.CHART_BUILDER}
                      leftYAxisLabel={metrics[0]?.name}
                      rightYAxisLabel={metrics[1]?.name}
                      chartHeight={chartHeight}
                      dateRangePeriod={globalDateRangePeriod || ({} as DateRange)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// This is a placeholder component
const GetStartedMessage = ({ missingItems }: { missingItems: string[] }) => (
  <h4>
    {`${translate('analytics_chart_builder_get_started_add')} `}
    {missingItems.map((item: string, index: number) => {
      if (index > 0 && index % 2 !== 0) {
        return (
          <span key={item}>
            <span>{` ${translate('analytics_chart_builder_get_started_and_or')} `}</span>
            <span className="u-color--blue">{item}</span>
          </span>
        );
      }
      return (
        <span key={item} className="u-color--blue">
          {item}
        </span>
      );
    })}
    {` ${translate('analytics_chart_builder_get_started')}`}
  </h4>
);
