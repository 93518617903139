export interface Reports {
  partnerId: number;
  partnerFolder: string;
  reports: ReportsType[];
}

export type ReportsType = {
  id: number;
  name: string;
  frequency: ReportFrequency;
  supportedFrequencies: ReportFrequency[];
  subFolder: string;
  isActive: boolean;
  partnerFolder: string;
  documentationUri: string;
};

export type ReportTypeDetail = {
  sourceFileAwsPath: string;
  reportName?: string;
  created: Date;
  jobId: number;
};

export enum ReportFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}
