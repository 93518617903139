import { FC, ReactNode, useRef, useState } from 'react';
import { ReportDownloadCellProps } from './report-download-cell.types';
import { translate, TranslationKey } from '../../helpers/utils';
import styles from './report-download-cell.module.css';
import { Icon } from '../icon';
import { FileFormat, fromTo } from '../../models';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { Color } from '../../constants';
import { Loading } from '../loading';
import cn from 'classnames';
import { TooltipPosition, TooltipWrapper } from '../tooltip-wrapper';
import trackerService from '../../services/tracker/tracker-service';

export const ReportDownloadCell: FC<ReportDownloadCellProps> = ({
  displayValue,
  onClick,
  hasValue,
  fileFormats,
  columnName,
}) => {
  const downloadSelectorRef = useRef<HTMLOListElement>(null);
  const [showDownloadSelector, setShowDownloadSelector] = useState(false);
  const [loading, setLoading] = useState(false);
  const tableCellRef = useRef<HTMLTableCellElement>(null);

  const handleButtonClick = (fileFormat: FileFormat) => {
    if (columnName === 'Low-bid Active') {
      const campaign = tableCellRef.current?.parentElement?.parentElement?.children[1]?.textContent;
      const biddingType =
        tableCellRef.current?.parentElement?.parentElement?.children[2]?.textContent;

      trackerService.track('Low-bid Active column - export button', {
        campaign,
        biddingType,
      });
    }
    setShowDownloadSelector(false);
    setLoading(true);
    onClick(fileFormat).then(() => setLoading(false));
  };

  const downloadCsvFileOption = () => {
    const csvFileFormat = FileFormat.CSV;
    return (
      <li
        className={styles.downloadSelectorItem}
        key={csvFileFormat}
        onClick={() => handleButtonClick(csvFileFormat)}
      >
        <Icon type="ExportResource" fill={Color.Grey700} className={styles.downloadSelectorIcon} />
        {translate(EXPORT_DROPDOWN_OPTION_KEY[csvFileFormat])}
      </li>
    );
  };

  const downloadExcelFileOption = () => {
    // @see: https://superuser.com/a/366473
    const maxAllowedRowsForExcelFile = 1024 * 1024;

    const excelFileFormat = FileFormat.EXCEL;
    const amountOfDataEntries = +displayValue;
    const amountOfRowsWithHeader = amountOfDataEntries + 1;
    const isDisabled = amountOfRowsWithHeader > maxAllowedRowsForExcelFile;
    const tooltipMessage = isDisabled ? translate('export_report_file_too_large') : undefined;
    const iconColor = isDisabled ? Color.Grey500 : Color.Grey700;
    const downloadExcelFile = isDisabled ? undefined : () => handleButtonClick(excelFileFormat);
    return (
      <li
        className={cn(styles.downloadSelectorItem, {
          [styles.disabled]: isDisabled,
        })}
        key={excelFileFormat}
        onClick={downloadExcelFile}
      >
        <TooltipWrapper message={tooltipMessage} position={TooltipPosition.LEADING_CENTER}>
          <Icon type="ExportResource" fill={iconColor} className={styles.downloadSelectorIcon} />
          {translate(EXPORT_DROPDOWN_OPTION_KEY[excelFileFormat])}
        </TooltipWrapper>
      </li>
    );
  };

  const EXPORT_DROPDOWN_OPTION_FN: fromTo<FileFormat, () => ReactNode> = {
    [FileFormat.CSV]: downloadCsvFileOption,
    [FileFormat.EXCEL]: downloadExcelFileOption,
  };

  useOnClickOutside(downloadSelectorRef, e => {
    e.stopPropagation();
    setShowDownloadSelector(false);
  });

  const multipleFileFormats = fileFormats.length > 1;

  const downloadSelector = () => (
    <ol ref={downloadSelectorRef} className={styles.downloadSelector}>
      {fileFormats.map(fileFormat => EXPORT_DROPDOWN_OPTION_FN[fileFormat]())}
    </ol>
  );

  if (!hasValue) return <span className={styles.cell}>{displayValue}</span>;

  if (loading) return <Loading className={styles.loading} />;

  return (
    <span className={styles.cell} ref={tableCellRef}>
      <span>{displayValue}</span>
      <button
        onClick={e => {
          if (multipleFileFormats) setShowDownloadSelector(true);
          e.stopPropagation();
        }}
        className={styles.downloadButton}
      >
        <Icon type="ExportResource" className={styles.downloadIcon} />
      </button>
      {multipleFileFormats && showDownloadSelector && downloadSelector()}
    </span>
  );
};

const EXPORT_DROPDOWN_OPTION_KEY: fromTo<FileFormat, TranslationKey> = {
  [FileFormat.CSV]: 'export_report_csv_option',
  [FileFormat.EXCEL]: 'export_report_excel_option',
};
