import React, { useState } from 'react';
import { Props } from './general-information-panel.types';
import './general-information-panel.css';
import { BaseNotification, BiddingActionType } from '../../../../models';
import { Loading } from '../../../../components/loading';
import { Notification } from '../../../../components/notification';
import {
  TIMEOUT_NOTIFICATION,
  ANALYTICS_ROUTE,
  DETAILED_HOTEL_VIEW_PREFIX,
} from '../../../../constants';
import { HotelDetailInformation } from '../hotel-detail-information';
import { Redirect } from 'react-router';
import { HotelViewControlPanel } from '../../../../components/hotel-control-panel';
import { useLocation } from 'react-router-dom';

export const GeneralInformationPanel: React.FunctionComponent<Props> = ({
  hotel,
  isLoading,
  onUpdate,
}) => {
  const [notification, setNotification] = useState<BaseNotification | undefined>(undefined);
  const [isRedirectActive, setRedirectActive] = useState(false);
  const location = useLocation();

  const onShowNotification = (notificationTmp: BaseNotification) => {
    setNotification(notificationTmp);
    setTimeout(onCloseNotification, TIMEOUT_NOTIFICATION);
  };
  const onCloseNotification = () => setNotification(undefined);

  return (
    <>
      {isRedirectActive && (
        <Redirect to={{ pathname: ANALYTICS_ROUTE, state: { from: location.pathname } }} />
      )}
      {isLoading ? (
        <div className="u-background--blue-very-light">
          <Loading isWhite={true} className="u-padding--gutter" />
        </div>
      ) : (
        <section>
          <HotelViewControlPanel
            hotels={hotel !== undefined ? [hotel] : []}
            areAllRowsSelected={false}
            totalProperties={1}
            onUpdateTable={onUpdate}
            onShowNotification={onShowNotification}
            onClickLeading={() => setRedirectActive(true)}
            isShowingDetail={true}
            trackingPrefix={DETAILED_HOTEL_VIEW_PREFIX}
            biddingActionType={BiddingActionType.CustomEditCpc}
          />
          {hotel !== undefined && <HotelDetailInformation hotel={hotel} />}
          {notification && (
            <Notification notification={notification} onClose={onCloseNotification} />
          )}
        </section>
      )}
    </>
  );
};
