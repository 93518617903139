import { Report, ReportDefinition, Subscription } from '../report.types';

export function getDisplayName(report: Report): string;
export function getDisplayName(reportDefinition: ReportDefinition): string;
export function getDisplayName(subscription: Subscription): string;

export function getDisplayName(value: Report | ReportDefinition | Subscription): string {
  const subscription = value as Subscription;
  if (subscription.reportDefinition?.report?.name) return getSubscriptionDisplayName(subscription);

  const definition = value as ReportDefinition;
  if (definition.report?.name) return getReportDefinitionDisplayName(definition);

  const report = value as Report;
  if (report.name) return getReportDisplayName(report);

  return 'Unknown';
}

function getReportDisplayName(report: Report): string {
  return report.displayName;
}

function getReportDefinitionDisplayName(reportDefinition: ReportDefinition): string {
  const definitionName = reportDefinition.name;
  const version = getVersionString(reportDefinition);
  if (!version) return definitionName;
  return `${definitionName} ${version}`;
}

function getSubscriptionDisplayName(subscription: Subscription): string {
  const definitionName = getReportDefinitionDisplayName(subscription.reportDefinition);
  if (!subscription.isCombined) return definitionName;
  return `${definitionName} (combined)`;
}

function getVersionString(reportDefinition: ReportDefinition): string {
  const { majorVersion, minorVersion } = reportDefinition;
  return `v${majorVersion}.${minorVersion}`;
}
