import { FunctionComponent } from 'react';
import { BidModifierDimensionMenu } from './bid-modifier-dimension-menu';
import { MultiLevelMenu } from '../../../components/multi-level-menu';
import { dimensionTitleTranslationKey, getBmDimensionBreakouts } from './bm-dimension-breakouts';
import { BidModifierProps, DimensionWithLabel } from './bid-modifier-dimension-menu.types';
import { translate } from '../../../helpers/utils';

export const BidModifierDimension: FunctionComponent<BidModifierProps> = ({
  applyFilter,
  previouslySelectedBreakouts,
}) => {
  const items = getBmDimensionBreakouts().map(([dimension, breakouts]) => ({
    key: dimension,
    label: translate(dimensionTitleTranslationKey(dimension)),
    subMenu: [
      {
        key: dimension,
        label: (
          <BidModifierDimensionMenu
            onApply={(selectedOptions: DimensionWithLabel) => applyFilter(selectedOptions)}
            dimension={dimension}
            availableBreakouts={breakouts}
            previouslySelectedBreakouts={previouslySelectedBreakouts}
          />
        ),
      },
    ],
  }));
  if (!previouslySelectedBreakouts) return <MultiLevelMenu items={items} />;
  const selectedBM = items.find(
    item =>
      item.label === translate(dimensionTitleTranslationKey(previouslySelectedBreakouts.label)),
  );
  return <>{selectedBM?.subMenu[0].label}</>;
};
