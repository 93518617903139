import React, { ChangeEvent, FC, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import ArrowIcon from 'tcp-react-icons/lib/ArrowIcon';
import SearchLineDarkIcon from 'tcp-react-icons/lib/SearchLineDarkIcon';
import TickThinDarkIcon from 'tcp-react-icons/lib/TickThinDarkIcon';
import { Partner } from '../../../../models';
import { InputIcon } from '../../../../components/input-icon/input-icon';
import './partner-selector.css';
import { Popover } from '../../../../components/popover/popover';
import { Props } from './partner-selector.types';
import {
  EVENT_KEYDOWN,
  EVENT_MOUSEDOWN,
  KEYBOARD_ARROW_DOWN,
  KEYBOARD_ARROW_UP,
  KEYBOARD_EVENT_ENTER,
  KEYBOARD_EVENT_ESCAPE,
} from '../../../../constants/constants';
import { PopoverDirection } from '../../../../components/popover';

const DEFAULT_SELECTION = 0;

export const PartnerSelector: FC<Props> = ({ partners, selectedValue, onChange }) => {
  const dropdownRef: RefObject<HTMLDivElement> = useRef(null);
  const inputRef: RefObject<HTMLInputElement> = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [preSelected, setPreSelected] = useState(DEFAULT_SELECTION);
  const partnerSelected = partners.find(partner => partner.partnerId === selectedValue);
  const filteredPartners = partners.filter(filterByNameOrId(searchText));
  const sortedPartners = filteredPartners.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
  );

  const handleArrowKeys = useCallback(
    (event: KeyboardEvent) => {
      const filteredList = sortedPartners.filter(filterByNameOrId(searchText));
      const maxLength = filteredList.length - 1;

      switch (event.key) {
        case KEYBOARD_ARROW_DOWN:
          setPreSelected((oldPreSelected: number) =>
            oldPreSelected + 1 > maxLength ? maxLength : oldPreSelected + 1,
          );
          event.preventDefault();
          break;
        case KEYBOARD_ARROW_UP:
          setPreSelected((oldPreSelected: number) =>
            oldPreSelected - 1 < DEFAULT_SELECTION ? DEFAULT_SELECTION : oldPreSelected - 1,
          );
          event.preventDefault();
          break;
        case KEYBOARD_EVENT_ENTER:
          event.preventDefault();
          if (!filteredList[preSelected]) return;

          setSearchText('');
          setPreSelected(DEFAULT_SELECTION);
          setIsOpen(false);
          onChange(filteredList[preSelected].partnerId);

          break;

        case KEYBOARD_EVENT_ESCAPE:
          event.preventDefault();
          setSearchText('');
          setPreSelected(DEFAULT_SELECTION);
          setIsOpen(false);
          break;
      }
    },
    [onChange, preSelected, searchText, sortedPartners],
  );

  const clickHandler = () => {
    setIsOpen(prev => !prev);
    setPreSelected(DEFAULT_SELECTION);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    event.preventDefault();
    const target = event.target as Node;

    if (dropdownRef.current && !dropdownRef.current.contains(target)) {
      setIsOpen(false);
    }
  }, []);

  const onSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPreSelected(DEFAULT_SELECTION);
  };

  useEffect(() => {
    isOpen && inputRef.current && inputRef.current.focus();

    isOpen && document.addEventListener(EVENT_MOUSEDOWN, handleClickOutside);
    const dropdown = dropdownRef.current;

    dropdown && dropdown.addEventListener(EVENT_KEYDOWN, handleArrowKeys);

    return () => {
      document.removeEventListener(EVENT_MOUSEDOWN, handleClickOutside);
      dropdown && dropdown.removeEventListener(EVENT_KEYDOWN, handleArrowKeys);
    };
  }, [handleClickOutside, preSelected, searchText, isOpen, handleArrowKeys]);

  return (
    <div
      ref={dropdownRef}
      onClick={clickHandler}
      className="c-partnerSelector u-user-select--none u-padding--small u-display--flex u-align-items--center u-cursor--pointer u-position--relative u-margin-right--gutter"
    >
      <span data-qa="partner-selector" className="u-font-size--t-small u-margin-right--gutter">
        {partnerSelected?.name ?? 'Select a partner'}
      </span>
      <ArrowIcon
        className={cs('u-transition--quick', {
          'u-transform--rotate-90deg': !isOpen,
          'u-transform--rotate-minus-90deg': isOpen,
        })}
      />
      {isOpen && (
        <Popover direction={PopoverDirection.BOTTOM_TRAILING}>
          <div
            data-qa="container-partner-selector"
            className="c-partnerSelector__menu u-background--white u-padding--small"
          >
            <div onClick={e => e.stopPropagation()}>
              <InputIcon
                className="c-input c-input--big u-width--100 u-border-radius"
                placeholder="Search partner"
                icon={SearchLineDarkIcon}
                onChange={onSearchHandler}
                value={searchText}
                ref={inputRef}
              />
            </div>
            <ul className="c-partnerSelector__menu__list">
              {sortedPartners.map((partner, index) => {
                return (
                  <li
                    data-qa={`partner-selector-${partner.partnerId}`}
                    onClick={() => {
                      onChange(partner.partnerId);
                      setSearchText('');
                    }}
                    key={partner.partnerId}
                    className={cs('u-padding--t-medium u-border--juri-lightest u-border-bottom', {
                      'u-background--juri-lightest': preSelected === index,
                    })}
                  >
                    <p className="u-display--flex u-align-items--center">
                      <span>
                        {partner.name} ({partner.partnerId})
                      </span>
                      {partner.partnerId === selectedValue && (
                        <span className="u-margin-left--auto u-color--juri-light u-display--flex u-align-items--center">
                          <TickThinDarkIcon /> Selected
                        </span>
                      )}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </Popover>
      )}
    </div>
  );
};

const filterByNameOrId = (searchText: string) => (partner: Partner) =>
  partner.name.toLowerCase().includes(searchText.toLowerCase()) ||
  partner.partnerId.toString() === searchText.toLowerCase() ||
  partner.subpartners.some(p => p.partnerId.toString() === searchText.toLowerCase());
