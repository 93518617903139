import React from 'react';
import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { translate } from '../../helpers/utils';

const PreventNavigationPrompt = () => {
  const message = translate('prevent_navigation_prompt_message');
  useEffect(() => {
    const preventUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // NOTE: This message isn't used in modern browsers, but is required
      event.returnValue = message;
    };

    window.addEventListener('beforeunload', preventUnload);

    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, []);
  return <Prompt when={true} message={message} />;
};
export default PreventNavigationPrompt;
