import { WarningRowValidationRule } from '../row-validation-rule';

export type MaxCpcValueWarningRuleOptions = {
  fieldName: string;
  localeFieldName: string;
  bidCapsByLocale: Record<string, number>;
  defaultMax: number;
};

export class MaxCpcValueWarningRule extends WarningRowValidationRule {
  constructor(private readonly options: MaxCpcValueWarningRuleOptions) {
    super();
  }

  getWarning(row: Record<string, string>): string | null {
    const { fieldName, localeFieldName, bidCapsByLocale, defaultMax } = this.options;
    const bid = Number(row[fieldName]);
    const locale = row[localeFieldName];
    const maxCpc = bidCapsByLocale[locale.toLowerCase()] ?? defaultMax;
    if (bid > maxCpc) return `Field ${fieldName} should be at most ${maxCpc}`;
    return null;
  }
}
