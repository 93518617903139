import { WebSocketHelper } from './web-socket-helper';

const url = 'notifications';

export class NotificationWebsocket extends WebSocketHelper {
  private static instance: NotificationWebsocket;

  public static getInstance() {
    if (!NotificationWebsocket.instance) {
      NotificationWebsocket.instance = new NotificationWebsocket();
    }

    return NotificationWebsocket.instance;
  }

  constructor() {
    super(url);
  }
}
