import { FC, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { identity } from 'lodash';

type ResolveFn<T> = (result: T) => void;

export type ResolvableModalComponent<R, D = unknown> = FC<{ resolve: ResolveFn<R>; data?: D }>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export function useIntermediateDialog<R, D = unknown>(Component: ResolvableModalComponent<R, D>) {
  const [resolverFn, setResolverFn] = useState<ResolveFn<R>>(identity);
  const [inputData, setInputData] = useState<D>();

  const [showModal, hideModal] = useModal(
    () => <Component resolve={resolverFn} data={inputData} />,
    [resolverFn, inputData],
  );

  function show(data: D): Promise<R> {
    setInputData(data);
    return new Promise(resolve => {
      setResolverFn(() => (result: R) => {
        resolve(result);
        hideModal();
        setInputData(undefined);
      });
      showModal();
    });
  }

  return show;
}
