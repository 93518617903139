import { AxiosError } from 'axios';
import { NotificationLevel } from '../../../constants';
import { showNotification } from '../../../services/notification-service';

export function showError(error: any) {
  console.error(error);
  showNotification({
    level: NotificationLevel.ERROR,
    message: getErrorMessage(error),
  });
}

const defaultMessage = 'Something went wrong';
export function getErrorMessage(error: any): string {
  const axiosError = error as AxiosError | undefined;
  const responseStatus = axiosError?.response?.status;
  if (responseStatus && responseStatus >= 500) {
    return defaultMessage;
  }
  if (
    axiosError?.response?.data &&
    typeof axiosError.response.data === 'object' &&
    'message' in axiosError.response.data
  ) {
    return axiosError.response.data.message as string;
  }
  return error?.message || defaultMessage;
}
