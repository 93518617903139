import { FC, useState } from 'react';
import { Row } from '../../../components/grid';
import styles from './property-group-cell.module.css';
import cn from 'classnames';
import { EditAliasDialog } from './edit-alias-dialog';
import { useSingleSelectedLocale } from '../../../hooks/use-selected-locales/use-selected-locales';
import { useSingleSelectedPartner } from '../../../hooks/use-selected-partner';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';
import { translate } from '../../../helpers/utils';

export const PropertyGroupCell: FC<{ row: Row }> = ({ row }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const selectedLocale = useSingleSelectedLocale();
  const selectedPartner = useSingleSelectedPartner();

  return (
    <span className={styles.propertyGroupCell}>
      <b>{row.group}</b>
      {row.alias ? (
        <span title={row.alias} className={styles.alias}>
          {row.alias}
        </span>
      ) : null}
      <EditAliasLink
        isDisabled={!selectedLocale || !selectedPartner}
        alias={row.alias}
        onClick={() => setIsDialogOpen(true)}
      />
      {isDialogOpen ? (
        <EditAliasDialog
          onClose={() => setIsDialogOpen(false)}
          initialValue={row.alias}
          propertyGroupId={row.property_group}
          partner={selectedPartner!}
          localeCode={selectedLocale!}
        />
      ) : null}
    </span>
  );
};

type EditAliasLinkProps = {
  alias?: string;
  onClick: VoidFunction;
  isDisabled: boolean;
};

const EditAliasLink: FC<EditAliasLinkProps> = ({ alias, onClick, isDisabled }) => {
  const link = (
    <a
      className={cn(styles.editAliasLink, { [styles.disabled]: isDisabled })}
      onClick={e => {
        e.stopPropagation();
        if (isDisabled) return;
        onClick();
      }}
    >
      {alias ? 'Edit' : '+ Add alias'}
    </a>
  );

  if (isDisabled) {
    return (
      <TooltipWrapper
        noTinyPaddingTop
        message={translate('property_group_alias_disabled_tooltip')}
        position={TooltipPosition.TRAILING_CENTER}
        classNameParent={styles.editAliasLink}
      >
        {link}
      </TooltipWrapper>
    );
  }

  return link;
};
