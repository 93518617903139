import {
  Report,
  ReportDefinition,
  SftpConfig,
  Subscription,
} from '../../../views/reports/report.types';
import { RequestHelper } from '../request-helper';
import { ReportArtifact } from './types';

const URL_SUBSCRIPTIONS = 'reports/subscriptions';
const URL_DEFINITIONS = 'reports/definitions';
const URL_SFTP_CONFIG = 'reports/sftp-config';
const URL_REPORTS = 'reports/report';
const URL_FETCH_REPORT_ARTIFACTS_LIST = 'reports/artifacts';

export interface CreateSubscriptionRequest {
  reportDefinitionId: number;
  partnerId: number;
  isCombined: boolean;
  deliverEmptyFile: boolean;
}

export interface FetchReportArtifactsListParams {
  partnerId: number;
  reportName: string;
  fromDate: Date | string;
  toDate: Date | string;
  reportDefinitionId?: number;
  isCombined?: boolean;
}

class ReportsApiHelper extends RequestHelper {
  async fetchSubscriptions(partnerId: number): Promise<Subscription[]> {
    const { data } = await this.getRequest(URL_SUBSCRIPTIONS, {
      params: {
        partnerIds: partnerId,
        includeReportDefinition: true,
      },
    });
    return data;
  }

  async createSubscription(request: CreateSubscriptionRequest) {
    await this.postRequest(URL_SUBSCRIPTIONS, request);
  }

  async deleteSubscription(subscriptionId: number) {
    await this.deleteRequest(`${URL_SUBSCRIPTIONS}/${subscriptionId}`, null);
  }

  async updateSftpFolderPath(partnerId: number, sftpFolderPath: string) {
    await this.putRequest(`${URL_SFTP_CONFIG}/${partnerId}`, {
      folderName: sftpFolderPath,
    });
  }

  async fetchSftpConfig(partnerId: number): Promise<SftpConfig> {
    const { data } = await this.getRequest(`${URL_SFTP_CONFIG}/${partnerId}`);
    return data;
  }

  async fetchAllReports(): Promise<Report[]> {
    const { data } = await this.getRequest(URL_REPORTS);
    return data;
  }

  async fetchReportArtifactsList(
    params: FetchReportArtifactsListParams,
  ): Promise<ReportArtifact[]> {
    const { data } = await this.getRequest(
      `${URL_FETCH_REPORT_ARTIFACTS_LIST}/${params.partnerId}`,
      {
        params,
      },
    );
    return data;
  }

  async fetchReportDefinitions(reportName: string): Promise<ReportDefinition[]> {
    const { data } = await this.getRequest(URL_DEFINITIONS, {
      params: { reportName },
    });
    return data;
  }
}

export const ReportsApi = new ReportsApiHelper();
