import React from 'react';

export const ExploreChartIcon: React.FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18" fill="none">
    <mask id="mask0_1930_1180" maskUnits="userSpaceOnUse" x="7" y="7" width="10" height="10">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.5 7.5H16.5V16.5H7.5V7.5Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1930_1180)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 7.5H8.25C7.836 7.5 7.5 7.836 7.5 8.25V14.25C7.5 14.553 7.683 14.8267 7.96275 14.943C8.05575 14.9812 8.15325 15 8.25 15C8.445 15 8.637 14.9235 8.78025 14.7802L9.75 13.8105L12.2197 16.2802C12.366 16.4265 12.558 16.5 12.75 16.5C12.942 16.5 13.134 16.4265 13.2802 16.2802L16.2802 13.2802C16.5735 12.987 16.5735 12.513 16.2802 12.2198L13.8105 9.75L14.7802 8.78025C14.9947 8.56575 15.0592 8.24325 14.943 7.96275C14.8267 7.683 14.553 7.5 14.25 7.5ZM14.25 8.25L12.75 9.75L15.75 12.75L12.75 15.75L9.75 12.75L8.25 14.25V8.25H14.25Z"
        fill="var(--grey-800)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.875 8.25H1.875C1.668 8.25 1.5 8.082 1.5 7.875C1.5 7.668 1.668 7.5 1.875 7.5H4.875C5.082 7.5 5.25 7.668 5.25 7.875C5.25 8.082 5.082 8.25 4.875 8.25Z"
      fill="var(--grey-800)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 5.25C7.668 5.25 7.5 5.082 7.5 4.875V1.875C7.5 1.668 7.668 1.5 7.875 1.5C8.082 1.5 8.25 1.668 8.25 1.875V4.875C8.25 5.082 8.082 5.25 7.875 5.25Z"
      fill="var(--grey-800)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62494 6C5.52894 6 5.43294 5.96325 5.35944 5.8905L3.10944 3.6405C2.96319 3.4935 2.96319 3.2565 3.10944 3.1095C3.25644 2.96325 3.49344 2.96325 3.64044 3.1095L5.89044 5.3595C6.03669 5.5065 6.03669 5.7435 5.89044 5.8905C5.81694 5.96325 5.72094 6 5.62494 6Z"
      fill="var(--grey-800)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1249 6C10.0289 6 9.93294 5.96325 9.85944 5.8905C9.71319 5.7435 9.71319 5.5065 9.85944 5.3595L12.1094 3.1095C12.2564 2.96325 12.4934 2.96325 12.6404 3.1095C12.7867 3.2565 12.7867 3.4935 12.6404 3.6405L10.3904 5.8905C10.3169 5.96325 10.2209 6 10.1249 6Z"
      fill="var(--grey-800)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.37494 12.75C3.27894 12.75 3.18294 12.7133 3.10944 12.6405C2.96319 12.4935 2.96319 12.2565 3.10944 12.1095L5.35944 9.8595C5.50644 9.71325 5.74344 9.71325 5.89044 9.8595C6.03669 10.0065 6.03669 10.2435 5.89044 10.3905L3.64044 12.6405C3.56694 12.7133 3.47094 12.75 3.37494 12.75Z"
      fill="var(--grey-800)"
    />
  </svg>
);
