import { FC, useContext, useEffect, useState } from 'react';
import {
  useDateRangePeriod,
  useIsComparing,
} from '../../../../components/date-picker/date-picker.state';
import { Grid, SortDirection } from '../../../../components/grid';
import { PeriodPicker } from '../../../../components/period-picker';
import { ShownMetricsSelector } from '../../../../components/shown-metrics-selector';
import { Toolbar } from '../../../../components/toolbar';
import { ViewExporter } from '../../../../components/view-exporter';
import {
  COLUMN_KEY_DATE,
  COLUMN_KEY_PARTNER_REFERENCE,
  GRID_VIEW_CONFIGURATION,
  TimeOption,
  VIEW_NAME,
} from '../../../../constants';
import { AppContext } from '../../../../global/context/app-context';
import { formatColumnsAndRows } from '../../../../helpers/grid-formatter';
import { translate } from '../../../../helpers/utils';
import { useShownMetricsSelector } from '../../../../hooks/use-shown-metrics-selector';
import { useViewTable } from '../../../../hooks/use-view-table';
import { Aggregation, FileFormat, fromTo, View } from '../../../../models';
import { partnerRef2Filter } from '../../../../services/analytics-service';
import styles from '../../common-view-styles.module.css';
import { withSortTracking } from '../../with-sort-tracking';
import { IncludeNonPerformingDataToggle } from '../../hotel-view/include-non-performing-data-toggle';
import { useIncludeNonPerformingData } from '../../hotel-view/use-include-non-performing-data';

export const HotelDetail: FC<{ partnerRef: string }> = ({ partnerRef }) => {
  const { selectedLocales } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();
  const [isComparing] = useIsComparing();
  const [timeOptionSelected, setTimeOptionSelected] = useState(TimeOption.DAY);
  const view = View.HOTEL_DETAIL;
  const numOfStickyColumns = 1;

  const { includeNonPerformingData, setIncludeNonPerformingData } = useIncludeNonPerformingData();

  useEffect(() => {
    setTimeOptionSelected(isComparing ? ('Custom' as TimeOption) : TimeOption.DAY);
  }, [isComparing]);

  const {
    table: { columns, data, total },
    isLoading,
    pagination,
    filters: { allActiveFilters },
    sort: { sort, setSort },
  } = useViewTable(
    view,
    isComparing ? Aggregation.TOTAL : TIME_OPTION_TO_AGGREGATION[timeOptionSelected],
    {
      locales: selectedLocales,
      from,
      to,
      filters: [partnerRef2Filter(partnerRef, COLUMN_KEY_PARTNER_REFERENCE)],
    },
    {
      ignoreGlobalFilters: true,
      avoidFetchingSubtotal: true,
      includeNonPerformingData,
    },
  );

  const { formattedColumns, formattedRows, formattedFooterRows } = formatColumnsAndRows({
    columns,
    rows: data.rows,
    total,
    id: COLUMN_KEY_DATE,
  });

  const { shownMetrics, setShownMetrics, availableMetrics, visibleFormattedColumns } =
    useShownMetricsSelector(view, numOfStickyColumns, columns, formattedColumns);

  const gridPagination = () => {
    if (isComparing) return;
    return {
      page: pagination.pagination,
      totalItems: data.count,
      onPreviousPage: pagination.setPrevPage,
      onNextPage: pagination.setNextPage,
      onUpdateRowsPerPage: pagination.setNumItemsPerPage,
    };
  };

  const handleGridSort = () => {
    if (isComparing) return;
    return (columnName: string, direction: SortDirection) => {
      setSort({ columnName, direction });
    };
  };

  return (
    <div className={styles.toolbarWrapper}>
      <Toolbar
        filters={[
          <PeriodPicker
            key="key-period-picker"
            onSelectTimeOption={setTimeOptionSelected}
            timeOptionSelected={timeOptionSelected}
            disabled={isComparing}
          />,
        ]}
        actions={[
          <IncludeNonPerformingDataToggle
            value={includeNonPerformingData}
            setValue={setIncludeNonPerformingData}
            tooltip={translate('include_non_performing_data_tooltip_hotel_details_view')}
          />,
          <ShownMetricsSelector
            options={availableMetrics}
            selectedByDefaultOptions={shownMetrics}
            view={view}
            onApply={setShownMetrics}
            key="key-metrics-selector"
          />,
          <ViewExporter
            key="key-view-exporter"
            title={translate('analytics_home_export_report')}
            exportOptions={[
              {
                text: translate('analytics_toolbar_export_performance_grid'),
                fileFormat: FileFormat.CSV,
              },
              {
                text: translate('analytics_toolbar_export_performance_grid_excel'),
                fileFormat: FileFormat.EXCEL,
              },
            ]}
            view={view}
            columns={columns}
            aggregation={TIME_OPTION_TO_AGGREGATION[timeOptionSelected]}
            filters={allActiveFilters}
            sort={sort}
          />,
        ]}
      />

      <div className={styles.gridWrapper}>
        <Grid
          numStickyColumns={numOfStickyColumns}
          columns={visibleFormattedColumns}
          rows={formattedRows}
          footerRows={formattedFooterRows}
          isLoading={isLoading}
          configuration={GRID_VIEW_CONFIGURATION}
          onSort={withSortTracking(handleGridSort(), VIEW_NAME[view])}
          pagination={gridPagination()}
        />
      </div>
    </div>
  );
};

const TIME_OPTION_TO_AGGREGATION: fromTo<Partial<TimeOption>, Aggregation> = {
  [TimeOption.DAY]: Aggregation.HOTEL_DETAIL_DAY,
  [TimeOption.WEEK]: Aggregation.HOTEL_DETAIL_WEEK,
  [TimeOption.MONTH]: Aggregation.HOTEL_DETAIL_MONTH,
  [TimeOption.QUARTER]: Aggregation.TIME_QUARTER,
};
