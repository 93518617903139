import { GridConfiguration } from '../components/grid';

export enum RevertType {
  BIDS = 'BIDS',
  MODIFIERS = 'MODIFIERS',
  PROPERTY_GROUPS = 'PROPERTY_GROUPS',
  CAMPAIGN_ALLOCATION = 'CAMPAIGN_ALLOCATION',
  CPA_VALUE = 'CPA_VALUE',
}

export const GRID_VIEW_CONFIGURATION: GridConfiguration = {
  locale: 'de',
  currency: {
    code: 'EUR',
  },
};
