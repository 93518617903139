import { RevertOption } from '../../../../components/revert';
import { RevertSponsored } from '../../../../constants/sponsored';
import { fromTo } from '../../../../models';

export type Props = {};

export const REVERT_BID_OPTIONS: fromTo<RevertSponsored, RevertOption<RevertSponsored>> = {
  [RevertSponsored.SL_BIDS]: {
    type: RevertSponsored.SL_BIDS,
    option: {
      title: 'sponsored_revert_base_bids_title',
      subtitle: 'sponsored_revert_base_bids_subtitle',
    },
    modal: {
      title: 'sponsored_revert_modal_title_info_base_bids',
      subtitle: 'sponsored_revert_modal_info_base_bids',
    },
  },
  [RevertSponsored.SL_MODIFIERS]: {
    type: RevertSponsored.SL_MODIFIERS,
    option: {
      title: 'sponsored_revert_modifiers_title',
      subtitle: 'sponsored_revert_modifiers_subtitle',
    },
    modal: {
      title: 'sponsored_revert_modal_title_info_modifiers',
      subtitle: 'sponsored_revert_modal_info_modifiers',
    },
  },
  [RevertSponsored.SL_BUDGET_CAPS]: {
    type: RevertSponsored.SL_BUDGET_CAPS,
    option: {
      title: 'sponsored_revert_budget_cap_title',
      subtitle: 'sponsored_revert_budget_cap_subtitle',
    },
    modal: {
      title: 'sponsored_revert_modal_title_info_budget_cap',
      subtitle: 'sponsored_revert_modal_info_budget_cap',
    },
  },
};
