import * as React from 'react';
import { Props } from './sponsored-analytics-router.types';
import './sponsored-analytics-router.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DASHBOARD_ROUTE, HOTEL_DETAILS_PARTIAL_ROUTE } from '../../../../constants';
import { SponsoredHotelDetailView } from '../sponsored-hotel-detail-view';
import { RouteComponentProps } from 'react-router';
import { SponsoredAnalytics } from '../sponsored-analytics';

export const SponsoredAnalyticsRouter: React.FunctionComponent<Props> = ({ routeProps }) => {
  const baseRoute = routeProps.match.path;

  const renderHotelDetail = (routePropsParam: RouteComponentProps<any>) => {
    return <SponsoredHotelDetailView partnerRef={routePropsParam.match.params.partnerRef} />;
  };

  return (
    <Switch>
      <Route exact={true} path={baseRoute} render={() => <SponsoredAnalytics />} />
      <Route
        exact={true}
        path={`${baseRoute}/${HOTEL_DETAILS_PARTIAL_ROUTE}`}
        render={renderHotelDetail}
      />
      <Redirect to={DASHBOARD_ROUTE} />
    </Switch>
  );
};
