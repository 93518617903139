import React from 'react';
import { Observable } from '../../../../../helpers/utils/observable';

const editingNameStore = new Observable('');

export const useEditFilterSetName = (name: string): [string, (name: string) => void, boolean] => {
  const [editingName, editingNameSubscriber] = React.useState<string>(editingNameStore.get());
  const isEditingCurrent = name === editingName;

  React.useEffect(() => editingNameStore.subscribe(editingNameSubscriber), []);

  const setEditingName = React.useCallback((name: string) => editingNameStore.set(name), []);

  return [editingName, setEditingName, isEditingCurrent];
};
