import { fromTo, NotificationSettings } from '../../../../../models';
import { NotificationType } from '../../../../../constants';

export type Props = {
  onClose: (settings: NotificationSettings[]) => void;
  onErrorFetchingNotifications: () => void;
};

export const NOTIFICATION_TYPE_TO_OPTION_KEY_BASE: fromTo<NotificationType, string> = {
  [NotificationType.FILE_BASE_BIDS_UPLOAD]: 'notification_settings_option_file_base_bid_upload_',
  [NotificationType.FILE_BID_MODIFIERS_UPLOAD]: 'notification_settings_option_file_bid_modifiers_',
  [NotificationType.FILE_PROPERTY_GROUPS_UPLOAD]:
    'notification_settings_option_file_property_groups_',
  [NotificationType.FILE_CAMPAIGN_ALLOCATIONS_UPLOAD]:
    'notification_settings_option_file_campaign_allocations_',
  [NotificationType.FILE_CPA_VALUES_UPLOAD]: 'notification_settings_option_file_cpa_values_',
  [NotificationType.FILE_SL_BM_UPLOAD]: 'notification_settings_option_file_sl_bm_',
  [NotificationType.FILE_SL_BID_UPLOAD]: 'notification_settings_option_file_sl_bid_',
  [NotificationType.FILE_SL_BUDGET_CAP_UPLOAD]: 'notification_settings_option_file_sl_budget_cap_',
};
