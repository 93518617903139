import { ErrorRowValidationRule } from '../row-validation-rule';

export class RequiredFieldRule extends ErrorRowValidationRule {
  constructor(private readonly fieldName: string) {
    super();
  }

  getError(row: Record<string, string>): string | null {
    return row[this.fieldName] ? null : `Field ${this.fieldName} is required`;
  }
}
