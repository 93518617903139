import { FC } from 'react';
import { MultiLevelMenu } from '../../../components/multi-level-menu';
import { DimensionValueLabels } from '../../../models';
import { capitalizeFirstLetter } from '../../../helpers/utils';
import {
  DESTINATION_DIMENSIONS,
  DestinationDimensionName,
} from '../multi-folder-select/multi-folder-select.types';
import { DestinationDimensionSelector } from './destination-dimension-selector';

type Props = {
  applyFilter: (name: DestinationDimensionName, selectedOptions: DimensionValueLabels[]) => void;
  selectedDestinationType?: DestinationDimensionName;
  selectedDestinations: string[];
};

export const DestinationDimensionMenu: FC<Props> = ({
  selectedDestinationType,
  selectedDestinations,
  applyFilter,
}) => {
  const items = generateDestinationMenuItems(
    applyFilter,
    selectedDestinations,
    selectedDestinationType,
  );

  if (!selectedDestinationType) return <MultiLevelMenu items={items} />;
  const selectedItem = items.find(item => item.key === selectedDestinationType);
  return <>{selectedItem?.subMenu[0].label}</>;
};

const generateDestinationMenuItems = (
  handleApply: (name: DestinationDimensionName, selectedOptions: DimensionValueLabels[]) => void,
  selectedDestinations: string[],
  selectedDestinationType?: DestinationDimensionName,
) =>
  DESTINATION_DIMENSIONS.map(destinationDimension => {
    return {
      key: destinationDimension,
      label: capitalizeFirstLetter(destinationDimension),
      subMenu: [
        {
          key: destinationDimension,
          label: (
            <DestinationDimensionSelector
              destinationDimension={destinationDimension}
              handleApply={selectedOptions => handleApply(destinationDimension, selectedOptions)}
              initialSelection={
                destinationDimension === selectedDestinationType ? selectedDestinations : []
              }
            />
          ),
        },
      ],
    };
  });
