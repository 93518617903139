import { NotificationLevel, NotificationType } from '../constants/notifications';
import { fromTo } from '.';

export type BaseNotification = {
  message: string | string[];
  level: NotificationLevel;
};

export type Notification = {
  id: string;
  title: string;
  brandName: string;
  date: Date;
  isRead: boolean;
  link?: string;
} & BaseNotification;

export type NotificationSettings = {
  id: number;
  isActive: boolean;
  notificationType: NotificationType;
  notificationLevel: NotificationLevel;
};

export const STRING_TO_NOTIFICATION_LEVEL: fromTo<string, NotificationLevel> = {
  ERROR: NotificationLevel.ERROR,
  SUCCESS: NotificationLevel.SUCCESS,
  WARNING: NotificationLevel.WARNING,
  INFO: NotificationLevel.INFO,
};
