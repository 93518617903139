import * as React from 'react';

export const useStickyFooter = (): {
  getCustomRowStyle: (index: number) => { bottom?: number };
  refFooter: React.RefObject<HTMLTableRowElement>;
} => {
  const [rowsHeights, setRowsHeights] = React.useState<number[]>([]);
  const refFooter = React.useRef<HTMLTableRowElement>(null);
  const getCustomRowStyle = (index: number) => {
    if (rowsHeights.length < index) {
      return {};
    }

    const pixelsToMoveToBottom = rowsHeights
      .slice(0, index)
      .reduceRight((sumPixels, currentWidth) => {
        return sumPixels + currentWidth;
      }, 0);
    return { bottom: pixelsToMoveToBottom };
  };

  React.useEffect(() => {
    if (refFooter.current) {
      const nextColumnsWidths = Array.from(refFooter.current.children).map(
        columnTmp => columnTmp.clientHeight,
      );
      setRowsHeights(nextColumnsWidths);
    }
  }, []);

  return {
    getCustomRowStyle,
    refFooter,
  };
};
