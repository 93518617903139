import axios from 'axios';
import { useEffect, useState } from 'react';
import { POS_GLOBAL_VALUE, PROFILE_SETTINGS } from '../../constants';
import { getLastXDays } from '../../helpers/utils';
import {
  Profile,
  Partner,
  Region,
  Locale,
  DatePeriod,
  DateRangePeriod,
  User,
  DateRange,
} from '../../models';
import {
  getProfileSettings,
  getSelectedPartners,
  setProfileSettings,
  setSelectedPartner,
  setTrackingProfile,
} from '../../services/profile-service';
import TrackerService from '../../services/tracker/tracker-service';
import ApiAppHelper from '../../helpers/api/app/api-app-helper';
import { getPartnersId } from '../../services/app-service';
import { LOGGED_IN_NAME } from '../../services/tracker/customer-actions';
import { handleRedirect } from '../../services/resource-center';
import {
  getPeriodFromLocalStorage,
  getProfileFromLocalStorage,
} from '../../helpers/local-storage-helper';

export const useApp = (): AppState => {
  const [state, setState] = useState<State>({
    profile: undefined,
    selectedPartner: undefined,
    subbrands: [],
    isLoadingProfile: true,
  });

  const [locales, setLocales] = useState<Locale[]>([]);
  const [POSlocales, setPOSLocales] = useState<string[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [dateRangePeriod, setPeriod] = useState<DateRangePeriod>({
    to: getLastXDays(1),
    from: getLastXDays(7),
    period: DatePeriod.LAST_7,
  });
  const [dateHistory, setDatehistory] = useState<DateRange>({
    to: getLastXDays(0),
    from: getLastXDays(6),
  });
  const [compareDatePeriods, setCompareDatePeriods] = useState<DateRangePeriod[]>([]);

  useEffect(() => {
    const profile = getProfileFromLocalStorage();
    const { subbrands, currentPartner, selectedPartner } = readSelectedBrands(profile);

    setState(prevState => ({
      ...prevState,
      profile: profile,
      subbrands,
      selectedPartner,
      isLoadingProfile: false,
    }));

    const dateRangePeriod = getPeriodFromLocalStorage();
    if (dateRangePeriod) {
      setPeriod(dateRangePeriod);
    }

    if (profile !== undefined && profile.user !== undefined && currentPartner !== undefined) {
      axios.defaults.headers.common.Authorization = `Bearer ${profile.token.accessToken}`;
      setTrackingProfile(profile.user, currentPartner, subbrands);
      handleRedirect();
    }
    if (selectedPartner) {
      fetchLocales(selectedPartner!);
      fetchRegions();
    }
  }, []);

  // This is pretty bad, there may be edge cases where it doesn't work properly. Touch with care.
  const readSelectedBrands = (profile: Profile | undefined) => {
    let subbrands: Partner[] = [];
    let currentPartner: Partner | undefined;
    const localStoragePartners = getSelectedPartners();
    // calculate selected partner
    if (localStoragePartners !== undefined && profile) {
      const foundPartner = profile.user.partners.find(
        it => it.partnerId === localStoragePartners.partnerId,
      );
      if (foundPartner !== undefined) {
        currentPartner = foundPartner;
      } else {
        currentPartner = profile.user.partners[0];
      }
    } else if (profile) {
      currentPartner = profile.user.partners[0];
    }
    const selectedPartner = profile ? currentPartner : undefined;
    const selectedPartnerSubbrands =
      selectedPartner && selectedPartner.subpartners ? selectedPartner.subpartners : [];

    if (selectedPartnerSubbrands.length > 0) {
      if (
        localStoragePartners &&
        localStoragePartners.subbrands &&
        localStoragePartners.subbrands.length > 0
      ) {
        subbrands = selectedPartner!.subpartners.filter(it =>
          localStoragePartners.subbrands.includes(it.partnerId),
        );
      } else {
        subbrands = selectedPartnerSubbrands;
      }
    }

    if (selectedPartner !== undefined) {
      setSelectedPartner({
        partnerId: selectedPartner!.partnerId,
        subbrands: subbrands.map(it => it.partnerId),
      });
    }
    return { subbrands, currentPartner, selectedPartner };
  };

  const loadData = (partner?: Partner) => {
    fetchLocales(partner ?? state.selectedPartner!);
    fetchRegions();
  };

  const fetchLocales = async (partner: Partner) => {
    const subPartners = partner.subpartners;

    const data = await ApiAppHelper.fetchLocales(getPartnersId(partner, subPartners));
    const { preferedLocale } = getProfileSettings();
    const prefLocales = data.filter(locale => preferedLocale.includes(locale.localeCode));
    const defaultOption =
      prefLocales.length > 0
        ? prefLocales.map(localeTmp => localeTmp.localeCode)
        : [POS_GLOBAL_VALUE];

    setLocales(data);
    setPOSLocales(defaultOption);
  };

  const fetchRegions = async () => {
    const fetchedRegions = await ApiAppHelper.fetchRegions();
    setRegions(fetchedRegions);
  };

  const updatePartner = (partner: Partner) => {
    let subbrands: Partner[] = [];
    localStorage.removeItem(PROFILE_SETTINGS);
    if (partner) {
      if (partner.subpartners.length > 0) {
        subbrands = partner.subpartners;
      }
    }

    setState({
      ...state,
      selectedPartner: partner,
      subbrands,
    });
    setPOSLocales([]);

    setSelectedPartner({
      partnerId: partner.partnerId,
      subbrands: subbrands.map(it => it.partnerId),
    });

    loadData(partner);

    TrackerService.baseAttributes = {
      partner_id: partner.partnerId,
      adv_type: partner.type,
    };
    TrackerService.baseAttributes = {
      sub_partner_id: partner.subpartners.map(subpartner => subpartner.partnerId),
    };
  };

  const updateSubpartners = (subpartners: Partner[]) => {
    setState({
      ...state,
      subbrands: subpartners,
    });
    setSelectedPartner({
      partnerId: state.selectedPartner!.partnerId,
      subbrands: subpartners.map(it => it.partnerId),
    });
    TrackerService.baseAttributes = {
      ...TrackerService.baseAttributes,

      sub_partner_id: subpartners.map(subpartner => subpartner.partnerId),
    };
  };

  const updateLocales = (nextLocales: string[]) => {
    setProfileSettings({
      memberId: state.profile!.user.memberId,
      preferedLocale: nextLocales.map(locale => {
        return locale.toUpperCase();
      }),
    });
    setState({
      ...state,
    });
    setPOSLocales(nextLocales);
  };

  const updatePeriod = (nextPeriod: DateRangePeriod) => {
    setPeriod(nextPeriod);
  };

  const updateUser = (user: User) => {
    setState({ ...state, profile: { user, token: state.profile!.token } });
  };

  const retrieveUser = (profile: Profile) => {
    const {
      user,
      token: { accessToken },
    } = profile;

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const selectedPartner = user.partners[0];
    let subbrands: Partner[] = [];

    if (selectedPartner.subpartners.length > 0) {
      subbrands = selectedPartner.subpartners;
    }

    setState({
      ...state,
      profile,
      selectedPartner,
      subbrands,
      isLoadingProfile: false,
    });

    loadData();

    setTrackingProfile(user, selectedPartner, state.subbrands);
    // Delay login event dispatch until base attributes are set
    setTimeout(() => TrackerService.track(LOGGED_IN_NAME, {}), 0);
  };

  return {
    ...state,
    locales,
    POSlocales,
    dateRangePeriod,
    compareDatePeriods,
    onUpdatePartner: updatePartner,
    onUpdateSubpartners: updateSubpartners,
    onUpdateLocales: updateLocales,
    onUpdatePeriod: updatePeriod,
    onUpdateCompareDatePeriods: setCompareDatePeriods,
    onUpdateUser: updateUser,
    onRetrieveUser: retrieveUser,
    historyRange: dateHistory,
    onUpdateHistoryRange: (range: DateRange) => setDatehistory(range),
    regions,
  };
};

type State = {
  profile: Profile | undefined;
  selectedPartner: Partner | undefined;
  subbrands: Partner[];
  isLoadingProfile: boolean;
};

export type AppState = State & {
  locales: Locale[];
  POSlocales: string[];
  dateRangePeriod: DateRangePeriod;
  compareDatePeriods: DateRangePeriod[];
  onUpdatePartner: (partner: Partner) => void;
  onUpdateSubpartners: (subpartners: Partner[]) => void;
  onUpdateLocales: (locales: string[]) => void;
  onUpdatePeriod: (dateRangePeriod: DateRangePeriod) => void;
  onUpdateCompareDatePeriods: (dateRangePeriods: DateRangePeriod[]) => void;
  onUpdateUser: (user: User) => void;
  onRetrieveUser: (profile: Profile) => void;
  historyRange: DateRange;
  onUpdateHistoryRange: (range: DateRange) => void;
  regions: Region[];
};
