export interface BidModifier {
  breakoutId: number;
  dimensionId: string;
  value: number;
  field: string;
  pendingStatus: boolean;
}

export const MAX_BID_MODIFIER = 10;
export const MIN_BID_MODIFIER = 0.01;
