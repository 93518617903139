import { RefObject, createRef, useEffect, useState } from 'react';
import { NO_OF_NARROW_TABLE_COLUMNS } from '../../../constants';
import { Column } from '../models/grid-model';

export const useStickyColumn = (
  columns: Column[],
  numStickyColumns: number = 0,
  refRow: RefObject<HTMLTableRowElement> = createRef<HTMLTableRowElement>(),
  avoidEmptyPixel: boolean = false,
): {
  refRow: RefObject<HTMLTableRowElement>;
  isColumnSticky: (index: number) => boolean;
  getCustomColumnStyle: (
    index: number,
    extraPadding?: number,
    columnsLength?: number,
  ) => { left?: number };
} => {
  const [columnsWidths, setColumnsWidths] = useState<number[]>([]);

  const isColumnSticky = (index: number) => columnsWidths.length > 0 && index < numStickyColumns;

  // extraLeftPadding is because sometime we need to add some extra pixels to the left property because otherwise the
  // verticals borders are hidden when the user scrolls
  const getCustomColumnStyle = (index: number, extraLeftPadding = 0, columnsLength: number) => {
    if (columnsWidths.length < index) {
      return {};
    }

    const pixelsToMoveToLeft = columnsWidths.slice(0, index).reduce((sumPixels, currentWidth) => {
      return sumPixels + currentWidth;
    }, extraLeftPadding * index);

    const left =
      columnsLength < NO_OF_NARROW_TABLE_COLUMNS
        ? undefined
        : avoidEmptyPixel
          ? pixelsToMoveToLeft - 1 * index
          : pixelsToMoveToLeft;
    return { left };
  };

  useEffect(() => {
    if (refRow.current) {
      const nextColumnsWidths = Array.from(refRow.current.children).map(
        columnTmp => columnTmp.clientWidth,
      );
      setColumnsWidths(nextColumnsWidths);
    }
  }, [columns]);

  return {
    refRow,
    getCustomColumnStyle,
    isColumnSticky,
  };
};
