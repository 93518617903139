import { useQueryClient } from '@tanstack/react-query';
import { MemberAdministration } from '../../../models';
import ApiAdminHelper from '../../../helpers/api/admin/api-admin-helper';
import { showNotification } from '../../../services/notification-service';
import { NotificationLevel } from '../../../constants';
import { translate } from '../../../helpers/utils';

export function useSetPartnerLevel() {
  const queryClient = useQueryClient();

  const setPartnerLevel = async (member: MemberAdministration, partnerLevel: number) => {
    try {
      if (partnerLevel === member?.partnerLevel) return;
      await ApiAdminHelper.setMemberPartnerLevel(member.memberId, partnerLevel);
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'members',
      });
      showNotification({
        level: NotificationLevel.SUCCESS,
        message: translate('member_partner_level_updated'),
      });
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  return setPartnerLevel;
}
