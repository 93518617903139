import { Aggregation, View, ViewData } from '../../../../models';
import { useQuery } from '@tanstack/react-query';
import ApiWidgetsHelper from '../../../../helpers/api/dashboard/api-widgets-helper';
import { getLastXDays } from '../../../../helpers/utils';
import { TYPE_SUBTOTAL } from '../../../../helpers/api/analytics/api-analytics-helper';
import {
  ReactQueryKeys,
  REACT_QUERY_STALE_TIME,
  COLUMN_KEY_CPA_GROUP,
} from '../../../../constants';
import { useFilters, useGlobalColumns } from '../../../../global/context/filter-context';
import { getMatchingFilters } from '../../../../components/toolbar/custom-filters/helpers/validate-filters';
import { biddingTypes2Filter } from '../../../../services/analytics-service';

export const useYesterdayOverviewData = (
  payload: { partnersId: number[]; localeCodes: string[] },
  dependencyList: any[] = [],
) => {
  const { filters, selectedBiddingTypes, includeSlData } = useFilters();
  const view = View.YESTERDAY_OVERVIEW_WITH_SL;
  const fetchColumns = async () => {
    const columns = await ApiWidgetsHelper.getColumns(view, payload.partnersId);
    const columnsToDisplay = columns.filter(column => !column.isHidden);
    const columnsToFilter = columns.filter(column => column.isComparable);
    return { columnsToDisplay, columnsToFilter };
  };

  const {
    data: columns,
    isLoading: isFetchingColumns,
    isError: isColumnError,
  } = useQuery({
    queryKey: [ReactQueryKeys.YESTERDAY_OVERVIEW_COLUMNS],
    queryFn: fetchColumns,
    staleTime: REACT_QUERY_STALE_TIME,
  });

  const { columnsToDisplay = [], columnsToFilter = [] } = columns ?? {};

  useGlobalColumns(columnsToFilter, view);

  const condition = getMatchingFilters(filters, columnsToFilter);

  const biddingTypeFilters = biddingTypes2Filter(selectedBiddingTypes, COLUMN_KEY_CPA_GROUP);
  condition.push(biddingTypeFilters);
  condition.push({
    columnName: 'is_sl_record',
    type: 'EQUALS',
    value: includeSlData ? '1' : '0',
    columnLabel: 'SL',
  });

  const fetchData = async () => {
    const promises: Array<Promise<ViewData[]>> = [
      fetchYesterdayDataPromise(),
      fetchLast7DaysDataPromise(),
    ];
    const [yesterdayData, last7daysData] = await Promise.all(promises);
    const dataTmp = new Map<
      string,
      { yesterday: string | number; last7days: string | number | undefined }
    >();
    const yesterdayMetrics: ViewData | undefined =
      yesterdayData.length > 0 ? yesterdayData[0] : undefined;
    const last7DaysMetrics: ViewData | undefined =
      last7daysData.length > 0 ? last7daysData[0] : undefined;
    const fetchedColumns = columnsToDisplay || [];

    fetchedColumns.forEach(({ key }) => {
      if (yesterdayMetrics !== undefined && yesterdayMetrics[key] !== undefined) {
        dataTmp.set(key, {
          yesterday: yesterdayMetrics[key]!,
          last7days: last7DaysMetrics !== undefined ? last7DaysMetrics[key] : undefined,
        });
      }
    });

    return dataTmp;
  };

  const {
    data,
    isLoading: isFetchingData,
    isError: isDataError,
    refetch,
  } = useQuery({
    queryKey: [
      ReactQueryKeys.YESTERDAY_OVERVIEW_DATA,
      JSON.stringify(dependencyList),
      JSON.stringify(condition),
    ],
    queryFn: fetchData,
    staleTime: REACT_QUERY_STALE_TIME,
    enabled: columnsToDisplay.length > 0,
  });

  const fetchYesterdayDataPromise = () => {
    return ApiWidgetsHelper.getWidgetsData(view, {
      aggregations: [Aggregation.TIME_DAY],
      condition,
      partnersId: payload.partnersId,
      localeCodes: payload.localeCodes,
      from: getLastXDays(1),
      to: getLastXDays(1),
    });
  };

  const fetchLast7DaysDataPromise = () => {
    return ApiWidgetsHelper.getWidgetsData(view, {
      aggregations: [Aggregation.TIME_DAY],
      condition,
      partnersId: payload.partnersId,
      localeCodes: payload.localeCodes,
      from: getLastXDays(8),
      to: getLastXDays(2),
      type: TYPE_SUBTOTAL,
    });
  };

  return {
    columns: columnsToDisplay,
    data,
    refetch,
    isFetching: isFetchingColumns || isFetchingData,
    isError: isColumnError || isDataError,
  };
};
