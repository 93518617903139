export enum EventType {
  UNDEFINED = 0,
  EDIT_CPC_UI = 2,
  REVERT_PENDING_CHANGES = 3,
  FILE_BIDDING = 4,
  CAMPAIGN_REFRESH = 12,
  PUSH = 13,
  TYPE_CONTENT_HUB_TASK = 15,
  FILE_BIDDING_MODIFIERS = 16,
  PUSH_BM = 17,
  REVERT_BM = 18,
  CANCEL_PENDING_FILES = 19,
  CONTENT_HUB_BID_MODIFIER_IMPORT = 21,
  EDIT_BM_UI = 23,
  FILE_PROPERTY_GROUP_ID = 24,
  PUSH_PROPERTY_GROUPS = 26,
  CAMPAIGN_TO_MANUAL_RESET = 27,
  REPORTS = 29,
  FILE_CAMPAIGN_ALLOCATION = 30,
  FILE_CPA_VALUE = 31,
  PUSH_CAMPAIGN_ALLOCATIONS = 32,
  PUSH_CPA_VALUE = 33,
  EDIT_CPA_VALUE_UI = 34,
  REVERT_PROPERTY_GROUPS = 35,
  REVERT_PENDING_CAMPAIGN_ALLOCATION = 36,
  REVERT_PENDING_CPA_VALUE = 37,
  CONTENT_HUB_CPA_VALUE_IMPORT = 38,
  CONTENT_HUB_CPA_ALLOC_IMPORT = 39,
  FILE_SL_BM = 40,
  FILE_SL_BID = 41,
  PUSH_SL_BID = 42,
  FILE_SL_BUDGET_CAP = 43,
  PUSH_SL_BUDGET_CAP = 44,
  REVERT_SL_BIDS = 45,
  REVERT_SL_BUDGET_CAP = 46,
  REVERT_SL_MODIFIERS = 47,
  EDIT_SL_BID_UI = 48,
  CONTENT_HUB_PROPERTY_GROUP_IMPORT = 49,
  CONTENT_HUB_SL_IMPORT = 50,
  CONTENT_HUB_SL_BM_IMPORT = 51,
  INVENTORY_IMPORT = 52,
  CONTENT_HUB_BID_MODIFIER_IMPORT_MANUAL = 53,
  CONTENT_HUB_CPA_VALUE_IMPORT_MANUAL = 54,
  CONTENT_HUB_CPA_ALLOC_IMPORT_MANUAL = 55,
  CONTENT_HUB_PROPERTY_GROUP_IMPORT_MANUAL = 56,
  CONTENT_HUB_SL_IMPORT_MANUAL = 57,
  CONTENT_HUB_SL_BM_IMPORT_MANUAL = 58,
  INVENTORY_IMPORT_MANUAL = 59,
  TYPE_CONTENT_HUB_TASK_MANUAL = 60,
  CONTENT_HUB_SL_BUDGET_CAP = 61,
  CONTENT_HUB_SL_BUDGET_CAP_MANUAL = 62,
  COMBINED_BIDDING = 63,
  PUSH_SL_BM = 64,
  INVENTORY_IMPORT_TI = 65,
  FILE_CPA_BM = 66,
  PUSH_CPA_BM = 67,
  CONTENT_HUB_CPA_BM_IMPORT_SCHEDULED = 68,
  CONTENT_HUB_CPA_BM_IMPORT_MANUAL = 69,
  CPA_BM_UI_EDIT = 70,
}

export function getPushTypeForImportType(type: EventType): EventType {
  const pushType = PUSH_TYPES_BY_IMPORT_TYPE[type];
  if (!pushType) throw new Error(`There is no related push type for ${type}`);
  return pushType;
}

const PUSH_TYPES_BY_IMPORT_TYPE: Record<number, EventType> = {
  [EventType.FILE_BIDDING]: EventType.PUSH,
  [EventType.EDIT_CPC_UI]: EventType.PUSH,
  [EventType.FILE_BIDDING_MODIFIERS]: EventType.PUSH_BM,
  [EventType.EDIT_BM_UI]: EventType.PUSH_BM,
  [EventType.FILE_PROPERTY_GROUP_ID]: EventType.PUSH_PROPERTY_GROUPS,
  [EventType.FILE_CAMPAIGN_ALLOCATION]: EventType.PUSH_CAMPAIGN_ALLOCATIONS,
  [EventType.FILE_CPA_VALUE]: EventType.PUSH_CPA_VALUE,
  [EventType.EDIT_CPA_VALUE_UI]: EventType.PUSH_CPA_VALUE,
  [EventType.FILE_SL_BID]: EventType.PUSH_SL_BID,
  [EventType.EDIT_SL_BID_UI]: EventType.PUSH_SL_BID,
  [EventType.FILE_SL_BM]: EventType.PUSH_SL_BM,
  [EventType.FILE_SL_BUDGET_CAP]: EventType.PUSH_SL_BUDGET_CAP,
  [EventType.FILE_CPA_BM]: EventType.PUSH_CPA_BM,
  [EventType.CPA_BM_UI_EDIT]: EventType.PUSH_CPA_BM,
};

export enum EventStatus {
  PENDING = 0,
  IN_PROGRESS = 4,
  SUCCESS = 1,
  PARTIAL_SUCCESS = 3,
  ERROR = 2,
  CANCELED = 5,
  WAITING = 6,
}

export class HistoryEvent {
  public readonly locales: string[];
  public readonly targetValue: number;
  public readonly previousTargetValue: number;
  public readonly budgetValue: number;
  public readonly previousBudgetValue: number;

  constructor(
    public readonly id: number,
    public readonly status: number,
    public readonly createdAt: string,
    public readonly type: EventType,
    private readonly jobProperties: Properties,
  ) {
    this.locales = [jobProperties.pos];
    this.targetValue = jobProperties.targetValue;
    this.previousTargetValue = jobProperties.previousTargetValue;
    this.budgetValue = jobProperties.budgetValue;
    this.previousBudgetValue = jobProperties.previousBudgetValue;
  }

  public isImportEvent(): boolean {
    return [
      EventType.FILE_BIDDING,
      EventType.EDIT_CPC_UI,
      EventType.FILE_BIDDING_MODIFIERS,
      EventType.EDIT_BM_UI,
      EventType.FILE_PROPERTY_GROUP_ID,
      EventType.FILE_CAMPAIGN_ALLOCATION,
      EventType.FILE_CPA_VALUE,
      EventType.EDIT_CPA_VALUE_UI,
      EventType.FILE_SL_BID,
      EventType.EDIT_SL_BID_UI,
      EventType.FILE_SL_BM,
      EventType.FILE_SL_BUDGET_CAP,
      EventType.FILE_CPA_BM,
      EventType.CPA_BM_UI_EDIT,
    ].includes(this.type);
  }

  private isPushEvent(): boolean {
    return [
      EventType.PUSH,
      EventType.PUSH_BM,
      EventType.PUSH_PROPERTY_GROUPS,
      EventType.PUSH_CAMPAIGN_ALLOCATIONS,
      EventType.PUSH_CPA_VALUE,
      EventType.PUSH_SL_BID,
      EventType.PUSH_SL_BM,
      EventType.PUSH_SL_BUDGET_CAP,
      EventType.PUSH_CPA_BM,
    ].includes(this.type);
  }

  public shouldHaveSummaryFile(): boolean {
    return (
      (this.isPushEvent() && this.status !== EventStatus.ERROR) ||
      (this.isImportEvent() && this.status !== EventStatus.PENDING)
    );
  }

  public shouldHaveSourceFile(): boolean {
    return [
      EventType.FILE_BIDDING,
      EventType.TYPE_CONTENT_HUB_TASK,
      EventType.FILE_BIDDING_MODIFIERS,
      EventType.FILE_PROPERTY_GROUP_ID,
      EventType.CONTENT_HUB_BID_MODIFIER_IMPORT,
      EventType.FILE_CPA_VALUE,
      EventType.FILE_CAMPAIGN_ALLOCATION,
      EventType.CONTENT_HUB_CPA_VALUE_IMPORT,
      EventType.CONTENT_HUB_CPA_ALLOC_IMPORT,
      EventType.CONTENT_HUB_PROPERTY_GROUP_IMPORT,
      EventType.CONTENT_HUB_SL_IMPORT,
      EventType.CONTENT_HUB_SL_BM_IMPORT,
      EventType.CONTENT_HUB_SL_BUDGET_CAP,
      EventType.FILE_SL_BM,
      EventType.FILE_SL_BID,
      EventType.FILE_SL_BUDGET_CAP,
      EventType.INVENTORY_IMPORT,
      EventType.CONTENT_HUB_BID_MODIFIER_IMPORT,
      EventType.TYPE_CONTENT_HUB_TASK_MANUAL,
      EventType.CONTENT_HUB_CPA_VALUE_IMPORT_MANUAL,
      EventType.CONTENT_HUB_CPA_ALLOC_IMPORT_MANUAL,
      EventType.CONTENT_HUB_PROPERTY_GROUP_IMPORT_MANUAL,
      EventType.CONTENT_HUB_SL_IMPORT_MANUAL,
      EventType.CONTENT_HUB_SL_BM_IMPORT_MANUAL,
      EventType.CONTENT_HUB_SL_BUDGET_CAP_MANUAL,
      EventType.INVENTORY_IMPORT_MANUAL,
      EventType.COMBINED_BIDDING,
      EventType.FILE_CPA_BM,
      EventType.CONTENT_HUB_CPA_BM_IMPORT_MANUAL,
      EventType.CONTENT_HUB_CPA_BM_IMPORT_SCHEDULED,
    ].includes(this.type);
  }

  private isDpiBased(): boolean {
    return [
      EventType.TYPE_CONTENT_HUB_TASK,
      EventType.CONTENT_HUB_BID_MODIFIER_IMPORT,
      EventType.CONTENT_HUB_CPA_VALUE_IMPORT,
      EventType.CONTENT_HUB_CPA_ALLOC_IMPORT,
      EventType.CONTENT_HUB_PROPERTY_GROUP_IMPORT,
      EventType.CONTENT_HUB_SL_IMPORT,
      EventType.CONTENT_HUB_SL_BM_IMPORT,
      EventType.CONTENT_HUB_SL_BUDGET_CAP,
      EventType.INVENTORY_IMPORT,
      EventType.TYPE_CONTENT_HUB_TASK_MANUAL,
      EventType.CONTENT_HUB_BID_MODIFIER_IMPORT,
      EventType.CONTENT_HUB_CPA_VALUE_IMPORT_MANUAL,
      EventType.CONTENT_HUB_CPA_ALLOC_IMPORT_MANUAL,
      EventType.CONTENT_HUB_PROPERTY_GROUP_IMPORT_MANUAL,
      EventType.CONTENT_HUB_SL_IMPORT_MANUAL,
      EventType.CONTENT_HUB_SL_BM_IMPORT_MANUAL,
      EventType.CONTENT_HUB_SL_BUDGET_CAP_MANUAL,
      EventType.INVENTORY_IMPORT_MANUAL,
      EventType.COMBINED_BIDDING,
      EventType.CONTENT_HUB_CPA_BM_IMPORT_MANUAL,
      EventType.CONTENT_HUB_CPA_BM_IMPORT_SCHEDULED,
    ].includes(this.type);
  }

  public hasSummaryFile(): boolean {
    return this.shouldHaveSummaryFile() && (!this.isPushEvent() || !!this.jobProperties.filePath);
  }

  public hasSourceFile(): boolean {
    if (this.shouldHaveSourceFile()) {
      if (
        !!this.jobProperties.sourceFileAWSPath ||
        !!this.jobProperties.sourceFilePath ||
        (!this.isPushEvent() && !!this.jobProperties.filePath)
      ) {
        if (!this.isDpiBased() || this.jobProperties.error === '') {
          return true;
        }
      }
    }
    return false;
  }
}

export type EventProperties = {
  properties: Properties[];
};

export type Properties = {
  pos: string;
  targetValue: number;
  previousTargetValue: number;
  budgetValue: number;
  previousBudgetValue: number;
  filePath: string;
  sourceFilePath: string;
  sourceFileAWSPath: string;
  error: string;
};

export interface RemainingPushes {
  type: EventType;
  pushesRemaining: number;
}

export interface ImportSchedule {
  type: EventType;
  cronSchedule: string;
  isDailyFlexible: boolean;
}
