import { fromTo, FileFormat } from '../models';

export const FILE_FORMAT_VALUE: fromTo<FileFormat, string> = {
  [FileFormat.CSV]: 'Csv',
  [FileFormat.EXCEL]: 'Excel',
};

export const FILE_EXTENSION: fromTo<FileFormat, string> = {
  [FileFormat.CSV]: '.csv.gz',
  [FileFormat.EXCEL]: '.xlsx',
};
