import cs from 'classnames';
import * as React from 'react';
import { SELECTED_OPTION } from '../../../../constants/constants';
import { TranslationKey, translate } from '../../../../helpers/utils';
import './selector-area-menu.css';
import { OptionProps, Props } from './selector-area-menu.types';

const MenuItem: React.FunctionComponent<OptionProps> = ({ isActive, onClick, label }) => {
  return (
    <li
      onClick={() => (!isActive ? onClick() : null)}
      className={cs('u-padding--t-small', {
        'c-area-selector__selected': isActive,
        'u-color--juri-light u-cursor--pointer': !isActive,
      })}
    >
      <span className={cs({ 'u-font-weight--bold': isActive })}>{label}</span>
    </li>
  );
};

export const SelectorAreaMenu: React.FunctionComponent<Props> = ({
  options,
  selectedOption,
  numOptionSelected,
  onSelect,
  isMultiple = true,
}) => {
  const isSelected = (option: string) => option === selectedOption;
  return (
    <ul
      className={`
        c-area-selector
        u-display--flex 
        u-flex-direction--column
        u-border--juri-very-light 
        u-border-right 
      `}
    >
      {options.map(option => {
        return (
          <MenuItem
            key={option}
            onClick={() => onSelect(option)}
            isActive={isSelected(option)}
            label={translate(option as TranslationKey)}
          />
        );
      })}
      <MenuItem
        isActive={isSelected(SELECTED_OPTION)}
        onClick={() => onSelect(SELECTED_OPTION)}
        label={isMultiple ? `${translate(SELECTED_OPTION)} (${numOptionSelected})` : ''}
      />
    </ul>
  );
};
