import { RequestHelper } from '../request-helper';
import { BiddingPayload } from '../analytics/types';
import {
  Aggregation,
  BidModifier,
  VIEWS_BM_EDIT_BID_MODIFIER,
  Bid,
  BiddingActionType,
  BiddingCampaign,
  View,
} from '../../../models';
import { getFormattedDates } from '../../utils';
import { BiddingResponse, ApiBidModifier, BidCap } from './types';
import {
  VIEW_NAME,
  AGGREGATION_NAME,
  BM_VIEW_DIMENSION,
  RevertType,
  BIDDING_ACTION_TYPE_TO_VIEW,
} from '../../../constants';
import { AxiosError } from 'axios';

const URL_APPLY_BIDS = '/bidding/apply';
const URL_REVERT = 'bidding/revert/partner';
const URL_APPLY_BIDS_HOTEL_VIEW_MANUAL = 'analytics/bidding';

export const URL_APPLY_BID_MODIFIERS_CPC = '/modifier/apply';
const URL_APPLY_CPA_APPLY = '/cpa/apply';
const URL_MAX_BID_CAP = '/bidcap';
export const URL_APPLY_BID_MODIFIERS_CPA = '/cpa-modifier/apply';

class ApiBiddingHelper extends RequestHelper {
  public async applyBids(
    bids: Bid[],
    partnerId: number,
    cpc: number,
    actionType: BiddingActionType,
  ): Promise<BiddingResponse> {
    const headers = {
      partnerId,
      properties: JSON.stringify({ bid: cpc }),
      actionType: actionType,
    };
    try {
      const result = await this.postRequest(URL_APPLY_BIDS, bids, { headers });
      return result.data;
    } catch (e) {
      this.handleError(e as AxiosError);
      return {} as BiddingResponse;
    }
  }

  public async applyRevert(partnerId: number, type: RevertType): Promise<unknown> {
    try {
      return await this.deleteRequest(`${URL_REVERT}/${partnerId}/${type}`, {});
    } catch (e) {
      this.handleError(e as AxiosError);
      return;
    }
  }

  public async applyManualCpcToAllInventory(
    cpcInCents: number,
    payload: BiddingPayload,
    actionType: BiddingActionType,
  ): Promise<BiddingResponse> {
    const {
      partnersId,
      filters,
      dateRange: { from, to },
      locales,
    } = payload;
    const view = BIDDING_ACTION_TYPE_TO_VIEW[actionType];
    if (view === null) {
      throw Error('Unsupported bidding action type');
    }

    const body = {
      name: VIEW_NAME[view],
      filters: {
        partnerId: partnersId,
        aggregation: AGGREGATION_NAME[Aggregation.HOTEL],
        condition: filters,
        date: getFormattedDates(from, to),
        localeCodes: locales,
      },
      bid: cpcInCents,
      type: actionType,
    };
    try {
      const result = await this.postRequest(`${URL_APPLY_BIDS_HOTEL_VIEW_MANUAL}`, body);
      return result.data;
    } catch (e) {
      this.handleError(e as AxiosError);
      return {} as BiddingResponse;
    }
  }

  public async applyBidModifiers(
    bidModifiers: BidModifier[],
    propertyGroups: number[],
    view: VIEWS_BM_EDIT_BID_MODIFIER,
    partnerId: number,
    localeCode: string,
    value: number,
    url: string,
  ): Promise<BiddingResponse> {
    const headers = {
      partnerId,
      properties: JSON.stringify({ bidModifier: value }),
      actionType: url.includes('cpa')
        ? BiddingActionType.CPABiddingModifier
        : BiddingActionType.CPCBiddingModifier,
    };

    const isCPAWithTTTView = view === View.BM_POS_TIME_TO_TRAVEL && url.includes('cpa');

    const apiBidModifiers: ApiBidModifier[] = bidModifiers.flatMap(({ breakoutId }) => {
      if (isCPAWithTTTView) {
        return {
          idModifier: {
            dimension: BM_VIEW_DIMENSION[view],
            breakout: breakoutId,
          },
          localeCode,
          modifier: String(value),
        };
      }
      return propertyGroups.map(groupId => ({
        idModifier: {
          dimension: BM_VIEW_DIMENSION[view],
          breakout: breakoutId,
          propertyGroupId: groupId,
        },
        localeCode,
        modifier: String(value),
      }));
    });

    try {
      const result = await this.postRequest(url, apiBidModifiers, {
        headers,
      });
      return result.data;
    } catch (e) {
      this.handleError(e as AxiosError);
      return {} as BiddingResponse;
    }
  }

  public async applyCpaValueBids(
    campaigns: BiddingCampaign[],
    partnerId: number,
    bidValue: number,
  ): Promise<BiddingResponse> {
    const headers = {
      partnerId,
      properties: JSON.stringify({ cpa: bidValue }),
      actionType: BiddingActionType.CpaValue,
    };

    const body = campaigns.map(campaignTmp => ({
      biddingType: campaignTmp.type.value,
      localeCode: campaignTmp.pos.localeCode,
      value: String(bidValue),
    }));

    try {
      const result = await this.postRequest(URL_APPLY_CPA_APPLY, body, { headers });
      return result.data;
    } catch (e) {
      this.handleError(e as AxiosError);
      return {} as BiddingResponse;
    }
  }
  public async getMaxCPCBidCaps(partnerId: number): Promise<BidCap[]> {
    try {
      const result = await this.getRequest(`${URL_MAX_BID_CAP}/${partnerId}`);
      return result.data.bidCap;
    } catch (e) {
      this.handleError(e as AxiosError);
      return [] as BidCap[];
    }
  }
}

export const apiBiddingHelper = new ApiBiddingHelper();
