import * as React from 'react';
import { SELECTED_OPTION } from '../../../../constants';
import './area-options.css';
import { Props } from './area-options.types';
import { Option } from './option';
import { Option as OptionProp } from '../../../../models';
import cs from 'classnames';
import { TranslationKey, translate } from '../../../../helpers/utils';

export const AreaOptions: React.FunctionComponent<Props> = ({
  options,
  selectedGroup,
  searchKeyword,
  selectedOptions,
  onChange,
  isMultiple = true,
  className,
}) => {
  const isSelected = (value: string) => selectedOptions.some(option => option.value === value); // iterate until it finds the option selected
  const [globalOption, ...restOptions] = options; // take the first array element and gather the rest
  const showGlobalOption =
    globalOption && // if it exists
    isMultiple &&
    ((selectedGroup === SELECTED_OPTION && isSelected(globalOption.value)) || // if selected option is "Selected" and globalOption is selected
      globalOption.group === selectedGroup); // or it belongs to the proper selected group
  return (
    <div className="c-area-options">
      {showGlobalOption && (
        <Option
          key={globalOption.value}
          option={{ ...globalOption, label: translate(globalOption.label as TranslationKey) }}
          isSelected={isSelected(globalOption.value)}
          onChange={onChange}
          className="u-list-style--none u-margin-left--small"
        />
      )}
      <ul
        className={cs(
          `c-area-options__options
        u-display--flex
        u-flex-direction--column
        u-flex-wrap--wrap
        u-align-content--flex-start
        u-padding--small
        u-background--white`,
          className,
        )}
      >
        {restOptions
          .filter(filterByGroup(selectedGroup, isSelected))
          .filter(filterBySearchKeyWord(searchKeyword))
          .map(renderOption(onChange, isSelected, isMultiple))}
      </ul>
    </div>
  );
};

const filterByGroup =
  (selectedGroup: string, isSelected: (text: string) => void) => (option: OptionProp) =>
    selectedGroup === SELECTED_OPTION ? isSelected(option.value) : option.group === selectedGroup;

const filterBySearchKeyWord =
  (searchText: string) =>
  ({ label, value }: OptionProp) =>
    label.toLowerCase().includes(searchText.toLowerCase()) ||
    value.toLowerCase().includes(searchText.toLowerCase());

const renderOption =
  (
    onChange: (option: OptionProp) => void,
    isSelected: (text: string) => boolean,
    isMultiple: boolean,
  ) =>
  (option: OptionProp) => {
    return (
      <Option
        key={option.value}
        option={option}
        isSelected={isSelected(option.value)}
        onChange={onChange}
        isMultiple={isMultiple}
      />
    );
  };
