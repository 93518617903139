import React from 'react';
import { YAxis, XAxis, ResponsiveContainer, Tooltip, BarChart, Bar } from 'recharts';
import { WidgetTooltip } from '../widget-tooltip';
import { ChartColor, Color } from '../../constants';
import { Props } from './chart.types';
import './chart.css';
import { ExtendedTimelineWidgetProps, WidgetType } from '../../models';
import ChartBuilderBarChart from './chart-builder-bar-chart';

export const BarChartComponent: React.FunctionComponent<Props> = ({
  isFullScreen,
  columns,
  data,
  columnsColor,
  xAxisDataKey = 'date',
  tickFormatterX,
  tickFormatterY,
  toolTipTitle,
  domain,
  stackID,
  tickLineX = false,
  tickLineY = false,
  highlightBar = false,
  xAxisLabel,
  leftYAxisLabel,
  widgetType = WidgetType.TIMELINE,
}) => {
  if (widgetType === WidgetType.CHART_BUILDER)
    return (
      <ChartBuilderBarChart
        isFullScreen={isFullScreen}
        columns={columns}
        chartProps={{} as ExtendedTimelineWidgetProps}
        data={data}
        columnsColor={columnsColor}
        xAxisDataKey={xAxisDataKey}
        tickFormatterX={tickFormatterX}
        tickFormatterY={tickFormatterY}
        toolTipTitle={toolTipTitle}
        domain={domain}
        stackID={stackID}
        tickLineX={tickLineX}
        tickLineY={tickLineY}
        highlightBar={highlightBar}
        xAxisLabel={xAxisLabel}
        leftYAxisLabel={leftYAxisLabel}
        variant={'singleAxisLine'}
      />
    );
  return (
    <ResponsiveContainer width="100%" height={isFullScreen ? 620 : 270}>
      <BarChart data={data} margin={{ left: 16, top: 16, right: 16, bottom: 16 }}>
        {[...columns].reverse().map(c => (
          <Bar key={c.key} dataKey={c.key} stackId={stackID} fill={columnsColor![c.key]} />
        ))}

        <XAxis
          dataKey={xAxisDataKey}
          tickFormatter={tickFormatterX}
          axisLine={{ stroke: Color.Grey200 }}
          stroke={Color.Grey500}
          tickLine={tickLineX}
        />
        <YAxis
          domain={domain}
          tickFormatter={tickFormatterY}
          stroke={Color.Grey500}
          tickLine={tickLineY}
          axisLine={{ stroke: Color.Grey200 }}
        />
        <Tooltip
          cursor={{ fill: highlightBar ? Color.Blue300 : 'none' }}
          content={({ label, payload }: { label: string; payload: any[] }) => {
            if (!payload) {
              return;
            }
            return (
              <WidgetTooltip
                title={toolTipTitle(label)}
                metrics={payload.reverse().map((payloadTmp, index) => {
                  return {
                    name: columns.find(metricTmp => metricTmp.key === payloadTmp.dataKey)!.name,
                    wrapper: columns[index].wrapper,
                    color: columnsColor?.[payloadTmp.dataKey] ?? Object.values(ChartColor)[index],
                    value: payloadTmp.value,
                  };
                })}
              />
            );
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
