import { FC } from 'react';
import { Region } from '../../models';
import { CheckboxSelectOption, DEFAULT_OPTIONS_LIMIT, CheckboxSelect } from '../checkbox-select';
import { SelectableRegion } from './pos-selector';
import { translate } from '../../helpers/utils';

export type PosRegionsSelectorProps = {
  regions: SelectableRegion[];
  onApply: (regions: Region[]) => void;
};

export const PosRegionsSelector: FC<PosRegionsSelectorProps> = ({ regions, onApply }) => (
  <CheckboxSelect
    options={regions.map(toOption)}
    onApply={options => onApply(options.filter(o => o.checked).map(o => o.value))}
    title={`${translate('analytics_home_pos_selector')} · ${translate(
      'analytics_chart_builder_dimension_posRegion_title',
    )}`}
    searchEnabled
    searchPlaceholder="Search regions"
    selectedOptionsLimit={DEFAULT_OPTIONS_LIMIT}
  />
);

const toOption = (region: SelectableRegion): CheckboxSelectOption<Region> => ({
  id: region.regionName,
  checked: region.selected,
  label: region.regionName,
  value: region,
  subLabel: `Aggregation of ${region.localeCodes.length} point of sale`,
  tooltip: region.localeCodes.join(', '),
});
