import { useQuery } from '@tanstack/react-query';
import { EventType, HistoryEvent } from '../../models';
import ApiHistoryHelper from '../../helpers/api/history/api-history-helper';
import { filterEvents } from '../../services/history-service';

interface UseHistoryEventsResult {
  allEvents: HistoryEvent[];
  filteredEvents: HistoryEvent[];
  isLoading: boolean;
  refetch: VoidFunction;
}

interface UseHistoryEventsRequest {
  partnerId: number;
  from: Date;
  to: Date;
  types: EventType[];
  searchText: string;
}

export function useHistoryEvents(request: UseHistoryEventsRequest): UseHistoryEventsResult {
  const { partnerId, from, to, types, searchText } = request;
  const { data, isLoading, refetch } = useQuery(keyOf(request), () =>
    ApiHistoryHelper.fetchEvents(partnerId, from, to, types),
  );

  const allEvents = data || [];

  return {
    allEvents,
    filteredEvents: filterEvents(allEvents, searchText),
    isLoading,
    refetch,
  };
}

const keyOf = ({ partnerId, from, to, types }: UseHistoryEventsRequest): any[] => [
  'event-history',
  partnerId,
  from.toDateString(),
  to.toDateString(),
  ...types,
];
