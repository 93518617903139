import { FC } from 'react';
import { Props } from './scrollable-modal.types';
import styles from './scrollable-modal.module.css';
import { Modal, ModalSize } from '../modal';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import cn from 'classnames';
import { TooltipWrapper, TooltipPosition } from '../tooltip-wrapper';
import { Button } from '../../core-ui/components/button/button';

export const ScrollableModal: FC<Props> = ({
  children,
  title,
  onClose,
  bodyStyle = {},
  checkboxTitle,
  onChangeCheckbox,
  size = ModalSize.MEDIUM,
  backdropStatic = false,
  primaryButton,
  secondaryButton,
  footer,
}) => {
  const toolbarMessageWhenIsDisabled = primaryButton?.toolbarMessageWhenIsDisabled ?? '';
  const isSecondaryBtnDisabled = secondaryButton?.isDisabled ?? false;

  return (
    <Modal onClose={onClose} size={size} backdropStatic={backdropStatic}>
      <header className="u-padding--large u-display--flex u-align-items--center">
        <h1 className="u-font-size--t-small">{title}</h1>
        <CloseIcon className="u-margin-left--auto u-cursor--pointer" onClick={onClose} />
      </header>
      <section className={styles.cModalContainerContent} style={bodyStyle}>
        {children}
      </section>
      {!footer || !footer.isHidden ? (
        <footer
          className={cn('u-padding--large u-display--flex u-align-items--center', {
            'u-border-top--juri-very-light': !footer || !footer.isTopBorderHidden,
          })}
        >
          {checkboxTitle && (
            <label className="u-display--flex u-align-items--center u-font-weight--normal u-cursor--pointer u-color--juri-light u-margin-right--auto">
              <input
                type="checkbox"
                className="u-margin-right--small"
                onChange={onChangeCheckbox}
              />
              {checkboxTitle}
            </label>
          )}
          <section className="u-margin-left--auto " style={{ gap: 12, display: 'flex' }}>
            {secondaryButton !== undefined && (
              <Button
                variant="tertiary"
                text={secondaryButton.title}
                onClick={secondaryButton.actionFn}
                disabled={isSecondaryBtnDisabled}
                size="large"
              />
            )}
            {primaryButton !== undefined && (
              <TooltipWrapper
                message={toolbarMessageWhenIsDisabled}
                position={TooltipPosition.TOP_TRAILING}
                className="c-panel-control__faq-circle-icon"
                noTinyPaddingTop={true}
                hideTooltip={!primaryButton.isDisabled}
              >
                <Button
                  text={primaryButton.title}
                  onClick={primaryButton.actionFn}
                  disabled={primaryButton.isDisabled}
                  size="large"
                />
              </TooltipWrapper>
            )}
          </section>
        </footer>
      ) : (
        <div className={styles.cModalContainerFooterEmpty} />
      )}
    </Modal>
  );
};
