import { FC, useState } from 'react';
import styles from './dashboard-create-modal.module.css';
import cn from 'classnames';
import { ScrollableModal } from '../../../components/scrollable-modal';
import { translate, pressKeyEnter, TranslationKey } from '../../../helpers/utils';
import { InputIcon } from '../../../components/input-icon';
import { RadioButton } from '../../../components/radio-button';
import { WrapperErrorHint } from '../../../components/wrapper-error-hint';
import imgBlankUnselected from './icons/blank-unselected.svg';
import imgBlankSelected from './icons/blank-selected.svg';
import imgStarterUnselected from './icons/starter-unselected.svg';
import imgStarterSelected from './icons/starter-sselected.svg';
import { useNameValidation } from '../hooks/use-name-validation';
import { DashBoardType, Partner } from '../../../models';
import { useDashboards } from '../use-dashboards';
import {
  dashboardValidationProps,
  DASHBOARD_ERROR_VALIDATION_TO_TRANSLATION_KEY,
} from '../../../constants';

type Props = {
  onClose: () => void;
  onCreate: (name: string, type: DashBoardType) => void;
  partner: Partner;
};

export const DashboardCreateModal: FC<Props> = ({ partner, onCreate, onClose }) => {
  const [optionSelected, setOptionSelected] = useState(DashBoardType.EMPTY);
  const { data: dashboards = [] } = useDashboards();
  const { name, setName, isValid, validationErrors, shouldShowErrors, setShouldShowErrors } =
    useNameValidation(dashboardValidationProps(dashboards));

  const isOptionSelected = (option: DashBoardType) => option === optionSelected;

  const changeName = (nameValue: string) => {
    setName(nameValue);
    setShouldShowErrors(false);
  };

  const clickSaveButton = () => {
    if (isValid) {
      onCreate(name, optionSelected);
      onClose();
    } else {
      setShouldShowErrors(true);
    }
  };

  return (
    <ScrollableModal
      title={translate('dashboard_create_modal_title')}
      primaryButton={{
        actionFn: clickSaveButton,
        title: translate('dashboard_create_modal_button'),
        isDisabled: false,
      }}
      onClose={onClose}
      footer={{ isTopBorderHidden: true }}
    >
      <div className={styles.modalBody}>
        <span className={styles.modalInfo}>
          {translate('dashboard_create_modal_info', partner.name)}
        </span>
        <span className={styles.dashboardNameLabel}>
          {translate('dashboard_create_modal_choose_name')}
        </span>
        <WrapperErrorHint
          message={translate(DASHBOARD_ERROR_VALIDATION_TO_TRANSLATION_KEY[validationErrors[0]])}
          isShown={!isValid && shouldShowErrors}
          extraClasses={styles.errorHint}
        >
          <InputIcon
            placeholder={translate('dashboard_create_modal_input_placeholder')}
            value={name}
            autoFocus
            onChange={event => changeName(event.target.value)}
            className={cn(styles.nameField, 'c-input', {
              [styles.inputError]: !isValid && shouldShowErrors,
            })}
            onKeyPress={event => pressKeyEnter(event.key, clickSaveButton)}
          />
        </WrapperErrorHint>
        <div className={styles.dashboardTemplateSelector}>
          {DASHBOARD_TEMPLATE_OPTIONS.map(({ option, imageSelected, imageUnselected, key }) => (
            <div key={option} className={styles.dashboardOption}>
              <img
                src={isOptionSelected(option) ? imageSelected : imageUnselected}
                className={cn({
                  [styles.unselectedOption]: !isOptionSelected(option),
                })}
                alt="Option showing create a new dashboard"
              />
              <div className={styles.radioButtonWrapper}>
                <RadioButton
                  title={translate(key as TranslationKey)}
                  isChecked={isOptionSelected(option)}
                  onChange={() => setOptionSelected(option)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </ScrollableModal>
  );
};

const DASHBOARD_TEMPLATE_OPTIONS = [
  {
    option: DashBoardType.EMPTY,
    imageUnselected: imgBlankUnselected,
    imageSelected: imgBlankSelected,
    key: 'dashboard_create_modal_blank_option',
  },
  {
    option: DashBoardType.DEFAULT,
    imageUnselected: imgStarterUnselected,
    imageSelected: imgStarterSelected,
    key: 'dashboard_create_modal_starter_option',
  },
];
