import { useEffect, useState } from 'react';
import { ReportsApi } from '../../helpers/api/reports/reports-api';
import { ReportDefinition } from '../../views/reports/report.types';

interface UseReportDefinitionsResult {
  definitions: ReportDefinition[];
  isLoading: boolean;
  error?: string;
}

export function useReportDefinitions(reportName: string): UseReportDefinitionsResult {
  const [definitions, setDefinitions] = useState<ReportDefinition[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  async function fetchDefinitions() {
    try {
      setLoading(true);
      setError(undefined);
      setDefinitions(await ReportsApi.fetchReportDefinitions(reportName));
    } catch (e) {
      console.log(e);
      setError((e as any).message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDefinitions();
  }, [reportName]);

  return {
    isLoading,
    error,
    definitions,
  };
}
