import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { EventType } from '../../../models';
import ApiHistoryHelper from '../../../helpers/api/history/api-history-helper';
import { AxiosError } from 'axios';

const jobTypes = [
  EventType.PUSH,
  EventType.PUSH_BM,
  EventType.PUSH_PROPERTY_GROUPS,
  EventType.PUSH_SL_BID,
  EventType.PUSH_SL_BM,
  EventType.PUSH_SL_BUDGET_CAP,
  EventType.PUSH_CAMPAIGN_ALLOCATIONS,
  EventType.FILE_CPA_VALUE,
];

async function fetchRemainingPushes(partnerId: number): Promise<Record<number, number>> {
  const remainingPushes = await ApiHistoryHelper.fetchRemainingPushes(partnerId);
  return Object.fromEntries(remainingPushes.map(el => [el.type, el.pushesRemaining]));
}

export function useRemainingPushes(
  partnerId: number,
): UseQueryResult<Record<number, number>, AxiosError> {
  return useQuery(['remaining-pushes', partnerId, jobTypes.sort().join()], () =>
    fetchRemainingPushes(partnerId),
  );
}
