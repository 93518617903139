import { useConvertBaseBidFileModal } from '../use-convert-base-bid-file-modal';
import { trackBaseBidFileConverterEvent } from './tracking';
import { validateCsvHeaders } from '../../../helpers/csv/validation/validate-csv-headers';
import { isValidLocaleCode } from '../../../helpers/csv/validation/rules/valid-locale-code-rule';
import { HeaderValidationRule } from '../../../helpers/csv/validation/row-validation-rule';

export function useBaseBidFileConverter() {
  const showConvertBaseBidFileModal = useConvertBaseBidFileModal();

  async function convert(file: File) {
    const errors = await validateCsvHeaders(file, [new OldBaseBidFileFormatRule()]);
    const isOldBaseBidFileFormat = errors.length > 0;
    if (isOldBaseBidFileFormat) {
      showConvertBaseBidFileModal(file);
      trackBaseBidFileConverterEvent('old_base_bid_file_format', file, { errors });
    }
    return { isOldBaseBidFileFormat };
  }

  return { convert };
}

class OldBaseBidFileFormatRule implements HeaderValidationRule {
  validate(headers: string[]): string | null {
    const [firstHeader, ...remainingHeaders] = headers;
    if (!isValidLocaleCode(firstHeader) && someAreValidLocales(remainingHeaders)) {
      return 'Old base bid format file detected';
    }
    return null;
  }
}

const someAreValidLocales = (headers: string[]): boolean =>
  headers.some(header => isValidLocaleCode(header));
