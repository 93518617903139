import { useQueryParam } from '../../../hooks/use-query-param';

export function useMembersFilters() {
  const [includeTrivagoEmployees, setIncludeTrivagoEmployees] =
    useQueryParam('includeTrivagoEmployees');
  const [partnerId, setPartnerId] = useQueryParam('partnerId');
  const [email, setEmail] = useQueryParam('email');

  return {
    includeTrivagoEmployees: includeTrivagoEmployees === 'true',
    setIncludeTrivagoEmployees: (value: boolean) =>
      setIncludeTrivagoEmployees(value ? 'true' : 'false'),
    partnerId: partnerId ? Number(partnerId) : 0,
    setPartnerId: (value: number) => setPartnerId(String(value)),
    email: email || '',
    setEmail,
  };
}
