import endOfISOWeek from 'date-fns/end_of_iso_week';
import lastDayOfMonth from 'date-fns/last_day_of_month';
import lastDayOfYear from 'date-fns/last_day_of_year';
import startOfISOWeek from 'date-fns/start_of_iso_week';
import startOfMonth from 'date-fns/start_of_month';
import startOfYear from 'date-fns/start_of_year';
import subMonths from 'date-fns/sub_months';
import subWeeks from 'date-fns/sub_weeks';
import subYears from 'date-fns/sub_years';
import { DatePeriod, DateRangePeriod } from '../models';
import { getLastXDays } from './utils';
import { isToday } from 'date-fns';
import { DAYS_IN_LAST_12_MONTHS } from '../constants';

export function parseDateRangePeriod({ from, to, period }: DateRangePeriod): DateRangePeriod {
  const today = new Date();
  const yesterday = getLastXDays(1);

  switch (period) {
    case DatePeriod.CUSTOM:
      return {
        from: new Date(from),
        to: new Date(to),
        period,
      };
    case DatePeriod.YESTERDAY:
      return {
        from: yesterday,
        to: yesterday,
        period,
      };
    case DatePeriod.LAST_2:
      return {
        from: getLastXDays(2),
        to: yesterday,
        period,
      };
    case DatePeriod.LAST_3:
      return {
        from: getLastXDays(3),
        to: yesterday,
        period,
      };
    case DatePeriod.LAST_7:
      return {
        from: getLastXDays(7),
        to: yesterday,
        period,
      };
    case DatePeriod.LAST_30:
      return {
        from: getLastXDays(30),
        to: yesterday,
        period,
      };
    case DatePeriod.THIS_WEEK: {
      const startDate = startOfISOWeek(today);
      const endDate = isToday(startDate) ? startDate : yesterday;
      return {
        from: startDate,
        to: endDate,
        period,
      };
    }
    case DatePeriod.LAST_WEEK: {
      const lastWeek = subWeeks(today, 1);
      return {
        from: startOfISOWeek(lastWeek),
        to: endOfISOWeek(lastWeek),
        period,
      };
    }
    case DatePeriod.THIS_MONTH: {
      const startDate = startOfMonth(today);
      const endDate = isToday(startDate) ? startDate : yesterday;
      return {
        from: startDate,
        to: endDate,
        period,
      };
    }
    case DatePeriod.LAST_MONTH: {
      const lastMonth = subMonths(today, 1);
      return {
        from: startOfMonth(lastMonth),
        to: lastDayOfMonth(lastMonth),
        period,
      };
    }
    case DatePeriod.LAST_12_MONTHS:
      return {
        from: getLastXDays(DAYS_IN_LAST_12_MONTHS),
        to: yesterday,
        period,
      };
    case DatePeriod.THIS_YEAR: {
      const startDate = startOfYear(today);
      const endDate = isToday(startDate) ? startDate : yesterday;
      return {
        from: startDate,
        to: endDate,
        period,
      };
    }
    case DatePeriod.LAST_YEAR: {
      const lastYear = subYears(today, 1);
      return {
        from: startOfYear(lastYear),
        to: lastDayOfYear(lastYear),
        period,
      };
    }
    default:
      return {
        from: new Date(from),
        to: new Date(to),
        period,
      };
  }
}
