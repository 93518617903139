import trackerService from '../../services/tracker/tracker-service';

export function trackCsvValidation(eventName: string, file: File, properties: any = {}) {
  trackerService.trackWithPrefix('csv_validation', eventName, {
    fileName: file.name,
    fileSize: file.size,
    fileType: file.type,
    ...properties,
  });
}
