import * as React from 'react';
import { Props, REVERT_BID_OPTIONS } from './revert-bid-changes.types';
import './revert-bid-changes.css';
import { CONFIRM_REVERT, NotificationLevel, RevertType } from '../../../../constants';
import TrackerService from '../../../../services/tracker/tracker-service';
import { AppContext } from '../../../../global/context/app-context';
import { getPartner } from '../../../../services/app-service';
import { applyRevert } from '../../../../services/bidding-service';
import { showNotification } from '../../../../services/notification-service';
import { Revert } from '../../../../components/revert';

export const RevertBidChanges: React.FunctionComponent<Props> = () => {
  const { partner, subPartners } = React.useContext(AppContext);

  const onApply = async (revertType: RevertType) => {
    TrackerService.track(CONFIRM_REVERT, { biddingType: revertType });
    const effectivePartnerId = getPartner(partner, subPartners).partnerId;
    try {
      await applyRevert(effectivePartnerId, revertType);
      showNotification({
        message: 'analytics_revert_success',
        level: NotificationLevel.SUCCESS,
      });
    } catch (e) {
      showNotification({
        message: 'analytics_revert_error',
        level: NotificationLevel.ERROR,
      });
    }
  };

  return (
    <Revert
      options={[
        REVERT_BID_OPTIONS.BIDS,
        REVERT_BID_OPTIONS.MODIFIERS,
        REVERT_BID_OPTIONS.PROPERTY_GROUPS,
        REVERT_BID_OPTIONS.CAMPAIGN_ALLOCATION,
        REVERT_BID_OPTIONS.CPA_VALUE,
      ]}
      onApply={onApply}
    />
  );
};
