import { RequestHelper } from '../request-helper';
import { AGGREGATION_NAME, TimeOption, VIEW_NAME } from '../../../constants';
import { getFormattedDates } from '../../utils';
import { MetricColumn, View, ViewData } from '../../../models';
import { WidgetPayload } from './types';
import { ApiViewData } from '../views/types';
import { AxiosError } from 'axios';
import { CHART_DATA_DATE_COLUMN_TIME_OPTION } from '../../../views/custom-dashboard/widget';

const URL_GET_COLUMNS = `analytics/columns/{view}`;
const URL_GET_VIEW_DATA = `analytics/data/{view}`;
const URL_MOVE_WIDGET =
  'metrics/partners/{partnerId}/dashboards/{sourceDashboardId}/reassign/{destinationDashboardId}';

class ApiWidgetsHelper extends RequestHelper {
  private static instance: ApiWidgetsHelper;

  public static getInstance() {
    if (!ApiWidgetsHelper.instance) {
      ApiWidgetsHelper.instance = new ApiWidgetsHelper();
    }

    return ApiWidgetsHelper.instance;
  }

  public async getColumns(view: View, partnersId: number[]): Promise<MetricColumn[]> {
    const params = [{ key: 'view', value: VIEW_NAME[view] }];
    const url = RequestHelper.replaceUrlParams(URL_GET_COLUMNS, params);
    try {
      const response = await this.postRequest(url, {
        partnerId: partnersId,
      });
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'widget_columns_error');
      return [];
    }
  }

  public async getWidgetsData(
    view: View,
    {
      aggregations,
      condition,
      partnersId,
      localeCodes,
      from,
      to,
      sort,
      type,
      shownColumns,
      timeOption,
    }: WidgetPayload,
  ): Promise<ViewData[]> {
    const params = [{ key: 'view', value: VIEW_NAME[view] }];

    const body: any = {
      partnerId: partnersId,
    };
    if (sort !== undefined && sort.length) {
      body.sort = sort;
    }
    if (aggregations !== undefined) {
      body.aggregation = aggregations.map(aggregation => AGGREGATION_NAME[aggregation]);
    }
    if (localeCodes !== undefined) {
      body.localeCodes = localeCodes;
    }
    if (type !== undefined) {
      body.type = type;
    }
    if (from !== undefined && to !== undefined) {
      body.date = getFormattedDates(from, to);
    }
    if (condition !== undefined) {
      body.condition = condition;
    }
    if (shownColumns !== undefined && shownColumns.length) {
      body.shownColumns = shownColumns;
    }

    const url = RequestHelper.replaceUrlParams(URL_GET_VIEW_DATA, params);

    try {
      const response = await this.postRequest(url, body);
      return (response.data as ApiViewData[]).map(row =>
        timeOption ? this.apiViewData2Object(row, timeOption) : (row as ViewData),
      );
    } catch (e) {
      this.handleError(e as AxiosError, 'widget_data_error');
      return [];
    }
  }

  public async moveWidget(request: MoveWidgetRequest) {
    const params = Object.entries(request).map(([key, value]) => ({ key, value }));
    const url = RequestHelper.replaceUrlParams(URL_MOVE_WIDGET, params);
    try {
      await this.postRequest(url, { widgetUuid: request.widgetUuid });
    } catch (e) {
      this.handleError(e as AxiosError, 'widget_move_error');
    }
  }

  private apiViewData2Object = (
    apiViewData: ApiViewData,
    aggregationPeriod: TimeOption,
  ): ViewData => {
    const viewData: ViewData = {
      date: Number(apiViewData[CHART_DATA_DATE_COLUMN_TIME_OPTION[aggregationPeriod]]),
    };
    Object.keys(apiViewData).forEach(key => {
      const value = apiViewData[key];
      viewData[key] = value;
      if (typeof value === 'string' && (value === '' || value === '-')) {
        viewData[key] = undefined;
      }
    });

    return viewData;
  };
}

interface MoveWidgetRequest {
  partnerId: number;
  sourceDashboardId: number;
  destinationDashboardId: number;
  widgetUuid: string;
  newPosition: { x: number; y: number };
}

export default ApiWidgetsHelper.getInstance();
