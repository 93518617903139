import { useState } from 'react';
import {
  getIncludeNonPerformingDataFromLocalStorage,
  saveIncludeNonPerformingDataToLocalStorage,
} from '../../../helpers/local-storage-helper';

export function useIncludeNonPerformingData() {
  const [includeNonPerformingData, _setIncludeNonPerformingData] = useState(
    getIncludeNonPerformingDataFromLocalStorage(),
  );

  function setIncludeNonPerformingData(value: boolean) {
    _setIncludeNonPerformingData(value);
    saveIncludeNonPerformingDataToLocalStorage(value);
  }

  return {
    includeNonPerformingData,
    setIncludeNonPerformingData,
  };
}
