import { AxiosError } from 'axios';
import { RequestHelper } from '../../../helpers/api/request-helper';

const URL_GET_INVENTORY_STATS = '/inventory-stats/latest';

export interface ApiInventoryStats {
  partnerId: number;
  updatedAt: string;
  taskId: string;
  numFailedMatches: number;
  numDuplicatedMatches: number;
}

class ApiInventoryStatsHelper extends RequestHelper {
  public async getInventoryStats(partnerIds: number[]): Promise<ApiInventoryStats[]> {
    try {
      const response = await this.getRequest(URL_GET_INVENTORY_STATS, {
        params: { partnerIds },
        paramsSerializer: params =>
          (params.partnerIds as number[]).map(t => `partnerIds=${t}`).join('&'),
      });
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'widget_data_error');
      return [];
    }
  }
}

export default new ApiInventoryStatsHelper();
