import { useEffect, useState } from 'react';
import { FilterType } from '../../models';
import ApiAnalyticsHelper from '../../helpers/api/analytics/api-analytics-helper';
import { showNotification } from '../../services/notification-service';
import { NotificationLevel } from '../../constants';
import { translate } from '../../helpers/utils';

export const useFilterTypes = (): [FilterType[]] => {
  const [filterTypes, setFilterTypes] = useState<FilterType[]>([]);

  const fetchFilterTypes = async () => {
    try {
      const filters = await ApiAnalyticsHelper.fetchFilterTypes();
      setFilterTypes(filters);
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  useEffect(() => {
    fetchFilterTypes();
  }, []);

  return [filterTypes];
};
