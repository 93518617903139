import { apiBiddingHelper } from '../../helpers/api/bidding/api-bidding-helper';
import { useQuery } from '@tanstack/react-query';
import { useSelectedLocales } from '../use-selected-locales/use-selected-locales';
import { useSelectedPartner } from '../use-selected-partner';
import { areMultiplePOSorGlobalSelected } from '../../services/app-service';

export function useCPCBidCapsByLocale() {
  const { partnerId } = useSelectedPartner();
  const MAX_CPC_BIDS_KEY = ['max_cpc_bids', partnerId];
  const { data, isLoading } = useQuery({
    queryKey: MAX_CPC_BIDS_KEY,
    queryFn: () => apiBiddingHelper.getMaxCPCBidCaps(partnerId),
  });
  const bidCaps: Record<string, number> = {};
  for (const value of data ?? []) {
    bidCaps[value.localeCode.toLowerCase()] = value.bidCap;
  }

  return { isLoading, bidCaps };
}

export function useMaxCPCBidCap() {
  const selectedLocales = useSelectedLocales();
  const { isLoading, bidCaps } = useCPCBidCapsByLocale();

  const validLocaleForBidding = !areMultiplePOSorGlobalSelected(selectedLocales);
  let selectLocaleMaxBidCap;

  if (validLocaleForBidding) {
    const filteredBidCap = bidCaps[selectedLocales[0].toLowerCase()];
    if (filteredBidCap) {
      selectLocaleMaxBidCap = filteredBidCap / 100;
    }
  }

  return {
    maxCPCBidCap: selectLocaleMaxBidCap,
    isLoading,
  };
}
