import { useState } from 'react';
import { Pagination } from '../../models';

export const usePagination = (
  numPage: number = 1,
  numItemsPerPage: number = 50,
): UsePaginationRtn => {
  const [pag, setPag] = useState<Pagination>({
    numPage,
    numItemsPerPage,
  });

  const setPrevPage = () => {
    if (pag.numPage > 1) {
      setPag({ numPage: pag.numPage - 1, numItemsPerPage: pag.numItemsPerPage });
    }
  };

  const resetPagination = () => {
    setPag({ numPage: 1, numItemsPerPage: pag.numItemsPerPage });
  };

  const setNumItemsPerPage = (numItemsPerPageParam: number) => {
    if (numItemsPerPageParam >= 1) {
      setPag({ numPage: pag.numPage, numItemsPerPage: numItemsPerPageParam });
    }
  };

  return {
    pagination: pag,
    resetPagination,
    setPrevPage,
    setNextPage: () => {
      setPag({ numPage: pag.numPage + 1, numItemsPerPage: pag.numItemsPerPage });
    },
    setNumItemsPerPage,
  };
};

export type UsePaginationRtn = {
  pagination: Pagination;
  resetPagination: () => void;
  setPrevPage: () => void;
  setNextPage: () => void;
  setNumItemsPerPage: (numItemsPerPageParam: number) => void;
};
