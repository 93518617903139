import * as React from 'react';
import { Props } from './notification-drawer.types';
import './notification-drawer.css';
import { Popover, PopoverDirection } from '../../../../../components/popover';
import { translate } from '../../../../../helpers/utils';
import cn from 'classnames';
import { Loading } from '../../../../../components/loading';
import { NotificationDrawerHeader } from '../notification-drawer-header';
import { NotificationDrawerItem } from '../notification-drawer-item';

export const NotificationDrawer: React.FunctionComponent<Props> = ({
  notifications,
  isLoading,
  onScroll,
  onClose,
  onMarkAllAsRead,
  onRead,
  onShowModal,
}) => {
  return (
    <Popover direction={PopoverDirection.BOTTOM_TRAILING} tooltipType="notificationDrawer">
      <div className="c-notification-drawer u-flex-direction--column ">
        <NotificationDrawerHeader
          isShowingMarkAllAsRead={!isLoading && notifications.length > 0}
          onMarkAllAsRead={onMarkAllAsRead}
          onClose={onClose}
          onShowModal={onShowModal}
        />

        <div className="c-notification-drawer__notifications" onScroll={onScroll}>
          {notifications.map((notification, i) => (
            <div className="u-flex-direction--column" key={i}>
              <div
                className={cn('u-margin-left--gutter--small u-margin-right--gutter--small', {
                  'u-border-vertical u-border--juri-very-light': i === 0,
                  'u-border-bottom  u-border--juri-very-light':
                    i < notifications.length - 1 && i !== 0,
                  'u-margin-bottom--gutter--small': i === notifications.length - 1,
                })}
              >
                <NotificationDrawerItem
                  notification={notification}
                  onRead={() => onRead(notification.id, !notification.isRead)}
                />
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="u-position--relative">
              <Loading isDark={true} isWhite={false} />
            </div>
          )}
          {!isLoading && notifications.length === 0 && (
            <div className="c-notification-drawer__no-results u-color--juri-lighter u-padding-bottom--huge u-padding-top--huge">
              {translate('notifications_no_results')}
            </div>
          )}
        </div>
      </div>
    </Popover>
  );
};
