import { FC, useEffect, useState } from 'react';
import DayPicker from 'react-day-picker';
import { formattedDate, translate } from '../../helpers/utils';
import cn from 'classnames';
import styles from './single-date-picker.module.css';
import { addDays, isValid, startOfToday, subDays } from 'date-fns';

export type SingleDatePickerProps = {
  onSelectedDate: (day: Date) => void;
  selectedDate?: Date;
  periodLength?: number;
};

export const SingleDatePicker: FC<SingleDatePickerProps> = ({
  onSelectedDate,
  selectedDate,
  periodLength,
}) => {
  const [inputDateFromRef, setInputDateFromRef] = useState<HTMLInputElement | null>(null);
  useEffect(() => {
    if (inputDateFromRef) inputDateFromRef.select();
  }, [inputDateFromRef]);

  const [dateInput, setDateInput] = useState<string>(
    selectedDate ? formattedDate(selectedDate) : '',
  );

  const handleDayClick = (day: Date) => {
    if (day > startOfToday()) return;
    onSelectedDate(day);
    setDateInput(formattedDate(day));
  };

  return (
    <div className={styles.singleDatePickerContainer}>
      <DayPicker
        className={cn('DatePicker Selectable', styles.dayPicker)}
        numberOfMonths={2}
        selectedDays={selectedDate}
        onDayClick={handleDayClick}
        fixedWeeks={false}
        showOutsideDays
        toMonth={startOfToday()}
        firstDayOfWeek={1}
        disabledDays={date => date > startOfToday()}
      />

      <div className={styles.dayPickerWrapper}>
        <h4 className={styles.dayPickerLabel}>{translate('pop_custom_starting_date')}</h4>
        <div className={styles.datePickerInputWrapper}>
          <input
            ref={setInputDateFromRef}
            data-qa="date-picker-from"
            className={cn(styles.datePickerInput, {
              [styles.invalidDate]: selectedDate && !isValid(selectedDate),
            })}
            value={dateInput}
            placeholder="Select a date"
            type="text"
            onChange={event => setDateInput(event.target.value)}
            onBlur={event => {
              const date = new Date(event.target.value);
              if (!isValid(date)) {
                setDateInput('Invalid Date');
                return;
              }
              if (date > startOfToday()) {
                handleDayClick(subDays(startOfToday(), 1));
                return;
              }

              handleDayClick(date);
            }}
          />

          <input
            data-qa="date-picker-to"
            className={cn(styles.datePickerInput, styles.disabledInput)}
            value={
              isValid(new Date(dateInput))
                ? formattedDate(addDays(selectedDate || startOfToday(), periodLength ?? 0))
                : ''
            }
            type="text"
            disabled
          />
        </div>
      </div>
    </div>
  );
};
