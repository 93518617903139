import { FC, useRef, useState } from 'react';
import { useOnClickOutside } from '../../../hooks/use-on-click-outside';
import { Button } from '../../../core-ui/components/button/button';
import styles from './property-group-cell.module.css';
import { useOnEscape } from '../../../hooks/use-on-escape';
import { useUpdateAliasMutation } from '../../../hooks/use-property-groups';
import { Partner } from '../../../models';

const MAX_ALIAS_LENGTH = 80;

type EditAliasDialogProps = {
  onClose: VoidFunction;
  propertyGroupId: number;
  initialValue?: string;
  localeCode: string;
  partner: Partner;
};

export const EditAliasDialog: FC<EditAliasDialogProps> = ({
  onClose,
  propertyGroupId,
  initialValue,
  partner,
  localeCode,
}) => {
  const ref = useRef(null);
  const [alias, setAlias] = useState(initialValue ?? '');
  function setAliasIfValid(value: string) {
    if (value.length > MAX_ALIAS_LENGTH) return;
    setAlias(value);
  }
  const mutation = useUpdateAliasMutation();

  const updateAlias = () => {
    mutation.mutate({
      partnerId: partner.partnerId,
      propertyGroupId,
      localeCode,
      alias: alias ? alias : null,
    });
    onClose();
  };

  useOnClickOutside(ref, onClose);
  useOnEscape(onClose);

  return (
    <div ref={ref} className={styles.editAliasDialog} onClick={e => e.stopPropagation()}>
      <form className={styles.form} onSubmit={updateAlias}>
        <div className={styles.inputWrapper}>
          <input
            autoFocus
            type="text"
            placeholder="Add alias"
            value={alias}
            onChange={e => setAliasIfValid(e.target.value)}
          />
          <div className={styles.notes}>
            <p>
              {partner.name} · {localeCode} · PG {propertyGroupId}
            </p>
            <p>
              {alias.length} / {MAX_ALIAS_LENGTH}
            </p>
          </div>
        </div>
        <Button variant="primary" size="large" text="Save" type="submit" />
        <Button variant="tertiary" size="large" text="Cancel" onClick={onClose} />
      </form>
    </div>
  );
};
