import { RequestHelper } from '../request-helper';
import { DashBoard, DashBoardType } from '../../../models';
import {
  API_WIDGET_TYPE_2_WIDGET_TYPE,
  ApiDashboardSaveResponse,
  ApiDashBoard,
  WIDGET_TYPE_2_API_WIDGET_TYPE,
  DASHBOARD_CREATION_TYPE_TO_DASHBOARD_TYPE,
  ApiDashboardEditResponse,
} from './types';
import { AxiosError } from 'axios';

const BASE_URL = '/metrics/partners/{partnerId}/dashboards';

const URL_GET_ALL_DASHBOARDS = `${BASE_URL}`;
const URL_CREATE_DASHBOARDS = `${BASE_URL}`;
const URL_EDIT_DASHBOARD = `${BASE_URL}/{dashboardId}`;
const URL_DELETE_DASHBOARD = `${BASE_URL}/{dashboardId}`;
const URL_RESET_DASHBOARD = `${BASE_URL}/{dashboardId}/reset`;

class ApiDashboardHelper extends RequestHelper {
  private static instance: ApiDashboardHelper;

  public static getInstance() {
    if (!ApiDashboardHelper.instance) {
      ApiDashboardHelper.instance = new ApiDashboardHelper();
    }

    return ApiDashboardHelper.instance;
  }

  public async getAll(partnerId: number): Promise<DashBoard[]> {
    const params = [{ key: 'partnerId', value: partnerId }];
    const url = RequestHelper.replaceUrlParams(URL_GET_ALL_DASHBOARDS, params);
    try {
      const response = await this.getRequest(url);
      return (response.data as ApiDashBoard[]).map(apiDashboard =>
        this.apiObject2Model(apiDashboard),
      );
    } catch (e) {
      this.handleError(e as AxiosError, 'dashboards_get_all_error');
      return [];
    }
  }

  public async save(
    partnerId: number,
    name: string,
    type: DashBoardType,
  ): Promise<ApiDashboardSaveResponse> {
    const params = [{ key: 'partnerId', value: partnerId }];
    const url = RequestHelper.replaceUrlParams(URL_CREATE_DASHBOARDS, params);
    const body = {
      name,
      type: DASHBOARD_CREATION_TYPE_TO_DASHBOARD_TYPE[type],
    };

    try {
      const response = await this.postRequest(url, body);
      return response.data as ApiDashboardSaveResponse;
    } catch (e) {
      this.handleError(e as AxiosError, 'dashboards_save_error');
      return {} as ApiDashboardSaveResponse;
    }
  }

  public async edit(partnerId: number, dashboard: DashBoard): Promise<ApiDashboardEditResponse> {
    const params = [
      { key: 'partnerId', value: partnerId },
      { key: 'dashboardId', value: dashboard.id },
    ];
    const url = RequestHelper.replaceUrlParams(URL_EDIT_DASHBOARD, params);
    const body: ApiDashBoard = this.model2ApiObject(dashboard);
    try {
      const response = await this.putRequest(url, body);
      return response.data as ApiDashboardEditResponse;
    } catch (e) {
      this.handleError(e as AxiosError, 'dashboards_edit_error');
      return {} as ApiDashboardEditResponse;
    }
  }

  public async delete(partnerId: number, dashboardId: number): Promise<void> {
    const params = [
      { key: 'partnerId', value: partnerId },
      { key: 'dashboardId', value: dashboardId },
    ];
    const url = RequestHelper.replaceUrlParams(URL_DELETE_DASHBOARD, params);
    try {
      await this.deleteRequest(url, {});
    } catch (e) {
      this.handleError(e as AxiosError, 'dashboards_delete_error');
    }
  }

  public async reset(partnerId: number, dashboardId: number): Promise<void> {
    const params = [
      { key: 'partnerId', value: partnerId },
      { key: 'dashboardId', value: dashboardId },
    ];
    const url = RequestHelper.replaceUrlParams(URL_RESET_DASHBOARD, params);
    try {
      await this.putRequest(url, {});
    } catch (e) {
      this.handleError(e as AxiosError, 'dashboards_delete_error');
    }
  }

  private apiObject2Model(apiDashboard: ApiDashBoard): DashBoard {
    const { id, name, widgets: apiWidgets } = apiDashboard;

    const widgets = apiWidgets.map(
      ({ uuid, position, size, isResizable, isDraggable, type, props }) => {
        return {
          uuid,
          position,
          size,
          isResizable,
          isDraggable,
          type: API_WIDGET_TYPE_2_WIDGET_TYPE[type],
          props: props === undefined ? {} : props,
        };
      },
    );

    return { id, name, widgets };
  }

  private model2ApiObject(dashboard: DashBoard): ApiDashBoard {
    const { id, name, widgets } = dashboard;

    const apiWidgets = widgets.map(widget => {
      return {
        ...widget,
        type: WIDGET_TYPE_2_API_WIDGET_TYPE[widget.type],
      };
    });

    return { id, name, widgets: apiWidgets };
  }
}

export default ApiDashboardHelper.getInstance();
