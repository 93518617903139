import { FC } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatNumber } from '../../helpers/formater';
import { Props } from './chart.types';
import { Color } from '../../constants';
import { WidgetTooltip } from '../widget-tooltip';
import './chart.css';
import { WidgetType } from '../../models';
import { ChartBuilderLineChart } from './chart-builder-line-chart';

export const LineChartComponent: FC<Props> = ({
  data,
  columns,
  chartProps,
  variant,
  isFullScreen = false,
  lineType = 'monotone',
  lineDot = false,
  xAxisDataKey = 'date',
  tickFormatterX,
  toolTipTitle,
  tickLineX = false,
  tickLineY = false,
  xAxisLabel,
  leftYAxisLabel,
  rightYAxisLabel,
  widgetType = WidgetType.TIMELINE,
  chartHeight,
}) => {
  const calculateWidth = (columnKey: string) => {
    const biggestValue = Math.round(
      Math.max(
        ...data.map(dataTmp => {
          const valueTmp = dataTmp[columnKey];
          return typeof valueTmp === 'number' ? valueTmp : 0;
        }),
      ) * 1.1,
    );

    const wrapper = columns.find(columnTmp => columnTmp.key === columnKey)!.wrapper;
    const value = formatNumber(biggestValue, wrapper);

    if (value.length > 8) {
      return 80;
    } else if (value.length <= 5) {
      return 40;
    }
    return 60;
  };
  const wrappers: string[] = [];

  chartProps.metrics.forEach(metric => {
    const wrapperTmp = columns.find(metricTmp => metricTmp.key === metric.id);
    if (wrapperTmp) {
      wrappers.push(wrapperTmp.wrapper);
    }
  });

  if (widgetType === WidgetType.CHART_BUILDER)
    return (
      <ChartBuilderLineChart
        data={data}
        columns={columns}
        chartProps={chartProps}
        variant={variant}
        isFullScreen={isFullScreen}
        lineType={lineType}
        lineDot={lineDot}
        xAxisDataKey={xAxisDataKey}
        tickFormatterX={tickFormatterX}
        toolTipTitle={toolTipTitle}
        tickLineX={tickLineX}
        tickLineY={tickLineY}
        xAxisLabel={xAxisLabel}
        leftYAxisLabel={leftYAxisLabel}
        rightYAxisLabel={rightYAxisLabel}
        widgetType={widgetType}
        chartHeight={chartHeight}
      />
    );
  return (
    <ResponsiveContainer width="100%" height={isFullScreen ? 620 : 270}>
      <LineChart data={data} margin={{ left: 16, top: 16, right: 16, bottom: 16 }}>
        <CartesianGrid stroke={Color.Grey200} vertical={false} />
        {chartProps &&
          chartProps.metrics.map((metric, index) => (
            <Line
              type={lineType}
              dataKey={metric.id}
              stroke={metric.color}
              dot={lineDot}
              key={metric.id}
              yAxisId={variant === 'doubleAxisLine' && index === 1 ? 'right' : 'left'}
            />
          ))}

        <XAxis
          dataKey={xAxisDataKey}
          tickFormatter={tickFormatterX}
          axisLine={{ stroke: Color.Grey200 }}
          stroke={Color.Grey500}
          tickLine={tickLineX}
        />
        <YAxis
          orientation="left"
          yAxisId="left"
          width={calculateWidth(chartProps.metrics[0].id)}
          dataKey={chartProps.metrics[0].id}
          tickFormatter={value =>
            formatNumber(
              value,
              columns.find(columnTmp => columnTmp.key === chartProps.metrics[0].id)!.wrapper,
            )
          }
          stroke={Color.Grey500}
          tickLine={tickLineY}
          axisLine={{ stroke: Color.Grey200 }}
        />
        {variant === 'doubleAxisLine' && (
          <YAxis
            orientation="right"
            yAxisId="right"
            width={calculateWidth(chartProps.metrics[1].id)}
            dataKey={chartProps.metrics[1].id}
            tickFormatter={value =>
              formatNumber(
                value,
                columns.find(columnTmp => columnTmp.key === chartProps.metrics[1].id)!.wrapper,
              )
            }
            stroke={Color.Grey500}
            tickLine={tickLineY}
            axisLine={{ stroke: Color.Grey200 }}
          />
        )}
        <Tooltip
          content={({ label, payload }: { label: string; payload: any[] }) => {
            if (!payload) {
              return;
            }
            return (
              <WidgetTooltip
                title={toolTipTitle!(label)}
                metrics={payload.map((payloadTmp, index: number) => {
                  return {
                    name: columns.find(metricTmp => metricTmp.key === payloadTmp.dataKey)!.name,
                    wrapper: wrappers[index],
                    color: payloadTmp.stroke,
                    value: payloadTmp.value,
                  };
                })}
              />
            );
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
