import cs from 'classnames';
import * as React from 'react';
import ArrowIcon from 'tcp-react-icons/lib/ArrowIcon';
import { FlagIcon } from '../flag-icon';
import './composed-dropdown.css';
import { Props } from './composed-dropdown.types';

export const ComposedDropdown: React.FunctionComponent<Props> = ({
  title,
  subTitle,
  isSelected,
  icon,
  onClick,
  extraClass = '',
  isReadOnlyMode = false,
  qaId,
}) => {
  return (
    <div
      className={cs(
        'c-composedDropdown u-display--flex u-align-items--center u-border-radius',
        {
          'u-background--juri-lightest': isSelected,
          'u-cursor--pointer c-composedDropdown__selectable': !isReadOnlyMode,
        },
        extraClass,
      )}
      data-qa={qaId}
      data-testid={qaId}
      onClick={() => {
        if (!isReadOnlyMode) {
          onClick();
        }
      }}
    >
      <div className="u-display--flex u-align-items--center">
        <FlagIcon flag={icon} className="composedDropdown__subTitle__icon" />
        <div>
          <div className="u-display--block u-display--flex u-align-items--center">
            <span className="u-color--juri-lighter u-margin-right--small">{title}</span>
          </div>
          <p className="c-composedDropdown__subTitle u-display--block u-display--flex">
            {subTitle}
            {!isReadOnlyMode && (
              <ArrowIcon
                className={cs(
                  'c-composedDropdown__arrow-icon u-margin-left--t-small u-font-weight--bold u-position--relative',
                  {
                    'c-composedDropdown__arrow-icon--upside-down': isSelected,
                  },
                )}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
