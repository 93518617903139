import { TranslationKey } from '../helpers/utils';
import { fromTo, ReportFrequency } from '../models';

export const REPORT_TO_KEY: fromTo<string, string> = {
  'bid-modifiers': 'reports_bid_modifiers',
  'bm-pos-report': 'reports_bm_pos_report',

  item_export: 'reports_item_export',
  'pos-report': 'reports_pos_report',
  'ras-report': 'reports_ras_report',
  'bm-pos-combined-view': 'reports_bm_pos_combined_view',
  'bm-property-combined-view': 'reports_bm_property_combined_view',
  'item-combined-view': 'reports_item_combined_view',
  'pos-combined-view': 'reports_pos_combined_view',
  'bm-property-group-suggestion-report': 'reports_bm_property_group_suggestion_report',
  'property-group-suggestion-report': 'reports_property_group_suggestion_report',
  'bml-champion-report': 'reports_bml_champion_report',
  'inventory-matched-active-report': 'reports_inventory_matched_active_report',
  'inventory-matched-inactive-report': 'reports_inventory_matched_inactive_report',
  'inventory-unmatched-active-report': 'reports_inventory_unmatched_active_report',
  'inventory-unmatched-inactive-report': 'reports_inventory_unmatched_inactive_report',

  item_export_tas: 'reports_item_export_tas',
  'clickshare-report': 'reports_clickshare_report',
  'clickshare-report-combined': 'reports_clickshare_report_combined',
  'clickshare-report-combined-aggregated': 'reports_clickshare_report_combined_aggregated',
  'sl-bm-combined-report': 'reports_sl_bm_combined_report',
  'meta-performance-report': 'reports_meta_performance_report',
  'meta-performance-report-v1-1': 'reports_meta_performance_report_v1_1',
  'meta-performance-report-v2': 'reports_meta_performance_report_v2',
  'meta-performance-report-v2-1': 'reports_meta_performance_report_v2_1',
  'meta-performance-combined-report-v2-1': 'reports_meta_performance_combined_v2_1',
  'meta-performance-report-v3-1': 'reports_meta_performance_report_v3_1',
  'meta-performance-combined-report-v3-1': 'reports_meta_performance_combined_v3_1',
  'meta-performance-integrity-check-report': 'reports_meta_performance_integrity_check_report',
  'meta-performance-combined-report': 'reports_meta_performance_combined',
  'meta-performance-combined-report-v1-1': 'reports_meta_performance_combined_v1_1',
  'meta-performance-combined-report-v2': 'reports_meta_performance_combined_v2',
  'meta-combined-integrity-report': 'reports_meta_performance_integrity_check_combined',
  'meta-performance-pos-report': 'reports_meta_performance_pos',
  'meta-performance-pos-combined-report': 'reports_meta_performance_pos_combined',
  'ras-pos-report-v1': 'reports_ras_pos_report_v1',
  'ras-pos-report-combined-v1': 'reports_ras_pos_report_combined_v1',
  'sl-bm-report-aggregated': 'reports_sl_bm_report',
  'sl-bm-report-aggregated-agency': 'reports_sl_bm_report_agency',
  'hotel-imp-report': 'reports_hotel_imp_impressions',
  'cpa-fallback-report': 'reports_cpa_fallback_report',
  'cpa-predictions-report': 'reports_cpa_predictions_report',
  'rac-report': 'reports_rac_report',
  'aa-cluster-impressions-report': 'reports_aa_cluster_performance_report',
  'aa-item-mapping-report': 'reports_aa_item_mapping_report',
  'cpa-cost-report': 'reports_cpa_cost_report',
  'cpa-cost-combined-report': 'reports_cpa_cost_combined_report',
  'rate-insights-report-v2': 'reports_rate_insights_report_v2',
  'rate-insights-combined-report-v2': 'reports_rate_insights_combined_report_v2',
  'sl-bid-suggestions-report-v1': 'reports_sl_bid_suggestions_report_v1',
};

export const FREQUENCY_TO_TRANSLATION: fromTo<ReportFrequency, TranslationKey> = {
  [ReportFrequency.WEEKLY]: 'reports_generated_weekly',
  [ReportFrequency.DAILY]: 'reports_generated_daily',
  [ReportFrequency.MONTHLY]: 'reports_generated_monthly',
};
