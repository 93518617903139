import * as React from 'react';
import { Props } from './assign-partner-modal-row.types';
import './assign-partner-modal-row.css';

export const AssignPartnerModalRow: React.FunctionComponent<Props> = ({
  partner: { partnerId, name },
  checkbox,
}) => {
  return (
    <>
      <td className="u-vertical-align--middle">
        {checkbox ? (
          <input
            type="checkbox"
            checked={checkbox.isChecked}
            onChange={() => checkbox.onChange(partnerId)}
          />
        ) : (
          <span> Already Assigned </span>
        )}
      </td>
      <td className="u-font-weight-bold"> {partnerId} </td>
      <td className="u-font-weight-bold"> {name} </td>
    </>
  );
};
