import { useState } from 'react';
import { SingleDatePicker } from '../../components/single-date-picker';
import { ModalDialog } from '../../core-ui/components/modal-dialog/modal-dialog';
import { useModal } from 'react-modal-hook';
import { differenceInCalendarDays, isValid } from 'date-fns';
import { useDateRangePeriod } from '../../components/date-picker/date-picker.state';

type Props = {
  onApply: (date: Date) => void;
};

export const useFullScreenDatePickerModal = ({ onApply }: Props) => {
  const [globalDateRangePeriod] = useDateRangePeriod();
  const periodLength = differenceInCalendarDays(
    globalDateRangePeriod.to,
    globalDateRangePeriod.from,
  );

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [appliedDate, setAppliedDate] = useState<Date>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const clearAppliedDate = () => {
    setSelectedDate(undefined);
    setAppliedDate(undefined);
  };

  const [showModal, hideFullScreenDatePicker] = useModal(
    () => (
      <ModalDialog
        onClose={() => {
          setSelectedDate(appliedDate);
          hideFullScreenDatePicker();
          setIsModalOpen(false);
        }}
        open
        hasNoContentPadding
        closeOnBackdropClick
        buttons={{
          end: [
            {
              text: 'Cancel',
              variant: 'tertiary',
              onClick: () => {
                setSelectedDate(appliedDate);
                hideFullScreenDatePicker();
                setIsModalOpen(false);
              },
            },
            {
              text: 'Apply',
              variant: 'primary',
              onClick: () => {
                setAppliedDate(selectedDate);
                if (selectedDate) onApply(selectedDate);
                hideFullScreenDatePicker();
                setIsModalOpen(false);
              },
              disabled: selectedDate ? !isValid(selectedDate) : true,
            },
          ],
        }}
      >
        <SingleDatePicker
          onSelectedDate={date => setSelectedDate(date)}
          selectedDate={selectedDate}
          periodLength={periodLength}
        />
      </ModalDialog>
    ),
    [selectedDate, periodLength, appliedDate],
  );

  const showFullScreenDatePicker = () => {
    setIsModalOpen(true);
    showModal();
  };

  return {
    showFullScreenDatePicker,
    customSelectedDate: appliedDate,
    clearSelectedDate: clearAppliedDate,
    isModalOpen,
  };
};
