import * as React from 'react';
import { Props, DatePickerAlignment } from './date-picker-dropdown.types';
import './date-picker-dropdown.css';
import { useState } from 'react';
import { ComposedInlineDropdown } from '../composed-inline-dropdown/composed-inline-dropdown';
import { format } from 'date-fns';
import { DATE_FORMAT_LONG_COMA } from '../../constants/constants';
import { DatePicker } from '../date-picker/date-picker';
import cs from 'classnames';

export const DatePickerDropdown: React.FunctionComponent<Props> = ({
  alignment,
  dateRange: { from, to },
  onUpdateDateRange,
}) => {
  const [isDatePickerVisible, setDatePicker] = useState(false);

  const handleCancel = (): void => setDatePicker(false);

  const updateRange = (nextFrom: Date, nextTo: Date): void => {
    onUpdateDateRange({ from: nextFrom, to: nextTo });
    setDatePicker(false);
  };

  const calculateDateRangeSubtitle = () => {
    const formatFrom = format(from, DATE_FORMAT_LONG_COMA);
    const formatTo = format(to, DATE_FORMAT_LONG_COMA);

    return (
      <span className="u-padding-left--medium c-date-picker-dropdown__text">
        {formatFrom} - {formatTo}
      </span>
    );
  };

  return (
    <>
      <article
        className={cs(
          'u-margin-left--small u-border--juri-lighter c-date-picker-dropdown u-radius-2px',
          {
            'c-date-picker-dropdown__opened': isDatePickerVisible,
          },
        )}
      >
        <div className="c-date-picker-dropdown__dropdown">
          <ComposedInlineDropdown
            subTitle={calculateDateRangeSubtitle()}
            extraClass="a u-position--relative js-calendar c-date-picker-dropdown__dropdown-extra"
            isSelected={false}
            alignArrowIconToRight={true}
            onClick={() => setDatePicker(!isDatePickerVisible)}
          />
        </div>
        {isDatePickerVisible && (
          <div
            className={cs('c-date-picker-dropdown__alignment', {
              'c-date-picker-dropdown__left-alignment': alignment === DatePickerAlignment.LEFT,
              'c-date-picker-dropdown__right-alignment': alignment === DatePickerAlignment.RIGHT,
            })}
          >
            <DatePicker
              offset="left"
              onClose={handleCancel}
              onUpdateRange={updateRange}
              from={from}
              to={to}
              isTodayChoosable={true}
            />
          </div>
        )}
      </article>
    </>
  );
};
