import cn from 'classnames';
import { Button } from '../button/button';
import { ButtonProps } from '../button/button.types';
import { Tooltip, TooltipProps } from '../tooltip/tooltip';
import styles from './segmented-buttons.module.css';

export type SegmentedButtonsProps<T> = {
  value: T | null;
  onChange: (newValue: T) => void;
  options: Option<T>[];
  disabled?: boolean;
};

type Option<T> = {
  value: T;
  text?: string;
  icon?: ButtonProps['icon'];
  tooltip?: TooltipProps;
};

// eslint-disable-next-line react/function-component-definition
export function SegmentedButtons<T>(props: SegmentedButtonsProps<T>) {
  const { value, onChange, options, disabled } = props;
  function onClick(option: Option<T>) {
    if (option.value === value) return;
    onChange(option.value);
  }

  function buttonOf(option: Option<T>, index: number) {
    const isFirst = index === 0;
    const isLast = index === options.length - 1;
    const isSelected = option.value === value && !disabled;
    const button = (
      <Button
        key={index}
        text={option.text}
        icon={option.icon}
        disabled={disabled}
        className={cn(styles.button, {
          [styles.selected]: isSelected,
          [styles.first]: isFirst,
          [styles.last]: isLast,
        })}
        variant={isSelected ? 'selected' : 'tertiary'}
        onClick={() => onClick(option)}
      />
    );

    if (!option.tooltip) return button;

    return (
      <Tooltip {...option.tooltip} key={index}>
        {button}
      </Tooltip>
    );
  }

  return (
    <div className={styles.segmentedButtons}>
      {options.map((option, index) => buttonOf(option, index))}
    </div>
  );
}
