import { DateRange } from './date-range';

export type DateRangePeriod = DateRange & { period: DatePeriod };

export enum DatePeriod {
  CUSTOM = 'analytics_calendar_custom_period',
  YESTERDAY = 'analytics_calendar_yesterday',
  LAST_2 = 'analytics_calendar_last_2_days',
  LAST_3 = 'analytics_calendar_last_3_days',
  LAST_7 = 'analytics_calendar_last_7_days',
  LAST_30 = 'analytics_calendar_last_30_days',
  THIS_WEEK = 'analytics_calendar_this_week',
  LAST_WEEK = 'analytics_calendar_last_week',
  THIS_MONTH = 'analytics_calendar_this_month',
  LAST_MONTH = 'analytics_calendar_last_month',
  LAST_12_MONTHS = 'analytics_calendar_last_12_months',
  THIS_YEAR = 'analytics_calendar_this_year',
  LAST_YEAR = 'analytics_calendar_last_year',
}
