/* eslint-disable @typescript-eslint/naming-convention */
export type ViewData = { [key: string]: string | number | undefined } & { date: number };

export enum View {
  HOTEL,
  POS,
  YESTERDAY_OVERVIEW_OLD,
  TOP_METRICS,
  INVESTMENT_RETURN,
  RAS_REPORT,
  AB_ACTIVE_ITEMS,
  HISTORY,
  TIME,
  TIME_DAY,
  TIME_WEEK,
  TIME_MONTH,
  HOTEL_DETAIL,
  HOTEL_DETAIL_DAY,
  HOTEL_DETAIL_WEEK,
  HOTEL_DETAIL_MONTH,
  RATING,
  STARS,
  ACCOMMODATION_TYPE,
  COUNTRY,
  REGION,
  CITY,
  HOTEL_WITHOUT_IMPRESSIONS,
  HOTEL_MULTI_LOCALE,
  INVENTORY,
  BM_POS_LENGTH_OF_STAY,
  BM_POS_TIME_TO_TRAVEL,
  BM_POS_COMBINED_MODIFIERS,
  TIMELINE,
  TIMELINE_WITH_SL,
  BIDDING_TYPE_CB_WITH_SL,
  BM_CB_WITH_SL,
  BRAND_CB_WITH_SL,
  NAME_PARTNERREF_CB_WITH_SL,
  POS_REGION_CB_WITH_SL,
  POS_CB_WITH_SL,
  PROPERTY_LOCATION_CB_WITH_SL,
  YESTERDAY_OVERVIEW_WITH_SL,
  PROPERTY_GROUPS_WITH_SL,
  YESTERDAY_OVERVIEW,
  POS_OVERVIEW,
  BMLU,
  BIDDING_SUGGESTION_DOWNLOAD,
  INVENTORY_MATCHED,
  INVENTORY_UNMATCHED,
  PROPERTY_STATIC_DETAILS,
  BM_POS_DEFAULT_DATE,
  CAMPAIGNS,
  TEB_RECOMMENDATIONS,
  HOTEL_WITHOUT_IMPRESSIONS_MULTI_LOCALE,
  SPONSORED_HOTEL,
  SPONSORED_POS,
  SPONSORED_HOTEL_DETAIL,
  SPONSORED_HOTEL_DETAIL_DAY,
  SPONSORED_HOTEL_DETAIL_WEEK,
  SPONSORED_HOTEL_DETAIL_MONTH,
  SPONSORED_CAMPAIGNS,
  BM_POS_GROUP_SIZE,
  SPONSORED_TIME,
  SPONSORED_TIME_DAY,
  SPONSORED_TIME_WEEK,
  SPONSORED_TIME_MONTH,
  BIDDING_TYPE,
  CAMPAIGN_PROPERTIES_CPC,
  CAMPAIGN_PROPERTIES_CPA,
  INACTIVE_PROPERTIES_CPC,
  INVISIBLE_PROPERTIES_CPC,
  CAMPAIGNS_NO_BID_DOWNLOAD,
  PROPERTY_GROUPS,
}

export enum Aggregation {
  HOTEL,
  POS,
  HOTEL_DETAIL_DAY,
  HOTEL_DETAIL_WEEK,
  HOTEL_DETAIL_MONTH,
  TIME_DAY,
  TIME_WEEK,
  TIME_MONTH,
  TOTAL,
  RATING,
  STARS,
  ACCOMMODATION_TYPE,
  COUNTRY,
  REGION,
  CITY,
  HOTEL_WITHOUT_IMPRESSIONS,
  BM_POS_LENGTH_OF_STAY,
  BM_POS_TIME_TO_TRAVEL,
  BM_POS_COMBINED_MODIFIERS,
  BY_PERIOD,
  TIME_QUARTER,
  BY_PARTNER_REF,
  BM_POS_DEFAULT_DATE,
  CAMPAIGNS,
  BM_POS_GROUP_SIZE,
  SPONSORED_HOTEL_DETAIL_DAY,
  SPONSORED_HOTEL_DETAIL_WEEK,
  SPONSORED_HOTEL_DETAIL_MONTH,
  BIDDING_TYPE,
  BRAND,
  CHART_GROUP_SIZE,
  CHART_STARS,
  CHART_ACCOMMODATION_TYPE,
  CHART_COUNTRY,
  CHART_REGION,
  CHART_CITY,
  CHART_POS_REGION,
  BY_TIMELINE_DAY,
  BY_TIMELINE_WEEK,
  BY_TIMELINE_MONTH,
  BY_TIMELINE_QUARTER,
  BY_ITEM_ID,
  BY_COUNTRY,
  BY_REGION,
  BY_CITY,
  PROPERTY_GROUPS,
  BY_BIDDING_TYPE,
  TIME_PERIOD,
}

export enum TotalTypes {
  TOTAL,
  TOTAL_WITH_FILTERS,
}

export type VIEWS_BM_EDIT_BID_MODIFIER =
  | View.BM_POS_LENGTH_OF_STAY
  | View.BM_POS_TIME_TO_TRAVEL
  | View.BM_POS_DEFAULT_DATE
  | View.BM_POS_GROUP_SIZE;
