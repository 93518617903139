import React from 'react';

export const FilterNotAppliedIcon: React.FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="currentColor">
      <path d="M20.5,21a.47.47,0,0,1-.35-.15l-17-17a.49.49,0,0,1,.7-.7l17,17a.48.48,0,0,1,0,.7A.47.47,0,0,1,20.5,21Z" />
      <path d="M19.12,17h1.35a.5.5,0,1,0,0-1H18.12Z" />
      <path d="M13.88,16H11a2.5,2.5,0,0,0-4.9,0H3.53a.5.5,0,1,0,0,1H6.1A2.5,2.5,0,0,0,11,17h3.88ZM10,17a1.49,1.49,0,0,1-2.82,0,1.43,1.43,0,0,1,0-1A1.49,1.49,0,0,1,10,16a1.43,1.43,0,0,1,0,1Z" />
      <path d="M10.12,8H13a2.5,2.5,0,0,0,4.9,0h2.57a.5.5,0,1,0,0-1H17.9A2.5,2.5,0,0,0,13,7H9.12ZM14,7a1.49,1.49,0,0,1,2.82,0,1.43,1.43,0,0,1,0,1A1.49,1.49,0,0,1,14,8a1.43,1.43,0,0,1,0-1Z" />
      <path d="M4.88,7H3.53a.5.5,0,1,0,0,1H5.88Z" />
    </g>
  </svg>
);
