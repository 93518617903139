import * as React from 'react';
import { Props, MenuDirection } from './menu.types';
import './menu.css';
import cs from 'classnames';
import { Popover, PopoverDirection } from '../popover';
import { fromTo } from '../../models';

export const Menu: React.FunctionComponent<Props> = ({
  body,
  header,
  footer,
  defaultEmpty,
  onSelectItem,
  direction = MenuDirection.BottomTrailing,
  qaId,
}) => {
  const shouldMenuBeVisible =
    body.items.length > 0 ||
    header !== undefined ||
    footer !== undefined ||
    defaultEmpty !== undefined;

  return (
    <>
      {shouldMenuBeVisible && (
        <Popover direction={MENU_TO_POPOVER[direction]}>
          {header && (
            <ol
              className={cs(
                'c-menu c-menu__item-header u-background--white u-border-radius',
                header.extraClasses,
              )}
            >
              <li key="c-menu-header">{header.html}</li>
            </ol>
          )}
          <ol
            className={cs(
              'c-menu u-background--white u-border-radius u-padding--tiny u-width--100',
              body.extraClasses,
            )}
          >
            {body.items.map((item, index) => (
              <li
                key={index}
                className="c-menu__item u-padding-h--small"
                onClick={() => onSelectItem(index)}
                data-qa={`${qaId}-option-${index}`}
                data-testid={`menu-item-${index}`}
              >
                {item}
              </li>
            ))}
            {body.items.length === 0 && defaultEmpty !== undefined && defaultEmpty}
          </ol>
          {footer && footer.html && (
            <ol className={cs('c-menu__item-footer', footer.extraClasses)}>
              <li key="c-menu-footer" className="c-menu__item-footer">
                {footer.html}
              </li>
            </ol>
          )}
        </Popover>
      )}
    </>
  );
};

const MENU_TO_POPOVER: fromTo<MenuDirection, PopoverDirection> = {
  [MenuDirection.BottomCenter]: PopoverDirection.BOTTOM_CENTER,
  [MenuDirection.BottomTrailing]: PopoverDirection.BOTTOM_TRAILING,
  [MenuDirection.BottomLeading]: PopoverDirection.BOTTOM_LEADING,
};
