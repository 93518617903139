import { WidgetRowProps } from './widget-row.types';
import './widget-row.css';
import { TooltipWrapper, TooltipPosition } from '../../../../components/tooltip-wrapper';
import ExclamationCircleFillDarkIcon from 'tcp-react-icons/lib/ExclamationCircleFillDarkIcon';
import { TranslationKey, translate } from '../../../../helpers/utils';
import { FEATURE_FLAGS } from '../../../../constants/features';
import { FC } from 'react';
import { GridCellContextMenu } from '../../../../core-ui/components/grid-cell-context-menu/grid-cell-context-menu';
import styles from './widget-row.module.css';
import cn from 'classnames';
import sharedStyles from '../../../../core-ui/components/grid-cell-context-menu/shared.module.css';

export const WidgetRow: FC<WidgetRowProps> = ({
  field: { text, tooltip },
  children,
  contextMenu,
}) => {
  const isTooltipAvailable = tooltip !== undefined && tooltip !== '';
  const isQuickDataExplorationEnabled = FEATURE_FLAGS.enableQuickDataExploration;

  return (
    <tr className={cn('c-widget-row', styles.contextMenuTable, sharedStyles.contextGridRow)}>
      <td>
        {text}
        {isTooltipAvailable && (
          <TooltipWrapper
            message={translate(tooltip as TranslationKey)}
            position={TooltipPosition.TRAILING_TOP}
            noTinyPaddingTop={true}
            isAddingPadding={true}
          >
            <ExclamationCircleFillDarkIcon
              className="u-color--juri-very-light u-margin-left--small"
              width={14}
              height={14}
            />
          </TooltipWrapper>
        )}
      </td>
      {children}
      <td>
        {isQuickDataExplorationEnabled && contextMenu && (
          <div className={(styles.contextMenu, styles.showContextMenu)}>
            <GridCellContextMenu
              gridCell={''}
              menuItems={contextMenu.menuItems}
              rowId={0}
              tooltipTitle="Examine by"
              menuTitle={`Examine performance by:`}
              value={text}
              applyMenuValue={() => {}}
              tooltipPosition="leftCenter"
            />
          </div>
        )}
      </td>
    </tr>
  );
};
