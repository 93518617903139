import { fromTo, View } from '../../../models';
import {
  COLUMN_KEY_PROPERTIES_ALL,
  COLUMN_KEY_PROPERTIES_INACTIVE,
  COLUMN_KEY_PROPERTIES_INVISIBLE,
  COLUMN_KEY_PROPERTIES_NO_BID,
} from '../../../constants';
import { BiddingModel } from '../../../hooks/use-export-properties-list/use-export-properties-list.types';

export enum PropertyListType {
  ALL,
  INACTIVE,
  INVISIBLE,
  NO_BID,
}

export const PROPERTY_LIST_TYPE_COLUMN_NAME: fromTo<PropertyListType, string> = {
  [PropertyListType.ALL]: COLUMN_KEY_PROPERTIES_ALL,
  [PropertyListType.INACTIVE]: COLUMN_KEY_PROPERTIES_INACTIVE,
  [PropertyListType.INVISIBLE]: COLUMN_KEY_PROPERTIES_INVISIBLE,
  [PropertyListType.NO_BID]: COLUMN_KEY_PROPERTIES_NO_BID,
};

export const PROPERTY_LIST_VIEW: fromTo<PropertyListType, fromTo<BiddingModel, View | null>> = {
  [PropertyListType.ALL]: {
    [BiddingModel.CPC]: View.CAMPAIGN_PROPERTIES_CPC,
    [BiddingModel.CPA]: View.CAMPAIGN_PROPERTIES_CPA,
  },
  [PropertyListType.INACTIVE]: {
    [BiddingModel.CPC]: View.INACTIVE_PROPERTIES_CPC,
    [BiddingModel.CPA]: null,
  },
  [PropertyListType.INVISIBLE]: {
    [BiddingModel.CPC]: View.INVISIBLE_PROPERTIES_CPC,
    [BiddingModel.CPA]: null,
  },
  [PropertyListType.NO_BID]: {
    [BiddingModel.CPC]: View.CAMPAIGNS_NO_BID_DOWNLOAD,
    [BiddingModel.CPA]: View.CAMPAIGNS_NO_BID_DOWNLOAD,
  },
};

export const PROPERTY_LIST_FILE_NAME_SUFFIX: fromTo<PropertyListType, string> = {
  [PropertyListType.ALL]: 'properties',
  [PropertyListType.INACTIVE]: 'inactive',
  [PropertyListType.INVISIBLE]: 'invisible',
  [PropertyListType.NO_BID]: 'no_bid',
};
