import * as React from 'react';
import { useState } from 'react';
import { Props } from './is-visible-toggle.types';
import './is-visible-toggle.css';
import { InvisibleFillDarkIcon, VisibleFillDarkIcon } from 'tcp-react-icons';
import { TooltipPosition, TooltipWrapper } from '../tooltip-wrapper';

export const IsVisibleToggle: React.FunctionComponent<Props> = ({ defaults, onToggle }) => {
  const isVisibleDefault = defaults && defaults.isVisible !== undefined ? defaults.isVisible : true;
  const [isVisible, setIsVisible] = useState(isVisibleDefault);
  const isTooltipDisabled = defaults?.tooltip === undefined;

  return (
    <div className="u-padding-top--minuscule">
      <TooltipWrapper
        hideTooltip={isTooltipDisabled}
        message={defaults?.tooltip?.text}
        position={
          defaults?.tooltip?.position ? defaults?.tooltip?.position : TooltipPosition.TOP_CENTER
        }
      >
        <div
          onClick={() => {
            setIsVisible(!isVisible);
            onToggle();
          }}
        >
          {isVisible ? <VisibleFillDarkIcon /> : <InvisibleFillDarkIcon />}
        </div>
      </TooltipWrapper>
    </div>
  );
};
