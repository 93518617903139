import { fromTo, ReportFrequency } from '../../../models';

export interface ReportArtifact {
  path: string;
  createdAt: string;
  id: number;
  isCombined: boolean;
  partnerId: number;
  reportDefinitionId: number;
  reportName: string;
  updatedAt: string;
}

export type ReportTypeDetailResponse = {
  properties: string;
  created_at: string;
  type: number;
  log_job_id: number;
};

export type ReportEnable = {
  partnerId: number;
  period: ReportPeriod;
  name: string;
  partnerFolder?: string;
};

export type ReportUpdate = {
  partnerId: number;
  period: ReportPeriod;
  isActive: boolean;
  reportId?: number;
};

export type ReportPeriod = {
  day?: number;
  frequency: ReportFrequency;
};

export const STRING_TO_REPORT_FREQUENCY: fromTo<string, ReportFrequency> = {
  DAILY: ReportFrequency.DAILY,
  WEEKLY: ReportFrequency.WEEKLY,
  MONTHLY: ReportFrequency.MONTHLY,
};

export const DEFAULT_WEEKLY_DAY = 7;
export const DEFAULT_PROTO_DAY = 0;
