import { FC } from 'react';
import { Props } from './area-pos.types';
import './area-pos.css';
import { SelectorArea } from '../selector-area';
import { SelectorAreaMenu } from '../selector-area-menu/selector-area-menu';
import { POINT_OF_SALE, POS_GLOBAL_VALUE, REGIONS, VIEW_NAME } from '../../../../constants';
import { InputIcon } from '../../../../components/input-icon';
import { SearchLineDarkIcon, CloseIcon } from 'tcp-react-icons';
import { translate } from '../../../../helpers/utils';
import { AreaOptions } from '../area-options/area-options';
import { globalOption } from '../default-options';
import { Option } from '../../../../models';
import cs from 'classnames';
import trackerService from '../../../../services/tracker/tracker-service';
import { useCurrentView } from '../../../../hooks/use-current-view/use-current-view';

export const AreaPos: FC<Props> = ({ pos, onCancel, onSave, onUpdatePos }) => {
  const selectPOS = (option: Option) => {
    const isAlreadyInTheList = pos.optionsSelected.some(
      optionTmp => optionTmp.value === option.value,
    );
    let optionsSelected: Option[] = [];

    if (isGlobalSelected(option.value)) {
      optionsSelected = [globalOption];
    } else if (isAlreadyInTheList) {
      optionsSelected = [...pos.optionsSelected.filter(o => o.value !== option.value)];
    } else {
      optionsSelected = [
        ...pos.optionsSelected.filter(
          optionTmp => !isGlobalSelected(optionTmp.value) && optionTmp.group === option.group,
        ),
        option,
      ];
    }

    if (optionsSelected.length === 0) {
      optionsSelected = [globalOption];
    }

    onUpdatePos({ ...pos, optionsSelected });
  };

  const isGlobalSelected = (optionValue: string) => optionValue === POS_GLOBAL_VALUE;

  const selectorAreaOptions = [POINT_OF_SALE, REGIONS];

  const selectedOptionsMenuOpen = !selectorAreaOptions.includes(pos.subMenuSelected);

  const areLocalesSelected = pos.optionsSelected.some(option => option.group === POINT_OF_SALE);

  const currentView = useCurrentView();

  const handleSave = () => {
    const selectedPOS = pos.optionsSelected.map(option => ({
      label: option.label,
      value: option.value,
    }));
    trackerService.track('Change Point of sales', {
      locales: selectedPOS.map(it => it.value),
      view: currentView
        ? VIEW_NAME[currentView as keyof typeof VIEW_NAME] ?? currentView
        : 'Unknown',
      url: window.location.href,
    });
    onSave();
  };

  return (
    <SelectorArea onCancel={onCancel} onSave={handleSave} qaId="selector-bar-menu-pos">
      <SelectorAreaMenu
        options={selectorAreaOptions}
        selectedOption={pos.subMenuSelected}
        onSelect={option => {
          onUpdatePos({
            ...pos,
            subMenuSelected: option,
          });
        }}
        numOptionSelected={pos.optionsSelected.length}
      />
      <div className="u-flex-grow--1">
        <article className="c-area-pos u-padding--gutter u-background--white">
          <div data-qa="selector-bar-input">
            <InputIcon
              className="c-input c-input--wider u-border-radius"
              icon={pos.search === '' ? SearchLineDarkIcon : CloseIcon}
              placeholder={translate(
                pos.subMenuSelected === POINT_OF_SALE
                  ? 'analytics_home_placeholder_search_country'
                  : 'analytics_home_placeholder_search_region',
              )}
              onChange={e => {
                onUpdatePos({
                  ...pos,
                  search: e.target.value,
                });
              }}
              value={pos.search}
              onClickIcon={() =>
                onUpdatePos({
                  ...pos,
                  search: '',
                })
              }
              autoFocus={true}
            />
          </div>
        </article>
        <AreaOptions
          options={pos.options.sort((pos1, pos2) => pos1.label.localeCompare(pos2.label))}
          selectedGroup={pos.subMenuSelected}
          searchKeyword={pos.search}
          selectedOptions={pos.optionsSelected}
          onChange={selectPOS}
          className={cs({
            'c-area-options-locales':
              pos.subMenuSelected === POINT_OF_SALE ||
              (selectedOptionsMenuOpen && areLocalesSelected),
          })}
        />
      </div>
    </SelectorArea>
  );
};
