import { useState } from 'react';
import { ValidationOption, fromTo } from '../../../models';

export const useNameValidation = <T extends keyof ValidationOption>(
  validationProps: Pick<ValidationOption, T>,
  initialName: string = '',
) => {
  const [name, setName] = useState(initialName);
  const [shouldShowErrors, setShouldShowErrors] = useState(false);

  const validationErrors = validateInputValue(name, validationProps);

  const isValid = validationErrors.length === 0;

  return {
    name,
    setName,
    isValid,
    validationErrors,
    shouldShowErrors,
    setShouldShowErrors,
  };
};

const validateInputValue = <T extends keyof ValidationOption>(
  value: string,
  validationConstraint: Pick<ValidationOption, T>,
): T[] => {
  const valueToValidate = value.trim();

  const validations: fromTo<keyof ValidationOption, (vo: Partial<ValidationOption>) => boolean> = {
    minimum: ({ minimum }) => minimum !== undefined && valueToValidate.length < minimum,
    maximum: ({ maximum }) => maximum !== undefined && valueToValidate.length > maximum,
    cannotBeRepeatedWith: ({ cannotBeRepeatedWith }) =>
      !!cannotBeRepeatedWith?.some(
        valueTmp => valueTmp.toLocaleLowerCase() === valueToValidate.toLocaleLowerCase(),
      ),
    regexValidation: ({ regexValidation }) => regexValidation?.test(valueToValidate) === false,
  };

  return Object.keys(validationConstraint)
    .map(validationKey => validationKey as T)
    .filter(validationKey => validations[validationKey](validationConstraint));
};
