import * as React from 'react';
import { Props } from './bell-and-notification-drawer.types';
import './bell-and-notification-drawer.css';
import BellLineDarkIcon from 'tcp-react-icons/lib/BellLineDarkIcon';
import { useOnClickOutside } from '../../../../hooks/use-on-click-outside';
import { NotificationDrawer } from './notification-drawer';
import cs from 'classnames';
import { AppContext } from '../../../context/app-context';
import { Notification, NotificationSettings } from '../../../../models';
import ApiNotificationsHelper from '../../../../helpers/api/notifications/api-notifications-helper';
import {
  MAX_NOTIFICATIONS,
  NOTIFICATIONS_PAGINATION,
  EVENT_DRAWER_NOTIFICATION,
  NotificationLevel,
  Color,
  NOTIFICATIONS_READ_ALL,
  NOTIFICATIONS_READ,
  NOTIFICATIONS_OPEN_DRAWER,
} from '../../../../constants';
import { sleep, translate } from '../../../../helpers/utils';
import { Circle } from '../../../../components/circle';
import { Event } from '../../../../services/event';
import { NotificationSettingsModal } from './notification-settings-modal';
import { useModal } from 'react-modal-hook';
import { fetchNotifications, showNotification } from '../../../../services/notification-service';
import TrackerService from '../../../../services/tracker/tracker-service';

export const BellAndNotificationDrawer: React.FunctionComponent<Props> = () => {
  const { partner, subPartners } = React.useContext(AppContext);
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.createRef<HTMLDivElement>();

  const isModalVisible = React.useRef(false);

  const [visibleNotifications, setVisibleNotifications] = React.useState(NOTIFICATIONS_PAGINATION);
  const [isLoading, setIsLoading] = React.useState(true);

  const [showModal, closeModal] = useModal(() => {
    return (
      <NotificationSettingsModal
        onClose={closeModalAndSaveSettings}
        onErrorFetchingNotifications={() => {
          closeModal();
          showNotification({
            message: translate('notifications_settings_fetch_error'),
            level: NotificationLevel.ERROR,
          });
        }}
      />
    );
  });

  const closeModalAndSaveSettings = async (settings: NotificationSettings[]) => {
    isModalVisible.current = false;
    closeModal();
    try {
      await ApiNotificationsHelper.saveSettings(settings);
    } catch (_) {
      showNotification({
        message: translate('notifications_settings_save_error'),
        level: NotificationLevel.ERROR,
      });
    }
  };

  useOnClickOutside(ref, () => {
    if (!isModalVisible.current) {
      setIsOpen(false);
    }
  });

  React.useEffect(() => {
    const fetchNotificationsTmp = async () => {
      setIsLoading(true);
      try {
        const fetchedNotifications = await fetchNotifications(partner, Array.from(subPartners));
        setNotifications(fetchedNotifications);
        setVisibleNotifications(
          fetchedNotifications.length > visibleNotifications
            ? fetchedNotifications.length
            : visibleNotifications,
        );
      } catch (e) {
        showNotification({
          level: NotificationLevel.ERROR,
          message: translate('notifications_get_error'),
        });
      }
      setIsLoading(false);
    };
    fetchNotificationsTmp();
  }, [partner, subPartners, visibleNotifications]);

  const addNotification = (notification: Notification) => {
    const nextNotifications = notifications.slice();

    nextNotifications.splice(0, 0, notification);

    setNotifications(nextNotifications);
  };

  Event.subscribe(EVENT_DRAWER_NOTIFICATION, addNotification);

  const onScroll = async ({ target }: any) => {
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (visibleNotifications < MAX_NOTIFICATIONS && visibleNotifications < notifications.length) {
        setIsLoading(true);
        await sleep(500);
        setIsLoading(false);
        const visibleNotificationsTmp = visibleNotifications + NOTIFICATIONS_PAGINATION;
        setVisibleNotifications(
          notifications.length > visibleNotificationsTmp
            ? visibleNotificationsTmp
            : notifications.length,
        );
      }
    }
  };

  const readNotification = (notificationId: string, isRead: boolean) => {
    TrackerService.track(NOTIFICATIONS_READ, {
      partner: partner.partnerId,
      isRead: isRead,
    });

    const nextNotifications = notifications.slice();

    const notification = nextNotifications.find(({ id }) => id === notificationId);

    if (!notification) {
      return;
    }

    notification.isRead = isRead;

    setNotifications(nextNotifications);
  };

  const onMarkAllAsRead = () => {
    TrackerService.track(NOTIFICATIONS_READ_ALL, {
      partner: partner.partnerId,
    });

    const nextNotifications = notifications.slice();

    nextNotifications.forEach(notificationTmp => {
      notificationTmp.isRead = true;
    });

    setNotifications(nextNotifications);
  };

  const isUnreadNotification = () => notifications.some(({ isRead }) => !isRead);

  return (
    <div ref={ref}>
      <div
        className={cs(
          'c-bell-and-notification-drawer u-padding--small u-cursor--pointer u-margin-right--gutter',
          {
            'u-background--juri-lightest': isOpen,
          },
        )}
        onClick={() => {
          TrackerService.track(NOTIFICATIONS_OPEN_DRAWER, {
            partner: partner.partnerId,
          });
          setIsOpen(!isOpen);
        }}
      >
        <BellLineDarkIcon />
        {isUnreadNotification() && (
          <div className="u-position--relative c-bell-and-notification-drawer__bell">
            <Circle radius={7} color={Color.Red700} />
          </div>
        )}
      </div>
      {isOpen && (
        <div className="u-position--relative">
          <NotificationDrawer
            notifications={notifications.slice(0, visibleNotifications)}
            isLoading={isLoading}
            onScroll={onScroll}
            onMarkAllAsRead={onMarkAllAsRead}
            onClose={() => setIsOpen(false)}
            onRead={readNotification}
            onShowModal={() => {
              isModalVisible.current = true;
              showModal();
            }}
          />
        </div>
      )}
    </div>
  );
};
