import './bidding.css';
import { Campaigns } from './campaigns';
import { Card } from '../../components/card';
import { FC } from 'react';

export const Bidding: FC = () => {
  return (
    <Card className="c-bidding">
      <Campaigns />
    </Card>
  );
};
