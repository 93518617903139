import { RequestHelper } from '../request-helper';
import { Notification, NotificationSettings } from '../../../models';
import { Partner } from '../../../models';
import { data2DrawerNotification } from '../../transformers';
import {
  FetchNotificationsBody,
  NUMBER_TO_NOTIFICATION_LEVEL,
  NUMBER_TO_NOTIFICATION_TYPE,
  ApiSettings,
  NOTIFICATION_LEVEL_TO_NUMBER,
  NOTIFICATION_TYPE_TO_NUMBER,
} from './types';
import { AxiosError } from 'axios';

const URL_FETCH_NOTIFICATIONS = '/notifications/partners';
const URL_NOTIFICATIONS_MARK_READ = '/notifications/{notificationId}/read';
const URL_NOTIFICATIONS_MARK_AS_READ_ALL = '/notifications/read/all';
const URL_NOTIFICATIONS_FETCH_SETTINGS = '/notifications/settings/member';
const URL_NOTIFICATIONS_SAVE_SETTINGS = '/notifications/settings/member';

export interface ApiNotification {
  id: string;
  isRead: boolean;
  level: number;
  type: number;
  createdAt: string;
  metadata: {
    PARTNER_ID?: number;
    IS_GLOBAL?: boolean;
  };
  data: {
    TRANSLATION_KEY: string;
    TITLE?: string;
    LINK?: string;
    FILE_NAME?: string;
  };
}

class ApiNotificationsHelper extends RequestHelper {
  private static instance: ApiNotificationsHelper;

  public static getInstance() {
    if (!ApiNotificationsHelper.instance) {
      ApiNotificationsHelper.instance = new ApiNotificationsHelper();
    }

    return ApiNotificationsHelper.instance;
  }

  public async fetchNotifications(
    partnersId: number[],
    pagination: {
      offset: number;
      limit: number;
    },
    partner: Partner,
    subpartners: Partner[],
  ): Promise<Notification[]> {
    const body: FetchNotificationsBody = {
      pagination,
      partnerId: partnersId,
    };

    try {
      const response = await this.postRequest(URL_FETCH_NOTIFICATIONS, body);
      return this.data2DrawerNotifications(
        response.data as ApiNotification[],
        partner,
        subpartners,
      );
    } catch (e) {
      this.handleError(e as AxiosError, 'notifications_fetch_error');
      return [];
    }
  }

  public async markRead(notificationId: string, isRead: boolean): Promise<boolean> {
    const params = [{ key: 'notificationId', value: notificationId }];
    const url = RequestHelper.replaceUrlParams(URL_NOTIFICATIONS_MARK_READ, params);

    const body = {
      isRead,
    };

    try {
      await this.putRequest(url, body);
      return true;
    } catch (e) {
      this.handleError(e as AxiosError, 'notifications_mark_read_error');
      return false;
    }
  }

  public async markAllAsRead(partnerId: number): Promise<boolean> {
    const params = [{ key: 'partnerId', value: partnerId }];
    const url = RequestHelper.replaceUrlParams(URL_NOTIFICATIONS_MARK_AS_READ_ALL, params);

    try {
      await this.putRequest(url, {});
      return true;
    } catch (e) {
      this.handleError(e as AxiosError, 'notifications_mark_read_all_error');
      return false;
    }
  }

  public async fetchSettings(): Promise<NotificationSettings[]> {
    try {
      const response = await this.getRequest(URL_NOTIFICATIONS_FETCH_SETTINGS);
      return this.data2Settings(response.data);
    } catch (e) {
      this.handleError(e as AxiosError, 'notifications_settings_error');
      return [];
    }
  }

  public async saveSettings(settings: NotificationSettings[]): Promise<boolean> {
    const body = this.settings2ApiSettings(settings);

    try {
      await this.postRequest(URL_NOTIFICATIONS_SAVE_SETTINGS, body);
      return true;
    } catch (e) {
      this.handleError(e as AxiosError, 'notifications_save_settings_error');
      return false;
    }
  }

  public async sendNotificationToAllMembers(data: any): Promise<void> {
    try {
      await this.postRequest('/notifications/manual', {
        metadata: {
          IS_GLOBAL: true,
        },
        type: 'RESOURCE_CENTER',
        level: 'INFO',
        data: {
          TITLE: data.title,
          TRANSLATION_KEY: data.message,
          LINK: data.link,
        },
        createdAt: new Date().toISOString(),
      });
    } catch (e) {
      this.handleError(e as AxiosError, 'notifications_send_error');
    }
  }

  private data2DrawerNotifications(
    data: ApiNotification[],
    partner: Partner,
    subpartners: Partner[],
  ): Notification[] {
    return data.map(dataTmp => {
      const messagePartnerId = dataTmp.metadata['PARTNER_ID'];
      const subPartner = subpartners.find(p => p.partnerId === messagePartnerId);

      return data2DrawerNotification(dataTmp, subPartner ?? partner);
    });
  }

  private data2Settings(data: any[]): NotificationSettings[] {
    return data.map(dataTmp => {
      return this.data2NotificationSettings(dataTmp);
    });
  }

  private settings2ApiSettings(data: NotificationSettings[]): ApiSettings[] {
    return data.map(dataTmp => {
      return this.settingsToApiSetting(dataTmp);
    });
  }

  private data2NotificationSettings(data: any): NotificationSettings {
    return {
      id: data.id,
      isActive: data.sendEmail,
      notificationLevel: NUMBER_TO_NOTIFICATION_LEVEL[data.notificationLevel],
      notificationType: NUMBER_TO_NOTIFICATION_TYPE[data.notificationType],
    };
  }

  private settingsToApiSetting(data: NotificationSettings): ApiSettings {
    return {
      sendEmail: data.isActive,
      notificationLevel: NOTIFICATION_LEVEL_TO_NUMBER[data.notificationLevel],
      notificationType: NOTIFICATION_TYPE_TO_NUMBER[data.notificationType],
    } as ApiSettings;
  }
}

export default ApiNotificationsHelper.getInstance();
