import { FC, useContext } from 'react';
import { useDateRangePeriod } from '../../../../components/date-picker/date-picker.state';
import { Grid, SortDirection } from '../../../../components/grid';
import { ResultsNotFound } from '../../../../components/results-not-found';
import { ShownMetricsSelector } from '../../../../components/shown-metrics-selector';
import { Toolbar } from '../../../../components/toolbar';
import { SearchTextFilter } from '../../../../components/toolbar/search-text-filter';
import { ViewExporter } from '../../../../components/view-exporter';
import {
  COLUMN_KEY_LOCAL_CODE_SL,
  COLUMN_KEY_PARTNER_REFERENCE_SL,
  GRID_VIEW_CONFIGURATION,
  VIEW_NAME,
} from '../../../../constants';
import { AppContext } from '../../../../global/context/app-context';
import { formatColumnsAndRows } from '../../../../helpers/grid-formatter';
import { translate } from '../../../../helpers/utils';
import { useShownMetricsSelector } from '../../../../hooks/use-shown-metrics-selector';
import { useViewTable } from '../../../../hooks/use-view-table';
import { Aggregation, FileFormat, View } from '../../../../models';
import { withSortTracking } from '../../../analytics/with-sort-tracking';
import styles from '../common-view-styles.module.css';

export const SponsoredPosView: FC = () => {
  const { selectedLocales } = useContext(AppContext);
  const [{ from, to }] = useDateRangePeriod();

  const view = View.SPONSORED_POS;
  const numOfStickyColumns = 1;

  const {
    table: { columns, data, total },
    isLoading,
    pagination,
    retry,
    setSearch,
    filters: { allActiveFilters, hasGlobalFilters, clearGlobalFilters },
    sort: { sort, setSort },
  } = useViewTable(
    view,
    Aggregation.POS,
    {
      locales: selectedLocales,
      from,
      to,
    },
    {
      columnId: COLUMN_KEY_PARTNER_REFERENCE_SL,
    },
  );

  const { formattedColumns, formattedRows, formattedFooterRows } = formatColumnsAndRows({
    columns,
    rows: data.rows,
    total,
    id: COLUMN_KEY_PARTNER_REFERENCE_SL,
  });

  const { shownMetrics, setShownMetrics, availableMetrics, visibleFormattedColumns } =
    useShownMetricsSelector(view, numOfStickyColumns, columns, formattedColumns);

  return (
    <div className={styles.toolbarWrapper}>
      <Toolbar
        filters={[
          <SearchTextFilter
            key="key-custom-filters"
            placeholder={translate('sponsored_home_search_item_pos')}
            onSearch={filterTmp => setSearch(filterTmp !== undefined ? [filterTmp] : [])}
            searchableColumnNames={[COLUMN_KEY_LOCAL_CODE_SL]}
          />,
        ]}
        actions={[
          <ShownMetricsSelector
            key="key-metrics-selector"
            options={availableMetrics}
            selectedByDefaultOptions={shownMetrics}
            view={view}
            onApply={setShownMetrics}
          />,
          <ViewExporter
            key="key-view-exporter"
            title={translate('sponsored_home_export_report')}
            exportOptions={[
              {
                text: translate('sponsored_toolbar_export_performance_grid'),
                fileFormat: FileFormat.CSV,
              },
              {
                text: translate('sponsored_toolbar_export_performance_grid_excel'),
                fileFormat: FileFormat.EXCEL,
              },
            ]}
            view={view}
            columns={columns}
            aggregation={Aggregation.POS}
            filters={allActiveFilters}
            sort={sort}
          />,
        ]}
      />

      <div className={styles.gridWrapper}>
        <Grid
          numStickyColumns={numOfStickyColumns}
          columns={visibleFormattedColumns}
          rows={formattedRows}
          footerRows={formattedFooterRows}
          isLoading={isLoading}
          configuration={GRID_VIEW_CONFIGURATION}
          onSort={withSortTracking((columnName: string, direction: SortDirection) => {
            setSort({ columnName, direction });
          }, VIEW_NAME[view])}
          pagination={{
            page: pagination.pagination,
            totalItems: data.count,
            onPreviousPage: pagination.setPrevPage,
            onNextPage: pagination.setNextPage,
            onUpdateRowsPerPage: pagination.setNumItemsPerPage,
          }}
          resultsNotFound={
            <ResultsNotFound
              isButtonEnabled={hasGlobalFilters && !isLoading}
              onClick={clearGlobalFilters}
              message="sponsored_pos_view_no_results"
              retry={retry}
            />
          }
        />
      </div>
    </div>
  );
};
