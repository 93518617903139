import React, { useState, FC } from 'react';
import { useModal } from 'react-modal-hook';
import { InventoryFileUpload } from '../../views/bidding/campaigns/inventory-file-upload';
import { useSelectedPartner } from '../../hooks/use-selected-partner';
import { useHistory } from 'react-router-dom';
import { UploadInventoryModal } from '../upload-inventory-modal';
import { Modal } from '../modal';
import { Loading } from '../loading';
import { translate } from '../../helpers/utils';
import { uploadInventoryFile } from '../../services/file-bidding-service';
import { HISTORY_ROUTE } from '../../constants';
import styles from './button-dropdown-file-uploader.module.css';
import { LinearProgressBar } from '../linear-progress-bar';
import PreventNavigationPrompt from '../prevent-navigation-prompt';

const useConfirmInventoryUpload = () => {
  const [resolverFn, setResolverFn] = useState<(x: boolean) => void>(() => (x: boolean) => x);
  const [showModal, hideModal] = useModal(
    () => (
      <InventoryFileUpload
        closeUploadModal={() => {
          hideModal();
          resolverFn(false);
        }}
        initiateFileUpload={() => {
          hideModal();
          resolverFn(true);
        }}
      />
    ),
    [resolverFn],
  );

  return function confirm(): Promise<boolean> {
    return new Promise(resolve => {
      setResolverFn(() => resolve);
      showModal();
    });
  };
};

export const useUploadInventoryFile = () => {
  const partnerId = useSelectedPartner().partnerId;
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [showUploadingProgressModal, hideUploadingProgressModal] = useModal(
    () => <UploadProgressModal progress={progress} />,
    [progress],
  );
  const [showInventoryFileUploadedModal, hideInventoryFileUploadedModal] = useModal(
    () => (
      <WithoutValidationInventoryModal
        onCloseModal={hideInventoryFileUploadedModal}
        onCTAModal={() => history.push(`${HISTORY_ROUTE}?tab=Other+events`)}
      />
    ),
    [],
  );
  const confirmInventoryUpload = useConfirmInventoryUpload();

  return async function uploadInventory(file: File) {
    try {
      const confirmed = await confirmInventoryUpload();
      if (!confirmed) return;
      showUploadingProgressModal();
      await uploadInventoryFile(file, partnerId, setProgress);
      hideUploadingProgressModal();
      showInventoryFileUploadedModal();
    } finally {
      hideUploadingProgressModal();
    }
  };
};

const UploadProgressModal: FC<{ progress: number }> = ({ progress }) => (
  <>
    <PreventNavigationPrompt />
    <Modal backdropStatic={false} onClose={() => false}>
      <Loading
        message={
          <div>
            <h3>Uploading file, don't close the browser window</h3>
            <h3>{progress}%</h3>
          </div>
        }
        variant="dots"
      />
    </Modal>
  </>
);
type WithoutValidationInventoryModalProps = {
  onCloseModal: () => void;
  onCTAModal: () => void;
};

const WithoutValidationInventoryModal: FC<WithoutValidationInventoryModalProps> = ({
  onCloseModal,
  onCTAModal,
}) => (
  <UploadInventoryModal
    variant="withoutHeader"
    onCloseModal={onCloseModal}
    ctaButtonTitle="Go To History"
    tertiaryButtonTitle="Close"
    onClickCTA={() => onCTAModal()}
    ctaButtonStyle={styles.withoutValidationModalCTAButton}
  >
    <>
      <LinearProgressBar
        startMilestoneLabel="Inventory File uploaded"
        endMilestoneLabel="Validation"
      />
      <p className={styles.withoutValidationModalText}>
        {translate('inventory_file_upload_nonvalidated_success')}
      </p>
    </>
  </UploadInventoryModal>
);
