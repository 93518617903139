import React, { FC } from 'react';
import { translate } from '../../helpers/utils';
import { Subscription } from './report.types';
import { ReportRow } from './report-row/report-row';
import { ReportsApi } from '../../helpers/api/reports/reports-api';
import { useConfirmationModal } from '../../hooks/use-confirmation-modal';
import { getDisplayName, showError } from './util';

interface Props {
  onShowReportDetails: (subscription: Subscription) => void;
  subscriptions: Subscription[];
  onSubscriptionDeleted: (subscription: Subscription) => void;
}

export const ActiveReports: FC<Props> = ({
  onShowReportDetails,
  subscriptions,
  onSubscriptionDeleted,
}) => {
  const { confirm } = useConfirmationModal();

  async function deleteSubscription(subscription: Subscription) {
    try {
      if (!(await confirm())) return;
      await ReportsApi.deleteSubscription(subscription.id);
      onSubscriptionDeleted(subscription);
    } catch (e) {
      showError(e);
    }
  }

  return (
    <div className="c-reports-type-list__container">
      <div className="c-reports-type-list__title u-color--juri-lighter u-margin-bottom--medium">
        {translate('reports_active_reports')}
      </div>
      <table className="c-reports-type-list">
        <tbody>
          {subscriptions.map(subscription => (
            <ReportRow
              key={subscription.id}
              displayName={getDisplayName(subscription)}
              isActive={true}
              frequency={subscription.deliveryFrequency}
              onClick={() => onShowReportDetails(subscription)}
              documentationUri={subscription.reportDefinition.documentationUri}
              onEnableDisableReport={() => deleteSubscription(subscription)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
