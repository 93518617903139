import React, {
  Fragment,
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import cn from 'classnames';
import './modal.css';
import { getClass, Props } from './modal.types';

export const Modal: FunctionComponent<Props> = ({
  children,
  className,
  size,
  backdropStatic,
  onClose,
  backdropClassName,
}) => {
  const myModalRef: RefObject<HTMLDivElement> = useRef(null);
  const modalSizeClass = size ? getClass(size) : '';

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (myModalRef.current === event.target) {
        onClose();
      }
    },
    [myModalRef, onClose],
  );

  useEffect(() => {
    if (!backdropStatic) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.documentElement.style.overflow = '';
        document.body.style.overflow = '';
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    return;
  }, [handleClickOutside, backdropStatic]);

  return (
    <Fragment>
      <section className="c-modal" ref={myModalRef}>
        <article className={cn('c-modal__container', modalSizeClass, className)}>
          {children}
        </article>
      </section>
      <div className={cn('c-modal__backdrop', backdropClassName)} />
    </Fragment>
  );
};
