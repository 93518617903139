import React from 'react';

export const SaveAsIcon: React.FunctionComponent<{ fill?: string }> = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4.5H14.25V5.25H15V15H5.25V14.25H4.5V15C4.5 15.4125 4.8375 15.75 5.25 15.75H15C15.4125 15.75 15.75 15.4125 15.75 15V5.25C15.75 4.8375 15.4125 4.5 15 4.5Z"
      fill={fill || 'var(--grey-800)'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12.75H12.75V3H3V12.75ZM12.75 13.5C13.1625 13.5 13.5 13.1625 13.5 12.75V3C13.5 2.5875 13.1625 2.25 12.75 2.25H3C2.5875 2.25 2.25 2.5875 2.25 3V12.75C2.25 13.1625 2.5875 13.5 3 13.5H12.75Z"
      fill={fill || 'var(--grey-800)'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.875 8.25H7.5V10.875C7.5 11.085 7.665 11.25 7.875 11.25C8.085 11.25 8.25 11.085 8.25 10.875V8.25H10.875C11.085 8.25 11.25 8.085 11.25 7.875C11.25 7.665 11.085 7.5 10.875 7.5H8.25V4.875C8.25 4.665 8.085 4.5 7.875 4.5C7.665 4.5 7.5 4.665 7.5 4.875V7.5H4.875C4.665 7.5 4.5 7.665 4.5 7.875C4.5 8.085 4.665 8.25 4.875 8.25Z"
      fill={fill || 'var(--grey-800)'}
    />
  </svg>
);
