import { FC } from 'react';
import './page-footer.css';
import logo from './trivago.svg';

export const PageFooter: FC = () => (
  <footer className="c-pageFooter u-color--white u-font-size--h-tiny">
    <div className="u-background--juri-dark u-display--flex u-flex-direction--column u-justify-content--center u-align-items--center">
      <p>trivago N.V., Kesselstraße 5 – 7, 40221 Düsseldorf, Germany</p>
      <a className="c-pageFooter__privacy_link" href="https://www.trivago.com/privacy-policy">
        Privacy Policy
      </a>
    </div>
    <div className="u-background--juri u-display--flex u-justify-content--center u-align-items--center u-flex-direction--column">
      <img src={logo} className="u-margin-bottom--small" width="102" alt="trivago Intelligence" />
      <small className="u-font-size--tiny">
        Copyright {new Date().getFullYear()} trivago | All rights reserved.
      </small>
    </div>
  </footer>
);
