import { FC } from 'react';
import { ExclamationCircleFillDarkIcon } from 'tcp-react-icons';
import { Toggle } from '../../../components/toggle';
import { TooltipWrapper } from '../../../components/tooltip-wrapper';
import { HOTEL_VIEW_SHOW_NON_PERFORMING_DATA } from '../../../constants';
import { translate } from '../../../helpers/utils';
import TrackerService from '../../../services/tracker/tracker-service';
import styles from './include-non-performing-data-toggle.module.css';

export const IncludeNonPerformingDataToggle: FC<{
  value: boolean;
  setValue: (newValue: boolean) => void;
  tooltip: string;
}> = ({ value, setValue, tooltip }) => {
  const handleChange = (nextValue: boolean) => {
    if (nextValue) TrackerService.track(HOTEL_VIEW_SHOW_NON_PERFORMING_DATA);
    setValue(nextValue);
  };

  return (
    <div className={styles.wrapper}>
      <TooltipWrapper message={tooltip}>
        <ExclamationCircleFillDarkIcon className={styles.infoIcon} width={14} height={14} />
      </TooltipWrapper>
      <Toggle
        isActive={value}
        label={translate('include_non_performing_data_toggle_label')}
        onChange={handleChange}
        style={{
          border: 'none',
          padding: 0,
          gap: 'var(--abs-8)',
        }}
      />
    </div>
  );
};
