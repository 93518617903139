import { FC } from 'react';
import { ButtonDropdown } from '../button-dropdown';
import styles from './bidding-type-selector.module.css';
import cn from 'classnames';
import { codesToValues, getAllCampaignTypes, valuesToCodes } from '../../models';
import { Checkbox } from '../checkbox';
import { CheckboxSelectHeader } from '../checkbox-select/checkbox-select-header';
import { Toggle } from '../toggle';
import { BiddingTypeSelectorState, useBiddingTypeSelector } from './use-bidding-type-selector';
import { useFilters } from '../../global/context/filter-context';
import {
  saveCampaignFiltersToLocalStorage,
  saveIncludeSlDataToLocalStorage,
} from '../../helpers/local-storage-helper';
import { OnboardingTooltip } from '../onboarding-tooltip';
import { BIDDING_FILTER_TRACKING, SL_FILTER_ONBOARDING_KEY } from '../../constants';
import { PopoverDirection } from '../popover';
import { TooltipPosition, TooltipWrapper } from '../tooltip-wrapper';
import trackerService from '../../services/tracker/tracker-service';
import { Tag } from '../../core-ui/components/tag/tag';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';
import { Button } from '../../core-ui/components/button/button';

export const BiddingTypeSelector: FC = () => {
  const allBiddingTypes = getAllCampaignTypes();
  const {
    selectedBiddingTypes: codes,
    setSelectedBiddingTypes,
    includeSlData,
    setIncludeSlData,
  } = useFilters();
  const selectedBiddingTypes = codesToValues(codes);
  const currentView = String(useCurrentView()).toLowerCase();

  function title(): string {
    const prefixes: string[] = [];

    if (includeSlData) prefixes.push('SL');
    if (selectedBiddingTypes.length > 0) prefixes.push('Meta');

    const prefix = prefixes.join(' & ');
    if (selectedBiddingTypes.length === 0) return prefix;

    const areAllBiddingTypesSelected = selectedBiddingTypes.length === allBiddingTypes.length;
    const suffix = areAllBiddingTypesSelected
      ? 'All'
      : valuesToCodes(selectedBiddingTypes).join(', ');

    return `${prefix} - ${suffix}`;
  }

  function apply(biddingTypes: number[], slActive: boolean) {
    const stringTypes = valuesToCodes(biddingTypes);
    setSelectedBiddingTypes(stringTypes);
    saveCampaignFiltersToLocalStorage(stringTypes);
    setIncludeSlData(slActive);
    saveIncludeSlDataToLocalStorage(slActive);
    closeDropdown();
    trackerService.track(BIDDING_FILTER_TRACKING, {
      biddingTypes: valuesToCodes(biddingTypes),
      slActive,
      view: 'Dashboard',
      url: window.location.href,
    });
  }

  const getButtonDropdown = () => (
    <Tag
      isSelected
      className={styles.tagContainer}
      onClick={event => {
        event?.stopPropagation();
        trackerService.track('Clicked filter tag: Bidding Type', {
          view: currentView,
          url: window.location.href,
        });
      }}
    >
      <ButtonDropdown title={title()} className={styles.biddingTypeSelectorDropdownTag}>
        <DropdownContent
          onApply={apply}
          selectedBiddingTypes={selectedBiddingTypes}
          isSlActive={includeSlData}
        />
      </ButtonDropdown>
    </Tag>
  );

  const isToolTipWrapperShown = title().length > 25;
  return (
    <>
      <TooltipWrapper
        message={title()}
        noTinyPaddingTop={true}
        position={TooltipPosition.BOTTOM_TRAILING}
        hideTooltip={!isToolTipWrapperShown}
      >
        {getButtonDropdown()}
      </TooltipWrapper>
      <div style={{ position: 'relative', top: -34, right: -8 }}>
        <OnboardingTooltip
          keyStorage={SL_FILTER_ONBOARDING_KEY}
          direction={PopoverDirection.TRAILING_TOP}
        >
          <p>You can now apply a Sponsored Listings filter directly on the dashboard.</p>
        </OnboardingTooltip>
      </div>
    </>
  );
};

const closeDropdown = () => document.dispatchEvent(new MouseEvent('mousedown'));

export type BiddingTypeSelectorProps = {
  selectedBiddingTypes: number[];
  isSlActive: boolean;
  onApply: (biddingTypes: number[], isSlActive: boolean) => void;
};

const DropdownContent: FC<BiddingTypeSelectorProps> = props => {
  const { meta, isSlActive, toggleIsSlActive } = useBiddingTypeSelector(props);

  return (
    <div onClick={event => event?.stopPropagation()}>
      <Toggle
        label="Meta"
        isActive={meta.isActive}
        onChange={meta.toggleIsActive}
        style={{ paddingBottom: meta.isActive ? 0 : 24 }}
      />
      {meta.isActive && <MetaOptions meta={meta} />}
      <Toggle label="Sponsored Listings" isActive={isSlActive} onChange={toggleIsSlActive} />
      <Footer
        onApply={() => props.onApply(meta.selectedBiddingTypes, isSlActive)}
        applyDisabled={meta.selectedBiddingTypes.length === 0 && !isSlActive}
      />
    </div>
  );
};

const MetaOptions: FC<{ meta: BiddingTypeSelectorState['meta'] }> = ({ meta }) => (
  <>
    <CheckboxSelectHeader onClearSelection={meta.deselectAll} onSelectAll={meta.selectAll} />
    <div className={styles.options}>
      {meta.options.map(option => (
        <Checkbox
          classNames={cn('option-item', {
            'option-item--selected': option.checked,
          })}
          key={option.id}
          title={option.label}
          isChecked={option.checked}
          onChange={() => {
            meta.toggleOption(option);
          }}
        />
      ))}
    </div>
  </>
);

const Footer: FC<{ onApply: () => void; applyDisabled: boolean }> = props => (
  <div className={styles.footer}>
    <Button
      text="Apply"
      disabled={props.applyDisabled}
      onClick={event => {
        event?.stopPropagation();
        props.onApply();
      }}
    />
  </div>
);
