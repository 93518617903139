import { FC, useState } from 'react';
import { translate } from '../../../helpers/utils';
import { useFilters } from '../../../global/context/filter-context';
import { getAllCampaignTypes } from '../../../models';
import trackerService from '../../../services/tracker/tracker-service';
import { useCurrentView } from '../../../hooks/use-current-view/use-current-view';
import {
  BIDDING_FILTER_TRACKING,
  MULTI_SELECT_BIDDING_TYPE_CLICK,
  VIEW_NAME,
} from '../../../constants';
import styles from './global-bidding-type-dropdown.module.css';
import { Tag } from '../../../core-ui/components/tag/tag';
import { Anchor } from '../../../core-ui/components/anchor';
import { CheckboxSelect, CheckboxSelectOption } from '../../../components/checkbox-select';
import { TooltipPosition, TooltipWrapper } from '../../../components/tooltip-wrapper';
import ExclamationCircleFillDarkIcon from 'tcp-react-icons/lib/ExclamationCircleFillDarkIcon';
import { Icon } from '../../../core-ui/components/icon';

export const GlobalBiddingTypeDropdown: FC = () => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const { selectedBiddingTypes, setSelectedBiddingTypes } = useFilters();
  const currentView = useCurrentView();

  const clickApply = (options: CheckboxSelectOption<string>[]) => {
    trackerService.track(BIDDING_FILTER_TRACKING, {
      biddingTypes: options.map(({ value }) => value),
      view: currentView
        ? VIEW_NAME[currentView as keyof typeof VIEW_NAME] ?? currentView
        : 'Unknown',
      url: window.location.href,
    });

    setSelectedBiddingTypes(options.filter(({ checked }) => checked).map(({ value }) => value));
    setAnchor(null);
  };

  const biddingTitle =
    selectedBiddingTypes.length === 1
      ? `${translate('analytics_bidding_type_dropdown_single_selected')} ${selectedBiddingTypes[0]}`
      : `${selectedBiddingTypes.length} ${translate('analytics_bidding_type_dropdown_multiple_selected')}`;

  return (
    <div className={styles.biddingTypeDropdownContainer}>
      <Tag
        isSelected
        onClick={event => {
          setAnchor(anchor ? null : (event?.currentTarget as HTMLElement));
          trackerService.track(MULTI_SELECT_BIDDING_TYPE_CLICK, {
            view: currentView,
            url: window.location.href,
          });
        }}
        className={styles.biddingTypeDropdownTag}
      >
        {biddingTitle}
        <Icon name="arrowDown" rotation={anchor ? 180 : 0} />
      </Tag>
      <TooltipWrapper
        position={TooltipPosition.TOP_CENTER}
        noTinyPaddingTop={true}
        message={translate('analytics_bidding_type_dropdown_information_tooltip')}
      >
        <ExclamationCircleFillDarkIcon
          className={styles.biddingTypeDropdownInfoIcon}
          width={14}
          height={14}
        />
      </TooltipWrapper>
      {anchor && (
        <Anchor anchor={anchor} onClose={() => setAnchor(null)}>
          <CheckboxSelect
            options={getAllCampaignTypes().map(({ code }) => ({
              id: code,
              key: code,
              value: code,
              label: code,
              checked: selectedBiddingTypes.includes(code),
            }))}
            onApply={clickApply}
          />
        </Anchor>
      )}
    </div>
  );
};
