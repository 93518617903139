import { ExtendedTimelineWidgetProps, MetricColumn } from '../../models';
import { TimelineProps, ViewDataPerDate } from './chart.types';

export function lineChartMetadata(
  columns: MetricColumn[],
  { dimension, metrics }: ExtendedTimelineWidgetProps,
): TimelineProps[] {
  if (dimension && dimension.values) {
    const metricId = metrics[0].id;
    return dimension.values.map((dimensionValue, index) => ({
      name: dimensionValue.label,
      wrapper: columns.find(column => column.key === metricId)!!.wrapper,
      dataGetter: (dataObject: ViewDataPerDate) =>
        (dataObject.viewData[index]?.[metricId] as number) ?? null,
    }));
  }
  const selectedColumns = metrics.map(metric => columns.find(column => metric.id === column.key)!!);
  return selectedColumns.map(column => ({
    name: column.name,
    wrapper: column.wrapper,
    dataGetter: (dataObject: ViewDataPerDate) => dataObject.viewData[0][column.key] as number,
  }));
}
