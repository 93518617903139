export enum NotificationLevel {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum NotificationType {
  FILE_BASE_BIDS_UPLOAD,
  FILE_BID_MODIFIERS_UPLOAD,
  FILE_PROPERTY_GROUPS_UPLOAD,
  FILE_CAMPAIGN_ALLOCATIONS_UPLOAD,
  FILE_CPA_VALUES_UPLOAD,
  FILE_SL_BM_UPLOAD,
  FILE_SL_BID_UPLOAD,
  FILE_SL_BUDGET_CAP_UPLOAD,
}

export const EVENT_NOTIFICATION = 'EVENT_NOTIFICATION';
export const EVENT_SNACKBAR_NOTIFICATION = 'EVENT_SNACKBAR_NOTIFICATION';
export const EVENT_DRAWER_NOTIFICATION = 'EVENT_DRAWER_NOTIFICATION';
export const TIMEOUT_NOTIFICATION = 5000;

export const MAX_NOTIFICATIONS = 100;
export const NOTIFICATIONS_PAGINATION = 10;
