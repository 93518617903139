/**
 *  Enable banner
 * @param key
 * @throws Exception if banner does not exist
 */
export const enable = (key: string): boolean => {
  if (isDefined(key)) {
    set(key, true);
    return true;
  }

  return false;
};

/**
 *  Disable banner
 * @param key
 * @throws Exception if banner does not exist
 */
export const disable = (key: string) => {
  if (isDefined(key)) {
    set(key, false);
    return true;
  }

  return false;
};

/**
 * Set banner
 * @param key
 * @param isEnabledParam by default true
 */
export const set = (key: string, isEnabledParam: boolean = true) =>
  localStorage.setItem(key, String(isEnabledParam));

/**
 * Get banner with key `key`
 * @param key
 */
export const get = (key: string): string | undefined => {
  const banner = localStorage.getItem(key);
  return banner !== null ? banner : undefined;
};

/**
 * Remove banner
 * @param key
 * @throws Exception if banner does not exist
 */
export const remove = (key: string) => {
  if (isDefined(key)) {
    localStorage.removeItem(key);
    return true;
  }

  return false;
};

/**
 * Checks if banner with key `key` is defined
 * @param key
 */
export const isDefined = (key: string): boolean => localStorage.getItem(key) !== null;

/**
 * Checks if banner with key `key` is enabled
 * @param key
 */
export const isEnabled = (key: string) => {
  if (!isDefined(key)) {
    return false;
  }
  return get(key) === String(true);
};
