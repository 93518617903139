import { YesterdayOverviewWidgetProps } from './yesterday-overview-widget.types';
import { translate, getLastXDays, dateFormatterLongYear } from '../../../helpers/utils';
import { Widget } from '../widget';
import { WidgetRow } from '../widget/widget-row';
import { WidgetTable } from '../widget/widget-table';
import { AppContext } from '../../../global/context/app-context';
import { getPartnersId } from '../../../services/app-service';
import { WidgetRemoveMenuItem } from '../widget/widget-remove-menu-item';
import { WidgetNoData } from '../widget/widget-no-data';
import { MetricTrend } from './metric-trend';
import { ButtonRetry } from '../../../components/button-retry';
import { useYesterdayOverviewData } from './hooks/use-yesterday-overview-data';
import { View } from '../../../models';
import { FC, useContext } from 'react';

export const YesterdayOverviewWidget: FC<YesterdayOverviewWidgetProps> = ({ onRemoveWidget }) => {
  const { selectedLocales, partner, subPartners } = useContext(AppContext);
  const { columns, data, isFetching, refetch, isError } = useYesterdayOverviewData(
    {
      partnersId: getPartnersId(partner, subPartners),
      localeCodes: [...selectedLocales],
    },
    [selectedLocales, subPartners],
  );

  return (
    <Widget
      menuItems={[<WidgetRemoveMenuItem key="1" />]}
      onSelectMenuItem={onRemoveWidget}
      isLoading={isFetching}
      title={
        <span className="u-font-weight--bold">
          {translate(
            'dashboard_yesterday_overview_widget_title',
            dateFormatterLongYear(getLastXDays(1)),
          )}
        </span>
      }
    >
      <div className="u-display--flex u-justify-content--center u-align-items--center u-height--100">
        {isError ? (
          <ButtonRetry message={translate('dashboard_widget_error')} onClick={refetch} />
        ) : data?.size === 0 ? (
          <WidgetNoData />
        ) : (
          <WidgetTable>
            {columns?.map(({ key, name, tooltip, wrapper }, index) => {
              const metricData = data?.get(key);
              return (
                <WidgetRow
                  key={index}
                  field={{ text: name, tooltip }}
                  contextMenu={{ menuItems: getContextMenu(name) }}
                >
                  <>
                    {metricData !== undefined ? (
                      <MetricTrend wrapper={wrapper} metric={metricData} />
                    ) : (
                      <td colSpan={3}>{translate('common_not_available')}</td>
                    )}
                  </>
                </WidgetRow>
              );
            })}
          </WidgetTable>
        )}
      </div>
    </Widget>
  );
};

const getContextMenu = (menuName: string) => [
  {
    menu: `Highest ${menuName}`,
    nestedMenu: [
      { id: View.HOTEL, menu: translate('analytics_home_hotel_view') },
      {
        id: View.POS,
        menu: translate('analytics_home_pos_selector'),
      },
    ],
  },
  {
    menu: `Lowest ${menuName}`,
    nestedMenu: [
      { id: View.HOTEL, menu: translate('analytics_home_hotel_view') },
      {
        id: View.POS,
        menu: translate('analytics_home_pos_selector'),
      },
    ],
  },
  {
    menu: 'Time Period',
    nestedMenu: [
      { id: View.TIME, menu: translate('analytics_calendar_last_7_days') },
      {
        id: View.TIME,
        menu: translate('analytics_calendar_last_30_days'),
      },
      {
        id: View.TIME,
        menu: translate('analytics_calendar_this_week'),
      },
      {
        id: View.TIME,
        menu: translate('analytics_calendar_this_month'),
      },
      {
        id: View.TIME,
        menu: translate('analytics_calendar_last_month'),
      },
    ],
  },
];
