import React from 'react';
import { Icon } from '../../../../components/icon';
import { Props } from './widget-menu-item.types';
import { Color } from '../../../../constants';

export const WidgetMenuItem: React.FunctionComponent<Props> = ({
  title,
  icon,
  disabled,
  onClick,
}) => {
  const color = disabled ? Color.Grey500 : undefined;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="widget-menu-item"
      key="explore-chart-menu-item"
    >
      <Icon type={icon} fill={color} />
      <span className="u-margin-left--small">{title}</span>
    </button>
  );
};
