import * as React from 'react';
import { Props } from './notification-drawer-item.types';
import './notification-drawer-item.css';
import cn from 'classnames';
import { TranslationKey, formatDateAndTime, translate } from '../../../../../helpers/utils';
import ApiNotificationsHelper from '../../../../../helpers/api/notifications/api-notifications-helper';
import { showNotification } from '../../../../../services/notification-service';
import { NotificationLevel } from '../../../../../constants';

export const NotificationDrawerItem: React.FunctionComponent<Props> = ({
  notification,
  onRead,
}) => {
  const markReadNotification = () => {
    try {
      ApiNotificationsHelper.markRead(notification.id, !notification.isRead);
      onRead();
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  return (
    <div
      className={cn(
        'u-padding--gutter--medium c-notification-drawer-notification-notification__unread',
        {
          'u-background--blue-lightest': !notification.isRead,
        },
      )}
    >
      <div className="u-display--flex">
        <div>
          <span className="u-font-weight--bold">{notification.title} - </span>
          <div>
            {Array.isArray(notification.message)
              ? notification.message.map(messageTmp => translate(messageTmp as TranslationKey))
              : translate(notification.message as TranslationKey)}
          </div>
          {notification.link && (
            <a href={notification.link} target="_blank" rel="noopener noreferrer">
              {notification.link}
            </a>
          )}
        </div>
      </div>
      <div className="u-display--flex u-flex-wrap--wrap u-justify-content--space-between u-margin-top--small u-color--juri-light">
        <span className="u-margin-right--small">{notification.brandName}</span>
        <span className="c-notification-drawer-notification__time">
          {formatDateAndTime(notification.date)}
        </span>
        <span
          className="u-color--blue u-cursor--pointer c-notification-drawer-notification__mark-read"
          onClick={markReadNotification}
        >
          {!notification.isRead
            ? translate('notifications_mark_notification_as_read')
            : translate('notifications_mark_notification_as_unread')}
        </span>
      </div>
    </div>
  );
};
