import { FC, useState } from 'react';
import styles from './unauthorized.module.css';
import UnauthorizedLogo from './unauthorized-account.svg';
import { getEmailFromLocalStorage } from '../../services/profile-service';
import { getLogoutUrl } from '../../helpers/api/api-auth-helper';
import { Button } from '../../core-ui/components/button/button';

export const Unauthorized: FC = () => {
  const [isRedirectTriggered, setIsRedirectedTriggered] = useState(false);

  if (isRedirectTriggered) {
    window.location.href = getLogoutUrl();
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.unauthorized}>
        <section>
          <img src={UnauthorizedLogo} width="164" alt="Icon showing access is unathorized" />
        </section>
        <h1>{getEmailFromLocalStorage()}</h1>
        <p>Your trivago account is not yet activated for trivago Intelligence.</p>
        <p>Please contact your Account Manager to request access.</p>
        <div className={styles.buttonWrapper}>
          <Button text="Back to login options" onClick={() => setIsRedirectedTriggered(true)} />
        </div>
      </div>
    </div>
  );
};
