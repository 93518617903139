import { FunctionComponent, MutableRefObject, useContext, useEffect } from 'react';
import {
  BiddingTypeDimension,
  BiddingTypeFilterProps,
} from '../../../components/bidding-type-dimension';
import { MultiLevelMenu } from '../../../components/multi-level-menu';
import {
  PosSelector,
  SelectableLocale,
  SelectableRegion,
} from '../../../components/pos-selector/pos-selector';
import { BidModifierDimension } from '../bid-modifier-dimension-menu';
import {
  BidModifierDimensionName,
  DestinationDimensionName,
  MenuItemType,
  MenuItemsProps,
  SelectedDimension,
  SelectedDimensionMenuProps,
  isBidModifierDimension,
  isDestinationDimension,
} from './multi-folder-select.types';

import { BrandDimension, BrandFilterProps } from '../../../components/brand-dimension-selector';
import {
  PropertyGroupFilterProps,
  PropertyGroupsDimension,
} from '../../../components/property-groups-dimension-selector';
import { PropertyNameDimension } from '../../../components/property-name-dimension';
import { TimePeriodSelector } from '../../../components/time-period-selector/time-period-selector';
import { AppContext } from '../../../global/context/app-context';
import { getAllBiddingTypesWithSL } from '../../../helpers/transformers';
import { Period, dateFromYmd, isCustomStartingDate, translate } from '../../../helpers/utils';
import { useDatePickerModal } from '../../../hooks/use-date-picker-modal';
import { useLocales, useRegions } from '../../../hooks/use-locales';
import { usePropertyGroups } from '../../../hooks/use-property-groups';
import { getSelectedBrands } from '../../../services/analytics-service';
import trackerService from '../../../services/tracker/tracker-service';
import { DimensionWithLabel } from '../bid-modifier-dimension-menu/bid-modifier-dimension-menu.types';
import { dimensionTitleTranslationKey } from '../bid-modifier-dimension-menu/bm-dimension-breakouts';
import { DestinationDimensionMenu } from '../destination-dimension-menu';
import { useFullScreenDatePickerModal } from '../../../hooks/use-fullscreen-date-picker';
import { CheckboxSelectOption } from '../../../components/checkbox-select';
import { format } from 'date-fns';
import { DATE_FORMAT_STANDARD } from '../../../constants';

type UnorderedMenuItem = Omit<MenuItemType, 'key'>;

export const DimensionMenuItems: FunctionComponent<
  MenuItemsProps & {
    showDatePickerRef: MutableRefObject<boolean>;
  }
> = ({ applyFilters, selectedDimension, showDatePickerRef }) => {
  const { partner } = useContext(AppContext);
  const locales = useLocales();
  const regions = useRegions();

  const sortedLocales = [...locales].sort((a, b) => a.name.localeCompare(b.name));
  const sortedRegions = [...regions].sort((a, b) => a.regionName.localeCompare(b.regionName));

  const getLocales = (): SelectableLocale[] => {
    const selectedLocales = selectedDimension?.values;
    return sortedLocales.map(item =>
      item.localeCode === selectedLocales?.find(option => option.value === item.localeCode)?.value
        ? { ...item, selected: true }
        : { ...item, selected: false },
    );
  };
  const getRegions = (): SelectableRegion[] => {
    const selectedRegions = selectedDimension?.values;
    return sortedRegions.map(item => ({
      ...item,
      selected: selectedRegions?.some(option => option.value === item.regionName) ?? false,
    }));
  };

  function prevSelectedDimensions(
    dimension: SelectedDimension | undefined,
  ): DimensionWithLabel | undefined {
    if (!dimension) return;
    return {
      label: dimension.name as BidModifierDimensionName,
      selectedOptions: dimension.values.map(({ label, value }) => ({
        dimension: dimension.name as BidModifierDimensionName,
        breakout: {
          label,
          value: Number(value),
        },
      })),
    };
  }

  function prevSelectedBiddingTypes(
    dimension: SelectedDimension | undefined,
  ): BiddingTypeFilterProps | undefined {
    if (!dimension) return;
    return {
      name: dimension.name,
      selectedOptions: getAllBiddingTypesWithSL().filter(
        campaignType =>
          dimension.values.map(option => option.value).includes(campaignType.value) ?? false,
      ),
    };
  }

  const subBrands = getSelectedBrands(partner, partner.subpartners);

  const previouslySelectedBrands = (
    dimension: SelectedDimension | undefined,
  ): BrandFilterProps | undefined => {
    if (!dimension) return;
    return {
      name: dimension.name,
      selectedOptions: subBrands
        .filter(item => dimension.values.map(option => option.value).includes(item.value) ?? false)
        .map(item => ({ code: item.label, value: item.value })),
    };
  };

  const allPropertyGroups = usePropertyGroups();

  const previouslySelectedPropertyGroups = (
    dimension: SelectedDimension | undefined,
  ): PropertyGroupFilterProps | undefined => {
    if (!dimension) return;

    return {
      name: dimension.name,
      selectedOptions: allPropertyGroups
        .filter(pg => dimension.values.map(option => Number(option.value)).includes(pg.id) ?? false)
        .map(pg => ({ propertyGroupName: pg.name, value: pg.id })),
    };
  };

  const bidModifierMenuItem: UnorderedMenuItem = {
    label: translate('analytics_chart_builder_dimension_bm_title'),
    subMenu: [
      {
        key: 1,
        label: (
          <BidModifierDimension
            applyFilter={({ label, selectedOptions }) => {
              applyFilters({
                name: label,
                values: selectedOptions.map(({ breakout }) => breakout),
              });
            }}
            previouslySelectedBreakouts={prevSelectedDimensions(selectedDimension)}
          />
        ),
      },
    ],
  };

  const biddingTypeMenuItem: UnorderedMenuItem = {
    label: 'Bidding Type',
    subMenu: [
      {
        key: 1,
        label: (
          <BiddingTypeDimension
            applyBiddingTypeFilter={({ name, selectedOptions }) => {
              applyFilters({
                name,
                values: selectedOptions.map(biddingType => ({
                  label: biddingType.code,
                  value: biddingType.value,
                })),
              });
            }}
            previouslySelectedTypes={prevSelectedBiddingTypes(selectedDimension)}
          />
        ),
      },
    ],
  };

  const posMenuItem: UnorderedMenuItem = {
    label: 'Point of Sale',
    subMenu: [
      {
        key: 1,
        label: <PosSelector onApply={applyFilters} locales={getLocales()} regions={getRegions()} />,
      },
    ],
  };

  const brandsMenuItem: UnorderedMenuItem = {
    label: 'Brand',
    subMenu: [
      {
        key: 1,
        label: (
          <BrandDimension
            applyBrandFilter={({ name, selectedOptions }) => {
              applyFilters({
                name,
                values: selectedOptions.map(item => ({
                  label: item.code,
                  value: item.value,
                })),
              });
            }}
            previouslySelectedBrands={previouslySelectedBrands(selectedDimension)}
            brands={subBrands}
          />
        ),
      },
    ],
  };

  const propertyNameMenuItem: UnorderedMenuItem = {
    label: translate(dimensionTitleTranslationKey('propertyName')),
    subMenu: [
      {
        key: 1,
        label: (
          <PropertyNameDimension
            applyFilter={selectedOptions => {
              applyFilters({
                name: 'propertyName',
                values: selectedOptions.map(option => ({
                  value: option.itemId,
                  label: option.name,
                })),
              });
            }}
            selectedPropertyIds={
              selectedDimension?.values.map(dimensionValue => dimensionValue.value as string) ?? []
            }
          />
        ),
      },
    ],
  };

  const propertyGroupsMenuItem: UnorderedMenuItem = {
    label: 'Property Groups',
    subMenu: [
      {
        key: 1,
        label: (
          <PropertyGroupsDimension
            applyPropertyGroupsFilter={({ name, selectedOptions }) => {
              applyFilters({
                name,
                values: selectedOptions.map(item => ({
                  value: item.value,
                  label: item.propertyGroupName,
                })),
              });
            }}
            previouslySelectedPropertyGroups={previouslySelectedPropertyGroups(selectedDimension)}
          />
        ),
      },
    ],
  };

  const destinationMenuItems: UnorderedMenuItem = {
    label: translate('analytics_chart_builder_dimension_destination_title'),
    subMenu: [
      {
        key: 1,
        label: (
          <DestinationDimensionMenu
            applyFilter={(name, selectedOptions) =>
              applyFilters({
                name,
                values: selectedOptions,
              })
            }
            selectedDestinationType={selectedDimension?.name as DestinationDimensionName}
            selectedDestinations={
              selectedDimension?.values.map(dimensionValue => dimensionValue.value as string) ?? []
            }
          />
        ),
      },
    ],
  };

  const { showDatePicker, isModalOpen } = useDatePickerModal();

  function showDatePickerAndTrackEvent() {
    showDatePicker();
    trackerService.track("Chart Builder: Change 'Time Period'");
  }

  const applyTimePeriodFilter = (selectedOptions: CheckboxSelectOption<number>[]) => {
    applyFilters({
      name: 'timePeriod',
      values: selectedOptions.map(option => {
        return {
          label: option.label as Period,
          value: option.id as Period,
        };
      }),
    });
  };

  const applyCustomDate = (date: Date) => {
    const customSelectedOption: CheckboxSelectOption<number> = {
      id: format(date, DATE_FORMAT_STANDARD),
      label: translate('pop_custom_starting_date'),
      checked: true,
      value: 0,
    };

    applyTimePeriodFilter([customSelectedOption]);
  };

  const fullScreenDatePickerProps = useFullScreenDatePickerModal({ onApply: applyCustomDate });

  useEffect(() => {
    if (showDatePickerRef) {
      showDatePickerRef.current = isModalOpen || Boolean(fullScreenDatePickerProps?.isModalOpen);
    }
  }, [isModalOpen, fullScreenDatePickerProps?.isModalOpen, showDatePickerRef]);

  const selectedTimePeriods =
    selectedDimension?.values.map(dimensionValue => dimensionValue.value as Period) ?? [];
  const customSelectedDate =
    selectedTimePeriods[0] && isCustomStartingDate(String(selectedTimePeriods[0]))
      ? dateFromYmd(String(selectedTimePeriods[0]))
      : undefined;

  const timePeriodMenuItems: UnorderedMenuItem = {
    label: 'Time Period',
    subMenu: [
      {
        key: 1,
        label: (
          <TimePeriodSelector
            applyFilter={applyTimePeriodFilter}
            selectedTimePeriods={selectedTimePeriods}
            showDatePicker={showDatePickerAndTrackEvent}
            {...fullScreenDatePickerProps}
            customSelectedDate={fullScreenDatePickerProps.customSelectedDate ?? customSelectedDate}
          />
        ),
      },
    ],
  };

  const menuItems: MenuItemType[] = [
    bidModifierMenuItem,
    biddingTypeMenuItem,
    posMenuItem,
    propertyNameMenuItem,
    propertyGroupsMenuItem,
    brandsMenuItem,
    destinationMenuItems,
    timePeriodMenuItems,
  ].map((menuItem, index) => ({
    ...menuItem,
    key: index,
  }));

  const shouldKeepMenuOpen = isModalOpen || fullScreenDatePickerProps.isModalOpen;

  return selectedDimension ? (
    <SelectedDimensionMenu menuItems={menuItems} selectedDimension={selectedDimension} />
  ) : (
    <MultiLevelMenu items={menuItems} isDatePickerModalOpen={shouldKeepMenuOpen} />
  );
};

const SelectedDimensionMenu: FunctionComponent<SelectedDimensionMenuProps> = ({
  menuItems,
  selectedDimension,
}) => {
  const isSelectedMenuItemBidModifier = (item: MenuItemType): boolean =>
    item.label === translate('analytics_chart_builder_dimension_bm_title') &&
    isBidModifierDimension(selectedDimension.name);
  const isSelectedDestinationDimension = (item: MenuItemType): boolean =>
    item.label === translate('analytics_chart_builder_dimension_destination_title') &&
    isDestinationDimension(selectedDimension.name);

  const filteredItem = menuItems.find((item: MenuItemType) => {
    return (
      isSelectedMenuItemBidModifier(item) ||
      isSelectedDestinationDimension(item) ||
      item.label === translate(dimensionTitleTranslationKey(selectedDimension.name))
    );
  });

  if (!filteredItem?.subMenu?.length) return null;
  return <>{filteredItem.subMenu[0].label}</>;
};
