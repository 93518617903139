import { translate } from '../../helpers/utils';
import { TeapotAnimation } from '../teapot-animation';
import { Button } from '../../core-ui/components/button/button';

export const FetchError = (props: { message: string; retry: VoidFunction }) => {
  return (
    <div
      className="u-display--flex u-flex-direction--column u-align-items--center u-justify-content--center"
      style={{ gap: 8 }}
    >
      <TeapotAnimation message={props.message} />
      <Button text={translate('common_retry')} onClick={() => props.retry()} variant="tertiary" />
    </div>
  );
};
