import React from 'react';
import { DeleteLineDarkIcon, DesktopLineDarkIcon } from 'tcp-react-icons';
import { ExploreChartIcon } from './explore-chart-icon';
import { MoveIcon } from './move-icon';
import { PencilIcon } from './pencil-icon';
import { SaveAsIcon } from './save-as-icon';
import { FilterNotAppliedIcon } from './filter-not-applied';
import { ExportResourceIcon } from './export-resource-icon';

export const iconTypes = {
  ExploreChartIcon: ExploreChartIcon,
  PencilIcon: PencilIcon,
  SaveAsIcon: SaveAsIcon,
  MoveIcon: MoveIcon,
  DeleteLineDarkIcon: DeleteLineDarkIcon,
  FilterNotAppliedIcon: FilterNotAppliedIcon,
  FullScreen: DesktopLineDarkIcon,
  ExportResource: ExportResourceIcon,
};

type Props = {
  type: keyof typeof iconTypes;
  fill?: string;
  className?: string;
};

export const Icon: React.FunctionComponent<Props> = ({ type, ...props }) => {
  const Component = iconTypes[type];
  return <Component {...props} />;
};
