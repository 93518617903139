import { Props } from './revert.types';
import './revert.css';
import { createRef, FC, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { TranslationKey, translate, translateHtml } from '../../helpers/utils';
import { Menu, MenuDirection } from '../menu';
import { useModal } from 'react-modal-hook';
import { ConfirmationModal } from '../confirmation-modal';
import { REVERT } from '../../constants';
import TrackerService from '../../services/tracker/tracker-service';
import { Button } from '../../core-ui/components/button/button';

export const Revert: FC<Props<any>> = ({ options, onApply }) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedRevertTypeIndex = useRef<number>(-1);

  const ref = createRef<HTMLDivElement>();
  useOnClickOutside(ref, () => setIsOpen(false));

  const [showModal, closeModal] = useModal(() => {
    return (
      <ConfirmationModal
        title={translate(options[selectedRevertTypeIndex.current!].modal.title as TranslationKey)}
        details={[translateHtml(options[selectedRevertTypeIndex.current!].modal.subtitle)]}
        onClose={closeModal}
        primaryButton={{
          title: translate('common_revert'),
          actionFn: () => {
            closeModal();
            onApply(options[selectedRevertTypeIndex.current!].type);
          },
          style: 'e-button--danger',
        }}
        secondaryButton={{
          title: translate('common_cancel'),
          actionFn: closeModal,
        }}
      />
    );
  });

  const onSelect = (index: number) => {
    const resetType = options[index];
    TrackerService.track(REVERT, { biddingType: resetType.type });
    setIsOpen(false);
    selectedRevertTypeIndex.current = index;
    showModal();
  };

  const generateMenuItems = () => {
    return options.map(({ option: { title, subtitle } }, idx) => (
      <div
        key={idx}
        className="
        c-revert__item
        u-display--flex
        u-margin-left--gutter--small
        u-margin-right--gutter--small
        u-border-bottom 
        u-border--juri-lightest
        "
      >
        <span className="u-padding-top--gutter u-padding-bottom--gutter u-padding-right--gutter">
          <p className="u-font-size--h-tiny u-font-weight--bold u-line-height--small u-padding-bottom--tiny">
            {translateHtml(title)}
          </p>
          <p className="c-revert__item-subtitle u-line-height--large2">
            {translate(subtitle as TranslationKey)}
          </p>
        </span>
      </div>
    ));
  };

  return (
    <div className="c-revert" ref={ref}>
      <div>
        <Button
          variant="tertiary"
          text={translate('common_revert')}
          icon={{ name: 'arrowDown', rotation: isOpen ? 180 : 0 }}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      <div className="u-position--relative c-revert__menu">
        {isOpen && (
          <Menu
            body={{ items: generateMenuItems() }}
            onSelectItem={onSelect}
            direction={MenuDirection.BottomCenter}
          />
        )}
      </div>
    </div>
  );
};
