import React from 'react';
import { SimpleSnackbar } from './simple-snackbar';
import { useSnackbar } from '../../global/app/snackbar/use-snackbar';
import './snackbar-display-container.css';

export const SnackbarDisplayContainer: React.FunctionComponent = () => {
  const { visibleSnackbarMessages } = useSnackbar();

  function getOffsetAndScale(position: number) {
    if (position === 0) return { offset: 100, scale: 1 };
    if (position === 1) return { offset: 115, scale: 0.95 };
    return { offset: 130, scale: 0.9 };
  }

  const transform = (position: number) => {
    const { offset, scale } = getOffsetAndScale(position);
    return `translateY(-${offset}%) scale(${scale})`;
  };
  return (
    <div className="snackbar-display-container">
      {visibleSnackbarMessages
        .slice()
        .reverse()
        .map((message, index) => (
          <div
            key={message.id}
            style={{
              position: 'absolute',
              transform: transform(visibleSnackbarMessages.length - index - 1),
            }}
          >
            <SimpleSnackbar {...message} />
          </div>
        ))}
    </div>
  );
};
