import { format } from 'date-fns';
import React from 'react';
import { ButtonRetry } from '../../../components/button-retry';
import { Loading } from '../../../components/loading';
import { DATE_FORMAT_LONG_COMA, FEATURE_FLAGS, TIME_FORMAT_SHORT } from '../../../constants';
import { Icon } from '../../../core-ui/components/icon';
import { useFilters } from '../../../global/context/filter-context';
import { translate } from '../../../helpers/utils';
import { Widget } from '../widget';
import { WidgetRemoveMenuItem } from '../widget/widget-remove-menu-item';
import { WidgetRow } from '../widget/widget-row';
import { WidgetTable } from '../widget/widget-table';
import './global-inventory-widget.css';
import { Props } from './global-inventory-widget.types';
import iconDownload from './icn-download.svg';
import { useGlobalInventory } from './use-global-inventory';

const datetimeFormat = `${DATE_FORMAT_LONG_COMA} \\a\\t ${TIME_FORMAT_SHORT}`;

export const GlobalInventoryWidget: React.FC<Props> = ({ onRemoveWidget }) => {
  const { data, isLoading, error, refetch, isDownloadingReport } = useGlobalInventory();

  function widgetBody() {
    if (error) {
      return <ButtonRetry message={translate('dashboard_widget_error')} onClick={refetch} />;
    }
    if (isDownloadingReport) {
      return (
        <div className="u-width--100 u-vertical-align--middle">
          <Loading message={translate('dashboard_global_inventory_download_in_progress')} />
        </div>
      );
    }

    const lastUpdate = data.lastUpdate ? format(new Date(data.lastUpdate), datetimeFormat) : null;

    return (
      <>
        {!FEATURE_FLAGS.enableGlobalInventoryWidget && (
          <div
            style={{
              position: 'absolute',
              top: '40%',
              color: 'var(--orange-600)',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80%',
              textAlign: 'center',
            }}
          >
            <Icon name="info" />
            <p>This widget is temporarily not available</p>
          </div>
        )}
        <div
          style={{
            filter: FEATURE_FLAGS.enableGlobalInventoryWidget ? '' : 'blur(4px)',
            pointerEvents: 'none',
          }}
        >
          <WidgetTable>
            {data.metrics.map(metric => (
              <WidgetRow
                key={metric.label}
                field={{ text: metric.label, tooltip: metric.tooltip || '' }}
              >
                <td>{metric.count}</td>
                {metric.downloadReport && metric.count > 0 && (
                  <td className="c-global-inventory-widget__icon">
                    <img
                      src={iconDownload}
                      className="u-color--juri-very-light u-height--16px u-cursor--pointer"
                      onClick={() => metric.downloadReport!()}
                      alt="Icon showing download global inventory status metric"
                    />
                  </td>
                )}
              </WidgetRow>
            ))}
          </WidgetTable>

          {lastUpdate && (
            <span className="u-margin-top--auto u-margin-left--small u-color--juri-lighter">
              Last update: {lastUpdate}
            </span>
          )}
        </div>
      </>
    );
  }

  const { includeSlData } = useFilters();
  const partialFiltersAppliedMessage = includeSlData
    ? 'SL filter is not applicable to this widget'
    : undefined;

  return (
    <Widget
      menuItems={[<WidgetRemoveMenuItem key="1" />]}
      onSelectMenuItem={onRemoveWidget}
      isLoading={isLoading}
      title={
        <span className="u-font-weight--bold">
          {translate('dashboard_global_inventory_widget_title')}
        </span>
      }
      partialFiltersAppliedMessage={partialFiltersAppliedMessage}
    >
      {widgetBody()}
    </Widget>
  );
};
