import './wdyr';
import ReactDOM from 'react-dom';
import { unregister } from './register-service-worker';
import './styles/index.css';
import { Login } from './views/login';

require('promise.prototype.finally').shim();

ReactDOM.render(<Login />, document.getElementById('root') as HTMLElement);
// @see https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#making-a-progressive-web-app
unregister();
