import cs from 'classnames';
import * as React from 'react';
import ArrowIcon from 'tcp-react-icons/lib/ArrowIcon';
import './composed-inline-dropdown.css';
import { Props } from './composed-inline-dropdown.types';

export const ComposedInlineDropdown: React.StatelessComponent<Props> = ({
  subTitle,
  isSelected,
  onClick,
  extraClass = '',
  children,
  alignArrowIconToRight = false,
}) => {
  return (
    <div
      className={cs(
        'c-composedInlineDropdown u-display--flex u-align-items--center u-color-juri-light  u-cursor--pointer u-border-radius u-padding--small',
        extraClass,
        { 'u-background--juri-lightest': isSelected },
      )}
      onClick={onClick}
    >
      <div className="u-display--flex u-align-items--center u-color--juri-light">
        {subTitle}
        <ArrowIcon
          className={cs('c-composedInlineDropdown__arrow-icon u-color-juri-light', {
            'c-composedInlineDropdown__arrow-icon--upside-down': isSelected,
            'c-composedInlineDropdown__arrow-icon': !alignArrowIconToRight,
            'c-composedInlineDropdown__arrow-icon-aligned-right': alignArrowIconToRight,
          })}
        />
      </div>
      {children}
    </div>
  );
};
