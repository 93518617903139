import { useQueryParam } from '../../../hooks/use-query-param';

export function useQueryParamPagination(data: any[]) {
  const [_page, _setPage] = useQueryParam('page');
  const page = _page ? Number(_page) : 1;
  const setPage = (p: number) => _setPage(String(p));

  const [_itemsPerPage, _setItemsPerPage] = useQueryParam('itemsPerPage');
  const itemsPerPage = _itemsPerPage ? Number(_itemsPerPage) : 50;
  const setItemsPerPage = (i: number) => _setItemsPerPage(String(i));

  const maxPage = Math.ceil(data.length / itemsPerPage);

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);

  return {
    page: {
      numPage: page,
      numItemsPerPage: itemsPerPage,
    },
    totalItems: data.length,
    onNextPage: () => setPage(Math.min(page + 1, maxPage)),
    onPreviousPage: () => setPage(Math.max(page - 1, 1)),
    onUpdateRowsPerPage: setItemsPerPage,
    setPage,
    paginatedData,
  };
}
