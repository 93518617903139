import React from 'react';
import { Observable } from '../../../../../helpers/utils/observable';

const isOpenStore = new Observable(false);

export const useFilterSetDeleteModal = (): [boolean, (isOpen: boolean) => void] => {
  const [isOpen, isOpenSubscriber] = React.useState<boolean>(isOpenStore.get());

  React.useEffect(() => isOpenStore.subscribe(isOpenSubscriber), []);

  const setIsOpen = React.useCallback((isOpen: boolean) => isOpenStore.set(isOpen), []);

  return [isOpen, setIsOpen];
};
