import { FC, MouseEvent, useContext } from 'react';
import { Modal } from '../modal';
import { DatePicker } from './date-picker';
import './date-picker.css';
import { DatePeriod, Selection } from '../../models';
import { useDateRangePeriod } from './date-picker.state';
import { getDifferenceBetweenDays, translate } from '../../helpers/utils';
import { format } from 'date-fns';
import { DATE_FORMAT_LONG_COMA } from '../../constants';
import { DateSelector } from '../../global/app/selector-bar/date-selector';
import { AppContext } from '../../global/context/app-context';
import { savePeriodToLocalStorage } from '../../helpers/local-storage-helper';

type Props = {
  onClose: () => void;
};
export const DatePickerWithModal: FC<Props> = ({ onClose }) => {
  const { onUpdatePeriod } = useContext(AppContext);
  const [globalDateRangePeriod, setGlobalDateRangePeriod] = useDateRangePeriod();
  const { from, to, period } = globalDateRangePeriod;

  const calculateDateRangeSubtitle = () => {
    const f = format(from, DATE_FORMAT_LONG_COMA);
    const t = format(to, DATE_FORMAT_LONG_COMA);

    let subtitle = `${translate(period)} (${f} - ${t})`;

    if (period === DatePeriod.CUSTOM) {
      const daysBetween = Math.abs(getDifferenceBetweenDays(from, to));
      subtitle = translate('selector_bar_period', (daysBetween + 1).toString(), `${f} - ${t}`);
    }

    if (period === DatePeriod.YESTERDAY) {
      subtitle = `${translate(period)} (${f})`;
    }

    return subtitle;
  };

  const onClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Modal backdropStatic={false} onClose={() => {}} backdropClassName="datePickerModal">
      <div>
        <div className="datePickerSelector">
          <DateSelector
            isDateSelectionAvailable
            calculateDateRangeSubtitle={() => <span>{calculateDateRangeSubtitle()}</span>}
            selectorBarOptionSelected={Selection.CALENDAR}
            clickOpenClose={() => true}
          />
        </div>
        <DatePicker
          offset="left"
          isPeriodChoosable
          from={from}
          to={to}
          period={period}
          onClose={() => {
            onClose();
          }}
          onClick={onClick}
          onUpdateRange={(fromTmp, toTmp, periodValueTmp) => {
            const dateRangePeriod = {
              from: fromTmp,
              to: toTmp,
              period: periodValueTmp,
            };
            onUpdatePeriod(dateRangePeriod);
            savePeriodToLocalStorage(dateRangePeriod);
            onClose();
            setGlobalDateRangePeriod(dateRangePeriod);
          }}
          isModal
        />
      </div>
    </Modal>
  );
};
