import { RequestHelper } from '../request-helper';
import {
  Reports,
  DateRange,
  ReportTypeDetail,
  ReportsType,
  ReportFrequency,
  EventType,
} from '../../../models';
import {
  ReportTypeDetailResponse,
  STRING_TO_REPORT_FREQUENCY,
  DEFAULT_WEEKLY_DAY,
  DEFAULT_PROTO_DAY,
  ReportUpdate,
  ReportEnable,
  ReportArtifact,
} from './types';
import { getFormattedDates } from '../../utils';
import { AxiosError } from 'axios';

const URL_FETCH_REPORTS = '/reports/partners';
const URL_FETCH_REPORTS_TYPE = '/analytics/data/job-type-view';
const URL_UPDATE_REPORT = '/reports/partners';
const URL_UPDATE_REPORT_ENABLE = '/reports/partners/enable';
const URL_FETCH_REPORT_ARTIFACTS_LIST = '/reports/artifacts';

class ApiReportsHelper extends RequestHelper {
  private static instance: ApiReportsHelper;

  public static getInstance() {
    if (!ApiReportsHelper.instance) {
      ApiReportsHelper.instance = new ApiReportsHelper();
    }

    return ApiReportsHelper.instance;
  }

  public async fetchReports(partnerId: number): Promise<Reports[]> {
    try {
      const body = {
        partnerIds: [partnerId],
      };
      const response = await this.postRequest(`${URL_FETCH_REPORTS}`, body);
      return this.data2Reports(response.data);
    } catch (e) {
      this.handleError(e as AxiosError, 'reports_fetch_error');
      return [];
    }
  }

  public async fetchReportArtifactsList(
    partnerId: number,
    reportName: string,
  ): Promise<ReportArtifact[]> {
    try {
      const response = await this.getRequest(`${URL_FETCH_REPORT_ARTIFACTS_LIST}/${partnerId}`, {
        params: { reportName },
      });
      return response.data;
    } catch (e) {
      this.handleError(e as AxiosError, 'reports_fetch_error_by_type');
      return [];
    }
  }

  public async fetchReportsByType(
    reportType: string,
    { from, to }: DateRange,
    partnerId: number,
  ): Promise<ReportTypeDetail[]> {
    try {
      const body = {
        condition: [
          { columnName: 'type', type: 'EQUALS', value: String(EventType.REPORTS) },
          { columnName: 'properties', type: 'CONTAINS', value: reportType },
          { columnName: 'properties', type: 'NOT_CONTAINS', value: `${reportType}-` },
        ],
        partnerId: [partnerId],
        date: getFormattedDates(from, to),
      };
      const response = await this.postRequest(`${URL_FETCH_REPORTS_TYPE}`, body);
      return this.convertReportsByType(response.data);
    } catch (e) {
      this.handleError(e as AxiosError, 'reports_fetch_error_by_type');
      return [];
    }
  }

  public async updateReports(partnerId: number, report: ReportsType) {
    try {
      const body = this.reportType2ReportUpdate(partnerId, report);
      await this.putRequest(URL_UPDATE_REPORT, body);
    } catch (e) {
      this.handleError(e as AxiosError, 'reports_update_error');
    }
  }

  public async enableReports(partnerId: number, report: ReportsType, partnerFolder: string) {
    try {
      const body = this.reportType2ReportEnable(partnerId, report, partnerFolder);
      await this.postRequest(URL_UPDATE_REPORT_ENABLE, body);
    } catch (e) {
      this.handleError(e as AxiosError, 'reports_update_error');
    }
  }

  private reportType2ReportUpdate(partnerId: number, report: ReportsType): ReportUpdate {
    return {
      partnerId,
      period: {
        frequency: report.frequency,
        day: report.frequency === ReportFrequency.WEEKLY ? DEFAULT_WEEKLY_DAY : DEFAULT_PROTO_DAY,
      },
      isActive: report.isActive,
      reportId: report.id !== 0 ? report.id : undefined,
    };
  }

  private reportType2ReportEnable(
    partnerId: number,
    report: ReportsType,
    partnerFolder: string,
  ): ReportEnable {
    return {
      partnerId,
      period: {
        frequency: report.frequency,
        day: report.frequency === ReportFrequency.WEEKLY ? DEFAULT_WEEKLY_DAY : DEFAULT_PROTO_DAY,
      },
      name: report.name,
      partnerFolder: partnerFolder !== '' ? undefined : report.partnerFolder,
    };
  }

  private data2Reports(data: any[]): Reports[] {
    return data.map((dataTmp: any) => {
      return {
        partnerId: dataTmp.partnerId,
        partnerFolder: dataTmp.partnerFolder,
        reports: dataTmp.reports.map((reportTmp: any) => {
          return {
            name: reportTmp.name,
            frequency:
              reportTmp.frequency !== ''
                ? STRING_TO_REPORT_FREQUENCY[reportTmp.frequency]
                : ReportFrequency.DAILY,
            supportedFrequencies: reportTmp.supportedFrequencies.map(
              (supportedFrequencyTmp: string) => STRING_TO_REPORT_FREQUENCY[supportedFrequencyTmp],
            ),
            subFolder: reportTmp.subFolder,
            isActive: reportTmp.isActive,
            id: reportTmp.reportId,
            documentationUri: reportTmp.documentationUri,
          };
        }),
      };
    });
  }

  private convertReportsByType(data: ReportTypeDetailResponse[]): ReportTypeDetail[] {
    return data.map(({ created_at, properties, log_job_id }) => {
      const jsonData = JSON.parse(properties);
      return {
        sourceFileAwsPath: jsonData['sourceFileAWSPath'],
        reportName:
          !!jsonData['reportName'] && jsonData['reportName'] !== ''
            ? jsonData['reportName']
            : undefined,
        jobId: log_job_id,
        created: new Date(created_at),
      };
    });
  }
}

export default ApiReportsHelper.getInstance();
