import React, { FunctionComponent, useState } from 'react';
import { CheckboxSelect } from '../checkbox-select/checkbox-select';
import {
  CheckboxSelectOption,
  DEFAULT_OPTIONS_LIMIT,
} from '../checkbox-select/use-checkbox-options';
import { Props } from './brand-dimension-selector.types';

export const BrandDimension: FunctionComponent<Props> = ({
  applyBrandFilter,
  previouslySelectedBrands,
  brands,
}) => {
  const selectedTypes = previouslySelectedBrands && previouslySelectedBrands.selectedOptions;

  const brandsSelection = brands.map(item => ({
    id: item.value,
    label: item.label,
    checked: selectedTypes?.some(option => option.value === item.value) ? true : false,
    value: item.value,
  }));
  const [selectedBrandOptions, setSelectedBrandOptions] =
    useState<CheckboxSelectOption<number | string>[]>(brandsSelection);

  const handleApply = (options: CheckboxSelectOption<number | string>[] | null) => {
    const selectedOptions = options?.filter(option => option.checked);

    if (selectedOptions) {
      setSelectedBrandOptions(selectedOptions);
      const mappedSelectedOptions = selectedOptions.map(option => ({
        value: option.value,
        code: option.label,
      }));
      applyBrandFilter({
        name: 'brand',
        selectedOptions: mappedSelectedOptions,
      });
    }
  };

  return (
    <div>
      <CheckboxSelect
        title="Brands"
        searchEnabled={true}
        searchPlaceholder="Search brands"
        options={selectedBrandOptions}
        onApply={options => handleApply(options)}
        selectedOptionsLimit={
          selectedBrandOptions.length > DEFAULT_OPTIONS_LIMIT ? DEFAULT_OPTIONS_LIMIT : undefined
        }
      />
    </div>
  );
};
