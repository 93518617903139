import { differenceInCalendarDays, format, subDays } from 'date-fns';
import apiWidgetsHelper from '../../helpers/api/dashboard/api-widgets-helper';
import { WidgetPayload } from '../../helpers/api/dashboard/types';
import {
  Period,
  TranslationKey,
  customStartingDateToDateRange,
  dateFromYmd,
  getComparePeriodWithLabels,
  isCustomStartingDate,
  shiftYmdForwards,
  translate,
} from '../../helpers/utils';
import { DatePeriod, DateRangePeriod, View, ViewData } from '../../models';
import { DATE_FORMAT_STANDARD } from '../../constants';

export type GetTimePeriodDataRequest = {
  widgetPayload: WidgetPayload;
  basePeriod: DateRangePeriod;
  view: View;
};

export const getTimePeriodData = async (request: GetTimePeriodDataRequest) => {
  const { widgetPayload, basePeriod, view } = request;
  const comparedPeriod = (widgetPayload?.dimensionValues || [])[0] as Period; // Hardcoded to take the first value

  function getComparedPeriodData() {
    if (isCustomStartingDate(comparedPeriod)) {
      const { from: comparedFrom, to: comparedTo } = customStartingDateToDateRange(
        basePeriod,
        comparedPeriod,
      );
      return fetchData(comparedFrom, comparedTo);
    }
    const { from: comparedFrom, to: comparedTo } = getComparePeriodWithLabels(
      basePeriod,
      comparedPeriod,
    );
    return fetchData(comparedFrom, comparedTo);
  }

  const fetchData = (from: Date, to: Date) =>
    apiWidgetsHelper.getWidgetsData(view, { ...widgetPayload, from, to });

  const [basePeriodData, comparedPeriodData] = await Promise.all([
    fetchData(basePeriod.from, basePeriod.to),
    getComparedPeriodData(),
  ]);

  return [
    ...extendWithTimePeriod(basePeriodData, basePeriod.period),
    ...extendWithTimePeriod(
      comparedPeriodData.map(withShiftedDate(basePeriod, comparedPeriod)),
      comparedPeriod,
    ),
  ];
};

function extendWithTimePeriod(viewData: ViewData[], timePeriod: DatePeriod | Period): ViewData[] {
  return viewData.map(el => ({ ...el, timePeriod: translate(timePeriod as TranslationKey) }));
}

function withShiftedDate(basePeriod: DateRangePeriod, comparedPeriod: Period) {
  return function map(el: ViewData): ViewData {
    if (isCustomStartingDate(comparedPeriod)) {
      return {
        ...el,
        date: customStartingDateShifted(basePeriod, comparedPeriod, String(el.date)),
      };
    }
    // TODO: check if date2 is also needed
    return { ...el, date: shiftYmdForwards(el.date, comparedPeriod, basePeriod) };
  };
}

const customStartingDateShifted = (
  basePeriod: DateRangePeriod,
  comparedPeriod: string,
  customDateYMD: string,
): number => {
  const customStartingDate = dateFromYmd(comparedPeriod);
  const customDate = dateFromYmd(customDateYMD);
  // Duration in days can be negative, so we always addDays below
  const durationInDays = differenceInCalendarDays(customStartingDate, basePeriod.from);
  return Number(format(subDays(customDate, durationInDays), DATE_FORMAT_STANDARD));
};
