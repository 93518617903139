import * as React from 'react';
import { Props } from './single-selection-info.types';
import './single-selection-info.css';
import { FlagIcon } from '../../../../components/flag-icon';

export const SingleSelectionInfo: React.FunctionComponent<Props> = ({
  campaign: { pos, type },
}) => {
  return (
    <>
      <span className="u-margin-right--gutter">
        <FlagIcon className="c-single-selection-info__flag" flag={pos.localeCode} />
      </span>
      <span className="u-font-weight-bold">
        {pos.name} - {type.code}
      </span>
    </>
  );
};
