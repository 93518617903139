import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Banner } from '../../helpers/api/banner/types';
import { UseBanner } from './use-banner.types';
import { apiBannerSwitchHelper } from '../../helpers/api/banner/api-banner-switch-helper';

const BANNER_QUERY_KEY = ['banner'];

export const useBanner: UseBanner = () => {
  const queryClient = useQueryClient();

  const isEmpty = (banner: Banner | null) => !banner || (!banner.header && !banner.body);

  const fetchBanner = async () => {
    const fetchedBanner = await apiBannerSwitchHelper.getBanner();
    return isEmpty(fetchedBanner) ? null : fetchedBanner;
  };

  const { data: loadedBanner } = useQuery({
    queryKey: BANNER_QUERY_KEY,
    queryFn: fetchBanner,
  });

  const onSuccess = () => void queryClient.invalidateQueries(BANNER_QUERY_KEY);

  const enableMutation = useMutation(apiBannerSwitchHelper.enableBanner, { onSuccess: onSuccess });
  const enable = (banner: Banner) => enableMutation.mutate(banner);

  const disableMutation = useMutation(apiBannerSwitchHelper.disableBanner, {
    onSuccess: onSuccess,
  });
  const disable = () => disableMutation.mutate();

  const isUpdating = enableMutation.isLoading || disableMutation.isLoading;
  return {
    banner: loadedBanner ?? undefined,
    isUpdating: isUpdating,
    enable: enable,
    disable: disable,
  };
};
