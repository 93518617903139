import { useState } from 'react';

export type CheckboxSelectOption<T> = {
  id: string | number;
  label: string;
  subLabel?: string;
  tooltip?: string;
  checked: boolean;
  isRadio?: boolean;
  value: T;
  isDisabled?: boolean;
};

type UseOptionsResult<T> = {
  options: CheckboxSelectOption<T>[];
  visibleOptions: CheckboxSelectOption<T>[];
  isMaxReached: boolean;
  toggle(option: CheckboxSelectOption<T>): void;
  filterVisible(searchText: string): void;
  toggleAvailability: (enable: boolean) => void;
  selectAll: VoidFunction;
  clearSelection: VoidFunction;
};

export const DEFAULT_OPTIONS_LIMIT = 12;

export function useCheckboxOptions<T>(
  initial: CheckboxSelectOption<T>[],
  checkedOptionsLimit?: number,
): UseOptionsResult<T> {
  const [options, setOptions] = useState(initial);
  const [searchText, setSearchText] = useState('');
  const checkedOptionsCount = options.filter(o => o.checked).length;
  const isMaxReached = checkedOptionsCount === checkedOptionsLimit;
  const lowerCaseSearchText = searchText.toLowerCase();
  const visibleOptions = options.filter(
    o =>
      o.label.toLowerCase().includes(lowerCaseSearchText) ||
      o.subLabel?.toLowerCase().includes(lowerCaseSearchText),
  );

  function toggle(option: CheckboxSelectOption<T>) {
    if (option.isRadio) return toggleRadio(option);
    return toggleCheckbox(option);
  }

  function toggleRadio(option: CheckboxSelectOption<T>) {
    const desiredState = !option.checked;
    if (desiredState === false) return;
    setOptions(prev => withChecked(prev, option));
  }

  function toggleCheckbox(option: CheckboxSelectOption<T>) {
    const desiredState = !option.checked;
    if (desiredState === true && isMaxReached) return;
    if (desiredState === true) setOptions(uncheckAllRadio);
    option.checked = desiredState;
    setOptions(prev => [...prev]);
  }

  function toggleAvailability(enable: boolean) {
    setOptions(options.map(option => ({ ...option, isDisabled: !enable })));
  }

  return {
    options,
    visibleOptions,
    isMaxReached,
    toggle,
    filterVisible: setSearchText,
    selectAll: () => setOptions(checkAll),
    toggleAvailability: toggleAvailability,
    clearSelection: () => setOptions(uncheckAll),
  };
}

type OptionsMapper = <T>(options: CheckboxSelectOption<T>[]) => CheckboxSelectOption<T>[];

const withChecked = <T>(
  options: CheckboxSelectOption<T>[],
  option: CheckboxSelectOption<T>,
): CheckboxSelectOption<T>[] => options.map(o => ({ ...o, checked: o.id === option.id }));

const uncheckAllRadio: OptionsMapper = options =>
  options.map(o => (o.isRadio ? { ...o, checked: false } : o));
const uncheckAll: OptionsMapper = options => options.map(o => ({ ...o, checked: false }));
const checkAll: OptionsMapper = options => options.map(o => ({ ...o, checked: true }));
