import { Color } from '../../../constants/color';
import { TimeOption } from '../../../constants';
import {
  Aggregation,
  DashBoardType,
  FilterColumn,
  Sort,
  WidgetType,
  fromTo,
} from '../../../models';
import { Dimension } from '../../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';

type ApiWidget = {
  uuid: string;
  position: {
    x: number;
    y: number;
  };
  size: {
    height: number; //  min is 1, max is maxHeight value
    maxHeight: number; // default = 1
    width: number; // min is 1, max is 3
  };
  isDraggable: boolean; // default: true
  isResizable: boolean; // default is true
  type: number;
};

export type ApiDashBoard = {
  id: number;
  name: string;
  widgets: ApiDashboardWidget[];
};

export type ApiDashboardWidget =
  | ApiTimelineWidget
  | ApiYesterdayOverviewWidget
  | ApiPosOverviewWidget
  | ApiGlobalInventoryWidget
  | ApiMBLUWidget;

export type ApiTimelineWidget = {
  props: {
    metrics: Array<{
      id: string;
      color?: Color;
    }>;
  };
} & ApiWidget;

export type ApiYesterdayOverviewWidget = {
  props: {};
} & ApiWidget;

export type ApiPosOverviewWidget = {
  props: {};
} & ApiWidget;

export type ApiGlobalInventoryWidget = {
  props: {};
} & ApiWidget;

export type ApiMBLUWidget = {
  props: {};
} & ApiWidget;

/**
 *  API responses
 */

export type ApiDashboardSaveResponse = {
  id: number;
};

export type ApiDashboardEditResponse = {
  id: number;
};

/**
 *  CONVERTERS BETWEEN MODELS & API MODELS
 */

const API_WIDGET_UNDEFINED_NUMBER = 0;
const API_WIDGET_TIMELINE_NUMBER = 1;
const API_WIDGET_YESTERDAY_OVERVIEW_NUMBER = 2;
const API_WIDGET_POS_OVERVIEW_NUMBER = 3;
const API_WIDGET_GLOBAL_INVENTORY_NUMBER = 4;
const API_WIDGET_MBLU_NUMBER = 5;
const API_WIDGET_CHART_BUILDER = 6;

export const API_WIDGET_TYPE_2_WIDGET_TYPE: fromTo<number, WidgetType> = {
  [API_WIDGET_UNDEFINED_NUMBER]: WidgetType.UNKNOWN,
  [API_WIDGET_TIMELINE_NUMBER]: WidgetType.TIMELINE,
  [API_WIDGET_YESTERDAY_OVERVIEW_NUMBER]: WidgetType.YESTERDAY_OVERVIEW,
  [API_WIDGET_POS_OVERVIEW_NUMBER]: WidgetType.POS_OVERVIEW,
  [API_WIDGET_GLOBAL_INVENTORY_NUMBER]: WidgetType.GLOBAL_INVENTORY,
  [API_WIDGET_MBLU_NUMBER]: WidgetType.MBLU,
  [API_WIDGET_CHART_BUILDER]: WidgetType.CHART_BUILDER,
};

export const WIDGET_TYPE_2_API_WIDGET_TYPE: fromTo<WidgetType, number> = {
  [WidgetType.UNKNOWN]: API_WIDGET_UNDEFINED_NUMBER,
  [WidgetType.TIMELINE]: API_WIDGET_TIMELINE_NUMBER,
  [WidgetType.YESTERDAY_OVERVIEW]: API_WIDGET_YESTERDAY_OVERVIEW_NUMBER,
  [WidgetType.POS_OVERVIEW]: API_WIDGET_POS_OVERVIEW_NUMBER,
  [WidgetType.GLOBAL_INVENTORY]: API_WIDGET_GLOBAL_INVENTORY_NUMBER,
  [WidgetType.MBLU]: API_WIDGET_MBLU_NUMBER,
  [WidgetType.CHART_BUILDER]: API_WIDGET_CHART_BUILDER,
};

export type WidgetPayload = {
  aggregations?: Aggregation[];
  condition?: FilterColumn[];
  partnersId: number[];
  localeCodes?: string[];
  from?: Date;
  to?: Date;
  sort?: Sort[];
  type?: string;
  shownColumns?: string[];
  timeOption?: TimeOption;
  dimension?: Dimension;
  dimensionValues?: string[];
  metricKeys?: string[];
};

export enum DashboardType {
  DEFAULT = 0,
  EMPTY = 1,
}

export const DASHBOARD_CREATION_TYPE_TO_DASHBOARD_TYPE: fromTo<DashBoardType, DashboardType> = {
  [DashBoardType.DEFAULT]: DashboardType.DEFAULT,
  [DashBoardType.EMPTY]: DashboardType.EMPTY,
};
