import { FC } from 'react';
import { FilterFormField } from '../../../features/filters/filter-form-field/filter-form-field';
import { OldFilter, StringQualifier, NumericQualifier } from '../../../features/filters/types';
import { translate } from '../../../helpers/utils';
import { PopoverFilterType } from './global-active-filters.types';
import { Popover } from '../../../core-ui/components/popover/popover';

type FilterPopoverProps = {
  anchor: HTMLElement;
  setAnchor: (anchor: HTMLElement | null) => void;
  selectedFilter: PopoverFilterType | undefined;
  clearFilter: (filter: PopoverFilterType) => void;
  applyFilter: (filter: PopoverFilterType) => void;
  setSelectedFilter: (filter: PopoverFilterType | undefined) => void;
  isFilterInFilters: boolean;
};

export const FilterPopover: FC<FilterPopoverProps> = ({
  anchor,
  setAnchor,
  selectedFilter,
  clearFilter,
  applyFilter,
  setSelectedFilter,
  isFilterInFilters,
}) => (
  <Popover
    anchor={anchor}
    onClose={() => {
      setAnchor(null);
      setSelectedFilter(undefined);
    }}
    buttons={{
      start: [
        {
          text: translate('analytics_filter_clear'),
          variant: 'flat',
          disabled: !isFilterInFilters,
          onClick: () => {
            setSelectedFilter(undefined);
            clearFilter(selectedFilter as PopoverFilterType);
            setAnchor(null);
          },
        },
      ],
      end: [
        {
          text: 'Cancel',
          variant: 'tertiary',
          onClick: () => {
            setAnchor(null);
            setSelectedFilter(selectedFilter as PopoverFilterType);
          },
        },
        {
          text: 'Apply',
          variant: 'primary',
          disabled: !selectedFilter?.value,
          onClick: () => {
            applyFilter(selectedFilter as PopoverFilterType);
            setAnchor(null);
          },
        },
      ],
    }}
  >
    {selectedFilter && (
      <form
        onSubmit={() => {
          applyFilter(selectedFilter);
          setAnchor(null);
        }}
      >
        <FilterFormField
          filter={getFormFilter(selectedFilter)}
          setFilter={(currentFilter: OldFilter) =>
            setSelectedFilter({
              ...selectedFilter,
              //TODO: remove the string conversion when the backend is ready to accept numbers
              value: currentFilter.value?.toString(),
              type: currentFilter.qualifier,
            } as PopoverFilterType)
          }
        />
      </form>
    )}
  </Popover>
);

const getFormFilter = (filter: PopoverFilterType): OldFilter => {
  if (filter.columnType === 'string') {
    return {
      id: filter.columnName,
      name: filter.columnLabel,
      type: 'string',
      qualifier: (filter.type as StringQualifier) ?? 'CONTAINS',
      value: filter.value,
      decoration: filter.wrapper,
    };
  }
  return {
    id: filter.columnName,
    name: filter.columnLabel,
    type: 'numeric',
    qualifier: (filter.type as NumericQualifier) ?? 'EQUALS',
    value: filter.value,
    decoration: filter.wrapper,
  };
};
