import { FC } from 'react';
import { CheckboxSelectOption, DEFAULT_OPTIONS_LIMIT, CheckboxSelect } from '../checkbox-select';
import { Locale } from '../../models';
import { SelectableLocale } from './pos-selector';
import { translate } from '../../helpers/utils';

export type PosCountriesSelectorProps = {
  locales: SelectableLocale[];
  onApply: (locales: Locale[]) => void;
};

export const PosCountriesSelector: FC<PosCountriesSelectorProps> = ({ locales, onApply }) => {
  return (
    <CheckboxSelect
      options={locales.map(toOption)}
      onApply={options => onApply(options.filter(o => o.checked).map(o => o.value))}
      title={`${translate('analytics_home_pos_selector')} · ${translate(
        'analytics_chart_builder_dimension_posCountry_title',
      )}`}
      searchEnabled
      searchPlaceholder="Search countries"
      selectedOptionsLimit={DEFAULT_OPTIONS_LIMIT}
    />
  );
};

const toOption = (locale: SelectableLocale): CheckboxSelectOption<Locale> => ({
  id: locale.localeCode,
  label: locale.name,
  checked: locale.selected,
  value: locale,
  isRadio: (locale as any).isRadio,
});
