import { useEffect, useRef, useState, FC, useContext } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatNumber } from '../../helpers/formater';
import { Props, ViewDataPerDate } from './chart.types';
import { ChartColor, Color, DATE_FORMAT_LONG, DATE_FORMAT_LONG_COMA } from '../../constants';
import { WidgetTooltip } from '../widget-tooltip';
import './chart.css';
import { DatePeriod, DimensionValueLabels, ViewData } from '../../models';
import { chain, groupBy, keyBy, mapValues } from 'lodash';
import { DIMENSION_AGGREGATION } from '../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';
import {
  getHiddenLinesFromLocaleStorage,
  saveHiddenLinesToLocaleStorage,
} from '../../helpers/local-storage-helper';
import { lineChartMetadata } from './line-chart-meta-data';
import { TooltipPosition, TooltipWrapper } from '../tooltip-wrapper';
import cs from 'classnames';
import { AppContext } from '../../global/context/app-context';
import {
  customStartingDateToDateRange,
  dateFromYmd,
  formatDateRange,
  formattedDate,
  getCompareDateRange,
  getComparePeriodWithLabels,
  getDifferenceBetweenDays,
  isCustomStartingDate,
  periodFromEn,
  shiftBackwards,
  shiftToCustomStartingDate,
  translate,
} from '../../helpers/utils';
import { format } from 'date-fns';

export const ChartBuilderLineChart: FC<Props> = ({
  data,
  columns,
  chartProps,
  variant,
  lineType = 'monotone',
  lineDot = false,
  xAxisDataKey = 'date',
  tickFormatterX,
  toolTipTitle,
  tickLineX = false,
  tickLineY = false,
  xAxisLabel,
  leftYAxisLabel,
  rightYAxisLabel,
  chartHeight,
}) => {
  const { dateRangePeriod } = useContext(AppContext);
  const selectedDimension = chartProps.dimension;
  const isAppliedCustomStartingDate =
    selectedDimension?.name === 'timePeriod' &&
    isCustomStartingDate(String(selectedDimension?.values[1].label));
  const [shouldShortenText, setShouldShortenText] = useState<boolean>(false);
  const chartRef = useRef<HTMLDivElement>(null);

  const createDummyViewDataForEachDimension = (aggregationColumn: string, metricId: string) =>
    selectedDimension?.values.map(
      ({ value }) =>
        ({
          [aggregationColumn]: value,
          [metricId]: NaN,
        }) as ViewData,
    );

  const aggregateData = (): ViewDataPerDate[] => {
    const metricsGroupedByDate = aggregationColumn
      ? mapValues(groupBy(data, xAxisDataKey), viewDataList =>
          chain(createDummyViewDataForEachDimension(aggregationColumn, chartProps.metrics[0].id))
            .keyBy(aggregationColumn)
            .merge(keyBy(viewDataList, aggregationColumn))
            .values()
            .value(),
        )
      : groupBy(data, xAxisDataKey);
    return Object.entries(metricsGroupedByDate).map(([date, viewData]) => ({ date, viewData }));
  };
  const aggregationColumn = selectedDimension?.name
    ? DIMENSION_AGGREGATION[selectedDimension.name].columnName
    : undefined;
  // In aggregateData() there is a bug in keyBy. It changes the order of items while iterating over the data
  // This only happens when custom starting date is selected, not when previous period is selected
  let aggregatedData = aggregateData();
  if (isAppliedCustomStartingDate) {
    aggregatedData = aggregatedData.map(item => ({
      ...item,
      viewData: [item.viewData[1], item.viewData[0]],
    }));
  }
  const hasSingleYAxis = variant === 'singleAxisLine';
  const lineIds = aggregationColumn
    ? selectedDimension!.values.map(dimensionValue => String(dimensionValue.value))
    : chartProps.metrics.map(metric => columns.find(column => column.key === metric.id)!.key);
  const initialLineVisibility = () => {
    const hiddenLineIds = chartProps.uuid ? getHiddenLinesFromLocaleStorage(chartProps.uuid) : [];
    return lineIds.reduce(
      (acc, lineId) => ({
        ...acc,
        [lineId as string]: hiddenLineIds?.includes(lineId) ?? false,
      }),
      {},
    );
  };
  const [isLineHidden, setHiddenLines] = useState<{ [key: string]: boolean }>(
    initialLineVisibility(),
  );
  const calculateWidth = (columnKey: string) => {
    const biggestValue = Math.round(
      Math.max(
        ...data.map(dataTmp => {
          const valueTmp = dataTmp[columnKey];
          return typeof valueTmp === 'number' ? valueTmp : 0;
        }),
      ) * 1.1,
    );

    const wrapper = columns.find(columnTmp => columnTmp.key === columnKey)!.wrapper;
    const value = formatNumber(biggestValue, wrapper);

    if (value.length > 8) {
      return 80;
    } else if (value.length <= 5) {
      return 40;
    } else {
      return 60;
    }
  };

  const getNonDummyAggregationColumnValue = (index: number) =>
    Object.entries(isLineHidden)
      .filter(([_, isHidden]) => !isHidden)
      .map(([dimensionValue, _]) => dimensionValue)[index];

  const getTimePeriodLineName = (
    index: number,
    dimensionInfo: DimensionValueLabels,
    date: Date,
  ): string =>
    index === 0
      ? getDateRangePeriodDateLabel(date)
      : getSelectedDimensionDateLabel(dimensionInfo, date);

  const getLineNameFromResult = (i: number, dateYMD: string) => {
    // Another weird behavior with indexes. This combination below works, but makes no sense
    // Especially makes no sense how dimensionInfo is selected from the data - and then has indexes switched with base period
    let index = i;
    if (isAppliedCustomStartingDate) {
      index = i === 0 ? 1 : 0;
    }
    const date = dateFromYmd(dateYMD);
    const dimensionInfo = selectedDimension?.values.find(
      ({ value }) => value == getNonDummyAggregationColumnValue(index), // Do not change to strict equality here or you introduce a bug with empty labels displaying
    ) || { label: '', value: '' };
    if (selectedDimension?.name === 'timePeriod') {
      return getTimePeriodLineName(i, dimensionInfo, date);
    }
    return aggregationColumn
      ? dimensionInfo.label
      : columns.find(column => chartProps.metrics[index].id === column.key)!.name;
  };

  const getDateRangePeriodDateLabel = (date?: Date) => {
    const dateRangePeriodFormatted = formatDateRange(dateRangePeriod, DATE_FORMAT_LONG);
    const dateFormatted = date ? formattedDate(date) : undefined;
    const daysBetween = Math.abs(
      getDifferenceBetweenDays(dateRangePeriod.from, dateRangePeriod.to),
    );
    if (dateRangePeriod.period !== DatePeriod.CUSTOM) {
      return `${translate(dateRangePeriod.period)} (${dateFormatted})`;
    }
    return `${daysBetween + 1} ${translate('common_days')} (${
      dateFormatted || dateRangePeriodFormatted
    })`;
  };

  const getSelectedDimensionDateLabel = (dimensionInfo: DimensionValueLabels, date?: Date) => {
    const { label, value } = dimensionInfo;
    const selectedPeriod = periodFromEn[label as keyof typeof periodFromEn];
    if (date) {
      if (isCustomStartingDate(label)) {
        return `${translate('pop_custom_starting_date_short')} (${formattedDate(
          shiftToCustomStartingDate(dateRangePeriod, String(value), date),
        )})`;
      }
      return `${label} (${formattedDate(shiftBackwards(date, selectedPeriod, dateRangePeriod))})`;
    }
    if (isCustomStartingDate(label)) {
      return `${label} (${formattedDate(dateFromYmd(String(value)))})`;
    }
    const comparedPeriodWithLabels = getComparePeriodWithLabels(dateRangePeriod, selectedPeriod);
    return `${label} (${comparedPeriodWithLabels.subLabel})`;
  };

  const getTimePeriodLineInfo = (index: number): { key: string; label: string } => {
    const dimensionInfo = selectedDimension?.values[index]!;
    // DateRangePeriod
    if (index === 0) {
      return {
        key: String(dimensionInfo.value),
        label: getDateRangePeriodDateLabel(),
      };
    }
    // selectedPeriod
    return {
      key: String(dimensionInfo.value),
      label: getSelectedDimensionDateLabel(dimensionInfo),
    };
  };

  const getLineInfoFromWidgetProps = (index: number): { key: string; label: string } => {
    if (aggregationColumn) {
      const dimensionInfo = selectedDimension?.values[index]!;
      if (selectedDimension?.name === 'timePeriod') {
        if (dateRangePeriod.period === DatePeriod.CUSTOM && index === 0) {
          return getTimePeriodLineInfo(index);
        }
        const value = String(dimensionInfo.value);
        const selectedPeriodDateRange = isCustomStartingDate(value)
          ? customStartingDateToDateRange(dateRangePeriod, value)
          : getCompareDateRange(dateRangePeriod, (periodFromEn as any)[dimensionInfo.label]) || {};
        const selectedPeriodLabel = isCustomStartingDate(value)
          ? translate('pop_custom_starting_date_short')
          : dimensionInfo.value;
        return {
          key: String(dimensionInfo.value),
          label: `${
            index === 0 ? translate(dateRangePeriod.period) : selectedPeriodLabel
          } (${format(
            index === 0 ? dateRangePeriod.from : selectedPeriodDateRange.from,
            DATE_FORMAT_LONG_COMA,
          )} - ${format(
            index === 0 ? dateRangePeriod.to : selectedPeriodDateRange.to,
            DATE_FORMAT_LONG_COMA,
          )})`,
        };
      }
      return {
        key: String(dimensionInfo.value),
        label: dimensionInfo.label,
      };
    }
    const metricColumn = columns.find(column => chartProps.metrics[index].id === column.key)!;
    return {
      key: metricColumn.key,
      label: metricColumn.name,
    };
  };

  const timelineProps = lineChartMetadata(columns, chartProps);

  useEffect(() => {
    const checkLegendWidth = () => {
      if (chartRef.current) {
        const legendContainer = chartRef.current.querySelector(
          '.recharts-legend-wrapper',
        ) as HTMLElement;
        const legendItemContainer = chartRef.current.querySelectorAll('.recharts-legend-item');

        if (legendContainer && legendItemContainer.length > 0) {
          const legendWidth = legendContainer.getBoundingClientRect().width;

          const itemsWidth = Array.from(legendItemContainer).reduce(
            (acc, item) => acc + item.getBoundingClientRect().width,
            0,
          );
          setShouldShortenText(legendWidth < itemsWidth);
        }
      }
    };
    checkLegendWidth();
    window.addEventListener('resize', checkLegendWidth);

    let shouldCheckLegendWidth = true;

    const observer = new MutationObserver(() => {
      if (shouldCheckLegendWidth) {
        shouldCheckLegendWidth = false;
        checkLegendWidth();
      }
    });

    const config = { childList: true, subtree: true };

    if (chartRef.current) {
      observer.observe(chartRef.current, config);
      checkLegendWidth();
    }
    return () => {
      window.removeEventListener('resize', checkLegendWidth);
      if (observer) {
        observer.disconnect();
      }
    };
  }, [chartRef]);
  const isThereData = data.length > 0;

  const renderColorfulLegendText = (_: string, entry: any, index: number) => (
    <TooltipWrapper
      message={getLineInfoFromWidgetProps(index).label}
      position={TooltipPosition.TOP_LEADING}
    >
      <span
        style={{
          color: entry.inactive || !isThereData ? Color.Grey500 : Color.Grey700,
        }}
        className={cs('item-text', { 'short-text': shouldShortenText })}
      >
        {getLineInfoFromWidgetProps(index).label}
      </span>
    </TooltipWrapper>
  );

  const toggleLineVisibility = (index: number) => {
    const { key: lineId } = getLineInfoFromWidgetProps(index);
    setHiddenLines({ ...isLineHidden, [lineId]: !isLineHidden[lineId] });
    if (chartProps.uuid) {
      const hiddenLineIds = getHiddenLinesFromLocaleStorage(chartProps.uuid) ?? [];
      if (hiddenLineIds.includes(lineId)) hiddenLineIds.splice(hiddenLineIds.indexOf(lineId), 1);
      else hiddenLineIds.push(lineId);
      saveHiddenLinesToLocaleStorage(hiddenLineIds, chartProps.uuid);
    }
  };
  const fixTooltipPosition = (isBringToFront: boolean) => {
    if (!chartRef.current) return;
    const gridItem = chartRef.current.closest('.react-grid-item');
    const gridItemSiblings = Array.from(gridItem?.parentElement?.children || []).filter(
      element => element !== gridItem,
    ) as HTMLElement[];
    gridItemSiblings.forEach(sibling =>
      isBringToFront ? (sibling.style.zIndex = '-1') : sibling.style.removeProperty('z-index'),
    );
  };

  return (
    <div
      ref={chartRef}
      style={{
        height: chartHeight ? chartHeight : '99%',
      }}
      className="u-display-grid"
      onMouseEnter={() => fixTooltipPosition(true)}
      onMouseLeave={() => fixTooltipPosition(false)}
    >
      <ResponsiveContainer
        width="99%"
        height={'99%'}
        minWidth={0}
        minHeight={0}
        className={cs('chart-builder--chart', { 'no-data': !isThereData })}
      >
        <LineChart data={aggregatedData} margin={{ left: 30, top: 16, right: 25, bottom: 16 }}>
          <CartesianGrid stroke={Color.Grey200} vertical={false} horizontal={isThereData} />
          {timelineProps.map((lineInfo, index) => {
            return (
              <Line
                type={lineType}
                dataKey={lineInfo.dataGetter}
                stroke={isThereData ? Object.values(ChartColor)[index] : Color.Grey500}
                dot={lineDot}
                key={lineInfo.name}
                yAxisId={variant === 'doubleAxisLine' && index === 1 ? 'right' : 'left'}
                hide={isLineHidden[getLineInfoFromWidgetProps(index).key]}
              />
            );
          })}
          <XAxis
            dataKey={xAxisDataKey}
            tickFormatter={tickFormatterX}
            axisLine={{ stroke: Color.Grey200 }}
            stroke={Color.Grey500}
            tickLine={tickLineX}
            tickMargin={0}
            label={{
              value: xAxisLabel,
              position: 'bottom',
              fill: Color.Grey700,
            }}
            tick={{ fill: Color.Grey700 }}
            hide={!isThereData}
          />

          {chartProps.metrics[0] && (
            <YAxis
              orientation="left"
              yAxisId="left"
              width={calculateWidth(chartProps.metrics[0].id)}
              tickFormatter={value =>
                formatNumber(
                  value,
                  columns.find(columnTmp => columnTmp.key === chartProps.metrics[0].id)!.wrapper,
                )
              }
              stroke={Color.Grey600}
              tickLine={tickLineY}
              tickMargin={8}
              label={{
                value: leftYAxisLabel,
                angle: -90,
                position: 'left',
                offset: 24,
                fill: Color.Grey500,
                style: { textAnchor: 'middle' },
              }}
              axisLine={false}
            />
          )}
          {variant === 'doubleAxisLine' && (
            <YAxis
              orientation="right"
              yAxisId="right"
              width={calculateWidth(chartProps.metrics[1].id)}
              tickFormatter={value =>
                formatNumber(
                  value,
                  columns.find(columnTmp => columnTmp.key === chartProps.metrics[1].id)!.wrapper,
                )
              }
              stroke={Color.Grey600}
              tickLine={tickLineY}
              tickMargin={8}
              label={{
                value: rightYAxisLabel,
                angle: -90,
                position: 'right',
                offset: 15,
                fill: Color.Grey500,
              }}
              axisLine={false}
            />
          )}
          <Tooltip
            content={({ label, payload }: { label: string; payload: any[] }) => {
              if (!payload) {
                return;
              }
              return (
                <WidgetTooltip
                  title={selectedDimension?.name !== 'timePeriod' ? toolTipTitle!(label) : ''}
                  metrics={payload.map((payloadTmp, index: number) => {
                    return {
                      name: getLineNameFromResult(index, payloadTmp.payload.date),
                      wrapper: columns.find(
                        column =>
                          (hasSingleYAxis ? chartProps.metrics[0] : chartProps.metrics[index])
                            .id === column.key,
                      )!.wrapper,
                      color: payloadTmp.stroke as Color,
                      value: payloadTmp.value as number,
                    };
                  })}
                />
              );
            }}
            offset={10}
          />

          <Legend
            height={30}
            verticalAlign="top"
            align="center"
            iconType="circle"
            onClick={(_, index) => isThereData && toggleLineVisibility(index)}
            formatter={renderColorfulLegendText}
            iconSize={8}
            wrapperStyle={{
              top: -7.5,
              width: '100%',
              display: 'inline-flex',
              alignItems: 'flex-start',
              flexDirection: 'row',
              justifyContent: 'center',
              left: 0,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
