import * as React from 'react';
import { Props } from './notification-drawer-header.types';
import { SettingsLineDarkIcon, CloseIcon } from 'tcp-react-icons';
import { translate } from '../../../../../helpers/utils';
import ApiNotificationsHelper from '../../../../../helpers/api/notifications/api-notifications-helper';
import { AppContext } from '../../../../context/app-context';
import { showNotification } from '../../../../../services/notification-service';
import { NotificationLevel } from '../../../../../constants';

export const NotificationDrawerHeader: React.FunctionComponent<Props> = ({
  isShowingMarkAllAsRead,
  onShowModal,
  onMarkAllAsRead,
  onClose,
}) => {
  const { partner } = React.useContext(AppContext);
  const markAllAsRead = () => {
    try {
      ApiNotificationsHelper.markAllAsRead(partner.partnerId);
      onMarkAllAsRead();
    } catch (e) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    }
  };

  return (
    <div className="u-display--flex u-padding-left--gutter u-padding-right--gutter u-margin-left--gutter--small u-margin-right--gutter--small u-margin-top--large u-margin-bottom--large ">
      <span className="c-notification-drawer__notifications-title u-font-weight--bold u-margin-right--gutter">
        {translate('notifications_title')}
      </span>
      {isShowingMarkAllAsRead && (
        <div className="c-notification-drawer__mark-all-read u-position--relative">
          <span
            className="u-color--blue u-cursor--pointer u-margin-right--medium"
            onClick={markAllAsRead}
          >
            {translate('notifications_mark_all_as_read')}
          </span>
        </div>
      )}
      <div className="c-notification-drawer__icons u-margin-left--auto">
        <SettingsLineDarkIcon
          className="u-position--relative u-margin-right--medium u-cursor--pointer"
          onClick={onShowModal}
        />
        <CloseIcon className="u-position--relative u-cursor--pointer" onClick={onClose} />
      </div>
    </div>
  );
};
