import { Color } from './color';
import { DashboardWidget, WidgetType, fromTo, DashBoard } from '../models';
import { TranslationKey } from '../helpers/utils';

export const DASHBOARD_WELCOME_BANNER = 'is_dashboard_welcome_banner_enabled';

export const DASHBOARD_MINIMUM_NAME_LENGTH = 2;
export const DASHBOARD_MAXIMUM_NAME_LENGTH = 255;

export const KEY_SELECTED_DASHBOARD = 'dashboard-selected';

export const TIME_LINE_COLOR_OPTIONS = [
  Color.Blue400,
  Color.Red600,
  Color.Green600,
  Color.Orange600,
];

export const WIDGET_TYPE_2_DEFAULT_WIDGET: fromTo<WidgetType, DashboardWidget> = {
  [WidgetType.TIMELINE]: {
    uuid: '',
    position: { x: -1, y: -1 }, // default values
    size: {
      width: 1,
      maxHeight: 1,
      height: 1,
    },
    isDraggable: true,
    isResizable: true,
    type: WidgetType.TIMELINE,
    props: {
      name: '',
      metrics: [
        { id: 'cost2', color: Color.Green300 },
        { id: 'top_pos_share2', color: Color.Blue400 },
      ],
    },
  },
  [WidgetType.POS_OVERVIEW]: {
    uuid: '',
    position: { x: -1, y: -1 }, // default values
    size: {
      width: 1,
      maxHeight: 1,
      height: 1,
    },
    isDraggable: true,
    isResizable: true,
    type: WidgetType.POS_OVERVIEW,
    props: {},
  },
  [WidgetType.YESTERDAY_OVERVIEW]: {
    uuid: '',
    position: { x: -1, y: -1 }, // default values
    size: {
      width: 1,
      maxHeight: 1,
      height: 1,
    },
    isDraggable: true,
    isResizable: true,
    type: WidgetType.YESTERDAY_OVERVIEW,
    props: {},
  },
  [WidgetType.GLOBAL_INVENTORY]: {
    uuid: '',
    position: { x: -1, y: -1 }, // default values
    size: {
      width: 1,
      maxHeight: 1,
      height: 1,
    },
    isDraggable: true,
    isResizable: true,
    type: WidgetType.GLOBAL_INVENTORY,
    props: {},
  },
  [WidgetType.MBLU]: {
    uuid: '',
    position: { x: -1, y: -1 }, // default values
    size: {
      width: 1,
      maxHeight: 1,
      height: 1,
    },
    isDraggable: true,
    isResizable: true,
    type: WidgetType.MBLU,
    props: {},
  },
  [WidgetType.UNKNOWN]: {
    uuid: '',
    position: { x: -1, y: -1 }, // default values
    size: {
      width: 1,
      maxHeight: 1,
      height: 1,
    },
    isDraggable: true,
    isResizable: true,
    type: WidgetType.UNKNOWN,
    props: {},
  },
  [WidgetType.CHART_BUILDER]: {
    uuid: '',
    position: { x: -1, y: -1 }, // default values
    size: {
      width: 1,
      maxHeight: 1,
      height: 1,
    },
    isDraggable: true,
    isResizable: true,
    type: WidgetType.CHART_BUILDER,
    props: {},
  },
};

export const dashboardValidationProps = (dashboards: DashBoard[]) => ({
  minimum: DASHBOARD_MINIMUM_NAME_LENGTH,
  maximum: DASHBOARD_MAXIMUM_NAME_LENGTH,
  cannotBeRepeatedWith: dashboards.map(({ name }) => name),
});

export const DASHBOARD_ERROR_VALIDATION_TO_TRANSLATION_KEY: fromTo<
  keyof ReturnType<typeof dashboardValidationProps>,
  TranslationKey
> = {
  minimum: 'dashboard_create_modal_input_error_out_of_scope',
  maximum: 'dashboard_create_modal_input_error_out_of_scope',
  cannotBeRepeatedWith: 'dashboard_create_modal_input_error_repeated_name',
};
