import { FC } from 'react';
import { GridSortButtonProps } from './grid-sort-button.types';
import styles from './grid-sort-button.module.css';
import cn from 'classnames';
import { SortDirection } from '../../models/grid-model';
import { ButtonIcon } from '../../../../core-ui/components/button-icon/button-icon';

export const GridSortButton: FC<GridSortButtonProps> = ({ onSort, column, sort, extraClasses }) => {
  const direction = sort?.direction ?? SortDirection.DESC;
  return (
    <ButtonIcon
      icon={{ name: 'directionRight', rotation: direction === SortDirection.DESC ? 90 : 270 }}
      onClick={onSort}
      className={cn(styles.sortButton, extraClasses, {
        [styles.active]: column.key === sort?.columnKey,
      })}
    />
  );
};
