import { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './horizontal-scroll.module.css';
import { ButtonIcon } from '../button-icon/button-icon';
import { IconProps } from '../icon';
import { clamp } from 'lodash';
import cn from 'classnames';

type HorizontalScrollProps = {
  children: ReactNode;
  className?: string;
};

export const HorizontalScroll: FC<HorizontalScrollProps> = ({ children, className }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showScroll, setShowScroll] = useState({ left: false, right: false });

  useLayoutEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    const observer = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        setShowScroll({
          left: element.scrollLeft > 0 && element.scrollLeft !== 0,
          right:
            element.scrollWidth > element.clientWidth &&
            element.scrollLeft < element.scrollWidth - element.clientWidth,
        });
      });
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [showScroll]);

  const handleScroll = (direction: 'left' | 'right', amount: number = 200) => {
    const container = containerRef.current;

    if (!container) return;

    const maxScrollLeft = container.scrollWidth - container.clientWidth;
    const currentScrollPosition = container.scrollLeft;

    const delta = direction === 'right' ? amount : -amount;
    // Ensure the new scroll position is within bounds
    const newScrollPosition = clamp(currentScrollPosition + delta, 0, maxScrollLeft);

    container.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth',
    });
  };

  const icon: IconProps = {
    name: 'arrowRight',
    className: styles.elevatedIcon,
  };

  return (
    <div className={cn(styles.horizontalScrollContainer, className)}>
      {showScroll.left && (
        <ButtonIcon
          onClick={() => handleScroll('left')}
          icon={{ ...icon, rotation: 180 }}
          isElevated
          aria-label="Scroll left"
        />
      )}

      <div
        className={cn(styles.content, {
          [styles.leftFade]: showScroll.left,
          [styles.rightFade]: showScroll.right,
        })}
        ref={containerRef}
      >
        {children}
      </div>

      {showScroll.right && (
        <ButtonIcon
          onClick={() => handleScroll('right')}
          icon={icon}
          isElevated
          aria-label="Scroll right"
          hidden={!showScroll}
        />
      )}
    </div>
  );
};
