import { HeaderValidationRule } from '../row-validation-rule';

type Options = {
  header: string;
  ignoreCase?: boolean;
};

export class RequiredHeaderRule implements HeaderValidationRule {
  constructor(private options: Options) {}

  validate(headers: string[]): string | null {
    const { ignoreCase, header } = this.options;
    const requiredHeader = ignoreCase ? header.toLowerCase() : header;
    const headersToCheck = ignoreCase ? headers.map(h => h.toLowerCase()) : headers;
    if (headersToCheck.includes(requiredHeader)) return null;
    return `Missing required header: ${header}`;
  }
}
