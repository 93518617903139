import { insertDecimal } from '../helpers/utils';
import {
  BidModifier,
  DateRange,
  FilterColumn,
  Hotel,
  VIEWS_BM_EDIT_BID_MODIFIER,
  PerformedBids,
  Bid,
  BiddingActionType,
} from '../models';
import { apiBiddingHelper } from '../helpers/api/bidding/api-bidding-helper';
import { BiddingResponse } from '../helpers/api/bidding/types';
import { RevertType } from '../constants';

export async function applyBids(
  hotels: Hotel[],
  partnerId: number,
  cpcInEuros: number,
  locale: string,
  actionType: BiddingActionType,
): Promise<BiddingResponse> {
  const cpsInCentsOfEuros = +insertDecimal(cpcInEuros * 100, 0);
  const bids: Bid[] = transformToBid(hotels, cpsInCentsOfEuros, locale);

  return apiBiddingHelper.applyBids(bids, partnerId, cpsInCentsOfEuros, actionType);
}

export async function applyBidModifiers(
  bidModifiers: BidModifier[],
  propertyGroups: number[],
  view: VIEWS_BM_EDIT_BID_MODIFIER,
  partnerId: number,
  localeCode: string,
  value: number,
  url: string,
): Promise<PerformedBids> {
  return apiBiddingHelper.applyBidModifiers(
    bidModifiers,
    propertyGroups,
    view,
    partnerId,
    localeCode,
    value,
    url,
  );
}

export function applyRevert(partnerId: number, type: RevertType) {
  return apiBiddingHelper.applyRevert(partnerId, type);
}

export function applyManualCpcToAll(
  cpcInEuros: number,
  partnerId: number,
  dateRange: DateRange,
  locales: readonly string[],
  filters: FilterColumn[],
  actionType: BiddingActionType,
) {
  const cpsInCentsOfEuros = +insertDecimal(cpcInEuros * 100, 0);
  return apiBiddingHelper.applyManualCpcToAllInventory(
    cpsInCentsOfEuros,
    {
      partnersId: [partnerId],
      dateRange,
      filters,
      locales,
    },
    actionType,
  );
}

/* Private methods */

export function transformToBid(hotels: Hotel[], cpc: number, localeCode: string): Bid[] {
  return hotels.map(({ partnerRef }) => {
    return {
      partnerReference: partnerRef,
      localeCode,
      cpc: cpc.toString(),
    };
  });
}
