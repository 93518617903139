import { useHistory, useLocation } from 'react-router-dom';

export function useQueryParam(key: string): [string | null, (value: string) => void] {
  const { search, pathname } = useLocation();
  const history = useHistory();

  function setQueryParam(value: string) {
    const params = new URLSearchParams(search);
    params.set(key, value);
    history.replace({
      pathname,
      search: params.toString(),
    });
  }

  const queryParams = new URLSearchParams(search);
  return [queryParams.get(key), setQueryParam];
}
