import { FC, useContext, useState } from 'react';
import { addDays, format } from 'date-fns';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import { AppContext } from '../../global/context/app-context';
import {
  Period,
  TranslationKey,
  formattedDate,
  getComparePeriodWithLabels,
  getDifferenceBetweenDays,
  translate,
} from '../../helpers/utils';
import { CheckboxSelect, CheckboxSelectOption } from '../checkbox-select';
import styles from './time-period-selector.module.css';
import { DatePeriod } from '../../models';
import { FullScreenDatePickerProps } from '../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';
import { DATE_FORMAT_STANDARD } from '../../constants';
import { SelectedRange } from './selected-range';

export type TimePeriodSelectorProps = {
  applyFilter: (selectedOption: CheckboxSelectOption<number>[]) => void;
  selectedTimePeriods: Period[];
  showDatePicker?: VoidFunction;
};

export const TimePeriodSelector: FC<TimePeriodSelectorProps & FullScreenDatePickerProps> = ({
  applyFilter,
  selectedTimePeriods,
  showDatePicker,
  showFullScreenDatePicker,
  customSelectedDate,
  clearSelectedDate,
}) => {
  const { dateRangePeriod } = useContext(AppContext);
  const [customStartingDate, setCustomStartingDate] = useState(customSelectedDate);

  const { from: baseFrom, to: baseTo, period } = dateRangePeriod;
  const timeRangeOptions = Object.keys(Period)
    .map(option =>
      getComparePeriodWithLabels(dateRangePeriod, Period[option as keyof typeof Period]),
    )
    .map(({ timeRangeName, from, to }) => ({
      id: timeRangeName as Period,
      label: translate(timeRangeName as TranslationKey),
      checked: selectedTimePeriods.includes(timeRangeName as Period),
      isRadio: true,
      value: 1,
      subLabel: `${formattedDate(from)} - ${formattedDate(to)}`,
      isDisabled: !!customStartingDate,
    }));
  const daysBetween = Math.abs(getDifferenceBetweenDays(baseFrom, baseTo));

  const CustomHeader = () => (
    <SelectedRange
      label={
        <p>
          {period === DatePeriod.CUSTOM
            ? `${daysBetween + 1} ${translate('common_days')}`
            : translate(period as TranslationKey)}
        </p>
      }
      details={`${formattedDate(baseFrom as Date)} - ${formattedDate(baseTo as Date)}`}
      actionLabel={<div className={styles.customHeaderChange}>Change</div>}
      onClick={showDatePicker}
      onActionClick={showDatePicker}
    />
  );

  const CustomOptions = () => {
    if (customStartingDate) {
      return (
        <SelectedRange
          label={<p>{translate('pop_custom_starting_date')}</p>}
          details={`${formattedDate(customStartingDate)} - ${formattedDate(addDays(customStartingDate, daysBetween))}`}
          actionLabel={<CloseIcon />}
          onClick={showFullScreenDatePicker}
          onActionClick={() => {
            setCustomStartingDate(undefined);
            clearSelectedDate();
          }}
        />
      );
    }
    return (
      <div className={styles.customOptions} onClick={showFullScreenDatePicker}>
        <p>{translate('pop_custom_starting_date')}</p>
      </div>
    );
  };

  return (
    <div className="u-background--white">
      <CheckboxSelect
        // Sub-labels are the only dynamic properties of the options
        key={timeRangeOptions.map(option => option.subLabel).join()}
        title="Time Period"
        options={timeRangeOptions as CheckboxSelectOption<number>[]}
        onApply={options => {
          const selectedOption: CheckboxSelectOption<number> = options.filter(
            option => option.checked,
          )[0];
          const customSelectedOption: CheckboxSelectOption<number> = {
            id: customStartingDate ? format(customStartingDate, DATE_FORMAT_STANDARD) : 0,
            label: customStartingDate ? translate('pop_custom_starting_date') : '',
            checked: true,
            value: 0,
          };
          applyFilter(customStartingDate ? [customSelectedOption] : [selectedOption]);
        }}
        customHeader={
          <>
            <h4 className={styles.timePeriodTitle}>Time Period</h4>
            <CustomHeader />
            <h5 className={styles.compareToTitle}>Compare to</h5>
          </>
        }
        customOptions={<CustomOptions />}
        allowEmptySelection={!!customStartingDate}
        customSelectedDate={customStartingDate}
      />
    </div>
  );
};
