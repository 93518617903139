import React from 'react';
import './simple-snackbar.css';
import cs from 'classnames';
import { useSnackbar } from '../../global/app/snackbar/use-snackbar';
import { SnackbarMessage } from '../../global/app/snackbar/snackbar-message';
import { CloseIcon } from 'tcp-react-icons';
import { useTimeout } from '../../hooks/use-timeout';

export const SimpleSnackbar: React.FunctionComponent<SnackbarMessage> = ({ id, message, type }) => {
  const { remove } = useSnackbar();
  useTimeout(() => remove(id!), 3000);

  return (
    <div className={cs('simple-snackbar', `simple-snackbar--${type}`)}>
      <p title={message}>{message}</p>
      <CloseIcon style={{ cursor: 'pointer', minWidth: 24 }} onClick={() => remove(id!)} />
    </div>
  );
};
