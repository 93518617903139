import { useCurrentView } from '../use-current-view/use-current-view';

export const useDefaultFiltersByView = () => {
  const currentView = useCurrentView();

  const defaultFilters: { [key: string]: string[] } = {
    dashboard: ['Country', 'Avg. CPC', 'Clicks', 'Bookings', 'Top Pos Share', 'Outbid Ratio'],
    hotel: ['Property group', 'Country', 'Bid CPC', 'Clicks', 'Bookings', 'Top Pos Share'],
    others: ['Country', 'Clicks', 'Bookings', 'Top Pos Share', 'Outbid Ratio', 'Hotel Impr'],
    sponsored_hotel: ['Country', 'Clicks', 'Bookings', 'Bid CPC'],
    sponsored_pos: ['Clicks', 'Bookings', 'Avg.CPC'],
    sponsored_time: ['Clicks', 'Bookings', 'Avg.CPC'],
  };

  const strCurrentView = String(currentView).toLowerCase();

  if (Object.keys(defaultFilters).includes(strCurrentView) && currentView)
    return defaultFilters[strCurrentView];

  return defaultFilters.others;
};
