import { DateRange } from '../../models';

export type Props = {
  dateRange: DateRange;
  onUpdateDateRange: (dateRange: DateRange) => void;
  alignment: DatePickerAlignment;
};

export enum DatePickerAlignment {
  LEFT,
  RIGHT,
}
