import { FC, HTMLAttributes, MouseEvent } from 'react';
import styles from './tag.module.css';
import cn from 'classnames';
import { Icon, IconProps } from '../icon';

type BaseProps = {
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: (event?: MouseEvent) => void;
  qaId?: string;
  className?: string;
} & HTMLAttributes<HTMLButtonElement>;

type IconTagProps = BaseProps & {
  icon: Pick<IconProps, 'name' | 'rotation'>;
  onDelete?: never;
};

type DeletableTagProps = BaseProps & {
  icon?: never;
  onDelete?: () => void;
};
export type TagProps = IconTagProps | DeletableTagProps;

export const Tag: FC<TagProps> = ({
  children,
  isSelected = false,
  isDisabled = false,
  icon = null,
  onDelete,
  className,
  ...props
}) => (
  <button
    className={cn(styles.tag, className, {
      [styles.selected]: isSelected,
      [styles.disabled]: isDisabled,
    })}
    {...props}
  >
    <span>{children}</span>
    {onDelete && (
      <Icon
        name="close"
        onClick={event => {
          event.stopPropagation();
          onDelete();
        }}
        className={styles.closeIcon}
      />
    )}
    {icon && <Icon name={icon.name} rotation={icon.rotation} aria-label={icon.name} />}
  </button>
);
