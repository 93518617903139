import * as React from 'react';
import { Aggregation, DateRange, fromTo } from '../../../models';
import { TimeOption } from '../../../constants';
import {
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfQuarter,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfQuarter,
} from 'date-fns';

export type Props = {
  title: React.ReactNode;
  legend?: React.ReactNode[];
  menuItems: React.ReactNode[];
  isLoading?: boolean;
  onSelectMenuItem: (index: number) => void;
  defaultStatus?: React.ReactNode;
  className?: string;
  showMenu?: boolean;
  partialFiltersAppliedMessage?: string;
};

export const CHART_DATA_AGGREGATION_TIME_OPTION: fromTo<
  TimeOption,
  | Aggregation.BY_TIMELINE_DAY
  | Aggregation.BY_TIMELINE_WEEK
  | Aggregation.BY_TIMELINE_MONTH
  | Aggregation.BY_TIMELINE_QUARTER
> = {
  [TimeOption.DAY]: Aggregation.BY_TIMELINE_DAY,
  [TimeOption.WEEK]: Aggregation.BY_TIMELINE_WEEK,
  [TimeOption.MONTH]: Aggregation.BY_TIMELINE_MONTH,
  [TimeOption.QUARTER]: Aggregation.BY_TIMELINE_QUARTER,
};

export const CHART_DATA_DATE_COLUMN_TIME_OPTION: fromTo<TimeOption, string> = {
  [TimeOption.DAY]: 'date2',
  [TimeOption.WEEK]: 'start_date_week',
  [TimeOption.MONTH]: 'start_date_month',
  [TimeOption.QUARTER]: 'start_date_quarter',
};

export const PERIOD_FORMATTER_TIME_OPTION: fromTo<TimeOption, (date: string) => DateRange> = {
  [TimeOption.DAY]: date => ({
    from: startOfDay(date),
    to: endOfDay(date),
  }),
  [TimeOption.WEEK]: date => ({
    from: startOfISOWeek(date),
    to: endOfISOWeek(date),
  }),
  [TimeOption.MONTH]: date => ({
    from: startOfMonth(date),
    to: endOfMonth(date),
  }),
  [TimeOption.QUARTER]: date => ({
    from: startOfQuarter(date),
    to: endOfQuarter(date),
  }),
};
