import { EventType } from '../../../models';

type EventTypeTab = {
  label: string;
  jobTypes: EventType[];
  tooltip?: string;
};

const CPA_JOB_TYPES: EventType[] = [
  EventType.FILE_CAMPAIGN_ALLOCATION,
  EventType.PUSH_CAMPAIGN_ALLOCATIONS,
  EventType.REVERT_PENDING_CAMPAIGN_ALLOCATION,
  EventType.CONTENT_HUB_CPA_ALLOC_IMPORT,
  EventType.CONTENT_HUB_CPA_ALLOC_IMPORT_MANUAL,
  EventType.EDIT_CPA_VALUE_UI,
  EventType.FILE_CPA_VALUE,
  EventType.CONTENT_HUB_CPA_VALUE_IMPORT_MANUAL,
  EventType.CONTENT_HUB_CPA_VALUE_IMPORT,
  EventType.PUSH_CPA_VALUE,
  EventType.REVERT_PENDING_CPA_VALUE,
  EventType.FILE_CPA_BM,
  EventType.PUSH_CPA_BM,
  EventType.CONTENT_HUB_CPA_BM_IMPORT_MANUAL,
  EventType.CONTENT_HUB_CPA_BM_IMPORT_SCHEDULED,
  EventType.CPA_BM_UI_EDIT,
];

export const EVENT_TYPE_TABS: EventTypeTab[] = [
  {
    label: 'CPC',
    jobTypes: [
      EventType.EDIT_CPC_UI,
      EventType.FILE_BIDDING,
      EventType.EDIT_BM_UI,
      EventType.PUSH_BM,
      EventType.FILE_BIDDING_MODIFIERS,
      EventType.CONTENT_HUB_BID_MODIFIER_IMPORT,
      EventType.CONTENT_HUB_BID_MODIFIER_IMPORT_MANUAL,
      EventType.PUSH,
      EventType.REVERT_BM,
      EventType.REVERT_PENDING_CHANGES,
      EventType.TYPE_CONTENT_HUB_TASK,
      EventType.TYPE_CONTENT_HUB_TASK_MANUAL,
      EventType.PUSH_PROPERTY_GROUPS,
      EventType.FILE_PROPERTY_GROUP_ID,
      EventType.CONTENT_HUB_PROPERTY_GROUP_IMPORT,
      EventType.REVERT_PROPERTY_GROUPS,
      EventType.CONTENT_HUB_PROPERTY_GROUP_IMPORT_MANUAL,
    ],
  },
  {
    label: 'CPA',
    jobTypes: CPA_JOB_TYPES,
  },
  {
    label: 'Sponsored Listing',
    jobTypes: [
      EventType.EDIT_SL_BID_UI,
      EventType.FILE_SL_BID,
      EventType.FILE_SL_BM,
      EventType.FILE_SL_BUDGET_CAP,
      EventType.PUSH_SL_BID,
      EventType.PUSH_SL_BM,
      EventType.PUSH_SL_BUDGET_CAP,
      EventType.REVERT_SL_BIDS,
      EventType.REVERT_SL_BUDGET_CAP,
      EventType.REVERT_SL_MODIFIERS,
      EventType.CONTENT_HUB_SL_BM_IMPORT,
      EventType.CONTENT_HUB_SL_IMPORT,
      EventType.CONTENT_HUB_SL_BM_IMPORT_MANUAL,
      EventType.CONTENT_HUB_SL_IMPORT_MANUAL,
      EventType.CONTENT_HUB_SL_BUDGET_CAP,
      EventType.CONTENT_HUB_SL_BUDGET_CAP_MANUAL,
    ],
  },
  {
    label: 'Other events',
    tooltip: 'history_other_events_tooltip',
    jobTypes: [
      EventType.INVENTORY_IMPORT,
      EventType.INVENTORY_IMPORT_MANUAL,
      EventType.COMBINED_BIDDING,
      EventType.INVENTORY_IMPORT_TI,
    ],
  },
];
