import React, { FC } from 'react';
import { ResultsNotFoundProps } from './results-not-found.types';
import styles from './results-not-found.module.css';
import { TeapotAnimation } from '../teapot-animation';
import { translate, translateHtml } from '../../helpers/utils';
import cn from 'classnames';
import { Button } from '../../core-ui/components/button/button';

export const ResultsNotFound: FC<ResultsNotFoundProps> = props => {
  const { onClick, isButtonEnabled = false, classNames, message, retry = null } = props;
  return (
    <div className={cn(styles.noResultsContainer, classNames)}>
      <TeapotAnimation message={translateHtml(message ?? 'common_no_data_result_message')} />
      {isButtonEnabled && (
        <Button
          variant="tertiary"
          onClick={onClick}
          text={translate('common_clear_filters_message')}
        />
      )}
      {retry && <Button variant="tertiary" text={translate('common_retry')} onClick={retry} />}
    </div>
  );
};
