import * as React from 'react';
import { Props } from './confirmation-modal.types';
import { ScrollableModal } from '../scrollable-modal';

export const ConfirmationModal: React.FunctionComponent<Props> = ({
  title,
  details,
  onClose,
  primaryButton,
  secondaryButton,
}) => {
  return (
    <ScrollableModal
      title={title}
      onClose={onClose}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      footer={{
        isTopBorderHidden: true,
      }}
    >
      <div className="u-padding-w--large">
        {details.map((detailTmp: string, index: number) => (
          <p key={index} className="u-padding-bottom--gutter u-font-size--t-small">
            {detailTmp}
          </p>
        ))}
      </div>
    </ScrollableModal>
  );
};
