import { useQuery } from '@tanstack/react-query';
import ApiAdminHelper from '../../../helpers/api/admin/api-admin-helper';
import { MemberAdministration } from '../../../models';

export type UseMembersOptions = {
  includeTrivagoEmployees: boolean;
  partnerId?: number;
  email?: string;
};

export const useMembers = (options: UseMembersOptions) =>
  useQuery<MemberAdministration[], Error>({
    queryKey: ['members', options],
    queryFn: () => getMembers(options),
  });

async function getMembers(options: UseMembersOptions) {
  const members = await ApiAdminHelper.getMembers(options);
  if (members.length === 0 && isValidEmail(options.email)) {
    const member = await getExternalMember(options.email);
    if (member) members.push(member);
  }
  return members;
}

const isValidEmail = (email: string | undefined): email is string => !!email && email.includes('@');

async function getExternalMember(email: string): Promise<MemberAdministration | null> {
  try {
    return await ApiAdminHelper.searchMemberByEmail(email);
  } catch (error) {
    console.error(error);
    return null;
  }
}
