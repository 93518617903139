import { FC } from 'react';
import { FullscreenModal } from '../../../components/fullscreen-modal';
import { DashBoard } from '../../../models';
import { SelectDropdown } from '../../../core-ui/select-dropdown';
import { Button } from '../../../core-ui/components/button/button';

type SaveAsModalProps = {
  isSavingNewChart: boolean;
  saveAsOptions: {
    name: string;
    dashboard: DashBoard | undefined;
  };
  allDashboards: DashBoard[];
  setSaveAsOptions: (options: any) => void;
  restorePreviousChartName: () => void;
  saveAsWidget: () => void;
  translate: (key: string) => string;
};

export const SaveAsModal: FC<SaveAsModalProps> = ({
  isSavingNewChart,
  saveAsOptions,
  allDashboards,
  setSaveAsOptions,
  restorePreviousChartName,
  saveAsWidget,
  translate,
}) => {
  if (!isSavingNewChart) return null;

  return (
    <FullscreenModal
      title={translate('analytics_chart_builder_unsaved_changes_modal_save_as')}
      onClose={restorePreviousChartName}
    >
      <div>
        <label>{translate('analytics_chart_builder_save_as_modal_name')}</label>
        <input
          type="text"
          className="c-save-as-name-input"
          style={{ height: '36px' }}
          value={saveAsOptions.name}
          onChange={e => {
            const value = e.target?.value;
            setSaveAsOptions((prevState: any) => ({ ...prevState, name: value }));
          }}
        />
      </div>
      <div>
        <label>{translate('analytics_chart_builder_save_as_modal_board')}</label>
        <SelectDropdown
          defaultValue={{
            value: saveAsOptions.dashboard?.id ?? '',
            label: saveAsOptions.dashboard?.name ?? '',
          }}
          onChange={selected =>
            setSaveAsOptions((prevState: any) => ({
              ...prevState,
              dashboard: allDashboards.find(dashboard => dashboard.id === selected?.value),
            }))
          }
          options={allDashboards.map(dashboard => ({ value: dashboard.id, label: dashboard.name }))}
        />
      </div>
      <div className="u-display--flex u-justify-content--flex-end u-margin-top--large">
        <Button
          variant="tertiary"
          text={translate('analytics_chart_builder_save_as_modal_cancel')}
          onClick={restorePreviousChartName}
        />
        <Button
          text={translate('analytics_chart_builder_unsaved_changes_modal_save')}
          onClick={saveAsWidget}
          className="u-margin-left--small"
          disabled={!saveAsOptions.name.trim()}
        />
      </div>
    </FullscreenModal>
  );
};
