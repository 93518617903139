import {
  DashBoard,
  DashBoardType,
  DashboardWidget,
  DashboardWidgetProps,
  TimelineWidget,
  TimelineWidgetProps,
  WidgetPosition,
  WidgetType,
} from '../models';
import {
  KEY_SELECTED_DASHBOARD,
  TIME_LINE_COLOR_OPTIONS,
  WIDGET_TYPE_2_DEFAULT_WIDGET,
} from '../constants';
import cloneDeep from 'lodash.clonedeep';
import { v4 } from 'uuid';
import { isEqual } from 'lodash';
import ApiDashboardHelper from '../helpers/api/dashboard/api-dashboard-helper';
import { getItem, saveItemToLocalStorage } from '../helpers/local-storage-helper';

export const MAX_NUMBER_COLUMNS = 3;

export const getDashboards = async (partnerId: number): Promise<DashBoard[]> => {
  const dashboards: DashBoard[] = await ApiDashboardHelper.getAll(partnerId);

  const selectedDashboardId = getSelectedDashboardId();
  const doesSelectedDashboardExists =
    dashboards.find(({ id }) => id === selectedDashboardId) !== undefined;
  if (selectedDashboardId === undefined || !doesSelectedDashboardExists) {
    selectDashboard(dashboards[0].id);
  }

  return dashboards;
};

export const createDashboard = async (
  partnerId: number,
  name: string,
  type: DashBoardType,
): Promise<number> => {
  const response = await ApiDashboardHelper.save(partnerId, name, type);

  selectDashboard(response.id);

  return response.id;
};

export const saveDashboard = async (
  partnerId: number,
  dashboard: DashBoard,
  newWidgets: DashboardWidget[],
  checkIsEqual: boolean = true,
): Promise<DashBoard> => {
  const currentWidgets = dashboard.widgets;

  if (isEqual(newWidgets, currentWidgets) && checkIsEqual) {
    return dashboard;
  }

  const dashboardTmp = { ...dashboard };
  dashboardTmp.widgets = newWidgets;

  const { id } = await ApiDashboardHelper.edit(partnerId, dashboardTmp);
  dashboardTmp.id = id;

  return dashboardTmp;
};

export const addNewWidget = (
  widgets: DashboardWidget[],
  widgetType: WidgetType,
  widgetProps?: DashboardWidgetProps,
): DashboardWidget[] => {
  let newWidget: DashboardWidget = cloneDeep(WIDGET_TYPE_2_DEFAULT_WIDGET[widgetType]);
  if (newWidget.type === WidgetType.TIMELINE) {
    newWidget = randomizeColors(newWidget as TimelineWidget);
  } else if (newWidget.type === WidgetType.CHART_BUILDER) {
    (newWidget as TimelineWidget).props = widgetProps as TimelineWidgetProps;
  }
  const coordinates: WidgetPosition = calculateNewWidgetPosition(widgets);
  newWidget.uuid = v4();
  newWidget.position = coordinates;

  return widgets.concat([newWidget]);
};

export const selectDashboard = (dashboardId: number) =>
  saveItemToLocalStorage(KEY_SELECTED_DASHBOARD, dashboardId);

export const getSelectedDashboardId = (): number | undefined => getItem(KEY_SELECTED_DASHBOARD);

export const calculateNewWidgetPosition = (widgets: DashboardWidget[]): WidgetPosition => {
  const widths = widgets.reduce((ac, { size: { width } }) => ac + width, 0);
  // Theorem of Division ==>  D = d * c + r
  const x = widths % MAX_NUMBER_COLUMNS;
  const y = (widths - x) / MAX_NUMBER_COLUMNS;

  return { x, y };
};

const randomizeColors = (widget: TimelineWidget): TimelineWidget => {
  const possibleColours = [...TIME_LINE_COLOR_OPTIONS];
  const getRandomValue = (maxValue: number) => Math.floor(Math.random() * maxValue);

  widget.props.metrics.forEach(metricTmp => {
    const value = getRandomValue(possibleColours.length);
    metricTmp.color = possibleColours[value];
    possibleColours.splice(value, 1);
  });

  return widget;
};
