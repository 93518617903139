export const currency = (
  value: number,
  digits: number = 2,
  locale: string = 'en',
  defaultValue: string | number = '-',
) => {
  if (isNaN(value)) {
    switch (typeof defaultValue) {
      case 'string':
        return defaultValue;
      case 'number':
        value = defaultValue;
        break;
    }
  }

  return value.toLocaleString(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

/***
 * Convert percentage value expressed as integer into percentage format
 * @param value: integer representation of percentage
 */
export const percentage = (value: number, defaultValue: string | number = '-') => {
  if (isNaN(value)) {
    switch (typeof defaultValue) {
      case 'string':
        return defaultValue;
      case 'number':
        value = defaultValue;
        break;
    }
  }

  const percentageValue = value / 100;

  return percentageValue.toLocaleString('en', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const number2percentage = (value: number, defaultValue: string | number = '-') => {
  if (isNaN(value)) {
    switch (typeof defaultValue) {
      case 'string':
        return defaultValue;
      case 'number':
        value = defaultValue;
        break;
    }
  }

  return value.toLocaleString('en', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatNumber = (value: any, wrapper: string) => {
  if (value === undefined) {
    return '';
  }
  if (value === '') {
    return '-';
  }

  switch (wrapper) {
    case '€':
      return value.toLocaleString('de', { style: 'currency', currency: 'EUR' });
    case '%':
      return value.toLocaleString('de', {
        style: 'percent',
        maximumFractionDigits: 2,
      });
    default:
      if (!Number.isNaN(value)) {
        return value.toLocaleString('de');
      }
      return value || '';
  }
};

export const roundPercentage = (value: number) => Math.round(value * 10000) / 100;
