import React, { FC, useCallback, useEffect, useState } from 'react';
import { Props } from './hotel-control-panel.types';
import './hotel-control-panel.css';
import { insertDecimal, translate } from '../../helpers/utils';
import { TooltipPosition, TooltipWrapper } from '../tooltip-wrapper';
import { WrapperErrorHint } from '../wrapper-error-hint';
import NumberFormat from 'react-number-format';
import cn from 'classnames';
import iconLockLineDark from './icn-lock-line-dark.svg';
import { VerticalDivider } from '../vertical-divider';
import {
  MANUAL_APPLY_ALL_PROPERTIES,
  MANUAL_APPLY_EXCEED_LIMIT_CPC,
  MANUAL_APPLY_MULTIPLE_PROPERTIES,
  MANUAL_APPLY_SINGLE_PROPERTIES,
  MANUAL_APPLY_SUCCESSFULLY_UPDATED,
  NotificationLevel,
  Color,
} from '../../constants';
import iconArrow from './icn-arrow.svg';
import iconClose from './icn-close.svg';
import {
  areMultipleBrandsSelected,
  areMultiplePOSorGlobalSelected,
} from '../../services/app-service';
import { BaseNotification, BiddingActionType, EventType } from '../../models';
import { BiddingResponse } from '../../helpers/api/bidding/types';
import TrackerService from '../../services/tracker/tracker-service';
import { applyBids, applyManualCpcToAll } from '../../services/bidding-service';
import { useSelectedPartner, useSelectedSubPartners } from '../../hooks/use-selected-partner';
import { useRemainingPushes } from '../../views/history/history-toolbar/use-remaining-pushes';
import { useSelectedLocales } from '../../hooks/use-selected-locales/use-selected-locales';
import { useMaxCPCBidCap } from '../../hooks/use-max-CPC-cap';
import { Loading } from '../loading';

export const HotelControlPanel: React.FunctionComponent<Props> = ({
  hotels,
  areAllRowsSelected,
  totalProperties,
  filters,
  dateRange,
  onUpdateTable,
  onShowNotification,
  onClickLeading,
  isShowingDetail,
  trackingPrefix,
  biddingActionType,
  maxCPCBidCap,
}) => {
  const [cpc, setCpc] = useState('');
  const [inputRef, setInputRef] = useState<HTMLInputElement>();
  const [isApplying, setIsApplying] = useState(false);
  const selectedLocales = useSelectedLocales();
  const partner = useSelectedPartner();
  const subPartners = useSelectedSubPartners();

  const partnerId = partner.partnerId;

  const clickBidInput = useCallback(() => {
    if (inputRef) {
      inputRef.select();
    }
  }, [inputRef]);

  useEffect(() => {
    clickBidInput();
    setCpc(hotels.length === 1 && !isNaN(hotels[0].bidCPC) ? String(hotels[0].bidCPC) : '');
  }, [clickBidInput, hotels, inputRef]);

  const resolveBidValueName = () => {
    switch (biddingActionType) {
      case BiddingActionType.CustomEditCpc:
        return translate('analytics_control_panel_current_bid_cpc');
      case BiddingActionType.SlBid:
        return translate('sl_analytics_control_panel_current_sl_bid_cpc');
      default:
        return 'value';
    }
  };

  const resolvePushType = () => {
    switch (biddingActionType) {
      case BiddingActionType.CustomEditCpc:
        return EventType.PUSH;
      case BiddingActionType.SlBid:
        return EventType.PUSH_SL_BID;
      default:
        throw new Error('Unsupported');
    }
  };

  const nameSelection = () => {
    return (
      <div>
        {((hotels.length === 1 && isShowingDetail) || hotels.length > 1) && (
          <img
            onClick={onClickLeading}
            src={isShowingDetail ? iconArrow : iconClose}
            className="c-panel-control__icn c-panel-control__icn-leading u-cursor--pointer u-margin-right--medium"
            alt="Icon showing navigation button to go back to hotel view"
          />
        )}
        {hotels.length === 1 ? (
          <span className="u-font-weight-bold">{hotels[0].name}</span>
        ) : areAllRowsSelected || hotels.length > 1 ? (
          <span className="u-font-weight-bold">
            {areAllRowsSelected ? totalProperties : hotels.length}{' '}
            {translate('analytics_control_panel_properties_selected')}
          </span>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const { data } = useRemainingPushes(partnerId);
  const remainingPushes = data || {};
  const dailyLimitReached = remainingPushes[resolvePushType()] === 0;

  const isBlocked = () =>
    dailyLimitReached ||
    areMultipleBrandsSelected(subPartners) ||
    areMultiplePOSorGlobalSelected(selectedLocales) ||
    maxCPCBidCap === undefined;

  const atLeastOneNotEqual = (value: number) =>
    hotels.some(someHotel => someHotel.bidCPC !== value);

  const showCurrentCpc = () => {
    let message: string;

    if (!atLeastOneNotEqual(hotels[0].bidCPC)) {
      message = `${resolveBidValueName()}: € ${hotels[0].bidCPC}`;
    } else if (hotels.length > 1) {
      message = `${resolveBidValueName()}: ${translate('analytics_control_panel_multiple')}`;
    } else {
      message = `${resolveBidValueName()}${translate(
        'analytics_control_panel_current_bid_cpc_na',
      )}`;
    }

    return (
      <div className="c-panel-control__selection-information u-margin-left--gutter u-margin-right--gutter">
        {!isBlocked() && message !== '' && message}
      </div>
    );
  };

  const isCpcOverLimit = (): boolean => +cpc > maxCPCBidCap!;

  const inputPlaceHolder =
    hotels.length > 1 ? translate('analytics_control_panel_multiple') : '€ 0.00';

  const canApplyBids = () => {
    return cpc !== '' && !isCpcOverLimit() && !isApplying && atLeastOneNotEqual(+cpc);
  };

  const changeCpcAndValidate = () => {
    setCpc(cpc !== '' ? `${insertDecimal(+cpc, 2)}` : '');
    setIsApplying(true);
  };

  const track = (eventName: string) => {
    return TrackerService.track(`${trackingPrefix}: ${eventName}`);
  };

  const sendTracker = () => {
    if (areAllRowsSelected) {
      track(MANUAL_APPLY_ALL_PROPERTIES);
      return;
    }

    // Bidding in just a single property
    if (hotels.length === 1) {
      track(MANUAL_APPLY_SINGLE_PROPERTIES);
    } else {
      track(MANUAL_APPLY_MULTIPLE_PROPERTIES);
    }
  };

  const applyCpc = async () => {
    const notification: BaseNotification = {
      level: NotificationLevel.ERROR,
      message: 'analytics_perform_bids_error',
    };

    if (cpc !== '') {
      changeCpcAndValidate();
      let biddingResponse: BiddingResponse = { updatedBids: 0, totalBids: 0 };
      sendTracker();
      if (!isCpcOverLimit()) {
        try {
          if (!areAllRowsSelected) {
            biddingResponse = await applyBids(
              hotels,
              partnerId,
              +cpc,
              selectedLocales[0],
              biddingActionType,
            );
          } else {
            if (dateRange && filters !== undefined) {
              biddingResponse = await applyManualCpcToAll(
                +cpc,
                partnerId,
                dateRange,
                selectedLocales,
                filters,
                biddingActionType,
              );
            }
          }
          if (biddingResponse.updatedBids === 0) {
            notification.level = NotificationLevel.ERROR;
            notification.message = `${translate('common_fail')} ${translate(
              'analytics_perform_bids_error',
            )}`;
          } else if (biddingResponse.updatedBids === biddingResponse.totalBids) {
            notification.level = NotificationLevel.SUCCESS;
            const keyProperty =
              biddingResponse.totalBids === 1 ? 'common_property' : 'common_properties';
            notification.message = `${translate('common_success')} ${translate(
              'analytics_perform_bids_success',
            )} ${biddingResponse.totalBids} ${translate(keyProperty)}. ${translate(
              'analytics_perform_bids_success_extra_info',
            )}`;
            track(MANUAL_APPLY_SUCCESSFULLY_UPDATED);
          } else {
            notification.level = NotificationLevel.WARNING;
            notification.message = `${translate('notification_partial_success')} ${translate(
              'analytics_perform_bids_partial_success',
            )} ${biddingResponse.updatedBids} ${translate('common_of_the')} ${
              biddingResponse.totalBids
            } ${translate('common_selected_properties')} ${translate('analytics_check_status')}`;
          }
          onUpdateTable();
        } catch {
          notification.message = `${translate('common_fail')} ${translate(
            'analytics_perform_bids_error',
          )}`;
          notification.level = NotificationLevel.ERROR;
        } finally {
          onShowNotification(notification);
          setIsApplying(false);
        }
      } else {
        track(MANUAL_APPLY_EXCEED_LIMIT_CPC);
      }
    }
  };

  const keyPressEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && canApplyBids()) {
      applyCpc();
    }
  };

  //TODO change so that would react to several selected bidding types
  const isCPA = hotels.some(hotelTmp => hotelTmp.biddingType.toLowerCase().indexOf('cpa') !== -1);

  const cpcLimitErrorText = translate(
    'analytics_control_panel_error_limit_cpc',
    resolveBidValueName(),
    (Math.round(maxCPCBidCap! * 100) / 100).toFixed(2),
  );

  const blockedMessage = () => {
    if (dailyLimitReached) {
      return translate('analytics_control_panel_locked_limit_reached', resolveBidValueName());
    }

    if (areMultipleBrandsSelected(subPartners) || areMultiplePOSorGlobalSelected(selectedLocales)) {
      return translate(
        'analytics_control_panel_locked_multiple_locales_or_brands',
        resolveBidValueName(),
      );
    }

    if (maxCPCBidCap === undefined) return translate('max_cpc_bids_cap_error');

    return translate('analytics_control_panel_locked');
  };

  return (
    <>
      <div
        className={cn(
          'u-display--flex c-panel-control u-flex-direction--row  u-padding-h--small c-panel-control__general',
          {
            'u-margin-top--gutter': !isShowingDetail,
          },
        )}
      >
        <div className="u-display--flex c-panel-control__selection-information u-margin-left--gutter u-align-items--center">
          {nameSelection()}
          <div className="u-display--flex u-flex-direction--column">
            {isBlocked() && (
              <div className="u-display--flex u-margin-left--huge">
                <img
                  src={iconLockLineDark}
                  className="c-panel-control__icn u-margin-right--tiny u-color--juri"
                  alt="Lock icon showing bidding is not possible"
                />
                <span
                  className="u-color--juri c-panel-control__lock"
                  data-testid="disabled-bidding-message"
                >
                  {blockedMessage()}
                </span>
                {isShowingDetail && hotels.length === 1 && <br />}
              </div>
            )}
            <div className="u-display--flex u-align-items--center">
              {!isBlocked() && showCurrentCpc()}
            </div>
          </div>
        </div>

        {!isBlocked() && (
          <div className="u-display--inline-flex u-margin-left--auto  u-align-items--center">
            <label className="u-font-weight-normal u-margin-right--gutter">
              {resolveBidValueName()}
            </label>

            <WrapperErrorHint message={cpcLimitErrorText} isShown={isCpcOverLimit()}>
              <NumberFormat
                getInputRef={setInputRef}
                className={cn('c-panel-control__numberinput u-margin-right--medium', {
                  'c-panel-control__numberinput-error': isCpcOverLimit(),
                })}
                decimalScale={2}
                decimalSeparator="."
                prefix={'€ '}
                placeholder={inputPlaceHolder}
                allowNegative={false}
                onKeyPress={keyPressEvent}
                value={cpc}
                isNumericString={true}
                onValueChange={v => setCpc(v.value)}
                onClick={clickBidInput}
              />
            </WrapperErrorHint>
            {!canApplyBids() ? (
              <TooltipWrapper
                message={
                  !isApplying ? translate('analytics_control_panel_apply_tooltip') : undefined
                }
                noTinyPaddingTop={true}
                position={TooltipPosition.TOP_TRAILING}
                className="c-panel-control__button-disabled-toltip"
              >
                <button
                  className="e-button e-button--small e-button--primary u-margin-right--gutter"
                  type="button"
                  onClick={() => applyCpc()}
                  disabled={true}
                >
                  {translate('analytics_home_apply')}
                </button>
              </TooltipWrapper>
            ) : (
              <button
                className="e-button e-button--small e-button--primary u-margin-right--gutter"
                type="button"
                onClick={() => applyCpc()}
                disabled={!canApplyBids()}
              >
                {translate('analytics_home_apply')}
              </button>
            )}
          </div>
        )}
      </div>
      {!isBlocked() && isCPA && (
        <div className="u-padding-h--small c-panel-control__information u-padding-left--large">
          {translate('analytics_control_panel_cpa')}
        </div>
      )}
    </>
  );
};

export const HotelViewControlPanel: FC<Omit<Props, 'maxCPCBidCap'>> = props => {
  const { maxCPCBidCap, isLoading } = useMaxCPCBidCap();
  if (isLoading) return <Loading />;

  return <HotelControlPanel {...props} maxCPCBidCap={maxCPCBidCap} />;
};
