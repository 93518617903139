import { AxiosResponse, AxiosError } from 'axios';
import { Locale, Region } from '../../../models';
import { RequestHelper } from '../request-helper';
import { TranslationKey, translate } from '../../utils';

const URL_FETCH_LOCALES = '/partner/locales';
const URL_FETCH_REGIONS = '/partner/regions';

class ApiAppHelper extends RequestHelper {
  private static instance: ApiAppHelper;

  public static getInstance() {
    if (!ApiAppHelper.instance) {
      ApiAppHelper.instance = new ApiAppHelper();
    }

    return ApiAppHelper.instance;
  }

  public async fetchLocales(partnersId: number[]): Promise<Locale[]> {
    const body = {
      partnerId: partnersId,
    };

    try {
      const data = await this.postRequest(URL_FETCH_LOCALES, body);
      const locales = data.data as Locale[];

      return locales.map(l => ({ ...l, name: translate(l.name as TranslationKey) }));
    } catch (e) {
      this.handleError(e as AxiosError);
      return [];
    }
  }

  public async fetchRegions(): Promise<Region[]> {
    try {
      const data: AxiosResponse<any[]> = await this.getRequest(URL_FETCH_REGIONS);

      return data.data.map(el => ({ regionName: el.regionName, localeCodes: el.localeCode }));
    } catch (e) {
      this.handleError(e as AxiosError);
      return [];
    }
  }
}

export default ApiAppHelper.getInstance();
