import { FC } from 'react';
import { ComposedDropdown } from '../../../components/composed-dropdown';
import { translate } from '../../../helpers/utils';
import { Selection } from '../../../models';

type DateSelectorProps = {
  isDateSelectionAvailable: boolean;
  calculateDateRangeSubtitle: () => JSX.Element;
  selectorBarOptionSelected: Selection;
  clickOpenClose: (selection: Selection) => void;
};

export const DateSelector: FC<DateSelectorProps> = ({
  isDateSelectionAvailable,
  calculateDateRangeSubtitle,
  selectorBarOptionSelected,
  clickOpenClose,
}) => {
  if (!isDateSelectionAvailable) return null;
  return (
    <ComposedDropdown
      title={translate('analytics_home_calendar')}
      subTitle={calculateDateRangeSubtitle()}
      extraClass="u-position--relative js-calendar"
      isSelected={selectorBarOptionSelected === Selection.CALENDAR}
      icon={''}
      onClick={() => clickOpenClose(Selection.CALENDAR)}
      qaId="selector-bar-date-picker"
    />
  );
};
