import styles from './sponsored-hotel-detail-view.module.css';
import { SponsoredGeneralInformationPanel } from './sponsored-general-information-panel';
import { SponsoredHotelDetail } from './sponsored-hotel-detail';
import { Hotel } from '../../../../models';
import { getPartnersId } from '../../../../services/app-service';
import { AppContext } from '../../../../global/context/app-context';
import { useContext, useEffect, useRef, useState, FC } from 'react';
import { getHotel } from '../../../../services/analytics-service';
import { areArraysEqual, translate, TranslationKey } from '../../../../helpers/utils';
import { RequestHelper } from '../../../../helpers/api/request-helper';
import { HotelNotFound } from '../../../../components/hotel-not-found';
import { NotificationLevel, SPONSORED_ANALYTICS_ROUTE } from '../../../../constants';
import { showNotification } from '../../../../services/notification-service';

export const SponsoredHotelDetailView: FC<{ partnerRef: string }> = ({ partnerRef }) => {
  const [hotel, setHotel] = useState<Hotel | undefined>(undefined);
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { partner, selectedLocales, subPartners } = useContext(AppContext);

  const previousPartnersId = useRef<number[]>([]);
  const previousSelectedLocales = useRef<string[]>([]);
  const isPartnerChange = useRef(false);
  const isSelectedLocalesChange = useRef(false);

  useEffect(() => {
    onUpdate();
  }, [selectedLocales, partner, subPartners]);

  useEffect(() => {
    isPartnerChange.current =
      previousPartnersId.current.length !== 0 &&
      !areArraysEqual(
        getPartnersId(partner, subPartners),
        previousPartnersId.current,
        (t1, t2) => t1 === t2,
      );
    isSelectedLocalesChange.current =
      previousSelectedLocales.current.length !== 0 &&
      !areArraysEqual(
        Array.from(selectedLocales),
        previousSelectedLocales.current,
        (t1, t2) => t1 === t2,
      );

    previousPartnersId.current = getPartnersId(partner, subPartners);
    previousSelectedLocales.current = Array.from(selectedLocales);
  }, [partner, subPartners, selectedLocales]);
  const hasFetchedHotelData = useRef(false);
  const onUpdate = async () => {
    const fetchHotelData = async () =>
      await getHotel(
        getPartnersId(partner, Array.from(subPartners)),
        partnerRef,
        Array.from(selectedLocales),
      );
    let hotelTmp: Hotel | undefined;
    try {
      setLoading(true);
      if (!hasFetchedHotelData.current) {
        hotelTmp = await fetchHotelData();
        setHotel(hotelTmp);
        setIsError(!hotelTmp);
        hasFetchedHotelData.current = true;
      }
    } catch (e) {
      const error = e as Error;
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate(error.message as TranslationKey),
      });
      if (error.message !== RequestHelper.cancelMessage) {
        setIsError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const getHotelNotFoundReason = () => {
    if (isPartnerChange.current) {
      return 'sponsored_hotel_not_found_partner_change';
    } else if (isSelectedLocalesChange.current) {
      return 'sponsored_hotel_not_found_pos_change';
    }
    return 'sponsored_hotel_not_found_unknown';
  };

  if (isError) {
    return (
      <HotelNotFound
        partnerRef={partnerRef}
        reason={getHotelNotFoundReason()}
        redirectRoute={SPONSORED_ANALYTICS_ROUTE}
      />
    );
  }
  return (
    <div className={styles.hotelDetails}>
      <SponsoredGeneralInformationPanel hotel={hotel} isLoading={isLoading} onUpdate={onUpdate} />
      <SponsoredHotelDetail partnerRef={partnerRef} />
    </div>
  );
};
