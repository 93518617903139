import * as React from 'react';
import { Props, LocalNotification } from './notifications.types';
import { Notification } from '../../../components/notification';
import { Notification as NotificationHandler } from '../../../models/notification';
import { NotificationLevel, EVENT_NOTIFICATION, TIMEOUT_NOTIFICATION } from '../../../constants';
import { Event } from '../../../services/event';
import { v4 } from 'uuid';

export const Notifications: React.FunctionComponent<Props> = () => {
  const [notifications, setNotifications] = React.useState<LocalNotification[]>([]);

  Event.subscribe(EVENT_NOTIFICATION, (notification: NotificationHandler) => {
    const newNotification: LocalNotification = {
      id: v4(),
      level: notification.level,
      message: notification.message,
    };
    notifications.push(newNotification);
    setNotifications(notifications.slice());

    if (notification.level === NotificationLevel.SUCCESS) {
      setTimeout(() => closeNotification(newNotification.id), TIMEOUT_NOTIFICATION);
    }
  });

  const closeNotification = (id: string) =>
    setNotifications(notifications.filter(notification => notification.id !== id));

  return (
    <React.Fragment>
      {notifications.length > 0 && (
        <div>
          {notifications.map(notificationTmp => {
            return (
              <Notification
                key={notificationTmp.id}
                notification={notificationTmp}
                onClose={() => closeNotification(notificationTmp.id)}
              />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};
