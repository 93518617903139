import * as React from 'react';
import { POS_GLOBAL } from '../../constants';
import GlobalLineDarkIcon from 'tcp-react-icons/lib/GlobalLineDarkIcon';
import FlagCreator from '../flag/flag';
import './flag-icon.css';
import { Props } from './flag-icon.types';

export const FlagIcon: React.FunctionComponent<Props> = ({ flag, className, size = 'lg' }) => {
  const flagToLowerCase = flag.toLowerCase();
  switch (flagToLowerCase) {
    case '':
      return null;
    case POS_GLOBAL:
      return <GlobalLineDarkIcon className={className} />;
    case 'aa':
      return <FlagCreator className={className} code={'sa'} size={size} />;
    case 'uk':
      return <FlagCreator className={className} code={'gb'} size={size} />;
    default:
      return <FlagCreator className={className} code={flagToLowerCase} size={size} />;
  }
};
