import { PARTNERLEVEL_DEVELOPER, LEVEL_TRIVAGO_EMPLOYEE } from '../../constants';
import { User } from '../../models';

export const isDeveloper = (partnerLevel: number) => partnerLevel === PARTNERLEVEL_DEVELOPER;

export const isFeatureEnabledForUser = (right: number, user: User) => {
  return right <= user.partnerLevel || user.isAdmin;
};

export const isTrivagoEmployee = (level: number) => level === LEVEL_TRIVAGO_EMPLOYEE;
