import { useEffect, useState } from 'react';
import { ReportsApi } from '../../helpers/api/reports/reports-api';
import { Report } from '../../views/reports/report.types';

interface UseAllReportsResult {
  reports: Report[];
  isLoading: boolean;
  refetch: VoidFunction;
  error?: string;
}

export function useAllReports(): UseAllReportsResult {
  const [reports, setReports] = useState<Report[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  async function fetchAllReports() {
    try {
      setLoading(true);
      setError(undefined);
      setReports(await ReportsApi.fetchAllReports());
    } catch (e) {
      console.error(e);
      setError('Unable to fetch all reports');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAllReports();
  }, []);

  return {
    reports,
    isLoading,
    refetch: fetchAllReports,
    error,
  };
}
