import React, { FC } from 'react';
import { CheckboxSelectOption } from '../../../../components/checkbox-select/use-checkbox-options';
import { ButtonDropdown } from '../../../../components/button-dropdown';
import { CheckboxSelect } from '../../../../components/checkbox-select/checkbox-select';
import { TranslationKey, translate } from '../../../../helpers/utils';
import styles from './filter.module.css';
import cn from 'classnames';
import { TooltipPosition, TooltipWrapper } from '../../../../components/tooltip-wrapper';

export type FilterProps = {
  allIds: number[];
  defaultLabel: string;
  selectedIds: number[];
  onApply: (ids: number[]) => void;
  label: (id: number) => string;
  subLabel?: (id: number) => string | undefined;
  disabledMessage?: string;
  allowEmptySelection?: boolean;
};

export const Filter: FC<FilterProps> = props => {
  const {
    allIds,
    selectedIds,
    label,
    subLabel,
    defaultLabel,
    disabledMessage,
    allowEmptySelection,
  } = props;
  const options: CheckboxSelectOption<number>[] = allIds.map(id => ({
    id,
    label: translate(label(id) as TranslationKey),
    subLabel: subLabel?.(id),
    value: id,
    checked: selectedIds.includes(id),
  }));

  const title = () => {
    const selectedOptions = options.filter(o => o.checked);
    if (selectedOptions.length === 0) return defaultLabel;
    if (selectedOptions.length === 1) return selectedOptions[0].label;
    return `${selectedOptions.length} ${defaultLabel} selected`;
  };

  function onApply(appliedOptions: CheckboxSelectOption<number>[]) {
    props.onApply(appliedOptions.filter(o => o.checked).map(o => o.value));
    closeDropdown();
  }

  // hacky way to do it, but the only way to do it
  const closeDropdown = () => document.dispatchEvent(new MouseEvent('mousedown'));

  const hasIdsSelected = selectedIds.length > 0;

  const dropdown = (
    <ButtonDropdown
      disabled={!!disabledMessage}
      title={title()}
      className={styles.filterDropdown}
      classNameButton={cn(styles.filterDropdownButton, {
        [styles.selected]: hasIdsSelected && !disabledMessage,
        [styles.disabled]: !!disabledMessage,
      })}
      position="left"
    >
      <CheckboxSelect
        options={options}
        onApply={onApply}
        allowEmptySelection={allowEmptySelection}
      />
    </ButtonDropdown>
  );

  if (disabledMessage) {
    return (
      <TooltipWrapper
        message={disabledMessage}
        position={TooltipPosition.TOP_LEADING}
        noTinyPaddingTop
      >
        {dropdown}
      </TooltipWrapper>
    );
  }

  return dropdown;
};
