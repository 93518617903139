import * as React from 'react';
import { Props } from './management-rights-guard.types';
import { isFeatureEnabledForUser, isDeveloper } from '../../helpers/utils';
import { Redirect } from 'react-router';
import { DEFAULT_ROUTE } from '../../constants';

export const ManagementRightsGuard: React.FunctionComponent<Props> = ({
  user,
  rights,
  children,
}) => {
  const isEnabledForUser = isFeatureEnabledForUser(rights, user) || isDeveloper(user.partnerLevel);

  if (isEnabledForUser === null) {
    return <></>;
  }

  if (!isEnabledForUser) {
    return <Redirect to={DEFAULT_ROUTE} />;
  }

  return <>{children}</>;
};
