import React from 'react';
import { DeleteChartProps } from './chart-builder.types';
import { ConfirmationModal } from '../../../components/confirmation-modal';
import { translate } from '../../../helpers/utils';

const DeleteChartModal: React.FunctionComponent<DeleteChartProps> = ({
  chartName,
  openDeleteChart,
  confirmDeleteChart,
}) => {
  const message = `Are you sure you want to delete <b>${chartName}</b>?`;
  return (
    <ConfirmationModal
      title={'Delete'}
      details={[<div dangerouslySetInnerHTML={{ __html: message }} key="message" />]}
      onClose={() => openDeleteChart(false)}
      primaryButton={{
        title: translate('common_delete'),
        actionFn: confirmDeleteChart,
        style: 'e-button--danger',
      }}
      secondaryButton={{
        title: translate('common_cancel'),
        actionFn: () => openDeleteChart(false),
      }}
    />
  );
};

export default DeleteChartModal;
