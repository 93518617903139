import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { DatePeriod, DateRangePeriod } from '../../models';
import {
  getPeriodFromLocalStorage,
  savePeriodToLocalStorage,
} from '../../helpers/local-storage-helper';
import { getLastXDays } from '../../helpers/utils';
import { DATE_PICKER_COMPARE_TOGGLE_KEY } from '../../constants';

export type CompareColor = {
  name: string;
  primary: string;
  secondary: string;
};

type DatePickerState = {
  dateRangePeriod: DateRangePeriod;
  compareDateRangePeriods: DateRangePeriod[];
  compareColors: CompareColor[];
  isComparing: boolean;
  isCompareEnabled: boolean;
  isSelectingTo: boolean;
  isSelectingFrom: boolean;
  setDateRangePeriod: (dateRangePeriod: DateRangePeriod) => void;
  setCompareDateRangePeriods: (dateRangePeriods: DateRangePeriod[]) => void;
  setCompareColors: (compareColors: CompareColor[]) => void;
  setIsComparing: (isComparing: boolean) => void;
  setIsCompareEnabled: (isCompareEnabled: boolean) => void;
  setIsSelectingFrom: (isSelectingFrom: boolean) => void;
  setIsSelectingTo: (isSelectingTo: boolean) => void;
};

const useDatePickerState = createWithEqualityFn<DatePickerState>(
  set => ({
    dateRangePeriod: getPeriodFromLocalStorage() ?? {
      from: getLastXDays(7),
      to: getLastXDays(1),
      period: DatePeriod.LAST_7,
    },
    compareDateRangePeriods: getCompareDatePeriodsFromLocalStorage(),
    compareColors: [],
    isComparing: getCompareStateFromLocalStorage(),
    isCompareEnabled: false,
    isSelectingFrom: false,
    isSelectingTo: false,
    setDateRangePeriod: (dateRangePeriod: DateRangePeriod) => {
      savePeriodToLocalStorage(dateRangePeriod);
      set({ dateRangePeriod });
    },
    setCompareDateRangePeriods: (compareDateRangePeriods: DateRangePeriod[]) =>
      set({ compareDateRangePeriods }),
    setCompareColors: (compareColors: CompareColor[]) => set({ compareColors }),
    setIsComparing: (isComparing: boolean) => set({ isComparing }),
    setIsCompareEnabled: (isCompareEnabled: boolean) => set({ isCompareEnabled }),
    setIsSelectingFrom: (isSelectingFrom: boolean) => set({ isSelectingFrom }),
    setIsSelectingTo: (isSelectingTo: boolean) => set({ isSelectingTo }),
  }),
  Object.is,
);

export function useDateRangePeriod(): [
  DateRangePeriod,
  (dateRangePeriod: DateRangePeriod) => void,
] {
  return useDatePickerState(
    (state: DatePickerState) => [state.dateRangePeriod, state.setDateRangePeriod],
    shallow,
  );
}

export function useCompareDateRangePeriods(): [
  DateRangePeriod[],
  (dateRangePeriods: DateRangePeriod[]) => void,
] {
  return useDatePickerState(
    (state: DatePickerState) => [state.compareDateRangePeriods, state.setCompareDateRangePeriods],
    shallow,
  );
}

export function useCompareColors(): [CompareColor[], (compareColors: CompareColor[]) => void] {
  return useDatePickerState(
    (state: DatePickerState) => [state.compareColors, state.setCompareColors],
    shallow,
  );
}

export function useIsComparing(): [boolean, (isComparing: boolean) => void] {
  return useDatePickerState(
    (state: DatePickerState) => [state.isComparing, state.setIsComparing],
    shallow,
  );
}

export function useIsCompareEnabled(): [boolean, (isCompareEnabled: boolean) => void] {
  return useDatePickerState(
    (state: DatePickerState) => [state.isCompareEnabled, state.setIsCompareEnabled],
    shallow,
  );
}

export function useIsSelectingFrom(): [boolean, (isSelectingFrom: boolean) => void] {
  return useDatePickerState(
    (state: DatePickerState) => [state.isSelectingFrom, state.setIsSelectingFrom],
    shallow,
  );
}

export function useIsSelectingTo(): [boolean, (isSelectingTo: boolean) => void] {
  return useDatePickerState(
    (state: DatePickerState) => [state.isSelectingTo, state.setIsSelectingTo],
    shallow,
  );
}

export function saveDatePickerStateToLocalStorage(compareDateRangePeriods: DateRangePeriod[]) {
  localStorage.setItem(DATE_PICKER_COMPARE_TOGGLE_KEY, 'true');
  localStorage.setItem('compareDateRangePeriods', JSON.stringify(compareDateRangePeriods));
}

export function removeCompareStateFromLocalStorage() {
  localStorage.removeItem(DATE_PICKER_COMPARE_TOGGLE_KEY);
}

export function getCompareStateFromLocalStorage() {
  return Boolean(localStorage.getItem(DATE_PICKER_COMPARE_TOGGLE_KEY));
}

export function getCompareDatePeriodsFromLocalStorage() {
  return JSON.parse(localStorage.getItem('compareDateRangePeriods') || '[]').map(
    (period: DateRangePeriod) => ({
      ...period,
      from: new Date(period.from),
      to: new Date(period.to),
    }),
  );
}
