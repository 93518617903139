import React, { FC, useContext, useState } from 'react';
import { ScrollableModal } from '../../../components/scrollable-modal';
import { NotificationLevel } from '../../../constants';
import { Report, ReportDefinition, Subscription } from '../report.types';
import { ReportDefinitionPicker } from './report-definition-picker';
import { CombinedPicker } from './combined-picker';
import { AppContext } from '../../../global/context/app-context';
import { CreateSubscriptionRequest, ReportsApi } from '../../../helpers/api/reports/reports-api';
import { showNotification } from '../../../services/notification-service';
import { getDisplayName, showError } from '../util';
import { getPartner } from '../../../services/app-service';
import { AxiosError } from 'axios';
import { Checkbox } from '../../../components/checkbox';

export const SubscribeToReportModal: FC<{
  report?: Report;
  onClose: (changesMade: boolean) => void;
}> = ({ report, onClose }) => {
  const { partner, subPartners } = useContext(AppContext);
  const partnerId = getPartner(partner, subPartners).partnerId;
  const [reportDefinition, setDefinition] = useState<ReportDefinition | undefined>();
  const [combined, setCombined] = useState(false);
  const [nonCombined, setNonCombined] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [deliverEmptyFile, setDeliverEmptyFiles] = useState(true);

  async function createSubscription(request: CreateSubscriptionRequest) {
    await ReportsApi.createSubscription(request);
    const message = `Subscribed to ${request.isCombined ? '' : 'non-'}combined version!`;
    showNotification({
      level: NotificationLevel.SUCCESS,
      message,
    });
  }

  async function subscribe() {
    try {
      setLoading(true);
      const promises: Promise<void>[] = [];
      const reportDefinitionId = reportDefinition?.id!;
      if (nonCombined) {
        promises.push(
          createSubscription({
            isCombined: false,
            reportDefinitionId,
            partnerId,
            deliverEmptyFile,
          }).catch(err => handleError(err)),
        );
      }
      if (combined) {
        promises.push(
          createSubscription({
            isCombined: true,
            reportDefinitionId,
            partnerId,
            deliverEmptyFile,
          }).catch(err => handleError(err, true)),
        );
      }

      await Promise.all(promises);

      onClose(true);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  }

  function handleError(error: any, isCombined = false) {
    if (!error.response) return showError(error);
    const response = (error as AxiosError).response;
    if (response?.status !== 409) return showError(error);
    const displayName = getDisplayName({ isCombined, reportDefinition } as Subscription);
    showError(new Error(`Subscription to ${displayName} already exists`));
  }

  function isFormValid(): boolean {
    return isDefinitionSelected() && isCombinedOrNonCombinedChecked();
  }

  const isDefinitionSelected = () => reportDefinition !== undefined;
  const isCombinedOrNonCombinedChecked = () => combined || nonCombined;

  if (!report) return null;
  return (
    <ScrollableModal
      title={`Subscribe to ${getDisplayName(report)}`}
      onClose={() => onClose(false)}
      bodyStyle={{ overflow: 'initial' }}
      primaryButton={{
        title: isLoading ? 'Subscribing...' : 'Subscribe',
        actionFn: subscribe,
        isDisabled: !isFormValid(),
      }}
      secondaryButton={{ title: 'Cancel', actionFn: () => onClose(false) }}
      footer={{ isTopBorderHidden: false }}
    >
      <div className="u-padding-w--large">
        <ReportDefinitionPicker reportName={report.name} onDefinitionPick={setDefinition} />
        <div className="u-padding-vertical--t-medium">
          <Checkbox
            title="Deliver an empty file in case there is no data"
            onChange={() => setDeliverEmptyFiles(prev => !prev)}
            isChecked={deliverEmptyFile}
          />
        </div>
        <hr />
        <CombinedPicker
          combinedValue={combined}
          nonCombinedValue={nonCombined}
          onCombinedChange={setCombined}
          onNonCombinedChange={setNonCombined}
        />
        <div className="u-padding-vertical--t-medium">
          <p>
            Delivery frequency: <b>DAILY</b>
          </p>
        </div>
      </div>
    </ScrollableModal>
  );
};
