import { addDays, differenceInCalendarDays, subDays, subYears } from 'date-fns';
import format from 'date-fns/format';
import isSameDay from 'date-fns/is_same_day';
import { ReactText } from 'react';
import { TranslationKey, dateFromYmd, translate } from '.';
import {
  DATE_FORMAT_LONG,
  DATE_FORMAT_LONG_YEAR,
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_YEAR,
  DATE_FORMAT_STANDARD,
  DATE_FORMAT_TIMELINE,
  TIME_FORMAT,
  TimeOption,
} from '../../constants';
import { DateRange, DateRangePeriod, ViewData } from '../../models';
import {
  CHART_DATA_DATE_COLUMN_TIME_OPTION,
  PERIOD_FORMATTER_TIME_OPTION,
} from '../../views/custom-dashboard/widget';

export const formattedDate = (date: Date) => {
  return format(date, DATE_FORMAT_LONG);
};

export const formatDateAndTime = (date: Date) => {
  return `${format(date, DATE_FORMAT_LONG)} ${translate('history_at')} ${format(
    date,
    TIME_FORMAT,
  )}`;
};

export const labelDateFormatter = (date: Date) => {
  return format(date, DATE_FORMAT_SHORT);
};

export const dateFormatterShortYear = (date: Date) => {
  return format(date, DATE_FORMAT_SHORT_YEAR);
};

export const dateFormatterLongYear = (date: Date) => {
  return format(date, DATE_FORMAT_LONG_YEAR);
};

export const getFormattedDates = (from?: Date, to?: Date) => {
  if (!from || !to) return undefined;
  return {
    from: format(from, DATE_FORMAT_STANDARD),
    to: format(to, DATE_FORMAT_STANDARD),
  };
};

export const formatDateRange = ({ from, to }: DateRange, dateFormat: string) =>
  isSameDay(from, to)
    ? format(from, dateFormat)
    : `${format(from, dateFormat)} - ${format(to, dateFormat)}`;

export const formatDateAsDateRange = (
  date: string,
  aggregationPeriod: TimeOption,
  { from, to }: DateRange,
) => {
  const dateRange = PERIOD_FORMATTER_TIME_OPTION[aggregationPeriod](date);
  const startDate = from > dateRange.from ? from : dateRange.from;
  const endDate = to < dateRange.to ? to : dateRange.to;
  return formatDateRange({ from: startDate, to: endDate }, DATE_FORMAT_TIMELINE);
};

export const getDateTooltip = (
  data: ViewData[],
  dateNumber: ReactText,
  aggregationPeriod: TimeOption,
  dateRange: DateRange,
): string => {
  const element = data.find(d => d.date === Number(dateNumber));
  if (!element) return '';
  const date = String(element[CHART_DATA_DATE_COLUMN_TIME_OPTION[aggregationPeriod]]);
  return formatDateAsDateRange(date, aggregationPeriod, dateRange);
};

export const WEEKDAYS_SHORT: [string, string, string, string, string, string, string] = [
  'sun',
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
];

export enum Period {
  PREVIOUS = 'time_period_previous_period',
  YEAR_OVER_YEAR = 'time_period_year_over_year',
  YEAR_OVER_2_YEARS = 'time_period_year_over_2_years',
}

export const periodFromEn = {
  'Previous period': Period.PREVIOUS,
  'Period Year over Year': Period.YEAR_OVER_YEAR,
  'Period Year over 2 Years': Period.YEAR_OVER_2_YEARS,
};

type PeriodLabels = {
  label: string;
  subLabel: string;
  fullLabel: string;
};

type DateRangeWithPeriodLabels = DateRange & PeriodLabels;

export function getComparePeriodWithLabels(
  dateRangeToCompareTo: DateRange,
  period: Period,
): DateRangeWithPeriodLabels {
  const { from, to } = getCompareDateRange(dateRangeToCompareTo, period);

  const label = translate(period as TranslationKey);
  const subLabel = `${formattedDate(from)} - ${formattedDate(to)}`;

  return {
    timeRangeName: period,
    from,
    to,
    label,
    subLabel,
    fullLabel: `${label} (${subLabel})`,
  };
}

export function getCompareDateRange(dateRange: DateRange, period: Period): DateRange {
  const durationInDays = differenceInCalendarDays(dateRange.to, dateRange.from) + 1;
  switch (period) {
    case Period.PREVIOUS:
      return {
        from: subDays(dateRange.from, durationInDays),
        to: subDays(dateRange.to, durationInDays),
      };
    case Period.YEAR_OVER_YEAR:
      return { from: subYears(dateRange.from, 1), to: subYears(dateRange.to, 1) };
    case Period.YEAR_OVER_2_YEARS:
      return { from: subYears(dateRange.from, 2), to: subYears(dateRange.to, 2) };
  }
}

export function isCustomStartingDate(comparedPeriod: string) {
  return (
    !Object.keys(Period)[Object.values(Period).indexOf(comparedPeriod as Period)] &&
    !periodFromEn[comparedPeriod as unknown as keyof typeof periodFromEn]
  );
}

export function customStartingDateToDateRange(basePeriod: DateRangePeriod, ymd: string): DateRange {
  const durationInDays = differenceInCalendarDays(basePeriod.to, basePeriod.from);
  const customStartingDate = dateFromYmd(ymd);
  return {
    from: customStartingDate,
    to: addDays(customStartingDate, durationInDays),
  };
}

export function shiftToCustomStartingDate(
  basePeriod: DateRangePeriod,
  customStartingDateYmd: string,
  dateToShift: Date,
): Date {
  const customStartingDate = dateFromYmd(customStartingDateYmd);
  const durationInDays = differenceInCalendarDays(customStartingDate, basePeriod.from);
  return addDays(dateToShift, durationInDays);
}
