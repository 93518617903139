import * as React from 'react';
import { Props } from './list.types';
import './list.css';

export const List: React.FunctionComponent<Props> = ({ rows, headers }) => {
  return (
    <table>
      <thead className="c-list-header">
        <tr>
          {headers.map((h, idx) => (
            <th key={idx}>{h}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((r, idx) => (
          <tr key={idx} className="c-list-row">
            {r}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
