import * as React from 'react';
import { Props } from './mblu-title-widget.types';
import './mblu-title-widget.css';
import { Circle } from '../../../../components/circle';

export const MbluTitleWidget: React.FunctionComponent<Props> = ({
  text,
  color,
  extraClasses = '',
}) => {
  return (
    <div className={`u-display--flex u-align-items--center ${extraClasses}`}>
      <Circle radius={5} color={color} />
      <span className="u-margin-left--gutter--small">{text}</span>
    </div>
  );
};
