import { ReactNode } from 'react';
import { PropsDataQA } from '../../models';

export type Props = {
  header?: {
    html: ReactNode;
    extraClasses?: string;
  };
  footer?: {
    html?: ReactNode;
    extraClasses?: string;
  };
  body: {
    items: Array<ReactNode | string>;
    extraClasses?: string;
  };
  defaultEmpty?: ReactNode;
  onSelectItem: (index: number) => void;
  direction?: MenuDirection;
} & PropsDataQA;

export enum MenuDirection {
  BottomCenter,
  BottomTrailing,
  BottomLeading,
}
