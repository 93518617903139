import React from 'react';
import { translate } from '../../helpers/utils';
import { Button } from '../button';
import { CheckboxSelectProps } from './checkbox-select';
import cs from 'classnames';

type FooterProps<T> = Pick<CheckboxSelectProps<T>, 'selectedOptionsLimit'> & {
  numberOfSelectedOptions: number;
  onApply: VoidFunction;
  allowEmptySelection?: boolean;
};

export function CheckboxSelectFooter<T>(props: FooterProps<T>) {
  const { onApply, selectedOptionsLimit, numberOfSelectedOptions, allowEmptySelection } = props;
  return (
    <div className="checkbox-select-footer u-display--flex u-flex-direction--row u-justify-content--space-between">
      {selectedOptionsLimit === undefined ? null : (
        <p className="u-display--flex u-align-items--center u-justify-content--center">
          {numberOfSelectedOptions} selected (max {selectedOptionsLimit})
        </p>
      )}
      <Button
        message={translate('common_apply')}
        disabled={!allowEmptySelection && numberOfSelectedOptions === 0}
        className={cs('u-justify-content--space-around', {
          'u-margin-left--gutter--large': selectedOptionsLimit !== undefined,
        })}
        onClick={() => onApply()}
      />
    </div>
  );
}
