export const filters = [
  {
    columnName: 'invalid_filter',
    columnLabel: 'Invalid',
    type: 'is equal to',
    typeLabel: '',
    value: '0',
  },
  {
    columnName: 'anomalies',
    columnLabel: 'Anomalies',
    type: 'is equal to',
    typeLabel: '',
    value: '4',
  },
  {
    columnName: 'average_booking_volume2',
    columnLabel: 'ABV',
    type: 'is higher than',
    typeLabel: '',
    value: '1',
  },
  {
    columnName: 'avg_cpc2',
    columnLabel: 'Avg.CPC',
    type: 'is higher than',
    typeLabel: '',
    value: '2',
  },
  {
    columnName: 'frequency',
    columnLabel: 'Frequency',
    type: 'is higher than',
    typeLabel: '',
    value: '3',
  },
];

export const filterTypes = [
  {
    index: 5,
    key: 'CONTAINS',
    name: 'contains',
  },
  {
    index: 6,
    key: 'NOT_CONTAINS',
    name: 'does not contain',
  },
  {
    index: 0,
    key: 'EQUALS',
    name: 'is equal to',
  },
  {
    index: 4,
    key: 'NOT_EQUALS',
    name: 'is not equal to',
  },
  {
    index: 1,
    key: 'GREATER',
    name: 'is higher than',
  },
  {
    index: 2,
    key: 'LESS',
    name: 'is lower than',
  },
];

export const columns = {
  hotelView: [
    {
      key: 'id',
      name: 'id',
      type: 'int',
      isHidden: true,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'name2',
      name: 'Hotel Name',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: ' ',
      round: 0,
    },
    {
      key: 'pid',
      name: 'PartnerRef',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'pos2',
      name: 'POS',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'status',
      name: 'Status',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip:
        'The Status describes the current state of any changes to CPC. No change: displayed Bid CPC is live/no change has been made. Pending: displayed Bid CPC has been change/waiting to go live. Processing: change to the Bid CPC is being processed to go live. ',
      round: 0,
    },
    {
      key: 'hv_bidding_type',
      name: 'Bidding Type',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'hv_pending_bidding_type',
      name: 'Pending Bidding Type',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'hv_cpa',
      name: 'CPA value',
      type: 'string',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'hv_bid_cpc',
      name: 'Bid CPC',
      type: 'string',
      isHidden: false,
      wrapper: '€',
      isHiddenTotal: true,
      tooltip:
        'The Bid CPC is the CPC applied to the hotel. It will always show the current bid, regardless of the date range.',
      round: 0,
    },
    {
      key: 'hv_pending_bid_cpc',
      name: 'Pending Bid CPC',
      type: 'string',
      isHidden: false,
      wrapper: '€',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'hv_property_group',
      name: 'Property group',
      type: 'int',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip:
        'This column indicates which Property Group each property belongs to. Property Groups allow you to apply different Bid Modifiers to properties that you have grouped together within a point of sale. ',
      round: 0,
    },
    {
      key: 'hotel_impressions2',
      name: 'Hotel Impr',
      type: 'int',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_hotel_impress',
      round: 0,
    },
    {
      key: 'clicks2',
      name: 'Clicks',
      type: 'int',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_clicks',
      round: 0,
    },
    {
      key: 'cost2',
      name: 'Cost',
      type: 'float',
      isHidden: false,
      wrapper: '€',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_cost',
      round: 2,
    },
    {
      key: 'avg_cpc2',
      name: 'Avg.CPC',
      type: 'float',
      isHidden: false,
      wrapper: '€',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_avg_cpc',
      round: 2,
    },
    {
      key: 'top_pos_share2',
      name: 'Top Pos Share',
      type: 'float',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_top_pos_share',
      round: 4,
    },
    {
      key: 'impressions_share2',
      name: 'Impr Share',
      type: 'float',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_impr_share',
      round: 4,
    },
    {
      key: 'outbid_rate2',
      name: 'Outbid Ratio',
      type: 'float',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_outbid_ratio',
      round: 4,
    },
    {
      key: 'beat_share2',
      name: 'Beat',
      type: 'float',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_beat',
      round: 4,
    },
    {
      key: 'meet_share2',
      name: 'Meet',
      type: 'float',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_meet',
      round: 4,
    },
    {
      key: 'lose_share2',
      name: 'Lose',
      type: 'float',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_lose',
      round: 4,
    },
    {
      key: 'unavailability_share2',
      name: 'Unavailability',
      type: 'float',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_unavailability',
      round: 4,
    },
    {
      key: 'bookings2',
      name: 'Bookings',
      type: 'int',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_bookings',
      round: 0,
    },
    {
      key: 'booking_conversion2',
      name: 'Booking Rate',
      type: 'float',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_booking_rate',
      round: 4,
    },
    {
      key: 'cpa2',
      name: 'CPA',
      type: 'float',
      isHidden: false,
      wrapper: '%',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_cpa',
      round: 4,
    },
    {
      key: 'gross_revenue2',
      name: 'Gross Rev',
      type: 'float',
      isHidden: false,
      wrapper: '€',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_gross_rev',
      round: 2,
    },
    {
      key: 'average_booking_volume2',
      name: 'ABV',
      type: 'float',
      isHidden: false,
      wrapper: '€',
      isHiddenTotal: false,
      tooltip: 'analytics_tooltip_abv',
      round: 2,
    },
    {
      key: 'last_pushed',
      name: 'Last Pushed',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'region',
      name: 'Region',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'country',
      name: 'Country',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: 'The country in which this property is located.',
      round: 0,
    },
    {
      key: 'city',
      name: 'City',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: 'The city in which this property is located.',
      round: 0,
    },
    {
      key: 'stars',
      name: 'Stars',
      type: 'int',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: 'The number of stars that this property possesses (from 1 to 5).',
      round: 0,
    },
    {
      key: 'overall_liking2',
      name: 'Rating',
      type: 'float',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'item_id',
      name: 'trivagoID',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: '',
      round: 0,
    },
    {
      key: 'url',
      name: 'trivago URL',
      type: 'string',
      isHidden: false,
      wrapper: '',
      isHiddenTotal: true,
      tooltip: 'This is a direct link to the property on trivago.',
      round: 0,
    },
  ],
};
