import { AxiosError, AxiosResponse } from 'axios';
import { FILE_FORMAT_VALUE, VIEW_NAME } from '../../constants';
import { FileFormat, View } from '../../models';
import { getProfileFromLocalStorage } from '../local-storage-helper';
import { CONTENT_TYPE_VALUES, HEADERS } from './http-constants';
import { RequestHelper } from './request-helper';

const URL_DOWNLOAD_SUMMARY_FILE = '/bidding/summary-file';
const URL_SOURCE_SUMMARY_FILE = '/bidding/source-file';
const URL_DOWNLOAD_SAMPLE_FILE = '/bidding/samples/{filePath}';
const URL_DOWNLOAD_FROM_ANALYTICS = '/analytics/export/download';
const URL_DOWNLOAD_REPORT_ARTIFACT = '/reports/artifacts/{partnerId}/download';
const URL_DOWNLOAD_INVENTORY_FAILED_MATCHES = '/inventory/{failedType}/{partnerId}/latest';
const URL_DOWNLOAD_INVENTORY_RELATED_FILE = '/new-inventory/download/{jobId}/{fileType}';

class ApiFileHelper extends RequestHelper {
  private getToken() {
    const user = getProfileFromLocalStorage();
    if (!user) {
      return null;
    }
    return user.token.accessToken;
  }

  public uploadFile = (partnerId: number, file: File, url: string, delimiter: string) => {
    const form = new FormData();

    form.append('file', file);
    form.append('partnerId', partnerId.toString());
    form.append('delimiter', delimiter);

    const headers = {
      [HEADERS.CONTENT_TYPE]: CONTENT_TYPE_VALUES.MULTIPART_FORM_DATA,
    };

    try {
      return this.postRequest(url, form, { headers }).then(
        (
          response: AxiosResponse<{
            locales: string[];
            text: string;
          }>,
        ) => response.data,
      );
    } catch (e) {
      this.handleError(e as AxiosError);
      return;
    }
  };

  private getFileNameFromHeaders = (headers: any) => {
    if (headers) {
      const contentDisposition: string[] = headers['content-disposition'].split(';');
      const idx = contentDisposition.findIndex(item => item.includes('filename'));
      return contentDisposition[idx].split('=')[1];
    }
    return '';
  };

  public downloadSourceFile = (partnerId: number, id: number) => {
    const url = `${URL_SOURCE_SUMMARY_FILE}?jobId=${id}`;
    return this.downloadFile(partnerId, url);
  };

  public async downloadReportArtifact(artifactPath: string, partnerId: number) {
    try {
      const url = RequestHelper.replaceUrlParams(URL_DOWNLOAD_REPORT_ARTIFACT, [
        { key: 'partnerId', value: partnerId },
      ]);
      const { data } = await this.getRequest(url, { params: { artifactPath } });
      window.open(data.downloadLink, '_blank');
    } catch (e) {
      this.handleError(e as AxiosError, 'file_download_artifact_error');
    }
  }

  public async downloadSampleFile(path: string) {
    try {
      const url = RequestHelper.replaceUrlParams(URL_DOWNLOAD_SAMPLE_FILE, [
        { key: 'filePath', value: path },
      ]);
      const { data } = await this.getRequest(url);
      window.open(data, '_blank');
    } catch (e) {
      this.handleError(e as AxiosError, 'file_download_sample_error');
    }
  }

  public downloadSummaryFile = (partnerId: number, id: number) => {
    const url = `${URL_DOWNLOAD_SUMMARY_FILE}?jobId=${id}`;
    return this.downloadFile(partnerId, url);
  };

  public downloadReportAnalyticsFile = async (
    view: View,
    body: any,
    fileFormat: FileFormat = FileFormat.CSV,
    fileName?: string,
  ) => {
    const token = this.getToken() || '';
    const url =
      `${URL_DOWNLOAD_FROM_ANALYTICS}?view=${VIEW_NAME[view]}&filters=` +
      JSON.stringify(body) +
      `&auth=${token}&format=${FILE_FORMAT_VALUE[fileFormat]}`;
    try {
      const response = await this.getRequest(url, { responseType: 'blob' });
      const downloadUrl = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      const fileNameToDownload = fileName
        ? fileName
        : this.getFileNameFromHeaders(response.headers);
      link.setAttribute('download', fileNameToDownload);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      this.handleError(e as AxiosError, 'file_download_analytics_error');
    }
  };

  public downloadFailedInventoryMatches = async (partnerIds: number[], failedStat: string) => {
    try {
      const promises = [];
      for (const partnerId of partnerIds) {
        const pathParams = [
          { key: 'failedType', value: failedStat },
          { key: 'partnerId', value: partnerId },
        ];
        const url = RequestHelper.replaceUrlParams(
          URL_DOWNLOAD_INVENTORY_FAILED_MATCHES,
          pathParams,
        );
        promises.push(this.getRequest(url, { responseType: 'blob' }));
      }

      const results = await Promise.allSettled(promises);
      const responses = results
        .filter(result => result.status === 'fulfilled')
        .map((el: PromiseFulfilledResult<AxiosResponse>) => el.value);

      for (const response of responses) {
        const downloadUrl = window.URL.createObjectURL(response.data);
        const fileName = this.getFileNameFromHeaders(response.headers);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      if (results.some(result => result.status === 'rejected')) {
        throw new Error('Some files failed to download');
      }
    } catch (e) {
      this.handleError(e as any, (e as any)?.message);
    }
  };

  public downloadInventoryRelatedFile = (jobId: number, fileType: string) => {
    const pathParams = [
      { key: 'jobId', value: jobId },
      { key: 'fileType', value: fileType },
    ];
    const url = RequestHelper.replaceUrlParams(URL_DOWNLOAD_INVENTORY_RELATED_FILE, pathParams);
    return this.downloadFile(0, url);
  };

  private async downloadFile(partnerId: number, url: string) {
    const headers = {
      partnerId,
    };
    try {
      const response = await this.getRequest(url, { headers, responseType: 'blob' });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = this.getFileNameFromHeaders(response.headers);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      this.handleError(e as AxiosError, 'file_download_error');
    }
  }
}

export const apiFileHelper = new ApiFileHelper();
