import { DetailedHTMLProps, HTMLAttributes } from 'react';

export enum ModalSize {
  SMALL,
  MEDIUM,
  LARGE,
}

// TODO namespaces/modules not supported last versions of typescript look for and alternative
// https://github.com/babel/babel/issues/8244

export function getClass(size: ModalSize): string {
  switch (size) {
    case ModalSize.SMALL:
      return 'c-modal__container--small';
    case ModalSize.MEDIUM:
      return 'c-modal__container--medium';
    case ModalSize.LARGE:
      return 'c-modal__container--large';
    default:
      return '';
  }
}

export type Props = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  size?: ModalSize;
  backdropStatic: boolean;
  onClose: () => void;
  backdropClassName?: string;
};
export type State = {};
