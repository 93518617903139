import * as React from 'react';
import { Props } from './user-popover.types';
import './user-popover.css';
import { Popover } from '../../../../../components/popover/popover';
import { translate } from '../../../../../helpers/utils';
import { PopoverDirection } from '../../../../../components/popover';

export const UserPopover: React.FunctionComponent<Props> = ({ name, onClickLogout }) => {
  return (
    <div className="c-user-popover">
      <Popover direction={PopoverDirection.BOTTOM_TRAILING} tooltipType="userDrawer">
        <ul className="c-user-popover__menu u-background--white">
          <li
            className="u-padding-horizontal--s-large u-padding-vertical--t-medium u-font-weight--bold u-margin--gutter--tiny"
            data-qa="username"
          >
            <span>{name}</span>
          </li>
          <li
            className="u-padding-horizontal--s-large u-padding-vertical--t-medium u-margin--gutter--tiny"
            data-qa="logout"
            onClick={onClickLogout}
          >
            {translate('common_logout')}
          </li>
        </ul>
      </Popover>
    </div>
  );
};
