import * as React from 'react';
import { Props } from './button-confirm.types';

export const ButtonConfirm: React.FunctionComponent<Props> = ({
  className,
  onClick,
  label,
  labelConfirmed,
  buttonProps,
}) => {
  const [didConfirm, setDidConfirm] = React.useState(false);

  const handleClick = () => {
    setDidConfirm(true);
    onClick();
  };

  React.useEffect(() => {
    if (didConfirm) {
      const timeoutId = setTimeout(() => setDidConfirm(false), 2000);
      return () => clearTimeout(timeoutId);
    }
    return;
  }, [didConfirm]);

  return (
    <button className={className} type="button" onClick={handleClick} {...buttonProps}>
      {didConfirm ? labelConfirmed : label}
    </button>
  );
};
