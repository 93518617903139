import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ApiAnalyticsHelper from '../../helpers/api/analytics/api-analytics-helper';
import {
  getCampaignFiltersFromLocalStorage,
  getIncludeSlDataFromLocalStorage,
} from '../../helpers/local-storage-helper';
import {
  CampaignTypeCode,
  ColumnDef,
  FilterColumn,
  FilterSet,
  FilterType,
  MetricColumn,
  View,
} from '../../models';
import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_STALE_TIME, ReactQueryKeys } from '../../constants';
import { uniqWith } from 'lodash';
import { useStoredFilterSets } from '../../components/toolbar/custom-filters/hooks/use-stored-filter-sets';
import isEqual from 'lodash.isequal';
import { useContextualFilters } from '../../hooks/use-contextual-filters/use-contextual-filters';

type State = {
  columns: ColumnDef[];
  filterTypes: FilterType[];
  filters: FilterColumn[];
  selectedFilter?: FilterColumn;
  isCustomFiltersModalOpen: boolean;
  selectedBiddingTypes: CampaignTypeCode[];
  storedFilterSets: FilterSet[];
  hideBiddingTypeDropdown: boolean;
  includeSlData: boolean;
};

type Actions = {
  closeCustomFiltersModal: () => void;
  setFiltersData: (columns: ColumnDef[], filters: FilterColumn[]) => void;
  setColumns: (columns: ColumnDef[]) => void;
  addColumns: (columns: MetricColumn[], view: View) => void;
  setFilters: (filters: FilterColumn[]) => void;
  setFilterTypes: (filterTypes: FilterType[]) => void;
  setSelectedBiddingTypes: (selectedBiddingTypes: any) => void;
  setSelectedFilter: (selectedFilter?: FilterColumn) => void;
  setStoredFilterSets: (newFilterSets: FilterSet[]) => void;
  setHideBiddingTypeDropdown: Dispatch<SetStateAction<boolean>>;
  setIncludeSlData: (value: boolean) => void;
};

const FilterContext = createContext<{ state: State; actions: Actions }>({
  state: {} as State,
  actions: {} as Actions,
});

type Props = {
  children: ReactNode;
};

export const FilterContextProvider: FC<Props> = ({ children }) => {
  const [columns, setColumns] = useState<ColumnDef[]>([]);
  const [filterTypes, setFilterTypes] = useState<FilterType[]>([]);
  const [filters, setFilters] = useContextualFilters();
  const [isCustomFiltersModalOpen, setIsCustomFiltersModalOpen] = useState<boolean>(false);
  const [selectedBiddingTypes, setSelectedBiddingTypes] = useState<CampaignTypeCode[]>(
    getCampaignFiltersFromLocalStorage(),
  );
  const [hideBiddingTypeDropdown, setHideBiddingTypeDropdown] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterColumn>();
  const [storedFilterSets, setStoredFilterSets] = useStoredFilterSets();

  const [includeSlData, setIncludeSlData] = useState(getIncludeSlDataFromLocalStorage());

  const closeCustomFiltersModal = useCallback(() => setIsCustomFiltersModalOpen(false), []);

  const setFiltersData = useCallback(
    (newColumns: ColumnDef[], newFilters: FilterColumn[]) => {
      if (!isEqual(filters, newFilters)) setFilters(newFilters);
      setColumns(newColumns);
    },
    [filters],
  );
  const addColumns = useCallback(
    (columnsToAdd: MetricColumn[], view: View) => {
      const columnsNotAlreadyInState = columnsToAdd.filter(c =>
        columns.every(column => column.name !== c.name),
      );
      if (columnsNotAlreadyInState.length === 0) return;
      return addViewColumns(columnsToAdd, view, setColumns);
    },
    [columns],
  );

  const state: State = {
    columns,
    filterTypes,
    filters,
    isCustomFiltersModalOpen,
    selectedBiddingTypes,
    selectedFilter,
    storedFilterSets,
    hideBiddingTypeDropdown,
    includeSlData,
  };

  const actions = {
    closeCustomFiltersModal,
    setFiltersData,
    setColumns,
    addColumns,
    setFilters,
    setFilterTypes,
    setSelectedBiddingTypes,
    setSelectedFilter,
    setStoredFilterSets,
    setHideBiddingTypeDropdown,
    setIncludeSlData,
  };

  const fetchFilterTypes = async () => await ApiAnalyticsHelper.fetchFilterTypes();
  const { data, isError } = useQuery({
    queryKey: [ReactQueryKeys.FILTER_TYPES],
    queryFn: fetchFilterTypes,
    staleTime: REACT_QUERY_STALE_TIME,
  });

  useEffect(() => {
    if (!isError && data) {
      setFilterTypes(data);
    }
  }, [data, isError]);

  return (
    <FilterContext.Provider
      value={{
        state,
        actions,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

function addViewColumns(
  columns: MetricColumn[],
  view: View,
  setState: Dispatch<SetStateAction<ColumnDef[]>>,
) {
  const columnsWithView: MetricColumn[] = columns.map((column: MetricColumn) => ({
    ...column,
    view,
  }));
  setState(
    (prevState: ColumnDef[]) =>
      uniqWith(
        [...prevState, ...columnsWithView],
        (column, nextColumn) => column.name === nextColumn.name,
      ) as ColumnDef[],
  );
}

export const useFilters = (options?: { hideGlobalBiddingTypeDropdown?: boolean }) => {
  const { state, actions } = useContext(FilterContext);

  useEffect(() => {
    if (options?.hideGlobalBiddingTypeDropdown === undefined) return;
    actions.setHideBiddingTypeDropdown(!!options?.hideGlobalBiddingTypeDropdown);
  }, [actions, options]);

  return {
    ...state,
    ...actions,
  };
};

export const useGlobalColumns = (columns: MetricColumn[], view: View) => {
  const { addColumns } = useFilters();
  useEffect(() => addColumns(columns, view), [columns, view, addColumns]);
};
