import { fromTo } from '../../../models';
import { DimensionBreakout } from '../../../models/dimension-breakout';
import {
  BID_MODIFIER_DIMENSIONS,
  BidModifierDimensionName,
  Dimension,
  isBidModifierDimension,
  isDestinationDimension,
} from '../multi-folder-select/multi-folder-select.types';
import { TranslationKey, translate } from '../../../helpers/utils';

export const BM_BREAKOUTS: fromTo<BidModifierDimensionName, number[]> = {
  ttt: [0, 1, 2, 5, 8, 15, 29, 41, 61, 91, 181, 301],
  los: [1, 2, 3, 4, 7, 8, 13, 15],
  dd: [0, 1],
  gs: [1, 2, 3, 6],
};

export const getBmDimensionBreakouts = (): Array<[BidModifierDimensionName, DimensionBreakout[]]> =>
  BID_MODIFIER_DIMENSIONS.map((dimension: BidModifierDimensionName) => [
    dimension,
    BM_BREAKOUTS[dimension].map(breakout => ({
      dimension,
      breakout: {
        label: translate(breakoutTranslationKey(dimension, breakout) as TranslationKey),
        value: breakout,
      },
    })),
  ]);

export const dimensionTranslationKeyPrefix = (dim: Dimension) => {
  if (isBidModifierDimension(dim)) return `analytics_chart_builder_dimension_bm_${dim}`;
  if (isDestinationDimension(dim)) return `analytics_chart_builder_dimension_destination_${dim}`;
  return `analytics_chart_builder_dimension_${dim}`;
};

export const dimensionTitleTranslationKey = (dim: Dimension): TranslationKey =>
  `${dimensionTranslationKeyPrefix(dim)}_title` as TranslationKey;

export const breakoutTranslationKey = (
  dim: BidModifierDimensionName,
  breakout: number,
): TranslationKey => `${dimensionTranslationKeyPrefix(dim)}_breakout_${breakout}` as TranslationKey;
