import * as React from 'react';
import { Props } from './app-web-socket.types';
import { useContext, useEffect } from 'react';
import { NotificationWebsocket } from '../../../helpers/websockets/notification-websocket';
import { AppContext } from '../../context/app-context';
import {
  onReceiveMessage,
  onOpenConnection,
  onConnectionError,
  onConnectionClosed,
} from '../../../services/notification-service';

export const AppWebSocket: React.FunctionComponent<Props> = ({ children }) => {
  const { partner, subPartners } = useContext(AppContext);

  useEffect(() => {
    if (partner) {
      const initNotificationWebSocketConnection = () => {
        const onMessage = (e: MessageEvent) => onReceiveMessage(e, partner, [...subPartners]);
        const ws = NotificationWebsocket.getInstance();
        ws.onOpen(onOpenConnection);
        ws.onMessage(onMessage);
        ws.onError(onConnectionError);
        ws.onClose(onConnectionClosed);
      };
      initNotificationWebSocketConnection();
    }
  }, [partner, subPartners]);

  return <> {children} </>;
};
