import React, { FC, useState } from 'react';
import '../report-tab.css';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Card } from '../../../components/card';
import { DatePickerAlignment, DatePickerDropdown } from '../../../components/date-picker-dropdown';
import { REPORT_TO_KEY } from '../../../constants';
import { TranslationKey, getLastXDays, translate } from '../../../helpers/utils';
import { DateRange } from '../../../models';
import styles from './report-history.module.css';
import { ReportArtifacts } from './report-artifacts';
import { Button } from '../../../core-ui/components/button/button';

const ReportHistoryPage: FC<RouteComponentProps> = props => {
  const { reportName } = props.match.params as { reportName: string };

  const [dateRange, setDateRange] = useState<DateRange>({
    from: getLastXDays(30),
    to: getLastXDays(0),
  });

  return (
    <Card className={styles.reportHistoryPage}>
      <Button
        text={translate('reports_back')}
        variant="tertiary"
        onClick={() => props.history.goBack()}
      />
      <div className={styles.header}>
        <h2>{translate((REPORT_TO_KEY[reportName] || reportName) as TranslationKey)}</h2>
        <DatePickerDropdown
          dateRange={dateRange}
          alignment={DatePickerAlignment.LEFT}
          onUpdateDateRange={setDateRange}
        />
      </div>
      <ReportArtifacts reportName={reportName} dateRange={dateRange} />
    </Card>
  );
};

export const ReportHistory = withRouter(ReportHistoryPage);
