import React from 'react';
import { translate } from '../../helpers/utils';
import { Button } from '../../core-ui/components/button/button';

type Props = {
  message: string | React.ReactNode;
  onClick: () => void;
};

export default function ButtonRetry({ message, onClick }: Props) {
  return (
    <div className="u-display--flex u-flex-direction--column u-margin-bottom--huge u-align-items--center u-height--100 u-justify-content--center">
      <div className="u-color--juri-lighter position-center center">{message}</div>
      <Button onClick={onClick} variant="tertiary" text={translate('common_retry')} />
    </div>
  );
}
