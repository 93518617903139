import { FC } from 'react';
import styles from './property-groups-filters.module.css';
import { PropertyGroupsFilter } from './property-groups-filter';

export type PropertyGroupFilters = {
  propertyGroupIds: number[];
};

export const PropertyGroupsFilters: FC<{
  filters: PropertyGroupFilters;
  setFilters: (values: PropertyGroupFilters) => void;
}> = ({ filters, setFilters }) => {
  return (
    <section className={styles.propertyGroupsFilters}>
      <PropertyGroupsFilter
        selectedIds={filters.propertyGroupIds}
        onApply={ids => setFilters({ propertyGroupIds: ids })}
      />
    </section>
  );
};
