import ApiAnalyticsHelper from '../../../helpers/api/analytics/api-analytics-helper';
import { useQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_RETRY_PREDICATE,
  REACT_QUERY_STALE_TIME,
  ReactQueryKeys,
} from '../../../constants';
import { useSelectedSubPartners } from '../../../hooks/use-selected-partner';
import { PropertyPartnerName } from '../../../helpers/api/analytics/types';

export const usePropertiesData = <T = PropertyPartnerName>(
  propertyIds: string[],
  mapper: (ppn: PropertyPartnerName) => T = ppn => ppn as unknown as T,
) => {
  const subPartners = useSelectedSubPartners();
  const partnerIds = subPartners.map(({ partnerId }) => partnerId);

  const { data, isError, isLoading } = useQuery({
    queryKey: [ReactQueryKeys.PROPERTIES, propertyIds.join()],
    queryFn: () =>
      ApiAnalyticsHelper.fetchPropertiesById(partnerIds, propertyIds).then(properties => {
        return properties.map(i => mapper(i));
      }),
    staleTime: REACT_QUERY_STALE_TIME,
    retry: REACT_QUERY_RETRY_PREDICATE,
  });
  return {
    data,
    isError,
    isLoading,
  };
};
