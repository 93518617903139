import * as React from 'react';
import { Props } from './metrics-picker.types';
import './metrics-picker.css';
import { Circle } from '../../../../components/circle';
import { createRef } from 'react';
import { useOnClickOutside } from '../../../../hooks/use-on-click-outside';
import { Menu, MenuDirection } from '../../../../components/menu';
import { InputIcon } from '../../../../components/input-icon/input-icon';
import SearchLineDarkIcon from 'tcp-react-icons/lib/SearchLineDarkIcon';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import { translate } from '../../../../helpers/utils';
import iconTick from '../../dashboard-header/dashboard-dropdown/icn-tick.svg';
import ArrowIcon from 'tcp-react-icons/lib/ArrowIcon';

export const MetricsPicker: React.FunctionComponent<Props> = ({
  id,
  color,
  metrics,
  onSelectMetric,
}) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const ref = createRef<HTMLDivElement>();
  const [filteredMetrics, setFilteredMetrics] = React.useState(
    metrics.sort((a, b) => a.name.localeCompare(b.name)),
  );
  const [searchText, setSearchText] = React.useState('');
  useOnClickOutside(ref, () => {
    setIsOpened(false);
    onChangeSearch('');
  });

  React.useEffect(() => {
    setFilteredMetrics(metrics);
  }, [metrics]);

  const selectItem = (position: number) => {
    onSelectMetric(filteredMetrics[position].key);
    onChangeSearch('');
    setIsOpened(false);
  };

  const onChangeSearch = (text: string) => {
    setSearchText(text);
    const textLocalLowercase = text.toLocaleLowerCase();
    setFilteredMetrics(
      metrics.filter(metricTmp => metricTmp.name.toLocaleLowerCase().includes(textLocalLowercase)),
    );
  };

  return (
    <div data-testid="metrics-picker" ref={ref}>
      <div
        className="u-display--flex u-align-items--center c-metrics-picker u-padding-left--medium u-cursor--pointer u-height--36px u-padding-right--medium c-metrics_picker"
        onClick={() => setIsOpened(!isOpened)}
      >
        <Circle radius={5} color={color} />
        <span className="u-margin-left--gutter--small">
          {metrics.find(tmp => tmp.key === id)?.name}
        </span>
        <ArrowIcon className="u-transform--rotate-90deg u-margin-left--auto" />
      </div>
      <div className="u-position--relative">
        {isOpened && (
          <Menu
            header={{
              html: (
                <InputIcon
                  icon={searchText.length === 0 ? SearchLineDarkIcon : CloseIcon}
                  value={searchText}
                  autoFocus={true}
                  placeholder={translate('timeline_metric_dropdown_search_dashboard')}
                  className="c-input c-dashboard-dropdown__input u-margin-bottom--medium"
                  onChange={event => onChangeSearch(event.target.value)}
                  onClickIcon={() => onChangeSearch('')}
                  onMouseDown={event => {
                    event.stopPropagation();
                  }} // This is needed because the input is inside the container which is draggable
                />
              ),
              extraClasses: 'c-metrics_picker__menu-header',
            }}
            direction={MenuDirection.BottomLeading}
            body={{
              items: filteredMetrics.map(tmp => {
                return (
                  <div key={tmp.key} className="u-display--flex u-width--100">
                    {tmp.name}
                    {tmp.key === id && (
                      <div className="u-margin-left--auto u-color--juri-light">
                        <img
                          src={iconTick}
                          className="u-margin-right--tiny"
                          alt="Tick icon showing selected metric"
                        />
                        {translate('timeline_metric_dropdown_selected')}
                      </div>
                    )}
                  </div>
                );
              }),
              extraClasses: 'c-metrics_picker__menu',
            }}
            defaultEmpty={
              <div className="u-text-align--center u-color--juri-lighter">
                {translate('timeline_metric_dropdown_no_results')}
              </div>
            }
            onSelectItem={selectItem}
          />
        )}
      </div>
    </div>
  );
};
