import * as React from 'react';
import { Props } from './control-panel.types';
import './control-panel.css';
import { MultiSelectionInfo } from './multi-selection-info';
import { SingleSelectionInfo } from './single-selection-info';
import { CpaInputForm } from './cpa-input-form';
import { translate } from '../../../helpers/utils';
import { apiBiddingHelper } from '../../../helpers/api/bidding/api-bidding-helper';
import { NotificationLevel } from '../../../constants';
import { showNotification } from '../../../services/notification-service';
import { useSelectedPartner } from '../../../hooks/use-selected-partner';
import { useConfirmationModal } from '../../../hooks/use-confirmation-modal';
import { useRemainingPushes } from '../../history/history-toolbar/use-remaining-pushes';
import { EventType } from '../../../models';
import iconLockLineDark from '../../../components/hotel-control-panel/icn-lock-line-dark.svg';

export const ControlPanel: React.FunctionComponent<Props> = ({ campaigns, onApply, onClose }) => {
  const { partnerId } = useSelectedPartner();
  const { data } = useRemainingPushes(partnerId);

  function isDailyPushLimitReached(): boolean {
    const remainingPushes = data || {};
    const remainingPushesCpaValue = remainingPushes[EventType.PUSH_CPA_VALUE] || 0;
    return remainingPushesCpaValue === 0;
  }

  const apply = async (newCpaValue: number) => {
    try {
      if (!(await confirm())) return;

      const result = await apiBiddingHelper.applyCpaValueBids(campaigns, partnerId, newCpaValue);
      onApply(newCpaValue, result);
      onClose();
    } catch {
      showNotification({
        message: `${translate('common_fail')} ${translate('analytics_perform_bids_error')}`,
        level: NotificationLevel.ERROR,
      });
    }
  };

  const { confirm } = useConfirmationModal({
    title: translate('bidding_control_panel_modal_title'),
    details: [translate('bidding_control_panel_modal_detail_1')],
    confirmTitle: translate('bidding_control_panel_modal_apply_changes'),
    cancelTitle: translate('common_cancel'),
  });

  function body() {
    if (isDailyPushLimitReached()) {
      return (
        <div className="u-display--flex u-margin-left--huge u-align-items--center">
          <img
            src={iconLockLineDark}
            className="c-panel-control__icn u-margin-right--tiny u-color--juri"
            alt="Lock icon showing bidding is not possible"
          />
          <span
            className="u-color--juri c-panel-control__lock"
            data-testid="disabled-bidding-message"
          >
            {translate('campaigns_control_panel_locked_limit_reached')}
          </span>
        </div>
      );
    }

    return (
      <>
        <div className="u-display--flex u-padding-left--gutter">
          {campaigns.length > 1 ? (
            <MultiSelectionInfo numberOfCampaigns={campaigns.length} onClose={onClose} />
          ) : (
            <SingleSelectionInfo campaign={campaigns[0]} />
          )}
        </div>
        <div className="u-padding-right--gutter">
          <CpaInputForm campaigns={campaigns} onApply={apply} />
        </div>
      </>
    );
  }

  return <section className="c-control-panel u-margin-top--gutter">{body()}</section>;
};
