import React, { useState } from 'react';
import { ReportArtifact } from '../../../helpers/api/reports/types';
import TrackerService from '../../../services/tracker/tracker-service';
import { NotificationLevel, REPORTS_DOWNLOAD_REPORT } from '../../../constants';
import { apiFileHelper } from '../../../helpers/api/api-file-helper';
import { showNotification } from '../../../services/notification-service';
import { formatDateAndTime, translate } from '../../../helpers/utils';
import { useSelectedPartner } from '../../../hooks/use-selected-partner';
import styles from './report-artifact-row.module.css';
import cs from 'classnames';
import { Button } from '../../../core-ui/components/button/button';

export const ReportArtifactRow: React.FunctionComponent<{ reportArtifact: ReportArtifact }> = ({
  reportArtifact,
}) => {
  const partnerId = useSelectedPartner().partnerId;
  const [isDownloading, setIsDownloading] = useState(false);
  const artifactKey = reportArtifact.path.substring(reportArtifact.path.lastIndexOf('/') + 1);

  const downloadReport = async () => {
    setIsDownloading(true);
    try {
      TrackerService.track(REPORTS_DOWNLOAD_REPORT, {
        report_name: artifactKey,
      });
      await apiFileHelper.downloadReportArtifact(reportArtifact.path, partnerId);
    } catch (e) {
      showNotification({
        message: (e as any).message,
        level: NotificationLevel.ERROR,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableCell}>
        <p className={styles.artifactKey}>{artifactKey}</p>
        <p className={styles.timestamp}>
          {translate('reports_report_date')}:{' '}
          {formatDateAndTime(new Date(reportArtifact.createdAt))}
        </p>
      </td>
      <td className={cs(styles.tableCell, styles.cellWithButton)}>
        <Button
          variant="tertiary"
          isLoading={isDownloading}
          text={translate('reports_export_report')}
          icon={{ name: 'download' }}
          onClick={downloadReport}
        />
      </td>
    </tr>
  );
};
