export const DATE_SELECTED_NAME = 'Date selected';
export const ENTERED_DASHBOARD_NAME = 'Entered Dashboard';
export const ENTERED_ANALYTICS_NAME = 'Entered Analytics';
export const ENTERED_RESOURCE_CENTER_NAME = 'Entered Resource Center';
export const ENTERED_REPORTS_NAME = 'Entered Reports page';
export const ENTERED_SPONSORED_NAME = 'Entered Sponsored page';
export const ENTERED_SPONSORED_ANALYTICS_NAME = 'Entered Sponsored Analytics page';
export const ENTERED_SPONSORED_CAMPAIGNS_NAME = 'Entered Sponsored Campaigns page';
export const ENTERED_CAMPAIGN_NAME = 'Entered Campaign page';
export const ENTERED_HISTORY_NAME = 'Entered History page';
export const ENTERED_ADMIN_NAME = 'Entered Admin page';

export const CLICK_SPONSORED_NAME = 'Click Sponsored menu item';

export const LOGGED_IN_NAME = 'Logged in';

export interface DateSelected {
  calendar: boolean;
  manual: boolean;
  predefined: boolean;
  num_days: number;
  predefined_name: string;
  adv_type: number;
  partner_id: number;
  level: number;
}

export interface EnteredTab {
  previous_tab?: string;
}
