import { ErrorRowValidationRule } from '../row-validation-rule';

export class ValidLocaleCodeRule extends ErrorRowValidationRule {
  constructor(private readonly fieldName: string) {
    super();
  }

  getError(row: Record<string, string>): string | null {
    const localeCode = row[this.fieldName];
    if (!localeCode) return `Field ${this.fieldName} is required`;
    if (!isValidLocaleCode(localeCode))
      return `Field ${this.fieldName} has invalid locale code: ${localeCode}`;
    return null;
  }
}

export function isValidLocaleCode(localeCode: string): boolean {
  if (localeCode.length !== 2) return false;
  return LOCALE_CODES.has(localeCode.toUpperCase());
}

const LOCALE_CODES = new Set([
  'AA',
  'AE',
  'AR',
  'AT',
  'AU',
  'BE',
  'BG',
  'BR',
  'CA',
  'CH',
  'CL',
  'CN',
  'CO',
  'CZ',
  'DE',
  'DK',
  'EC',
  'ES',
  'FI',
  'FR',
  'GR',
  'HK',
  'HR',
  'HU',
  'ID',
  'IE',
  'IL',
  'IN',
  'IT',
  'JP',
  'KR',
  'MX',
  'MY',
  'NG',
  'NL',
  'NO',
  'NZ',
  'PE',
  'PH',
  'PL',
  'PT',
  'RO',
  'RS',
  'RU',
  'SE',
  'SG',
  'SI',
  'SK',
  'TH',
  'TR',
  'TW',
  'UK',
  'US',
  'UY',
  'VN',
  'ZA',
]);
