import React, { FC, useState } from 'react';
import { PropertyGroupFilters, PropertyGroupsFilters } from './filters/property-groups-filters';
import { ColumnOnboardingTooltip, Grid } from '../../../components/grid';
import { GRID_VIEW_CONFIGURATION } from '../../../constants';
import { usePropertyGroupsView } from './use-property-groups-view';
import { usePropertyGroups } from '../../../hooks/use-property-groups';
import { ResultsNotFound } from '../../../components/results-not-found';
import { PopoverDirection } from '../../../components/popover';
import { translate } from '../../../helpers/utils';

export const PropertyGroupsView: FC = () => {
  const allPropertyGroupIds = usePropertyGroups().map(({ id }) => id);
  const [filters, setFilters] = useState<PropertyGroupFilters>({
    propertyGroupIds: allPropertyGroupIds,
  });
  const { isLoading, columns, rows, refetch } = usePropertyGroupsView(filters);

  function resetFilters() {
    setFilters({ propertyGroupIds: allPropertyGroupIds });
  }

  const onboardingTooltips: ColumnOnboardingTooltip[] = [
    {
      column: columns[0],
      position: PopoverDirection.TRAILING_TOP,
      text: translate('property_group_aliases_tooltip'),
    },
  ];

  return (
    <div className="u-padding--gutter">
      <PropertyGroupsFilters filters={filters} setFilters={setFilters} />
      <div className="u-margin-top--gutter">
        <Grid
          columnOnboardingTooltips={onboardingTooltips}
          numStickyColumns={1}
          columns={columns}
          configuration={GRID_VIEW_CONFIGURATION}
          isLoading={isLoading}
          rows={rows}
          resultsNotFound={
            <ResultsNotFound
              isButtonEnabled={true}
              onClick={resetFilters}
              retry={() => void refetch()}
            />
          }
          isAccordionTable
        />
      </div>
    </div>
  );
};
