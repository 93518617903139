import React, { FC } from 'react';
import styles from './sponsored-analytics.module.css';
import { View } from '../../../models';
import { translate } from '../../../helpers/utils';
import { Card } from '../../../components/card';
import { Tabs } from '../../../components/tabs';
import { SponsoredHotelView } from './sponsored-hotel-view';
import { SponsoredPosView } from './sponsored-pos-view';
import { SponsoredTimeView } from './sponsored-time-view';
import { TRACK_NAME_SL_ANALYTICS_TABS, QUERY_PARAM_ACTIVE_TAB } from '../../../constants';
import TrackerService from '../../../services/tracker/tracker-service';
import { Tab } from '../../../components/tabs';
import { useQueryParam } from '../../../hooks/use-query-param';

export const DEFAULT_SPONSORED_ANALYTICS_TAB_KEY = String(View.SPONSORED_HOTEL);

export const SponsoredAnalytics: FC = () => {
  const [activeTabKey, setActiveTabKey] = useQueryParam(QUERY_PARAM_ACTIVE_TAB);

  function changeTab(tab: Tab) {
    setActiveTabKey(tab.key);
    TrackerService.trackTabChange(tab, TRACK_NAME_SL_ANALYTICS_TABS);
  }

  return (
    <main className={styles.main}>
      <Card>
        <Tabs
          activeTabKey={activeTabKey ?? String(View.SPONSORED_HOTEL)}
          leading={<p>{translate('sponsored_home_dimensions')}</p>}
          onTabChange={changeTab}
          tabs={[
            {
              key: String(View.SPONSORED_HOTEL),
              label: translate('sponsored_home_hotel_view'),
              content: <SponsoredHotelView />,
            },
            {
              key: String(View.SPONSORED_POS),
              label: translate('sponsored_home_pos_view'),
              content: <SponsoredPosView />,
            },
            {
              key: String(View.SPONSORED_TIME),
              label: translate('sponsored_home_time_view'),
              content: <SponsoredTimeView />,
            },
          ]}
        />
      </Card>
    </main>
  );
};
