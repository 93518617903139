import React, { FC } from 'react';
import { DimensionValueDropdown, SelectionRow } from '../../../components/dimension-value-dropdown';
import { translate } from '../../../helpers/utils';
import { dimensionTitleTranslationKey } from '../bid-modifier-dimension-menu/bm-dimension-breakouts';
import { DEFAULT_OPTIONS_LIMIT } from '../../../components/checkbox-select';
import ApiAnalyticsHelper from '../../../helpers/api/analytics/api-analytics-helper';
import { DestinationDimensionName } from '../multi-folder-select/multi-folder-select.types';
import { Destination, DESTINATION_COLUMNS } from '../../../helpers/api/analytics/types';
import { DimensionValueLabels, fromTo } from '../../../models';
import { useQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_RETRY_PREDICATE,
  REACT_QUERY_STALE_TIME,
  ReactQueryKeys,
} from '../../../constants';
import { useSelectedSubPartners } from '../../../hooks/use-selected-partner';
import { useSelectedLocales } from '../../../hooks/use-selected-locales/use-selected-locales';

type Props = {
  destinationDimension: DestinationDimensionName;
  handleApply: (selectedOptions: DimensionValueLabels[]) => void;
  initialSelection: string[];
};

export const DestinationDimensionSelector: FC<Props> = ({
  destinationDimension,
  handleApply,
  initialSelection,
}) => {
  const localeCodes = useSelectedLocales();
  const subPartners = useSelectedSubPartners();
  const partnerIds = subPartners.map(({ partnerId }) => partnerId);
  const { data: defaultDestinations } = useQuery({
    queryKey: [
      ReactQueryKeys.DESTINATION,
      destinationDimension,
      partnerIds.join(),
      localeCodes.join(),
    ],
    queryFn: () => defaultDestinationsFn[destinationDimension](),
    staleTime: REACT_QUERY_STALE_TIME,
    retry: REACT_QUERY_RETRY_PREDICATE,
  });

  const defaultDestinationsFn: fromTo<
    DestinationDimensionName,
    () => Promise<Destination[] | null>
  > = {
    country: () => Promise.resolve(null),
    region: () => Promise.resolve(null),
    city: () => ApiAnalyticsHelper.fetchTopCities(partnerIds, localeCodes),
  };

  const applyFilter = (selectedOptions: SelectionRow[]) =>
    handleApply(
      selectedOptions.map(({ value }) =>
        parseDestinationOption(String(value), destinationDimension),
      ),
    );

  const defaultOptions = defaultDestinations
    ? defaultDestinations.map(row => createOption(row, destinationDimension))
    : defaultDestinations;
  return (
    <DimensionValueDropdown
      title={translate(dimensionTitleTranslationKey(destinationDimension))}
      selectionLimit={DEFAULT_OPTIONS_LIMIT}
      selectedValues={initialSelection}
      defaultOptions={defaultOptions}
      loadOptions={{
        byId: () => ({
          data: initialSelection.map(entry => parseDestinationOption(entry, destinationDimension)),
          isLoading: false,
          isError: false,
        }),
        byName: inputValue =>
          ApiAnalyticsHelper.fetchDestinations(partnerIds, inputValue, destinationDimension).then(
            response => response.map(row => createOption(row, destinationDimension)),
          ),
      }}
      applyFilter={applyFilter}
    />
  );
};

const parseDestinationOption = (
  serializedDestination: string,
  destinationType: DestinationDimensionName,
) => {
  const destination = parseDestination(serializedDestination);
  return createOption(destination, destinationType);
};

const destinationComponentSeparator = ' · ';
export const parseDestination = (serializedDestination: string): Destination => {
  const [country, region, city] = serializedDestination
    .split(destinationComponentSeparator)
    .reverse();
  return {
    country,
    region,
    city,
  };
};

const stringifyDestination = (
  destination: Partial<Destination>,
  fields: Array<keyof Destination>,
) => fields.map(field => destination[field]).join(destinationComponentSeparator);

const createOption = (destination: Destination, destinationType: DestinationDimensionName) => {
  const fields = DESTINATION_COLUMNS[destinationType];
  const destinationKey = fields[0];

  return {
    value: stringifyDestination(destination, fields),
    label: destination[destinationKey]!,
    subLabel: stringifyDestination(
      destination,
      fields.filter(field => field !== destinationKey),
    ),
  };
};
