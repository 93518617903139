import { ReactElement } from 'react';
import htmlParse from 'html-react-parser';
import { TRANSLATIONS_DEACTIVATED } from '../../constants';
import { en } from './en';

export type TranslationKey = keyof typeof en;

const logMissingTranslations = localStorage.getItem('logMissingTranslations') === 'true';

const shouldLogMissingTranslations = () => {
  if (logMissingTranslations) return true;
  return process.env.NODE_ENV !== 'production';
};

export function translate(key: TranslationKey, ...args: string[]) {
  if (isTranslationDisabled()) {
    return key;
  }
  const value = (en as any)[key];
  if (!value && shouldLogMissingTranslations()) {
    console.table({ message: 'Missing translation key', key });
  }

  let translationValue: string = value || key;
  args.forEach(argTmp => {
    translationValue = translationValue.replace('$$', argTmp);
  });

  return translationValue;
}

export const translateHtml: TranslateHtml = (key: string) => {
  if (isTranslationDisabled()) {
    return key;
  }
  return htmlParse((en as any)[key] || key);
};

type TranslateHtml = (key: string) => ReactElement<any> | Array<ReactElement<any>> | string;

const isTranslationDisabled = (): boolean => {
  const translationsDeactivatedString = localStorage.getItem(TRANSLATIONS_DEACTIVATED);

  if (translationsDeactivatedString !== null) {
    return Boolean(translationsDeactivatedString);
  } else {
    return false;
  }
};
