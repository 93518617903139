import { Aggregation } from '../../../models';
import { TimeOption } from '../../../constants';
import { fromTo, Partner } from '../../../models';

export type TimeViewProps = {
  partner: Partner;
  subPartners: Partner[];
  locales: string[];
  from: Date;
  to: Date;
};

export const AGGREGATION_TIME_OPTION: fromTo<
  TimeOption,
  Aggregation.TIME_DAY | Aggregation.TIME_WEEK | Aggregation.TIME_MONTH | Aggregation.TIME_QUARTER
> = {
  [TimeOption.DAY]: Aggregation.TIME_DAY,
  [TimeOption.WEEK]: Aggregation.TIME_WEEK,
  [TimeOption.MONTH]: Aggregation.TIME_MONTH,
  [TimeOption.QUARTER]: Aggregation.TIME_QUARTER,
};
