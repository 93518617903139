import React from 'react';
import { Button } from '../../../components/button';
import { FullscreenModal } from '../../../components/fullscreen-modal';
import { translate } from '../../../helpers/utils';

type Props = {
  onDiscard: () => void;
  onContinue: () => void;
  onSaveAs: () => void;
  onSave: () => void;
  isSaved: boolean;
};

export const UnsavedChangesModal = ({
  onDiscard,
  onContinue,
  onSaveAs,
  onSave,
  isSaved,
}: Props) => {
  return (
    <FullscreenModal
      title={translate('analytics_chart_builder_unsaved_changes_modal_title')}
      onClose={onContinue}
      containerStyle="c-min-width-500"
    >
      <p>{translate('analytics_chart_builder_unsaved_changes_modal_body')}</p>
      <div className="u-display--flex u-justify-content--space-between u-margin-top--gutter u-padding-top--small">
        <Button
          className="e-button--tertiary c-red-tertiary-button"
          message={translate('analytics_chart_builder_unsaved_changes_modal_discard')}
          isLoading={false}
          onClick={onDiscard}
          disabled={false}
        />
        <div className="u-display--flex">
          <Button
            className="e-button--tertiary u-margin-right--gutter"
            message={translate('analytics_chart_builder_unsaved_changes_modal_continue')}
            isLoading={false}
            onClick={onContinue}
            disabled={false}
          />
          {isSaved ? (
            <Button
              className="u-margin-right--gutter"
              message={translate('analytics_chart_builder_unsaved_changes_modal_save')}
              loadingMessage={
                translate('analytics_chart_builder_unsaved_changes_modal_saving') + '...'
              }
              onClick={onSave}
            />
          ) : (
            <Button
              message={translate('analytics_chart_builder_unsaved_changes_modal_save_as') + '...'}
              loadingMessage={
                translate('analytics_chart_builder_unsaved_changes_modal_saving') + '...'
              }
              isLoading={false}
              onClick={onSaveAs}
            />
          )}
        </div>
      </div>
    </FullscreenModal>
  );
};
