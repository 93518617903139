import { useCallback, useRef, useState } from 'react';
import { AsyncSelectDropdownProps, Props, SelectOption } from './select-dropdown.types';
import Select, { ClassNamesConfig, GroupBase } from 'react-select';
import cn from 'classnames';
import styles from './select-dropdown.module.css';
import AsyncSelect from 'react-select/async';
import { allCustomComponents } from './all-custom-components';
import { debounce } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

export const SelectDropdown = <T extends any>(props: Props<T>) => {
  const {
    options,
    className,
    disabled,
    onChange,
    defaultValue = null,
    required,
    placeholder = 'Please select',
    isSearchable = false,
    noOptionsMessage = 'No results were found',
    resetOnSelect = true,
    isAsync = false,
    loadOptions,
    allowFreeSearch = false,
  } = props;
  const [selectedValue, setSelectedValue] = useState<SelectOption<T> | null>(defaultValue);
  const [tempOptions, setTempOptions] = useState<SelectOption<T>[]>(options ?? []);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleChange = (selectedOption: SelectOption<T> | null) => {
    setSelectedValue(selectedOption);
    onChange?.(selectedOption);

    if (resetOnSelect || isAsync) {
      setSelectedValue(null);
    }
  };
  const customComponent = isSearchable
    ? {
        DropdownIndicator: allCustomComponents.DropdownIndicator,
        Input: allCustomComponents.Input,
      }
    : undefined;

  const handleMenuClose = useCallback(() => {
    if (selectRef.current) {
      const input = selectRef.current.querySelector('.react-select-input');
      if (input) {
        (input as HTMLInputElement | HTMLSelectElement).blur();
      }
    }
  }, [selectRef.current]);

  const handleCreate = (inputValue: string) => {
    const newOption = { value: inputValue as T, label: inputValue };
    setSelectedValue(newOption);
    setTempOptions([newOption, ...tempOptions]);
  };
  const commonProps = {
    value: selectedValue,
    onChange: handleChange,
    onMenuClose: handleMenuClose,
    components: customComponent,
    name: 'selectDropdown',
    isDisabled: disabled,
    classNamePrefix: prefix,
    options: tempOptions,
    isLoading: options === undefined,
    className: cn(className),
    isClearable: true,
    isSearchable: isSearchable,
    required: required,
    placeholder: placeholder,
    openMenuOnClick: !isSearchable,
    noOptionsMessage: () => noOptionsMessage,
    classNames: selectDropdownClassNames,
    backspaceRemovesValue: true,
  };

  const selectDropdown = () => {
    if (isAsync) {
      return (
        <AsyncSelectDropdown
          handleChange={handleChange}
          defaultOptions={options}
          loadOptions={loadOptions}
          placeholder={placeholder}
          disabled={disabled}
          onMenuClose={handleMenuClose}
        />
      );
    }

    if (allowFreeSearch) {
      return (
        <CreatableSelect
          {...commonProps}
          onCreateOption={handleCreate}
          isSearchable
          createOptionPosition="first"
          formatCreateLabel={(inputValue: string) => inputValue}
        />
      );
    }

    return <Select {...commonProps} />;
  };

  return <div ref={selectRef}>{selectDropdown()}</div>;
};

const AsyncSelectDropdown = <T extends any>(props: AsyncSelectDropdownProps<T>) => {
  const {
    handleChange,
    defaultOptions,
    loadOptions,
    placeholder,
    disabled,
    onMenuClose,
    allowFreeSearch,
  } = props;
  const [selectedOption, setSelectedOption] = useState<T | null>(null);

  const getOptions = async (inputValue: string) => {
    if (!loadOptions) return [];
    const response: SelectOption<T>[] = await loadOptions(inputValue);

    if (!response) return [];

    return response;
  };

  const debounceGetOptions = useCallback(
    debounce((inputValue: string, callback: Function) => {
      getOptions(inputValue).then(options => callback(options));
    }, 1000),
    [],
  );

  const handleSelectOption = (option: SelectOption<T>) => {
    handleChange(option);
    setSelectedOption(null);
  };

  const commonAsyncProps = {
    loadOptions: debounceGetOptions,
    defaultOptions: true,
    placeholder,
    isDisabled: disabled,
    classNames: selectDropdownClassNames,
    onMenuClose,
  };

  if (allowFreeSearch) {
    return <AsyncCreatableSelect {...commonAsyncProps} createOptionPosition="first" />;
  }

  return (
    <AsyncSelect
      {...commonAsyncProps}
      // uncomment next line once following issue is fixed https://github.com/JedWatson/react-select/issues/4645
      // cacheOptions
      components={allCustomComponents}
      isLoading={defaultOptions === undefined}
      value={selectedOption}
      openMenuOnClick={defaultOptions !== null}
      onChange={handleSelectOption}
    />
  );
};
const prefix = 'selectDropdown';

const selectDropdownClassNames: ClassNamesConfig<unknown, boolean, GroupBase<unknown>> = {
  container: () => styles.selectDropdownContainer,
  control: ({ isFocused }) =>
    isFocused ? styles.selectDropdownControlFocused : styles.selectDropdownControl,
  indicatorSeparator: () => styles.indicatorSeparator,
  dropdownIndicator: ({ isFocused }) =>
    isFocused ? styles.selectDropdownIndicatorSearchFocused : styles.selectDropdownIndicatorSearch,
  valueContainer: () => styles.selectDropdownValueContainer,
  singleValue: () => styles.selectDropdownSingleValue,
  menu: () => styles.selectDropdownMenu,
  menuList: () => styles.selectDropdownMenuList,
  option: ({ isFocused, isSelected }) =>
    isFocused || isSelected ? styles.selectDropdownOptionFocused : styles.selectDropdownOption,
  placeholder: () => styles.selectDropdownPlaceholder,
  input: () => styles.selectDropdownInput,
  loadingIndicator: () => styles.selectDropdownLoadingIndicator,
};
