import { FC, ReactNode } from 'react';
import {
  InputProps,
  OptionProps,
  components as ReactSelectComponents,
  DropdownIndicatorProps,
} from 'react-select';
import { CustomInputProps, SearchButtonProps } from './select-dropdown.types';
import { Loading } from '../../components/loading';
import { SearchLineDarkIcon } from 'tcp-react-icons';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import styles from './select-dropdown.module.css';

export const allCustomComponents = {
  DropdownIndicator: (searchProps: DropdownIndicatorProps) => (
    <SearchIcons searchProps={searchProps} />
  ),
  Input: (inputProps: InputProps) => <CustomInput inputProps={inputProps} />,
  LoadingMessage: () => <LoadingMessage />,
  Option: (optionProps: OptionProps) => <CustomOption optionProps={optionProps} />,
};

const CustomOption: FC<{ optionProps: OptionProps }> = ({ optionProps }) => {
  const data = optionProps.data as { label: ReactNode } | undefined;

  if (!data || typeof data !== 'object') {
    return null;
  }

  const { label } = data;

  if (typeof label !== 'string') {
    return (
      <ReactSelectComponents.Option {...optionProps}>
        <div className="u-display-flex u-flex-direction--column">{label}</div>
      </ReactSelectComponents.Option>
    );
  }
  return (
    <ReactSelectComponents.Option {...optionProps}>
      <div className="u-color--juri">{label}</div>
    </ReactSelectComponents.Option>
  );
};

const LoadingMessage: FC = () => <Loading className="c-select-dropdown--loading-message" />;

const SearchIcons: FC<SearchButtonProps> = ({ searchProps }) => {
  const {
    hasValue,
    clearValue,
    selectProps: { menuIsOpen },
  } = searchProps;

  const menuIsClosed = !hasValue && !menuIsOpen;
  const menuIsOpenOrHasValue = hasValue || menuIsOpen;
  return (
    <ReactSelectComponents.DropdownIndicator {...searchProps}>
      {menuIsClosed && (
        <SearchLineDarkIcon className="search-icon" onMouseDown={(e: any) => e.stopPropagation()} />
      )}
      {menuIsOpenOrHasValue && (
        <CloseIcon
          className="close-icon"
          onClick={clearValue}
          onMouseDown={(e: any) => e.stopPropagation()}
        />
      )}
    </ReactSelectComponents.DropdownIndicator>
  );
};

const CustomInput: FC<CustomInputProps> = ({ inputProps }) => (
  <div className={styles.selectDropdownCustomInput}>
    <input
      {...inputProps}
      className="react-select-input"
      disabled={inputProps.isDisabled}
      autoFocus
    />
  </div>
);
