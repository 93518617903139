import { UseQueryResult, useQuery } from '@tanstack/react-query';
import ApiHistoryHelper from '../../../helpers/api/history/api-history-helper';
import cronstrue from 'cronstrue';

export function useImportSchedules(
  partnerId: number,
): UseQueryResult<Record<number, string[]>, Error> {
  return useQuery(['import-schedules', partnerId], () => fetchImportSchedules(partnerId));
}

async function fetchImportSchedules(partnerId: number): Promise<Record<number, string[]>> {
  const schedules = await ApiHistoryHelper.fetchImportSchedules(partnerId);
  const result: Record<number, string[]> = {};
  for (const { type, cronSchedule, isDailyFlexible } of schedules) {
    const readableExpression = isDailyFlexible
      ? 'Daily flexible'
      : toHumanReadableExpression(cronSchedule);
    if (result[type]) {
      result[type].push(readableExpression);
    } else {
      result[type] = [readableExpression];
    }
  }

  return result;
}

function toHumanReadableExpression(cronExpression: string): string {
  return cronstrue.toString(cronExpression, {
    use24HourTimeFormat: true,
    verbose: true,
  });
}
