import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Report } from '../../views/reports/report.types';
import { SubscribeToReportModal } from '../../views/reports/subscribe-to-report-modal';

export function useSubscribeToReportModal(
  onClosed: (changeMade: boolean) => void,
): (report: Report) => void {
  const [report, setReport] = useState<Report | undefined>();
  const [showModal, closeModal] = useModal(
    () => <SubscribeToReportModal report={report} onClose={closeIt} />,
    [report],
  );

  function showIt(r: Report) {
    setReport(r);
    showModal();
  }

  function closeIt(changesMade: boolean) {
    closeModal();
    onClosed(changesMade);
  }

  return showIt;
}
