import * as React from 'react';
import { parse } from 'date-fns';
import { getDateTooltip, labelDateFormatter } from '../../../helpers/utils';
import { formatNumber } from '../../../helpers/formater';
import { Props } from './mblu-chart.types';
import { Chart } from '../../../components/chart';
import { DateRange, ExtendedTimelineWidgetProps } from '../../../models';
import { useDateRangePeriod } from '../../../components/date-picker/date-picker.state';

export const MbluChart: React.FunctionComponent<Props> = ({
  columns,
  data,
  columnsColor,
  isFullScreen,
  aggregationPeriod,
}) => {
  const [globalDateRangePeriod] = useDateRangePeriod();

  return (
    <Chart
      variant="bar"
      isFullScreen={isFullScreen}
      data={data}
      columns={columns}
      chartProps={{} as ExtendedTimelineWidgetProps}
      columnsColor={columnsColor}
      toolTipTitle={value =>
        getDateTooltip(data, value, aggregationPeriod, globalDateRangePeriod || ({} as DateRange))
      }
      tickFormatterX={value => labelDateFormatter(parse(String(value)))}
      tickFormatterY={value => formatNumber(value, '%')}
      domain={[0, 1]}
      stackID="a"
      xAxisLabel=""
      leftYAxisLabel=""
      rightYAxisLabel=""
    />
  );
};
