import trackerService from '../../../services/tracker/tracker-service';

export function trackBaseBidFileConverterEvent(
  eventName: string,
  file: File,
  properties: Record<any, any> = {},
) {
  trackerService.trackWithPrefix('base_bid_file_converter', eventName, {
    fileName: file.name,
    fileSize: file.size,
    ...properties,
  });
}
