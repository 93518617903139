import {
  CAMPAIGN_TYPES_KEY,
  DATE_RANGE_PERIOD_KEY,
  FilterStorageKey,
  GLOBAL_ACTIVE_FILTER_SET,
  HIDDEN_CHART_LINES_KEY_PREFIX,
  HIDDEN_COLUMNS_KEY_PREFIX,
  PROFILE_KEY,
  SL_ACTIVE_FILTER_SET,
  VIEW_NAME,
} from '../constants';
import {
  ActiveFilterSet,
  CampaignType,
  CampaignTypeCode,
  DateRangePeriod,
  FilterColumn,
  Profile,
  View,
  getAllCampaignTypes,
} from '../models';
import { parseDateRangePeriod } from './date-range-period';

const DEFAULT_FILTER_STORAGE_KEY = FilterStorageKey.DEFAULT;

export const saveItemToLocalStorage = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const getItem = <T>(key: string): T | undefined => {
  try {
    const value = localStorage.getItem(key);
    if (value === null) return undefined;
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
};
export const deleteItemFromLocalStorage = (key: string): void => localStorage.removeItem(key);
export const clearLocalStorage = (): void => localStorage.clear();

// Dimension filters
export const saveDimensionFiltersToLocalStorage = (
  value: FilterColumn[],
  filterStorageKey: FilterStorageKey = DEFAULT_FILTER_STORAGE_KEY,
) => saveItemToLocalStorage(filterStorageKey, value);
export const getDimensionFiltersFromLocalStorage = (
  filterStorageKey = DEFAULT_FILTER_STORAGE_KEY,
) => {
  return getItem<FilterColumn[]>(filterStorageKey) || [];
};
export const deleteDimensionFiltersFromLocalStorage = (
  filterStorageKey = DEFAULT_FILTER_STORAGE_KEY,
) => saveItemToLocalStorage(filterStorageKey, []);

// Active filter set
export const saveActiveFilterSetToLocalStorage = (
  value: ActiveFilterSet,
  filterStorageKey: FilterStorageKey = DEFAULT_FILTER_STORAGE_KEY,
) =>
  saveItemToLocalStorage(filterStorageKey ? GLOBAL_ACTIVE_FILTER_SET : SL_ACTIVE_FILTER_SET, value);

export const getActiveFilterSetFromLocalStorage = (
  filterStorageKey: FilterStorageKey = DEFAULT_FILTER_STORAGE_KEY,
) =>
  getItem<ActiveFilterSet>(filterStorageKey ? GLOBAL_ACTIVE_FILTER_SET : SL_ACTIVE_FILTER_SET) ||
  '';

// Period
export const savePeriodToLocalStorage = (value: DateRangePeriod) =>
  saveItemToLocalStorage(DATE_RANGE_PERIOD_KEY, value);
export const getPeriodFromLocalStorage = (): DateRangePeriod | undefined => {
  const dateRangePeriod = getItem<DateRangePeriod>(DATE_RANGE_PERIOD_KEY);
  if (!dateRangePeriod) return undefined;
  return parseDateRangePeriod(dateRangePeriod);
};

// Profile
export const saveProfileToLocalStorage = (value: Profile) =>
  saveItemToLocalStorage(PROFILE_KEY, value);
export const getProfileFromLocalStorage = () => getItem<Profile>(PROFILE_KEY);
export const deleteProfileFromLocalStorage = () => deleteItemFromLocalStorage(PROFILE_KEY);

// Campaigns

export const saveCampaignFiltersToLocalStorage = (value: CampaignTypeCode[]) =>
  saveItemToLocalStorage(CAMPAIGN_TYPES_KEY, value);
export const getCampaignFiltersFromLocalStorage = (
  availableCampaigns: readonly CampaignType[] = getAllCampaignTypes(),
) => {
  const allCampaignTypeCodes = availableCampaigns.map(({ code }) => code);
  const selectedCampaignTypeCodes = getItem<CampaignTypeCode[]>(CAMPAIGN_TYPES_KEY);
  if (!selectedCampaignTypeCodes) return allCampaignTypeCodes;
  return selectedCampaignTypeCodes.filter(el => allCampaignTypeCodes.includes(el));
};

export const deleteCampaignFiltersFromLocalStorage = () =>
  deleteItemFromLocalStorage(CAMPAIGN_TYPES_KEY);

const INCLUDE_SL_DATA_KEY = 'includeSlData';
export const saveIncludeSlDataToLocalStorage = (value: boolean) =>
  saveItemToLocalStorage(INCLUDE_SL_DATA_KEY, value);
export const getIncludeSlDataFromLocalStorage = () =>
  getItem<boolean>(INCLUDE_SL_DATA_KEY) || false;

const INCLUDE_NON_PERFORMING_ITEMS_KEY = 'includeNonPerformingItems';
export const getIncludeNonPerformingDataFromLocalStorage = () =>
  getItem<boolean>(INCLUDE_NON_PERFORMING_ITEMS_KEY) || false;
export const saveIncludeNonPerformingDataToLocalStorage = (value: boolean) =>
  saveItemToLocalStorage(INCLUDE_NON_PERFORMING_ITEMS_KEY, value);

// Hidden columns
const initialHiddenColumns: string[] = [];

const getHiddenColumnsByViewKey = (view: View) => `${HIDDEN_COLUMNS_KEY_PREFIX}-${VIEW_NAME[view]}`;
const getHiddenLinesByChartUuid = (uuid: string) => `${HIDDEN_CHART_LINES_KEY_PREFIX}-${uuid}`;

export const saveHiddenColumnsByViewToLocalStorage = (view: View, columnNames: string[]) =>
  saveItemToLocalStorage(getHiddenColumnsByViewKey(view), columnNames);
export const getHiddenColumnsByViewFromLocalStorage = (view: View) =>
  getItem<string[]>(getHiddenColumnsByViewKey(view)) || initialHiddenColumns;
export const deleteHiddenColumnsByViewFromLocalStorage = (view: View) =>
  deleteItemFromLocalStorage(getHiddenColumnsByViewKey(view));
export const saveHiddenLinesToLocaleStorage = (lineNames: string[], uuid: string) =>
  saveItemToLocalStorage(getHiddenLinesByChartUuid(uuid), lineNames);
export const getHiddenLinesFromLocaleStorage = (uuid: string) =>
  getItem<string[]>(getHiddenLinesByChartUuid(uuid));
