import * as React from 'react';
import { Props } from './circle.types';
import './circle.css';

export const Circle: React.FunctionComponent<Props> = ({ radius, color, children }) => {
  return (
    <i
      className="c-circle"
      style={{ height: 2 * radius, width: 2 * radius, backgroundColor: color }}
    >
      {children}
    </i>
  );
};
