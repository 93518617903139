import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { NotificationLevel } from '../../constants';
import { showNotification } from '../../services/notification-service';
import trackerService from '../../services/tracker/tracker-service';
import {
  PropertyGroupAliases,
  URL_PROPERTY_GROUP_ALIASES,
  propertyGroupAliasesQueryKey,
} from './use-property-group-aliases';

type AssignAliasRequest = {
  partnerId: number;
  propertyGroupId: number;
  alias: string | null;
  localeCode: string;
};

export function useUpdateAliasMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (request: AssignAliasRequest) => {
      if (request.alias === null) {
        return await deleteAlias(request);
      }
      return await assignAlias(request);
    },
    {
      onMutate: async request => {
        const queryKey = propertyGroupAliasesQueryKey(request.partnerId);
        await queryClient.cancelQueries({ queryKey });
        const previousAliases = queryClient.getQueryData<PropertyGroupAliases>(queryKey);
        queryClient.setQueryData(queryKey, (old: PropertyGroupAliases) => {
          const newAliases = old ? cloneDeep(old) : {};
          if (!newAliases[request.propertyGroupId]) {
            newAliases[request.propertyGroupId] = {};
          }
          if (request.alias) {
            newAliases[request.propertyGroupId][request.localeCode] = request.alias;
          } else {
            delete newAliases[request.propertyGroupId][request.localeCode];
          }
          return newAliases;
        });
        return { previousAliases, queryKey };
      },
      onError: (err, _, context) => {
        console.error(err);
        queryClient.setQueryData(context!.queryKey, context!.previousAliases);
        showNotification({
          level: NotificationLevel.ERROR,
          message: 'Failed to update property group alias',
        });
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      onSettled: (_, __, ___, context) => {
        queryClient.invalidateQueries({ queryKey: context!.queryKey });
      },
    },
  );
}

async function assignAlias(request: AssignAliasRequest) {
  const result = await axios.post(`${URL_PROPERTY_GROUP_ALIASES}/assign`, request, {
    baseURL: process.env.REACT_APP_URL,
  });
  trackAliasEvent('assigned', request);
  return result;
}

async function deleteAlias(request: AssignAliasRequest) {
  const { propertyGroupId, partnerId, localeCode } = request;
  const result = axios.delete(
    `${URL_PROPERTY_GROUP_ALIASES}/${partnerId}/${propertyGroupId}/${localeCode}`,
    {
      baseURL: process.env.REACT_APP_URL,
    },
  );
  trackAliasEvent('deleted', request);
  return result;
}

const trackAliasEvent = (event: 'assigned' | 'deleted', properties: any) =>
  trackerService.trackWithPrefix('Property Group Alias', event, properties);
