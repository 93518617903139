import { useState } from 'react';
import './widget-title-edit.css';
import { Button } from '../../../core-ui/components/button/button';

export type Props = {
  initialValue?: string;
  onSave(title: string): Promise<void>;
  onCancel(): void;
};

export const WidgetTitleEdit = ({ initialValue, onSave, onCancel }: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState(initialValue || '');

  async function save() {
    try {
      setIsSaving(true);
      await onSave(title);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <div data-testid="widget-title-edit" className="widget-title-edit">
      <input
        value={title}
        disabled={isSaving}
        onChange={e => setTitle(e.target.value)}
        type="text"
        data-testid="title-edit-input"
        autoFocus
        onMouseDown={e => e.stopPropagation()}
      />
      <Button
        disabled={!title.trim() || isSaving}
        text="Save"
        onClick={() => save()}
        data-testid="title-edit-save"
      />
      <Button
        variant="tertiary"
        disabled={isSaving}
        text="Cancel"
        onClick={() => onCancel()}
        data-testid="title-edit-cancel"
      />
    </div>
  );
};
