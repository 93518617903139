import { ColumnDef, FilterColumn, MetricColumn } from '../../../../../models';
import { getColumnKeys } from '../../../../../services/analytics-service';
import { createEmptyFilter } from '../create-filters';

export const isFilterFilled = (filter: FilterColumn): boolean =>
  filter.columnName !== '' &&
  filter.columnLabel !== '' &&
  filter.type !== '' &&
  filter.value !== '';

const doesFilterMatchColumn = (
  filter: FilterColumn,
  columns: ColumnDef[] | MetricColumn[],
): boolean =>
  !!columns.find(
    (column: ColumnDef | MetricColumn) => column.key === filter.columnName && column.isComparable,
  );

const isValueNumeric = (filterValue: string) => !Number.isNaN(Number(filterValue));

const isValidNumber = (filter: FilterColumn, columns: ColumnDef[]): boolean => {
  const columnMatch = columns.find(column => column.key === filter.columnName);
  if (!columnMatch) return false;
  if (columnMatch.type !== 'int' && columnMatch.type !== 'float') return true;
  if (filter.type === 'NOT_IN' || filter.type === 'IN') {
    return filter.value.split(',').every(numValue => isValueNumeric(numValue));
  }
  const numValue = filter.value;
  return isValueNumeric(numValue);
};

const isColumnNotComparable = (filter: FilterColumn, columns: ColumnDef[]): boolean => {
  const nonComparableColumns = columns.filter(column => !column.isComparable);
  const columnKeys = getColumnKeys(nonComparableColumns);
  return columnKeys.includes(filter.columnName);
};

export const isFilterValid = (filter: FilterColumn, columns: ColumnDef[]): boolean => {
  if (!isFilterFilled(filter)) return false;
  if (!doesFilterMatchColumn(filter, columns)) return false;
  if (!isValidNumber(filter, columns)) return false;
  if (isColumnNotComparable(filter, columns)) return false;
  return true;
};

export const hasInvalidFilters = (filters: FilterColumn[], columns: ColumnDef[]): boolean =>
  filters.some(filter => !isFilterValid(filter, columns));

export const getValidFilters = (filters: FilterColumn[], columns: ColumnDef[]): FilterColumn[] => {
  const groupedFilters = getGroupedFilters(filters, columns);
  const validFilters = groupedFilters.filter(filter => isFilterValid(filter, columns));
  return validFilters.length > 0 ? validFilters : [createEmptyFilter()];
};

export const getFilledFilters = (filters: FilterColumn[]): FilterColumn[] =>
  filters.filter(filter => isFilterFilled(filter));

export const areAllFiltersFilled = (filters: FilterColumn[]): boolean =>
  filters.filter(filter => !isFilterFilled(filter)).length === 0;

const getColumnMatchingFilters = (
  filters: FilterColumn[],
  columns: MetricColumn[],
): FilterColumn[] => filters.filter(filter => doesFilterMatchColumn(filter, columns));

export const getGroupedFilters = (
  filters: FilterColumn[],
  columns: MetricColumn[] | ColumnDef[],
): FilterColumn[] =>
  filters.map(filter => {
    const widgetColumn = columns?.find(column => column.name === filter.columnLabel);
    if (widgetColumn) return { ...filter, columnName: widgetColumn.key };
    return filter;
  });

export const getMatchingFilters = (
  filters: FilterColumn[],
  columns: MetricColumn[] | ColumnDef[],
): FilterColumn[] => {
  const groupedFilters = getGroupedFilters(filters, columns);
  return getColumnMatchingFilters(groupedFilters, columns);
};
