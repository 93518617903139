import { ErrorRowValidationRule } from '../row-validation-rule';

type Options = {
  fieldName: string;
  values: string[];
  ignoreCase?: boolean;
};

export class OneOfValuesRule extends ErrorRowValidationRule {
  constructor(private readonly options: Options) {
    super();
  }

  getError(row: Record<string, string>): string | null {
    const { fieldName, ignoreCase, values } = this.options;
    const validValues = ignoreCase ? values.map(v => v.toLowerCase()) : values;
    const valueToBeChecked = ignoreCase ? row[fieldName]?.toLowerCase() : row[fieldName];
    if (!validValues.includes(valueToBeChecked)) {
      return `Invalid value for ${fieldName}. Allowed values are: ${values.join(', ')}`;
    }
    return null;
  }
}
