import axios from 'axios';
import {
  UPLOADED_BIDS,
  UPLOADED_BID_MODIFIERS,
  UPLOADED_CAMPAIGN_ALLOCATION,
  UPLOADED_CPA_BID_MODIFIERS,
  UPLOADED_CPA_VALUE,
  UPLOADED_INVENTORY,
  UPLOADED_PROPERTY_GROUP,
  UPLOADED_SL_BIDS,
  UPLOADED_SL_BID_MODIFIERS,
  UPLOADED_SL_BUDGET_CAP,
} from '../constants';
import { apiFileHelper } from '../helpers/api/api-file-helper';
import { extractFileHeaders, readFile } from '../helpers/files';
import { BidFileType, Locale, fromTo } from '../models';
import TrackerService from './tracker/tracker-service';

const UPLOAD_BIDDING_FILE_URL = '/bidding/schedule-file';
const UPLOAD_BIDDING_MODIFIER_FILE_URL = '/bidding/schedule-bid-modifiers-file';
const UPLOAD_PROPERTY_GROUP_FILE_URL = '/bidding/schedule-property-group-id-file';
const UPLOAD_CAMPAIGN_ALLOCATION_FILE_URL = '/bidding/schedule-campaign-allocation-file';
const UPLOAD_CPA_VALUE_FILE_URL = '/bidding/schedule-cpa-value-file';
const UPLOAD_SL_BASE_BIDS_FILE_URL = '/bidding/schedule-sl-bid-file';
const UPLOAD_SL_BIDDING_MODIFIER_FILE_URL = '/bidding/schedule-bid-modifiers-sl-file';
const UPLOAD_CPA_BIDDING_MODIFIER_FILE_URL = '/bidding/schedule-bid-modifiers-cpa-file';
const UPLOAD_SL_BUDGET_CAP_FILE_URL = '/bidding/schedule-sl-budget-cap-file';
const UPLOAD_INVENTORY_FILE_URL = '/new-inventory';

const UPLOAD_BIDDING_TYPE: fromTo<
  BidFileType,
  {
    backendUrl: string;
    eventAction: string;
  }
> = {
  [BidFileType.BASE_BID]: {
    backendUrl: UPLOAD_BIDDING_FILE_URL,
    eventAction: UPLOADED_BIDS,
  },
  [BidFileType.BID_MODIFIER]: {
    backendUrl: UPLOAD_BIDDING_MODIFIER_FILE_URL,
    eventAction: UPLOADED_BID_MODIFIERS,
  },
  [BidFileType.PROPERTY_GROUPS]: {
    backendUrl: UPLOAD_PROPERTY_GROUP_FILE_URL,
    eventAction: UPLOADED_PROPERTY_GROUP,
  },
  [BidFileType.CAMPAIGN_ALLOCATION]: {
    backendUrl: UPLOAD_CAMPAIGN_ALLOCATION_FILE_URL,
    eventAction: UPLOADED_CAMPAIGN_ALLOCATION,
  },
  [BidFileType.CPA]: {
    backendUrl: UPLOAD_CPA_VALUE_FILE_URL,
    eventAction: UPLOADED_CPA_VALUE,
  },
  [BidFileType.SL_BASE_BID]: {
    backendUrl: UPLOAD_SL_BASE_BIDS_FILE_URL,
    eventAction: UPLOADED_SL_BIDS,
  },
  [BidFileType.SL_BID_MODIFIER]: {
    backendUrl: UPLOAD_SL_BIDDING_MODIFIER_FILE_URL,
    eventAction: UPLOADED_SL_BID_MODIFIERS,
  },
  [BidFileType.SL_BUDGET_CAP]: {
    backendUrl: UPLOAD_SL_BUDGET_CAP_FILE_URL,
    eventAction: UPLOADED_SL_BUDGET_CAP,
  },
  [BidFileType.INVENTORY]: {
    backendUrl: UPLOAD_INVENTORY_FILE_URL,
    eventAction: UPLOADED_INVENTORY,
  },
  [BidFileType.CPA_BID_MODIFIER]: {
    backendUrl: UPLOAD_CPA_BIDDING_MODIFIER_FILE_URL,
    eventAction: UPLOADED_CPA_BID_MODIFIERS,
  },
};

export const uploadInventoryFile = async (
  file: File,
  partnerId: number,
  onProgress: (progress: number) => void,
) => {
  try {
    await axios.post(`${UPLOAD_INVENTORY_FILE_URL}/${partnerId}`, file, {
      baseURL: process.env.REACT_APP_URL,
      headers: { 'Content-Type': 'text/csv', 'Content-Length': file.size },
      onUploadProgress: progressEvent => {
        const progress = Math.round((progressEvent.loaded / (progressEvent.total ?? 0)) * 100);
        onProgress(progress);
      },
    });
    TrackerService.track(UPLOAD_BIDDING_TYPE[BidFileType.INVENTORY].eventAction, {});
  } catch (err) {
    handleFileUploadError(err);
  }
};

export async function uploadFileByType(option: BidFileType, partnerId: number, file: File) {
  const { delimiter } = await extractLocalesFromFileByType(file, option);

  const backendUrl = UPLOAD_BIDDING_TYPE[option].backendUrl;
  let result;
  try {
    result = await apiFileHelper.uploadFile(partnerId, file, backendUrl, delimiter);
    TrackerService.track(UPLOAD_BIDDING_TYPE[option].eventAction, {});
  } catch (err) {
    handleFileUploadError(err);
  }

  return result;
}

function handleFileUploadError(err: any) {
  const e = err as any;
  if (e.response.status === 413) throw Error('bidding_notification_upload_file_too_big_failure');
  throw Error('bidding_notification_upload_file_failure');
}

export async function extractLocalesFromFileByType(file: File, option: BidFileType) {
  const BID_MODIFIER_HEADER_SIZE = [4, 5];
  const PROPERTY_GROUP_HEADER_SIZE = 3;
  let headersResponse: { data: string[]; delimiter: string } = await extractFileHeaders(file);
  let auxLocales: string[] = [];
  try {
    switch (option) {
      case BidFileType.BASE_BID:
        auxLocales = headersResponse.data.slice(1, headersResponse.data.length);
        break;
      case BidFileType.INVENTORY:
        auxLocales = [];
        break;
      case BidFileType.BID_MODIFIER:
      case BidFileType.SL_BID_MODIFIER:
      case BidFileType.SL_BASE_BID:
      case BidFileType.SL_BUDGET_CAP:

      case BidFileType.PROPERTY_GROUPS: {
        const locales = new Set<string>();
        const { data, delimiter } = await readFile(file);

        if (
          (option === BidFileType.BID_MODIFIER &&
            !BID_MODIFIER_HEADER_SIZE.includes(headersResponse.data.length)) ||
          (option === BidFileType.PROPERTY_GROUPS &&
            headersResponse.data.length !== PROPERTY_GROUP_HEADER_SIZE) ||
          (option === BidFileType.SL_BASE_BID &&
            headersResponse.data.length !== PROPERTY_GROUP_HEADER_SIZE) ||
          (option === BidFileType.SL_BUDGET_CAP &&
            headersResponse.data.length !== PROPERTY_GROUP_HEADER_SIZE)
        )
          throw Error('bidding_notification_upload_file_failure_body');

        data.forEach(row => locales.add(row[0]));
        headersResponse = { data: Array.from(locales).slice(1, locales.size), delimiter };
        auxLocales = headersResponse.data;

        break;
      }
    }
  } catch (e) {
    const error = e as Error;
    throw Error(
      error.message === null ? 'bidding_notification_upload_file_failure' : error.message,
    );
  }

  return {
    locales: auxLocales,
    delimiter: headersResponse.delimiter,
  };
}

export const validateLocales = async (
  file: File,
  fileType: BidFileType,
  allowedLocales: Locale[],
  errorMessage: string,
): Promise<void> => {
  const { locales: fileLocales } = await extractLocalesFromFileByType(file, fileType);

  if (!allowedLocales.filter(locale => fileLocales.includes(locale.localeCode))) {
    // TODO modify translation keys to display invalid locales
    throw Error(errorMessage);
  }
};
