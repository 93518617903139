import * as React from 'react';
import { NOTIFICATION_TYPE_TO_OPTION_KEY_BASE, Props } from './notification-settings-modal.types';
import './notification-settings-modal.css';
import { ScrollableModal } from '../../../../../components/scrollable-modal';
import { TranslationKey, translate } from '../../../../../helpers/utils';
import ApiNotificationsHelper from '../../../../../helpers/api/notifications/api-notifications-helper';
import { useEffect } from 'react';
import { NotificationSettings } from '../../../../../models';
import { Switch } from '../../../../../components/switch';
import { Loading } from '../../../../../components/loading';
import TrackerService from '../../../../../services/tracker/tracker-service';
import {
  NOTIFICATIONS_OPEN_SETTINGS,
  NOTIFICATIONS_UPDATE_SETTINGS,
} from '../../../../../constants';

export const NotificationSettingsModal: React.FunctionComponent<Props> = ({
  onClose,
  onErrorFetchingNotifications,
}) => {
  const [settings, setSettings] = React.useState<NotificationSettings[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      TrackerService.track(NOTIFICATIONS_OPEN_SETTINGS);
      try {
        setIsLoading(true);
        setSettings(await ApiNotificationsHelper.fetchSettings());
        setIsLoading(false);
      } catch (e) {
        onErrorFetchingNotifications();
      }
    };
    fetchSettings();
  }, [onErrorFetchingNotifications]);

  const updateSettings = (i: number, isActive: boolean): void => {
    TrackerService.track(NOTIFICATIONS_UPDATE_SETTINGS);
    const nextSettings = settings.slice();

    nextSettings[i].isActive = isActive;

    setSettings(nextSettings);
  };

  const save = () => onClose(settings);

  return (
    <ScrollableModal
      title={translate('notifications_settings_title')}
      onClose={save}
      footer={{
        isTopBorderHidden: true,
      }}
    >
      {isLoading ? (
        <Loading isWhite={true} isDark={true} className="u-padding--gutter" />
      ) : (
        <div className="u-margin-left--large u-margin-right--large">
          <table className="c-notification-settings-modal">
            <tbody>
              {settings.map((settingTmp, i) => (
                <tr key={i}>
                  <td>
                    <span>
                      {translate(
                        (NOTIFICATION_TYPE_TO_OPTION_KEY_BASE[settingTmp.notificationType] +
                          settingTmp.notificationLevel.toLowerCase()) as TranslationKey,
                      )}
                    </span>
                  </td>
                  <td>
                    <div className="u-display--flex u-align-items--center u-margin-left--auto">
                      <span className="u-padding-right--gutter">
                        {translate('notifications_settings_receive_email')}
                      </span>
                      <Switch
                        isActive={settingTmp.isActive}
                        onChange={isActive => updateSettings(i, isActive)}
                      />
                      <span className="u-padding-left--gutter">
                        {settingTmp.isActive ? translate('common_on') : translate('common_off')}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </ScrollableModal>
  );
};
