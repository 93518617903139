import { BM_BREAKOUTS } from '../../../../views/custom-dashboard/bid-modifier-dimension-menu/bm-dimension-breakouts';
import { ErrorRowValidationRule } from '../row-validation-rule';

export class ValidBidModifierBreakoutRule extends ErrorRowValidationRule {
  constructor(private readonly options: { breakoutFieldName: string; dimensionFieldName: string }) {
    super();
  }
  getError(row: Record<string, string>): string | null {
    const { dimensionFieldName, breakoutFieldName } = this.options;
    const breakout = row[breakoutFieldName];
    const dimension = row[dimensionFieldName].toLowerCase() as keyof typeof BM_BREAKOUTS;

    const breakoutsForDimension = BM_BREAKOUTS[dimension];
    if (!breakoutsForDimension) return `Invalid dimension ${dimension}.`;
    const isValidBreakout =
      breakout.trim() !== '' && breakoutsForDimension.includes(Number(breakout));
    if (!isValidBreakout) {
      return `Invalid breakout value (${breakout}) for dimension ${dimension}.`;
    }
    return null;
  }
}
