import {
  Aggregation,
  DateRange,
  FilterColumn,
  Hotel,
  Pagination,
  Sort,
  fromTo,
} from '../../../models';
import { DestinationDimensionName } from '../../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';

export type BaseViewPayload = {
  partnersId: number[];
  from?: Date;
  to?: Date;
  locales: readonly string[];
};

export type BaseViewPayloadV2 = {
  from: Date;
  to: Date;
  locales: readonly string[];
  filters?: FilterColumn[];
};

export type ViewPayload = {
  pagination: Pagination;
  sort?: Sort;
  filters: FilterColumn[];
} & BaseViewPayload;

export type BiddingPayload = {
  partnersId: number[];
  dateRange: DateRange;
  locales: readonly string[];
  filters: FilterColumn[];
};

export type ExportViewPayLoad = {
  filters: FilterColumn[];
  sort?: Sort;
  comparePeriods?: { from: string; to: string }[];
} & BaseViewPayload;

export type TotalPayload = {
  defaultFilters: FilterColumn[];
} & BaseViewPayload;

export interface FetchTotalBody {
  partnerId: number[];
  aggregation: string;
  localeCodes: readonly string[];
  date?: { from: string; to: string };
  type: string;
  condition?: FilterColumn[];
}

export type PropertyPartnerName = Pick<Hotel, 'partnerRef' | 'name'> & { itemId: number };

export type Destination = {
  country: string;
  region?: string;
  city?: string;
};

export const DESTINATION_AGGREGATIONS: fromTo<DestinationDimensionName, Aggregation[]> = {
  city: [Aggregation.BY_CITY, Aggregation.BY_REGION, Aggregation.BY_COUNTRY],
  region: [Aggregation.BY_REGION, Aggregation.BY_COUNTRY],
  country: [Aggregation.BY_COUNTRY],
};

export const DESTINATION_COLUMNS: fromTo<DestinationDimensionName, Array<keyof Destination>> = {
  city: ['city', 'region', 'country'],
  region: ['region', 'country'],
  country: ['country'],
};
