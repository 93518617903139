import { Props } from './hyperlink-cell.types';
import { FC } from 'react';

export const HyperlinkCell: FC<Props> = ({ displayValue, onClick, hasValue }) => {
  if (!hasValue) return <span>{displayValue}</span>;

  return (
    <a
      href=""
      rel="noopener noreferrer"
      onClick={e => {
        onClick(e);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {displayValue}
    </a>
  );
};
