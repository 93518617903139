import * as React from 'react';
import { Props } from './widget-table.types';
import './widget-table.css';

export const WidgetTable: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <table className="c-widget-table">
      <tbody>{children}</tbody>
    </table>
  );
};
