import * as React from 'react';
import { Props } from './area-brand.types';
import './area-brand.css';
import { SelectorArea } from '../selector-area';
import { SelectorAreaMenu } from '../selector-area-menu/selector-area-menu';
import { BRANDS_SUBGROUP, BRANDS_ALLSUBBRANDS } from '../../../../constants';
import { InputIcon } from '../../../../components/input-icon';
import { SearchLineDarkIcon, CloseIcon } from 'tcp-react-icons';
import { translate } from '../../../../helpers/utils';
import { AreaOptions } from '../area-options/area-options';
import { Option } from '../../../../models';

export const AreaBrand: React.FunctionComponent<Props> = ({
  brand,
  isBrandSelectionMultiple,
  partnerOption,
  onCancel,
  onSave,
  onUpdateBrand,
}) => {
  const selectPartner = (option: Option) => {
    const isAlreadyInTheList = brand.optionsSelected.some(
      optionTmp => optionTmp.value === option.value,
    );
    let nextSelectedBrandOptionsInBar: Option[] = [];

    if (!isBrandSelectionMultiple) {
      nextSelectedBrandOptionsInBar = [option];
    } else {
      if (option.value === BRANDS_ALLSUBBRANDS) {
        nextSelectedBrandOptionsInBar = [partnerOption];
      } else if (isAlreadyInTheList) {
        nextSelectedBrandOptionsInBar = [
          ...brand.optionsSelected.filter(o => o.value !== option.value),
        ];
      } else {
        nextSelectedBrandOptionsInBar = [
          ...brand.optionsSelected.filter(optionTmp => optionTmp.value !== BRANDS_ALLSUBBRANDS),
          option,
        ];
      }

      if (nextSelectedBrandOptionsInBar.length === 0) {
        nextSelectedBrandOptionsInBar = [partnerOption];
      }
    }
    onUpdateBrand({
      ...brand,
      optionsSelected: nextSelectedBrandOptionsInBar,
    });
  };

  return (
    <SelectorArea onCancel={onCancel} onSave={onSave} qaId="selector-bar-menu-brand">
      <SelectorAreaMenu
        options={[BRANDS_SUBGROUP]}
        selectedOption={brand.subMenuSelected}
        onSelect={option => {
          onUpdateBrand({
            ...brand,
            subMenuSelected: option,
          });
        }}
        numOptionSelected={brand.optionsSelected.length}
        isMultiple={isBrandSelectionMultiple}
      />
      <div className="u-flex-grow--1">
        <article className="u-padding--gutter u-background--white">
          <div className="c-area-brand" data-qa="selector-bar-input">
            <InputIcon
              className="c-input c-input--wider u-border-radius"
              icon={brand.search === '' ? SearchLineDarkIcon : CloseIcon}
              placeholder={translate('analytics_home_placeholder_search_subbrands')}
              onChange={e => {
                onUpdateBrand({
                  ...brand,
                  search: e.target.value,
                });
              }}
              value={brand.search}
              onClickIcon={() =>
                onUpdateBrand({
                  ...brand,
                  search: '',
                })
              }
              autoFocus={true}
            />
          </div>
        </article>
        <AreaOptions
          options={brand.options}
          selectedGroup={brand.subMenuSelected}
          searchKeyword={brand.search}
          selectedOptions={brand.optionsSelected}
          isMultiple={isBrandSelectionMultiple}
          onChange={selectPartner}
        />
      </div>
    </SelectorArea>
  );
};
