import React from 'react';
import styles from './fullscreen-modal.module.css';
import { Props } from './fullscreen-modal.types';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import cn from 'classnames';

export const FullscreenModal: React.FunctionComponent<Props> = ({
  children,
  title,
  onClose,
  titleStyle,
  containerStyle,
}) => {
  const modalRef = React.createRef<HTMLInputElement>();
  useOnClickOutside(modalRef, () => onClose && onClose());

  React.useEffect(() => {
    document.body.style.height = '100vh';
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.height = '100%';
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <div className={styles.fullscreenModal}>
      <div ref={modalRef} className={cn(styles.fullscreenModalContainer, containerStyle)}>
        <h1 className={cn(styles.title, titleStyle)}>{title}</h1>
        {children}
      </div>
    </div>
  );
};
