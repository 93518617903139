import { Props } from './hotel-not-found.types';
import './hotel-not-found.css';
import teapot from './teapot.svg';
import { Card } from '../card';
import { translateHtml, translate } from '../../helpers/utils';
import { Redirect, useLocation } from 'react-router-dom';
import { Button } from '../../core-ui/components/button/button';
import { FC, useState } from 'react';

export const HotelNotFound: FC<Props> = ({ partnerRef, redirectRoute, reason }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const location = useLocation();
  return (
    <>
      {shouldRedirect && (
        <Redirect to={{ pathname: redirectRoute, state: { from: location.pathname } }} />
      )}
      <Card className="c-hotel-not-found__card u-margin--gutter">
        <div className="u-display--flex u-margin-auto c-hotel-not-found">
          <div className="u-flex-direction--column c-hotel-not-found__left">
            <h1 className="u-margin-bottom--medium u-margin-top--medium">
              {translate('analytics_hotel_not_found_title', partnerRef)}
            </h1>
            <span>{translateHtml(reason)}</span>
            <div>
              <Button
                text={translate('analytics_hotel_not_found_back_button')}
                onClick={() => setShouldRedirect(true)}
              />
            </div>
          </div>
          <div className="u-margin-left--small">
            <img src={teapot} width={150} height={150} alt="Teapot icon showing hotel not found" />
          </div>
        </div>
      </Card>
    </>
  );
};
