import { parse } from 'papaparse';
import { RowValidationIssue, RowValidationRule } from './row-validation-rule';
import { downloadCsv } from '../download';

export type ValidateCsvOptions = {
  rules: RowValidationRule[];
};

export type ValidateCsvResult = {
  rowCount: number;
  errorsCount: number;
  warningsCount: number;
  downloadIssuesFile: () => Promise<void>;
};

export const validateCsv = (file: File, options: ValidateCsvOptions): Promise<ValidateCsvResult> =>
  new Promise(resolve => {
    let i = 2; // starts from 2 because header is the first row
    let errorsCount = 0;
    let warningsCount = 0;
    const issues: Array<RowValidationIssue & { row: number }> = [];
    parse<Record<string, string>, File>(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader: header => header.trim().toLowerCase(),
      chunk: results => {
        for (const row of results.data) {
          const rowIssues = validateRow(row, options.rules);
          let hasErrors = false;
          let hasWarnings = false;
          for (const issue of rowIssues) {
            if (issue.type === 'error') hasErrors = true;
            if (issue.type === 'warning') hasWarnings = true;
            issues.push({ row: i, ...issue });
          }
          if (hasErrors) errorsCount++;
          if (hasWarnings) warningsCount++;
          i++;
        }
      },
      complete: () =>
        resolve({
          rowCount: i - 1,
          errorsCount,
          warningsCount,
          downloadIssuesFile: () => downloadCsv({ data: issues, fileName: 'issues' }),
        }),
    });
  });

const validateRow = (row: Record<string, string>, rules: RowValidationRule[]) =>
  rules
    .map(rule => rule.validate(row))
    .filter(error => !!error)
    .flat() as RowValidationIssue[];
