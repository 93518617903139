import * as React from 'react';
import { Props } from './empty-dashboard.types';
import './empty-dashboard.css';
import { translate } from '../../../helpers/utils';
import emptyDashboard from './empty-dashboard.svg';

export const EmptyDashboard: React.FunctionComponent<Props> = () => {
  return (
    <div className="c-empty-dashboard">
      <img
        className="c-empty-dashboard__img u-margin-bottom--medium"
        src={emptyDashboard}
        alt="Dashboard is empty"
      />
      <p className="u-text-align--center u-color--juri-light u-font-weight--bold ">
        {translate('dashboard_empty_1')}
      </p>
      <p className="u-text-align--center u-color--juri-light">{translate('dashboard_empty_2')}</p>
    </div>
  );
};
