import * as React from 'react';
import { Props } from './input-filter.types';
import './input-filter.css';
import DeleteLineDarkIcon from 'tcp-react-icons/lib/DeleteLineDarkIcon';
import { translate } from '../../../../helpers/utils';
import { ColumnDef } from '../../../../models';

export const InputFilter: React.FunctionComponent<Props> = ({
  columns,
  filterTypes,
  filter,
  isValid,
  onUpdateFilter,
  onRemoveFilter,
  isSelected,
  isOneFilter,
  index,
}) => {
  const getColumn = (columnKey: string) => columns.find(column => column.key === columnKey);

  const updateFilterBySelectingColumn = (columnKey: string) => {
    const columnLabel = getColumn(columnKey)?.name || '';
    const nextFilter = { ...filter, columnName: columnKey, columnLabel };
    onUpdateFilter(nextFilter, index);
  };

  const updateFilterBySelectingFilterOption = (filterOptionValue: string) => {
    const nextFilter = { ...filter, type: filterOptionValue };
    onUpdateFilter(nextFilter, index);
  };

  const updateFilterByAddingValue = (value: string) => {
    const nextFilter = { ...filter, value: value.replace(',', '.') };
    onUpdateFilter(nextFilter, index);
  };

  const getPossibleFilterTypes = (columnTmp: ColumnDef | undefined) => {
    if (!columnTmp) {
      return [];
    }
    if (columnTmp.type === 'string') {
      return filterTypes.slice(0, filterTypes.length - 2); // This comes from old toolbar, isn't a better way to know the filters according to the type?
    } else if (columnTmp.type !== 'string') {
      return filterTypes.slice(2, filterTypes.length);
    }
    return [];
  };

  const column = getColumn(filter.columnName);

  const columnOptions = [...columns]
    .sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))
    .map(({ key, name }) => (
      <option
        key={key}
        value={key}
        selected={key === filter.columnName}
        data-qa={`filters-select-column-${index}-option-${key}`}
      >
        {name}
      </option>
    ));

  const filterTypesSorted =
    filter.columnName === ''
      ? []
      : getPossibleFilterTypes(column)
          .sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))
          .map(({ key, name }) => (
            <option
              key={key}
              value={key}
              selected={name === filter.type}
              data-qa={`filters-select-condition-${index}-option-${key}`}
            >
              {name}
            </option>
          ));

  return (
    <div className="u-display--flex u-align-items--center u-margin-bottom--gutter c-input-filter__padding">
      <select
        className="u-margin-right--gutter u-font-size--h-tiny c-input-filter "
        onChange={({ target: { value } }) => updateFilterBySelectingColumn(value)}
        value={filter.columnName}
        data-qa={`filters-select-column-${index}`}
      >
        <option value="" data-qa={`filters-select-column-${index}-option-empty`}>
          {translate('analytics_input_filter_select_column')}
        </option>
        {columnOptions}
      </select>
      <select
        className="u-margin-right--gutter u-font-size--h-tiny c-input-filter"
        onChange={({ target: { value } }) => updateFilterBySelectingFilterOption(value)}
        value={filter.type}
        data-qa={`filters-select-condition-${index}`}
      >
        <option value="">{translate('analytics_input_filter_select_condition')}</option>
        {filterTypesSorted}
      </select>
      <span
        className="c-input-filter__symbol u-font-size--t-small u-position--relative"
        id={column !== undefined ? column.wrapper : ''}
      >
        <input
          className="u-margin-right--gutter u-font-size--h-tiny c-input-filter c-input"
          placeholder="Enter value"
          value={filter.value}
          autoFocus={isSelected}
          onChange={({ target: { value } }) => updateFilterByAddingValue(value)}
          data-qa={`filters-input-${index}`}
        />
      </span>

      {isValid || (isValid && isOneFilter) || !isOneFilter ? (
        <DeleteLineDarkIcon
          onClick={onRemoveFilter}
          className="u-cursor--pointer"
          data-qa={`filters-delete-${index}`}
        />
      ) : (
        <div className="c-input-filter__space-no-trash" />
      )}
    </div>
  );
};
