import * as React from 'react';
import { Props } from './pos-overview-widget.types';
import './pos-overview-widget.css';
import { Widget } from '../widget';
import { translate, getLastXDays } from '../../../helpers/utils';
import { LocaleColumn } from '../../../components/locale-column';
import { WidgetRow } from '../widget/widget-row';
import { AppContext } from '../../../global/context/app-context';
import {
  areMultiplePOSorGlobalSelected,
  getLocale,
  getPartner,
} from '../../../services/app-service';
import { View } from '../../../models';
import { WidgetTable } from '../widget/widget-table';
import { WidgetRemoveMenuItem } from '../widget/widget-remove-menu-item';
import { WidgetNoData } from '../widget/widget-no-data';
import { formatNumber } from '../../../helpers/formater';
import { ButtonRetry } from '../../../components/button-retry';
import { useWidgetData } from '../../../hooks/use-widget-data';

export const PosOverviewWidget: React.FunctionComponent<Props> = ({ onRemoveWidget }) => {
  const { selectedLocales, partner, subPartners, locales } = React.useContext(AppContext);

  const { columns, data, isFetchingColumns, isFetchingData, refetch, isError } = useWidgetData(
    View.POS_OVERVIEW,
    {
      partnersId: [getPartner(partner, subPartners).partnerId],
      localeCodes: [...selectedLocales],
      from: getLastXDays(1), // last week
      to: getLastXDays(1), // yesterday
    },
    [selectedLocales, subPartners],
  );

  const isSingleOrAllBrandsSelected =
    subPartners.length === 1 || subPartners.length === partner.subpartners.length;

  const isDisabled =
    areMultiplePOSorGlobalSelected([...selectedLocales]) || !isSingleOrAllBrandsSelected;

  const isLoading = isFetchingColumns || isFetchingData;

  const renderInfo = (value: string | number | undefined, wrapper: string) => {
    if (!isDisabled) {
      return value !== undefined ? formatNumber(value, wrapper) : translate('common_not_available');
    } else {
      return (
        <span className="u-color--juri-lighter">
          {translate('dashboard_pos_overview_widget_select_one_pos_brand')}
        </span>
      );
    }
  };

  return (
    <Widget
      title={
        <div>
          {!isDisabled && (
            <>
              <LocaleColumn pos={getLocale([...locales], selectedLocales[0])} />
              <span className="u-font-weight--bold"> -</span>
            </>
          )}
          <span className="u-font-weight--bold">
            {' '}
            {translate('dashboard_pos_overview_widget_title')}
          </span>
        </div>
      }
      isLoading={isLoading}
      menuItems={[<WidgetRemoveMenuItem key="1" />]}
      onSelectMenuItem={onRemoveWidget}
    >
      {isError ? (
        <ButtonRetry message={translate('dashboard_widget_error')} onClick={refetch} />
      ) : data.length === 0 ? (
        <WidgetNoData />
      ) : (
        <WidgetTable>
          {columns.map(({ name, key, tooltip, wrapper }, index) => (
            <WidgetRow key={index} field={{ text: name, tooltip }}>
              <td className="c-pos-overview-widget__value">{renderInfo(data[0][key], wrapper)}</td>
            </WidgetRow>
          ))}
        </WidgetTable>
      )}
      <span className="u-margin-top--auto u-margin-left--small u-color--juri-lighter">
        {translate('dashboard_pos_overview_widget_current_data')}
      </span>
    </Widget>
  );
};
