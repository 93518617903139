import { useEffect, useState } from 'react';
import { ReportsApi } from '../../helpers/api/reports/reports-api';
import { Subscription } from '../../views/reports/report.types';

interface UseSubscriptionResult {
  isLoading: boolean;
  subscriptions: Subscription[];
  refetch: () => void;
  error?: string;
}

export function useSubscriptions(partnerId: number): UseSubscriptionResult {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const loadSubscriptions = async () => {
    try {
      setLoading(true);
      setError(undefined);
      setSubscriptions(await ReportsApi.fetchSubscriptions(partnerId));
    } catch (e) {
      console.error(e);
      setError('Unable to fetch subscriptions');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSubscriptions();
  }, [partnerId]);

  return {
    isLoading,
    error,
    subscriptions,
    refetch: loadSubscriptions,
  };
}
