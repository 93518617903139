export interface Props {
  message?: React.ReactNode | string;
  className?: string;
  isDark?: boolean;
  isWhite?: boolean;
  positionMessage?: LoadingPositionMessage;
  variant?: 'dots' | 'ellipsis';
}

export enum LoadingPositionMessage {
  RIGHT,
  BOTTOM,
}
