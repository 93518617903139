import { useEffect } from 'react';

export function useOnEscape(callback: VoidFunction) {
  useEffect(() => {
    const callbackOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') callback();
    };
    document.addEventListener('keydown', callbackOnEscape);
    return () => document.removeEventListener('keydown', callbackOnEscape);
  }, [callback]);
}
