import cn from 'classnames';
import { ChangeEvent, FC, useRef } from 'react';
import { NotificationLevel } from '../../../../constants';
import { apiFileHelper } from '../../../../helpers/api/api-file-helper';
import { TranslationKey, translate } from '../../../../helpers/utils';
import { readableBytes } from '../../../../helpers/utils/bytes';
import { showNotification } from '../../../../services/notification-service';
import trackerService from '../../../../services/tracker/tracker-service';
import './file-uploader.css';
import { Props } from './file-uploader.types';
import uploadArrowDisabled from './upload-arrow-disabled.svg';
import uploadArrow from './upload-arrow.svg';

export const FileUploader: FC<Props> = props => {
  const { buttonText, downloadSample, qaId, isDisabled, uploadFileFn, maxFileSize } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file: File | null = extractFileAttached(e);
    if (!file) return;
    if (file.size > maxFileSize) {
      showNotification({
        message: `File is too large. Please upload a file smaller than ${readableBytes(
          maxFileSize,
        )}`,
        level: NotificationLevel.ERROR,
      });
      cleanFileInput();
      return;
    }
    uploadFileFn(file);
    cleanFileInput();
  };

  // @see https://stackoverflow.com/a/42192710
  const cleanFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const extractFileAttached = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      return e.target.files[0];
    }
    return null;
  };

  const { disabledMessage = 'file_uploader_not_available' } = props;

  return (
    <label
      data-qa={`file-uploader-${qaId}`}
      className={cn('c-file-uploader u-font-weight--normal u-cursor--pointer', {
        'c-file-uploader__disabled': isDisabled,
      })}
    >
      <img
        src={isDisabled ? uploadArrowDisabled : uploadArrow}
        alt="Icon representing file upload"
      />
      <span>
        <input
          className="c-file-uploader__input"
          type="file"
          accept="text/csv"
          multiple={false}
          onChange={onFileChange}
          ref={fileInputRef}
          onClick={() => trackerService.track('File Upload', { file: buttonText })}
        />
        <span className="u-padding-left--medium">{buttonText}</span>
      </span>
      {isDisabled && (
        <p>
          <a href="#" className="c-file-uploader__link u-color--blue">
            {translate(disabledMessage as TranslationKey)}
          </a>
        </p>
      )}
      {downloadSample && !isDisabled && (
        <p>
          <a
            onClick={e => {
              e.preventDefault();
              apiFileHelper.downloadSampleFile(downloadSample.url);
            }}
            href={downloadSample.url}
            className="c-file-uploader__link u-color--blue"
          >
            {translate(downloadSample.translationKey as TranslationKey)}
          </a>
        </p>
      )}
    </label>
  );
};
