import * as React from 'react';
import { Props } from './snackbar-notification.types';
import './snackbar-notification.css';
import { CloseIcon } from 'tcp-react-icons';
import cn from 'classnames';
import { NotificationLevel } from '../../../../constants/notifications';
import { TranslationKey, formatDateAndTime, translate } from '../../../../helpers/utils';

export const SnackbarNotification: React.FunctionComponent<Props> = ({ notification, onClose }) => {
  return (
    <div
      className={cn(
        'c-snackbar-notification u-padding--gutter--medium u-box-shadow u-margin-top--small',
        {
          'u-background--green-lightest u-border--green-lighter':
            notification.level === NotificationLevel.SUCCESS,
          'u-background--red-lightest u-border--red-lighter':
            notification.level === NotificationLevel.ERROR,
          'u-background--orange-lightest u-border--orange-lighter':
            notification.level === NotificationLevel.WARNING,
          'u-background--blue-lightest u-border--blue-lighter':
            notification.level === NotificationLevel.INFO,
        },
      )}
    >
      <div className="u-display--flex">
        <div>
          <span className="u-font-weight--bold">{notification.title} - </span>
          {Array.isArray(notification.message)
            ? notification.message.map(messageTmp => translate(messageTmp as TranslationKey))
            : translate(notification.message as TranslationKey)}
        </div>
        <div className="u-margin-left--auto">
          <CloseIcon
            className=" u-cursor--pointer c-snackbar-notification__close"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="u-display--flex u-flex-wrap--wrap u-justify-content--space-between u-margin-top--small u-color--juri-light">
        <span className="u-margin-right--small">{notification.brandName}</span>
        <span>{formatDateAndTime(notification.date)}</span>
      </div>
    </div>
  );
};
