import React, { FC } from 'react';
import { Props } from './member-partners.types';
import './member-partners.css';
import { translate } from '../../../../helpers/utils';
import { List } from '../../../../components/list';
import { AdminPartnerRow } from '../admin-partner-row';
import { useModal } from 'react-modal-hook';
import ApiAdminHelper from '../../../../helpers/api/admin/api-admin-helper';
import { showNotification } from '../../../../services/notification-service';
import { NotificationLevel } from '../../../../constants';
import { AssignPartnerModal } from '../assign-partner-modal';
import { useConfirmationModal } from '../../../../hooks/use-confirmation-modal';
import { Loading } from '../../../../components/loading';
import { usePartnersFor } from './use-partners-for-member';
import { Button } from '../../../../core-ui/components/button/button';

export const MemberPartners: FC<Props> = ({ member }) => {
  const { partners, refetchPartners, isLoading } = usePartnersFor(member.memberId);
  const { confirm } = useConfirmationModal();

  const [showAssignPartnerModal, closeAssignPartnerModal] = useModal(() => {
    return (
      <AssignPartnerModal
        member={member}
        onClose={() => {
          refetchPartners();
          closeAssignPartnerModal();
        }}
        memberPartnersIds={partners.map(mp => mp.partnerId)}
      />
    );
  }, [partners, member]);

  const unassignPartner = async (partnerId: number) => {
    try {
      if (!(await confirm())) return;
      await ApiAdminHelper.unAssignMemberPartner(member.memberId, partnerId);
      await refetchPartners();
    } catch (error) {
      console.error(error);
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((error as any).message),
      });
    }
  };

  return (
    <section className="u-flex-direction--row u-margin-top--large">
      <h3>{translate('admin_members_related_partners_title')}</h3>
      <div className="u-display--flex u-justify-content--flex-end u-padding-bottom--gutter">
        <Button
          text={translate('admin_members_add_partner_button')}
          onClick={() => showAssignPartnerModal()}
        />
      </div>
      <div className="u-display--block">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="u-display--flex">
            {partners.length > 0 ? (
              <List
                headers={[
                  translate('member_partner_header_partner_id'),
                  translate('member_partner_header_name'),
                  translate('member_partner_header_actions'),
                ]}
                rows={partners.map(partner => (
                  <AdminPartnerRow
                    key={partner.partnerId}
                    partner={partner}
                    onUnAssignPartner={unassignPartner}
                  />
                ))}
              />
            ) : (
              <span className="u-margin-auto u-color--juri-light">
                {translate('member_partner_empty_partner_list')}
              </span>
            )}
          </div>
        )}
      </div>
    </section>
  );
};
