import { FC } from 'react';
import { AnchoredMenu } from '../../../core-ui/components/menu/anchored-menu';
import { useMenuAnchor } from '../../../core-ui/components/menu/use-menu-anchor';
import { translate } from '../../../helpers/utils';
import { QUALIFIERS_BY_FILTER_TYPE } from '../types';
import styles from './qualifier-selector.module.css';
import { useFilterFormField } from './use-filter-form-field';

export const QualifierSelector: FC = () => {
  const { filter, changeQualifier } = useFilterFormField();
  const { anchor, showMenu, hideMenu } = useMenuAnchor();
  const qualifiers = QUALIFIERS_BY_FILTER_TYPE[filter.type];

  return (
    <>
      <a onClick={showMenu} className={styles.qualifierLink}>
        {translate(filter.qualifier)}
      </a>
      {anchor && (
        <AnchoredMenu
          anchor={anchor}
          onClose={hideMenu}
          items={qualifiers.map(qualifier => ({
            id: qualifier,
            label: translate(qualifier),
            onClick: () => changeQualifier(qualifier),
          }))}
        />
      )}
    </>
  );
};
