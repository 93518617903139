import { FileUploaderOption } from '../../../components/button-dropdown-file-uploader';
import { useSelectedPartner } from '../../../hooks/use-selected-partner';
import { EventType } from '../../../models';
import { useRemainingPushes } from '../../history/history-toolbar/use-remaining-pushes';
import {
  BaseBidUploaderOption,
  BidModifierUploaderOption,
  CampaignAllocationUploaderOption,
  CpaBidModifierUploaderOption,
  CpaUploaderOption,
  InventoryUploaderOption,
  PropertyGroupsUploaderOption,
} from './uploader-options';

export function useBidFileUploaderOptions(): FileUploaderOption[] {
  const partner = useSelectedPartner();
  const { data: remainingPushes = {} } = useRemainingPushes(partner.partnerId);

  const options: FileUploaderOption[] = [
    new BaseBidUploaderOption(remainingPushes[EventType.PUSH]),
    new BidModifierUploaderOption(remainingPushes[EventType.PUSH_BM]),
    new PropertyGroupsUploaderOption(remainingPushes[EventType.PUSH_PROPERTY_GROUPS]),
    new CampaignAllocationUploaderOption(
      remainingPushes[EventType.PUSH_CAMPAIGN_ALLOCATIONS],
      partner.isCpaEligible,
    ),
    new CpaUploaderOption(remainingPushes[EventType.PUSH_CPA_VALUE]),
    new CpaBidModifierUploaderOption(remainingPushes[EventType.PUSH_CPA_BM], partner.isCpaEligible),
    new InventoryUploaderOption(),
  ];

  return options;
}
