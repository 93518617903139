export const filterListWithPosBySearchText =
  <T extends { pos: { localeCode: string; name: string } }>(text: string) =>
  ({ pos: { localeCode, name } }: T) => {
    const lowerCasedText = text.toLowerCase();
    if (lowerCasedText.includes(',')) {
      const multipleTexts = lowerCasedText.split(',').map(subtext => subtext.trim());
      return multipleTexts.some(
        subtext =>
          localeCode.toLowerCase().includes(subtext) || name.toLowerCase().includes(subtext),
      );
    }
    return (
      localeCode.toLowerCase().includes(lowerCasedText) ||
      name.toLowerCase().includes(lowerCasedText)
    );
  };
