import { FilterColumn } from '../../models';
import { useCurrentFilterStorageKey } from '../use-current-filter-storage-key/use-current-filter-storage-key';
import { useState } from 'react';
import { getDimensionFiltersFromLocalStorage } from '../../helpers/local-storage-helper';
import { FilterStorageKey } from '../../constants';

export const useContextualFilters = () => {
  const filterStorageKey = useCurrentFilterStorageKey();
  const filters = useState<FilterColumn[]>(getDimensionFiltersFromLocalStorage());
  const slFilters = useState<FilterColumn[]>(
    getDimensionFiltersFromLocalStorage(FilterStorageKey.SPONSORED_LISTING),
  );

  if (filterStorageKey === FilterStorageKey.DEFAULT) return filters;
  return slFilters;
};
