import { FC, useState } from 'react';
import { MenuItemType, MultiLevelMenu } from '../multi-level-menu';
import { PosCountriesSelector } from './pos-countries-selector';
import { PosRegionsSelector } from './pos-regions-selector';
import { DimensionValueLabels, Locale, Region } from '../../models';
import { Dimension } from '../../views/custom-dashboard/multi-folder-select/multi-folder-select.types';

type Selectable = { selected: boolean; isRadio?: boolean; checked?: boolean };
export type SelectableLocale = Locale & Selectable;
export type SelectableRegion = Region & Selectable;

type ApplyPropsType = {
  name: Dimension;
  values: DimensionValueLabels[];
};
export type PosSelectorProps = {
  locales: SelectableLocale[];
  regions: SelectableRegion[];
  onApply: (localesOrRegions: ApplyPropsType) => void;
};

export const PosSelector: FC<PosSelectorProps> = ({ locales, regions, onApply }) => {
  const [selectedLocales, setSelectedLocales] = useState<SelectableLocale[]>(locales);
  const [selectedRegions, setSelectedRegions] = useState<SelectableRegion[]>(regions);

  function applyLocales(newSelectedLocales: Locale[]) {
    setSelectedRegions(regions);
    const localeCodes = new Set(newSelectedLocales.map(el => el.localeCode));
    const newLocales = locales
      .map(el => ({ ...el, selected: localeCodes.has(el.localeCode) }))
      .filter(el => el.selected);
    setSelectedLocales(newLocales);
    onApply({
      name: 'posCountry',
      values: newLocales.map(locale => ({
        label: locale.name,
        value: locale.localeCode,
      })),
    });
  }

  function applyRegions(newSelectedRegions: Region[]) {
    setSelectedLocales(locales);
    const regionNames = new Set(newSelectedRegions.map(el => el.regionName));
    const newRegions = regions
      .map(el => ({ ...el, selected: regionNames.has(el.regionName) }))
      .filter(el => el.selected);
    setSelectedRegions(newRegions);
    onApply({
      name: 'posRegion',
      values: newRegions.map(region => ({
        label: region.regionName,
        value: region.regionName,
      })),
    });
  }

  const isCountrySelected = locales.some(el => el.selected);
  const isRegionSelected = regions.some(el => el.selected);

  const items: MenuItemType[] = [
    {
      key: 'countries',
      label: 'Countries',
      subMenu: [
        {
          key: 'countries-selector',
          label: <PosCountriesSelector locales={selectedLocales} onApply={applyLocales} />,
        },
      ],
    },
    {
      key: 'regions',
      label: 'Regions',
      subMenu: [
        {
          key: 'regions-selector',
          label: <PosRegionsSelector regions={selectedRegions} onApply={applyRegions} />,
        },
      ],
    },
  ];
  if (!isCountrySelected && !isRegionSelected) return <MultiLevelMenu items={items} />;
  const selectedCountryOrRegion = items.find(
    item =>
      (isCountrySelected && item.key === 'countries') ||
      (isRegionSelected && item.key === 'regions'),
  );

  return <>{selectedCountryOrRegion?.subMenu && selectedCountryOrRegion.subMenu[0].label}</>;
};
