import { FC, useEffect, useState } from 'react';
import styles from './validate-csv-modal-dialog.module.css';
import { Button } from '../../core-ui/components/button/button';
import { ResolvableModalComponent } from '../../core-ui/components/modal-dialog/use-intermediate-dialog';
import {
  HeaderValidationRule,
  RowValidationRule,
} from '../../helpers/csv/validation/row-validation-rule';
import {
  ValidateCsvResult as CsvValidationResult,
  validateCsv,
} from '../../helpers/csv/validation/validate-csv';
import { validateCsvHeaders } from '../../helpers/csv/validation/validate-csv-headers';
import { ModalDialog, ModalDialogProps } from '../../core-ui/components/modal-dialog/modal-dialog';
import { translate } from '../../helpers/utils';
import { Loading } from '../../core-ui/components/loading/loading';
import { trackCsvValidation } from './validate-csv-tracking';

type ValidateCsvResult = {
  isCancelled: boolean;
};

type ValidateCsvRequest = {
  file: File;
  rowRules: RowValidationRule[];
  headerRules: HeaderValidationRule[];
};

export const ValidateCsvModalDialog: ResolvableModalComponent<
  ValidateCsvResult,
  ValidateCsvRequest
> = ({ data, resolve }) => {
  const { file, headerRules, rowRules } = data!;
  const [isValidating, setIsValidating] = useState(true);
  const [headerErrors, setHeaderErrors] = useState<string[]>([]);
  const [validationResult, setValidationResult] = useState<CsvValidationResult>();

  useEffect(() => {
    async function validate() {
      try {
        setIsValidating(true);
        const headerValidationErrors = await validateCsvHeaders(file, headerRules);
        if (headerValidationErrors.length > 0) {
          trackCsvValidation('header_errors', file, { headerValidationErrors });
          setHeaderErrors(headerValidationErrors);
          return;
        }
        const result = await validateCsv(file, { rules: rowRules });
        if (result.errorsCount === 0 && result.warningsCount === 0) {
          resolve({ isCancelled: false });
          return;
        }
        setValidationResult(result);
        trackCsvValidation('issues_found', file, {
          rows: result.rowCount,
          errors: result.errorsCount,
          warnings: result.warningsCount,
        });
      } catch (e) {
        const error = e as any;
        console.error(error);
        trackCsvValidation('unexpected_error', file, {
          errorMessage: error?.message,
          error,
        });
        resolve({ isCancelled: false });
      } finally {
        setIsValidating(false);
      }
    }
    validate();
  }, [file, headerRules, rowRules, resolve]);

  function downloadIssuesFile() {
    trackCsvValidation('issues_file_downloaded', file);
    validationResult!.downloadIssuesFile();
  }

  function title() {
    if (isValidating) return;
    return translate('csv_validator_modal_title');
  }

  function content() {
    if (isValidating) return <ValidatingLoader />;
    return (
      <div>
        <p>{translate('csv_validator_modal_description')}</p>
        <div className={styles.validationSummary}>
          <ValidationSummary
            fileName={file.name}
            headerErrors={headerErrors}
            rowCount={validationResult?.rowCount}
            errorsCount={validationResult?.errorsCount}
            warningsCount={validationResult?.warningsCount}
          />
          {validationResult && (
            <Button
              onClick={downloadIssuesFile}
              text={translate('csv_validator_review_issues')}
              variant="secondary"
              icon={{ name: 'download' }}
            />
          )}
        </div>
      </div>
    );
  }

  function buttons(): ModalDialogProps['buttons'] | undefined {
    if (isValidating) return;
    return {
      end: [
        { text: 'Cancel', variant: 'tertiary', onClick: cancel },
        { text: 'Upload anyway', disabled: headerErrors.length > 0, onClick: uploadAnyway },
      ],
    };
  }

  function cancel() {
    trackCsvValidation('cancel_clicked', file, { validationResult, headerErrors });
    resolve({ isCancelled: true });
  }

  function uploadAnyway() {
    trackCsvValidation('upload_anyway_clicked', file, { validationResult, headerErrors });
    resolve({ isCancelled: false });
  }

  return (
    <ModalDialog open title={title()} buttons={buttons()} style={{ minWidth: '20vw' }}>
      {content()}
    </ModalDialog>
  );
};

const ValidatingLoader: FC = () => (
  <div className={styles.loadingWrapper}>
    <Loading className={styles.loading} />
    <p>{translate('csv_validator_validating_message')}</p>
  </div>
);

const ValidationSummary: FC<{
  fileName: string;
  rowCount?: number;
  errorsCount?: number;
  warningsCount?: number;
  headerErrors: string[];
}> = props => {
  const { fileName, headerErrors, rowCount, errorsCount, warningsCount } = props;
  return (
    <div>
      <h4>{fileName}</h4>
      {headerErrors.length > 0 && (
        <ul>
          {headerErrors.map(error => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      )}
      <SummaryParagraph count={rowCount} label={translate('csv_validator_total_rows')} />
      <SummaryParagraph
        count={errorsCount}
        label={translate('csv_validator_errors')}
        className={styles.errors}
      />
      <SummaryParagraph
        count={warningsCount}
        label={translate('csv_validator_warnings')}
        className={styles.warnings}
      />
    </div>
  );
};

const SummaryParagraph: FC<{ count?: number; label: string; className?: string }> = ({
  count,
  label,
  className,
}) => {
  if (!count) return null;

  return (
    <p className={className}>
      {count.toLocaleString()} {label}
    </p>
  );
};
