import * as React from 'react';
import { useContext, useState } from 'react';
import format from 'date-fns/format';
import { Props } from './history-toolbar.types';
import { DatePicker } from '../../../components/date-picker/date-picker';
import { TranslationKey, translate } from '../../../helpers/utils';
import { ComposedInlineDropdown } from '../../../components/composed-inline-dropdown/composed-inline-dropdown';
import { DATE_FORMAT_LONG_COMA } from '../../../constants/constants';
import { EventType } from '../../../models';
import './history-toolbar.css';
import cs from 'classnames';
import { getEventTypeKey } from '../../../services/history-service';
import { AppContext } from '../../../global/context/app-context';

export const HistoryToolbar: React.FunctionComponent<Props> = props => {
  const { onUpdateHistoryRange } = useContext(AppContext);
  const [isDatePickerVisible, setDatePicker] = useState(false);

  const handleCancel = (): void => setDatePicker(false);

  const updateRange = (from: Date, to: Date): void => {
    onUpdateHistoryRange({ from, to });
    setDatePicker(false);
  };

  const calculateDateRangeSubtitle = () => {
    const {
      defaultDates: { from, to },
    } = props;
    const f = format(from, DATE_FORMAT_LONG_COMA);
    const t = format(to, DATE_FORMAT_LONG_COMA);

    return (
      <span className="u-padding-left--medium c-dropdown-calendar__text">
        {f} - {t}
      </span>
    );
  };

  return (
    <div className="u-display--flex u-align-items--center u-padding-w--large u-padding-h--gutter">
      <select
        className="c-history-toolbar__select"
        onChange={e => {
          props.onChangeFilter(+e.target.value as EventType);
        }}
        defaultValue={EventType.UNDEFINED + ''}
      >
        {props.selectableTypes
          .map(eventType => {
            return {
              key: eventType,
              translation: translate(getEventTypeKey(eventType).slice(0, -1) as TranslationKey),
            };
          })
          .sort((a, b) => a.translation.localeCompare(b.translation))
          .map(it => (
            <option key={it.key} value={it.key}>
              {it.translation}
            </option>
          ))}
      </select>

      <article
        className={cs(
          'u-margin-left--small u-border--juri-lighter c-calendar-selector u-radius-2px',
          {
            'c-calendar-selector__opened': isDatePickerVisible,
          },
        )}
      >
        <ComposedInlineDropdown
          subTitle={calculateDateRangeSubtitle()}
          extraClass="a u-position--relative js-calendar c-dropdown-calendar"
          isSelected={false}
          alignArrowIconToRight={true}
          onClick={() => setDatePicker(!isDatePickerVisible)}
        />
      </article>
      <div className="c-history-toolbar__datepicker-bottom">
        {isDatePickerVisible && (
          <DatePicker
            offset="left"
            onClose={handleCancel}
            onUpdateRange={updateRange}
            from={props.defaultDates.from}
            to={props.defaultDates.to}
            isTodayChoosable={true}
          />
        )}
      </div>
    </div>
  );
};
