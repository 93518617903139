export type Option = {
  label: string;
  value: string;
  group: string;
  subLabel?: string;
  flag?: string;
  id?: string;
};

export enum Selection {
  BRAND,
  POS,
  CALENDAR,
  NONE,
}

export type SelectorBarOption = {
  options: Option[];
  optionsSelected: Option[];
  previousSavedOptionSelected: Option[];
  subMenuSelected: string;
  search: string;
};
