import { Column, ColumnType, Row } from '../components/grid';
import { ColumnDef, FileFormat, Row as DeprecatedRow } from '../models';
import { formatColumnRas, formatClickableColumn } from './grid-column-formatter';
import { COLUMN_KEY_OPPORTUNITY_CPC, COLUMN_KEY_RAS } from '../constants';
import React, { ReactText } from 'react';

export type ClickableCellProps = {
  action?: (row: Row, fileFormat?: FileFormat) => void | Promise<void>;
  displayValue?: (row: Row) => ReactText;
  fileDownloadProps?: { fileFormats: FileFormat[] };
};

export type CustomColumns = {
  columnCellClickActions?: { [columnKey: string]: ClickableCellProps };
  posLocale?: {
    key: string;
    children: (data: unknown, row: Row) => React.ReactNode;
  };
};

export const formatColumnsAndRows = (params: {
  columns: ColumnDef[];
  rows: DeprecatedRow[];
  total?: DeprecatedRow[];
  id?: string;
  isShowingCheckbox?: boolean;
  customColumns?: CustomColumns;
}): {
  formattedColumns: Column[];
  formattedRows: Row[];
  formattedFooterRows?: Row[];
} => {
  const formattedColumns = formatColumns(params.columns, params.customColumns);
  if (formattedColumns.length > 0 && params.id !== undefined && !!params.isShowingCheckbox) {
    formattedColumns.splice(0, 0, {
      type: ColumnType.CHECKBOX,
      key: 'checkbox',
    });
  }
  const gridColumns = formattedColumns
    .map(column => params.columns.find(c => c.key === column.key)!)
    .filter(c => !!c);
  return {
    formattedColumns,
    formattedRows: formatRows(params.rows, gridColumns, params.id || 'id'),
    formattedFooterRows: formatFooterRows(params.total, gridColumns, params.id || 'id'),
  };
};

const formatColumns = (columns: ColumnDef[], customColumns?: CustomColumns): Column[] => {
  return columns
    .filter(columnTmp => !columnTmp.isHidden)
    .map(columnTmp => {
      let column: Column;
      if (columnTmp.key === COLUMN_KEY_RAS) {
        column = {
          key: columnTmp.key,
          name: columnTmp.name,
          type: ColumnType.CUSTOM,
          tooltip: columnTmp.tooltip.trim() !== '' ? columnTmp.tooltip : undefined,
          children: formatColumnRas(),
        };
      } else if (customColumns?.columnCellClickActions?.[columnTmp.key]) {
        column = {
          key: columnTmp.key,
          name: columnTmp.name,
          type: ColumnType.CUSTOM,
          isClickable: true,
          isSortable: true,
          tooltip: columnTmp.tooltip.trim() !== '' ? columnTmp.tooltip : undefined,
          children: formatClickableColumn(
            customColumns.columnCellClickActions[columnTmp.key],
            columnTmp.name,
          ),
        };
      } else if (columnTmp.wrapper === '%') {
        column = {
          key: columnTmp.key,
          name: columnTmp.name,
          type: ColumnType.PERCENTAGE,
          isSortable: true,
          tooltip: columnTmp.tooltip.trim() !== '' ? columnTmp.tooltip : undefined,
        };
      } else if (columnTmp.wrapper !== '' || columnTmp.key === COLUMN_KEY_OPPORTUNITY_CPC) {
        column = {
          key: columnTmp.key,
          name: columnTmp.name,
          type: ColumnType.CURRENCY,
          isSortable: true,
          tooltip: columnTmp.tooltip.trim() !== '' ? columnTmp.tooltip : undefined,
        };
      } else if (columnTmp.type === 'int') {
        column = {
          key: columnTmp.key,
          name: columnTmp.name,
          type: ColumnType.INT,
          isSortable: true,
          tooltip: columnTmp.tooltip.trim() !== '' ? columnTmp.tooltip : undefined,
        };
      } else if (columnTmp.type === 'float') {
        column = {
          key: columnTmp.key,
          name: columnTmp.name,
          type: ColumnType.FLOAT,
          isSortable: true,
          tooltip: columnTmp.tooltip.trim() !== '' ? columnTmp.tooltip : undefined,
        };
      } else if (columnTmp.key === customColumns?.posLocale?.key) {
        column = {
          key: customColumns.posLocale.key,
          name: columnTmp.name,
          type: ColumnType.CUSTOM,
          isSortable: true,
          tooltip: columnTmp.tooltip.trim() !== '' ? columnTmp.tooltip : undefined,
          children: customColumns.posLocale.children,
        };
      } else {
        column = {
          key: columnTmp.key,
          name: columnTmp.name,
          type: ColumnType.TEXT,
          isSortable: true,
          tooltip: columnTmp.tooltip.trim() !== '' ? columnTmp.tooltip : undefined,
        };
      }

      return column;
    })
    .filter(columnTmp => columnTmp.key !== 'id');
};

const formatRows = (rows: DeprecatedRow[], gridColumns: ColumnDef[], key: string): Row[] => {
  return rows.map(rowTmp => {
    const rowHeader = rowTmp.rowHeader;
    const rowData: Row = {
      id: rowTmp[key],
      rowHeader: rowHeader,
      nested: rowTmp.nested ? formatRows(rowTmp.nested, gridColumns, key) : undefined,
    };
    gridColumns.forEach(columnTmp => {
      if (rowHeader && columnTmp.isHiddenTotal) return;
      rowData[columnTmp.key] = rowTmp[columnTmp.key];
    });
    return rowData;
  });
};

export const formatFooterRows = (
  rows: DeprecatedRow[] | undefined,
  gridColumns: ColumnDef[],
  key: string,
): Row[] | undefined => {
  return rows ? formatRows(rows, gridColumns, key) : undefined;
};
